import * as Sentry from "@sentry/react";
import { Component } from "react";

type AppErrorBoundaryProps = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export default class AppErrorBoundary extends Component<
  AppErrorBoundaryProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (error.name === "ChunkLoadError") {
      window.location.reload();
    } else {
      console.error("AppErrorBoundary: ", error);
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="unplugged-error">
          <div>
            <h1>Oops!</h1>
            <p>
              Something went wrong. Please try reloading the page. If the
              problem persists please contact an admin.
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
