import { useMutation, useQueryClient } from "@tanstack/react-query";

import { assignSpecialtyToCoach } from "~/api/requests/specialtyRequests";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

export const useUpdateSpecialty = () => {
  const queryClient = useQueryClient();

  const { mutate: updateSpecialty } = useMutation({
    mutationKey: ["specialties", "update"],
    mutationFn: ({ specialtyId }: { specialtyId: string }) =>
      assignSpecialtyToCoach(specialtyId),
    onSuccess: (specialty) => {
      queryClient.setQueryData(["specialties", "me"], specialty);
      displaySuccessToast({ message: t("profile.success") });
    }
  });

  return updateSpecialty;
};
