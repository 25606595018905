export default function (dataKey: string, allDataKeys: string[]) {
  switch (allDataKeys.indexOf(dataKey)) {
    case 0:
      return "blue";
    case 1:
      return "yellow";
    case 2:
      return "green";
    default:
      return "blue";
  }
}
