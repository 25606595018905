export enum UserProgramStatus {
  Active = 1,
  Pending = 2,
  Finished = 3,
  Paused = 4,
  Removed = 5,
  AlmostFinished = 6
}

export enum UserProgramStatusReason {
  Quit = 1,
  Discharged = 2,
  Exited = 3
}
