import styles from "./Tooltip.module.scss";

interface TooltipProps {
  content: React.ReactNode;
  show?: boolean;
  className?: string;
}

const Tooltip = ({ content, show, className }: TooltipProps) => {
  return show ? (
    <div className={`${styles.ActionTooltip} ${className ?? ""}`}>
      {content}
    </div>
  ) : null;
};

export default Tooltip;
