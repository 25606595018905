import round from "../number/round";

import {
  getHighestValuePropertyInArray,
  getLowestValuePropertyInArray
} from "~/helpers/array/arrayHelpers";
import { t } from "~/i18n";
import { HeartRateLineData } from "~/typing/carePortalTypes";

export type HeartRateInfoData = {
  title: string;
  heartrate: number;
};

export const getAnalyzedHeartRateData = (
  heartRateData: HeartRateLineData[]
): HeartRateInfoData[] => {
  const highestValue = getHighestValuePropertyInArray(
    heartRateData,
    "heartrate"
  );

  const lowestValue = getLowestValuePropertyInArray(heartRateData, "heartrate");

  return [
    {
      title: t("user.health.heartRate.highestHeartRate"),
      heartrate: round(highestValue?.heartrate) ?? 0
    },
    {
      title: t("user.health.heartRate.lowestHeartRate"),
      heartrate: round(lowestValue?.heartrate) ?? 0
    }
  ];
};
