import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ResetPasswordPage.module.scss";

import config from "../../config";

import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";

export default function ResetPasswordPage() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const { t } = useTranslation();
  const validate = () => {
    let valid = true;

    // Leaving regex validation of email up to the browser
    if (!email) {
      setInvalidEmail(true);
      valid = false;
    } else {
      setInvalidEmail(false);
    }
    return valid;
  };
  const resetPassword = async (e) => {
    e.preventDefault();
    if (!validate()) return false;
    const reqConfig: RequestInit = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-SK-PORTAL": `care-${config.platform}`
      })
    };
    reqConfig.body = JSON.stringify({
      email: email,
      client_id: "sidekickhealth.com"
    });
    const res = await fetch(
      `${config.authBaseUrl}/auth/password/reset/code`,
      reqConfig
    );
    setError(false);
    if (res.ok) {
      setSuccess(true);
    } else {
      setError(true);
    }
  };
  return (
    <SentryErrorBoundary transactionName="ResetPasswordPage">
      <Layout>
        <div className={styles.container}>
          <div>
            <h1>{t("resetpassword.title")}</h1>
            {success ? (
              <div>
                <h2>{t("resetpassword.success.title")}</h2>
                <div>
                  <p>{t("resetpassword.success.message")}</p>
                </div>
              </div>
            ) : (
              <form onSubmit={resetPassword} className="form--block">
                <p>{t("resetpassword.subTitle")}</p>
                <div className="inputContainer">
                  <input
                    autoComplete="email"
                    type="email"
                    placeholder={t("resetpassword.enterEmail")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoFocus
                    className="input"
                  />
                </div>
                {invalidEmail && (
                  <div className={styles.errorMessage}>
                    <p>{t("resetpassword.errors.missingEmail")}</p>
                  </div>
                )}
                {error && (
                  <div className={styles.errorMessage}>
                    <p>{t("resetpassword.errors.generic")}</p>
                  </div>
                )}

                <div className="form__actions">
                  <button className="btn-primary" type="submit">
                    {t("resetpassword.title")}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Layout>
    </SentryErrorBoundary>
  );
}
