import styles from "./ProgramRegistrationsPage.module.scss";

import MessagesIcon from "~/assets/messages.svg";
import { ExternalUser } from "~/typing/sidekickTypes";

export const IdentifierColumn = ({
  externalUser,
  onOpenRegistration,
  showSMSButton,
  openSMSMessages
}: {
  externalUser: ExternalUser;
  onOpenRegistration: (user: ExternalUser) => void;
  showSMSButton?: boolean;
  openSMSMessages: (user: ExternalUser) => void;
}) => {
  return (
    <div className={styles.userContainer}>
      <div
        className={styles.user}
        onClick={() => onOpenRegistration(externalUser)}
      >
        {externalUser.identifier}
      </div>
      {showSMSButton && (
        <img
          src={MessagesIcon}
          alt="SMS"
          className={`${styles.smsIcon} ${
            externalUser.unseenSms ? styles.unread : ""
          }`}
          data-testid={
            externalUser.unseenSms ? "program-registration-page-unread" : ""
          }
          onClick={() => openSMSMessages(externalUser)}
        />
      )}
    </div>
  );
};
