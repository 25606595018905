import { DateTime } from "luxon";

import FoodJournal from "./FoodJournal";

import { ScoreMeal } from "~/typing/sidekickTypes";

type FoodJournalListProps = {
  data: ScoreMeal[];
  day: string;
  timeZoneOffset: number;
};

const FoodJournalList = ({
  data,
  day,
  timeZoneOffset
}: FoodJournalListProps) => {
  // filter the list of journals to only include ones from the selected day
  const filtered = data
    ? data.filter((journal) => {
        return (
          DateTime.fromISO(journal.serverDate, {
            zone: "local"
          }).toISODate() === day
        );
      })
    : [];

  return (
    <>
      {filtered &&
        filtered.map((journal, index) => (
          <FoodJournal
            key={`food-journal-${journal.id}-${index}`}
            journal={journal}
            timeZoneOffset={timeZoneOffset}
          />
        ))}
    </>
  );
};

export default FoodJournalList;
