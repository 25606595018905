import { useState } from "react";

import styles from "./UserLayers.module.scss";
import UserLayersTooltip from "./UserLayersTooltip";

import Tooltip from "~/components/tooltip/Tooltip";
import colors from "~/styles/colors";
import { Layer } from "~/typing/sidekickTypes";

const MAX_LAYERS_TO_DISPLAY = 2;

type UserLayersProps = {
  layers: Layer[];
};

const UserLayers = ({ layers }: UserLayersProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const defaultColors = [
    colors.neonPink100,
    colors.neonPink140,
    "#0000aa",
    "#000078"
  ];

  const sortedLayersWithColor = [...layers]
    .sort((a, b) => ((a.title ?? a.name) > (b.title ?? b.name) ? 1 : -1))
    .map((layer, index) => ({
      ...layer,
      displayColor: defaultColors[index % defaultColors.length]
    }));

  const renderLayer = (layer: Layer, index: number) => {
    const displayedText =
      layer.carePortalAbbreviation || layer.title || layer.name;

    if (index === 1 && sortedLayersWithColor.length > MAX_LAYERS_TO_DISPLAY) {
      return (
        <>
          <span
            className={styles.layerName}
            style={{ color: layer.displayColor }}
          >
            {displayedText}
          </span>
          <span className={styles.additionalLayers}>
            +{sortedLayersWithColor.length - MAX_LAYERS_TO_DISPLAY}
          </span>
        </>
      );
    } else {
      return (
        <span
          className={styles.layerName}
          style={{ color: layer.displayColor }}
        >
          {displayedText}
        </span>
      );
    }
  };

  return (
    <div className={styles.userLayers}>
      <ul
        className={styles.layerList}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {sortedLayersWithColor
          .slice(0, MAX_LAYERS_TO_DISPLAY)
          .map((layer: Layer, index: number) => (
            <li key={layer.id} className={styles.layerItem}>
              {renderLayer(layer, index)}
            </li>
          ))}
        <Tooltip
          content={<UserLayersTooltip layers={sortedLayersWithColor} />}
          className={styles.tooltip}
          show={showTooltip}
        />
      </ul>
    </div>
  );
};

export default UserLayers;
