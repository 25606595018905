import updateStateFromPayload from "~/helpers/updateStateFromPayload";

export enum JournalActions {
  Set = "Set",
  Add = "Add",
  Update = "Update"
}

export default function reducer(state, action) {
  switch (action.type) {
    case JournalActions.Set: {
      return action.payload || [];
    }
    case JournalActions.Add: {
      action.payload?.forEach((entry) => {
        const index = state.findIndex((e) => e.id === entry.id);
        if (index !== -1) {
          state[index] = entry;
        } else {
          state.push(entry);
        }
      });

      return state;
    }
    case JournalActions.Update: {
      return updateStateFromPayload(state, action);
    }
    default:
      return state;
  }
}
