import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { ProviderViewData } from "~/typing/sidekickTypes";

export default function useProviderView(programCatalogItemId, locale) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/providerview`;
  const { data, isError, isLoading, invalidate } = useApi<ProviderViewData>({
    path,
    queryKey: QueryKeyFactory.programs.providerView(
      programCatalogItemId,
      locale
    )
  });
  return {
    providerView: data,
    isError,
    isLoading,
    invalidate
  };
}
