import { useTranslation } from "react-i18next";

import styles from "./TranslationToggleBox.module.scss";


type TranslationToggleBoxProps = {
    showTranslated: boolean;
    handleToggleTranslation: () => void;
};

const TranslationToggleBox = ({
    showTranslated,
    handleToggleTranslation,
}: TranslationToggleBoxProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.translationBox}>
            <div className={styles.translationText}>
            {showTranslated
                ? t("translation.text.wasTranslated")
                : t("translation.text.canBeTranslated")}
            </div>
            <a
            onClick={handleToggleTranslation}
            className={styles.translationLink}
            >
            {showTranslated
                ? t("translation.text.viewOriginal")
                : t("translation.text.viewTranslated")}
            </a>
        </div>
    );
};

export default TranslationToggleBox;