import { escapeSpecialCharacters } from "./string/stringHelpers";

import colors from "~/styles/colors";

export default function getMessageHtml(text?: string, query?: string) {
  text = text ?? "";
  const elements: any[] = [];
  let i = 0;
  text.split("\n").forEach((str) => {
    elements.push(str);
    elements.push(<br key={`br-${i++}`} />);
  });

  replaceMarkdown(elements, query);

  return elements;
}

const replaceMarkdown = (elements, query?: string) => {
  const markdownRegex = /(\[[^[\]\n\r]*\]\([^[\]()\n\r]*\))/g;
  const mapRegex = /(\[.*\]\(maps:.*\))/g;
  let k = 0;
  for (let i = 0; i < elements.length; i++) {
    const child = elements[i];
    if (child.type !== "br" && child.type !== "a") {
      const children: any[] = [];
      let element;
      child.split(markdownRegex).forEach((substr) => {
        if (substr.match(markdownRegex)) {
          const [label, href] = substr.match(mapRegex)
            ? mapLinkData(substr)
            : generalLinkData(substr);
          element = (
            <a href={href} key={`a-${k++}`} target="_blank" rel="noreferrer">
              {highlightSubstring(label, query)}
            </a>
          );
        } else {
          element = highlightSubstring(substr, query);
        }
        if (element !== "") {
          children.push(element);
        }
      });
      if (children.length > 0) {
        elements.splice(i, 1, children);
      } else {
        elements.splice(i, 1);
      }
    }
  }
};

const generalLinkData = (markdown) => {
  const [, label, href] = markdown.match(/\[(.*)\]\((.*)\)/);
  return [label, href];
};

const mapLinkData = (mapStr) => {
  const [, label, linkUrl] = mapStr.match(/\[(.*)\]\(maps:(.*)\)/);
  const href = `https://maps.google.com/?q=${linkUrl}`;
  return [label, href];
};

export const highlightSubstring = (
  str: string,
  query?: string,
  isMarkdown?: boolean
) => {
  if (!query) return str;
  if (!str) return "";
  if (query) {
    // remove markdown highlighting (``) while search to avoid conflicts
    // for highlighting message part while search
    str = str.replace(/`/g, "");
  }
  // Escape special characters to avoid regex errors
  const escapedQuery = escapeSpecialCharacters(query);

  const parts = str.split(new RegExp(`(${escapedQuery})`, "gi"));

  const highlightedParts = parts.map((part, index) => {
    if (part.toLowerCase() === query?.toLowerCase()) {
      if (isMarkdown) {
        return "`" + part + "`";
      }

      return (
        <span
          key={`highlighted-${index}`}
          style={{ backgroundColor: colors.mustardYellow100 }}
        >
          {part}
        </span>
      );
    }

    return part;
  });

  return isMarkdown ? highlightedParts.join("") : highlightedParts;
};
