import colors from "~/styles/colors";

interface CloseProps {
  color?: string;
}

const Archive = ({ color = colors.navyBlue60 }: CloseProps) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11441_5131)">
        <path
          d="M22.7188 9.84766V19.3477C22.7187 20.1433 22.4027 20.9064 21.8401 21.469C21.2775 22.0316 20.5144 22.3477 19.7188 22.3477H6.21875C5.4231 22.3477 4.66004 22.0316 4.09743 21.469C3.53482 20.9064 3.21875 20.1433 3.21875 19.3477V9.84766"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7188 1.84766H3.21875C2.82093 1.84766 2.43939 2.00569 2.15809 2.287C1.87679 2.5683 1.71875 2.94983 1.71875 3.34766V6.34766H24.2188V3.34766C24.2188 2.94983 24.0607 2.5683 23.7794 2.287C23.4981 2.00569 23.1166 1.84766 22.7188 1.84766Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9688 10.3477H10.9688C10.1403 10.3477 9.46875 11.0192 9.46875 11.8477V12.8477C9.46875 13.6761 10.1403 14.3477 10.9688 14.3477H14.9688C15.7972 14.3477 16.4688 13.6761 16.4688 12.8477V11.8477C16.4688 11.0192 15.7972 10.3477 14.9688 10.3477Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11441_5131">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.96875 0.0976562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Archive;
