import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

export default function useDocuments(userId: string) {
  const path = `/admin/users/${userId}/documents`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: any;
  }>({
    path,
    queryKey: QueryKeyFactory.users.documents(userId)
  });
  return {
    documents: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
