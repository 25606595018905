import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import { ampli } from "../ampli";

import config from "~/config";
import { isLocalhost } from "~/helpers/browser/browserHelpers";
import { wordCount } from "~/helpers/string/stringHelpers";
import useProgram from "~/hooks/useApi/useProgram";
import { HealthCardType } from "~/pages/user/information/healthCards/healthCardData";
import { UserURLParams } from "~/typing/carePortalTypes";

export const useAmplitudeTracking = () => {
  const { program_id, locale, user_id } = useParams<UserURLParams>();

  enum environment {
    Sidekick = "sidekick",
    Elevance = "elevance"
  }

  const { program } = useProgram({
    programCatalogItemId: program_id ?? "",
    locale: locale ?? ""
  });

  const defaultProperties = {
    programId: program_id !== undefined ? parseInt(program_id, 10) : -1,
    userId: user_id ?? "unknown",
    environment: config.isAnthem ? environment.Elevance : environment.Sidekick,
    isTestProgram:
      program?.programCatalogItemProjectType?.toLocaleLowerCase() === "test",
    programName: program?.name ?? "unknown"
  };

  const amplitudeWrapper = (fn: () => void) => {
    if (isLocalhost) return;

    // Wait for program if program_id is present
    if (program_id && !program) {
      const interval = setInterval(() => {
        if (program) {
          clearInterval(interval);
          fn();
        }
      }, 100);
      return;
    }

    fn();
  };

  const trackSendSMS = (message: string) =>
    amplitudeWrapper(() =>
      ampli.smsSent({
        wordCount: wordCount(message),
        ...defaultProperties
      })
    );

  const trackInviteSMSSent = () =>
    amplitudeWrapper(() =>
      ampli.smsInviteSent({
        ...defaultProperties
      })
    );

  const trackNewInviteSMSSent = ({
    externalUserId,
    message
  }: {
    externalUserId: string;
    message: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.newSmsInviteSent({
        ...defaultProperties,
        externalUserId: parseInt(externalUserId),
        wordCount: message.split(" ").length
      })
    );

  const trackPatientDischarged = () =>
    amplitudeWrapper(() =>
      ampli.patientDischarged({
        ...defaultProperties
      })
    );

  const trackDocumentDownload = () =>
    amplitudeWrapper(() => ampli.documentDownloaded(defaultProperties));

  const trackDocumentDelete = () =>
    amplitudeWrapper(() => ampli.documentDeleted(defaultProperties));

  const trackDocumentUpload = () =>
    amplitudeWrapper(() =>
      ampli.documentUploaded({
        ...defaultProperties
      })
    );

  const trackCoachAssigned = () =>
    amplitudeWrapper(() => ampli.coachAssigned(defaultProperties));

  const trackInviteCoach = () =>
    amplitudeWrapper(() => ampli.coachInvited(defaultProperties));

  const trackExport = ({
    startDate,
    informationIncluded
  }: {
    startDate: DateTime;
    endDate?: DateTime;
    informationIncluded: string[];
  }) =>
    amplitudeWrapper(() =>
      ampli.userReportExported({
        fromDate: startDate.toLocaleString() as any,
        informationIncluded,
        ...defaultProperties
      })
    );

  const trackDeviceOrderSelected = ({
    deviceNames
  }: {
    deviceNames: string[];
  }) =>
    amplitudeWrapper(() =>
      ampli.deviceOrderSelected({
        deviceName: deviceNames,
        ...defaultProperties
      })
    );

  const trackDeviceOrderStatusSelected = ({
    deviceOrderDate,
    deviceOrderStatus
  }: {
    deviceOrderDate: string;
    deviceOrderStatus: "Pending" | "Shipped" | "Delivered" | "Ordered";
  }) =>
    amplitudeWrapper(() =>
      ampli.deviceOrderStatusSelected({
        deviceOrderDate,
        deviceOrderStatus,
        ...defaultProperties
      })
    );

  const trackDeviceOrder = ({
    deviceIds,
    deviceNames
  }: {
    deviceIds: string[];
    deviceNames: string[];
  }) =>
    amplitudeWrapper(() =>
      ampli.deviceOrdered({
        deviceId: deviceIds,
        deviceName: deviceNames,
        ...defaultProperties
      })
    );

  const trackNoteSaved = ({
    category,
    message
  }: {
    category: string;
    message: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.noteAdded({
        noteTopic: category,
        wordCount: wordCount(message),
        ...defaultProperties
      })
    );

  const trackSendMessage = ({
    message,
    messageSuggestionId
  }: {
    message: string;
    messageSuggestionId?: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.messageSent({
        isTestProgram: defaultProperties.isTestProgram,
        environment: defaultProperties.environment,
        userId: defaultProperties.userId,
        wordCount: wordCount(message),
        isRecommendedMessage: messageSuggestionId !== undefined,
        messageSuggestionId
      })
    );

  const trackBulkMessageSent = (
    message: string,
    numberOfUsers: number,
    userSelection: string[]
  ) =>
    amplitudeWrapper(() =>
      ampli.bulkMessageSent({
        wordCount: wordCount(message),
        numberOfUsers,
        userSelection,
        ...defaultProperties
      })
    );

  const trackCalendarViewOpened = () =>
    amplitudeWrapper(() => ampli.calendarViewOpened({ ...defaultProperties }));

  const trackMedicationViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.medicationViewOpened({ ...defaultProperties })
    );

  const trackMedicationReminderSent = () =>
    amplitudeWrapper(() =>
      ampli.medicationReminderSent({ ...defaultProperties })
    );

  const trackMedicationFilterSelected = (medicationSelected: string) =>
    amplitudeWrapper(() =>
      ampli.medicationFilterSelected({
        medicationSelected,
        ...defaultProperties
      })
    );

  const trackAssignedToFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.assignedToFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackProFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.proFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackProResultFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.proResultFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackUserStatusFilterSelected = (userStatusFilterSelection: string[]) =>
    amplitudeWrapper(() =>
      ampli.userStatusFilterSelected({
        userStatusFilterSelection,
        ...defaultProperties
      })
    );

  const trackWeekFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.weekFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackBrandFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.brandFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackLobFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.lobFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackMemberStateFilterSelected = (filterValue: string[]) =>
    amplitudeWrapper(() =>
      ampli.memberStateFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackOutreachFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.outreachFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackPlanStateFilterSelected = (filterValue: string[]) =>
    amplitudeWrapper(() =>
      ampli.planStateFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackSmsFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.smsFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackUserTypeFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.userTypeFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackDiseaseTypesFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.diseaseTypeFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackLayerFilterSelected = (filterValue: string[]) =>
    amplitudeWrapper(() =>
      ampli.layerFilterSelected({
        filterValue,
        ...defaultProperties
      })
    );

  const trackMessageFilterSelected = (filterValue: string) =>
    amplitudeWrapper(() =>
      ampli.messageNotificationStatusFilter({
        filterValue,
        ...defaultProperties
      })
    );

  const trackLastWeekPointsHovered = () =>
    amplitudeWrapper(() =>
      ampli.weekPointsViewed({
        ...defaultProperties
      })
    );
  const trackPerformancePageViewed = ({
    performanceSection,
    performanceView,
    performanceViewMode
  }: {
    performanceSection:
      | "Sleep Quality"
      | "Stress Level"
      | "Energy Level"
      | "Activity"
      | "Step counter"
      | "Fitness test";
    performanceView: "Mental" | "Physical";
    performanceViewMode: "Weekly" | "Monthly" | "Detail";
  }) =>
    amplitudeWrapper(() =>
      ampli.performanceViewOpened({
        ...defaultProperties,
        performanceSection,
        performanceView,
        performanceViewMode
      })
    );

  const trackPROResultApproved = ({
    proResultId,
    proDate,
    proName
  }: {
    proDate: string;
    proName: string;
    proResultId: number;
  }) =>
    amplitudeWrapper(() =>
      ampli.proResultApproved({
        ...defaultProperties,
        proResult: proResultId,
        proDate,
        proName
      })
    );

  const trackPROResultMessageSent = ({
    proResultId,
    proDate,
    proName,
    wordCount
  }: {
    proDate: string;
    proName: string;
    proResultId: number;
    wordCount: number;
  }) =>
    amplitudeWrapper(() =>
      ampli.proResultMessageSent({
        ...defaultProperties,
        proResult: proResultId,
        proDate,
        proName,
        wordCount
      })
    );

  const trackDocumentViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.documentViewOpened({
        ...defaultProperties
      })
    );

  const trackUserInformationViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.userInformationViewOpened({
        ...defaultProperties
      })
    );

  const trackPROResultChanged = ({
    proResultId,
    proDate,
    proName
  }: {
    proDate: string;
    proName: string;
    proResultId: number;
  }) =>
    amplitudeWrapper(() =>
      ampli.proResultChanged({
        ...defaultProperties,
        proResult: proResultId,
        proDate,
        proName
      })
    );

  const trackMealMessageSent = ({
    isHealthyRatingChanged,
    wordCount,
    isPortionSizeChanged
  }: {
    mealDate: string;
    mealName: string;
    mealId: number;
    wordCount: number;
    isHealthyRatingChanged: boolean;
    isPortionSizeChanged: boolean;
  }) =>
    amplitudeWrapper(() =>
      ampli.mealMessageSent({
        ...defaultProperties,
        isHealthyRatingChanged,
        isPortionSizeChanged,
        wordCount
      })
    );

  const trackQuizMissionOpened = ({
    quizName,
    quizResult
  }: {
    quizName: string;
    quizResult: number;
  }) =>
    amplitudeWrapper(() =>
      ampli.quizMissionOpened({
        ...defaultProperties,
        quizName,
        quizResult
      })
    );

  const trackMissionsViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.missionsViewOpened({
        ...defaultProperties
      })
    );

  const trackPROViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.proViewOpened({
        ...defaultProperties
      })
    );

  const trackFoodJornalViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.foodJournalViewOpened({
        ...defaultProperties
      })
    );

  const trackUserGuideOpened = () =>
    amplitudeWrapper(() =>
      ampli.userGuideOpened({
        ...defaultProperties
      })
    );

  const trackMyPogramsPageViewed = () =>
    amplitudeWrapper(() =>
      ampli.myProgramsPageOpened({
        ...defaultProperties
      })
    );

  const trackUserListViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.userListViewOpened({
        ...defaultProperties
      })
    );

  const trackRegistrationViewOpened = () =>
    amplitudeWrapper(() =>
      ampli.registrationViewOpened({
        ...defaultProperties
      })
    );

  const trackHealthCardOpened = ({
    healthCardType
  }: {
    healthCardType: HealthCardType;
  }) =>
    amplitudeWrapper(() =>
      ampli.healthCardOpened({
        healthCardType,
        ...defaultProperties
      })
    );

  const trackRecommendedMessagesExited = () =>
    amplitudeWrapper(() =>
      ampli.recommendedMessagesExited({ ...defaultProperties })
    );

  const trackRecommendedMessagesViewed = () =>
    amplitudeWrapper(() =>
      ampli.recommendedMessagesOpened({ ...defaultProperties })
    );

  const trackRecommendedMessageEdited = ({
    messageSuggestionId,
    wordCount
  }: {
    messageSuggestionId: string;
    wordCount: number;
  }) =>
    amplitudeWrapper(() =>
      ampli.messageEditModeSelected({
        ...defaultProperties,
        messageSuggestionId,
        wordCount
      })
    );
  const trackPatientRestarted = () =>
    amplitudeWrapper(() =>
      ampli.patientRestarted({
        ...defaultProperties
      })
    );
  const trackMessageChatMaximized = () =>
    amplitudeWrapper(() =>
      ampli.messageChatMaximised({ ...defaultProperties })
    );

  const trackMessageChatMinimised = () =>
    amplitudeWrapper(() =>
      ampli.messageChatMinimised({
        ...defaultProperties
      })
    );

  const trackMessageChatClosed = () =>
    amplitudeWrapper(() =>
      ampli.messageChatClosed({
        ...defaultProperties
      })
    );

  const trackMessageChatOpened = () =>
    amplitudeWrapper(() =>
      ampli.messageChatOpened({
        ...defaultProperties
      })
    );

  const trackActionAwaitingMealSelected = ({ userId }: { userId: string }) =>
    amplitudeWrapper(() =>
      ampli.actionAwaitingMealSelected({
        ...defaultProperties,
        userId
      })
    );

  const trackActionAwaitingMessageSelected = ({
    isSms,
    isCmRecipient,
    userId
  }: {
    isSms: boolean;
    isCmRecipient?: boolean;
    userId: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.actionAwaitingMessageSelected({
        ...defaultProperties,
        isSms,
        isCmRecipient: isCmRecipient ?? false,
        userId
      })
    );

  const trackActionAwaitingPROSelected = ({ userId }: { userId: string }) =>
    amplitudeWrapper(() =>
      ampli.actionAwaitingProSelected({
        ...defaultProperties,
        userId
      })
    );

  const trackRecommendedMessageEditModeCancelled = ({
    messageSuggestionId
  }: {
    messageSuggestionId: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.messageResetSelected({
        ...defaultProperties,
        messageSuggestionId
      })
    );
  const trackAddressInsertedInChat = () =>
    amplitudeWrapper(() =>
      ampli.addressInserted({
        ...defaultProperties
      })
    );

  const trackRecommendedMessageEditDoneSelected = ({
    messageSuggestionId
  }: {
    messageSuggestionId: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.messagePreviewSelected({
        ...defaultProperties,
        messageSuggestionId
      })
    );

  const trackRecommendedMessageTraversed = ({
    direction,
    messageSuggestionId
  }: {
    direction: "next" | "previous";
    messageSuggestionId: string;
  }) =>
    amplitudeWrapper(() => {
      if (direction === "next") {
        ampli.nextMessageSelected({
          ...defaultProperties,
          messageSuggestionId
        });
      } else {
        ampli.previousMessageSelected({
          ...defaultProperties,
          messageSuggestionId
        });
      }
    });

  const trackMessageReplySelected = () =>
    amplitudeWrapper(() =>
      ampli.messageReplySelected({
        ...defaultProperties
      })
    );

  const trackMessageCopySelected = () =>
    amplitudeWrapper(() =>
      ampli.messageCopyTextSelected({
        ...defaultProperties
      })
    );
  const trackMessageThreadChanged = ({
    fromUserId,
    from,
    toUserId,
    to
  }: {
    fromUserId: string;
    from: string;
    toUserId: string;
    to: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.messageThreadChanged({
        isTestProgram: defaultProperties.isTestProgram,
        environment: defaultProperties.environment,
        userId: defaultProperties.userId,
        fromUserId,
        from,
        toUserId,
        to
      })
    );

  const trackUserReportCopied = ({
    fromDate,
    informationIncluded
  }: {
    fromDate: string;
    informationIncluded: string[];
  }) =>
    amplitudeWrapper(() =>
      ampli.userReportCopied({
        fromDate,
        informationIncluded,
        ...defaultProperties
      })
    );

  const trackNextStepUserMetricsViewed = () =>
    amplitudeWrapper(() => ampli.nextStepUserMetricsViewed());

  const trackNextStepGraphViewOpened = () =>
    amplitudeWrapper(() => ampli.nextStepGraphViewOpened());

  const trackNextStepRecommendedMessageCopied = ({
    messageSuggestionId
  }: {
    messageSuggestionId: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.nextStepRecommendedMessageCopied({
        messageSuggestionId
      })
    );

  const trackMessageSuggestionChanged = ({
    from,
    to
  }: {
    from: string;
    to: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.messageSuggestionChanged({
        from: from,
        to: to
      })
    );

  const trackCarePriorityMarkedDone = ({
    carePriority,
    userId
  }: {
    carePriority: string;
    userId: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.carePriorityMarkedDone({
        carePriority: carePriority,
        userId: userId
      })
    );

  const trackMessageSuggestionCopied = ({
    messageSuggestionId
  }: {
    messageSuggestionId: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.messageSuggestionCopied({
        messageSuggestionId: messageSuggestionId
      })
    );

  const trackCarePriorityTabChanged = ({
    from,
    to
  }: {
    from: string;
    to: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.carePriorityTabChanged({
        from: from,
        to: to
      })
    );

  const trackTaskPaginatorClicked = ({
    direction
  }: {
    direction: "next" | "previous";
  }) =>
    amplitudeWrapper(() =>
      ampli.taskPaginatorClicked({
        direction: direction
      })
    );

  const trackTaskRowsChanged = () =>
    amplitudeWrapper(() => ampli.taskRowsChanged());

  const trackTaskOpened = ({
    carePriorities,
    userId
  }: {
    carePriorities: string[];
    userId: string;
  }) =>
    amplitudeWrapper(() =>
      ampli.taskOpened({
        carePriorities: carePriorities,
        userId: userId
      })
    );

  const trackAccordionExpanded = ({ topic }: { topic: string }) =>
    amplitudeWrapper(() =>
      ampli.accordionExpanded({
        topic: topic
      })
    );

  const trackAccessManagementOpened = () =>
    amplitudeWrapper(() => ampli.accessManagementPageOpened());

  const trackNextStepTaken = ({
    carePriorities,
    direction,
    from,
    to,
    unfinishedCarePriorities
  }: {
    carePriorities: string[];
    direction: "next" | "previous";
    from: string;
    to: string;
    unfinishedCarePriorities: string[];
  }) =>
    amplitudeWrapper(() =>
      ampli.nextStepTaken({
        carePriorities: carePriorities,
        direction: direction,
        from: from,
        to: to,
        unfinishedCarePriorities: unfinishedCarePriorities
      })
    );

  return {
    trackSendMessage,
    trackNoteSaved,
    trackExport,
    trackCoachAssigned,
    trackDocumentDelete,
    trackDocumentDownload,
    trackDocumentUpload,
    trackInviteCoach,
    trackPatientDischarged,
    trackSendSMS,
    trackAssignedToFilterSelected,
    trackProFilterSelected,
    trackProResultFilterSelected,
    trackUserStatusFilterSelected,
    trackWeekFilterSelected,
    trackBulkMessageSent,
    trackBrandFilterSelected,
    trackLobFilterSelected,
    trackMemberStateFilterSelected,
    trackOutreachFilterSelected,
    trackPlanStateFilterSelected,
    trackSmsFilterSelected,
    trackUserTypeFilterSelected,
    trackDiseaseTypesFilterSelected,
    trackLayerFilterSelected,
    trackDeviceOrder,
    trackDeviceOrderSelected,
    trackDeviceOrderStatusSelected,
    trackLastWeekPointsHovered,
    trackPerformancePageViewed,
    trackPROResultApproved,
    trackPROResultMessageSent,
    trackDocumentViewOpened,
    trackUserInformationViewOpened,
    trackPROResultChanged,
    trackMealMessageSent,
    trackQuizMissionOpened,
    trackMissionsViewOpened,
    trackPROViewOpened,
    trackFoodJornalViewOpened,
    trackNewInviteSMSSent,
    trackInviteSMSSent,
    trackUserGuideOpened,
    trackHealthCardOpened,
    trackPatientRestarted,
    trackMessageFilterSelected,
    trackCalendarViewOpened,
    trackMedicationViewOpened,
    trackMedicationFilterSelected,
    trackMedicationReminderSent,
    trackRecommendedMessagesViewed,
    trackRecommendedMessageEdited,
    trackRecommendedMessageEditModeCancelled,
    trackRecommendedMessageEditDoneSelected,
    trackRecommendedMessageTraversed,
    trackMessageReplySelected,
    trackMessageCopySelected,
    trackMessageChatMaximized,
    trackMessageChatMinimised,
    trackMessageChatClosed,
    trackMessageChatOpened,
    trackActionAwaitingMealSelected,
    trackActionAwaitingMessageSelected,
    trackActionAwaitingPROSelected,
    trackAddressInsertedInChat,
    trackMessageThreadChanged,
    trackMyPogramsPageViewed,
    trackUserListViewOpened,
    trackRegistrationViewOpened,
    trackUserReportCopied,
    trackRecommendedMessagesExited,
    trackNextStepUserMetricsViewed,
    trackNextStepGraphViewOpened,
    trackNextStepRecommendedMessageCopied,
    trackMessageSuggestionChanged,
    trackCarePriorityMarkedDone,
    trackMessageSuggestionCopied,
    trackCarePriorityTabChanged,
    trackTaskPaginatorClicked,
    trackTaskRowsChanged,
    trackTaskOpened,
    trackAccordionExpanded,
    trackAccessManagementOpened,
    trackNextStepTaken
  };
};
