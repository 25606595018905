import updateStateFromPayload from "../../helpers/updateStateFromPayload";

import { Coach } from "~/typing/sidekickTypes";

type Group = {
  behaviour: number;
  coach: Coach;
  coachUserId: string;
  createdDate: string;
  id: string;
  locale: string;
  memberCount: number;
  modifiedByUserId: string;
  modifiedDate: string;
  name: string;
};

export enum GroupActions {
  Fetch = "Fetch",
  Update = "Update"
}

type State = Group[];

type FetchAction = {
  type: GroupActions.Fetch;
  payload: Group[];
};

type UpdateAction = {
  type: GroupActions.Update;
  payload: Group[];
};

type Action = UpdateAction | FetchAction;

export default function groupsReducer(state: State, action: Action) {
  switch (action.type) {
    case GroupActions.Fetch: {
      const groups = action.payload;
      return groups;
    }
    case GroupActions.Update:
      return updateStateFromPayload(state, action);
    default:
      return state;
  }
}
