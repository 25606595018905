import { DateTime } from "luxon";

import {
  DAYS_IN_MONTH,
  DAYS_IN_WEEK,
  GraphView
} from "./PhysicalPage.constants";

import changeMonth from "~/helpers/date/changeMonth";

export const getStartAndEndDatesForQuery = (
  graphView: GraphView,
  endDate: DateTime,
  change?: 1 | -1
): { newStartDate: DateTime; newEndDate: DateTime } => {
  const today = DateTime.utc();

  switch (graphView) {
    case GraphView.Weekly: {
      const days = (change ?? 0) * DAYS_IN_WEEK;
      const newEndDate = endDate.plus({ days: days });
      return {
        newStartDate: newEndDate.minus({ days: DAYS_IN_WEEK * 3 }),
        newEndDate: newEndDate
      };
    }
    case GraphView.Monthly: {
      const newEndDate = changeMonth(endDate, change);

      return {
        newStartDate: newEndDate.minus({ days: DAYS_IN_MONTH }),
        newEndDate
      };
    }
    case GraphView.Period: {
      return {
        newStartDate: today.minus({ years: 5 }),
        newEndDate: today
      };
    }
    default:
      return {
        newStartDate: endDate.minus({ days: 7 }),
        newEndDate: today
      };
  }
};
