import { ReactNode } from "react";

import styles from "./AnswerTooltip.module.scss";

import DiscreteScale from "../DiscreteScale";

import {
  SurveyResultAnswerSeverity,
  SurveyResultQuestion
} from "~/typing/sidekickTypes";

type AnswerTooltipProps = {
  question?: SurveyResultQuestion;
  uniqueValues?: number[];
  rating?: number | string;
  answerDisplay?: ({
    question,
    useSkipped,
    date,
    questionIndex,
    proIndex
  }: {
    question?: SurveyResultQuestion;
    useSkipped?: boolean;
    date?: Date;
    questionIndex?: number;
    proIndex?: number;
  }) => ReactNode;
  answerSeverities?: SurveyResultAnswerSeverity[];
  questionIndex?: number;
  proIndex?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const AnswerTooltip = ({
  question,
  rating,
  uniqueValues,
  answerDisplay,
  answerSeverities,
  questionIndex,
  proIndex,
  onMouseEnter,
  onMouseLeave
}: AnswerTooltipProps) => {
  const isCurrentRating = (value) => {
    return value === rating;
  };

  const hasUniqueRating = () => {
    return typeof rating === "number" && uniqueValues?.includes(rating);
  };

  const showScale = !!question && hasUniqueRating();

  return (
    <div
      className={styles.AnswerTooltip}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      >
      {showScale && (
        <DiscreteScale
          uniqueValues={uniqueValues ?? []}
          isCurrentRating={isCurrentRating}
          answerSeverities={answerSeverities}
        />
      )}
      <div className={styles.answers}>
        <ul>{
          answerDisplay?.({
            question: question,
            useSkipped: true,
            questionIndex: questionIndex,
            proIndex: proIndex
            })
          }
        </ul>
      </div>
    </div>
  );
};

export default AnswerTooltip;
