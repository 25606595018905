import { t } from "~/i18n";

export default function (time: number, withAgo = true) {
  const millisecondsPerSecond = 1000;
  const secondsPerYear = 31536000;
  const secondsPerDay = 86400;
  const secondsPerHour = 3600;
  const secondsPerMinute = 60;

  const totalSeconds = Math.floor(time / millisecondsPerSecond);
  const years = Math.floor(totalSeconds / secondsPerYear);
  const agoText = withAgo ? ` ${t("timeDiff.ago")}` : "";

  if (years > 0) {
    return `${years}y` + agoText;
  }

  let remainder = totalSeconds % secondsPerYear;
  const days = Math.floor(remainder / secondsPerDay);

  if (days > 0) {
    return `${days}d` + agoText;
  }

  remainder = totalSeconds % secondsPerDay;
  const hours = Math.floor(remainder / secondsPerHour);

  if (hours > 7) {
    return `${hours}${t("timeDiff.hours")}` + agoText;
  }

  remainder = remainder % secondsPerHour;
  const minutes = Math.floor(remainder / secondsPerMinute);

  if (hours > 0) {
    return `${hours}${t("timeDiff.hours")} ${minutes}m` + agoText;
  }

  if (minutes > 0) {
    return `${minutes}m` + agoText;
  }

  let seconds = Math.floor(time / millisecondsPerSecond);

  if (seconds < 0) seconds = 0;

  return `${seconds}s` + agoText;
}
