import colors from "~/styles/colors";
import { SurveyRating } from "~/typing/sidekickTypes";

export default function contrast(hex1: string, hex2: string) {
  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);

  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);

  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

const hexToRgb = (hex: string): number[] => {
  if (!hex) return [0, 0, 0];

  hex = hex.toLowerCase();
  if (/^#([a-f0-9]{3}){1,2}$/.test(hex)) {
    if (hex.length == 4) {
      hex = "#" + [hex[1], hex[1], hex[2], hex[2], hex[3], hex[3]].join("");
    }
    const c = Number("0x" + hex.substring(1));
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }

  return [0, 0, 0];
};

const luminance = (rgbValues: number[]) => {
  const rgb = rgbValues.map((v) => {
    const val = v / 255;
    return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
  });
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
};

function shouldUseLightText(rating: Partial<SurveyRating> | undefined) {
  const colour = rating?.colour || colors.navyBlue20;
  const lightContrast = contrast(colour, colors.white);
  const darkContrast = contrast(colour, colors.navyBlue100);
  return lightContrast > darkContrast;
}

function getBestRatingContrastTextColor(
  rating: Partial<SurveyRating> | undefined
) {
  return rating && shouldUseLightText(rating)
    ? colors.white
    : colors.navyBlue100;
}

export { shouldUseLightText, getBestRatingContrastTextColor };
