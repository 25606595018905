import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Profile from "./Profile";

import ProfileIcon from "~/assets/user.svg";
import NarrowLayout from "~/components/layout/NarrowLayout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import { useUpdateSpecialty } from "~/hooks/useApi/specialties/useUpdateSpecialty";
import useUser from "~/hooks/useUser";
import { AppDispatch } from "~/state/store";
import { updateUser, setUserSetting } from "~/state/user/userSlice";

const ProfilePage = () => {
  const { user, settings, userStatus, settingsStatus } = useUser();

  const updateUserSpecialty = useUpdateSpecialty();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const handleUpdate = ({
    nick,
    locale,
    useMetric,
    title,
    specialtyId,
    phoneNumber
  }: {
    nick: string;
    locale: string;
    useMetric: boolean;
    title?: string;
    specialtyId?: string;
    phoneNumber: string;
  }) => {
    toast.remove();
    if (
      nick !== user?.nick ||
      locale !== user?.locale ||
      title !== user?.title
    ) {
      i18n.changeLanguage(locale);

      dispatch(
        updateUser({
          userId: user?.id ?? "",
          update: { nick, locale, title }
        })
      );
    }

    if (useMetric !== Boolean(settings?.useMetric)) {
      dispatch(
        setUserSetting({
          userId: user?.id ?? "",
          settingName: "useMetric",
          setting: {
            booleanValue: useMetric,

            value: useMetric ? "true" : "false"
          }
        })
      );
    }

    if (phoneNumber !== settings?.phoneNumber) {
      dispatch(
        setUserSetting({
          userId: user?.id ?? "",
          settingName: "phoneNumber",
          setting: { value: phoneNumber }
        })
      );
    }

    if (specialtyId) {
      updateUserSpecialty({
        specialtyId
      });
    } else {
      displaySuccessToast({ message: t("profile.success") });
    }
  };

  return (
    <SentryErrorBoundary transactionName="ProfilePage">
      <NarrowLayout
        title={t("profile.title", "My page")}
        titleImg={ProfileIcon}
      >
        <Helmet title={t("profile.title", "My page")} defer={false} />
        {user && settings && (
          <Profile
            user={user}
            settings={settings}
            onUpdate={handleUpdate}
            isLoading={userStatus === "loading" || settingsStatus === "loading"}
            hasError={userStatus === "failed" || settingsStatus === "failed"}
          />
        )}
      </NarrowLayout>
    </SentryErrorBoundary>
  );
};

export default ProfilePage;
