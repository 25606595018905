import { createContext } from "react";

import { CustomListColumn } from "~/components/customList/CustomList";
import {
  SearchData,
  FilterCheckboxData,
  CustomListFilterData
} from "~/typing/carePortalTypes";
import { ExternalUser, ExternalUserType } from "~/typing/sidekickTypes";

type RegistrationListContextProps = {
  externalUsers: ExternalUser[];
  searchData?: SearchData;
  filterData: CustomListFilterData[];
  filterCheckboxData: FilterCheckboxData[];
  columns: CustomListColumn<ExternalUser>[];
  externalUserTypeId?: string;
  customListUniqueId: string;
  externalUserTypes: ExternalUserType[];
};

export const RegistrationListContext = createContext<RegistrationListContextProps>(
  {} as RegistrationListContextProps
);
