import { useNavigate } from "react-router";

import ImportExternalUsersPage from "./ImportExternalUsersPage";

import api from "../../api/api";
import config from "../../config";
import { FileUploadType } from "../../constants/fileUploadTypes";

import SentryErrorBoundary from "~/components/SentryErrorBoundary";

const ImportAnthemUsersPage = () => {
  // this page is explicitly for anthem
  const navigate = useNavigate();

  if (
    !config.isAnthem &&
    window.location.hostname !== "care-dev.sidekickhealth.com" &&
    window.location.hostname !== "localhost"
  ) {
    navigate(`/`);
  }

  const getExport = async ({
    setImportFinished,
    uploadToken,
    externalUserTypeName,
    setResponseMessage
  }: {
    setImportFinished: (value: boolean) => void;
    uploadToken: string;
    externalUserTypeName: string;
    setResponseMessage: (value: string) => void;
  }) => {
    setImportFinished(true);
    const exportResponse = await api.get(
      `/admin/extras/export/anthem?token=${uploadToken}&externalUserTypeName=${externalUserTypeName}`
    );
    if (exportResponse?.data) {
      const csvContent = atob(exportResponse.data.file);
      // ugly way to download the csv data in a .csv file
      const link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
      );
      link.setAttribute("download", "exportedusers.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setResponseMessage(
        `Could not export users due to error, status:${exportResponse?.status}`
      );
    }
  };

  return (
    <SentryErrorBoundary transactionName="ImportAnthemUsersPage">
      <ImportExternalUsersPage
        getExport={getExport}
        fileUploadType={FileUploadType.AnthemImport}
      />
    </SentryErrorBoundary>
  );
};

export default ImportAnthemUsersPage;
