import SkeletonElement from "./SkeletonElement";
import styles from "./SkeletonProgramCard.module.scss";
import SkeletonShimmer from "./SkeletonShimmer";

export default function SkeletonProgramCard() {
  return (
    <div className="skeleton-wrapper">
      <div className={styles.SkeletonProgramCard}>
        <SkeletonElement type="image" />
        <div className={styles.content}>
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
        </div>
      </div>
      <SkeletonShimmer />
    </div>
  );
}
