import { SurveyResult } from "~/typing/sidekickTypes";

export enum PROActions {
  Fetch = "Fetch",
  Update = "Update"
}

type PROState = SurveyResult[];

type FetchAction = {
  type: PROActions.Fetch;
  payload: SurveyResult[];
};

type UpdateAction = {
  type: PROActions.Update;
  payload: {
    id: string;
    coachRatingValue: number;
    coachRatingDate: string;
  };
};

type Action = FetchAction | UpdateAction;

const getNewResult = (
  state: PROState,
  action: UpdateAction,
  index: number
): SurveyResult => {
  return {
    ...state[index],
    coachRatingValue: action.payload.coachRatingValue,
    coachRatingDate: action.payload.coachRatingDate
  };
};

export default function reducer(state: PROState, action: Action): PROState {
  switch (action.type) {
    case PROActions.Fetch: {
      return action.payload || [];
    }
    case PROActions.Update: {
      const stateCopy = [...state];
      const index = stateCopy.findIndex(
        (element) => element.id === action.payload.id
      );
      const newElement = getNewResult(state, action, index);
      stateCopy.splice(index, 1, newElement);
      return stateCopy;
    }
    default:
      return state;
  }
}
