import { Outlet } from "react-router-dom";

import PerformanceNav from "./PerformanceNav";

const PerformancePage = () => {
  return (
    <>
      <PerformanceNav />
      <Outlet />
    </>
  );
};

export default PerformancePage;
