import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";

const SkeletonMedicationPage = () => {
  return (
    <>
      <div className="" style={{ marginTop: "0px" }}></div>
      <div className="skeleton-wrapper">
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonShimmer />
      </div>
    </>
  );
};

export default SkeletonMedicationPage;
