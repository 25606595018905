import { useState } from "react";

import styles from "./ImportEntriesUsersPage.module.scss";
import ImportInfoModal from "./ImportInfoModal";
import ImportModal from "./ImportModal";
import { convertEntryStatusToText } from "./importUtils";
import useImportEntries from "./useImportEntries";

import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { TimeInMs } from "~/constants/measurements";
import { getDateAndTimeString } from "~/helpers/date/dateHelpers";
import useInterval from "~/hooks/useInterval";
import { t } from "~/i18n";
import { ImportEntry, ImportEntryStatus } from "~/typing/sidekickTypes";

const PAGE_SIZE = 10;

const ImportEntriesUsersPage = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedImportEntry, setSelectedImportEntry] = useState<
    ImportEntry | undefined
  >(undefined);

  const {
    importEntries,
    invalidate: invalidateImportEntries,
    isLoading
  } = useImportEntries();

  const updateImportEntries = () => {
    setTimeout(() => invalidateImportEntries(), 1500);
  };

  const setModal = (entry: ImportEntry) => {
    setSelectedImportEntry(entry);
    setShowInfoModal(true);
  };

  const columns: CustomListColumn<ImportEntry>[] = [
    {
      heading: {
        text: "ID",
        tooltip: {
          text: "Entry ID"
        }
      },
      render: ({ id }) => id
    },
    {
      heading: {
        text: "Name",
        tooltip: {
          text: "name"
        }
      },
      render: ({ name }) => name
    },
    {
      heading: {
        text: "User Type",
        tooltip: {
          text: "User Type"
        }
      },
      render: ({ externalUserTypeName }) => externalUserTypeName
    },
    {
      heading: {
        text: "Import date",
        tooltip: {
          text: "Import date"
        }
      },
      render: ({ createdDate }) => getDateAndTimeString(createdDate)
    },
    {
      heading: {
        text: "Status",
        tooltip: {
          text: "Status"
        }
      },
      render: ({ status }) => convertEntryStatusToText(status)
    }
  ];

  useInterval(() => {
    if (
      importEntries
        ?.slice(0, 10)
        ?.some(
          (entry) =>
            entry.status !== ImportEntryStatus.Finished &&
            entry.status !== ImportEntryStatus.Error
        )
    ) {
      invalidateImportEntries();
    }
  }, TimeInMs.Second * 5);

  return (
    <SentryErrorBoundary transactionName="ImportEntriesUsersPage">
      <Layout className={styles.layout}>
        <div>
          <button
            type="button"
            className={`btn btn-primary btn-sm ${styles.importBtn}`}
            onClick={() => setShowImportModal(true)}
          >
            {t("general.import")}
          </button>
          <CustomList<ImportEntry>
            entities={importEntries ?? []}
            isLoading={isLoading}
            tableClassName={styles.table}
            columns={columns}
            onRowClick={setModal}
            customListUniqueId="importEntries"
            pagination={{
              entitiesPerPage: PAGE_SIZE
            }}
          />

          {showInfoModal && (
            <ImportInfoModal
              importEntry={selectedImportEntry}
              close={() => setShowInfoModal(false)}
            />
          )}
          {showImportModal && (
            <ImportModal
              updateImportEntries={updateImportEntries}
              close={() => setShowImportModal(false)}
            />
          )}
        </div>
      </Layout>
    </SentryErrorBoundary>
  );
};

export default ImportEntriesUsersPage;
