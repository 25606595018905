import { useCoachesForIds } from "~/hooks/useApi/useCoachesForIds";
import {
  Coach,
  MessageThread,
  Program,
  UserDetails
} from "~/typing/sidekickTypes";

export const useUsersForAvatarInMessageThreads = ({
  program,
  userDetail,
  messageThreads,
  requireProgramBeforeFetching
}: {
  userDetail?: UserDetails;
  program?: Program;
  messageThreads?: MessageThread;
  requireProgramBeforeFetching?: boolean;
}) => {
  const users: {
    [key: number]: Coach;
  } = {};

  if (userDetail) {
    users[userDetail.user.userId] = userDetail?.user;
  }

  program?.coaches?.forEach((coach) => {
    const userId = coach.userId;
    if (!users[userId]) {
      users[userId] = coach;
    }
  });

  const userIdsToFetch = new Set(
    messageThreads?.messages
      ?.filter(
        (msg) =>
          !users[msg.senderUserId] ||
          (msg.senderUserId === userDetail?.user.userId &&
            !users[msg.recipientUserId])
      )
      ?.map((msg) =>
        msg.senderUserId === userDetail?.user.userId
          ? msg.recipientUserId
          : msg.senderUserId
      ) || []
  );

  const { coaches } = useCoachesForIds({
    userIds: Array.from(userIdsToFetch),
    disabled: requireProgramBeforeFetching && !program
  });

  coaches.forEach((coach) => {
    if (coach?.data) {
      users[coach.data.userId] = coach.data;
    }
  });

  return users;
};
