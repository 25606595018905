import { DateTime } from "luxon";
import { useContext } from "react";

import { ExportContext } from "./exportPreviewContext";
import styles from "./table.module.scss";

import { isBetweenDates } from "~/helpers/date/isBetweenDates";

type StripedTableProps = {
  data: any[];
  fieldName?: string;
  middleFieldName?: string;
  firstColumnName: string;
  lastColumnName: string;
  middleColumnName?: string;
};

export default function StripedTable({
  data,
  fieldName,
  middleFieldName,
  firstColumnName,
  lastColumnName,
  middleColumnName
}: StripedTableProps) {
  const { startDate, endDate } = useContext(ExportContext);
  if (!data) {
    return null;
  } else {
    return (
      <table className={`${styles.table} ${styles.striped}`}>
        <tbody>
          <tr>
            <th>{firstColumnName}</th>
            {middleColumnName && (
              <th className={styles.right}>{middleColumnName}</th>
            )}
            <th className={styles.right}>{lastColumnName}</th>
          </tr>
          {data.map((result, key) => {
            if (isBetweenDates(result.date, startDate, endDate)) {
              return (
                <tr key={key}>
                  <td>
                    {DateTime.fromISO(result.date).toLocaleString({
                      month: "long",
                      day: "2-digit",
                      year: "numeric"
                    })}{" "}
                  </td>
                  {middleFieldName && (
                    <td className={styles.right}>{result[middleFieldName]}</td>
                  )}
                  <td className={styles.right}>
                    {fieldName ? result[fieldName] : result.value}
                  </td>
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    );
  }
}
