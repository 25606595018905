import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

import CoachColumn from "./components/coachColumn/CoachColumn";
import TruncatedCellTextWithTooltip from "./components/truncatedCellTextWithTooltip/TruncatedCellTextWithTooltip";
import UserLayers from "./components/userLayers/UserLayers";
import UserStatusCount from "./components/userStatuses/UserStatusCount";
import UserStatuses from "./components/userStatuses/UserStatuses";
import {
  coachFilterOptions,
  colourFilterOptions,
  lineOfBusinessOptions,
  memberStateFilterOptions,
  messagesFilterOptions,
  planStateFilterOptions,
  pregnancyWeekOptions,
  proFilterOptions,
  riskLevelOptions,
  weekFilterOptions,
  layerFilterOptions,
  getCoachesInProgram,
  groupFilterOptions,
  groupNameFilterOptions
} from "./filters/filterOptions";
import {
  ActionsAwaitingColumn,
  LastMessageDateColumn,
  PROResultColumn,
  UserNameColumn
} from "./ProgramUsersPage.columns";
import {
  filterSingleUserByActivity,
  filterUserByCoach,
  filterUserByLayer,
  filterUserByMessages,
  filterUserByPlanStates,
  filterUserByPRO,
  filterUserByProStatus,
  filterUserByRetrigger,
  filterUsersByMemberState,
  getCoachFilterDefaultValue,
  getRelevantSurvey
} from "./ProgramUsersPage.helpers";
import styles from "./ProgramUsersPage.module.scss";

import {
  getRelationshipText,
  getRetriggerOptions,
  filterUserByGroupNumber,
  filterUserByGroupName
} from "../registration/helper";

import { checkForUserListChanges } from "~/api/requests/userRequests";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import {
  createCheckboxFilterData,
  createFilterData
} from "~/components/customList/CustomList.utils";
import BulkMessageModal from "~/components/messages/bulkMessage/BulkMessageModal";
import Pill from "~/components/pill/Pill";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import { DEFAULT_FILTER_VALUE } from "~/constants/filters";
import getDiseaseNameOptions from "~/constants/getDiseaseNameOptions";
import { TimeInMs } from "~/constants/measurements";
import { UNDEFINED_OPTION } from "~/constants/options";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import { removeObjectFromArray } from "~/helpers/array/arrayHelpers";
import {
  getPregnancyWeekFromDueDate,
  humanizedTimeSince
} from "~/helpers/date/dateHelpers";
import { getLatestUnratedSurvey } from "~/helpers/getLatestSurvey";
import {
  isCancerProgram,
  programHasLayersForDisplay,
  programHasBehaviour,
  isMedicareProgram
} from "~/helpers/program/programHelpers";
import { capitalizeWord } from "~/helpers/string/stringHelpers";
import { displayActionToast } from "~/helpers/toast/displayToast";
import {
  getRetriggeredText,
  hasBeenDischargedFromProgram,
  hasFinishedProgram,
  hasQuitProgram,
  isScaleInactive,
  userHasActionAwaiting
} from "~/helpers/user/userHelpers";
import { getActivityFilterOptions } from "~/helpers/userLists/filterHelper";
import { QueryKeyNames } from "~/hooks/useApi/queryKeysFactory";
import useProgram from "~/hooks/useApi/useProgram";
import { useProgramSpecialties } from "~/hooks/useApi/useProgramSpecialties";
import useProgramUsers from "~/hooks/useApi/useProgramUsers";
import useSurveys from "~/hooks/useApi/useSurveys";
import useExternalUserGroups from "~/hooks/useApi/useUserGroups";
import useListFilters from "~/hooks/useListFilters";
import useUser from "~/hooks/useUser";
import useWindowSize from "~/hooks/useWindowSize";
import { setListFilter } from "~/state/customList/customListStateHelpers";
import colors from "~/styles/colors";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  SearchData,
  CustomListFilterData,
  ActionData,
  CoachOptionUserList
} from "~/typing/carePortalTypes";
import { CoachUser } from "~/typing/sidekickTypes";

export type CoachUserRendered = CoachUser & {
  user: {
    selectedProDate?: Date;
    selectedProResult?: number;
    selectedSurveyId?: string;
    selectedProColor?: string;
    latestUnratedSurveyId?: string;
    shouldShowPRONotification?: boolean;
    shouldShowFoodLogNotification?: boolean;
    shouldShowMessageNotification?: boolean;
    shouldShowSMSNotification?: boolean;
    shouldShowInactiveScaleNotification?: boolean;
  };
};

enum FilterNames {
  Activity = "activityFilter",
  PRO = "proFilter",
  PROStatus = "proStatusFilter",
  Disease = "diseaseName",
  NewAction = "newActionFilter",
  Coach = "coachFilter",
  Week = "weekFilter",
  MemberState = "memberStateFilter",
  PlanState = "planStateFilter",
  Layer = "layerFilter",
  LOB = "lineOfBusinessFilter",
  Messages = "messagesFilter",
  PregnancyWeek = "pregnancyWeekFilter",
  RiskLevel = "riskLevelFilter",
  Retrigger = "retriggerFilter",
  GroupNumber = "groupNumberFilter",
  GroupName = "groupNameFilter"
}

const ProgramUsersPage = () => {
  const { screenWidth } = useWindowSize();
  const {
    trackAssignedToFilterSelected,
    trackProFilterSelected,
    trackProResultFilterSelected,
    trackUserStatusFilterSelected,
    trackWeekFilterSelected,
    trackLobFilterSelected,
    trackPlanStateFilterSelected,
    trackMemberStateFilterSelected,
    trackDiseaseTypesFilterSelected,
    trackLayerFilterSelected,
    trackMessageFilterSelected,
    trackUserListViewOpened
  } = useAmplitudeTracking();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { user: loggedInUser } = useUser();
  const { groups } = useExternalUserGroups();

  const [hasChanges, setHasChanges] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(DateTime.utc());
  const [showSendBulkMessage, setShowSendBulkMessage] = useState(false);
  const [coachOptions, setCoachOptions] = useState<CoachOptionUserList[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<CoachUserRendered[]>([]);

  const { program_id = "", locale = "" } = useParams<{
    program_id: string;
    locale: string;
  }>();
  const customListUniqueId = `user-list-${program_id}-${locale}`;
  const { surveys, isLoading: surveysLoading } = useSurveys(program_id, locale);
  const { filters: programFilters } = useListFilters(customListUniqueId);
  const {
    programSpecialties,
    isLoading: programSpecialtiesLoading
  } = useProgramSpecialties({
    programId: program_id,
    locale
  });
  const { program, isLoading: programIsLoading } = useProgram({
    programCatalogItemId: program_id,
    locale
  });

  const {
    users,
    isLoading: usersLoading,
    invalidate: invalidateUsers
  } = useProgramUsers({
    programId: program_id,
    locale
  });

  const isSmallerThanDesktop = screenWidth ? screenWidth < 1024 : false;

  const usingBodyTraceScale = programHasBehaviour(
    program,
    ProgramBehaviour.UsingBodyTraceScale
  );
  const shouldShowLayers = programHasLayersForDisplay(program);

  let changesInterval;

  const checkForChanges = async () => {
    if (hasChanges || !document.hasFocus()) {
      return;
    }

    const userListHasChanged = await checkForUserListChanges({
      programId: program_id,
      locale,
      lastUpdate
    });

    if (userListHasChanged) {
      setHasChanges(true);
      displayActionToast({
        message: t(
          "programUserList.hasChanges",
          "The list has updated. Refresh to see latest data"
        ),
        onClick: handleRefreshUsers,
        buttonText: t("programUserList.refreshList", "Refresh list"),
        id: "user-list-changed"
      });
    }
  };

  const handleRefreshUsers = () => {
    setLastUpdate(DateTime.utc());
    clearInterval(changesInterval);
    queryClient.invalidateQueries({
      queryKey: [QueryKeyNames.lastWeekPoints]
    });
    setHasChanges(false);
    toast.remove();
    invalidateUsers();
  };

  const handleSetActivityFilter = (filterValues: string[]) => {
    trackUserStatusFilterSelected(filterValues);
    setListFilter(customListUniqueId, FilterNames.Activity, filterValues);
  };

  const handleSetProStatusFilter = (filterValue: string) => {
    trackProResultFilterSelected(filterValue);
    setListFilter(customListUniqueId, FilterNames.PROStatus, filterValue);
  };

  const renderColourOption = (option) => (
    <div className={styles.colourOption}>
      {option.colour && (
        <div
          className={styles.colourBubble}
          style={{ background: option.colour }}
        ></div>
      )}
      {option.text}
    </div>
  );

  const filterSelectData: CustomListFilterData<CoachUserRendered>[] = _.compact(
    [
      createCheckboxFilterData({
        onChange: trackUserStatusFilterSelected,
        key: FilterNames.Activity,
        options: getActivityFilterOptions(program),
        renderOption: (option) => option.text,
        placeholder: t("select.noFilterSelected"),
        label: t("programUserList.userStatus"),
        defaultValue: program
          ? getActivityFilterOptions(program).map(
              (option) => option.value as string
            )
          : undefined,
        filter: ({ entity: user, filterValue }) =>
          filterSingleUserByActivity(user, program, filterValue),
        dataTestId: "activity-filter"
      }),
      createFilterData({
        key: FilterNames.PregnancyWeek,
        options: pregnancyWeekOptions(users),
        renderOption: (option) => option.text,
        placeholder: t("select.noFilterSelected"),
        label: t("programUserList.pregnancyWeek"),
        conditional: program?.useUserListColumnCurrentPregnancyWeek ?? false,
        defaultValue: DEFAULT_FILTER_VALUE,
        filter: ({ entity: user, filterValue }) =>
          getPregnancyWeekFromDueDate(user.pregnancyDueDate) ===
          parseInt(filterValue as string),
        dataTestId: "pregnancy-week-filter"
      }),
      createFilterData({
        key: FilterNames.RiskLevel,
        options: riskLevelOptions(users),
        renderOption: (option) => option.text,
        placeholder: t("select.noFilterSelected"),
        label: t("programUserList.riskLevel"),
        conditional: program?.useUserListColumnRiskLevel ?? false,
        defaultValue: DEFAULT_FILTER_VALUE,
        filter: ({ entity: user, filterValue }) =>
          user.riskLevel?.toUpperCase() === filterValue,
        dataTestId: "risk-level-filter"
      }),
      createFilterData({
        key: FilterNames.PRO,
        options: proFilterOptions(surveys),
        renderOption: (option) => option.text,
        label: t("programUserList.pro"),
        defaultValue: DEFAULT_FILTER_VALUE,
        onChange: (value) => {
          trackProFilterSelected(value);
          handleSetProStatusFilter(DEFAULT_FILTER_VALUE);
        },
        filter: ({ entity: user, filterValue }) =>
          filterUserByPRO(user, surveys, filterValue),
        dataTestId: "pro-filter"
      }),
      createFilterData({
        onChange: trackProFilterSelected,
        key: FilterNames.PROStatus,
        options: colourFilterOptions(
          surveys,
          programFilters[FilterNames.PRO]?.toString()
        ),
        renderOption: renderColourOption,
        label: t("programUserList.proResult"),
        defaultValue: DEFAULT_FILTER_VALUE,
        filter: ({ entity: user, filterValue }) =>
          filterUserByProStatus({
            user,
            surveys,
            proStatusFilter: filterValue,
            proFilter: programFilters[FilterNames.PRO]?.toString()
          }),
        dataTestId: "pro-status-filter",
        dependencies: {
          dependencies: [
            {
              title: "PRO",
              visible: programFilters[FilterNames.PRO] !== DEFAULT_FILTER_VALUE
            }
          ]
        }
      }),
      createFilterData({
        onChange: trackDiseaseTypesFilterSelected,
        options: getDiseaseNameOptions(),
        renderOption: (option) => option.text,
        key: FilterNames.Disease,
        conditional: isCancerProgram(program),
        label: t("programUserList.diseaseName"),
        defaultValue: DEFAULT_FILTER_VALUE,
        filter: ({ entity: user, filterValue }) =>
          user.diseaseName === filterValue,
        dataTestId: "disease-filter"
      }),
      createCheckboxFilterData({
        onChange: trackMemberStateFilterSelected,
        options: memberStateFilterOptions(users),
        key: FilterNames.MemberState,
        conditional: config.isAnthem,
        label: t("programUserList.memberState"),
        includeNoValueOption: true,
        defaultValue: users.length
          ? memberStateFilterOptions(users)
              .map((state) => state.value)
              .concat([UNDEFINED_OPTION])
          : undefined,
        filter: ({ entity: user, filterValue }) =>
          filterUsersByMemberState(user, filterValue),
        dataTestId: "member-state-filter"
      }),
      createCheckboxFilterData({
        onChange: trackPlanStateFilterSelected,
        options: planStateFilterOptions(users),
        key: FilterNames.PlanState,
        conditional: config.isAnthem,
        label: t("programUserList.planState"),
        includeNoValueOption: true,
        defaultValue: users.length
          ? planStateFilterOptions(users)
              .map((state) => state.value)
              .concat([UNDEFINED_OPTION])
          : undefined,
        filter: ({ entity: user, filterValue }) =>
          filterUserByPlanStates(user, filterValue),
        dataTestId: "plan-state-filter"
      }),
      createFilterData({
        onChange: trackLobFilterSelected,
        options: lineOfBusinessOptions(users),
        key: FilterNames.LOB,
        conditional: config.isAnthem,
        renderOption: (option) => option?.text,
        label: t("programUserList.lob"),
        includeNoValueOption: true,
        defaultValue: DEFAULT_FILTER_VALUE,
        filter: ({ entity: user, filterValue }) =>
          user.lineOfBusiness === filterValue,
        dataTestId: "lob-filter"
      }),
      createCheckboxFilterData({
        onChange: trackLayerFilterSelected,
        options: layerFilterOptions(users),
        key: FilterNames.Layer,
        conditional: shouldShowLayers,
        label: t("programUserList.layer"),
        includeNoValueOption: true,

        defaultValue: users.length
          ? layerFilterOptions(users)
              .map((option) => option.value)
              .concat([UNDEFINED_OPTION])
          : undefined,
        filter: ({ entity: user, filterValue }) =>
          filterUserByLayer(user, filterValue),
        dataTestId: "layer-filter"
      }),
      createFilterData({
        onChange: trackWeekFilterSelected,
        options: weekFilterOptions(users),
        key: FilterNames.Week,
        renderOption: (option) => option.text,
        label: t("time.week"),
        defaultValue: DEFAULT_FILTER_VALUE,
        filter: ({ entity: user, filterValue }) =>
          user.currentSlot?.toString() === filterValue,
        dataTestId: "week-filter"
      }),
      createFilterData({
        options: messagesFilterOptions(),
        key: FilterNames.Messages,
        renderOption: (option) => option.text,
        label: t("general.messages"),
        defaultValue: DEFAULT_FILTER_VALUE,
        onChange: (value) => {
          trackMessageFilterSelected(value);
        },
        filter: ({ entity: user, filterValue }) =>
          filterUserByMessages(user, filterValue),
        dataTestId: "messages-filter"
      }),
      createFilterData({
        options: getRetriggerOptions(true),
        key: FilterNames.Retrigger,
        conditional: programHasBehaviour(
          program,
          ProgramBehaviour.RetriggeringEnabled
        ),
        renderOption: (option) => option.text,
        label: t("retrigger.retrigger"),
        defaultValue: DEFAULT_FILTER_VALUE,
        filter: ({ entity: user, filterValue }) =>
          filterUserByRetrigger(user, filterValue, program),
        dataTestId: "retrigger-filter"
      }),
      createFilterData({
        conditional: coachOptions.length > 0,
        onChange: trackAssignedToFilterSelected,
        key: FilterNames.Coach,
        options: coachFilterOptions(
          coachOptions,
          users,
          Boolean(programSpecialties?.length)
        ),
        renderOption: (option) => option.text,
        label: t("general.assignedTo"),
        dataTestId: "coach-filter",
        defaultValue: getCoachFilterDefaultValue(
          coachOptions,
          users,
          programSpecialties
        ),
        filter: ({ entity: user, filterValue }) =>
          filterUserByCoach(user, programSpecialties, filterValue)
      }),
      createCheckboxFilterData({
        //Do we want an amplitude event for this?
        key: FilterNames.GroupNumber,
        options: groupFilterOptions(groups || []),
        renderOption: (option) => option.text,
        placeholder: "No group selected",
        label: t("programUserList.groupNumber"),
        defaultValue: undefined,
        filter: ({ entity: user, filterValue }) =>
          filterUserByGroupNumber(user?.groupNumber, filterValue),
        dataTestId: "group-number-filter",
        conditional: isMedicareProgram(program),
        allowSearch: true
      }),
      createCheckboxFilterData({
        //Do we want an amplitude event for this?
        key: FilterNames.GroupName,
        options: groupNameFilterOptions(groups || []),
        renderOption: (option) => option.text,
        placeholder: "No group selected",
        label: t("programUserList.groupName"),
        defaultValue: undefined,
        filter: ({ entity: user, filterValue }) =>
          filterUserByGroupName(user?.groupName, filterValue),
        dataTestId: "group-name-filter",
        conditional: isMedicareProgram(program),
        allowSearch: true
      })
    ]
  );

  const actionData: ActionData[] = [
    {
      onClick: () => setShowSendBulkMessage(true),
      text: t("general.sendBulkMessage", "Bulk message "),
      className: `btn-secondary ${styles.bulkMessage}`,
      conditional: users?.length > 0
    }
  ];

  const searchData: SearchData = {
    placeholder: t("programUserList.search", "Search user name..."),
    data: [{ key: ["user", "fullName"] }, { key: ["id"] }]
  };

  const filterUsersByAction = (users: CoachUser[]) => {
    return users.filter((user) => userHasActionAwaiting(user));
  };

  const processRenderedUsers = (users: CoachUserRendered[]) => {
    const shouldShowFoodLogNotification = users.some(
      (user) => user.hasMealLogActions
    );
    const shouldShowMessageNotification = users.some(
      (user) => user.hasMessageActions || user.hasNonRecipientsMessageActions
    );
    const shouldShowSMSNotification = users.some((user) => user.unseenSms);
    const shouldShowPRONotification = users.some(
      (user) => user.hasSurveyActions
    );
    const shouldShowInactiveScaleNotification = users.some((user) =>
      isScaleInactive(user)
    );

    const finalUsersToRender = users.map((user) => ({
      ...user,
      user: {
        ...user.user,
        shouldShowPRONotification,
        shouldShowSMSNotification,
        shouldShowMessageNotification,
        shouldShowFoodLogNotification,
        shouldShowInactiveScaleNotification
      }
    }));

    return finalUsersToRender;
  };

  const columns: CustomListColumn<CoachUserRendered>[] = [
    {
      heading: {
        text: t("programUserList.userName", "User Name"),
        sortable: {
          sortBy: ["user", "fullName"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.userName")
        }
      },
      render: (user) => (
        <UserNameColumn user={user} maxPoints={600} program={program} />
      )
    },
    {
      heading: {
        text: t("programUserList.pregnancyWeek", "Pregnancy Week"),
        sortable: {
          sortBy: ["pregnancyDueDate"]
        },
        tooltip: {
          text: t("programUserList.pregnancyWeek")
        }
      },
      conditional: program?.useUserListColumnCurrentPregnancyWeek,
      render: (user) => (
        <p
          title={
            user.pregnancyDueDate
              ? `${t("time.week")} ${user.pregnancyDueDate}`
              : ""
          }
        >
          {user.pregnancyDueDate
            ? `${t("time.week")} ${getPregnancyWeekFromDueDate(
                user.pregnancyDueDate
              )}`
            : null}
        </p>
      )
    },
    {
      heading: {
        text: t("programUserList.riskLevel", "Risk Level"),
        sortable: {
          sortBy: ["riskLevel"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.riskLevel")
        }
      },
      conditional: config.isAnthem,

      render: (user) => (
        <Pill
          className={styles.pill}
          title={capitalizeWord(user.riskLevel)}
          fontColor={
            user.riskLevel?.toLowerCase() === "high"
              ? colors.white
              : colors.black
          }
          backgroundColor={
            user.riskLevel?.toLowerCase() === "high"
              ? colors.red100
              : colors.mustardYellow100
          }
        />
      )
    },
    {
      heading: {
        text: t("programUserList.memberState", "Member State"),
        sortable: {
          sortBy: ["memberState"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.memberState")
        }
      },
      conditional: config.isAnthem,
      render: (user) => <p title={user.memberState ?? ""}>{user.memberState}</p>
    },
    {
      heading: {
        text: t("programUserList.planState", "Plan State"),
        sortable: {
          sortBy: ["planState"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.planState")
        }
      },
      conditional: config.isAnthem,
      render: (user) => <p title={user.planState ?? ""}>{user.planState}</p>
    },
    {
      heading: {
        text: t("programUserList.lob", "LOB"),
        sortable: {
          sortBy: ["lineOfBusiness"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.lob")
        }
      },
      conditional: config.isAnthem,
      render: (user) => (
        <p title={user.lineOfBusiness ?? ""}>{user.lineOfBusiness}</p>
      )
    },
    {
      heading: {
        text: t("programUserList.groupNumber", "Group Number"),
        hideByDefault: true,
        sortable: {
          sortBy: ["groupNumber"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.groupNumber")
        }
      },
      conditional: isMedicareProgram(program),
      render: (user) => <p title={user.groupNumber ?? ""}>{user.groupNumber}</p>
    },
    {
      heading: {
        text: t("programUserList.groupName", "Group Name"),
        hideByDefault: true,
        sortable: {
          sortBy: ["groupName"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.groupName")
        }
      },
      conditional: isMedicareProgram(program),
      render: (user) => (
        <TruncatedCellTextWithTooltip cellText={user.groupName ?? ""} />
      )
    },
    {
      heading: {
        text: t("programUserList.relationship"),
        tooltip: {
          text: t("programUserList.headingTooltip.relationship")
        },
        hideByDefault: true,
        sortable: {
          sortBy: ["relationship"]
        }
      },
      conditional: config.isAnthem,
      render: (user) => (
        <p title={getRelationshipText(user.relationship ?? "")}>
          {getRelationshipText(user.relationship ?? "")}
        </p>
      )
    },
    {
      heading: {
        text: t("retrigger.retrigger", "Retrigger"),
        hideByDefault: true,
        tooltip: {
          text: t("retrigger.retrigger")
        }
      },
      conditional: programHasBehaviour(
        program,
        ProgramBehaviour.RetriggeringEnabled
      ),
      render: (user) => (
        <p
          title={getRetriggeredText(
            user.eligibleForRestart,
            user.eligibleForRestartDate,
            program?.retriggeredStatusDurationSeconds
          )}
        >
          {getRetriggeredText(
            user.eligibleForRestart,
            user.eligibleForRestartDate,
            program?.retriggeredStatusDurationSeconds
          )}
        </p>
      )
    },
    {
      heading: {
        text: t("retrigger.lastRetriggerDate", "Last Retrigger Date"),
        sortable: {
          reverse: false,
          sortBy: ["user", "eligibleForRestartDate"]
        },
        tooltip: {
          text: t("retrigger.lastRetriggerDate")
        },
        hideByDefault: true
      },

      conditional: programHasBehaviour(
        program,
        ProgramBehaviour.RetriggeringEnabled
      ),
      render: (user) =>
        user.eligibleForRestartDate && (
          <p>
            {DateTime.fromISO(user.eligibleForRestartDate).toFormat(
              "MM/dd/yyyy"
            )}
          </p>
        )
    },
    {
      heading: {
        text: t("programUserList.userStatus", "User Status"),
        tooltip: {
          text: t("programUserList.headingTooltip.userStatus")
        }
      },
      render: (user) => (
        <UserStatuses
          user={user}
          program={program}
          chosenStatuses={programFilters[FilterNames.Activity] as string[]}
        />
      )
    },
    {
      heading: {
        text: t("programUserList.programWeek", "Program Week"),
        sortable: {
          sortBy: ["currentSlot"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.programWeek")
        },
        hideByDefault: config.isAnthem
      },
      render: (user) => (
        <p>{user.currentSlot && `${t("time.week")} ${user.currentSlot}`}</p>
      )
    },
    {
      heading: {
        text: t("programUserList.cm", "CM"),
        sortable: {
          sortBy: ["assignedCoachUserId"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.cm")
        }
      },
      render: (user) =>
        program ? <CoachColumn user={user} program={program} /> : null
    },
    {
      heading: {
        text: isSmallerThanDesktop
          ? t("programUserList.message", "Message")
          : t("programUserList.lastCmMessage", "Last CM Message"),
        sortable: {
          reverse: true,
          sortBy: ["lastMessageDate"]
        },
        tooltip: {
          text: t("programUserList.lastCmMessage")
        }
      },
      render: (user) => (
        <LastMessageDateColumn
          user={user}
          isSmallerThanDesktop={isSmallerThanDesktop}
        />
      )
    },
    {
      heading: {
        text: t("programUserList.layer"),
        tooltip: {
          text: t("programUserList.layer")
        }
      },
      conditional: (program?.layersForDisplay?.length ?? 0) > 0,
      render: (user) =>
        user.coachUserLayersForDisplay && (
          <UserLayers layers={user.coachUserLayersForDisplay} />
        )
    },
    {
      heading: {
        text: t("programUserList.proResult", "PRO Result"),
        sortable: {
          sortBy: ["user", "selectedProResult"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.proResult")
        }
      },
      render: (user) => <PROResultColumn user={user} surveys={surveys} />
    },
    {
      heading: {
        text: t("programUserList.proSubmitted", "PRO Submitted"),
        sortable: {
          reverse: true,
          sortBy: ["user", "selectedProDate"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.proSubmitted")
        },
        hideByDefault: config.isAnthem
      },
      render: (user) =>
        user.user.selectedProDate
          ? humanizedTimeSince(
              user.user.selectedProDate.toISOString(),
              Boolean(isSmallerThanDesktop)
            )
          : null
    },
    {
      heading: {
        text: isSmallerThanDesktop
          ? t("programUserList.actions", "Actions")
          : t("programUserList.actionsAwaiting", "Actions awaiting"),
        sortable: {
          reverse: true,
          sortBy: ["oldestPendingAction"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.actionsAwaiting")
        }
      },
      render: (user: CoachUserRendered) => (
        <ActionsAwaitingColumn
          user={user}
          surveys={surveys}
          usingBodyTraceScale={Boolean(usingBodyTraceScale)}
        />
      )
    }
  ];

  useEffect(() => {
    if (!program) return;
    trackUserListViewOpened();
  }, [program?.programCatalogItemId]);

  useEffect(() => {
    if (hasChanges) return;

    if (changesInterval) {
      clearInterval(changesInterval);
    } else {
      changesInterval = setInterval(checkForChanges, TimeInMs.Minute * 5);
    }

    return () => clearInterval(changesInterval);
  }, [program, hasChanges]);

  useEffect(() => {
    if (
      programSpecialtiesLoading ||
      program?.behaviour === undefined ||
      !users ||
      users.length === 0
    ) {
      return;
    }

    const coaches = getCoachesInProgram(
      program,
      loggedInUser,
      programSpecialties
    );

    setCoachOptions(coaches);
  }, [programSpecialties, , programSpecialtiesLoading, program, users]);

  const getUsersToRender = (): CoachUserRendered[] => {
    if (surveysLoading || usersLoading || programIsLoading) return [];
    return (
      users.map((user) => {
        const selectedPro = getRelevantSurvey({
          latestSurveyResults: user.latestSurveyResults,
          surveys,
          proFilter: programFilters[FilterNames.PRO]?.toString()
        });

        const selectedSurvey = surveys.find(
          (survey) => survey.surveyName === selectedPro?.surveyName
        );

        const latestUnratedPRO = selectedSurvey
          ? getLatestUnratedSurvey(user.latestSurveyResults, surveys)
          : undefined;

        const latestUnratedSurvey = surveys?.find(
          (survey) => survey.surveyName === latestUnratedPRO?.surveyName
        );

        return {
          ...user,
          user: {
            ...user?.user,
            selectedSurveyId: selectedSurvey?.id,
            selectedProResult: selectedPro?.result,
            selectedProColor: selectedPro?.answerSeverity?.colour,
            selectedProDate: selectedPro?.date
              ? new Date(selectedPro.date)
              : undefined,
            latestUnratedSurveyId: latestUnratedSurvey?.id
          }
        };
      }) ?? []
    );
  };

  return (
    <div className={styles.ProgramUserList} data-testid="userList">
      <SentryErrorBoundary transactionName="ProgramUsersPage">
        <Outlet />
        <CustomList
          columns={columns}
          searchData={searchData}
          filterSelectData={filterSelectData}
          entities={getUsersToRender()}
          isLoading={surveysLoading || usersLoading || programIsLoading}
          noEntitiesText={t("programUserList.noUsers")}
          defaultSortingData={{
            sortBy: ["oldestPendingAction"]
          }}
          actionData={actionData}
          putEntityAtBottom={(user) =>
            hasFinishedProgram(
              user.programStatus,
              user.userProgramStatusReason
            ) ||
            hasQuitProgram(user?.programStatus, user.userProgramStatusReason) ||
            hasBeenDischargedFromProgram(
              user?.programStatus,
              user.userProgramStatusReason
            )
          }
          renderBetweenFiltersAndList={(users) => (
            <UserStatusCount
              statuses={programFilters[FilterNames.Activity] as string[]}
              program={program}
              users={users}
              onStatusRemove={(status) => {
                handleSetActivityFilter(
                  removeObjectFromArray(
                    [...(programFilters[FilterNames.Activity] as string[])],
                    status
                  )
                );
              }}
            />
          )}
          customListUniqueId={customListUniqueId}
          pagination={{ entitiesPerPage: 10 }}
          processRenderedEntities={processRenderedUsers}
          processFilteredEntities={(entities) => setFilteredUsers(entities)}
          dataTestId="custom-list-users-table"
        />
      </SentryErrorBoundary>
      {showSendBulkMessage && users.length > 0 && (
        <BulkMessageModal
          onClose={() => setShowSendBulkMessage(false)}
          programId={program_id}
          locale={locale}
          users={users}
          filterUsersByProStatus={(users, value) =>
            users.filter((user) =>
              filterUserByProStatus({
                user,
                surveys,
                proFilter: programFilters[FilterNames.PRO] as string,
                proStatusFilter: value
              })
            )
          }
          filterUsersByNewAction={filterUsersByAction}
          filterUsersByActivity={(users, filters) =>
            users.filter((user) =>
              filterSingleUserByActivity(user, program, filters)
            )
          }
          filterUsersByCoach={(users, value) =>
            users.filter((user) =>
              filterUserByCoach(user, programSpecialties, value)
            )
          }
          filterSelectData={filterSelectData}
          previousSelectedUsers={filteredUsers}
        />
      )}
    </div>
  );
};

export default ProgramUsersPage;
