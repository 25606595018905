import { DateTime } from "luxon";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./DateNavigator.module.scss";

import ChevronRightThick from "~/assets/chevron-right-thick.svg";
import ChevronRight from "~/assets/chevron-right.svg";
import getWeekDays from "~/helpers/date/getWeekDays";

type DateNavigatorProps = {
  startDateISO?: string | null;
  endDateISO?: string | null;
  onDateChange?: (props: { startDate: DateTime; endDate: DateTime }) => void;
  renderDayItems: (props: { date: DateTime; data: any[] }) => ReactNode;
};

const DateNavigator = ({
  startDateISO,
  endDateISO,
  onDateChange,
  renderDayItems
}: DateNavigatorProps) => {
  const { t } = useTranslation();
  const today = DateTime.local();
  const start = today.minus({ days: 7 }); // default to this week
  const end = today; // default to this week
  const [startDate, setStartDate] = useState(
    startDateISO ? DateTime.fromISO(startDateISO) : start
  );
  const [endDate, setEndDate] = useState(
    endDateISO ? DateTime.fromISO(endDateISO) : end
  );
  const numDays = Math.floor(endDate.diff(startDate, "days").days);

  useEffect(() => {
    setStartDate(startDateISO ? DateTime.fromISO(startDateISO) : start);
  }, [startDateISO]);

  useEffect(() => {
    setEndDate(endDateISO ? DateTime.fromISO(endDateISO) : end);
  }, [endDateISO]);

  const changeDate = (daysToAdd) => {
    setStartDate(startDate.plus({ days: daysToAdd }));
    setEndDate(endDate.plus({ days: daysToAdd }));
    if (onDateChange !== undefined) {
      onDateChange({
        startDate: startDate.plus({ days: daysToAdd }),
        endDate: endDate.plus({ days: daysToAdd })
      });
    }
  };

  const handleChangeToToday = () => {
    setStartDate(start);
    setEndDate(end);
    if (onDateChange !== undefined) {
      onDateChange({
        startDate: start,
        endDate: end
      });
    }
  };

  const weekDays = getWeekDays(endDate, numDays);
  const isToday = (date) => {
    return today.toISODate() === DateTime.fromISO(date).toISODate();
  };
  const isFuture = (date) => {
    return (
      (today.toISODate() ?? "") < (DateTime.fromISO(date).toISODate() ?? "")
    );
  };
  const dayClasses = (date) => {
    if (isFuture(date)) {
      return styles.dayColorFuture;
    }
    if (isToday(date)) {
      return styles.dayColorToday;
    }
    return "";
  };

  return (
    <div className={styles.DateNavigator}>
      <div className={styles.header}>
        <div className={styles.month}>
          {startDate.toLocaleString({
            month: "short",
            year: "numeric"
          })}
        </div>
        <button
          type="button"
          onClick={handleChangeToToday}
          className={styles.today}
        >
          {t("time.today")}
          <img src={ChevronRightThick} />
        </button>
      </div>
      <div className={styles.week}>
        <img
          className={`${styles.arrow} ${styles.arrowLeft}`}
          src={ChevronRight}
          onClick={() => changeDate(-1)}
        />

        {weekDays?.map((day, index) => (
          <div
            key={`${day.date.millisecond}-${index}`}
            className={`${styles.day} ${styles[`day${index}`]}`}
          >
            <div className={`${styles.dayName} ${dayClasses(day.date)}`}>
              {day.date.weekdayShort}
            </div>
            <div className={`${styles.dayNumber} ${dayClasses(day.date)}`}>
              {day.date.day}
            </div>
            <div
              key={`day-item-${day.date.millisecond}-${index}`}
              className={`${styles.dayItems} ${
                index !== weekDays.length - 1 ? styles.dayBorder : ""
              }`}
              data-testid="datenavigator-day-items"
            >
              {renderDayItems ? renderDayItems(day) : undefined}
            </div>
          </div>
        ))}
        <img
          className={`${styles.arrow} ${styles.arrowRight}`}
          src={ChevronRight}
          onClick={() => changeDate(1)}
        />
      </div>
    </div>
  );
};

export default DateNavigator;
