import ArteryIcon from "~/assets/artery.svg";
import BloodDropIcon from "~/assets/blood-drop.svg";
import ScaleIcon from "~/assets/diet-scale.svg";
import HeartPulseIcon from "~/assets/heart-pulse.svg";
import BloodTestIcon from "~/assets/laboratory-test-blood-finger.svg";
import BmiIcon from "~/assets/single-neutral-shield.svg";
import round from "~/helpers/number/round";
import bloodSugarToImperial from "~/helpers/units/bloodSugarToImperial";
import cholesterolToImperial from "~/helpers/units/cholesterolToImperial";
import kgToLbs from "~/helpers/units/kgToLbs";
import { t } from "~/i18n";
import { UserDetails } from "~/typing/sidekickTypes";

export enum HealthCardType {
  BloodPressue = "BloodPressure",
  BloodSugar = "BloodSugar",
  Weight = "Weight",
  HeartRate = "HeartRate",
  Cholesterol = "Cholesterol"
}

type HealthCardData = {
  title: string;
  value?: string | number;
  date?: string;
  unit?: string;
  iconSrc: string;
  onClickParameter?: HealthCardType;
  status?: {
    text: string;
    color: string;
  };
};

export const getHealthCardData = (
  useMetric: boolean,
  details?: UserDetails
): HealthCardData[] => {
  if (!details) return [];

  const { weightScore, bloodPressure, cholesterol, bmi, bloodSugar } = details;

  const standardizedBloodPressureStatus = () => {
    //TODO: Use standardizedRating once it becomes available in the backend response, replacing the "old school" rating
    if (bloodPressure && bloodPressure.systolic && bloodPressure.diastolic) {
      if (bloodPressure.systolic > 180 || bloodPressure.diastolic > 120) {
        return {
          text: t("user.health.bloodPressure.hypertensiveCrisis"),
          color: "pink"
        };
      } else if (
        (bloodPressure.systolic >= 140 && bloodPressure.systolic <= 180) ||
        (bloodPressure.diastolic >= 90 && bloodPressure.diastolic <= 120)
      ) {
        return {
          text: t("user.health.bloodPressure.stage2Hypertension"),
          color: "pink"
        };
      } else if (
        (bloodPressure.systolic >= 130 && bloodPressure.systolic < 140) ||
        (bloodPressure.diastolic >= 80 && bloodPressure.diastolic < 90)
      ) {
        return {
          text: t("user.health.bloodPressure.stage1Hypertension"),
          color: "pink"
        };
      } else if (
        bloodPressure.systolic >= 120 &&
        bloodPressure.systolic < 130 &&
        bloodPressure.diastolic < 80
      ) {
        return {
          text: t("user.health.bloodPressure.elevatedBp"),
          color: "yellow"
        };
      } else {
        return {
          text: t("user.health.bloodPressure.normalBp"),
          color: "green"
        };
      }
    } else {
      return undefined;
    }
  };

  const cholesterolStatus = () => {
    switch (cholesterol?.rating) {
      case 1:
        return { text: t("user.health.status.good", "Good"), color: "green" };
      case 2:
        return {
          text: t("user.health.status.borderline", "Borderline"),
          color: "yellow"
        };
      case 3:
        return { text: t("user.health.status.high", "High"), color: "pink" };
      default:
        return undefined;
    }
  };

  const bloodSugarStatus = () => {
    switch (bloodSugar?.rating) {
      case 1:
        return {
          text: t("user.health.status.normal", "Normal"),
          color: "green"
        };
      case 2:
        return {
          text: t("user.health.status.prediabetes", "Prediabetes"),
          color: "yellow"
        };
      case 3:
        return {
          text: t("user.health.status.diabetes", "Diabetes"),
          color: "pink"
        };
    }
  };

  return [
    {
      title: t("user.health.weight", "Weight"),
      value: useMetric
        ? round(weightScore?.weight, 1)
        : kgToLbs(weightScore?.weight ?? 0),
      date: weightScore?.date,
      unit: useMetric ? "kg" : "lbs",
      iconSrc: ScaleIcon,
      onClickParameter: HealthCardType.Weight
    },
    {
      title: t("user.health.bloodPressure.bloodPressure", "Blood Pressure"),
      value: bloodPressure
        ? `${round(bloodPressure?.systolic)}/${round(bloodPressure?.diastolic)}`
        : undefined,
      date: bloodPressure?.date,
      unit: `${t("user.health.bloodPressure.sysDia")}`,
      iconSrc: BloodDropIcon,
      status: standardizedBloodPressureStatus(),
      onClickParameter: HealthCardType.BloodPressue
    },
    {
      title: t("user.health.heartRate.heartRate", "Heart Rate"),
      value: round(bloodPressure?.heartrate),
      date: bloodPressure?.date,
      unit: `${t("user.health.heartRate.bpm")}`,
      iconSrc: HeartPulseIcon,
      onClickParameter: HealthCardType.HeartRate
    },
    {
      title: t("user.health.cholesterol", "Cholesterol"),
      value: useMetric
        ? round(cholesterol?.value, 1)
        : cholesterolToImperial(cholesterol?.value),
      date: cholesterol?.date,
      unit: useMetric ? "mmol/L" : "mg/dL",
      iconSrc: ArteryIcon,
      status: cholesterolStatus()
    },
    {
      title: t("user.health.bmi", "BMI"),
      value: round(bmi),
      date: weightScore?.date,
      iconSrc: BmiIcon
    },
    {
      title: t("user.health.bloodSugar.bloodSugar", "Blood Sugar"),
      value: useMetric
        ? round(bloodSugar?.value, 1)
        : bloodSugarToImperial(bloodSugar?.value),
      date: bloodSugar?.date,
      unit: useMetric ? `${t("units.mmolMol")}` : `${t("units.percentage")}`,
      iconSrc: BloodTestIcon,
      status: bloodSugarStatus(),
      onClickParameter: HealthCardType.BloodSugar
    }
  ];
};
