import { createContext } from "react";

import { Survey } from "~/typing/sidekickTypes";

type MissionPageContextProps = {
  handleMissionCardClick: (
    userMissionId: string,
    missionId: string,
    name: string,
    day: string
  ) => void;
  quizzes: Survey[];
  clickableMissionIds: string[];
};

export const MissionPageContext = createContext<MissionPageContextProps>(
  {} as MissionPageContextProps
);
