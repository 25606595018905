import { createContext } from "react";

import { UnreadMessagesState } from "./unreadMessagesReducer";

import { TimeZoneOffsetState } from "../reducers/timeZoneReducer";

import {
  ExternalUser,
  ExternalUserType,
  Program,
  UserDetails
} from "~/typing/sidekickTypes";

interface UserPageContextInterface {
  unreadMessages: UnreadMessagesState;
  updateUnreadMessages: () => void;
  userDetail: UserDetails | undefined;
  mutateUserDetail: () => void;
  userDetailLoading: boolean;
  program?: Program;
}

export type UserPageRouteContext = {
  program: Program;
  timezoneOffset: TimeZoneOffsetState;
  leftDate: string;
  isFinished: boolean;
  externalUser: ExternalUser;
  externalUserType: ExternalUserType;
  setShowNotes: (show: boolean) => void;
  userDetail: UserDetails | undefined;
  setShowMessages: (show: boolean) => void;
  isLoading: boolean;
};

export const UserPageContext = createContext<UserPageContextInterface>(
  {} as UserPageContextInterface
);
