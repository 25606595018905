import { useSelector } from "react-redux";

import { RootState } from "~/state/store";
import { TableHeading } from "~/typing/carePortalTypes";

export default function useListColumns(key: string) {
  const columns = useSelector<
    RootState,
    {
      [programId: string]: TableHeading[];
    }
  >((state) => state.customList.columns ?? {});

  return columns[key];
}
