import { programIncludesMaintenance } from "../user/userHelpers";

import { ProgramBehaviour } from "~/constants/programBehaviours";
import { t } from "~/i18n";
import { UserStatus } from "~/pages/program/programUsers/ProgramUsersPage.helpers";
import { SelectOption } from "~/typing/carePortalTypes";
import { Program } from "~/typing/sidekickTypes";

const getProRatingFilterText = (rating) => {
  const { lowerValue, upperValue } = rating;
  let text = "";
  if (lowerValue === undefined) {
    if (upperValue !== undefined) {
      text = `${upperValue}-`;
    }
  } else {
    if (upperValue === undefined) {
      text = `${lowerValue}+`;
    } else if (lowerValue === upperValue) {
      text = lowerValue;
    } else {
      text = `${lowerValue} - ${upperValue}`;
    }
  }

  return text;
};

const getActivityFilterOptions = (
  program: Program | undefined
): SelectOption<string>[] => {
  if (!program) return [];
  const usingBodyTraceScale =
    program.behaviour & ProgramBehaviour.UsingBodyTraceScale;
  const programIncludesFinished =
    program.behaviour & ProgramBehaviour.IncludeFinishedUsers;

  const programInclduesMaintenance = programIncludesMaintenance(program);

  return [
    {
      value: UserStatus.Active,
      text: t("programUserList.activity.active", "Active Users")
    },
    {
      value: UserStatus.New,
      text: t("programUserList.activity.new", "New Users")
    },
    {
      value: UserStatus.Inactive7Days,
      text: t("programUserList.activity.7Day", "Inactive for 7 days")
    },
    {
      value: UserStatus.Inactive14Days,
      text: t("programUserList.activity.14Day", "Inactive for 14 days")
    },
    {
      value: UserStatus.Inactive30Days,
      text: t("programUserList.activity.30Day", "Inactive for 30 days")
    },
    ...(programInclduesMaintenance
      ? [
          {
            value: UserStatus.Maintenance,
            text: t("programUserList.activity.maintenance", "Maintenance")
          }
        ]
      : []),
    ...(usingBodyTraceScale
      ? [
          {
            value: UserStatus.Scale14Day,
            text: "Scale inactive for 14 - 20 days"
          }
        ]
      : []),
    ...(usingBodyTraceScale
      ? [
          {
            value: UserStatus.Scale21Day,
            text: "Scale inactive for 21+ days"
          }
        ]
      : []),
    {
      value: UserStatus.Quit,
      text: t("programUserList.activity.dropOut", "Drop out")
    },
    {
      value: UserStatus.Discharged,
      text: t("programUserList.activity.discharged", "Discharged")
    },
    {
      value: UserStatus.Pause,
      text: t("programUserList.activity.paused", "Paused")
    },
    ...(programIncludesFinished
      ? [
          {
            value: UserStatus.Finished,
            text: t("programUserList.activity.finished")
          }
        ]
      : [])
  ];
};

export { getProRatingFilterText, getActivityFilterOptions };
