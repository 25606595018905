import { DEFAULT_FILTER_VALUE } from "~/constants/filters";
import { ListColumnType } from "~/constants/listColumnTypes";
import { getSurveyById } from "~/helpers/surveys/surveyHelpers";
import { getProRatingFilterText } from "~/helpers/userLists/filterHelper";
import { t } from "~/i18n";
import {
  ProviderListColumn,
  ProviderViewData,
  Survey
} from "~/typing/sidekickTypes";

export const getEngagementText = (engagement: number) => {
  const tString = "providerView.userColumn.engagement";
  if (engagement < 0.5) {
    return t(`${tString}.low`);
  }

  if (engagement < 0.75) {
    return t(`${tString}.med`);
  }

  return t(`${tString}.high`);
};

export const getSearchKeys = (providerView?: ProviderViewData) => {
  const searchKeys: { exactMatch?: boolean; key: string[] }[] = [];
  providerView?.columnsWithMetadata?.forEach((column) => {
    switch (column.listColumn?.listColumnType) {
      case ListColumnType.UserName:
        searchKeys.push({ key: ["userColumn", "displayName"] });
        break;
    }
  });

  return searchKeys;
};

export const getListColumnSortability = (
  listColumn: ProviderListColumn,
  symptomColumnIndex: number
) => {
  if (!listColumn) return;

  switch (listColumn.listColumnType) {
    case ListColumnType.UserName:
      return {
        sortBy: ["userColumn", "displayName"]
      };
    case ListColumnType.Weight:
      return {
        sortBy: ["weightColumn", "weight"]
      };
    case ListColumnType.Symptoms:
      return {
        sortBy: ["symptomColumns", symptomColumnIndex, "result"]
      };
    default:
      return undefined;
  }
};

export const colourFilterOptions = (surveys: Survey[], surveyId: string) => {
  const options: { value: string; text: string; colour?: string }[] = [];

  const survey = getSurveyById(surveys, surveyId);

  survey?.ratings?.forEach((rating) => {
    const text = getProRatingFilterText(rating);

    options.push({
      value: rating.id,
      text: text,
      colour: rating.colour
    });
  });

  options.unshift({
    value: DEFAULT_FILTER_VALUE,
    text: t("providerView.allSymptomStatus", "All symptom statuses")
  });

  return options;
};

export const shouldFetchSurveys = (providerView?: ProviderViewData) => {
  if (!providerView) return false;

  const columnsThatNeedSurveys = [
    ListColumnType.Symptoms,
    ListColumnType.IntakeSurveys
  ];
  let fetchSurveys = false;
  let i = 0;
  const columns = providerView?.columnsWithMetadata ?? [];
  while (!fetchSurveys && i < columns?.length) {
    const column = columns[i];
    if (columnsThatNeedSurveys.includes(column.listColumn?.listColumnType)) {
      fetchSurveys = true;
    }
    i++;
  }

  return fetchSurveys;
};
