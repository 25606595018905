import { Text } from "recharts";

import styles from "./CustomXAxisTick.module.scss";

import colors from "~/styles/colors";

type CustomXAxisTickProps = {
  todayLabel: string | number;
  payload?: any;
  wrapLabels?: boolean;
};

export default function CustomXAxisTick(props: CustomXAxisTickProps) {
  const { payload, todayLabel, wrapLabels } = props;

  if (!payload) return null;

  return (
    <Text
      {...props}
      fill={
        payload.value.toString() === todayLabel.toString() // ignore the time if included in the label
          ? colors.neonGreen120
          : colors.navyBlue50
      }
      textAnchor="middle"
      verticalAnchor="start"
      width={wrapLabels ? 62 : 0}
      className={wrapLabels ? styles.wrap : ""}
    >
      {payload.value}
    </Text>
  );
}
