import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { ComplianceUser } from "~/typing/sidekickTypes";

export default function useComplianceUsers(programCatalogItemId, locale) {
  const path = `/admin/programcatalogitems/${programCatalogItemId}/locales/${locale}/compliance/users`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: ComplianceUser[];
  }>({
    path,
    queryKey: QueryKeyFactory.complianceUsers.program(
      programCatalogItemId,
      locale
    )
  });

  return {
    complianceUsers: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
