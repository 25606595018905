import colors from "~/styles/colors";

type SearchProps = {
  color?: string;
};

export default function SearchIcon({ color = colors.navyBlue50 }: SearchProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 140 140"
      fill="none"
    >
      <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
        <path
          d="M0.750 9.812 A9.063 9.063 0 1 0 18.876 9.812 A9.063 9.063 0 1 0 0.750 9.812 Z"
          fill="none"
          stroke={color || ""}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(-3.056 4.62) rotate(-23.025)"
        />
        <path
          d="M16.221 16.22L23.25 23.25"
          fill="none"
          stroke={color || ""}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
