import React from "react";

import styles from "./ToggleSwitch.module.scss";

import ButtonLink from "../buttonLink/ButtonLink";

type ToggleSwitchProps<T> = {
  switchData: {
    label: string;
    value: T;
  }[];
  setValue: (value: T) => void;
  currentValue: T;
  className?: string;
  disabled?: boolean;
  useLinks?: boolean;
};

const ToggleSwitch = <T,>({
  switchData,
  setValue,
  currentValue,
  className,
  disabled,
  useLinks
}: ToggleSwitchProps<T>) => {
  const classes =
    (useLinks ? styles.ToggleSwitchLinks : styles.ToggleSwitch) +
    (className ? ` ${className}` : "");
  return (
    <div className={classes}>
      {switchData.map((btn, index) => (
        <React.Fragment key={`${btn.label}-${btn.value}-${index}`}>
          {useLinks ? (
            <ButtonLink
              title={btn.label}
              onClick={() => setValue(btn.value)}
              active={btn.value === currentValue}
            />
          ) : (
            <button
              type="button"
              onClick={() => setValue(btn.value)}
              key={`${btn.label}-${btn.value}-${index}`}
              className={btn.value === currentValue ? styles.active : ""}
              disabled={disabled}
            >
              {btn.label}
            </button>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ToggleSwitch;
