import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ProgramUserActivityLegend.module.scss";

type ProgramUserActivityLegendProps = {
  entry: {
    name: string;
    value: number;
    color: string;
  };
  total: number;
};

export default function ProgramUserActivityLegend({
  entry,
  total
}: ProgramUserActivityLegendProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();
  const percentage = total === 0 ? 0 : Math.round((entry.value / total) * 100);

  return (
    <li
      className={styles.Legend}
      key={entry.name}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div
        style={{ backgroundColor: entry.color }}
        className={styles.LegendColor}
      />
      {`${percentage}% ${t(`activityChart.${entry.name}`)}`}
      {showTooltip && (
        <div className={styles.tooltip}>{`${percentage}% ${t(
          `activityChart.legend.${entry.name}`
        )}`}</div>
      )}
    </li>
  );
}
