import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./CustomList.module.scss";

import NoUsersSidekick from "~/assets/sidekick-shrugging.png";

type CustomListEmptyStateProps = {
  renderEmptyState?: () => React.ReactNode;
  noEntities: boolean;
  noEntitiesText?: string;
  noColumnsSelected: boolean;
  loading: boolean;
};

const CustomListEmptyState = ({
  renderEmptyState,
  noEntities,
  noEntitiesText,
  noColumnsSelected,
  loading,
}: CustomListEmptyStateProps) => {
  const { t } = useTranslation();

  const [shouldRenderEmptyState, setShouldRenderEmptyState] = useState(false);

  useEffect(() => {
    // We want to render the empty state if there are no entities or if there are no columns selected
    // but sometimes it flickers for a second after the loading is done. This is to ensure that it doesn't
    setShouldRenderEmptyState(true);
  }, []);

  if (loading || !shouldRenderEmptyState) return null;

  return (
    <>
      <div className={styles.noUsers}>
        {renderEmptyState ? (
          renderEmptyState()
        ) : (
          <figure>
            <img src={NoUsersSidekick} className="no-users-image" />
            <figcaption>
              {noEntities
                ? noEntitiesText
                : noColumnsSelected
                ? t("list.noColumnsSelected")
                : t("list.notFoundFilters")}
            </figcaption>
          </figure>
        )}
      </div>
    </>
  );
};

export default CustomListEmptyState;
