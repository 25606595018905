import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useOutletContext, useParams } from "react-router-dom";

import Details from "./details/Details";
import InformationPageHeader from "./header/InformationPageHeader";
import HealthCards from "./healthCards/HealthCards";
import styles from "./InformationPage.module.scss";
import { InformationPageContext } from "./informationPageContext";
import CommunicationPreferences from "./modals/communicationPreferences/CommunicationPreferences";
import OrderDevice from "./modals/orderScale/OrderScaleModal";
import useBaseWeight from "./weightHistory/useBaseWeight";

import { UserPageRouteContext } from "../userPage/UserPage.context";

import api from "~/api/api";
import PinnedNotes from "~/components/notes/PinnedNotes";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import SkeletonInformationPage from "~/components/skeletons/SkeletonInformationPage";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import getLanguageFromLocale from "~/helpers/getLanguageFromLocale";
import { programHasBehaviour } from "~/helpers/program/programHelpers";
import { capitalizeWord } from "~/helpers/string/stringHelpers";
import useCommunicationPreferences from "~/hooks/useApi/useCommunicationPreferences";
import useUserSettings from "~/hooks/useApi/useUserSettings";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";

const InformationPage = () => {
  const [showOrderScale, setShowOrderScale] = useState(false);
  const [scaleOrderStatus, setScaleOrderStatus] = useState("");
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const {
    externalUser,
    setShowNotes: showAllNotes,
    leftDate,
    program,
    userDetail,
    externalUserType,
    isFinished,
    isLoading
  } = useOutletContext<UserPageRouteContext>();

  const programUsesScale = programHasBehaviour(
    program,
    ProgramBehaviour.UsingBodyTraceScale
  );
  const {
    communicationPreferences,
    invalidate: invalidateCommunicationPreferences
  } = useCommunicationPreferences(user_id);

  const {
    weight: userBaseWeight,
    invalidate: invalidateBaseWeight
  } = useBaseWeight(program_id, locale, user_id);

  const { settings } = useUserSettings(user_id);

  const { trackUserInformationViewOpened } = useAmplitudeTracking();

  const resetState = () => {
    setShowOrderScale(false);
    setScaleOrderStatus("");
    if (programUsesScale) {
      getScaleOrderStatus();
    }
  };

  const getScaleOrderStatus = async () => {
    await api
      .get(`/coach/users/${user_id}/extras/iredeem/order`)
      .then((res) => {
        if (!res?.data) return;
        const {
          decoratedStatus,
          orderDate,
          shippedDate,
          shippingTrackingNumber,
          iredeemOrderId,
          shippingCarrier
        } = res.data;

        const scaleStatus = decoratedStatus
          ? `${t("informationPage.scale.status")}: ${capitalizeWord(
              decoratedStatus
            )}`
          : undefined;
        const carrier = shippingCarrier
          ? `${t("informationPage.scale.carrier")}: ${capitalizeWord(
              shippingCarrier
            )}`
          : undefined;
        const tracking = shippingTrackingNumber
          ? `${t("informationPage.scale.tracking")}: ${shippingTrackingNumber}`
          : undefined;
        const orderId = iredeemOrderId
          ? `${t("informationPage.scale.orderId")}: ${iredeemOrderId}`
          : undefined;
        const shipped = shippedDate
          ? `${t("informationPage.scale.shipped")}: ${DateTime.fromISO(
              shippedDate
            ).toLocaleString()}`
          : undefined;
        const ordered = orderDate
          ? `${t("informationPage.scale.orderDate")}: ${DateTime.fromISO(
              orderDate
            ).toLocaleString()}`
          : undefined;

        const scaleOrderValues = [
          scaleStatus,
          carrier,
          tracking,
          orderId,
          shipped,
          ordered
        ];

        setScaleOrderStatus(scaleOrderValues.filter(Boolean).join(". "));
      });
  };

  useEffect(() => {
    if (!program) return;
    trackUserInformationViewOpened();
  }, [program?.programCatalogItemId]);

  useEffect(() => {
    if (programUsesScale) {
      getScaleOrderStatus();
    }
  }, [programUsesScale]);

  const language = getLanguageFromLocale(program?.locale);

  return (
    <SentryErrorBoundary
      resetState={resetState}
      transactionName="UserInformationPage"
    >
      <div className={styles.InformationPage}>
        <Helmet title={t("general.information", "Information")} defer={false} />
        <InformationPageContext.Provider
          value={{
            baseWeight: userBaseWeight,
            invalidateBaseWeight,
            baseWeightEnabled: program?.useUserBaseWeight,
            program,
            userDetail,
            settings,
            externalUser,
            externalUserType,
            hasQuit: !!leftDate,
            isFinished
          }}
        >
          {isLoading ? (
            <SkeletonInformationPage />
          ) : (
            <>
              <InformationPageHeader />
              {userDetail && (
                <Details
                  userDetails={userDetail}
                  language={language}
                  leftDate={
                    leftDate ? DateTime.fromISO(leftDate).toLocaleString() : ""
                  }
                  scaleOrderStatus={scaleOrderStatus}
                  showOrderScaleModal={() => setShowOrderScale(true)}
                />
              )}
              <CommunicationPreferences
                optedOutOfSms={externalUser?.smsOptOutType === "STOP"}
                preferences={communicationPreferences}
                invalidatePreferences={invalidateCommunicationPreferences}
              />
              <PinnedNotes showAllNotes={() => showAllNotes(true)} />
              <HealthCards details={userDetail} />
              {showOrderScale && (
                <OrderDevice
                  user={userDetail?.user}
                  externalPhoneNumber={externalUser?.phoneNumber}
                  onClose={() => setShowOrderScale(false)}
                  setScaleOrderStatus={setScaleOrderStatus}
                />
              )}
            </>
          )}
        </InformationPageContext.Provider>
      </div>
    </SentryErrorBoundary>
  );
};

export default InformationPage;
