import { DateTime } from "luxon";

import round from "~/helpers/number/round";
import metersToMiles from "~/helpers/units/metersToMiles";

export enum FitnessActions {
  Fetch = "Fetch"
}

type FetchAction = {
  type: FitnessActions.Fetch;
  useMetric: boolean;
  payload: {
    scoreMissions: { value1: number; value2: number }[];
    date: string;
  }[];
};

type State = {
  mins: number;
  distance: number;
  date: DateTime;
}[];

type Action = FetchAction;

export default function fitnessTestReducer(
  state: State,
  action: Action
): State {
  switch (action.type) {
    case FitnessActions.Fetch: {
      const useMetric = action.useMetric;

      const fitnessTestData = action.payload.map((item) => {
        let mins = item.scoreMissions[0].value1;
        const meters = item.scoreMissions[0].value2;

        if (mins === 360) {
          mins = 6;
        } else if (mins === 720) {
          mins = 12;
        }

        return {
          mins: mins,
          distance: useMetric ? round(meters) ?? 0 : metersToMiles(meters),
          date: DateTime.fromISO(item.date)
        };
      });

      return fitnessTestData;
    }
    default:
      return state;
  }
}
