import { gql } from "graphql-request";

import { gqlClient } from "../api";

import {
  CareManagerTasksDto,
  CarePriorities,
  MutationCalculateRankingScoreArgs,
  MutationMarkMessageSuggestionAsSentArgs,
  UserContextInfo
} from "~/typing/graphql/types";

export const getCareManagerTasks = async (
  coachId: number
): Promise<CareManagerTasksDto> => {
  const query = gql`
    query GetCareManagerTasks($coachId: Long!) {
      getCareManagerTasks(coachId: $coachId) {
        careManagerTaskDtos {
          estimatedUserCompletionTimeSeconds
          onHold
          onHoldUntil
          profilePictureUrl
          carePriorities
          timeOfRanking
          userFullName
          userId
          programCatalogItemName
        }
        estimatedChurnRiskCompletionTime
        estimatedFirstWeekMessageCompletionTime
        estimatedUnansweredMessageCompletionTime
        estimatedWeeklyFeedbackCompletionTime
      }
    }
  `;

  return await gqlClient
    .request(query, {
      coachId
    })
    .then((data: any) => data?.getCareManagerTasks || {});
};

export const getUserContextInfo = async (
  userId: string
): Promise<UserContextInfo> => {
  const query = gql`
    query GetUserContextInfo($userId: Long!) {
      getUserContextInfo(userId: $userId) {
        dayNumber
        locale
        programCatalogItemName
        userName
        weekNumber
        programCatalogItemId
        userActivityLastWeek {
          activeDates
          userId
        }
      }
    }
  `;

  return await gqlClient
    .request(query, {
      userId
    })
    .then((data: any) => data?.getUserContextInfo || {});
};

export const getCarePriorities = async (
  userId: string
): Promise<CarePriorities> => {
  const query = gql`
    query GetCarePriorities($userId: Long!) {
      getCarePriorities(userId: $userId) {
        priorities {
          metadata
          type
        }
        timeOfRanking
      }
    }
  `;

  return await gqlClient
    .request(query, {
      userId
    })
    .then((data: any) => data?.getCarePriorities || {});
};

export const calculateRankingScore = async (
  args: MutationCalculateRankingScoreArgs
): Promise<boolean> => {
  const mutation = gql`
    mutation CalculateRankingScore($userId: Long!) {
      calculateRankingScore(userId: $userId)
    }
  `;

  return await gqlClient
    .request(mutation, {
      userId: args.userId
    })
    .then((data: any) => data?.calculateRankingScore || {});
};

export const markMessageSuggestionAsSent = async (
  args: MutationMarkMessageSuggestionAsSentArgs
): Promise<number> => {
  const mutation = gql`
    mutation MarkMessageSuggestionAsSent(
      $messageSuggestionSentDto: MessageSuggestionSentDtoInput!
    ) {
      markMessageSuggestionAsSent(
        messageSuggestionSentDto: $messageSuggestionSentDto
      )
    }
  `;

  return await gqlClient
    .request(mutation, {
      messageSuggestionId: args.messageSuggestionSentDto
    })
    .then((data: any) => data?.markMessageSuggestionAsSent || {});
};
