import { useState } from "react";

import BloodSugarGraph from "./BloodSugarGraph";
import styles from "./BloodSugarModal.module.scss";

import Modal from "~/components/modal/Modal";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import { t } from "~/i18n";

type BloodSugarModalProps = {
  onClose: () => void;
  useMetric: boolean;
  latestHba1cLogDate?: string;
  latestFastingLogDate?: string;
  latestAfterMealLogDate?: string;
};

const DEFAULT_TYPE_TO_DISPLAY = "hba1c";

const BloodSugarModal = ({
  onClose,
  useMetric,
  latestHba1cLogDate,
  latestFastingLogDate,
  latestAfterMealLogDate
}: BloodSugarModalProps) => {
  const [typeToDisplay, setTypeToDisplay] = useState(DEFAULT_TYPE_TO_DISPLAY);

  const getLatestLogDate = () => {
    switch (typeToDisplay) {
      case "fasting":
        return latestFastingLogDate;
      case "meal":
        return latestAfterMealLogDate;
      default:
        return latestHba1cLogDate;
    }
  };

  return (
    <Modal
      className={styles.modal}
      title={t("user.health.bloodSugar.bloodSugar")}
      onClose={onClose}
    >
      <ToggleSwitch
        switchData={[
          {
            label: t("user.health.bloodSugar.hba1c"),
            value: "hba1c"
          },
          {
            label: t("user.health.bloodSugar.fastingBloodSugar"),
            value: "fasting"
          },
          {
            label: t("user.health.bloodSugar.afterMealBloodSugar"),
            value: "meal"
          }
        ]}
        setValue={(value) => setTypeToDisplay(value)}
        currentValue={typeToDisplay}
        className={styles.typeSwitch}
        useLinks={true}
      />
      <BloodSugarGraph
        useMetric={useMetric}
        type={typeToDisplay}
        latestLogDate={getLatestLogDate()}
      />
    </Modal>
  );
};

export default BloodSugarModal;
