import { createContext } from "react";

import { CareManagerWithPcIs } from "~/typing/graphql/types";
import { Location, Program, TherapeuticArea } from "~/typing/sidekickTypes";

export const defaultErrorState = {
  emails: "",
  locales: "",
  therapeuticAreas: "",
  locations: "",
  message: "",
  programs: ""
};

type AddCareManagersFormData = {
  userToInvite?: CareManagerWithPcIs;
  chosenEmails: string[];
  availableLocales: { name: string; locale: string }[];
  chosenLocales: string[];
  chosenTherapeuticAreas: string[];
  availableTherapeuticAreas: TherapeuticArea[];
  availableLocations: Location[];
  chosenLocations: string[];
  // message: string;
  chosenPrograms: string[];
  availablePrograms: Program[];
  setEmails: (emails: string[]) => void;
  setLocales: (locales: string[]) => void;
  setTherapeuticAreas: (therapeuticAreas: string[]) => void;
  setLocations: (locations: string[]) => void;
  // setMessage: (message: string) => void;
  setPrograms: (programs: string[]) => void;
  errors: typeof defaultErrorState;
};

export const AddCareManagersFormContext = createContext<AddCareManagersFormData>(
  {} as AddCareManagersFormData
);
