import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";

import styles from "./CustomLineChart.module.scss";
import CustomLineChartTooltip from "./CustomLineChartTooltip";

import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

import EmptyDataIcon from "~/assets/svgComponents/EmptyDataIcon";
import colors from "~/styles/colors";

type LineData<T> = {
  name: string;
  dataKey: keyof T;
  lineColor: string;
};

type CustomLineChartProps<T> = {
  lineData: T[];
  height: number;
  YAxisDomain: number[];
  units: string;
  lines: Array<LineData<T>>;
  getCellData?: (
    data: T | undefined
  ) => {
    cellTitle: string;
    cellColor: string;
  };
  isLoading?: boolean;
};

const CustomLineChart = <T,>({
  lineData,
  height,
  YAxisDomain,
  units,
  lines,
  getCellData,
  isLoading
}: CustomLineChartProps<T>) => {
  const { t } = useTranslation();

  return (
    <div className={styles.customLineChart}>
      <ResponsiveContainer height={height}>
        {lineData.length > 0 && !isLoading ? (
          <LineChart data={lineData} margin={{ top: 30 }}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="3"
              fill={colors.navyBlue0}
            />
            <XAxis
              stroke=""
              dataKey="date"
              padding={{ left: 30, right: 30 }}
              tickMargin={8}
            />
            <YAxis domain={YAxisDomain} stroke="" tickMargin={8}>
              <Label fontSize={12} offset={20} value={units} position="top" />
            </YAxis>
            <Legend />
            <Tooltip
              content={(value) => (
                <CustomLineChartTooltip<T>
                  {...value}
                  units={units}
                  getCellData={getCellData}
                />
              )}
            />
            {lines.map((line, index) => (
              <Line
                key={index}
                name={line.name}
                dataKey={line.dataKey as string}
                stroke={line.lineColor}
                strokeWidth={5}
                dot={{ stroke: line.lineColor, strokeWidth: 7 }}
                isAnimationActive={false}
              />
            ))}
          </LineChart>
        ) : (
          <>
            {isLoading ? (
              <div className={styles.loadingState}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className={styles.emptyState}>
                <EmptyDataIcon />
                <p>{t("chartDetail.noDataForPeriod")}</p>
                <p>{t("chartDetail.selectAnotherPeriod")}</p>
              </div>
            )}
          </>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
