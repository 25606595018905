import classNames from "classnames/bind";
import { forwardRef, useEffect, useState } from "react";

import styles from "./MultiInput.module.scss";

import CircledCloseIcon from "~/assets/closeCircled.svg";

const cx = classNames.bind(styles);

type MultiInput = {
  initialValue: string[];
  onChange: (emails: string[]) => void;
  placeholder?: string;
  dataTestId?: string;
  disabled?: boolean;
  error?: string;
  className?: string;
  wrapperClassName?: string;
  id?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
  maxLength?: number;
  multiple?: boolean;
  inputValidator?: (input: string) => boolean;
};

const MultiInput = forwardRef<HTMLInputElement, MultiInput>(
  (props, inputRef) => {
    return <MultiInputComponent {...props} inputRef={inputRef} />;
  }
);

const MultiInputComponent = ({
  onChange,
  placeholder,
  dataTestId,
  disabled,
  error,
  className,
  wrapperClassName,
  id,
  label,
  required,
  autoFocus,
  inputRef,
  maxLength,
  multiple,
  initialValue,
  inputValidator
}: MultiInput) => {
  const [showError, setShowError] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const [inputs, setInputs] = useState<string[]>(initialValue);

  const handleChange = (value: string) => {
    setShowError(false);
    if (value.includes(",")) {
      const newInputs = value.split(",").filter((input) => input.trim());
      setInputs([...inputs, ...newInputs]);
      setCurrentValue("");
    } else {
      setCurrentValue(value);
    }
  };

  const addCurrentValueToList = () => {
    if (currentValue.trim()) {
      const newEmails = [...inputs, currentValue.trim()];
      setInputs(newEmails);
      setCurrentValue("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addCurrentValueToList();
    } else if (e.key === "Backspace" && !currentValue) {
      removeInput(inputs.length - 1);
    }
  };

  const removeInput = (index: number) => {
    const newEmails = inputs.filter((_, i) => i !== index);
    setInputs(newEmails);
  };

  useEffect(() => {
    onChange(inputs);
  }, [inputs]);

  return (
    <div className={wrapperClassName}>
      <label className="label label--block">
        {label}
        {required && <span className="required-star">*</span>}
      </label>
      <div
        className={cx({
          wrapper: true,
          [className ?? ""]: className !== undefined,
          disabled: disabled,
          error: showError
        })}
      >
        {inputs.map((input, index) => (
          <div
            key={index}
            className={cx({
              email: true,
              invalid: inputValidator && !inputValidator(input)
            })}
          >
            <span>{input}</span>
            {!disabled && (
              <button
                className={styles.removeButton}
                onClick={() => removeInput(index)}
              >
                <img src={CircledCloseIcon} />
              </button>
            )}
          </div>
        ))}
        <input
          ref={inputRef}
          data-testid={dataTestId}
          id={id}
          type="text"
          placeholder={placeholder}
          value={currentValue}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          required={required}
          autoFocus={autoFocus}
          onBlur={addCurrentValueToList}
          maxLength={maxLength}
          multiple={multiple}
        />
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

MultiInput.displayName = "MultiInput";
export default MultiInput;
