import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ProgramLocaleNavState = {
  [programId: string]: {
    selectedLocale: string;
  };
};

const initialState: ProgramLocaleNavState = {};

type ProgramLocaleNavPayload = {
  programId: string;
  selectedLocale: string;
};

const programLocaleNavSlice = createSlice({
  name: "programLocaleNav",
  initialState,
  reducers: {
    setProgramLocaleNav(
      state: ProgramLocaleNavState,
      action: PayloadAction<ProgramLocaleNavPayload>
    ) {
      const { programId, selectedLocale } = action.payload;
      state[programId] = { selectedLocale };
    }
  }
});

const programLocaleNavReducer = programLocaleNavSlice.reducer;
export { programLocaleNavReducer };
export const { setProgramLocaleNav } = programLocaleNavSlice.actions;
