import { DateTime } from "luxon";

import api from "~/api/api";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

export const saveBaseWeight = async (
  weight: number,
  programCatalogItemId: string,
  locale: string,
  userId: string
) => {
  const path = `/coach/programcatalogitems/${programCatalogItemId}/locales/${locale}/users/${userId}/userbaseweight`;

  const postData = { value: weight };

  return await api
    .post(path, postData)
    .then((res) => res.data)
    .catch(() =>
      displayErrorToast({
        message: t("errors.save", { resource: t("user.health.baseWeight") })
      })
    );
};

export const getWeightDataForUser = async (
  programId: string,
  locale: string,
  userId: string,
  weightMissionId: string,
  startDate: DateTime,
  endDate: DateTime
) => {
  return await api
    .get(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/scores?missionId=${weightMissionId}&startDate=${startDate.toISO()}&endDate=${endDate.toISO()}`
    )
    .then((res) => res.data.items)
    .catch(() =>
      displayErrorToast({
        message: t("errors.fetch", { resource: t("weightHistory.weightData") })
      })
    );
};
