import { useTranslation } from "react-i18next";

import styles from "./NotFound.module.scss";

import SentryErrorBoundary from "~/components/SentryErrorBoundary";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <SentryErrorBoundary transactionName="NotFound">
      <div className={styles.NotFound}>
        <h1>{t("notfound.header", "Not Found!")}</h1>
        <p>{t("notfound.text", "The page you requested cannot be found.")}</p>
      </div>
    </SentryErrorBoundary>
  );
}
