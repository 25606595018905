import classNames from "classnames/bind";

import Modal from "./Modal";
import styles from "./ModalWithFooterActions.module.scss";

import Button from "../button/Button";

const cx = classNames.bind(styles);

type ModalWithFooterActionsProps = {
  children: React.ReactNode;
  className?: string;
  contentClass?: string;
  title: string;
  onClose: () => void;
  overflowHidden?: boolean;
  actions: {
    label: string;
    onClick: () => void;
    inverted?: boolean;
    hidden?: boolean;
    disabled?: boolean;
  }[];
};

const ModalWithFooterActions = ({
  children,
  className,
  contentClass,
  title,
  onClose,
  actions,
  overflowHidden = true
}: ModalWithFooterActionsProps) => {
  return (
    <Modal
      className={`${className ?? ""}`}
      contentClass={styles.modalContent}
      title={title}
      onClose={onClose}
    >
      <div
        className={cx({
          content: true,
          [contentClass ?? ""]: contentClass,
          overflowHidden: overflowHidden
        })}
      >
        {children}
      </div>
      <div className={styles.footer}>
        {actions
          .filter((action) => !action.hidden)
          .map((action, index) => (
            <Button
              key={index}
              onClick={action.onClick}
              inverted={action.inverted}
              disabled={action.disabled}
            >
              {action.label}
            </Button>
          ))}
      </div>
    </Modal>
  );
};

export default ModalWithFooterActions;
