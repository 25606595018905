import { ReactNode, useState } from "react";

import styles from "./AnswerBubble.module.scss";
import AnswerTooltip from "./AnswerTooltip";

import surveyQuestionDisplayTypes from "~/constants/surveyQuestionDisplayTypes";
import cellStyleForValue from "~/helpers/cellStyleForValue";
import colors from "~/styles/colors";
import {
  SurveyResultAnswerSeverity,
  SurveyResultQuestion
} from "~/typing/sidekickTypes";

type AnswerBubbleProps = {
  question?: SurveyResultQuestion;
  proExists: boolean;
  uniqueValues?: number[];
  answerSeverities?: SurveyResultAnswerSeverity[];
  answerDisplay?: ({
    question,
    useSkipped,
    date,
    index
  }: {
    question?: SurveyResultQuestion;
    useSkipped?: boolean;
    date?: Date;
    index?: number;
  }) => ReactNode;
  getAnswerSeverity?: (
    severityId: string
  ) => SurveyResultAnswerSeverity | undefined;
  small?: boolean;
  questionIndex?: number;
  proIndex?: number;
};

const AnswerBubble = ({
  question,
  proExists,
  uniqueValues,
  answerSeverities,
  answerDisplay,
  getAnswerSeverity,
  small,
  questionIndex,
  proIndex
}: AnswerBubbleProps) => {
  const [showAnswerTooltip, setShowAnswerTooltip] = useState(false);
  const [isOnAnswerTooltip, setOnAnswerTooltip] = useState(false);
  const { multiSelection } = surveyQuestionDisplayTypes;

  const maxAnswerSeverity = (): SurveyResultAnswerSeverity | undefined => {
    let maxSeverity: SurveyResultAnswerSeverity | undefined = undefined;

    if (!getAnswerSeverity) return;

    question?.answers?.forEach((answer) => {
      if (answer.surveyAnswerSeverityId && answerSeverities) {
        const severity = getAnswerSeverity(answer.surveyAnswerSeverityId);
        if (!maxSeverity || !severity || maxSeverity.order < severity.order) {
          maxSeverity = severity;
        }
      }
    });
    return maxSeverity;
  };

  const cellStyleForQuestion = (question?: SurveyResultQuestion) => {
    if (!question || !uniqueValues) return {};

    if (answerSeverities) {
      return { backgroundColor: maxSeverity?.colour || colors.navyBlue50 };
    }

    return cellStyleForValue(question.ratingValue, uniqueValues);
  };

  const getMultiSelectRatingValue = (question: SurveyResultQuestion) => {
    let totalRatingValue = 0;
    question?.answers?.forEach((answer) => {
      totalRatingValue += answer.ratingValue;
    });
    return totalRatingValue;
  };

  const getDisplayValue = () => {
    if (!question) {
      return "-";
    }
    if (question?.displayType === multiSelection) {
      return getMultiSelectRatingValue(question);
    }
    return question.ratingValue;
  };

  const getRating = () => {
    if (answerSeverities && maxSeverity) {
      return maxSeverity.value;
    }
    if (question?.displayType === multiSelection) {
      return undefined;
    }
    return question?.ratingValue;
  };

  const handleOnMouseEnter = () => {
    if (proExists) setShowAnswerTooltip(true);
  };

  const handleOnMouseLeave = () => {
    if (proExists) setShowAnswerTooltip(false);
  };

  const handleOnTooptipMouseEnter = () => {
    setOnAnswerTooltip(true);
  };

  const handleOnTooptipMouseLeave = () => {
    setOnAnswerTooltip(false);
  };

  const maxSeverity = maxAnswerSeverity();
  const displayValue = getDisplayValue();
  const classes = `${styles.AnswerBubble} ${small ? styles.small : ""}`;

  return (
    <div
      className={classes}
      style={cellStyleForQuestion(question)}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {proExists && displayValue}
      {(showAnswerTooltip || isOnAnswerTooltip) && answerDisplay && (
        <AnswerTooltip
          question={question}
          uniqueValues={uniqueValues}
          rating={getRating()}
          answerDisplay={answerDisplay}
          answerSeverities={answerSeverities}
          questionIndex={questionIndex}
          proIndex={proIndex}
          onMouseEnter={handleOnTooptipMouseEnter}
          onMouseLeave={handleOnTooptipMouseLeave}
        />
      )}
    </div>
  );
};

export default AnswerBubble;
