import api from "../api";

import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { SurveyResult } from "~/typing/sidekickTypes";

export const getSurveys = async ({
  programId,
  locale
}: {
  programId: string;
  locale: string;
}) => {
  const path = `/coach/programs/${programId}/locales/${locale}/surveys`;
  return await api
    .get(path)
    .then((res) => res.data.items)
    .catch(() => []);
};

export const getSurveyResults = async ({
  programId,
  locale,
  userId,
  surveyId
}: {
  programId: string;
  locale: string;
  userId: string;
  surveyId: string;
}): Promise<SurveyResult[]> => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/surveys/${surveyId}/surveyresults/`;
  return await api
    .get(path)
    .then((res) => res.data.items)
    .catch(() => []);
};

export const setCoachRatingForSurvey = async ({
  programId,
  locale,
  userId,
  surveyResultId,
  coachRatingValue
}: {
  programId: string;
  locale: string;
  userId: string;
  surveyResultId: string;
  coachRatingValue: number;
}) => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/surveyresults/${surveyResultId}/coachrating`;
  return await api
    .post(path, { coachRatingValue })
    .then((res) => {
      displaySuccessToast({ message: t("pro.success") });
      return res.data;
    })
    .catch(() => displayErrorToast({ message: t("pro.error") }));
};
