import { DateTime } from "luxon";

import humanizeTimeDiff from "~/helpers/date/humanizeTimeDiff";
import { t } from "~/i18n";
import { CarePriority, CarePriorityType } from "~/typing/graphql/types";
import { MessageSuggestionType } from "~/typing/sidekickTypes";

export const getCarePriorityMessageType = (type: CarePriorityType) => {
  switch (type) {
    case CarePriorityType.WeeklyFeedback:
      return MessageSuggestionType.WeeklyFeedback;
    case CarePriorityType.FirstWeek:
      return MessageSuggestionType.FirstWeek;
    case CarePriorityType.HighChurnRisk:
      return MessageSuggestionType.ChurnRisk;
    default:
      return undefined;
  }
};

export const getCarePriorityMessage = ({
  carePriority,
  userName
}: {
  carePriority: CarePriority;
  userName: string;
}) => {
  if (carePriority.type === CarePriorityType.HighChurnRisk) {
    const churnTime = humanizeTimeDiff(
      -carePriority.metadata.millisSinceLastActivity
    );
    return t("nextStep.highChurnRisk.message", {
      userName: userName,
      churnTime: churnTime
    });
  }
  if (carePriority.type === CarePriorityType.FirstWeek) {
    let messageNumber = "";
    switch (carePriority.metadata.numberOfMessagesReceived) {
      case 0:
        messageNumber = t("nextStep.firstWeek.firstMessage");
        break;
      case 1:
        messageNumber = t("nextStep.firstWeek.secondMessage");
        break;
      case 2:
        messageNumber = t("nextStep.firstWeek.thirdMessage");
        break;
      default:
        messageNumber = t("nextStep.firstWeek.messageDefault");
        break;
    }
    return t("nextStep.firstWeek.message", {
      userName: userName,
      messageNumber: messageNumber,
      numberOfMessagesReceived: carePriority?.metadata?.numberOfMessagesReceived
    });
  }
  if (carePriority.type === CarePriorityType.NewMessage) {
    const metadata = carePriority.metadata;
    const type = carePriority.type;
    let oldestUnreadMessageDate = "";
    let unreadMessageTime = "";

    //We need to check if the metadata is null or if the unread message count is 0.
    //If either of these are true, then we should set the noMessage state to true.
    //There might have been a message but a coach has seen it already and it been market as read but
    //we still want to show the message to the user.
    if (
      metadata.oldestUnreadMessageDate == null ||
      metadata.unreadMessageCount == 0
    ) {
      return t("nextStep.unseenMessage.emptyROR", { userName: userName });
    }

    //Might be a redundant check but just to be safe check for the
    //type of care priority and that there is metadata
    if (type === CarePriorityType.NewMessage && metadata) {
      const dateString = metadata.oldestUnreadMessageDate;
      const dateTime = DateTime.fromISO(dateString);

      // Function to get the ordinal suffix for a given day
      oldestUnreadMessageDate = `${dateTime.toLocaleString(
        DateTime.DATETIME_SHORT
      )}`;

      //Find the difference between the current time and the oldest unread message date
      const diff = Math.max(
        Date.now() -
          DateTime.fromISO(metadata.oldestUnreadMessageDate).toMillis(),
        0
      );

      unreadMessageTime = humanizeTimeDiff(diff);
      return t("nextStep.unseenMessage.message", {
        unreadMessageCount: metadata.unreadMessageCount,
        userName: userName,
        unreadMessageTime: unreadMessageTime,
        oldestUnreadMessageDate: oldestUnreadMessageDate
      });
    }
  }
  return "";
};
