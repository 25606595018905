import colors from "~/styles/colors";

type ExclamationIconProps = {
  color?: string;
};

const ExclamationIcon = ({ color = colors.red60 }: ExclamationIconProps) => {
  return (
    <svg
      width="3"
      height="12"
      viewBox="0 0 3 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.76325 7.736H2.21925L2.50725 0.327999H0.45925L0.76325 7.736ZM0.37925 10.056C0.37925 10.648 0.87525 11.16 1.48325 11.16C2.09125 11.16 2.60325 10.648 2.60325 10.056C2.60325 9.448 2.09125 8.936 1.48325 8.936C0.87525 8.936 0.37925 9.448 0.37925 10.056Z"
        fill={color}
      />
    </svg>
  );
};

export default ExclamationIcon;
