import { DateTime } from "luxon";

import styles from "./DateChanger.module.scss";

import ChevronRight from "~/assets/chevron-right.svg";
import getWeekDateRange from "~/helpers/date/getWeekDateRange";

type DateChangerProps = {
  endDate: DateTime;
  changeEndDate: (value: 1 | -1) => void;
  useWeek?: boolean;
};

export default function DateChanger({
  endDate,
  changeEndDate,
  useWeek
}: DateChangerProps) {
  return (
    <div className={styles.container}>
      <img
        className={`${styles.arrow} ${styles.arrowLeft}`}
        src={ChevronRight}
        onClick={() => changeEndDate(-1)}
        data-testid="date-changer-left-arrow"
      />
      <div className={styles.date}>
        {useWeek
          ? getWeekDateRange(endDate, true)
          : `${endDate.monthLong}, ${endDate.year}`}
      </div>
      <img
        className={`${styles.arrow} ${styles.arrowRight}`}
        src={ChevronRight}
        onClick={() => changeEndDate(1)}
        data-testid="date-changer-right-arrow"
      />
    </div>
  );
}
