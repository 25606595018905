/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull care-portal-web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 31
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/sidekickhealth/Care%20Portal/implementation/care-portal-web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '330bfc6f7e3882986789cc19d7717063',
  development: '8a750ba0e27c885fc1d2bb4d96a0676d'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '31',
    branch: 'next-step-launch',
    source: 'care-portal-web',
    versionId: 'e0250609-8b92-454a-904c-1dcd3f1310f2'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * Coach type is set by priviliges i.e. what they user can and cannot do in the Care Portal
   */
  coachType?: string;
  /**
   * The platform environment that the User is on
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment?: "sidekick" | "elevance";
  /**
   * The role the care manager has. Other is for any role that doesn't fall into the set list and N/A is used for care managers were no specific role is applicable
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Health Advocate, Social Worker, Health Program Representative, Health Coach, My Pharmacist, My Dietitian, My Dietitian/Diabetes Education, Care Navigator, My Navigator, Registered Nurse, Other, N/A |
   */
  role?:
    | "Health Advocate"
    | "Social Worker"
    | "Health Program Representative"
    | "Health Coach"
    | "My Pharmacist"
    | "My Dietitian"
    | "My Dietitian/Diabetes Education"
    | "Care Navigator"
    | "My Navigator"
    | "Registered Nurse"
    | "Other"
    | "N/A";
}

export interface AccordionExpandedProperties {
  topic: string;
}

export interface ActionAwaitingMealSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface ActionAwaitingMessageSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * Flagged as 1 if the care manager is the intended recipient of the message. Applicable is the color of the icon is pink. Not applicable if the message is an SMS
   */
  isCmRecipient?: boolean;
  /**
   * Flagged as 1 if the user sent a text message, applicable when the icon is green
   */
  isSms: boolean;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface ActionAwaitingProSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface AddressInsertedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface AssignedToFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface BrandFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface BulkMessageSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The total number of users a bulk message was sent to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfUsers: number;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The selection made in the to section of the bulk message
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  userSelection: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface CalendarViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface CarePriorityMarkedDoneProperties {
  carePriority: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface CarePriorityTabChangedProperties {
  from: string;
  to: string;
}

export interface CoachAssignedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface CoachInvitedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface CommunityViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
}

export interface DeviceOrderSelectedProperties {
  /**
   * The name of the device, Digital Scale, Glocumeter etc
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  deviceName: string[];
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface DeviceOrderStatusSelectedProperties {
  /**
   * The date the device was ordered
   */
  deviceOrderDate: string;
  /**
   * The order status of the device
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Pending, Ordered, Shipped, Delivered |
   */
  deviceOrderStatus: "Pending" | "Ordered" | "Shipped" | "Delivered";
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface DeviceOrderedProperties {
  /**
   * The id of the relevant device
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  deviceId: string[];
  /**
   * The name of the device, Digital Scale, Glocumeter etc
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  deviceName: string[];
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface DiseaseTypeFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface DocumentDeletedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface DocumentDownloadedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface DocumentUploadedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface DocumentViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface FoodJournalViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface HealthCardOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The type of health card being opened
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BloodPressure, BloodSugar, Weight, HeartRate, Cholesterol, BMI |
   */
  healthCardType: "BloodPressure" | "BloodSugar" | "Weight" | "HeartRate" | "Cholesterol" | "BMI";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface LayerFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  filterValue: string[];
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface LobFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface MealMessageSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * Flagged as 1 if the care manager adjusted the healthy rating for the users meal log
   */
  isHealthyRatingChanged: boolean;
  /**
   * Flagged as 1 if the care manager updated the portion size of the users meal log
   */
  isPortionSizeChanged: boolean;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface MedicationFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The selection made from the medication drop down filter
   */
  medicationSelected: any;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MedicationReminderSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MedicationViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MemberStateFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  filterValue: string[];
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface MessageChatClosedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageChatMaximisedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageChatMinimisedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageChatOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageCopyTextSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageEditModeSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface MessageNotificationStatusFilterProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface MessagePreviewSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageReplySelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageResetSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MessageSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * Flagged as 1 if the message was generated from the recommended message feature
   */
  isRecommendedMessage?: boolean;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId?: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface MessageSuggestionChangedProperties {
  from: string;
  to: string;
}

export interface MessageSuggestionCopiedProperties {
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId: string;
}

export interface MessageThreadChangedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  from: string;
  fromUserId: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The message thread selected in the chat window toggle. Could be You, All Care Managers etc
   */
  messageThreadSelection?: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  to: string;
  toUserId: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MissionsViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface MyProgramsPageOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
}

export interface NewSmsInviteSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The external user id is assigned when a eligible user is uploaded to the care portal. Does not match the sidekick user_id which is generated upon registratio
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  externalUserId?: number;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface NextMessageSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface NextStepRecommendedMessageCopiedProperties {
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId: string;
}

export interface NextStepTakenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  carePriorities: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | next, previous |
   */
  direction: "next" | "previous";
  from: string;
  to: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  unfinishedCarePriorities: string[];
}

export interface NoteAddedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * This is the topic for the note as selected by the care manager in the drop down on the note screen
   */
  noteTopic: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface OutreachFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface PatientDischargedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface PatientRestartedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface PerformanceViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The section of the performance view
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Sleep Quality, Stress Level, Energy Level, Activity, Step counter, Fitness test |
   */
  performanceSection: "Sleep Quality" | "Stress Level" | "Energy Level" | "Activity" | "Step counter" | "Fitness test";
  /**
   * The section the performance view pertains to. Can be either Mental or Physical
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Mental, Physical |
   */
  performanceView: "Mental" | "Physical";
  /**
   * The mode selected for the performance view
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Weekly, Monthly, Detail |
   */
  performanceViewMode: "Weekly" | "Monthly" | "Detail";
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface PlanStateFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  filterValue: string[];
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface PreviousMessageSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The id of the suggested message generated from the assistant coach
   */
  messageSuggestionId: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface ProFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface ProResultApprovedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The date the PRO was answered by the user
   */
  proDate: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The name of the patient reported outcome (survey)
   */
  proName: string;
  /**
   * The result of the PRO
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  proResult: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface ProResultChangedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The date the PRO was answered by the user
   */
  proDate: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The name of the patient reported outcome (survey)
   */
  proName: string;
  /**
   * The result of the PRO
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  proResult: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface ProResultFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface ProResultMessageSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The date the PRO was answered by the user
   */
  proDate: string;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The name of the patient reported outcome (survey)
   */
  proName: string;
  /**
   * The result of the PRO
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  proResult: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface ProViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface QuizMissionOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The name of the quiz
   */
  quizName: string;
  /**
   * The result of the quiz being checked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  quizResult: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface RecommendedMessagesExitedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface RecommendedMessagesOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface RegistrationViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
}

export interface SmsFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface SmsInviteSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The external user id is assigned when a eligible user is uploaded to the care portal. Does not match the sidekick user_id which is generated upon registratio
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  externalUserId?: number;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface SmsSentProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  userId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wordCount: number;
}

export interface TaskOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  carePriorities: string[];
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface TaskPaginatorClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | next, previous |
   */
  direction: "next" | "previous";
}

export interface UserGuideOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
}

export interface UserInformationViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface UserListViewOpenedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
}

export interface UserReportCopiedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The from date the coach selects in the period section of the Export PDF screen
   */
  fromDate: string;
  /**
   * The sections included in the PDF export
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  informationIncluded: string[];
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface UserReportExportedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The from date the coach selects in the period section of the Export PDF screen
   */
  fromDate: string;
  /**
   * The sections included in the PDF export
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  informationIncluded: string[];
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export interface UserStatusFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The selection(s) a care manager makes in the User Status Filter
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  userStatusFilterSelection: string[];
}

export interface UserTypeFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface WeekFilterSelectedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * The selection made by the care manager in the filter drop down
   */
  filterValue: string;
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
}

export interface WeekPointsViewedProperties {
  /**
   * The platform the event is fired in. Could be either sidekick care portal or anthem care portal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sidekick, elevance |
   */
  environment: "sidekick" | "elevance";
  /**
   * This indicates if the event is related to a test program or real world program. Populated as True if it's a test program
   */
  isTestProgram?: boolean;
  /**
   * The program catalog item id the event relates to
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  programId?: number;
  /**
   * The name of the program
   */
  programName?: string;
  /**
   * The user_id of the patient, not the coach
   */
  userId: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccessManagementPageOpened implements BaseEvent {
  event_type = 'Access Management Page Opened';
}

export class AccordionExpanded implements BaseEvent {
  event_type = 'Accordion Expanded';

  constructor(
    public event_properties: AccordionExpandedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionAwaitingMealSelected implements BaseEvent {
  event_type = 'Action Awaiting Meal Selected';

  constructor(
    public event_properties: ActionAwaitingMealSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionAwaitingMessageSelected implements BaseEvent {
  event_type = 'Action Awaiting Message Selected';

  constructor(
    public event_properties: ActionAwaitingMessageSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionAwaitingProSelected implements BaseEvent {
  event_type = 'Action Awaiting PRO Selected';

  constructor(
    public event_properties: ActionAwaitingProSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddressInserted implements BaseEvent {
  event_type = 'Address Inserted';

  constructor(
    public event_properties: AddressInsertedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AssignedToFilterSelected implements BaseEvent {
  event_type = 'Assigned To Filter Selected';

  constructor(
    public event_properties: AssignedToFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BrandFilterSelected implements BaseEvent {
  event_type = 'Brand Filter Selected';

  constructor(
    public event_properties: BrandFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BulkMessageSent implements BaseEvent {
  event_type = 'Bulk Message Sent';

  constructor(
    public event_properties: BulkMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarViewOpened implements BaseEvent {
  event_type = 'Calendar View Opened';

  constructor(
    public event_properties: CalendarViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CarePriorityMarkedDone implements BaseEvent {
  event_type = 'Care Priority Marked Done';

  constructor(
    public event_properties: CarePriorityMarkedDoneProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CarePriorityTabChanged implements BaseEvent {
  event_type = 'Care Priority Tab Changed';

  constructor(
    public event_properties: CarePriorityTabChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CoachAssigned implements BaseEvent {
  event_type = 'Coach Assigned';

  constructor(
    public event_properties: CoachAssignedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CoachInvited implements BaseEvent {
  event_type = 'Coach Invited';

  constructor(
    public event_properties: CoachInvitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CommunityViewOpened implements BaseEvent {
  event_type = 'Community View Opened';

  constructor(
    public event_properties: CommunityViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeviceOrderSelected implements BaseEvent {
  event_type = 'Device Order Selected';

  constructor(
    public event_properties: DeviceOrderSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeviceOrderStatusSelected implements BaseEvent {
  event_type = 'Device Order Status Selected';

  constructor(
    public event_properties: DeviceOrderStatusSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeviceOrdered implements BaseEvent {
  event_type = 'Device Ordered';

  constructor(
    public event_properties: DeviceOrderedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DiseaseTypeFilterSelected implements BaseEvent {
  event_type = 'Disease Type Filter Selected';

  constructor(
    public event_properties: DiseaseTypeFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentDeleted implements BaseEvent {
  event_type = 'Document Deleted';

  constructor(
    public event_properties: DocumentDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentDownloaded implements BaseEvent {
  event_type = 'Document Downloaded';

  constructor(
    public event_properties: DocumentDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentUploaded implements BaseEvent {
  event_type = 'Document Uploaded';

  constructor(
    public event_properties: DocumentUploadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentViewOpened implements BaseEvent {
  event_type = 'Document View Opened';

  constructor(
    public event_properties: DocumentViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FoodJournalViewOpened implements BaseEvent {
  event_type = 'Food Journal View Opened';

  constructor(
    public event_properties: FoodJournalViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HealthCardOpened implements BaseEvent {
  event_type = 'Health Card Opened';

  constructor(
    public event_properties: HealthCardOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LayerFilterSelected implements BaseEvent {
  event_type = 'Layer Filter Selected';

  constructor(
    public event_properties: LayerFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LobFilterSelected implements BaseEvent {
  event_type = 'LOB Filter Selected';

  constructor(
    public event_properties: LobFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MealMessageSent implements BaseEvent {
  event_type = 'Meal Message Sent';

  constructor(
    public event_properties: MealMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MedicationFilterSelected implements BaseEvent {
  event_type = 'Medication Filter Selected';

  constructor(
    public event_properties: MedicationFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MedicationReminderSent implements BaseEvent {
  event_type = 'Medication Reminder Sent';

  constructor(
    public event_properties: MedicationReminderSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MedicationViewOpened implements BaseEvent {
  event_type = 'Medication View Opened';

  constructor(
    public event_properties: MedicationViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MemberStateFilterSelected implements BaseEvent {
  event_type = 'Member State Filter Selected';

  constructor(
    public event_properties: MemberStateFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageChatClosed implements BaseEvent {
  event_type = 'Message Chat Closed';

  constructor(
    public event_properties: MessageChatClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageChatMaximised implements BaseEvent {
  event_type = 'Message Chat Maximised';

  constructor(
    public event_properties: MessageChatMaximisedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageChatMinimised implements BaseEvent {
  event_type = 'Message Chat Minimised';

  constructor(
    public event_properties: MessageChatMinimisedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageChatOpened implements BaseEvent {
  event_type = 'Message Chat Opened';

  constructor(
    public event_properties: MessageChatOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageCopyTextSelected implements BaseEvent {
  event_type = 'Message Copy Text Selected';

  constructor(
    public event_properties: MessageCopyTextSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageEditModeSelected implements BaseEvent {
  event_type = 'Message Edit Mode Selected';

  constructor(
    public event_properties: MessageEditModeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageNotificationStatusFilter implements BaseEvent {
  event_type = 'Message Notification Status Filter';

  constructor(
    public event_properties: MessageNotificationStatusFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessagePreviewSelected implements BaseEvent {
  event_type = 'Message Preview Selected';

  constructor(
    public event_properties: MessagePreviewSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageReplySelected implements BaseEvent {
  event_type = 'Message Reply Selected';

  constructor(
    public event_properties: MessageReplySelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageResetSelected implements BaseEvent {
  event_type = 'Message Reset Selected';

  constructor(
    public event_properties: MessageResetSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageSent implements BaseEvent {
  event_type = 'Message Sent';

  constructor(
    public event_properties: MessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageSuggestionChanged implements BaseEvent {
  event_type = 'Message Suggestion Changed';

  constructor(
    public event_properties: MessageSuggestionChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageSuggestionCopied implements BaseEvent {
  event_type = 'Message Suggestion Copied';

  constructor(
    public event_properties: MessageSuggestionCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageThreadChanged implements BaseEvent {
  event_type = 'Message Thread Changed';

  constructor(
    public event_properties: MessageThreadChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MissionsViewOpened implements BaseEvent {
  event_type = 'Missions View Opened';

  constructor(
    public event_properties: MissionsViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyProgramsPageOpened implements BaseEvent {
  event_type = 'My Programs Page Opened';

  constructor(
    public event_properties: MyProgramsPageOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewSmsInviteSent implements BaseEvent {
  event_type = 'New SMS Invite Sent';

  constructor(
    public event_properties: NewSmsInviteSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextMessageSelected implements BaseEvent {
  event_type = 'Next Message Selected';

  constructor(
    public event_properties: NextMessageSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStepGraphViewOpened implements BaseEvent {
  event_type = 'Next Step Graph View Opened';
}

export class NextStepRecommendedMessageCopied implements BaseEvent {
  event_type = 'Next Step Recommended Message Copied';

  constructor(
    public event_properties: NextStepRecommendedMessageCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStepTaken implements BaseEvent {
  event_type = 'Next Step Taken';

  constructor(
    public event_properties: NextStepTakenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextStepUserMetricsViewed implements BaseEvent {
  event_type = 'Next Step User Metrics Viewed';
}

export class NoteAdded implements BaseEvent {
  event_type = 'Note Added';

  constructor(
    public event_properties: NoteAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OutreachFilterSelected implements BaseEvent {
  event_type = 'Outreach Filter Selected';

  constructor(
    public event_properties: OutreachFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PatientDischarged implements BaseEvent {
  event_type = 'Patient Discharged';

  constructor(
    public event_properties: PatientDischargedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PatientRestarted implements BaseEvent {
  event_type = 'Patient Restarted';

  constructor(
    public event_properties: PatientRestartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerformanceViewOpened implements BaseEvent {
  event_type = 'Performance View Opened';

  constructor(
    public event_properties: PerformanceViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlanStateFilterSelected implements BaseEvent {
  event_type = 'Plan State Filter Selected';

  constructor(
    public event_properties: PlanStateFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreviousMessageSelected implements BaseEvent {
  event_type = 'Previous Message Selected';

  constructor(
    public event_properties: PreviousMessageSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProFilterSelected implements BaseEvent {
  event_type = 'PRO Filter Selected';

  constructor(
    public event_properties: ProFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProResultApproved implements BaseEvent {
  event_type = 'PRO Result Approved';

  constructor(
    public event_properties: ProResultApprovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProResultChanged implements BaseEvent {
  event_type = 'PRO Result Changed';

  constructor(
    public event_properties: ProResultChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProResultFilterSelected implements BaseEvent {
  event_type = 'PRO Result Filter Selected';

  constructor(
    public event_properties: ProResultFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProResultMessageSent implements BaseEvent {
  event_type = 'PRO Result Message Sent';

  constructor(
    public event_properties: ProResultMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProViewOpened implements BaseEvent {
  event_type = 'PRO View Opened';

  constructor(
    public event_properties: ProViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuizMissionOpened implements BaseEvent {
  event_type = 'Quiz Mission Opened';

  constructor(
    public event_properties: QuizMissionOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendedMessagesExited implements BaseEvent {
  event_type = 'Recommended Messages Exited';

  constructor(
    public event_properties: RecommendedMessagesExitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendedMessagesOpened implements BaseEvent {
  event_type = 'Recommended Messages Opened';

  constructor(
    public event_properties: RecommendedMessagesOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegistrationViewOpened implements BaseEvent {
  event_type = 'Registration View Opened';

  constructor(
    public event_properties: RegistrationViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SmsFilterSelected implements BaseEvent {
  event_type = 'SMS Filter Selected';

  constructor(
    public event_properties: SmsFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SmsInviteSent implements BaseEvent {
  event_type = 'SMS Invite Sent';

  constructor(
    public event_properties: SmsInviteSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SmsSent implements BaseEvent {
  event_type = 'SMS Sent';

  constructor(
    public event_properties: SmsSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TaskOpened implements BaseEvent {
  event_type = 'Task Opened';

  constructor(
    public event_properties: TaskOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TaskPaginatorClicked implements BaseEvent {
  event_type = 'Task Paginator Clicked';

  constructor(
    public event_properties: TaskPaginatorClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TaskRowsChanged implements BaseEvent {
  event_type = 'Task Rows Changed';
}

export class UserGuideOpened implements BaseEvent {
  event_type = 'User Guide Opened';

  constructor(
    public event_properties: UserGuideOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserInformationViewOpened implements BaseEvent {
  event_type = 'User Information View Opened';

  constructor(
    public event_properties: UserInformationViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserListViewOpened implements BaseEvent {
  event_type = 'User List View Opened';

  constructor(
    public event_properties: UserListViewOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserReportCopied implements BaseEvent {
  event_type = 'User Report Copied';

  constructor(
    public event_properties: UserReportCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserReportExported implements BaseEvent {
  event_type = 'User Report Exported';

  constructor(
    public event_properties: UserReportExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserStatusFilterSelected implements BaseEvent {
  event_type = 'User Status Filter Selected';

  constructor(
    public event_properties: UserStatusFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserTypeFilterSelected implements BaseEvent {
  event_type = 'User Type Filter Selected';

  constructor(
    public event_properties: UserTypeFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WeekFilterSelected implements BaseEvent {
  event_type = 'Week Filter Selected';

  constructor(
    public event_properties: WeekFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WeekPointsViewed implements BaseEvent {
  event_type = 'Week Points Viewed';

  constructor(
    public event_properties: WeekPointsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Access Management Page Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Access%20Management%20Page%20Opened)
   *
   * Event triggered when a user opens the access management page
   *
   * @param options Amplitude event options.
   */
  accessManagementPageOpened(
    options?: EventOptions,
  ) {
    return this.track(new AccessManagementPageOpened(), options);
  }

  /**
   * Accordion Expanded
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Accordion%20Expanded)
   *
   * Event to track when a user expands an accordion menu to view additional content
   *
   * @param properties The event's properties (e.g. topic)
   * @param options Amplitude event options.
   */
  accordionExpanded(
    properties: AccordionExpandedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccordionExpanded(properties), options);
  }

  /**
   * Action Awaiting Meal Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Action%20Awaiting%20Meal%20Selected)
   *
   * This event fires when a care manager clicks the 'New Meal to Review' action awaiting icon on the user list
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  actionAwaitingMealSelected(
    properties: ActionAwaitingMealSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionAwaitingMealSelected(properties), options);
  }

  /**
   * Action Awaiting Message Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Action%20Awaiting%20Message%20Selected)
   *
   * This event fires when a care manager select the message awaiting icon on the user list.
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  actionAwaitingMessageSelected(
    properties: ActionAwaitingMessageSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionAwaitingMessageSelected(properties), options);
  }

  /**
   * Action Awaiting PRO Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Action%20Awaiting%20PRO%20Selected)
   *
   * This event fires when a care manager clicks the 'New PRO to review' icon on the user list
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  actionAwaitingProSelected(
    properties: ActionAwaitingProSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionAwaitingProSelected(properties), options);
  }

  /**
   * Address Inserted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Address%20Inserted)
   *
   * This event fires when a care manager inserts an address into the chat box
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  addressInserted(
    properties: AddressInsertedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddressInserted(properties), options);
  }

  /**
   * Assigned To Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Assigned%20To%20Filter%20Selected)
   *
   * This event fires when a care manager filters on the Assigned To drop down on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  assignedToFilterSelected(
    properties: AssignedToFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssignedToFilterSelected(properties), options);
  }

  /**
   * Brand Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Brand%20Filter%20Selected)
   *
   * This event fires when a care managers filters on the Brand filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  brandFilterSelected(
    properties: BrandFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrandFilterSelected(properties), options);
  }

  /**
   * Bulk Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Bulk%20Message%20Sent)
   *
   * This event fires when a bulk message is sent from the program section using the bulk message button
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  bulkMessageSent(
    properties: BulkMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkMessageSent(properties), options);
  }

  /**
   * Calendar View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Calendar%20View%20Opened)
   *
   * This event fires when a care manager selects the calendar view in the medication section
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  calendarViewOpened(
    properties: CalendarViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarViewOpened(properties), options);
  }

  /**
   * Care Priority Marked Done
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Care%20Priority%20Marked%20Done)
   *
   * Event indicating that a care priority task has been marked as done
   *
   * @param properties The event's properties (e.g. carePriority)
   * @param options Amplitude event options.
   */
  carePriorityMarkedDone(
    properties: CarePriorityMarkedDoneProperties,
    options?: EventOptions,
  ) {
    return this.track(new CarePriorityMarkedDone(properties), options);
  }

  /**
   * Care Priority Tab Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Care%20Priority%20Tab%20Changed)
   *
   * Event to track when a user changes Care Priority in focus in the Context Panel
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  carePriorityTabChanged(
    properties: CarePriorityTabChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CarePriorityTabChanged(properties), options);
  }

  /**
   * Coach Assigned
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Coach%20Assigned)
   *
   * This event fires when a user is assigned a care manager
   *
   * Owner: Sarit Seal
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  coachAssigned(
    properties: CoachAssignedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CoachAssigned(properties), options);
  }

  /**
   * Coach Invited
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Coach%20Invited)
   *
   * This event fires when a lead care manager invites another care manager
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  coachInvited(
    properties: CoachInvitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CoachInvited(properties), options);
  }

  /**
   * Community View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Community%20View%20Opened)
   *
   * This event fires when a care manager opens the Community View section in the Care Portal
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  communityViewOpened(
    properties: CommunityViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CommunityViewOpened(properties), options);
  }

  /**
   * Device Order Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Device%20Order%20Selected)
   *
   * This event fires when a care manager selects the Device Order option on the User page 
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. deviceName)
   * @param options Amplitude event options.
   */
  deviceOrderSelected(
    properties: DeviceOrderSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeviceOrderSelected(properties), options);
  }

  /**
   * Device Order Status Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Device%20Order%20Status%20Selected)
   *
   * This event fires when a care manager clicks on the order status button to bring up more details on the order status 
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. deviceOrderDate)
   * @param options Amplitude event options.
   */
  deviceOrderStatusSelected(
    properties: DeviceOrderStatusSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeviceOrderStatusSelected(properties), options);
  }

  /**
   * Device Ordered
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Device%20Ordered)
   *
   * This event fires when a care manager has populated the required fields and hit the Order button
   *
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. deviceId)
   * @param options Amplitude event options.
   */
  deviceOrdered(
    properties: DeviceOrderedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeviceOrdered(properties), options);
  }

  /**
   * Disease Type Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Disease%20Type%20Filter%20Selected)
   *
   * This event fires when a care manager filters on the Disease Type drop down on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  diseaseTypeFilterSelected(
    properties: DiseaseTypeFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DiseaseTypeFilterSelected(properties), options);
  }

  /**
   * Document Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Document%20Deleted)
   *
   * This event fires when a care manager deletes a document
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  documentDeleted(
    properties: DocumentDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentDeleted(properties), options);
  }

  /**
   * Document Downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Document%20Downloaded)
   *
   * This event fires when a coach downloads a document
   *
   * Owner: Sindri Már Kaldal Sigurjónsson
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  documentDownloaded(
    properties: DocumentDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentDownloaded(properties), options);
  }

  /**
   * Document Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Document%20Uploaded)
   *
   * This event fires when a coach uploads a document
   *
   * Owner: bergdis@sidekickhealth.com
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  documentUploaded(
    properties: DocumentUploadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentUploaded(properties), options);
  }

  /**
   * Document View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Document%20View%20Opened)
   *
   * This event fires when a care managers opens the document section on the user page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  documentViewOpened(
    properties: DocumentViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentViewOpened(properties), options);
  }

  /**
   * Food Journal View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Food%20Journal%20View%20Opened)
   *
   * This event fires when a care manager opens the Food Journal section on the user page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  foodJournalViewOpened(
    properties: FoodJournalViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FoodJournalViewOpened(properties), options);
  }

  /**
   * Health Card Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Health%20Card%20Opened)
   *
   * This event fires when a care manager opens one of the healthcard tiles on the user information screen
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  healthCardOpened(
    properties: HealthCardOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HealthCardOpened(properties), options);
  }

  /**
   * Layer Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Layer%20Filter%20Selected)
   *
   * This event fires when a care mangers filters on the Layer filter on the User page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  layerFilterSelected(
    properties: LayerFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LayerFilterSelected(properties), options);
  }

  /**
   * LOB Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/LOB%20Filter%20Selected)
   *
   * This event fires when a care managers filer on the LOB (Line of Business) filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  lobFilterSelected(
    properties: LobFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LobFilterSelected(properties), options);
  }

  /**
   * Meal Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Meal%20Message%20Sent)
   *
   * This event fires when a care managers sends a message in response to a users food logging activity
   *
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  mealMessageSent(
    properties: MealMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MealMessageSent(properties), options);
  }

  /**
   * Medication Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Medication%20Filter%20Selected)
   *
   * This event fires when a coach selects a medication from the medication filter drop down
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  medicationFilterSelected(
    properties: MedicationFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MedicationFilterSelected(properties), options);
  }

  /**
   * Medication Reminder Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Medication%20Reminder%20Sent)
   *
   * This event fires when a care manager selects the Send Message button in the Medication View. 
   *
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  medicationReminderSent(
    properties: MedicationReminderSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MedicationReminderSent(properties), options);
  }

  /**
   * Medication View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Medication%20View%20Opened)
   *
   * This event fires when a coach opens a care manager opens the medication section on the user page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  medicationViewOpened(
    properties: MedicationViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MedicationViewOpened(properties), options);
  }

  /**
   * Member State Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Member%20State%20Filter%20Selected)
   *
   * This event fires when a care managers filters on the Member State filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  memberStateFilterSelected(
    properties: MemberStateFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MemberStateFilterSelected(properties), options);
  }

  /**
   * Message Chat Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Chat%20Closed)
   *
   * This event fires when a care manager closes down the message chat
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageChatClosed(
    properties: MessageChatClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageChatClosed(properties), options);
  }

  /**
   * Message Chat Maximised
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Chat%20Maximised)
   *
   * This event fires when a care manager maximises the message chat window
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageChatMaximised(
    properties: MessageChatMaximisedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageChatMaximised(properties), options);
  }

  /**
   * Message Chat Minimised
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Chat%20Minimised)
   *
   * This event fires when a care manager minimises the message chat window
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageChatMinimised(
    properties: MessageChatMinimisedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageChatMinimised(properties), options);
  }

  /**
   * Message Chat Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Chat%20Opened)
   *
   * This event fires when a care manager opens the message chat by clicking the Messages Icon
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageChatOpened(
    properties: MessageChatOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageChatOpened(properties), options);
  }

  /**
   * Message Copy Text Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Copy%20Text%20Selected)
   *
   * This event fires when a care manager selects the Copy Text option in the chat window drop down
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageCopyTextSelected(
    properties: MessageCopyTextSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageCopyTextSelected(properties), options);
  }

  /**
   * Message Edit Mode Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Edit%20Mode%20Selected)
   *
   * This event fires when a care manager puts the recommended message in edit mode by clicking the edit button
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageEditModeSelected(
    properties: MessageEditModeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageEditModeSelected(properties), options);
  }

  /**
   * Message Notification Status Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Notification%20Status%20Filter)
   *
   * This event fires when a care manager filter the user list by Message Notification Status
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageNotificationStatusFilter(
    properties: MessageNotificationStatusFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageNotificationStatusFilter(properties), options);
  }

  /**
   * Message Preview Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Preview%20Selected)
   *
   * This event fires when a care manager selects Preview when in message edit mode
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messagePreviewSelected(
    properties: MessagePreviewSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessagePreviewSelected(properties), options);
  }

  /**
   * Message Reply Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Reply%20Selected)
   *
   * This event fires when a care manager selects the Reply option in the chat window drop down 
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageReplySelected(
    properties: MessageReplySelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageReplySelected(properties), options);
  }

  /**
   * Message Reset Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Reset%20Selected)
   *
   * This event fires when a care manager selects Reset when in message edit mode
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageResetSelected(
    properties: MessageResetSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageResetSelected(properties), options);
  }

  /**
   * Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Sent)
   *
   * This event fires when a message is sent from the user section of the care portal
   *
   * Owner: Sarit Seal
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageSent(
    properties: MessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSent(properties), options);
  }

  /**
   * Message Suggestion Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Suggestion%20Changed)
   *
   * Event to track when a user changes a suggested message in the context panel
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  messageSuggestionChanged(
    properties: MessageSuggestionChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSuggestionChanged(properties), options);
  }

  /**
   * Message Suggestion Copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Suggestion%20Copied)
   *
   * Event indicating that a user has copied a message suggestion
   *
   * @param properties The event's properties (e.g. messageSuggestionId)
   * @param options Amplitude event options.
   */
  messageSuggestionCopied(
    properties: MessageSuggestionCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSuggestionCopied(properties), options);
  }

  /**
   * Message Thread Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Message%20Thread%20Changed)
   *
   * This event fires when the care mangers toggles to another recipient in the chat window 
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  messageThreadChanged(
    properties: MessageThreadChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageThreadChanged(properties), options);
  }

  /**
   * Missions View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Missions%20View%20Opened)
   *
   * This event fires when a care manager opens the Missions section on the user page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  missionsViewOpened(
    properties: MissionsViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MissionsViewOpened(properties), options);
  }

  /**
   * My Programs Page Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/My%20Programs%20Page%20Opened)
   *
   * This event fires when a care manager lands on the My Programs page where they see an overview of the programs they have access to. Either after logging in or by navigating back to it
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  myProgramsPageOpened(
    properties: MyProgramsPageOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyProgramsPageOpened(properties), options);
  }

  /**
   * New SMS Invite Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/New%20SMS%20Invite%20Sent)
   *
   * This event is triggered when a care manager sends an SMS invite through the patient information window on the Registration page
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  newSmsInviteSent(
    properties: NewSmsInviteSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewSmsInviteSent(properties), options);
  }

  /**
   * Next Message Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Next%20Message%20Selected)
   *
   * This event fires when a care manager toggles to view the next recommended message
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  nextMessageSelected(
    properties: NextMessageSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextMessageSelected(properties), options);
  }

  /**
   * Next Step Graph View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Next%20Step%20Graph%20View%20Opened)
   *
   * This event fires when a Coach presses the Graph View tab in the Next Step feature
   *
   *
   * @param options Amplitude event options.
   */
  nextStepGraphViewOpened(
    options?: EventOptions,
  ) {
    return this.track(new NextStepGraphViewOpened(), options);
  }

  /**
   * Next Step Recommended Message Copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Next%20Step%20Recommended%20Message%20Copied)
   *
   * This event fires when a Coach copies a recommended message into the chat window in the Next Step feature
   *
   *
   *
   *
   * @param properties The event's properties (e.g. messageSuggestionId)
   * @param options Amplitude event options.
   */
  nextStepRecommendedMessageCopied(
    properties: NextStepRecommendedMessageCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextStepRecommendedMessageCopied(properties), options);
  }

  /**
   * Next Step Taken
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Next%20Step%20Taken)
   *
   * Event to track when a CM completes advances in the Task List in Next Step
   *
   * @param properties The event's properties (e.g. carePriorities)
   * @param options Amplitude event options.
   */
  nextStepTaken(
    properties: NextStepTakenProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextStepTaken(properties), options);
  }

  /**
   * Next Step User Metrics Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Next%20Step%20User%20Metrics%20Viewed)
   *
   * This event fires when a Coach presses on the User Metrics tab in the Next Step feature
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  nextStepUserMetricsViewed(
    options?: EventOptions,
  ) {
    return this.track(new NextStepUserMetricsViewed(), options);
  }

  /**
   * Note Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Note%20Added)
   *
   * This event fires when a care manager adds a note in the Care Portal relating to a specifc user
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  noteAdded(
    properties: NoteAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NoteAdded(properties), options);
  }

  /**
   * Outreach Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Outreach%20Filter%20Selected)
   *
   * This event fires when a care managers filters on the Outreach filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  outreachFilterSelected(
    properties: OutreachFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OutreachFilterSelected(properties), options);
  }

  /**
   * Patient Discharged
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Patient%20Discharged)
   *
   * This event fires when a user is discharged by a coach
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  patientDischarged(
    properties: PatientDischargedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PatientDischarged(properties), options);
  }

  /**
   * Patient Restarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Patient%20Restarted)
   *
   * This event fires when a care manager restarts a user in a program
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  patientRestarted(
    properties: PatientRestartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PatientRestarted(properties), options);
  }

  /**
   * Performance View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Performance%20View%20Opened)
   *
   * This event fires when a care manager opens the Performance section on the user page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  performanceViewOpened(
    properties: PerformanceViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerformanceViewOpened(properties), options);
  }

  /**
   * Plan State Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Plan%20State%20Filter%20Selected)
   *
   * This event fires when a care manager filters on the plan state filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  planStateFilterSelected(
    properties: PlanStateFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlanStateFilterSelected(properties), options);
  }

  /**
   * Previous Message Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Previous%20Message%20Selected)
   *
   * This event fires when the care manager toggles to the previous recommended message
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  previousMessageSelected(
    properties: PreviousMessageSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviousMessageSelected(properties), options);
  }

  /**
   * PRO Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/PRO%20Filter%20Selected)
   *
   * This event fires when a care manager makes a selection in the PRO filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  proFilterSelected(
    properties: ProFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProFilterSelected(properties), options);
  }

  /**
   * PRO Result Approved
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/PRO%20Result%20Approved)
   *
   * This event fires when a care managers approve a users PRO results
   *
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  proResultApproved(
    properties: ProResultApprovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProResultApproved(properties), options);
  }

  /**
   * PRO Result Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/PRO%20Result%20Changed)
   *
   * This event fires when a care managers changes the PRO result
   *
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  proResultChanged(
    properties: ProResultChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProResultChanged(properties), options);
  }

  /**
   * PRO Result Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/PRO%20Result%20Filter%20Selected)
   *
   * This event fires when a care manager filters on the PRO Result filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  proResultFilterSelected(
    properties: ProResultFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProResultFilterSelected(properties), options);
  }

  /**
   * PRO Result Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/PRO%20Result%20Message%20Sent)
   *
   * This event fires when a care managers sends a message to the suer in response to their PRO result
   *
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  proResultMessageSent(
    properties: ProResultMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProResultMessageSent(properties), options);
  }

  /**
   * PRO View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/PRO%20View%20Opened)
   *
   * This event fires when a care manager opens the PRO section on the user page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  proViewOpened(
    properties: ProViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProViewOpened(properties), options);
  }

  /**
   * Quiz Mission Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Quiz%20Mission%20Opened)
   *
   * This event fires when a care managers opens a completed quiz mission to view the result
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  quizMissionOpened(
    properties: QuizMissionOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuizMissionOpened(properties), options);
  }

  /**
   * Recommended Messages Exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Recommended%20Messages%20Exited)
   *
   * This event fires when a care managers exists the recommended messages window by clicking the X in the top right corner
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  recommendedMessagesExited(
    properties: RecommendedMessagesExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendedMessagesExited(properties), options);
  }

  /**
   * Recommended Messages Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Recommended%20Messages%20Opened)
   *
   * This event fires when a care manager opens the recommended messages feature from the chat window
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  recommendedMessagesOpened(
    properties: RecommendedMessagesOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendedMessagesOpened(properties), options);
  }

  /**
   * Registration View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Registration%20View%20Opened)
   *
   * This event fires when a care manager opens the Registration view section in the Care Portal
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  registrationViewOpened(
    properties: RegistrationViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationViewOpened(properties), options);
  }

  /**
   * SMS Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/SMS%20Filter%20Selected)
   *
   * This event fires when a care managers filters on the SMS filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  smsFilterSelected(
    properties: SmsFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SmsFilterSelected(properties), options);
  }

  /**
   * SMS Invite Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/SMS%20Invite%20Sent)
   *
   * This event is triggers when a care managers sends an SMS invite to a member using the Invite Patient button 
   *
   *
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  smsInviteSent(
    properties: SmsInviteSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new SmsInviteSent(properties), options);
  }

  /**
   * SMS Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/SMS%20Sent)
   *
   * This event fires when an SMS is sent through the care portal to a user from a care manager. This only captures SMS sent from the User page in the care portal and not invites to external users 
   *
   *
   *
   *
   * Owner: Sindri Már Kaldal Sigurjónsson
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  smsSent(
    properties: SmsSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new SmsSent(properties), options);
  }

  /**
   * Task Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Task%20Opened)
   *
   * Event indicating when a CM task has been opened
   *
   * @param properties The event's properties (e.g. carePriorities)
   * @param options Amplitude event options.
   */
  taskOpened(
    properties: TaskOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TaskOpened(properties), options);
  }

  /**
   * Task Paginator Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Task%20Paginator%20Clicked)
   *
   * Event to track when a user clicks on a task paginator
   *
   * @param properties The event's properties (e.g. direction)
   * @param options Amplitude event options.
   */
  taskPaginatorClicked(
    properties: TaskPaginatorClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TaskPaginatorClicked(properties), options);
  }

  /**
   * Task Rows Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Task%20Rows%20Changed)
   *
   * Event to track when a CM changes the rows per page displayed in the Care Manager Tasks component
   *
   * @param options Amplitude event options.
   */
  taskRowsChanged(
    options?: EventOptions,
  ) {
    return this.track(new TaskRowsChanged(), options);
  }

  /**
   * User Guide Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/User%20Guide%20Opened)
   *
   * This event fires when a care manager opens the User Guide
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  userGuideOpened(
    properties: UserGuideOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserGuideOpened(properties), options);
  }

  /**
   * User Information View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/User%20Information%20View%20Opened)
   *
   * This event fires when a care manager opens the Information section on the user page by clicking on a user in the user list
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  userInformationViewOpened(
    properties: UserInformationViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserInformationViewOpened(properties), options);
  }

  /**
   * User List View Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/User%20List%20View%20Opened)
   *
   * This event fires when a care manager opens the user list section
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  userListViewOpened(
    properties: UserListViewOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserListViewOpened(properties), options);
  }

  /**
   * User Report Copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/User%20Report%20Copied)
   *
   * This event fires when a care manager selects the Copy option in the Export PDF window
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  userReportCopied(
    properties: UserReportCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserReportCopied(properties), options);
  }

  /**
   * User Report Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/User%20Report%20Exported)
   *
   * This event fires when a coach exports user information selecting the print option after opening the Export PDF window.
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  userReportExported(
    properties: UserReportExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserReportExported(properties), options);
  }

  /**
   * User Status Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/User%20Status%20Filter%20Selected)
   *
   * This event fires when a care manager filters on the User Status on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  userStatusFilterSelected(
    properties: UserStatusFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserStatusFilterSelected(properties), options);
  }

  /**
   * User Type Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/User%20Type%20Filter%20Selected)
   *
   * This event fires when a care managers filters on the User Type filter on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  userTypeFilterSelected(
    properties: UserTypeFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserTypeFilterSelected(properties), options);
  }

  /**
   * Week Filter Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Week%20Filter%20Selected)
   *
   * This event fires when a care managers filters on week (week in program) on the Program page
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  weekFilterSelected(
    properties: WeekFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WeekFilterSelected(properties), options);
  }

  /**
   * Week Points Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sidekickhealth/Care%20Portal/events/next-step-launch/latest/Week%20Points%20Viewed)
   *
   * This event fires when a care managers hover over the last 7 day progress bar on the program page to view the week points hovercard
   *
   *
   *
   *
   * Owner: Linnea Lindström
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  weekPointsViewed(
    properties: WeekPointsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WeekPointsViewed(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
