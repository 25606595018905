import { useEffect, useState } from "react";

import styles from "./Pagination.module.scss";

import ArrowForward from "~/assets/arrow-right.svg";

type PaginationProps = {
  pagesAmount: number;
  onPageClick: (page: number) => void;
  className?: string;
  hidePagination?: boolean;
  chosenPage: number;
};

const Pagination = ({
  pagesAmount,
  onPageClick,
  className,
  hidePagination,
  chosenPage
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(chosenPage);
  const [middlePages, setMiddlePages] = useState([3, 4, 5]);

  const updateMiddlePages = (page: number) => {
    if (page < 5) {
      return [3, 4, 5];
    } else if (page > pagesAmount - 4) {
      return [pagesAmount - 4, pagesAmount - 3, pagesAmount - 2];
    } else {
      return [page - 1, page, page + 1];
    }
  };

  const handlePageClick = (page: number) => {
    setMiddlePages(updateMiddlePages(page));
    setCurrentPage(page);
    onPageClick(page);
  };

  useEffect(() => {
    if (chosenPage && pagesAmount) {
      setCurrentPage(chosenPage);
      setMiddlePages(updateMiddlePages(chosenPage));
    }
  }, [chosenPage, pagesAmount]);

  if (!pagesAmount || hidePagination) return null;

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <button
        disabled={currentPage === 1}
        className={`${styles.navButton} ${styles.back}`}
        onClick={() => handlePageClick(currentPage - 1)}
      >
        <img src={ArrowForward} alt="Send Message" />
      </button>
      {pagesAmount <= 6 && (
        <ul className={styles.pages}>
          {Array.from(Array(pagesAmount).keys()).map((index) => (
            <li
              key={`pagination-${index}`}
              onClick={() => handlePageClick(index + 1)}
              className={`${styles.page} ${
                currentPage === index + 1 ? styles.selected : ""
              }`}
            >
              {index + 1}
            </li>
          ))}
        </ul>
      )}

      {pagesAmount > 6 && (
        <ul className={styles.pages}>
          <li
            onClick={() => handlePageClick(1)}
            className={`${styles.page} ${
              currentPage === 1 ? styles.selected : ""
            }`}
          >
            {1}
          </li>

          {currentPage < 5 ? (
            <li
              onClick={() => handlePageClick(2)}
              className={`${styles.page} ${
                currentPage === 2 ? styles.selected : ""
              }`}
            >
              {2}
            </li>
          ) : (
            <li className={styles.dots}>...</li>
          )}
          {middlePages.map((index) => (
            <li
              key={`pagination-middle-${index}`}
              onClick={() => handlePageClick(index)}
              className={`${styles.page} ${
                currentPage === index ? styles.selected : ""
              }`}
            >
              {index}
            </li>
          ))}
          {currentPage > pagesAmount - 3 ? (
            <li
              onClick={() => handlePageClick(pagesAmount - 1)}
              className={`${styles.page} ${
                currentPage === pagesAmount - 1 ? styles.selected : ""
              }`}
            >
              {pagesAmount - 1}
            </li>
          ) : (
            <li className={styles.dots}>...</li>
          )}

          <li
            onClick={() => handlePageClick(pagesAmount)}
            className={`${styles.page} ${
              currentPage === pagesAmount ? styles.selected : ""
            }`}
          >
            {pagesAmount}
          </li>
        </ul>
      )}

      <button
        className={styles.navButton}
        disabled={currentPage === pagesAmount}
        onClick={() => handlePageClick(currentPage + 1)}
      >
        <img src={ArrowForward} alt="Send Message" />
      </button>
    </div>
  );
};

export default Pagination;
