import { DateTime } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import MissionCard from "./MissionCard";
import styles from "./MissionsList.module.scss";
import { MissionPageContext } from "./missionsPageContext";

import { MissionCategory } from "~/constants/missionCategories";
import { MissionsSlotDay, MissionTask } from "~/typing/sidekickTypes";

type MissionsListProps = {
  day: string;
  slotDays: MissionsSlotDay[];
};

const MissionsList = ({ day, slotDays }: MissionsListProps) => {
  const { t } = useTranslation();

  const { clickableMissionIds, quizzes } = useContext(MissionPageContext);

  const slotDay = slotDays?.find((mission) => {
    return DateTime.fromISO(mission.date, { zone: "utc" }).toISODate() === day;
  });

  const proTasks: MissionTask[] = [];
  const quizTasks: MissionTask[] = [];
  const preSelectionTasks: MissionTask[] = [];
  const mandatoryTasks: MissionTask[] = [];
  slotDay?.tasks?.forEach((task) => {
    if (task.categoryId === MissionCategory.Survey) {
      if (clickableMissionIds?.includes(task.missionId)) {
        preSelectionTasks.push(task);
      } else if (quizzes?.some((quiz) => quiz.surveyName === task.name)) {
        quizTasks.push(task);
      } else {
        proTasks.push(task);
      }
    } else {
      mandatoryTasks.push(task);
    }
  });

  const renderMissionCardList = (tasks: MissionTask[], heading) => {
    if (tasks.length === 0) {
      return null;
    }

    return (
      <>
        <div className={styles.heading}>{heading}</div>
        {tasks.map((mission, index) => (
          <MissionCard
            key={`${mission.missionId}-${index}`}
            mission={mission}
            day={day}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {renderMissionCardList(proTasks, t("general.pro"))}
      {renderMissionCardList(preSelectionTasks, t("valuePreselection.title"))}
      {renderMissionCardList(quizTasks, t("general.quizzes"))}
      {renderMissionCardList(mandatoryTasks, t("general.missions"))}
    </>
  );
};

export default MissionsList;
