import styles from "./AvatarGroup.module.scss";

import Avatar from "../avatar/Avatar";

import { AvatarUser } from "~/typing/carePortalTypes";

type AvatarGroupProps = {
  users: AvatarUser[];
  maxBubbles?: number;
  onClick?: () => void;
  includeTitleOnHover?: boolean;
};

const AvatarGroup = ({
  users,
  maxBubbles = 3,
  onClick,
  includeTitleOnHover = true
}: AvatarGroupProps) => {
  const displayMoreIcon = users.length > maxBubbles;

  return (
    <div
      onClick={onClick}
      className={`${styles.wrapper} ${onClick ? styles.clickable : ""}`}
      data-testid="avatar-group"
    >
      {users.slice(0, maxBubbles).map((user, index) => (
        <Avatar
          user={user}
          key={index}
          size="md"
          shape="circle"
          title={includeTitleOnHover ? user.fullName : undefined}
        />
      ))}
      {displayMoreIcon && (
        <div data-testid="more-icon" className={styles.more}>
          <span>+{users.length - maxBubbles}</span>
        </div>
      )}
    </div>
  );
};

export default AvatarGroup;
