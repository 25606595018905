import { DEFAULT_FILTER_VALUE } from "~/constants/filters";

export default function getDiseaseNameOptions() {
  return [
    { value: DEFAULT_FILTER_VALUE, text: "All" },
    {
      text: "Cancer-Breast",
      value: "Cancer-Breast"
    },
    {
      text: "Cancer-Colon",
      value: "Cancer-Colon"
    },
    {
      text: "Cancer-Lung",
      value: "Cancer-Lung"
    },
    {
      text: "Cancer-Prostate",
      value: "Cancer-Prostate"
    },
    {
      text: "Cancer-Skin",
      value: "Cancer-Skin"
    },
    {
      text: "Cancer-Other",
      value: "Cancer-Other"
    }
  ];
}
