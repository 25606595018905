// Support for markdown (that will problably become another jira task)
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import styles from "./BulkMessageModal.module.scss";

import api from "~/api/api";
import Modal from "~/components/modal/Modal";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { CustomListFilterData, SelectOption } from "~/typing/carePortalTypes";
import { BaseUserInfo, CoachUser } from "~/typing/sidekickTypes";

enum Filters {
  Coach = "coachFilter",
  ProStatus = "proStatusFilter",
  Activity = "activityFilter",
  User = "userFilter",
  PreviousSelection = "prevSelectionFilter",
  NewAction = "newActionFilter"
}

interface Option {
  label: string;
  value: string;
  type: string;
  colour?: string;
  count: number;
}

interface BulkMessageModalProps {
  previousSelectedUsers: CoachUser[];
  filterSelectData: CustomListFilterData[];
  filterUsersByActivity: (users: CoachUser[], value?: string[]) => CoachUser[];
  filterUsersByNewAction: (users: CoachUser[]) => CoachUser[];
  filterUsersByProStatus: (users: CoachUser[], value?: string) => CoachUser[];
  filterUsersByCoach: (users: CoachUser[], value?: string) => CoachUser[];
  locale: string;
  onClose: () => void;
  programId: string;
  users: CoachUser[];
}

const BulkMessageModal = ({
  onClose,
  programId,
  locale,
  users,
  filterUsersByProStatus,
  filterUsersByNewAction,
  filterUsersByActivity,
  filterUsersByCoach,
  filterSelectData,
  previousSelectedUsers
}: BulkMessageModalProps) => {
  const allOptions: Option[] = [];
  const prevUsers = previousSelectedUsers
    .filter((user) => user && user.user && !user.leftDate)
    .map((user) => user.user);

  const { t } = useTranslation();
  const { trackBulkMessageSent } = useAmplitudeTracking();
  const [loading, setLoading] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [bulkMessageText, setBulkMessageText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<BaseUserInfo[]>(prevUsers);
  const [filterValues, setFilterValues] = useState<Option[]>(
    prevUsers && prevUsers.length > 0
      ? [
          {
            label: t("messages.yourselection", "Your selection"),
            value: Filters.PreviousSelection,
            type: Filters.PreviousSelection,
            count: prevUsers.length
          }
        ]
      : []
  );

  const sendBulkMessage = async (payload) => {
    const path = `/coach/programs/${programId}/locales/${locale}/sendBulkMessage`;
    return await api.post(path, payload);
  };

  const ignoredFilters = ["drop-out", "finished"];

  const resetState = () => {
    setLoading(false);
    setSendDisabled(false);
    setError("");
    setSuccess("");
    setBulkMessageText("");
  };

  const handleSubmitBulkMessage = async (e) => {
    e.preventDefault();
    setSendDisabled(true);

    setSuccess("");
    setError("");
    setLoading(true);

    await sendBulkMessage({
      text: bulkMessageText,
      recipients: filteredUsers.map((user) => user.userId)
    })
      .then(() => {
        trackBulkMessageSent(
          bulkMessageText,
          filteredUsers.length,
          filteredUsers.map((user) => user.userId)
        );

        setLoading(false);
        setSuccess(
          `${t("messages.successBulkMessage")} ${filteredUsers.length} ${t(
            "general.users"
          ).toLocaleLowerCase()}`
        );
      })
      .catch(() => {
        setSendDisabled(false);
        setLoading(false);
        setError(t("messages.errorBulkMessage"));
      });
  };

  const createOption = (
    option: SelectOption<string | number>,
    optionType: string,
    count: number
  ): Option => {
    return {
      label: option.text ?? "",
      value: option.value?.toString() ?? "",
      type: optionType,
      colour: option.colour,
      count: count
    };
  };

  const allUsers = users.filter((user) => {
    return user && user.user != null && !user.leftDate;
  });
  const findUserById = (id: string) => {
    return allUsers.find((user) => user.user.userId === id)?.user;
  };
  const getUserCountByFilter = (value: string, filterType: string): number => {
    if (filterType === Filters.User) {
      const user = findUserById(value);
      return user ? 1 : 0;
    }
    if (filterType === Filters.NewAction) {
      const newActionFilteredUsers = filterUsersByNewAction(allUsers);
      return newActionFilteredUsers.length;
    }
    if (filterType === Filters.Coach) {
      const coachFilteredUsers = filterUsersByCoach(allUsers, value);
      return coachFilteredUsers.length;
    }
    if (filterType === Filters.ProStatus) {
      const proFilteredUsers = filterUsersByProStatus(allUsers, value);
      return proFilteredUsers.length;
    }
    if (filterType === Filters.Activity) {
      const activityFilteredUsers = filterUsersByActivity(allUsers, [value]);

      return activityFilteredUsers.length;
    }
    if (filterType === Filters.PreviousSelection) {
      return previousSelectedUsers.length;
    }
    return 0;
  };

  filterSelectData
    .filter((data) => Object.values<string>(Filters).includes(data.key))
    .forEach((filter) => {
      filter.options.forEach((option) => {
        if (!ignoredFilters.includes(option.value?.toString() ?? "")) {
          const count = getUserCountByFilter(
            option.value?.toString() ?? "",
            filter.key
          );
          allOptions.push(createOption(option, filter.key, count));
        }
      });
    });

  allOptions.push({
    label: t("programUserList.actionsAwaiting", "Actions awaiting"),
    value: Filters.NewAction,
    type: Filters.NewAction,
    count: getUserCountByFilter("", Filters.NewAction)
  });
  allUsers.forEach((user) => {
    allOptions.push({
      label: user.user.fullName,
      value: user.user.userId,
      type: Filters.User,
      count: 1
    });
  });

  const handleSetFilter = async (filterValues: Option[]) => {
    setSendDisabled(false);
    setFilterValues(filterValues);
    const filteredUsers = await filterUsersWithFilters(filterValues);
    setFilteredUsers(filteredUsers);
  };

  const filterUsersWithFilters = async (filterValues) => {
    let users: BaseUserInfo[] = [];

    for (let index = 0; index < filterValues.length; index++) {
      const filterValue = filterValues[index];
      if (filterValue.type === Filters.User) {
        const user = findUserById(filterValue.value);
        if (user) users.push(user);
      }
      if (filterValue.type === Filters.NewAction) {
        const newActionFilteredUsers = filterUsersByNewAction(allUsers);
        newActionFilteredUsers.forEach((user) => {
          users.push(user.user);
        });
      }
      if (filterValue.type === Filters.ProStatus) {
        const proFilteredUsers = filterUsersByProStatus(
          allUsers,
          filterValue.value
        );
        proFilteredUsers.forEach((user) => {
          users.push(user.user);
        });
      }
      if (filterValue.type === Filters.Activity) {
        const activityFilteredUsers = filterUsersByActivity(allUsers, [
          filterValue.value
        ]);

        activityFilteredUsers.forEach((user) => {
          users.push(user.user);
        });
      }
      if (filterValue.type === Filters.PreviousSelection) {
        previousSelectedUsers.forEach((prevUser) => {
          allUsers.forEach((user) => {
            if (user.user?.userId === prevUser.user?.userId) {
              users.push(user.user);
            }
          });
        });
      }
      if (filterValue.type === Filters.Coach) {
        const coachFilteredUsers = filterUsersByCoach(
          allUsers,
          filterValue.value
        );

        coachFilteredUsers.forEach((user) => {
          users.push(user.user);
        });
      }
    }
    // make sure the array has no dupes
    users = Array.from(new Set(users));

    return users;
  };
  const handleSetMessage = (value) => {
    setSendDisabled(false);
    setBulkMessageText(value);
  };
  const formatOptionLabel = ({ label, count }) => (
    <div className={styles.optionLabel}>
      {label}{" "}
      {(count || count === 0 || count > 0) && (
        <span className={styles.optionLabelCount}>
          {count} {t("general.users")}
        </span>
      )}
    </div>
  );

  return (
    <Modal
      title={t("messages.newMessage", "New message")}
      onClose={onClose}
      className={styles.modal}
    >
      <SentryErrorBoundary
        resetState={resetState}
        transactionName="BulkMessageModal"
      >
        <div
          data-testid="bulkmessage-modal-multiselect-container"
          className={styles.multiSelectContainer}
        >
          <Select
            className={styles.multiSelect}
            defaultValue={filterValues}
            onChange={(value) => handleSetFilter(value as Option[])}
            isSearchable
            options={allOptions}
            formatOptionLabel={formatOptionLabel}
            isMulti
            placeholder={t("general.to", "To:")}
            classNamePrefix="multi-select"
          />
          <span
            className={`${styles.multiSelectCounter} ${
              filteredUsers.length < 1 ? styles.error : ""
            }`}
          >
            {filteredUsers.length} {t("general.users", "users")}
          </span>
        </div>

        <form onSubmit={handleSubmitBulkMessage} className={styles.form}>
          <div className={styles.inputContainer}>
            <textarea
              className={"input"}
              value={bulkMessageText}
              onChange={(e) => handleSetMessage(e.target.value)}
              placeholder={t(
                "messages.writeYourMessage",
                "Write your message..."
              )}
              rows={10}
              autoFocus
            />
          </div>
          <div className={styles.actions}>
            <div className={styles.error}>{error}</div>
            <div className={styles.success}>{success}</div>
            <button
              type="submit"
              className={"btn-primary btn-sm"}
              disabled={
                !bulkMessageText ||
                loading ||
                filteredUsers.length < 1 ||
                sendDisabled
              }
            >
              {t("general.send")}
            </button>
          </div>
        </form>
      </SentryErrorBoundary>
    </Modal>
  );
};

export default BulkMessageModal;
