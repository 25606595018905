import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./DischargePatient.module.scss";

import { stopUserProgram } from "~/api/requests/userRequests";
import Modal from "~/components/modal/Modal";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";

type DischargePatientProps = {
  close: (userDischarged: boolean) => void;
};

const DischargePatient = ({ close }: DischargePatientProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { trackPatientDischarged } = useAmplitudeTracking();

  const [loading, setLoading] = useState(false);

  const {
    program_id = "",
    user_id = "",
    locale = ""
  } = useParams<UserURLParams>();

  const removeExternalUser = async () => {
    setLoading(true);

    await stopUserProgram({
      programId: program_id,
      locale,
      userId: user_id
    })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: QueryKeyFactory.programs.users(program_id, locale)
        });
        displaySuccessToast({
          message: t("dischargePatient.successMessage")
        });
        trackPatientDischarged();
        close(true);
      })
      .catch(() => {
        displayErrorToast({
          message: t("dischargePatient.dischargeUserError")
        });
        close(false);
      });

    setLoading(false);
  };

  return (
    <>
      <Modal
        title={t("dischargePatient.title", "Discharge patient")}
        className={styles.dischargeModal}
        onClose={() => close(false)}
      >
        <div className={styles.header}>
          {t(
            "dischargePatient.subTitle",
            "Are you sure you want to discharge the patient from the program?"
          )}
        </div>
        <div>
          {t(
            "dischargePatient.description",
            "All the user progress in the program will be lost. You can not undo this action."
          )}
        </div>
        <div className={styles.buttons}>
          <button
            disabled={loading}
            className={styles.dischargeBtn}
            onClick={() => removeExternalUser()}
          >
            {t("dischargePatient.discharge", "Discharge")}
          </button>
          <button
            disabled={loading}
            className={styles.cancelBtn}
            onClick={() => close(false)}
          >
            {t("dischargePatient.cancel", "Cancel")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DischargePatient;
