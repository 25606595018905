export default function getMissionValueText(
  unit: string,
  currentValue: number,
  targetValue: number
) {
  const useUnits = unit && unit !== "valuecount" && unit !== "count";
  if (unit === "sec") {
    unit = "min";
    currentValue = Math.floor(currentValue / 60);
    targetValue = Math.floor(targetValue / 60);
  }
  return `${currentValue}${targetValue ? `/${targetValue}` : ""}${
    useUnits ? ` ${unit}` : ""
  }`;
}
