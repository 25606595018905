import { useQueries } from "@tanstack/react-query";

import api from "~/api/api";
import { Coach } from "~/typing/sidekickTypes";

type Props = {
  userIds: string[];
  disabled?: boolean;
};

export const useCoachesForIds = ({ userIds, disabled }: Props) => {
  const coaches = useQueries({
    queries: userIds.map((id) => ({
      queryKey: ["user", id],
      queryFn: () => api.get<Coach>(`/coach/users/${id}`),
      enabled: !disabled
    }))
  });
  return {
    coaches: coaches.map((coach) => coach.data),
    isPending: coaches.some((coach) => coach.isPending)
  };
};
