import styles from "./CustomSelect.module.scss";
import { OptionDependencies } from "./CustomSelect.types";

import { t } from "~/i18n";
import { SelectOption } from "~/typing/carePortalTypes";

export const Dependencies = ({
  dependencies
}: {
  dependencies: OptionDependencies;
}) => {
  if (!dependencies.dependencies.some((dependency) => dependency.visible))
    return null;
  return (
    <div className={styles.dependencies}>
      <p>Options affected by: </p>
      {dependencies.dependencies
        .filter((dependency) => dependency.visible)
        .map((dependency, index) => (
          <span key={index} className={styles.dependency}>
            {dependency.title}
          </span>
        ))}
    </div>
  );
};

export const SelectLabel = ({
  label,
  required,
  options
}: {
  label?: string;
  required?: boolean;
  options?: SelectOption[];
}) => {
  const visibleOptions = options?.filter((option) => !option.hide);

  return (
    <div className={styles.labelWrapper}>
      {label && (
        <label className="label label--block">
          {label}
          {required && <span className={styles.requiredStar}>*</span>}
        </label>
      )}
      {options && (
        <span className={styles.optionsCount}>
          {t("resources.amountAvailable", {
            available: visibleOptions?.length
          })}
        </span>
      )}
    </div>
  );
};
