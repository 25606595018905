import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import TopNav from "~/components/topNav/TopNav";
import { UserURLParams } from "~/typing/carePortalTypes";

export default function MentalNav() {
  const { t } = useTranslation();

  const { program_id, locale, user_id } = useParams<UserURLParams>();

  const navData = [
    {
      title: t("performance.mental.sleepQuality", "Sleep quality"),
      url: "sleep"
    },
    {
      title: t("performance.mental.stressLevel", "Stress level"),
      url: "stress"
    },
    {
      title: t("performance.mental.energyLevel", "Energy level"),
      url: "energy"
    }
  ];

  const baseUrl = `/program/${program_id}/locale/${locale}/users/${user_id}/performance/mental`;

  return <TopNav baseUrl={baseUrl} navData={navData} size="sm" />;
}
