import classNames from "classnames/bind";

import styles from "./PreviewForm.module.scss";

import Avatar from "~/components/avatar/Avatar";
import { PreviewFormData } from "~/typing/carePortalTypes";
import { CareManagerWithPcIs } from "~/typing/graphql/types";

const cx = classNames.bind(styles);

const Preview = ({ heading, values, withBorder }: PreviewFormData) => {
  return (
    <div className={styles.preview}>
      {heading && <p>{heading}</p>}
      <ul
        className={cx({
          previewList: true,
          border: withBorder
        })}
      >
        {values.filter(Boolean).map((value, index) => (
          <li className={styles.previewItem} key={index}>
            {value.renderOption()}
          </li>
        ))}
      </ul>
    </div>
  );
};

const CareManagerPreview = ({
  selectedCareManager
}: {
  selectedCareManager?: CareManagerWithPcIs;
}) => {
  return (
    <div className={styles.preview}>
      <p>Care manager</p>
      <div className={styles.careManager}>
        <Avatar
          user={{
            fullName: selectedCareManager?.careManager?.name ?? "",
            imageHref: selectedCareManager?.careManager?.imageHref
          }}
        />
        <div className={styles.careManagerInfo}>
          <p>{selectedCareManager?.careManager?.name}</p>
          <p>{selectedCareManager?.careManager?.email}</p>
        </div>
      </div>
    </div>
  );
};

type PreviewFormProps = {
  data: PreviewFormData[];
  selectedCareManager?: CareManagerWithPcIs;
};

const PreviewForm = ({ data, selectedCareManager }: PreviewFormProps) => {
  const hasAnyDataToShow = (previewData: PreviewFormData) => {
    if (previewData.hidden) return false;
    return previewData.values.some((value) => Boolean(value.renderOption()));
  };

  return (
    <div className={styles.wrapper}>
      {selectedCareManager && (
        <CareManagerPreview selectedCareManager={selectedCareManager} />
      )}
      {data.filter(hasAnyDataToShow).map((item, index) => (
        <Preview {...item} key={index} />
      ))}
    </div>
  );
};

export default PreviewForm;
