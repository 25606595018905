import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import styles from "./CustomTooltip.module.scss";

import getChartColorClass from "~/helpers/colours/getChartColorClass";

type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    payload: {
      date: DateTime;
    };
    dataKey: string;
    value: number;
  }[];
  units?: string;
  allDataKeys: string[];
};

export default function CustomTooltip({
  active,
  payload,
  units,
  allDataKeys
}: CustomTooltipProps) {
  const { t } = useTranslation();

  if (active && payload && payload[0]) {
    return (
      <div className={styles.CustomTooltip}>
        <ul>
          {payload.map((item, index) => {
            if (!item.payload.date) {
              return <li key={item.dataKey}></li>;
            }

            const date = item.payload.date
              .minus({ days: 7 * (payload.length - index - 1) })
              .toLocaleString();

            return (
              <li
                className={
                  styles[getChartColorClass(item.dataKey, allDataKeys)]
                }
                key={item.dataKey}
              >
                {`${date}: ${item.value} ${units ?? ""}`}
              </li>
            );
          })}
        </ul>
        {payload[0].dataKey === "weight" && (
          <p className={styles.label}>
            ({t("average.day", "Average of the day")})
          </p>
        )}
      </div>
    );
  }
  return <></>;
}
