import classNames from "classnames/bind";

import { CustomListColumn } from "./CustomList";
import styles from "./CustomList.module.scss";

import SortIcon from "../sortIcon/SortIcon";

import InfoIcon from "~/assets/question-circle.svg";
import { SortableData } from "~/typing/carePortalTypes";

const cx = classNames.bind(styles);

type CustomListColumnsProps<T> = {
  columns: CustomListColumn<T>[];
  sortingData?: SortableData;
  handleSetOrderBy: (data: SortableData) => void;
  order?: "asc" | "desc";
};

const CustomListColumns = <T,>({
  columns,
  sortingData,
  handleSetOrderBy,
  order
}: CustomListColumnsProps<T>) => {
  return (
    <tr>
      {columns.map(
        ({ heading: { className, text, sortable, tooltip } }, index) => {
          return (
            <th
              className={className}
              key={`table-heading-${text}-${index}`}
              title={tooltip?.text}
            >
              <div
                onClick={
                  sortable ? () => handleSetOrderBy(sortable) : undefined
                }
                className={cx({
                  sortable: sortable,
                  tableHead: true,
                  active: !!sortingData && sortingData === sortable
                })}
              >
                <p>{text}</p>
                {tooltip?.useIcon && (
                  <img className={styles.info} src={InfoIcon} alt="info" />
                )}
                {sortable ? (
                  <SortIcon
                    active={sortingData === sortable}
                    sortOrder={order}
                  />
                ) : null}
              </div>
            </th>
          );
        }
      )}
    </tr>
  );
};

export default CustomListColumns;
