import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import TopNav from "~/components/topNav/TopNav";
import { ProgramURLParams } from "~/typing/carePortalTypes";

export default function TeamNav() {
  const { t } = useTranslation();
  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();

  const navData = [
    {
      title: t("team.coaches"),
      url: "coaches"
    },
    {
      title: t("team.invites"),
      url: "invites"
    }
  ];

  const baseUrl = `/program/${program_id}/locale/${locale}/team`;

  return <TopNav baseUrl={baseUrl} navData={navData} />;
}
