import { createSlice } from "@reduxjs/toolkit";

type BannerState = {
  message?: string;
  action?: { onClick: () => void; text: string };
  type?: "success" | "error" | "warning" | "info";
};

const initialState: BannerState = {
  message: undefined,
  type: "info",
  action: undefined
};

type SetNavHeaderNotification = {
  payload: BannerState;
};

const navHeaderSlice = createSlice({
  name: "flash",
  initialState,
  reducers: {
    setNavBanner(state: BannerState, action: SetNavHeaderNotification) {
      state.message = action.payload.message;
      state.type = action.payload.type ?? "info";
      state.action = action.payload.action;
    },
    removeNavBanner(state: BannerState) {
      state.message = undefined;
      state.action = undefined;
      state.type = undefined;
    }
  },
  extraReducers: {}
});

const navHeaderReducer = navHeaderSlice.reducer;
export { navHeaderReducer };
export const { setNavBanner, removeNavBanner } = navHeaderSlice.actions;
