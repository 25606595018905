import classNames from "classnames";
import { useContext, useState } from "react";

import AddNoteModal from "./AddNoteModal";
import Note from "./Note";
import styles from "./PinnedNotes.module.scss";

import Plus from "~/assets/svgComponents/Plus";
import SkeletonPinnedNote from "~/components/skeletons/SkeletonPinnedNote";
import { welcomeJournalItemType } from "~/constants/notes";
import { useUserNotes } from "~/hooks/useUserNotes";
import { t } from "~/i18n";
import { TaskPageContext } from "~/pages/nextStep/TaskPage.context";
import { NoteInfo } from "~/typing/sidekickTypes";

const defaultNote: NoteInfo = {
  createdDate: new Date().toISOString(),
  text: t("notes.defaultNoteText"),
  itemType: welcomeJournalItemType.itemType,
  coachUser: {
    displayName: "Sidekicker",
    imageHref: "",
    title: "",
    userId: ""
  },
  id: "0"
};

interface PinnedNotesProps {
  showAllNotes: () => void;
}

const PinnedNotes = ({ showAllNotes }: PinnedNotesProps) => {
  const {
    notes,
    pinnedNotes,
    notesLoading,
    handleNotePinClick
  } = useUserNotes();
  const { newNextStep } = useContext(TaskPageContext);

  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const pinnedNotesToShow = pinnedNotes
    .sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
    .map((pinned) => notes.find((note) => note.id === pinned.journalItemId))
    .filter((note) => !note?.archived);

  const handleNoteModalToggle = () => {
    setShowAddNoteModal((prev) => !prev);
  };

  return notesLoading ? (
    <SkeletonPinnedNote />
  ) : (
    <div>
      <div className={styles.header}>
        <p>{t("informationPage.pinnedNotes")}:</p>
        <div className={styles.controls}>
          <button
            className={`${styles.iconButton} btn-secondary btn-sm `}
            onClick={handleNoteModalToggle}
          >
            <Plus />
          </button>
          <button
            className={classNames(styles.button, "btn-secondary btn-sm")}
            onClick={showAllNotes}
          >
            <p>{t("notes.viewAll")}</p>
          </button>
        </div>
      </div>
      <div
        data-testid="pinned-notes-noteswrapper"
        className={classNames(styles.notesWrapper, {
          [styles.newNextStep]: newNextStep
        })}
      >
        {pinnedNotesToShow.map((note) =>
          note ? (
            <Note
              note={note}
              key={note.id}
              onPinClick={() => handleNotePinClick(note)}
            />
          ) : null
        )}
        {notesLoading && <SkeletonPinnedNote />}
        {!notesLoading && pinnedNotesToShow.length === 0 && (
          <Note note={defaultNote} onPinClick={() => undefined} />
        )}
        {showAddNoteModal && (
          <AddNoteModal
            showModal={showAddNoteModal}
            onClose={handleNoteModalToggle}
          />
        )}
      </div>
    </div>
  );
};

export default PinnedNotes;
