import { createBrowserHistory } from "history";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import Login from "./Login";

import { AppDispatch, RootState } from "../../state/store";

import { login } from "~/auth/authRedcuer";
import NarrowLayout from "~/components/layout/NarrowLayout";
import { displayWarningToast } from "~/helpers/toast/displayToast";
import useLoggedIn from "~/hooks/useLoggedIn";
import useUrlQuery from "~/hooks/useUrlQuery";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useUrlQuery();
  const isLoggedIn = useLoggedIn();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(
    (state: RootState) => state.auth.loginstatus === "loading"
  );
  const history = createBrowserHistory();
  const error = useSelector((state: RootState) => state.auth.loginerror);
  const { t } = useTranslation();

  useEffect(() => {
    if (query.get("sessionExpired") === null) return;
    toast.remove();
    displayWarningToast({
      message: t("login.errors.sessionExpired")
    });

    history.push("/login");
  }, [query]);

  const { from } = (location.state as { from: string }) || {
    from: { pathname: "/" }
  };

  const handleLogin = (email, password) => {
    dispatch(
      login({
        email,
        password,
        cb: () => {
          navigate(from, { replace: true });
        }
      })
    );
  };

  return isLoggedIn ? (
    <Navigate to={from} />
  ) : (
    <NarrowLayout>
      <Helmet title={t("login.login", "Login")} defer={false} />
      <Login onLogin={handleLogin} isLoading={isLoading} error={error} />
    </NarrowLayout>
  );
};

export default LoginPage;
