import locales from "~/constants/locales";
import { filterOutUndefined } from "~/helpers/array/arrayHelpers";
import { Location, Program, TherapeuticArea } from "~/typing/sidekickTypes";

export const getInformationFromAvailablePrograms = (
  availablePrograms: Program[] | undefined
): {
  locales: { name: string; locale: string }[];
  locations: Location[];
  therapeuticAreas: TherapeuticArea[];
} => {
  if (!availablePrograms) {
    return {
      locales: [],
      locations: [],
      therapeuticAreas: []
    };
  }

  // Extract all the unique locales, therapeutic areas and locations from the available programs
  const uniqueLocales = Array.from(
    new Set(availablePrograms.map((program) => program.locale))
  );

  const uniqueLocalesWithData = uniqueLocales.map((locale) => ({
    name: locales.find((l) => l.locale === locale)?.name ?? locale,
    locale
  }));

  // Create a list if unique locations based on the countryCode property of the location object
  const uniqueLocationCodes = Array.from(
    new Set(availablePrograms.map((program) => program.location?.countryCode))
  ).filter(filterOutUndefined);

  const uniqueTherapeuticAreas = Array.from(
    new Set(availablePrograms.map((program) => program.therapeuticArea?.id))
  ).filter(filterOutUndefined);

  const uniqueTherapeuticAreasWithData = uniqueTherapeuticAreas
    .map(
      (id) =>
        availablePrograms.find((program) => program.therapeuticArea?.id === id)
          ?.therapeuticArea
    )
    .filter(filterOutUndefined);

  // Create a list of location objects based on the unique location codes
  const uniqueLocations = uniqueLocationCodes
    .map(
      (code) =>
        availablePrograms.find(
          (program) => program.location?.countryCode === code
        )?.location
    )
    .filter(filterOutUndefined);

  return {
    locales: uniqueLocalesWithData,
    locations: uniqueLocations,
    therapeuticAreas: uniqueTherapeuticAreasWithData
  };
};
