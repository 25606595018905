import classNames from "classnames/bind";
import React from "react";

import styles from "./Tabs.module.scss";

const cx = classNames.bind(styles);

export type Tab = {
  title: string;
  value: string;
  onClick: (value: string) => void;
  iconSrc?: string | JSX.Element;
  iconColor?: string;
};

type TabsProps = {
  tabs: Tab[];
  activeTab: string;
  flatDesign?: boolean;
};

const Tabs = ({ tabs, activeTab, flatDesign }: TabsProps) => {
  return (
    <div className={flatDesign ? styles.flatWrapper : styles.wrapper}>
      {tabs.map((tab, index) => {
        return (
          <button
            key={`tab-${index}-title`}
            className={cx({
              tab: true,
              active: tab.value === activeTab
            })}
            onClick={() => tab.onClick(tab.value)}
            tabIndex={0}
          >
            <div className={styles.content}>
              {tab.iconSrc && typeof tab.iconSrc === "string" && (
                <img
                  src={tab.iconSrc}
                  className={styles.icon}
                  alt={tab.title}
                />
              )}
              {tab.iconSrc && typeof tab.iconSrc !== "string" && (
                <div
                  className={cx({
                    icon: true,
                    active: tab.value === activeTab
                  })}
                >
                  {tab.iconSrc}
                </div>
              )}
              <div className={styles.title}>{tab.title}</div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
