import { Program } from "~/typing/sidekickTypes";

export default function getMergedPrograms(
  programs: Program[],
  isAnthem: boolean
): Program[] {
  if (!isAnthem) {
    return programs;
  } else {
    const programByIdMap = new Map<string, Program>();

    programs.forEach((program) => {
      // Check if the program already exists in the map
      const existingProgram = programByIdMap.get(program.programCatalogItemId);

      if (existingProgram) {
        // If the program exists, merge its data with the existing program
        const existingLocales = existingProgram.locales || [];

        // Merge locales, ensuring uniqueness
        const mergedLocales = new Set([...existingLocales, program.locale]);

        // Determine the primary locale, defaulting to "en" if it exists
        const primaryLocale = mergedLocales.has("en")
          ? "en"
          : Array.from(mergedLocales).find((loc) => loc !== "en") || "en";

        // Update the existing program with merged data
        existingProgram.locale = primaryLocale;

        // Sort locales, ensuring "en" is first if it exists, otherwise sort alphabetically
        const localesArray = Array.from(mergedLocales);
        if (localesArray.includes("en")) {
          localesArray.sort((a, b) =>
            a === "en" ? -1 : b === "en" ? 1 : a.localeCompare(b)
          );
        } else {
          localesArray.sort((a, b) => a.localeCompare(b));
        }
        existingProgram.locales = localesArray;

        // Merge unique coaches
        const mergedCoaches = new Map(
          existingProgram.coaches.map((coach) => [coach.userId, coach])
        );
        program.coaches.forEach((coach) =>
          mergedCoaches.set(coach.userId, coach)
        );
        existingProgram.coaches = Array.from(mergedCoaches.values());

        // Aggregate user counts
        existingProgram.userCount += program.userCount;
        existingProgram.activeUsers += program.activeUsers;
        existingProgram.newUsers += program.newUsers;

        // Update the map with the merged program
        programByIdMap.set(program.programCatalogItemId, existingProgram);
      } else {
        // If the program does not exist in the map, add it as a new entry
        const newProgram = {
          ...program,
          locale: program.locale === "en" ? "en" : program.locale,
          locales: [program.locale]
        };

        programByIdMap.set(program.programCatalogItemId, newProgram);
      }
    });

    return Array.from(programByIdMap.values());
  }
}
