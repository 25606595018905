import { ImportEntryStatus } from "~/typing/sidekickTypes";

export const convertEntryStatusToText = (status?: ImportEntryStatus) => {
  if (status === undefined) return "";

  switch (status) {
    case ImportEntryStatus.Finished:
      return "Finished";
    case ImportEntryStatus.Error:
      return "Error";
    case ImportEntryStatus.Parsing:
      return "Parsing";
    case ImportEntryStatus.Processing:
      return "Processing";
    case ImportEntryStatus.New:
      return "New";
    default:
      return "";
  }
};
