import { Survey, SurveyResult } from "~/typing/sidekickTypes";

export default function getLatestSurvey(
  latestSurveyResults: SurveyResult[],
  surveys: Survey[]
): SurveyResult {
  let latestSurvey;
  latestSurveyResults?.forEach((survey) => {
    if (
      !isSurveyQuiz(survey.surveyName, surveys) &&
      (!latestSurvey || survey.date > latestSurvey.date)
    ) {
      latestSurvey = survey;
    }
  });

  return latestSurvey;
}

export function getLatestUnratedSurvey(
  latestSurveyResults: SurveyResult[],
  surveys: Survey[]
): SurveyResult {
  let latestUnratedSurvey;
  latestSurveyResults?.forEach((survey) => {
    if (
      !survey.coachRatingDate &&
      !isSurveyQuiz(survey.surveyName, surveys) &&
      (!latestUnratedSurvey || survey.date > latestUnratedSurvey.date)
    ) {
      latestUnratedSurvey = survey;
    }
  });

  return latestUnratedSurvey;
}

export const isSurveyQuiz = (surveyName: string, surveys: Survey[]) => {
  const survey = surveys?.find((survey) => survey.surveyName === surveyName);

  return survey?.options?.quiz;
};
