import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { RootState } from "~/state/store";

const memoizedSelector = createSelector(
  [(state) => state.customList.filters ?? {}],
  (columns) => columns
);

export default function useListFilters(key: string) {
  const filters = useSelector<
    RootState,
    {
      [programId: string]: {
        filtersChangedCount: number;
        filters: { [filterKey: string]: string | string[] | number | boolean };
      };
    }
  >((state) => memoizedSelector(state));

  return filters[key] ?? { filters: {}, filtersChangedCount: 0 };
}
