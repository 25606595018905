export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s(){}[\]]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s(){}[\]]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s(){}[\]]{2,}|www\.[a-zA-Z0-9]+\.[^\s(){}[\]]{2,})/gi;
export const phoneRegex = /(^|\s)((?=[+(0-9])(?:\+[0-9]{1,3} ?)?(?:\([0-9]{3}\))?(?:(?!--| {2})[0-9 -]){6,14}[0-9])/g;
export const emailRegex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export default function getMessageMarkdown(message: string) {
  /* eslint-disable max-len */

  const markdownMessage = message
    .replace(urlRegex, (urlStr) => {
      return `[${urlStr}](${
        urlStr.match(/https?:\/\//) ? urlStr : `https://${urlStr}`
      })`;
    })
    .replace(phoneRegex, (_match, prefix, phoneStr) => {
      return `${prefix}[${phoneStr}](tel:${phoneStr.replace(/[() .-]/g, "")})`;
    });

  return markdownMessage;
}
