import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import InformationPageHeaderInfo from "./InformationPageHeaderInfo";
import styles from "./InformationPageHeaderInfo.module.scss";

import { InformationPageContext } from "../../informationPageContext";

import getProgramStatusText from "~/helpers/program/getProgramStatusText";

type InformationPageHeaderProgramsProps = {
  isPrintPage?: boolean;
};

const InformationPageHeaderPrograms = ({
  isPrintPage
}: InformationPageHeaderProgramsProps) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const isProgramListMultiline = () =>
    ref.current?.scrollHeight &&
    ref.current?.clientHeight &&
    ref.current.scrollHeight > ref.current.clientHeight;

  const [showLessPrograms, setShowLessPrograms] = useState(true);
  const [showMoreButton, setShowMoreButton] = useState(
    !isPrintPage && isProgramListMultiline()
  );

  useEffect(() => {
    setShowMoreButton(!isPrintPage && isProgramListMultiline());
  }, [showLessPrograms, ref.current?.scrollHeight]);

  const { userDetail } = useContext(InformationPageContext);

  const renderPrograms = () => {
    return (
      <div className={styles.programs}>
        <div
          className={`${styles.list} ${
            showLessPrograms ? styles.showLess : ""
          }`}
          ref={ref}
        >
          {userDetail?.userPrograms?.map((p, index) => {
            return `${p.programName} (${getProgramStatusText(p.status)})${
              index < userDetail.userPrograms.length - 1 ? ", " : ""
            }`;
          })}
          {!showLessPrograms && (
            <button onClick={() => setShowLessPrograms(!showLessPrograms)}>
              {t("general.showLess")}
            </button>
          )}
        </div>
        {showLessPrograms && showMoreButton && (
          <button onClick={() => setShowLessPrograms(!showLessPrograms)}>
            {t("general.showMore")}
          </button>
        )}
      </div>
    );
  };

  return (
    <InformationPageHeaderInfo
      title={t("programs.title", "Programs")}
      renderDetails={renderPrograms}
    />
  );
};

export default InformationPageHeaderPrograms;
