import {
  readOtherCoachMessage,
  readAllMessages
} from "~/api/requests/messageRequests";
import { NextRecommendedUserData } from "~/hooks/useApi/useNextRecommandedUser";
import { UnreadMessage } from "~/pages/user/userPage/unreadMessagesReducer";

export const readUnreadMessages = ({
  urneadMessages,
  nextUserInfo
}: {
  urneadMessages: UnreadMessage[];
  nextUserInfo?: NextRecommendedUserData;
}) => {
  if (!nextUserInfo) return;

  urneadMessages.forEach((message) => {
    if (!message.toAuthUser) {
      readOtherCoachMessage(
        nextUserInfo.program_catalog_item_id,
        nextUserInfo.locale,
        nextUserInfo.user_id,
        message.id
      );
    }
  });

  readAllMessages({
    programId: nextUserInfo.program_catalog_item_id,
    locale: nextUserInfo.locale,
    userId: nextUserInfo.user_id
  });
};
