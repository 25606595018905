import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import styles from "./TeamList.module.scss";
import TeamMemberOptions from "./TeamMemberOptions";
import { TeamPageRouteContext } from "./teamPageContext";

import Avatar from "~/components/avatar/Avatar";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import getRoleTextFromPrivileges from "~/helpers/getRoleTextFromPrivileges";
import { ProgramURLParams, SearchData } from "~/typing/carePortalTypes";
import { UserGroup } from "~/typing/sidekickTypes";

const TeamList = () => {
  const { t } = useTranslation();
  const searchData: SearchData = {
    data: [{ key: ["coach", "displayName"] }],
    placeholder: t("team.searchPlaceholder", "Search for a member..."),
    className: styles.search
  };

  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();

  const {
    hasLeadCoachPrivileges,
    coaches,
    authUser,
    updateGroups,
    team,
    isLoading
  } = useOutletContext<TeamPageRouteContext>();

  const columns: CustomListColumn<UserGroup>[] = [
    {
      heading: {
        className: styles.coaches,
        text: config.isAnthem ? t("roles.careManager") : t("roles.coach")
      },
      render: (group) => {
        const coach = coaches?.find(
          (coach) => coach.userId === group.coachUserId
        );
        const isYou = group.coachUserId === authUser?.id;
        return (
          <div className={`${styles.user} ${isYou ? styles.highlighted : ""}`}>
            <Avatar user={coach} />
            <span>
              {`${coach?.displayName}${
                isYou ? ` (${t("general.you", "You")})` : ""
              }`}
            </span>
          </div>
        );
      }
    },
    {
      heading: {
        text: t("team.patients", "Patients")
      },
      render: (group) => group.memberCount
    },
    {
      heading: {
        className: styles.acceptingPatients,
        text: t("team.acceptingPatients"),
        tooltip: {
          text: t("team.acceptingPatientsTooltip"),
          useIcon: true
        }
      },
      render: (group) => {
        const behaviourText = group.behaviour
          ? t("general.no")
          : t("general.yes");
        return hasLeadCoachPrivileges ? (
          <CheckboxInput
            checked={!group.behaviour}
            label={behaviourText}
            onChange={() =>
              updateGroups(group.id, {
                name: group.name,
                behaviour: group.behaviour ? 0 : 1,
                coachUserId: group.coachUserId
              })
            }
          />
        ) : (
          <>{behaviourText}</>
        );
      }
    },
    {
      heading: {
        text: t("team.role", "Role")
      },
      render: (group) => {
        const coach = coaches?.find(
          (coach) => coach.userId === group.coachUserId
        );
        return coach?.title;
      }
    },
    {
      heading: {
        text: t("team.privileges", "Privileges")
      },
      render: (group) => {
        const isYou = group.coachUserId === authUser?.id;
        return (
          <p
            className={`${styles.permissions} ${
              isYou ? styles.highlighted : ""
            }`}
          >
            {getRoleTextFromPrivileges(group.coach?.privileges)}
          </p>
        );
      }
    },
    {
      heading: {
        text: t("general.options", "Options")
      },
      conditional: hasLeadCoachPrivileges,
      render: (group) => (
        <TeamMemberOptions
          group={group}
          coachId={group.coachUserId}
          updateGroups={updateGroups}
          coaches={coaches}
        />
      )
    }
  ];

  return (
    <SentryErrorBoundary transactionName="TeamList">
      <div className={styles.TeamList}>
        <CustomList
          searchData={searchData}
          entities={team}
          isLoading={isLoading ?? false}
          columns={columns}
          noEntitiesText={t("manageCoaches.noCoaches")}
          defaultSortingData={{
            sortBy: ["coach", "privileges"]
          }}
          customListUniqueId={`team-list-${program_id}-${locale}`}
        />
      </div>
    </SentryErrorBoundary>
  );
};

export default TeamList;
