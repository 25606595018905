import { useId } from "react";
import { Tooltip } from "react-tooltip";

import styles from "./InfoTooltip.module.scss";

import InfoIcon from "~/assets/info.svg";

type InfoToolipProps = {
  text: string;
};

const InfoTooltip = ({ text }: InfoToolipProps) => {
  const id = useId();
  return (
    <>
      <div
        className={styles.wrapper}
        data-tooltip-delay-show={250}
        data-tooltip-id={id}
      >
        <img src={InfoIcon} />
      </div>
      <Tooltip className={styles.tooltip} place="top" id={id}>
        {text}
      </Tooltip>
    </>
  );
};

export default InfoTooltip;
