import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

export default function useInvites(programCatalogItemId, locale) {
  const path = `/coach/programcatalogitems/${programCatalogItemId}/locales/${locale}/invites`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: any;
  }>({
    path,
    queryKey: QueryKeyFactory.programs.invites(programCatalogItemId, locale)
  });

  return {
    invites: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
