import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { CommunicationPreferencesData } from "~/typing/carePortalTypes";

export default function useCommunicationPreferences(userId: string) {
  const path = `/users/${userId}/communicationPreference`;
  const {
    data,
    isError,
    isLoading,
    invalidate
  } = useApi<CommunicationPreferencesData>({
    path,
    queryKey: QueryKeyFactory.users.communicationPreferences(userId)
  });

  return {
    communicationPreferences: data,
    isError,
    isLoading,
    invalidate
  };
}
