import styles from "./LoadingSpinner.module.scss";

export default function LoadingSpinner() {
  return (
    <div className={styles.verticalCenteredBox}>
      <div className={styles.content}>
        <div className={styles.loaderCircle}></div>
        <div className={`${styles.loaderLineMask} ${styles.one}`}>
          <div className={styles.loaderLine}></div>
        </div>
        <div className={`${styles.loaderLineMask} ${styles.two}`}>
          <div className={styles.loaderLine}></div>
        </div>
        <div className={`${styles.loaderLineMask} ${styles.three}`}>
          <div className={styles.loaderLine}></div>
        </div>
        <div className={`${styles.loaderLineMask} ${styles.four}`}>
          <div className={styles.loaderLine}></div>
        </div>
      </div>
    </div>
  );
}
