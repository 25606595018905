import { DateTime } from "luxon";

import { getTimeZoneAdjustedDate } from "./adjustToTimeZone";

export const isBetweenDates = (
  serverDate: string,
  selectedStartDate?: Date,
  selectedEndDate?: Date,
  timeZoneOffset?: number
) => {
  if (!timeZoneOffset) {
    timeZoneOffset = 0;
  }
  const date = DateTime.fromISO(
    getTimeZoneAdjustedDate(serverDate, timeZoneOffset)
  );
  let endDate: DateTime | null = null;
  let startDate: DateTime | null = null;
  if (selectedEndDate) {
    // make sure endDate is set at midnight
    endDate = DateTime.fromJSDate(selectedEndDate).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999
    });
  }
  if (selectedStartDate) {
    // make sure startDate is set just before midnight the day before
    startDate = DateTime.fromJSDate(selectedStartDate).minus({ day: 1 }).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999
    });
  }

  if (startDate && endDate) {
    return date > startDate && date < endDate;
  } else if (endDate && !startDate) {
    return date < endDate;
  } else if (startDate && !endDate) {
    return date > startDate;
  } else {
    return true;
  }
};
