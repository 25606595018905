import { DateTime } from "luxon";

export default function <T extends { date: DateTime }>(
  data: T[] | undefined,
  useWeek: boolean,
  today: DateTime
) {
  return (
    data?.filter((reading) => {
      if (useWeek) {
        return (
          reading.date.diff(today, "days").days > -7 &&
          reading.date.diff(today, "days").days <= 0
        );
      }

      return (
        reading.date.month === today.month && reading.date.year === today.year
      );
    }) ?? []
  );
}
