import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { QueryKeyFactory } from "../queryKeysFactory";

import api from "~/api/api";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { logErrorToConsoleOnDev } from "~/helpers/util/util";
import { t } from "~/i18n";
import { SMSMessage } from "~/typing/sidekickTypes";

export const useReadSmsMessages = ({
  programId,
  locale,
  externalUser
}: {
  programId: string;
  locale: string;
  externalUser?: { externalUserTypeId: string; id: string };
}) => {
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess } = useMutation({
    mutationKey: ["readSmsMessage"],
    mutationFn: ({ message }: { message: SMSMessage }) => {
      const path = `/coach/messaging/textmessages/${message.id}/seen`;
      return api.put(path, {
        ...message,
        seenDate: DateTime.utc().toISO()
      });
    },
    onError: (err) => {
      logErrorToConsoleOnDev(err);
      displayErrorToast({ message: t("errors.generic") });
    },
    onSuccess: (response, message: {message: SMSMessage}) => {
      // set seen and seenDate on the message to update in the message window.
      message.message.seen = true;
      message.message.seenDate = response?.data?.seenDate;
      if (externalUser) {
        queryClient.invalidateQueries({
          queryKey: QueryKeyFactory.users.sms(
            programId,
            locale,
            externalUser.externalUserTypeId,
            externalUser.id
          )
        });
      }
      displaySuccessToast({
        message: t("messages.readSuccess"),
        position: "bottom-center"
      });
    }
  });

  return {
    readSmsMessage: mutate,
    isError,
    isSuccess
  };
};
