import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import MentalChartTemplate from "./MentalChartTemplate";
import MentalNav from "./MentalNav";
import styles from "./MentalPage.module.scss";

import ButtonLink from "~/components/buttonLink/ButtonLink";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { urlContainsString } from "~/helpers/browser/browserHelpers";
import useMissions from "~/hooks/useApi/useMissions";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { MentalURLParams } from "~/typing/carePortalTypes";

const MentalPage = () => {
  const { missions } = useMissions();
  const { t } = useTranslation();
  const [useWeek, setUseWeek] = useState(false);

  const { mental_category } = useParams<MentalURLParams>();

  const { trackPerformancePageViewed } = useAmplitudeTracking();

  const getPerformancePageTitle = () => {
    if (urlContainsString("sleep")) {
      return "Sleep Quality";
    }
    if (urlContainsString("energy")) {
      return "Energy Level";
    }
    if (urlContainsString("stress")) {
      return "Stress Level";
    }
    return "Activity";
  };

  useEffect(() => {
    trackPerformancePageViewed({
      performanceSection: getPerformancePageTitle(),
      performanceView: "Mental",
      performanceViewMode: useWeek ? "Weekly" : "Monthly"
    });
  }, [useWeek]);

  const toggleSwitch = (
    <div className={styles.toggleContainer}>
      <>
        <ButtonLink
          active={useWeek}
          title="Weekly"
          onClick={() => setUseWeek(true)}
        />
        <ButtonLink
          active={!useWeek}
          title="Detail"
          onClick={() => setUseWeek(false)}
        />
      </>
    </div>
  );

  const getMentalCategoryString = () => {
    switch (mental_category) {
      case "sleep":
        return "mission_sleep_quality";
      case "energy":
        return "mission_energy_levels";
      case "stress":
        return "mission_stress_levels";
      default:
        return "mission_sleep_quality";
    }
  };

  return (
    <SentryErrorBoundary transactionName="MentalPage">
      <Helmet
        title={t("performance.mental.performance", "Mental Performance")}
        defer={false}
      />
      <div className={styles.top}>
        <MentalNav />
        {toggleSwitch}
      </div>
      <MentalChartTemplate
        mentalCategory={getMentalCategoryString()}
        useWeek={useWeek}
        missions={missions ?? []}
      />
    </SentryErrorBoundary>
  );
};

export default MentalPage;
