import styles from "./SearchInput.module.scss";

import SearchIcon from "~/assets/svgComponents/SearchIcon";
type SearchInputProps = {
  value?: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder: string;
  size?: "sm" | "";
  disabled?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
};

const SearchInput = ({
  value,
  onChange,
  className = "",
  placeholder,
  disabled,
  size = "",
  autoFocus,
  dataTestId
}: SearchInputProps) => {
  const classes = `${styles.SearchInput} ${className} ${
    size ? styles[size] : ""
  }`;

  return (
    <div className={classes}>
      <input
        data-testid={dataTestId}
        autoFocus={autoFocus}
        className="input"
        value={value ?? ""}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        disabled={disabled}
      />
      <span className={styles.icon} title="Search">
        <SearchIcon />
      </span>
    </div>
  );
};

export default SearchInput;
