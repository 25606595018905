import React, { useState, useEffect } from 'react';

import AutoTranslateContext from './AutoTranslateContext';

type AutoTranslateProviderProps = {
    children: any;
};

export const AutoTranslateProvider = ({ children }: AutoTranslateProviderProps) => {
  const [autoTranslate, setAutoTranslate] = useState(() => {
    const savedPreference = localStorage.getItem('autoTranslate');
    return savedPreference !== null ? JSON.parse(savedPreference) : true;
  });

  useEffect(() => {
    localStorage.setItem('autoTranslate', JSON.stringify(autoTranslate));
  }, [autoTranslate]);

  return (
    <AutoTranslateContext.Provider value={{ autoTranslate, setAutoTranslate }}>
      {children}
    </AutoTranslateContext.Provider>
  );
};
