import { Reducer, combineReducers, configureStore } from "@reduxjs/toolkit";

import { customListReducer } from "./customList/customListSlice";
import { externalUsersReducer } from "./externalUsers/externalUsersSlice";
import { navHeaderReducer } from "./navHeaderNotification/navHeaderSlice";
import { programLocaleNavReducer } from "./programLocaleNav/programLocaleNavSlice";
import { userReducer } from "./user/userSlice";

import { authReducer } from "~/auth/authRedcuer";

const combinedReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  externalUsers: externalUsersReducer,
  customList: customListReducer,
  navHeader: navHeaderReducer,
  programLocaleNav: programLocaleNavReducer
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === "auth/logout/fulfilled") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
