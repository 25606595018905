import { t } from "i18next";

import styles from "./UserStatusCount.module.scss";

import { UserStatus } from "../../ProgramUsersPage.helpers";

import Close from "~/assets/svgComponents/Close";
import UserIcon from "~/assets/svgComponents/UserIcon";
import { TimeInMs } from "~/constants/measurements";
import {
  hasFinishedProgram,
  isPausedInProgram,
  hasQuitProgram,
  hasBeenDischargedFromProgram,
  isInMaintenance
} from "~/helpers/user/userHelpers";
import { getActivityFilterOptions } from "~/helpers/userLists/filterHelper";
import colors from "~/styles/colors";
import { CoachUser, Program } from "~/typing/sidekickTypes";

type UserStatusCountProps = {
  users: CoachUser[];
  onStatusRemove: (status: string) => void;
  program?: Program;
  statuses?: string[];
};

const getUserCountByStatus = ({
  users,
  statuses,
  numberOfPossibleOptions,
  program
}: {
  users: CoachUser[];
  statuses: string[];
  numberOfPossibleOptions: number;
  program?: Program;
}): { count: number; status: string }[] => {
  const now = Date.now();

  if (statuses.length >= numberOfPossibleOptions) return [];

  const scaleStatuses = statuses.filter((status) =>
    /(scale-)|(-day)/.test(status)
  );

  const statusMap: { [key: string]: number } = {};

  const incrementStatus = (status: UserStatus) =>
    (statusMap[status] = statusMap[status] ? statusMap[status] + 1 : 1);

  users.forEach((user) => {
    const lastActiveDate = user.lastActiveDate
      ? new Date(user.lastActiveDate)
      : undefined;
    const lastActiveDateOrJoined = user.lastActiveDate
      ? new Date(user.lastActiveDate)
      : new Date(user.joinedDate);

    const joinedDate = new Date(user.joinedDate);

    const latestScaleDate = user.latestScaleDate
      ? new Date(user.latestScaleDate)
      : undefined;

    if (hasFinishedProgram(user?.programStatus, user.userProgramStatusReason)) {
      incrementStatus(UserStatus.Finished);
      return;
    }
    if (isPausedInProgram(user?.programStatus)) {
      incrementStatus(UserStatus.Pause);
    }
    if (hasQuitProgram(user.programStatus, user.userProgramStatusReason)) {
      incrementStatus(UserStatus.Quit);
      return;
    }
    if (
      hasBeenDischargedFromProgram(
        user.programStatus,
        user.userProgramStatusReason
      )
    ) {
      incrementStatus(UserStatus.Discharged);
      return;
    }
    if (now - joinedDate.getTime() < TimeInMs.Week) {
      incrementStatus(UserStatus.New);
    }
    if (isInMaintenance(user, program)) {
      incrementStatus(UserStatus.Maintenance);
    }
    if (lastActiveDate && now - lastActiveDate.getTime() < TimeInMs.Week) {
      incrementStatus(UserStatus.Active);
    }
    if (
      now - lastActiveDateOrJoined.getTime() > TimeInMs.Week &&
      now - lastActiveDateOrJoined.getTime() < 2 * TimeInMs.Week
    ) {
      incrementStatus(UserStatus.Inactive7Days);
    }
    if (
      now - lastActiveDateOrJoined.getTime() > 2 * TimeInMs.Week &&
      now - lastActiveDateOrJoined.getTime() < 30 * TimeInMs.Day
    ) {
      incrementStatus(UserStatus.Inactive14Days);
    }
    if (now - lastActiveDateOrJoined.getTime() > 30 * TimeInMs.Day) {
      incrementStatus(UserStatus.Inactive30Days);
    }

    if (scaleStatuses.length > 0 && latestScaleDate) {
      if (
        now - latestScaleDate.getTime() > 2 * TimeInMs.Week &&
        now - latestScaleDate.getTime() < 3 * TimeInMs.Week
      ) {
        incrementStatus(UserStatus.Scale14Day);
      }
      if (now - latestScaleDate.getTime() > 3 * TimeInMs.Week) {
        incrementStatus(UserStatus.Scale21Day);
      }
    }
  });

  const statusCounts = Object.keys(statusMap)
    .filter((key) => statuses.includes(key))
    .map((key) => ({
      count: statusMap[key],
      status: key
    }));

  return statusCounts;
};

const UserStatusCount = ({
  users,
  onStatusRemove,
  program,
  statuses
}: UserStatusCountProps) => {
  const statusOptions = getActivityFilterOptions(program);

  const statusCounts = getUserCountByStatus({
    users,
    statuses: statuses || [],
    numberOfPossibleOptions: statusOptions.length,
    program
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.statusBubbleWrapper}>
        <div
          data-testid="users-page-totalusers"
          className={`${styles.statusBubble} ${styles.totalUsers}`}
        >
          <UserIcon color={colors.white} />
          <p>{`${users.length} ${t("programUserList.totalUsers")}`}</p>
        </div>
        {statusCounts.length < statusOptions.length &&
          statusCounts
            .filter((status) => status.count > 0)
            .map((status, index) => (
              <div
                key={`status-bubble-${index}`}
                className={styles.statusBubble}
              >
                <p>
                  {`${status.count} ${
                    status.count === 1 ? t("general.user") : t("general.users")
                  }
                      ${status.status === UserStatus.Maintenance ? " in " : ""}
                      ${
                        statusOptions.find(
                          (statusOption) => statusOption.value === status.status
                        )?.text
                      }`}{" "}
                </p>
                <button
                  className={styles.statusBubbleBtn}
                  onClick={() => onStatusRemove(status.status)}
                >
                  <Close />
                </button>
              </div>
            ))}
      </div>
    </div>
  );
};

export default UserStatusCount;
