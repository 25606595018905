export enum DisplayDistance {
  Six = 6,
  Twelve = 12
}

export enum GraphView {
  Weekly = "weekly",
  Monthly = "monthly",
  Period = "period"
}

export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 31;
