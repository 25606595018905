import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ProviderHeader from "./ProviderHeader";
import {
  filterUserByActivity,
  filterUserByPRO,
  getSurveyRating
} from "./ProviderPage.helpers";
import styles from "./ProviderPage.module.scss";
import {
  colourFilterOptions,
  getEngagementText,
  getListColumnSortability,
  getSearchKeys,
  shouldFetchSurveys
} from "./providerPageHelpers";
import SurveyResultsModal from "./SurveyResultsModal";
import WeeklyTheme from "./WeeklyTheme";

import SurveyResultBubble from "../components/surveyResultBubble/SurveyResultBubble";

import api from "~/api/api";
import ArrowIcon from "~/assets/arrow-right-thick.svg";
import Breadcrumbs from "~/components/breadcrumbs/Breadcrumbs";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import Layout from "~/components/layout/Layout";
import { DEFAULT_FILTER_VALUE } from "~/constants/filters";
import { ListColumnType } from "~/constants/listColumnTypes";
import { TimeInMs } from "~/constants/measurements";
import { shouldUseLightText } from "~/helpers/colours/contrast";
import round from "~/helpers/number/round";
import { displayActionToast } from "~/helpers/toast/displayToast";
import kgToLbs from "~/helpers/units/kgToLbs";
import { getActivityFilterOptions } from "~/helpers/userLists/filterHelper";
import useProgram from "~/hooks/useApi/useProgram";
import useProviderView from "~/hooks/useApi/useProviderView";
import useUseMetric from "~/hooks/useUseMetric";
import { setListFilter } from "~/state/customList/customListStateHelpers";
import colors from "~/styles/colors";
import {
  CustomListFilterData,
  ProgramURLParams,
  SearchData,
  SurveyResultsModalDetails
} from "~/typing/carePortalTypes";
import {
  ProviderColumnWithMetaData,
  ProviderViewUser,
  Survey
} from "~/typing/sidekickTypes";

enum ProviderViewFilters {
  Activity = "activity",
  PROStatus = "proStatus",
  SymptomColumnIndex = "symptomColumnIndex"
}

const ProviderPage = () => {
  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();
  const { program } = useProgram({
    programCatalogItemId: program_id,
    locale,
    includeSlots: true,
    includeHighRiskUserCount: true
  });
  const useMetric = useUseMetric();
  const [symptomColumnIndex, setSymptomColumnIndex] = useState<number>(0);

  const {
    providerView,
    isLoading: loading,
    invalidate: invalidateProviderView
  } = useProviderView(program_id, locale);

  const customListUniqueId = `provider-page-list-${program_id}-${locale}`;

  const { t } = useTranslation();

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const [selectedSurveyResult, setSelectedSurveyResult] = useState<
    SurveyResultsModalDetails | undefined
  >();
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [surveysLoading, setSurveysLoading] = useState(false);
  const [usersToRender, setUsersToRender] = useState<ProviderViewUser[]>([]);
  const [columns, setColumns] = useState<CustomListColumn<ProviderViewUser>[]>(
    []
  );

  const [filterSelectData, setFilterListData] = useState<
    CustomListFilterData[]
  >([]);

  useEffect(() => {
    if (!providerView || !program) return;

    const columns: CustomListColumn<ProviderViewUser>[] = [];
    const filterSelectData: CustomListFilterData[] = [];

    let symptomColumnIndexValue = 0;

    providerView?.columnsWithMetadata?.forEach((column) => {
      const listColumn = column.listColumn;

      columns.push({
        heading: {
          text: column.listColumn?.title ?? "",
          tooltip: listColumn?.tooltip
            ? {
                text: listColumn.tooltip,
                useIcon: true
              }
            : undefined,
          sortable: getListColumnSortability(
            listColumn,
            symptomColumnIndexValue
          )
        },
        render: (user) => getRenderFunctionForColumn(user, column)()
      });

      const columnsSupportingFilters = [
        ListColumnType.UserName,
        ListColumnType.Symptoms
      ];

      const addColumnFilter =
        listColumn.filterable &&
        columnsSupportingFilters.includes(listColumn.listColumnType);

      if (addColumnFilter) {
        switch (listColumn.listColumnType) {
          case ListColumnType.UserName:
            if (
              !filterSelectData.some(
                (data) => data.key === ProviderViewFilters.Activity
              )
            ) {
              filterSelectData.push({
                label: t("programUserList.userStatus"),
                onChange: (value) => handleSetActivityFilter(value),
                key: ProviderViewFilters.Activity,
                options: getActivityFilterOptions(program),
                renderOption: (option) => option.text,
                defaultValue: getActivityFilterOptions(program).map(
                  (option) => option.value as string
                ),
                usesCheckBoxes: true,
                filter: ({ entity: user, filterValue }) =>
                  filterUserByActivity(user, filterValue as string[], program)
              });
            }
            break;
          case ListColumnType.Symptoms: {
            if (
              !filterSelectData.some(
                (data) => data.key === ProviderViewFilters.PROStatus
              )
            ) {
              const curIndex = symptomColumnIndexValue;
              filterSelectData.push({
                onChange: (val) => {
                  handleSetProStatusFilter(val, curIndex);
                },
                label: t("programUserList.proResult"),
                key: ProviderViewFilters.PROStatus,
                options: colourFilterOptions(
                  surveys,
                  column.metadata?.symptomColumnSurveyId
                ),
                renderOption: renderColourOption,
                defaultValue: DEFAULT_FILTER_VALUE,
                filter: ({ entity: user, filterValue }) =>
                  filterUserByPRO({
                    user,
                    proStatusFilter: filterValue as string,
                    surveyId: column.metadata?.symptomColumnSurveyId,
                    symptomColumnIndex,
                    surveys
                  })
              });
              symptomColumnIndexValue++;
            }
            break;
          }
        }
      }
    });
    setColumns(columns);
    setFilterListData(filterSelectData);
  }, [providerView, surveys, program]);

  useEffect(() => {
    if (!providerView) return;
    getSurveys();
    if (usersToRender.length === 0) {
      setUsersToRender(providerView?.users ?? []);
    }
  }, [providerView]);

  const { getLastActiveTime } = useIdleTimer({
    timeout: TimeInMs.Minute * 10
  });

  const updateQueryParams = (paramName, paramValue) => {
    queryParams.set(paramName, paramValue);
    return queryParams;
  };

  const handleSetActivityFilter = (filterValue) => {
    const queryParams = updateQueryParams(
      ProviderViewFilters.Activity,
      filterValue
    );
    navigate(`/users?${queryParams.toString()}`, { replace: true });
  };

  const handleSetProStatusFilter = (filterValue, symptomColumnIndexValue) => {
    let queryParams = updateQueryParams(
      ProviderViewFilters.PROStatus,
      filterValue
    );
    queryParams = updateQueryParams(
      ProviderViewFilters.SymptomColumnIndex,
      symptomColumnIndexValue
    );
    navigate(`/users?${queryParams.toString()}`, { replace: true });

    setSymptomColumnIndex(symptomColumnIndexValue);
  };

  const getSurveys = async () => {
    if (shouldFetchSurveys(providerView)) {
      setSurveysLoading(true);
      const surveysData = await api.get(
        `/coach/programs/${program_id}/locales/${locale}/surveys`
      );
      setSurveys(surveysData?.data?.items);
      setSurveysLoading(false);
    }
  };

  const searchData: SearchData = {
    placeholder: t("programUserList.search", "Search user name..."),
    data: getSearchKeys(providerView)
  };

  const renderColourOption = (option) => (
    <div className={styles.colourOption}>
      {option.colour && (
        <div
          className={styles.colourBubble}
          style={{ background: option.colour }}
        ></div>
      )}
      {option.text}
    </div>
  );

  const getRenderFunctionForColumn = (
    user: ProviderViewUser,
    column: ProviderColumnWithMetaData
  ) => {
    let renderCell;
    const { listColumnType, title } = column.listColumn;
    const {
      userId,
      userColumn,
      weightColumn,
      programStatusColumn,
      symptomColumns,
      intakeSurveysColumns
    } = user;

    let intakeSurveysColumnIndex = 0;
    let symptomColumnIndexValue = 0;

    switch (listColumnType) {
      case ListColumnType.UserName:
        renderCell = () => renderUserCell(userColumn);
        break;
      case ListColumnType.ProgramStatus:
        renderCell = () =>
          programStatusColumn
            ? renderProgramStatusCell(programStatusColumn)
            : null;
        break;
      case ListColumnType.Weight:
        renderCell = () =>
          weightColumn ? renderWeightCell(weightColumn) : null;
        break;
      case ListColumnType.Symptoms: {
        const surveyResultModalDetails = {
          displayName: userColumn?.displayName || userId,
          columnTitle: title,
          userId: userId,
          surveyId: column.metadata?.symptomColumnSurveyId
        };
        const symptomColumn = symptomColumns
          ? symptomColumns[symptomColumnIndexValue]
          : undefined;

        renderCell = () =>
          symptomColumn
            ? renderSymptomCell(symptomColumn, surveyResultModalDetails)
            : null;

        symptomColumnIndexValue += 1;

        break;
      }
      case ListColumnType.IntakeSurveys: {
        const intakeSurveysColumn = intakeSurveysColumns
          ? intakeSurveysColumns[intakeSurveysColumnIndex]
          : undefined;

        renderCell = () =>
          intakeSurveysColumn
            ? renderIntakeSurveysCell(intakeSurveysColumn)
            : null;
        intakeSurveysColumnIndex += 1;
        break;
      }
      default:
        renderCell = () => null;
    }
    return renderCell;
  };

  const renderUserCell = ({ displayName, engagement }) => (
    <div className={styles.userCell}>
      <div className={styles.userName}>{displayName}</div>
      {engagement !== undefined && (
        <div className={styles.engagement}>
          {`${t(
            "providerView.userColumn.engagement.title"
          )}: ${getEngagementText(engagement)}`}
        </div>
      )}
    </div>
  );

  const renderProgramStatusCell = ({
    joinedDate,
    currentSlot,
    currentSlotName
  }) => (
    <div className={styles.programStatus}>
      <div>{`${t(
        "providerView.programStatusColumn.dateOfEnrolment"
      )}: ${DateTime.fromISO(joinedDate).toLocaleString()}`}</div>
      <div className={styles.currentWeek}>
        {currentSlot && currentSlotName
          ? `${t(
              "providerView.programStatusColumn.currentWeek"
            )}: ${currentSlot}`
          : "Quit / Finished"}
      </div>
      <div>{currentSlotName || ""}</div>
    </div>
  );

  const renderWeightCell = ({ weight, weightDiff, startingWeight }) => {
    const positive = weightDiff <= 0;

    return (
      <div className={styles.weightCell}>
        {!!weight && (
          <div className={styles.weight}>
            {useMetric ? round(weight, 1) : kgToLbs(weight, 1)}
            <div className={styles.tooltipWrapper}>
              <div className={styles.tooltip}>
                <div className={styles.weightTooltip}>
                  {t("providerView.weightColumn.startingWeight")}
                  <span>
                    {useMetric
                      ? round(startingWeight, 1)
                      : kgToLbs(startingWeight, 1)}
                  </span>
                  {useMetric ? "kg" : "lbs"}
                </div>
              </div>
            </div>
          </div>
        )}
        {!!weightDiff && (
          <div
            className={`${styles.weightDiff} ${
              positive ? styles.positive : ""
            }`}
          >
            <img className={styles.arrow} src={ArrowIcon} />
            {Math.abs(
              useMetric
                ? round(weightDiff, 1) ?? 0
                : kgToLbs(weightDiff, 1) ?? 0
            )}
            <div className={styles.tooltipWrapper}>
              <div className={styles.tooltip}>
                {t("providerView.weightColumn.weightDiffTooltip")}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderSymptomCell = (
    { result, resultDiff },
    surveyResultModalData: SurveyResultsModalDetails
  ) => {
    const positive = resultDiff <= 0;
    const surveyId = surveyResultModalData.surveyId;

    if (!surveyId) return null;

    const rating = getSurveyRating({ surveyId, surveyResult: result, surveys });

    return (
      result !== undefined && (
        <div
          className={styles.resultCell}
          onClick={() => setSelectedSurveyResult(surveyResultModalData)}
        >
          <SurveyResultBubble
            result={result}
            style={{
              background: rating?.colour || colors.navyBlue20
            }}
            useLightText={shouldUseLightText(rating)}
          />
          {!!resultDiff && (
            <div
              className={`${styles.resultDiff} ${
                positive ? styles.positive : ""
              }`}
            >
              <img className={styles.arrow} src={ArrowIcon} />
              {Math.abs(resultDiff)}
            </div>
          )}
        </div>
      )
    );
  };

  const renderIntakeSurveysCell = ({ surveyResults }) => {
    return (
      <div className={styles.intakeCell}>
        <ul>
          {surveyResults?.map((intakeSurvey) => {
            return (
              <li key={intakeSurvey.surveyShortName}>
                {`${intakeSurvey.surveyShortName}: `}
                {/* TODO: make this more visual with a colored result bubble */}
                {/* For now this is very specific to the anthem use case. Need state approval for design changes */}
                {intakeSurvey.result === undefined
                  ? "-"
                  : intakeSurvey.result > 2
                  ? "Yes"
                  : "No"}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const durationSinceLastActive =
        new Date().getTime() - (getLastActiveTime()?.getTime() ?? 0);
      if (durationSinceLastActive < TimeInMs.Minute * 10) {
        invalidateProviderView();
      } else {
        displayActionToast({
          onClick: () => {
            invalidateProviderView();
            toast.remove();
          },
          buttonText: t("login.refresh", "Refresh"),
          message: t("login.sessionSoonExpire"),
          id: "refresh-provider"
        });
      }
    }, TimeInMs.Minute * 10);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const activityFilterValue = queryParams.get(ProviderViewFilters.Activity);
    if (activityFilterValue) {
      setListFilter(
        customListUniqueId,
        ProviderViewFilters.Activity,
        activityFilterValue
      );
    }
  }, []);

  useEffect(() => {
    if (!surveysLoading) {
      const proStatusFilterValue = queryParams.get(
        ProviderViewFilters.PROStatus
      );
      const symptomColumnIndexValue = queryParams.get(
        ProviderViewFilters.SymptomColumnIndex
      );

      if (proStatusFilterValue && symptomColumnIndexValue) {
        setListFilter(
          customListUniqueId,
          ProviderViewFilters.PROStatus,
          proStatusFilterValue
        );
        setSymptomColumnIndex(parseInt(symptomColumnIndexValue));
      }
    }
  }, []);

  if (!program) return null;

  return (
    <Layout>
      <div className={styles.nav}>
        <Breadcrumbs
          linkData={[
            {
              text: program.name
            }
          ]}
        />
      </div>
      <div className={styles.container}>
        {program && <ProviderHeader {...program} />}
        <div className={styles.divider}></div>
        <WeeklyTheme slots={program?.slotNames ?? []} />
      </div>
      <div className={styles.container}>
        <CustomList<ProviderViewUser>
          entities={usersToRender}
          searchData={searchData}
          filterSelectData={filterSelectData}
          noEntitiesText={t("programUserList.noUsers")}
          isLoading={loading || surveysLoading}
          columns={columns}
          customListUniqueId={customListUniqueId}
        />
        {selectedSurveyResult && (
          <SurveyResultsModal
            programId={program_id}
            locale={locale}
            selectedSurveyResult={selectedSurveyResult}
            onClose={() => setSelectedSurveyResult(undefined)}
            surveys={surveys}
          />
        )}
      </div>
    </Layout>
  );
};

export default ProviderPage;
