import api from "~/api/api";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

export const removePinnedNote = async (
  userId: string,
  pinnedNoteId: string
): Promise<any> => {
  const path = `/coach/users/${userId}/journalPins/${pinnedNoteId}`;
  return await api
    .delete(path)
    .then(() => displaySuccessToast({ message: t("notes.unPinSuccess") }))
    .catch(() => displayErrorToast({ message: t("notes.unPinError") }));
};

export const addPinnedNote = async (
  programId: string,
  userId: string,
  noteId: string
): Promise<any> => {
  const path = `/coach/programs/${programId}/users/${userId}/journalitems/${noteId}/journalPins`;
  return await api
    .post(path, {})
    .then(() => displaySuccessToast({ message: t("notes.pinSuccess") }))
    .catch(() => displayErrorToast({ message: t("notes.pinError") }));
};
