import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { baseApiWithoutIntercept as api } from "~/api/api";
import { ExternalUser } from "~/typing/sidekickTypes";

export const fetchExternalUsers = createAsyncThunk(
  "externalUsers/fetchUsers",
  async ({
    programId,
    locale,
    userTypeId,
    searchString,
    unseenSmsOnly
  }: {
    programId: string;
    locale: string;
    userTypeId?: string;
    searchString?: string;
    unseenSmsOnly?: boolean;
  }): Promise<{
    programId: string;
    userTypeId: string;
    users: ExternalUser[];
  }> => {
    if (!userTypeId) {
      return { programId, userTypeId: "", users: [] };
    }

    let externalUsers = [];

    if (searchString) {
      const path = `/coach/programs/${programId}/locales/${locale}/externalusertypes/${userTypeId}/externalusers/search`;

      const res = await api.post(path, { searchString });

      externalUsers = res?.data.items;
    } else {
      const path = `${
        unseenSmsOnly ? "" : "/v2"
      }/coach/programs/${programId}/locales/${locale}/externalusertypes/${userTypeId}/externalusers${
        unseenSmsOnly ? "/unseensms" : ""
      }`;

      const res = await api.get(path);

      externalUsers = res?.data.items;
    }

    return { programId, userTypeId, users: externalUsers };
  }
);

export const updateExternalusers = createAsyncThunk(
  "externalUsers/updateUsers",
  async ({
    programId,
    locale,
    userTypeId,
    searchString,
    unseenSmsOnly
  }: {
    programId: string;
    locale: string;
    userTypeId?: string;
    searchString?: string;
    unseenSmsOnly?: boolean;
  }): Promise<{
    programId: string;
    userTypeId: string;
    users: ExternalUser[];
  }> => {
    if (!userTypeId) {
      return { programId, userTypeId: "", users: [] };
    }

    let externalUsers = [];

    if (searchString) {
      const path = `/coach/programs/${programId}/locales/${locale}/externalusertypes/${userTypeId}/externalusers/search`;

      const res = await api.post(path, { searchString });

      externalUsers = res?.data.items;
    } else {
      const path = `${
        unseenSmsOnly ? "" : "/v2"
      }/coach/programs/${programId}/locales/${locale}/externalusertypes/${userTypeId}/externalusers${
        unseenSmsOnly ? "/unseensms" : ""
      }`;

      const res = await api.get(path);

      externalUsers = res?.data.items;
    }

    return { programId, userTypeId, users: externalUsers };
  }
);

interface ExternalUsersState {
  externalUsers?: { [programId: number]: ExternalUser[] };
  externalUsersWithUnseenSms?: { [programId: number]: ExternalUser[] };
  externalUsersStatus?: string;
  externalUsersView?: { [programId: number]: "unseenSms" | "all" };
  externalUsersSearchString?: { [programId: number]: string };
}

const initialState: ExternalUsersState = {
  externalUsers: {},
  externalUsersStatus: "",
  externalUsersView: {},
  externalUsersSearchString: {}
};

const externalUsersSlice = createSlice({
  name: "externalUsers",
  initialState,
  reducers: {
    setExternalUsers: (
      state,
      action: PayloadAction<{
        users: ExternalUser[];
        programId: string;
        userTypeId: string;
      }>
    ) => {
      if (!state.externalUsers) state.externalUsers = {};
      if (action.payload.programId && action.payload.userTypeId) {
        state.externalUsers[
          `${action.payload.programId}-${action.payload.userTypeId}`
        ] = action.payload.users;
      }
    },
    setSearchString: (
      state,
      action: PayloadAction<{
        searchString: string;
        programId: string;
        userTypeId: string;
      }>
    ) => {
      if (!state.externalUsersSearchString)
        state.externalUsersSearchString = {};
      if (action.payload.programId) {
        state.externalUsersSearchString[`${action.payload.programId}`] =
          action.payload.searchString;
      }
    },
    setExternalUsersView: (
      state,
      action: PayloadAction<{ programId: string; view: "unseenSms" | "all" }>
    ) => {
      if (!state.externalUsersView) state.externalUsersView = {};
      if (action.payload.programId) {
        state.externalUsersView[`${action.payload.programId}`] =
          action.payload.view;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExternalUsers.pending, (state) => {
      state.externalUsersStatus = !state.externalUsers
        ? "initialLoad"
        : "loading";
    });
    builder.addCase(fetchExternalUsers.fulfilled, (state, action) => {
      if (!state.externalUsers) state.externalUsers = {};
      if (action.payload.programId && action.payload.userTypeId) {
        state.externalUsers[
          `${action.payload.programId}-${action.payload.userTypeId}`
        ] = action.payload.users;
      }

      state.externalUsersStatus = "success";
    });
    builder.addCase(fetchExternalUsers.rejected, (state) => {
      state.externalUsersStatus = "failed";
    });
    builder.addCase(updateExternalusers.fulfilled, (state, action) => {
      if (!state.externalUsers) state.externalUsers = {};
      if (action.payload.programId && action.payload.userTypeId) {
        state.externalUsers[
          `${action.payload.programId}-${action.payload.userTypeId}`
        ] = action.payload.users;
      }

      state.externalUsersStatus = "success";
    });
    builder.addCase(updateExternalusers.rejected, (state) => {
      state.externalUsersStatus = "failed";
    });
    builder.addCase("auth/logout/fulfilled", (state) => {
      state.externalUsers = {};
      state.externalUsersStatus = "";
    });
  }
});

const externalUsersReducer = externalUsersSlice.reducer;

const {
  setExternalUsers,
  setSearchString,
  setExternalUsersView
} = externalUsersSlice.actions;

export {
  externalUsersReducer,
  setExternalUsers,
  setSearchString,
  setExternalUsersView
};
