import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import UploadDocument from "./UploadDocument";
import styles from "./UploadDocumentModal.module.scss";

import api from "~/api/api";
import Modal from "~/components/modal/Modal";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";

type UploadDocumentsModalProps = {
  onClose: () => void;
  invalidateDocuments: () => void;
};

const UploadDocumentModal = ({
  onClose,
  invalidateDocuments
}: UploadDocumentsModalProps) => {
  const { t } = useTranslation();
  const { user_id } = useParams<UserURLParams>();
  const { trackDocumentUpload } = useAmplitudeTracking();

  const handleUpload = async ({
    selectedFile,
    encodedFile
  }: {
    selectedFile?: File;
    encodedFile: string;
  }) => {
    const path = `/admin/users/${user_id}/document`;
    const payload = {
      documentName: selectedFile?.name ?? "",
      contentType: selectedFile?.type ?? "",
      document: encodedFile
    };

    await api
      .post(path, payload)
      .then(() => {
        trackDocumentUpload();
        displaySuccessToast({
          message: t("documentsPage.uploadSuccess")
        });
        invalidateDocuments();
        onClose();
      })
      .catch(() =>
        displayErrorToast({
          message: t("documentsPage.uploadError")
        })
      );
  };

  return (
    <Modal
      title={t("general.upload")}
      onClose={onClose}
      className={styles.modal}
    >
      <UploadDocument onUpload={handleUpload} />
    </Modal>
  );
};

export default UploadDocumentModal;
