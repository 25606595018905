import { journalItemTypes } from "~/constants/notes";
import { t } from "~/i18n";
import { NoteInfo } from "~/typing/sidekickTypes";

export const getCoachesFromNotes = (notes: NoteInfo[]) => {
  const coaches = notes
    .filter(
      (note) =>
        note.coachUser !== undefined &&
        journalItemTypes.some((itemType) => itemType.itemType === note.itemType)
    )
    .map((note) => note.coachUser?.displayName);
  return [{ text: t("notes.allCareManagers") }].concat(
    Array.from(new Set(coaches)).map((coach) => ({ text: coach }))
  );
};

export const getTopicsFromNotes = (notes: NoteInfo[]) => {
  return journalItemTypes?.filter((type) =>
    notes.some((note) => note.itemType === type.itemType)
  );
};
