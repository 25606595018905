import { createContext, Dispatch, SetStateAction } from 'react';

interface AutoTranslateContextType {
  autoTranslate: boolean;
  setAutoTranslate: Dispatch<SetStateAction<boolean>>;
}

const AutoTranslateContext = createContext<AutoTranslateContextType>({
  autoTranslate: true,
  setAutoTranslate: () => {},
});

export default AutoTranslateContext;
