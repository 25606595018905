import { useEffect, useRef, useState } from "react";

import styles from "./Options.module.scss";

import MenuIcon from "~/assets/menu.svg";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { OptionItem } from "~/typing/carePortalTypes";

type OptionsProps = {
  options: OptionItem[];
  mainOption?: OptionItem;
  hideOptions?: boolean;
  className?: string;
  iconSrc?: string;
  dataTestId?: string;
};

const Options = ({
  options,
  mainOption,
  className,
  hideOptions,
  iconSrc,
  dataTestId
}: OptionsProps) => {
  const [showList, setShowList] = useState(false);
  const listRef = useRef(null);

  useOnClickOutside(listRef, () => {
    if (showList) {
      setShowList(false);
    }
  });

  useEffect(() => {
    if (hideOptions) {
      setShowList(false);
    }
  }, [hideOptions]);

  useEffect(() => {
    if (!showList) return;
    const options = document.getElementById("options-list");
    options?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [showList]);

  return (
    <div
      className={`${styles.container} ${className ?? ""}  ${
        hideOptions ? styles.hide : ""
      }`}
      data-testid={dataTestId}
      ref={listRef}
    >
      <button
        type="button"
        className={styles.menu}
        data-testid="options-menu"
        onClick={() => setShowList(!showList)}
      >
        <img src={iconSrc || MenuIcon} />
      </button>
      {showList && (
        <div id="options-list" className={styles.options}>
          <ul>
            {mainOption && (
              <>
                <li className={styles.mainOption}>
                  <button
                    type="button"
                    onClick={() => {
                      mainOption.func();
                      setShowList(false);
                    }}
                  >
                    {mainOption.renderOption
                      ? mainOption.renderOption()
                      : mainOption.title}
                  </button>
                </li>
                <div className={styles.seperator} />
              </>
            )}
            {options
              .filter((option) => !option.hidden)
              .map((option, index) => (
                <li className={option.className} key={`option-${index}`}>
                  <button
                    type="button"
                    onClick={() => {
                      option.func();
                      setShowList(false);
                    }}
                  >
                    {option.renderOption ? option.renderOption() : option.title}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Options;
