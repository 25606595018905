import { DateTime } from "luxon";
import { useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";

import styles from "../minimalGraph/MinimalGraph.module.scss";

import colors from "~/styles/colors";

type CustomLineChartProps<T> = {
  data: { [key: string]: { date: string; score: number }[] };
  keys: { label: string; key: keyof T }[];
};

const MinimalLineChart = <T,>({ data, keys }: CustomLineChartProps<T>) => {
  const axisLine = { stroke: colors.navyBlue20, strokeWidth: 2 };

  const [selectedKey, setSelectedKey] = useState(keys[0].key.toString());

  return (
    <div className={styles.wrapper}>
      <div className={styles.keys}>
        {keys.map((key, index) => (
          <button
            key={`line-key-${index}-${key.key as string}`}
            onClick={() => setSelectedKey(key.key.toString())}
            className={
              selectedKey === key.key.toString()
                ? styles.activeButton
                : styles.button
            }
          >
            {key.label}
          </button>
        ))}
      </div>
      <ResponsiveContainer height={250}>
        <LineChart
          data={data[selectedKey]}
          margin={{ top: 20, bottom: 23, right: 10 }}
        >
          <CartesianGrid
            vertical={false}
            strokeDasharray="3"
            fill={colors.navyBlue0}
          />
          <XAxis
            angle={-45}
            textAnchor="end"
            dataKey="date"
            axisLine={axisLine}
            tickLine={false}
            tickFormatter={(date) => DateTime.fromISO(date).toFormat("dd/MM")}
            tick={{ fontSize: 12 }}
            tickSize={0}
          />
          <YAxis
            dataKey={"score"}
            axisLine={axisLine}
            tick={{
              fill: colors.navyBlue50
            }}
            tickLine={false}
            interval={0}
            allowDecimals={false}
          />
          <Line
            type="monotone"
            dataKey="score"
            stroke={colors.lightBlue100}
            strokeWidth={3}
            connectNulls
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MinimalLineChart;
