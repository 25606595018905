export enum UserPrivileges {
  Compliance = 8,
  Provider = 9,
  Coach = 10,
  LeadCoach = 15,
  Admin = 20
}

export const getUserPrivilegeKey = (privileges: number): string => {
  const privilegeMapping: { [key: number]: string } = {
    [UserPrivileges.Compliance]: "Compliance",
    [UserPrivileges.Provider]: "Provider",
    [UserPrivileges.Coach]: "Coach",
    [UserPrivileges.LeadCoach]: "Lead Coach",
    [UserPrivileges.Admin]: "Admin"
  };

  return privilegeMapping[privileges] || "Unknown";
};
