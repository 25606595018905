import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./ActionBubble.module.scss";

import Tooltip from "~/components/tooltip/Tooltip";

type ActionBubbleProps = {
  tooltipContent: ReactNode;
  icon: string;
  url?: string;
  isActive?: boolean;
  actionCount?: number;
  extraAttention?: boolean;
  className?: string;
  bottomText?: string;
  onClick?: () => void;
};

const ActionBubble = ({
  tooltipContent,
  icon,
  url,
  isActive,
  actionCount = 0,
  extraAttention,
  className,
  bottomText,
  onClick
}: ActionBubbleProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Link
      onClick={onClick}
      className={!isActive ? styles.disabled : ""}
      to={url ?? ""}
    >
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className={`${styles.actionIconDivider} ${
          extraAttention && styles.extraAttention
        } ${className}`}
        data-count={`${actionCount}`}
      >
        <img
          className={`${styles.actionIcon} ${isActive && styles.active} ${
            extraAttention && styles.extraAttentionIcon
          } `}
          src={icon}
        />

        <Tooltip
          content={tooltipContent}
          className={styles.tooltip}
          show={showTooltip}
        />
        <p className={styles.bottomText}>{bottomText ?? ""}</p>
      </div>
    </Link>
  );
};

export default ActionBubble;
