import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import styles from "./EditEmailFrequency.module.scss";

import Modal from "~/components/modal/Modal";
import CustomSelect from "~/components/select/CustomSelect";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import useUser from "~/hooks/useUser";
import { AppDispatch } from "~/state/store";
import { setUserSetting } from "~/state/user/userSlice";

type EditEmailFrequencyProps = {
  onClose: () => void;
};

export default function EditEmailFrequency({
  onClose
}: EditEmailFrequencyProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { user, settings, userStatus, settingsStatus } = useUser();
  const [hasUpdatedFrequency, setHasUpdatedFrequency] = useState(false);

  const [emailFrequency, setEmailFrequency] = useState(
    settings?.providerEmailFrequency ?? ""
  );

  const frequencyOptions = [
    {
      value: "weekly",
      text: t("time.weekly", "Every week")
    },
    {
      value: "bi-weekly",
      text: t("time.biWeekly", "Bi-weekly")
    },
    {
      value: "monthly",
      text: t("time.monthly", "Monthly")
    }
  ];

  useEffect(() => {
    if (hasUpdatedFrequency) {
      if (settingsStatus === "failed") {
        displayErrorToast({
          message: t("provider.emailFrequencyError")
        });
      }
      if (settingsStatus === "success") {
        displaySuccessToast({
          message: t("provider.emailFrequencySuccess")
        });
        onClose();
      }
    }
  }, [settingsStatus]);

  const handleSave = async () => {
    toast.remove();
    dispatch(
      setUserSetting({
        userId: user?.id ?? "",
        settingName: "providerEmailFrequency",
        setting: { value: emailFrequency }
      })
    );
    setHasUpdatedFrequency(true);
  };

  return (
    <Modal
      className={styles.modal}
      onClose={onClose}
      title={t("header.settings")}
    >
      <div className={styles.editProvider}>
        <div className={styles.selectWrapper}>
          <label className={"label label--block"}>
            {t("provider.emailFrequency")}
          </label>
          <CustomSelect
            onChange={(e) => setEmailFrequency(e.target.value)}
            options={frequencyOptions}
            value={emailFrequency}
            placeholder={"Frequency..."}
            renderOption={(option) => option.text}
          />
        </div>
        <button
          className={`btn-primary`}
          disabled={
            userStatus === "loading" ||
            settingsStatus === "loading" ||
            emailFrequency === ""
          }
          onClick={handleSave}
        >
          {t("general.save")}
        </button>
        <br></br>
      </div>
    </Modal>
  );
}
