import { useSelector } from "react-redux";

import { RootState } from "~/state/store";

export default function useUseMetric() {
  const settings = useSelector((state: RootState) => state.user.settings);

  if (!settings) {
    return false;
  }

  return Boolean(settings["useMetric"]);
}
