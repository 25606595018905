import colors from "~/styles/colors";

type ThinCheckMarkProps = {
  color?: string;
};

const ThinCheckMark = ({ color = colors.navyBlue60 }: ThinCheckMarkProps) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.735 4.17761L7.3695 11.8422C7.2987 11.944 7.20475 12.0274 7.09538 12.0857C6.98601 12.144 6.86436 12.1755 6.74044 12.1775C6.61652 12.1796 6.49389 12.1521 6.38266 12.0975C6.27142 12.0428 6.17476 11.9625 6.10065 11.8632C6.10065 11.8632 3.91971 9.55248 3.38671 8.8419"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThinCheckMark;
