import { t } from "~/i18n";

const welcomeNoteItemType = 0;

export const historicalNote = 1;

const pharmacistConsultantNote = 5;
const medicationNote = 7;
const referralsNote = 8;
const symptomsNote = 9;
const treatmentPlanNote = 10;
const sdohNote = 11;
const importantUpcomingDatesNote = 12;
const diagnosisNote = 13;
const dietitianNote = 14;
const cmNote = 15;
const healthImpactNote = 16;
const goalsNote = 17;
export const generalNote = 18;
export const otherHistoricalNote = 20;

export const historicalTypes = [historicalNote, otherHistoricalNote];

export type JournalItemType = {
  title: string;
  itemType: number;
};

export const welcomeJournalItemType = {
  title: "General",
  itemType: welcomeNoteItemType
};

export const journalItemTypes = [
  {
    title: t("notes.itemType.medication"),
    itemType: medicationNote
  },
  {
    title: t("notes.itemType.historical"),
    itemType: historicalNote
  },
  {
    title: t("notes.itemType.otherHistorical"),
    itemType: otherHistoricalNote
  },
  {
    title: t("notes.itemType.healthImpact"),
    itemType: healthImpactNote
  },
  {
    title: t("notes.itemType.goals"),
    itemType: goalsNote
  },
  {
    title: t("notes.itemType.referrals"),
    itemType: referralsNote
  },
  {
    title: t("notes.itemType.cm"),
    itemType: cmNote
  },

  {
    title: t("notes.itemType.pharmacist"),
    itemType: pharmacistConsultantNote
  },
  {
    title: t("notes.itemType.general"),
    itemType: generalNote
  },
  {
    title: t("notes.itemType.symptoms"),
    itemType: symptomsNote
  },
  {
    title: t("notes.itemType.treatmentPlan"),
    itemType: treatmentPlanNote
  },
  {
    title: t("notes.itemType.sdoh"),
    itemType: sdohNote
  },
  {
    title: t("notes.itemType.upcomingDates"),
    itemType: importantUpcomingDatesNote
  },
  {
    title: t("notes.itemType.diagnosis"),
    itemType: diagnosisNote
  },
  {
    title: t("notes.itemType.dietitian"),
    itemType: dietitianNote
  }
];

export const MAX_PINNED_NOTES = 6;
