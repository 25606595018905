import styles from "./ErrorScreen.module.scss";

import ErrorGlitterIcon from "~/assets/errortriangle.png";

type ErrorScreenProps = {
  text: string;
};

const ErrorScreen = ({ text }: ErrorScreenProps) => {
  return (
    <div className={styles.wrapper}>
      <img src={ErrorGlitterIcon} alt="Error" />
      <p>{text}</p>
    </div>
  );
};

export default ErrorScreen;
