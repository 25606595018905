import config from "~/config";
import { clockTime } from "~/helpers/date/dateHelpers";
import { t } from "~/i18n";
import { CommunicationPreferencesData } from "~/typing/carePortalTypes";

export const buildPreferenceString = (
  optedOutOfSms: boolean,
  preferences?: CommunicationPreferencesData,
  timezone?: string
) => {
  const variables: string[] = [];
  if (!preferences) {
    return t("communicationPreferences.notDefined");
  }
  if (
    preferences.agreeableToSms === undefined &&
    preferences.agreeableToPhone === undefined &&
    preferences.agreeableToEmail === undefined
  ) {
    return t("communicationPreferences.notDefined");
  }

  if (preferences.agreeableToSms !== undefined) {
    if (preferences.agreeableToSms && !optedOutOfSms) {
      variables.push(t("general.sms"));
    } else {
      variables.push(t("communicationPreferences.noSms"));
    }
  }
  if (preferences.agreeableToPhone !== undefined) {
    const phoneString = preferences.agreeableToPhone
      ? t("general.phone")
      : t("communicationPreferences.noPhone");
    variables.push(phoneString);
  }
  if (preferences.agreeableToEmail !== undefined) {
    const emailString = preferences.agreeableToEmail
      ? t("general.email")
      : t("communicationPreferences.noEmail");
    variables.push(emailString);
  }
  if (preferences.availableTimes?.length ?? 0 > 0) {
    variables.push(
      `Available times: ${preferences.availableTimes
        ?.map((time) =>
          time.startTime && time.endTime
            ? `${clockTime(time.startTime, !config.isAnthem)} - ${clockTime(
                time.endTime,
                !config.isAnthem
              )}`
            : ""
        )
        .join(", ")
        .concat(timezone && config.isAnthem ? ` (${timezone})` : "")}`
    );
  }
  return variables.join(", ");
};
