import { DateTime } from "luxon";

import { hasQuitOrFinished, hasFinishedProgram } from "./userHelpers";

import getMostRecentProgram from "../program/getMostRecentProgram";

import { UserProgram, UserDetails } from "~/typing/sidekickTypes";

export const getCurrentSlotDay = (userProgram: UserProgram | undefined) => {
  const currentSlotStartDate = DateTime.fromISO(
    userProgram?.currentSlotStartDate ?? ""
  );
  const slotDay =
    Math.floor(DateTime.local().diff(currentSlotStartDate, "days").days) + 1;

  return slotDay;
};

export const getUserMostRecentProgramInfo = ({
  userDetail,
  programId,
  locale
}: {
  userDetail: UserDetails;
  programId: string;
  locale: string;
}) => {
  const activePrograms = userDetail?.userPrograms?.filter(
    (program) =>
      program.programCatalogItemId === programId &&
      program.locale === locale &&
      !hasQuitOrFinished(program.status)
  );

  const mostRecentProgram = getMostRecentProgram(
    activePrograms.length > 0 ? activePrograms : userDetail?.userPrograms,
    programId,
    locale
  );

  const isFinished =
    mostRecentProgram === undefined ||
    hasFinishedProgram(mostRecentProgram?.status ?? 0);

  return {
    isFinished,
    leftDate: mostRecentProgram?.leftDate ?? "",
    programName: mostRecentProgram?.programName ?? "",
    currentSlot: mostRecentProgram?.currentSlot ?? ""
  };
};
