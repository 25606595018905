import { useEffect } from "react";

import styles from "./UserGuidePage.module.scss";

import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";

const UserGuidePage = () => {
  const { trackUserGuideOpened } = useAmplitudeTracking();

  useEffect(() => {
    trackUserGuideOpened();
  }, []);

  return (
    <SentryErrorBoundary transactionName="UserGuidePage">
      <Layout>
        <div data-testid="userguide-page-wrapper" className={styles.wrapper}>
          <object
            data={
              "https://assets.sidekickhealth.com/cc/care-portal-user-guide.pdf"
            }
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>
              It seems like your browser does not support rendering this PDF
            </p>
          </object>
        </div>
      </Layout>
    </SentryErrorBoundary>
  );
};

export default UserGuidePage;
