import classNames from "classnames";
import { ForwardedRef, useEffect, useState } from "react";

import styles from "./NoteForm.module.scss";

import Pin from "~/assets/svgComponents/Pin";
import AutoResizeTextarea from "~/components/autoresizeTextarea/AutoresizeTextarea";
import Avatar from "~/components/avatar/Avatar";
import CustomSelect from "~/components/select/CustomSelect";
import {
  generalNote,
  historicalTypes,
  journalItemTypes
} from "~/constants/notes";
import { MAX_PINNED_NOTES } from "~/constants/notes";
import useUser from "~/hooks/useUser";
import { useUserNotes } from "~/hooks/useUserNotes";
import { t } from "~/i18n";
import colors from "~/styles/colors";

interface NoteFormProps {
  onSave: (value: string, itemType: number, isPinned: boolean) => void;
  onCancel?: () => void;
  textAreaRef: ForwardedRef<HTMLTextAreaElement>;
  standalone?: boolean;
}

const NoteForm = ({
  onSave,
  onCancel,
  textAreaRef,
  standalone = false
}: NoteFormProps) => {
  const [content, setContent] = useState("");
  const [isPinned, setIsPinned] = useState(false);
  const [journalItemType, setJournalItemType] = useState(generalNote);
  const { user } = useUser();
  const [error, setError] = useState("");
  const [pinHovering, setPinHovering] = useState(false);
  const { pinnedNotes } = useUserNotes();

  useEffect(() => {
    const amountOfPinnedNotes = pinnedNotes.length + (isPinned ? 1 : 0);
    if (amountOfPinnedNotes <= MAX_PINNED_NOTES) {
      setError("");
    } else {
      setError(t("notes.tooManyPins"));
    }
  }, [pinnedNotes]);

  const handleOnSave = () => {
    if (pinnedNotes.length >= MAX_PINNED_NOTES && isPinned) {
      setError(t("notes.tooManyPins"));
      return;
    }

    onSave(content.trim(), journalItemType, isPinned);
    setContent("");
    setJournalItemType(generalNote);
    setIsPinned(false);
    setError("");
  };

  const handlePin = () => {
    if (!isPinned && pinnedNotes.length >= MAX_PINNED_NOTES) {
      setError(t("notes.tooManyPins"));
    } else {
      if (error) {
        setError("");
      }
    }
    setIsPinned(!isPinned);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.standalone]: standalone
      })}
    >
      <div className={styles.header}>
        <div
          data-testid="noteform-noteinfo"
          className={classNames(styles.noteInfo, {
            [styles.standalone]: standalone
          })}
        >
          {standalone ? (
            <Avatar size="mdplus" user={user} />
          ) : (
            <h2>{t("notes.addNote")}</h2>
          )}
          <CustomSelect
            options={journalItemTypes.filter(
              (type) => !historicalTypes.includes(type.itemType)
            )}
            renderOption={(option) => option.title}
            value={journalItemType}
            valueKey="itemType"
            onChange={(e) => setJournalItemType(parseInt(e.target.value))}
            className={styles.dropdown}
            wrapperClassName={styles.dropdownWrapper}
          />
          <button
            className={classNames(
              styles.pinButton,
              "btn btn-secondary btn-sm",
              { [styles.pinned]: isPinned }
            )}
            onClick={handlePin}
            onMouseEnter={() => setPinHovering(true)}
            onMouseLeave={() => setPinHovering(false)}
          >
            <Pin color={colors.darkBlue80} hollow={!isPinned || pinHovering} />
            <p>{t("notes.pin")}</p>
          </button>
        </div>
      </div>
      <AutoResizeTextarea
        dataTestId="noteform-textarea"
        value={content}
        onChange={(value) => setContent(value)}
        placeholder={t("notes.inputPlaceholder")}
        className={styles.textArea}
        ref={textAreaRef}
      />

      {error && <p className={styles.error}>{error}</p>}
      <div
        className={classNames(styles.footer, {
          [styles.footerReverted]: !standalone
        })}
      >
        <button
          className={classNames("btn btn-link", styles.cancelButton)}
          onClick={onCancel}
          data-testid="noteform-save"
        >
          {t("general.cancel")}
        </button>
        <button
          disabled={!content.trim() || Boolean(error)}
          className="btn btn-primary "
          onClick={handleOnSave}
          data-testid="noteform-save"
        >
          {t("general.save")}
        </button>
      </div>
    </div>
  );
};

export default NoteForm;
