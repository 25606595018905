export enum SMSStatus {
  NotSent = 0,
  Sent = 1,
  Unknown = 2,
  Failed = 3,
  Processing = 4,
  Delivered = 5,
  Undelivered = 6,
  Scheduled = 8
}

export enum TextMessageType {
  Unknown = 0,
  Verification = 1,
  Invite = 2,
  InviteFromLandingPage = 3,
  UserResponse = 4,
  AdhocMessage = 5,
  OptOutInfo = 6,
  Insights = 7, //SMS sent through the insights service
  InviteFromCoach = 8 //Invite SMS sent from coach in the Care portal
}

export enum TextMessageSmsStatus {
  NotSent = 0,
  Sent = 1,
  Unknown = 2,
  Failed = 3,
  Processing = 4,
  Delivered = 5,
  Undelivered = 6,
  Scheduled = 8
}

export const TextMessageOkStatuses = [
  SMSStatus.Sent,
  SMSStatus.Processing,
  SMSStatus.Delivered,
  SMSStatus.Scheduled
];

export const TextMessageErrorStatuses = [
  SMSStatus.Unknown,
  SMSStatus.Failed,
  SMSStatus.Undelivered
];
