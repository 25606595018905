import { useQueries } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { QueryKeyFactory } from "./queryKeysFactory";

import { getMissionScores } from "~/api/requests/missionRequests";

type QueryProps = {
  userId: string;
  programCatalogItemId: string;
  locale: string;
  sleepMissionId: string;
  energyMissionId: string;
  stressMissionId: string;
};

export const usePatientMetrics = ({
  userId,
  programCatalogItemId,
  locale,
  sleepMissionId,
  energyMissionId,
  stressMissionId
}: QueryProps) => {
  const ids = [sleepMissionId, energyMissionId, stressMissionId];

  const scores = useQueries({
    queries: ids.map((id) => ({
      queryKey: QueryKeyFactory.users.missionScores(
        userId,
        id,
        programCatalogItemId,
        locale
      ),
      enabled: !!id,
      queryFn: () =>
        getMissionScores({
          missionId: id,
          locale,
          programCatalogItemId,
          userId,
          startDate: DateTime.now().minus({ weeks: 2 }),
          endDate: DateTime.now()
        })
    }))
  });

  return {
    sleepScores: scores[0].data ?? [],
    energyScores: scores[1].data ?? [],
    stressScores: scores[2].data ?? [],
    isLoading: scores.some((d) => d.isLoading)
  };
};
