import styles from "./SkeletonCareManagerTasks.module.scss";
import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";

type SkeletonCareManagerTasksProps = {
  quantity?: number;
};

export default function SkeletonCareManagerTasks({
  quantity = 5
}: SkeletonCareManagerTasksProps) {
  return (
    <>
      {Array.from({ length: quantity }).map((_, index) => (
        <div className={`skeleton-wrapper ${styles.wrapper}`} key={index}>
          <SkeletonElement type="avatar" />
          <div>
            <SkeletonElement type="text" />
            <SkeletonElement type="text" />
          </div>
          <SkeletonShimmer />
        </div>
      ))}
    </>
  );
}
