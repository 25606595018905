import api from "../api";

import { ProgramSpecialty, UserSpecialty } from "~/typing/sidekickTypes";

export const assignSpecialtyToCoach = async (specialtyId: string) => {
  return await api
    .post<UserSpecialty>("/coach/me/specialty", {
      specialtyId
    })
    .then((res) => res.data);
};

export const getProgramSpecialties = async (
  programId: string,
  locale: string
) => {
  return await api
    .get<{ items: ProgramSpecialty[] }>(
      `/coach/programs/${programId}/locales/${locale}/specialties`
    )
    .then((res) => res.data.items);
};
