import { useTranslation } from "react-i18next";

import styles from "./HealthCard.module.scss";

type HealthCardProps = {
  title: string;
  date: string;
  value?: string | number;
  unit?: string;
  iconSrc: string;
  status?: { text: string; color: string };
  onClickCard?: () => void;
};

export default function HealthCard({
  title,
  date,
  value,
  unit,
  iconSrc,
  status,
  onClickCard
}: HealthCardProps) {
  const footerClasses = () => {
    let classes = styles.footer;
    switch (status?.color) {
      case "green":
        classes += ` ${styles.green}`;
        break;
      case "yellow":
        classes += ` ${styles.yellow}`;
        break;
      case "pink":
        classes += ` ${styles.pink}`;
    }

    return classes;
  };

  const { t } = useTranslation();

  const cardClasses =
    styles.HealthCard + (onClickCard ? ` ${styles.clickable}` : "");

  return (
    <div
      data-testid={onClickCard ? "health-card-clickable" : ""}
      className={cardClasses}
      onClick={onClickCard}
    >
      <div data-testid="health-card-body" className={styles.body}>
        <div className={styles.top}>
          <div className={styles.title}>{title}</div>
          <div className={styles.date}>{date}</div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.value}>
            {value === undefined ||
            value === null ||
            (typeof value !== "string" && isNaN(value))
              ? "- "
              : value}
            <span className={styles.unit}>{unit}</span>
          </div>
          <img src={iconSrc} className={styles.icon} />
        </div>
      </div>
      <div className={footerClasses()}>
        {status?.text || t("user.health.status.notApplicable")}
      </div>
    </div>
  );
}
