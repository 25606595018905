import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";

export default function useProgramLocales({
  programCatalogItemId,
  disabled
}: {
  programCatalogItemId: string;
  disabled: boolean;
}) {
  const path = `/coach/programs/${programCatalogItemId}/locales`;

  const { data, isError, isLoading, invalidate } = useApi<{
    items: string[];
  }>({
    path,
    queryKey: QueryKeyFactory.programs.programLocales(programCatalogItemId),
    staleTime: TimeInMs.Minute * 20,
    disabled: disabled
  });

  const sortedLocales = (locales: string[]) => {
    // Sort locales, ensuring "en" is first if it exists, otherwise sort alphabetically
    const enIndex = locales.indexOf("en");
    if (enIndex > -1) {
      locales.splice(enIndex, 1);
      locales.sort();
      locales.unshift("en");
    } else {
      locales.sort();
    }
    return locales;
  };

  return {
    programLocales: sortedLocales(data?.items ?? []),
    isError,
    isLoading,
    invalidate
  };
}
