import config from "~/config";
import { ExternalUser } from "~/typing/sidekickTypes";

export default function handleClickHRSLink(externalUser: ExternalUser) {
  const { anthemHRSID, caseIdentifier } = externalUser;
  const baseUrl = config.anthemHRSDeepLinkBaseUrl;
  let url = baseUrl;
  if (caseIdentifier && !caseIdentifier.includes(";")) {
    url += `?caseId=${caseIdentifier}&platformCD=HA`;
  } else if (anthemHRSID) {
    url += `?memberID=${anthemHRSID}`;
  }
  window.open(url, "_blank");
}
