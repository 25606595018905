import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { ComplianceUserDetail } from "~/typing/sidekickTypes";

export default function useComplianceUserDetail(
  programCatalogItemId: string,
  locale: string,
  userId: string
) {
  const path = `/admin/programcatalogitems/${programCatalogItemId}/locales/${locale}/compliance/users/${userId}`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: ComplianceUserDetail[];
  }>({
    path: path,
    queryKey: QueryKeyFactory.complianceUsers.user(
      programCatalogItemId,
      locale,
      userId
    )
  });

  return {
    complianceUserDetail: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
