import config from "~/config";
import { ExternalUser } from "~/typing/sidekickTypes";

export default function handleClickHipLink(externalUser: ExternalUser) {
  let url = config.anthemHipDeepLinkBaseUrl;

  const { caseIdentifier, anthemMCID } = externalUser;

  url += `?caseID=${caseIdentifier}&mcid=${anthemMCID}`;

  window.open(url, "_blank");
}
