import { useTranslation } from "react-i18next";

import styles from "./ProgramHeader.module.scss";

import ProgramUserActivityChart from "../programUserActivityChart/ProgramUserActivityChart";

import LanguageIcon from "~/assets/language.svg";
import ActiveUsersIcon from "~/assets/users-active.svg";
import NewUsersIcon from "~/assets/users-new.svg";
import Image from "~/components/image/Image";
import SkeletonProgramHeader from "~/components/skeletons/SkeletonProgramHeader";
import config from "~/config";
import getCountryFlagSrc from "~/helpers/getCountryFlagSrc";
import getLanguageFromLocale from "~/helpers/getLanguageFromLocale";
import {
  getProgramDisplayName,
  isTestProgram
} from "~/helpers/program/programHelpers";
import CoachList from "~/pages/components/coachList/CoachList";
import ProgramCardDetail from "~/pages/programOverview/components/programCardDetail/ProgramCardDetail";
import { Program } from "~/typing/sidekickTypes";

export interface ProgramHeaderProps {
  program?: Program;
  loading?: boolean;
}

const ProgramHeader = ({ program, loading }: ProgramHeaderProps) => {
  const { t } = useTranslation();

  const language = getLanguageFromLocale(program?.locale);

  const programCardDetailData = [
    {
      title: "country",
      content: program?.location?.name,
      imgSrc: getCountryFlagSrc(program?.location?.name),
      isCountry: true
    },
    {
      title: "language",
      content: language,
      imgSrc: LanguageIcon,
      isCountry: false
    },
    {
      title: "activeUsers",
      content: (
        <>
          {program?.activeUsers}
          <span>/{program?.userCount}</span>
        </>
      ),
      imgSrc: ActiveUsersIcon,
      isCountry: false
    },
    {
      title: "newUsers",
      content: program?.newUsers,
      imgSrc: NewUsersIcon,
      isCountry: false
    }
  ];

  const programCardDetailComponents = programCardDetailData.map(
    ({ title, content, imgSrc, isCountry }) => {
      return (
        <ProgramCardDetail
          title={title}
          key={title}
          content={content}
          className={styles.detail}
          imgSrc={imgSrc}
          isCountry={isCountry}
        />
      );
    }
  );

  const imageClasses =
    styles.image + (config.isAnthem ? ` ${styles.anthem}` : "");

  if (loading) return <SkeletonProgramHeader />;

  return (
    <div className={styles.ProgramHeader}>
      <Image
        imageUrl={program?.imageHref}
        className={imageClasses}
        ribbonText={
          isTestProgram(program) ? t("programs.test").toUpperCase() : undefined
        }
      />
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.details}>
            <div className={styles.top}>
              <div className={styles.nameTier}>
                <h1 data-testid="program-header-name" className={styles.name}>
                  {getProgramDisplayName(program)}
                </h1>
                <div
                  className={`${styles.tier} ${
                    styles[`tier-${program?.tier}`]
                  }`}
                >
                  Tier {program?.tier}
                </div>
              </div>
              <div className={styles.coaches}>
                <h5 className={styles.title}>
                  {t("programCardDetail.careManagers")}
                </h5>
                <CoachList
                  coaches={program?.coaches ?? []}
                  numToShow={3}
                  className={styles.coachList}
                />
              </div>
            </div>

            <div className={styles.inner}>{programCardDetailComponents}</div>
          </div>
        </div>

        <div className={styles.separator}></div>
        <div className={styles.userActivity}>
          <ProgramUserActivityChart
            active={program?.activeUsers ?? 0}
            total={program?.userCount ?? 0}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramHeader;
