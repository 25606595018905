import { useState } from "react";

import styles from "./TruncatedCellTextWithTooltip.module.scss";

import Tooltip from "~/components/tooltip/Tooltip";

type TruncatedCellTextWithTooltipProps = {
  cellText: string;
};

const TruncatedCellTextWithTooltip = ({
  cellText
}: TruncatedCellTextWithTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={styles.cell}>
      <div
        className={styles.cellText}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {cellText}
        <Tooltip
          content={cellText}
          className={styles.tooltip}
          show={showTooltip}
        />
      </div>
    </div>
  );
};

export default TruncatedCellTextWithTooltip;
