import { useId } from "react";
import { Tooltip } from "react-tooltip";

import styles from "./AccessManagementPage.module.scss";

import { filterOutUndefined } from "~/helpers/array/arrayHelpers";
import {
  getProgramDisplayName,
  isTestProgram
} from "~/helpers/program/programHelpers";
import {
  CoachUserStatistics,
  ProgramCatalogItemLocaleDto
} from "~/typing/graphql/types";
import { Program } from "~/typing/sidekickTypes";

export const AccessManagementProgramsColumn = ({
  pcis,
  programs,
  coachUserStatistics
}: {
  pcis: ProgramCatalogItemLocaleDto[];
  programs: Program[];
  coachUserStatistics: CoachUserStatistics[];
}) => {
  const id = useId();
  const mapPCItoProgram = (pci: ProgramCatalogItemLocaleDto) => {
    const program = programs.find(
      (p) => p.programCatalogItemId === pci?.programCatalogItemId.toString()
    );

    //Count user count for each program, using coachUserStatistics
    const userCount =
      coachUserStatistics.find(
        (cus) => cus.programCatalogItemId === pci?.programCatalogItemId
      )?.totalUserCount || 0;

    //If program name is longer than 14 characters, show only the first 14 characters and add "..."
    //Return tuple with short and long name. Short name will be used in the column, long name in the tooltip.
    const programName = getProgramDisplayName(program);
    const shortProgramName =
      programName && programName.length > 12
        ? programName.slice(0, 10) + "..."
        : programName;
    const testLabel = isTestProgram(program) ? " (TEST)" : "";
    return [shortProgramName, programName, userCount, testLabel];
  };

  const firstTwoPrograms = pcis.slice(0, 2);

  return (
    <>
      <div className={styles.program}>
        <p>
          {firstTwoPrograms
            .map(mapPCItoProgram)
            .filter(filterOutUndefined)
            .map((program) => program[0] + ` (${program[2]}) ${program[3]}`)
            .join(", ")}
        </p>
        {pcis.length > 2 && (
          <span
            data-tooltip-delay-show={250}
            data-tooltip-id={id}
            className={styles.more}
          >{`+${pcis.length - 2}`}</span>
        )}
      </div>
      <Tooltip
        data-testid="tooltip-care-team"
        className={styles.tooltip}
        place="right"
        id={id}
      >
        {pcis.map(mapPCItoProgram).map((program, index) => (
          <p key={`program-pci-${index}`}>
            {program[1]} ({program[2]}) {program[3]}
          </p>
        ))}
      </Tooltip>
    </>
  );
};
