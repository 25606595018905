import { useTranslation } from "react-i18next";

import styles from "./ChartDetail.module.scss";

import ArrowCornerIcon from "~/assets/arrow-corner-right.svg";
import PencilIcon from "~/assets/svgComponents/PencilIcon";
import getChartColorClass from "~/helpers/colours/getChartColorClass";

type ChartDetailProps = {
  title: string;
  values: any;
  units?: string;
  positive?: boolean;
  valueDiff?: number;
  timeDiff?: string;
  dataKeys: string[];
  dataKeyTexts?: any;
  allDataKeys?: string[];
  onEditClick?: () => void;
};

const ChartDetail = ({
  title,
  values,
  units = "",
  positive,
  valueDiff,
  timeDiff,
  dataKeys,
  dataKeyTexts,
  allDataKeys,
  onEditClick
}: ChartDetailProps) => {
  const { t } = useTranslation();

  const isValueUp = valueDiff && valueDiff >= 0;
  const arrowClasses = `${styles.arrow} ${isValueUp ? styles.up : ""} ${
    positive ? styles.positive : ""
  }`;
  const valueDiffClasses = `${styles.valueDiff} ${
    positive ? styles.positive : styles.negative
  }`;
  const diffText = `${
    isValueUp
      ? t("chartDetail.moreThan", "more than")
      : t("chartDetail.lessThan", "less than")
  } ${timeDiff}`;

  const showDifference =
    timeDiff &&
    dataKeys.length < 2 &&
    (!allDataKeys || dataKeys[0] === allDataKeys[0]);

  if (!values) {
    values = {};
    values[dataKeys[0]] = 0;
  }

  const valueDisplay = () => {
    const value = values[dataKeys[0]];

    return isNaN(value) ? "-" : value;
  };

  return (
    <div className={styles.ChartDetail}>
      <div className={styles.title}>{title}</div>
      {dataKeys?.length < 2 && values && (
        <>
          <div className={styles.valueContainer}>
            <div data-testid="chart-detail-value" className={styles.value}>
              {valueDisplay()}
              <span className={styles.units}>{units}</span>
            </div>
            {onEditClick && (
              <button onClick={onEditClick}>
                <PencilIcon />
              </button>
            )}
          </div>
          {showDifference && (
            <div className={styles.change}>
              <img className={arrowClasses} src={ArrowCornerIcon} />
              {!valueDiff ? (
                <span className={valueDiffClasses}>-</span>
              ) : (
                <>
                  <span className={valueDiffClasses}>
                    {Math.abs(valueDiff)}
                  </span>
                  {diffText}
                </>
              )}
            </div>
          )}
        </>
      )}

      {dataKeys?.length >= 2 && values && (
        <div className={styles.comparisonContainer}>
          {dataKeys.map((key, i) => {
            const color = getChartColorClass(key, allDataKeys ?? []);
            const colorClass = styles[color];
            return (
              <div className={styles.valueContainer} key={key}>
                <div className={`${styles.value} ${colorClass}`}>
                  {values[key] || 0}
                  <span className={styles.units}>{units}</span>
                </div>
                <div className={styles.legend}>
                  <div
                    data-testid={`chart-detail-${color}`}
                    className={`${styles.color} ${colorClass}`}
                  />
                  {dataKeyTexts[dataKeys[i]]}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ChartDetail;
