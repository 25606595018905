import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";

const SkeletonPinnedNote = () => {
  return (
    <div className="skeleton-wrapper">
      <SkeletonElement type="title" />
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <SkeletonShimmer />
    </div>
  );
};

export default SkeletonPinnedNote;
