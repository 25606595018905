import { createContext } from "react";

import { UnreadMessagesState } from "~/pages/user/userPage/unreadMessagesReducer";
import {
  ChatMessage,
  Coach,
  EnrichedAuthenticatedUser,
  Program
} from "~/typing/sidekickTypes";

type MessageListContextProps = {
  usersForAvatars: { [key: number]: Coach };
  authUser?: EnrichedAuthenticatedUser;
  setMessageBeingRepliedTo: (message: ChatMessage) => void;
  unreadMessages?: UnreadMessagesState;
  program: Program;
  readMessageOnView?: boolean;
  searchQuery?: string;
};

export const MessageListContext = createContext<MessageListContextProps>(
  {} as MessageListContextProps
);
