import { useContext } from "react";

import styles from "./MissionCard.module.scss";
import { MissionPageContext } from "./missionsPageContext";

import CheckIcon from "~/assets/check.svg";
import { MissionCategory } from "~/constants/missionCategories";
import getMissionValueText from "~/helpers/mission/getMissionValueText";
import { MissionTask } from "~/typing/sidekickTypes";

type MissionCardProps = {
  mission: MissionTask;
  day: string;
};

const MissionCard = ({ mission, day }: MissionCardProps) => {
  const { handleMissionCardClick, clickableMissionIds, quizzes } = useContext(
    MissionPageContext
  );

  const complete = mission.currentValue >= mission.targetValue;

  const categoryClass = () => {
    if (!complete) {
      return "";
    }
    switch (mission.categoryId) {
      case MissionCategory.Food:
        return styles.food;
      case MissionCategory.Move:
        return styles.move;
      case MissionCategory.Mind:
      case MissionCategory.OpenMission: // Open mission and video
        return styles.mind;
      case MissionCategory.Survey:
        return styles.survey;
      default:
        return "";
    }
  };

  const cardIsClickable =
    complete &&
    (clickableMissionIds?.includes(mission.missionId) ||
      quizzes?.some((quiz) => quiz.surveyName === mission.name));

  const categoryClasses = `${styles.category} ${categoryClass()}`;

  const completionIndicatorClasses = `${styles.completionIndicator} ${
    complete && styles.complete
  }`;
  const classes = `${styles.MissionCard} ${
    cardIsClickable ? styles.clickable : ""
  }`;

  return (
    <div
      className={classes}
      onClick={
        cardIsClickable
          ? () =>
              handleMissionCardClick(
                mission.userMissionId,
                mission.missionId,
                mission.name,
                day
              )
          : undefined
      }
      data-testid="mission-card-wrapper"
    >
      <div className={categoryClasses} data-testid="mission-card-category" />
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.name}>{mission.name}</div>
          <div className={styles.value}>
            {getMissionValueText(
              mission.unit,
              mission.currentValue,
              mission.targetValue
            )}
          </div>
        </div>
        <div
          data-testid={
            complete ? "mission-card-complete" : "mission-card-completion"
          }
          className={completionIndicatorClasses}
        >
          <img src={CheckIcon} alt="check" />
        </div>
      </div>
    </div>
  );
};

export default MissionCard;
