import { useState } from "react";

import styles from "./Accordion.module.scss";

import ChevronDown from "~/assets/chevron-down-next-step.svg";
import { useChildrenTabIndexToggle } from "~/hooks/useChildrenTabIndexToggle";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";

type AccordionProps = {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
};

const Accordion = ({ title, subTitle, children }: AccordionProps) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const { parentRef } = useChildrenTabIndexToggle({
    childrenHidden: !accordionExpanded
  });

  const { trackAccordionExpanded } = useAmplitudeTracking();

  const toggleAccordion = () => {
    setAccordionExpanded((prev) => !prev);
    if (!accordionExpanded) {
      trackAccordionExpanded({ topic: title });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        <p className={styles.subTitle}>{subTitle}</p>
        <button
          tabIndex={0}
          className={styles.chevron}
          onClick={toggleAccordion}
        >
          {accordionExpanded ? (
            <img
              src={ChevronDown}
              className={`${styles.chevron}  ${styles.rotate}`}
            />
          ) : (
            <img src={ChevronDown} className={styles.chevron} />
          )}
        </button>
      </div>
      {
        <div
          className={`${styles.content} ${
            accordionExpanded ? styles.contentOpen : ""
          }`}
          ref={parentRef}
          aria-hidden={!accordionExpanded}
        >
          {children}
        </div>
      }
    </div>
  );
};

export default Accordion;
