import styles from "./SkeletonChartRating.module.scss";
import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";

const SkeletonChartRating = () => {
  return (
    <div className={`skeleton-wrapper ${styles.wrapper}`}>
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <SkeletonShimmer />
    </div>
  );
};

export default SkeletonChartRating;
