export const getStringLengthInPixels = (value: string, fontSize = 1) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return 0;
  }
  ctx.font = `${fontSize}rem Proxima Nova`;
  return ctx.measureText(value).width;
};

export const stringsAreEqual = (
  stringOne: string | undefined,
  stringTwo: string | undefined
) => {
  if (stringOne && stringTwo) {
    return stringOne.trim() === stringTwo.trim();
  }
  if (!stringOne || !stringTwo) {
    return Boolean(stringOne?.trim()) === Boolean(stringTwo?.trim());
  }
  return false;
};

export const trimmedSringsAreEqual = (
  stringOne: string | undefined,
  stringTwo: string | undefined
) => {
  return stringsAreEqual(stringOne?.trim(), stringTwo?.trim());
};

export const wordCount = (value?: string) => {
  const match = value?.match(/[^\s]+/g);
  return match ? match.length : 0;
};

export const capitalizeWord = (value?: string) => {
  if (!value || value.length === 0) return "";

  if (value.length === 1) return value.toUpperCase();

  const firstLetter = value[0].toUpperCase();

  const restOfWord = value.substring(1).toLowerCase();

  return firstLetter + restOfWord;
};

export const getCorrectPluralString = (
  length: number,
  singularString: string,
  pluralString: string
) => {
  return `${length} ${
    length > 1 || length === 0 ? pluralString : singularString
  }`;
};

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isValidEmail = (email: string) => {
  const re = /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return re.test(email.toLocaleLowerCase());
};

export const convertEnumToTitleCase = (value: string | undefined) => {
  if (!value) {
    return "";
  }
  return value
    .split("_")
    .map((word) => capitalizeWord(word))
    .join(" ");
};

export const escapeSpecialCharacters = (value: string = "") => {
  // escape batch of characters to avoid regex exceptions
  return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
