import { useTranslation } from "react-i18next";

import styles from "./ConfirmModal.module.scss";

import Modal from "~/components/modal/Modal";

interface ConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;
  modalTitle: string;
  confirmTitle?: string;
  confirmSubtitle?: string;
  cancelText?: string;
  confirmText?: string;
  type?: "info" | "warning";
}

const ConfirmModal = ({
  onClose,
  onConfirm,
  modalTitle,
  confirmTitle,
  confirmSubtitle,
  cancelText,
  confirmText,
  type = "info"
}: ConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal title={modalTitle} onClose={onClose}>
      {confirmTitle && <div className={styles.title}>{confirmTitle}</div>}
      {confirmSubtitle && (
        <div className={styles.subtitle}>{confirmSubtitle}</div>
      )}
      <div className={styles.actions}>
        <button
          type="button"
          className="btn-secondary btn-sm"
          onClick={onClose}
        >
          {cancelText || t("general.no", "No")}
        </button>
        <button
          type="button"
          className={`btn-primary btn-sm ${
            type === "warning" ? styles.red : ""
          }`}
          onClick={onConfirm}
        >
          {confirmText || t("general.yes", "Yes")}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
