import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import useApi from "~/hooks/useApi/useApi";

export type UserBaseWeight = {
  value: number;
  userId: string;
  createdById: string;
  createdDate: string;
};

export default function useBaseWeight(
  programCatalogItemId: string,
  locale: string,
  userId: string
) {
  const queryKey = QueryKeyFactory.users.baseWeight(
    programCatalogItemId,
    locale,
    userId
  );

  const path = `/coach/programcatalogitems/${programCatalogItemId}/locales/${locale}/users/${userId}/userbaseweight`;
  const { data, isError, isLoading, invalidate } = useApi<UserBaseWeight>({
    path,
    queryKey
  });

  return {
    weight: data,
    isError,
    isLoading,
    invalidate
  };
}
