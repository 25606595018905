import { useEffect, useRef } from "react";

interface Shortcut {
  keys: (string | number)[];
  callback: () => void;
}

enum SpecialKeys {
  Shift = "Shift",
  Alt = "Alt",
  Enter = "Enter"
}
enum KeyboardKeys {
  ArrowLeft = "ArrowLeft",
  ArrowUp = "ArrowUp",
  ArrowRight = "ArrowRight",
  ArrowDown = "ArrowDown",
  D = "KeyD",
  R = "KeyR",
  C = "KeyC"
}

export const KeyboardShortcuts = {
  markCarePriorityAsDone: [SpecialKeys.Shift, SpecialKeys.Alt, KeyboardKeys.D],
  prevUser: [SpecialKeys.Shift, SpecialKeys.Alt, KeyboardKeys.ArrowLeft],
  nextUser: [SpecialKeys.Shift, SpecialKeys.Alt, KeyboardKeys.ArrowRight],
  nextCarePriority: [
    SpecialKeys.Shift,
    SpecialKeys.Alt,
    KeyboardKeys.ArrowDown
  ],
  prevCarePriority: [SpecialKeys.Shift, SpecialKeys.Alt, KeyboardKeys.ArrowUp],
  sendMessage: [SpecialKeys.Shift, SpecialKeys.Alt, SpecialKeys.Enter],
  confirm: [SpecialKeys.Enter],
  copy: [SpecialKeys.Shift, SpecialKeys.Alt, KeyboardKeys.C],
  report: [SpecialKeys.Shift, SpecialKeys.Alt, KeyboardKeys.R]
};

const restrictedDOMNodes = ["TEXTAREA", "INPUT"];

export const useKeyboardShortcut = (
  shortcuts: Shortcut[],
  { ignoreInputs = true }: { ignoreInputs?: boolean } = {}
) => {
  const shortcutsRef = useRef(shortcuts);

  useEffect(() => {
    shortcutsRef.current = shortcuts;
  }, [shortcuts]);

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent) => {
      // Ignore shortcuts if the user is typing in an input/textarea field
      // or content editable element
      if (
        ignoreInputs &&
        (restrictedDOMNodes.includes((event.target as HTMLElement)?.tagName) ||
          (event.target as HTMLDivElement)?.isContentEditable)
      ) {
        return;
      }

      shortcutsRef.current.forEach(({ keys, callback }) => {
        const allKeysPressed = keys.every((key) => {
          if (key === SpecialKeys.Shift) return event.shiftKey;
          if (key === SpecialKeys.Alt) return event.altKey;
          return event.code === key;
        });

        if (allKeysPressed) {
          event.preventDefault();
          callback();
        }
      });
    };

    document.addEventListener("keydown", handleShortcut);

    return () => {
      document.removeEventListener("keydown", handleShortcut);
    };
  }, []);
};
