import { t } from "~/i18n";

export default function getHealthinessText(rating: number | undefined) {
  if (rating === undefined) {
    return t("performance.journals.review.neutral");
  }
  if (rating > 0.666) {
    return t("performance.journals.review.healthy");
  } else if (rating > 0.333) {
    return t("performance.journals.review.neutral");
  } else {
    return t("performance.journals.review.unhealthy");
  }
}
