import styles from "./CustomLineChartTooltip.module.scss";

import Cell from "~/components/cell/Cell";

type CustomLineChartTooltipProps<T> = {
  active?: boolean;
  payload?: {
    payload?: T & { longDate?: string };
    dataKey?: string | number;
    color?: string;
    name?: string | number;
    value?: any;
  }[];
  label?: string;
  units: string;
  getCellData?: (
    data: T | undefined
  ) => {
    cellTitle: string;
    cellColor: string;
  };
};

const CustomLineChartTooltip = <T,>(props: CustomLineChartTooltipProps<T>) => {
  const { active, payload, label, units, getCellData } = props;

  if (active && payload && payload.length) {
    const data = payload[0].payload ? payload[0].payload : undefined;

    return (
      <div className={styles.customLineChartTooltip}>
        <ul>
          <li className={styles.label}>
            {payload[0].payload?.longDate ? payload[0].payload.longDate : label}
            {getCellData && (
              <Cell
                text={getCellData(data).cellTitle}
                color={getCellData(data).cellColor}
              />
            )}
          </li>
          {payload.map((item) => (
            <li key={item.dataKey} style={{ color: item.color }}>
              {item.name}: {item.value} {units ? units : ""}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

export default CustomLineChartTooltip;
