import { useContext } from "react";
import { useTranslation } from "react-i18next";

import InformationPageHeaderInfo from "./InformationPageHeaderInfo";
import styles from "./InformationPageHeaderLayers.module.scss";
import { getAllowedLayersToDisplay } from "./layerHelpers";

import { InformationPageContext } from "../../informationPageContext";

const InformationPageHeaderLayers = () => {
  const { settings } = useContext(InformationPageContext);

  const { t } = useTranslation();

  const layers = getAllowedLayersToDisplay(settings);

  const renderLayers = () => {
    return (
      <div>
        {layers?.map((program, index) => (
          <div className={styles.layer} key={`program-layer-${index}`}>
            <p>{program.name}</p>
            <p>{`${t("actions.started")} ${program.joinedDate}`}</p>
          </div>
        ))}
      </div>
    );
  };

  return layers.length > 0 ? (
    <InformationPageHeaderInfo title="Layer" renderDetails={renderLayers} />
  ) : null;
};
export default InformationPageHeaderLayers;
