import { createContext } from "react";

import { Survey } from "~/typing/sidekickTypes";

type PROPageContextProps = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  surveys: Survey[];
  setUseTextBased: (value: boolean) => void;
  useTextBased: boolean;
};

export const PROPageContext = createContext<PROPageContextProps>(
  {} as PROPageContextProps
);
