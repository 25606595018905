import { idBelongsToUser } from "~/components/messages/messageWindow/MessageWindow.helpers";
import { TimeInMs } from "~/constants/measurements";
import { timeSince } from "~/helpers/date/dateHelpers";
import { ChatMessage, EnrichedAuthenticatedUser } from "~/typing/sidekickTypes";

export type UnreadMessage = {
  unreadByAuthUser: boolean;
  toAuthUser: boolean;
  recipientId: string;
  text: string;
  id: string;
  createdDate: string;
};

export type UnreadMessagesState = {
  unreadMessages: UnreadMessage[];
  totalUnreadToOtherCoaches: number | undefined;
  totalToAuthUser: number | undefined;
  initialized: boolean;
};

export enum UnreadMessagesActions {
  Update = "Update",
  Initialize = "Initialize",
  Reset = "Reset"
}

type ReadByAuthUserAction = {
  type: UnreadMessagesActions.Update;
  payload: {
    messages: ChatMessage[];
    authUser?: EnrichedAuthenticatedUser;
    patientUserId: string;
  };
};

type InitializeAction = {
  type: UnreadMessagesActions.Initialize;
  payload: {
    messages: ChatMessage[];
    authUser?: EnrichedAuthenticatedUser;
    patientUserId: string;
  };
};

type ResetAction = {
  type: UnreadMessagesActions.Reset;
};

type Action = ReadByAuthUserAction | InitializeAction | ResetAction;

const reducer = (
  state: UnreadMessagesState,
  action: Action
): UnreadMessagesState => {
  switch (action.type) {
    case UnreadMessagesActions.Update: {
      if (!action.payload.authUser || !state.initialized) return state;

      const { messages, authUser, patientUserId } = action.payload;

      const shouldProcessMessage = (message: ChatMessage) => {
        return (
          message.senderUserId === patientUserId &&
          (!message.seenDate || message.unseenByNonRecipient)
        );
      };

      const newUnreadMessages: UnreadMessage[] = [
        ...state.unreadMessages
      ].filter((unread) => {
        const isToAuthUser = idBelongsToUser(unread.recipientId, authUser);
        const createdMoreThan15SecondsAgo =
          timeSince(new Date(unread.createdDate)) > 15 * TimeInMs.Second;

        if (!isToAuthUser) return true;

        return !createdMoreThan15SecondsAgo;
      });

      messages.filter(shouldProcessMessage).forEach((message) => {
        const isToAuthUser = idBelongsToUser(message.recipientUserId, authUser);

        const messageIndex = newUnreadMessages.findIndex(
          (unread) => unread.id === message.id
        );

        const newUnreadMessage = {
          unreadByAuthUser: message.unseenByNonRecipient,
          toAuthUser: isToAuthUser,
          recipientId: message.recipientUserId,
          text: message.text,
          id: message.id,
          createdDate: message.createdDate
        };

        if (messageIndex > -1) {
          if (timeSince(new Date(message.createdDate)) > 15 * TimeInMs.Second) {
            newUnreadMessages.splice(messageIndex, 1, newUnreadMessage);
          }
        } else {
          newUnreadMessages.push(newUnreadMessage);
        }
      });

      const amountOfMessagesToAuthUser = newUnreadMessages.filter(
        (unread) => unread.toAuthUser
      ).length;

      return {
        unreadMessages: newUnreadMessages,
        totalToAuthUser: amountOfMessagesToAuthUser,
        totalUnreadToOtherCoaches: newUnreadMessages.filter(
          (message) => message.unreadByAuthUser
        ).length,
        initialized: state.initialized
      };
    }

    case UnreadMessagesActions.Initialize: {
      if (
        !action.payload.authUser ||
        action.payload.messages.length === 0 ||
        state.initialized
      ) {
        return state;
      }

      const unreadMessages: UnreadMessage[] = [];
      let totalUnreadToOtherCoaches: number | undefined = undefined;
      let totalToAuthUser: number | undefined = undefined;

      const { messages, authUser, patientUserId } = action.payload;
      const shouldProcessMessage = (message: ChatMessage) => {
        return message.senderUserId === patientUserId;
      };

      messages.filter(shouldProcessMessage).forEach((message) => {
        const isToAuthUser = idBelongsToUser(message.recipientUserId, authUser);
        if (
          !message.seenDate ||
          (!isToAuthUser && message.unseenByNonRecipient)
        ) {
          unreadMessages.push({
            unreadByAuthUser: message.unseenByNonRecipient,
            toAuthUser: isToAuthUser,
            recipientId: message.recipientUserId,
            text: message.text,
            id: message.id,
            createdDate: message.createdDate
          });
        }

        if (isToAuthUser && !message.seenDate) {
          totalToAuthUser = (totalToAuthUser ?? 0) + 1;
        } else if (message.unseenByNonRecipient) {
          totalUnreadToOtherCoaches = (totalUnreadToOtherCoaches ?? 0) + 1;
        }
      });

      return {
        unreadMessages,
        totalToAuthUser,
        totalUnreadToOtherCoaches,
        initialized: true
      };
    }
    case UnreadMessagesActions.Reset: {
      return {
        unreadMessages: [],
        initialized: false,
        totalToAuthUser: 0,
        totalUnreadToOtherCoaches: 0
      };
    }

    default:
      return state;
  }
};

export default reducer;
