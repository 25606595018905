import { isBetweenDates } from "~/helpers/date/isBetweenDates";
import { t } from "~/i18n";
import { NoteInfo } from "~/typing/sidekickTypes";

export const filterNoteBySearchString = (
  note: NoteInfo,
  searchString: string
) => {
  return (
    searchString === "" ||
    note.text?.toLowerCase().includes(searchString.toLowerCase())
  );
};

export const filterNoteByCareManager = (
  note: NoteInfo,
  selectedCareManager: string
) => {
  return (
    selectedCareManager === t("notes.allCareManagers") ||
    note.coachUser?.displayName === selectedCareManager
  );
};

export const filterNoteByDate = (
  note: NoteInfo,
  startDate?: Date,
  endDate?: Date
) => {
  return isBetweenDates(
    new Date(note.createdDate).toISOString(),
    startDate,
    endDate
  );
};

export const filterByTopics = (
  note: NoteInfo,
  selectedTopics: Map<number, boolean>
) => {
  return selectedTopics.get(note.itemType);
};
