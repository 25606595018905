import styles from "./TimelineStatus.module.scss";

type TimelineStatusProps = {
  statuses: {
    title: string;
    id: string | number;
    order: number;
    date?: string | null;
  }[];
  currentStatus?: number;
};

const TimelineStatus = ({ statuses, currentStatus }: TimelineStatusProps) => {
  const orderedStatuses = statuses.sort((statusA, statusB) =>
    statusA.order <= statusB.order ? -1 : 1
  );

  const indexOfCurrentStatus = orderedStatuses.findIndex(
    (status) => status.order === currentStatus
  );

  const timeLinePercentage =
    ((indexOfCurrentStatus ?? 0) / (orderedStatuses.length - 1)) * 100 + 1;

  const style = {
    "--status-percentage": `${timeLinePercentage}%`
  } as React.CSSProperties;

  return (
    <div data-testid="timeline-status-wrapper" className={styles.wrapper}>
      <div className={styles.titlesWrapper}>
        {orderedStatuses.map((status, index) => (
          <div className={styles.statusWrapper} key={`title-status-${index}`}>
            <p data-testid="status-title" className={styles.statusTitle}>
              {status.title}
            </p>
          </div>
        ))}
      </div>
      <div className={styles.statusLine} style={style} data-percentage={"50%"}>
        {orderedStatuses.map((status, index) => (
          <span
            key={index}
            data-testid={
              status.order <= (currentStatus ?? 0)
                ? "active-progress-dot"
                : "progress-dot"
            }
            className={`${styles.progressDot} ${
              status.order <= (currentStatus ?? 0) ? styles.active : ""
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default TimelineStatus;
