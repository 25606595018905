import { DateTime } from "luxon";

export const getLineData = <T>(data: T[]): T[] => {
  return data
    .map((item: any) => {
      const date = DateTime.fromISO(item.date).toLocaleString({
        month: "short",
        day: "2-digit"
      });
      const longDate = DateTime.fromISO(item.date).toLocaleString({
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric"
      });

      return {
        ...item,
        date,
        longDate
      };
    })
    .reverse();
};
