import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./LastWeekPoints.module.scss";
import ProgressDetail from "./ProgressDetail";

import { TimeInMs } from "~/constants/measurements";
import round from "~/helpers/number/round";
import useUserLastWeekPoints from "~/hooks/useApi/useUserPoints";
import useDebounce from "~/hooks/useDebounce";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { ProgramURLParams } from "~/typing/carePortalTypes";
import { UserDetails } from "~/typing/sidekickTypes";

type LastWeekPointsProps = {
  maxPoints?: number;
  className?: string;
  missions?: any[];
  displayName?: string;
  userId?: string;
  delayOnHover?: boolean;
  userDetails?: UserDetails;
};

const LastWeekPoints = ({
  maxPoints = 200,
  className,
  missions,
  displayName,
  userId,
  delayOnHover,
  userDetails
}: LastWeekPointsProps) => {
  const [showDetail, setShowDetail] = useState(false);
  const { t } = useTranslation();

  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();

  const { lastWeekPoints } = useUserLastWeekPoints(
    program_id,
    locale,
    userId,
    userDetails
  );

  const { trackLastWeekPointsHovered } = useAmplitudeTracking();

  const debouncedHoverTracker = useDebounce<boolean>(
    showDetail,
    TimeInMs.Second * 5
  );

  const showPoints = useDebounce<boolean>(showDetail, 300);

  const goals = [
    {
      title: t("general.move"),
      points: lastWeekPoints?.movePoints,
      class: styles.move
    },
    {
      title: t("general.mind"),
      points: lastWeekPoints?.mindPoints,
      class: styles.mind
    },
    {
      title: t("general.food"),
      points: lastWeekPoints?.foodPoints,
      class: styles.food
    }
  ];

  const classes = `${styles.LastWeekPoints} ${className ?? ""}`;

  useEffect(() => {
    if (debouncedHoverTracker) {
      trackLastWeekPointsHovered();
    }
  }, [debouncedHoverTracker]);

  if (
    !lastWeekPoints?.foodPoints &&
    !lastWeekPoints?.movePoints &&
    !lastWeekPoints?.mindPoints
  ) {
    return <></>;
  }

  const shouldShowDetails = delayOnHover
    ? showPoints && showDetail
    : showDetail;

  return (
    <div
      className={classes}
      onMouseEnter={() => setShowDetail(true)}
      onMouseLeave={() => setShowDetail(false)}
    >
      {goals.map((goal) => {
        const width = round(((goal.points ?? 0) / maxPoints) * 100, 3);

        return (
          <div
            className={goal.class}
            style={{ width: `${width}%` }}
            key={goal.title}
          ></div>
        );
      })}
      {shouldShowDetails && (
        <ProgressDetail
          points={lastWeekPoints}
          missions={missions}
          displayName={displayName}
        />
      )}
    </div>
  );
};

export default LastWeekPoints;
