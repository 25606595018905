import { SurveyResult } from "~/typing/sidekickTypes";

export const getLatestSurveyResult = (surveyResults: SurveyResult[]) => {
  if (surveyResults.length === 0) return undefined;

  return surveyResults.reduce(
    (acc, curr) => (curr.date > acc.date ? curr : acc),
    surveyResults[0]
  );
};
