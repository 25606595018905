import { QueryKeyFactory } from "../queryKeysFactory";
import useApi from "../useApi";

import config from "~/config";
import useUrlQuery from "~/hooks/useUrlQuery";
import { SMSMessage } from "~/typing/sidekickTypes";

export const useSmsMessages = ({
  programCatalogItemId,
  locale,
  externalUser,
  refetchInterval
}: {
  programCatalogItemId: string;
  locale: string;
  externalUser?: {
    externalUserTypeId?: string;
    id?: string;
  };
  refetchInterval?: () => number | false;
}) => {
  const query = useUrlQuery();

  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/externalusertypes/${externalUser?.externalUserTypeId}/externalusers/${externalUser?.id}/textmessages`;

  const shouldFetchSms =
    externalUser?.externalUserTypeId &&
    externalUser?.id &&
    (!!query.get("useSms") || config.useSms);

  const { data, isLoading, isError, invalidate } = useApi<{
    textMessages: SMSMessage[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.sms(
      programCatalogItemId,
      locale,
      externalUser?.externalUserTypeId ?? "",
      externalUser?.id ?? ""
    ),
    disabled: !shouldFetchSms,
    refetchInterval,
    modifyData: (data) => {
      const messages = data?.textMessages || [];

      messages.sort((msgA, msgB) => {
        const dateA = new Date(msgA.createdDate);
        const dateB = new Date(msgB.createdDate);
        return dateA.valueOf() - dateB.valueOf();
      });

      return { textMessages: messages };
    }
  });

  return {
    smsMessages: data?.textMessages ?? [],
    isError,
    isLoading,
    invalidate
  };
};
