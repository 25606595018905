import { filterOutUndefined } from "~/helpers/array/arrayHelpers";
import { CoachWithSpecialty } from "~/typing/carePortalTypes";
import { Coach, Specialty, CareTeamMember } from "~/typing/sidekickTypes";

export const mapCareTeamToCoaches = (
  careTeam: CareTeamMember[],
  coaches: Coach[],
  specialties?: Specialty[]
): CoachWithSpecialty[] => {
  const mappedCoaches = careTeam.map((careMember) => {
    const coach = coaches.find((c) => c.userId === careMember.coachUserId);

    const specialty = specialties?.find(
      (specialty) => specialty.id === careMember.specialtyId
    );

    if (!coach) return undefined;

    return { ...coach, specialty };
  });

  const noUndefinedCoaches = mappedCoaches.filter(filterOutUndefined);

  const sortedCoaches = noUndefinedCoaches.sort((a, b) => {
    if (!a.specialty || !b.specialty) return 0;

    if (a.specialty.name < b.specialty.name) return -1;
    if (a.specialty.name > b.specialty.name) return 1;

    return 0;
  });

  return sortedCoaches;
};
