import { useTranslation } from "react-i18next";

import ActionBubble from "./ActionBubble";
import styles from "./MessageActionBubble.module.scss";

import MessagesBubbleIcon from "~/assets/messages-bubble.svg";
import ExclamationIcon from "~/assets/svgComponents/ExclamationIcon";
import SadFaceIcon from "~/assets/svgComponents/SadFaceIcon";
import { SentimentThreshold } from "~/constants/sentiment";
import { humanizedTimeSince } from "~/helpers/date/dateHelpers";
import colors from "~/styles/colors";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";

type MessageActionBubbleProps = {
  url?: string;
  isActive?: boolean;
  actionCount?: number;
  extraAttention?: boolean;
  className?: string;
  overallSentiment?: number;
  possibleTechnicalProblem?: boolean;
  lastMessageDate: string;
  bottomText?: string;
  userId: string;
};

const MessageActionBubble = ({
  isActive,
  actionCount,
  extraAttention,
  className,
  url,
  overallSentiment,
  possibleTechnicalProblem,
  lastMessageDate,
  bottomText,
  userId
}: MessageActionBubbleProps) => {
  const { trackActionAwaitingMessageSelected } = useAmplitudeTracking();

  const { t } = useTranslation();
  const getSentimentAnalysisValue = (sentiment: number) => {
    if (sentiment < SentimentThreshold.Negative) {
      return t("sentiment.negative");
    } else if (sentiment < SentimentThreshold.Neutral) {
      return t("sentiment.neutral");
    } else {
      return t("sentiment.positive");
    }
  };

  const getToolTipContent = () => {
    return (
      <div className={styles.tooltipWrapper}>
        {overallSentiment && (
          <div className={styles.sentiment}>
            <p>{t("messages.sentimentAnalysis")}</p>
            <p>{getSentimentAnalysisValue(overallSentiment)}</p>
          </div>
        )}
        {(lastMessageDate || possibleTechnicalProblem) && (
          <div className={styles.info}>
            {possibleTechnicalProblem && (
              <p>{t("messages.possiblyTechnical")}</p>
            )}
            {lastMessageDate && (
              <p>{humanizedTimeSince(lastMessageDate, false)}</p>
            )}
          </div>
        )}
      </div>
    );
  };

  const hasNegativeSentiment =
    (overallSentiment ?? 1) < SentimentThreshold.Negative;

  const iconColor = extraAttention ? colors.red60 : colors.darkBlue60;

  return (
    <div className={styles.wrapper}>
      <ActionBubble
        icon={MessagesBubbleIcon}
        url={url}
        tooltipContent={getToolTipContent()}
        isActive={isActive}
        actionCount={actionCount}
        extraAttention={extraAttention}
        className={className}
        bottomText={bottomText}
        onClick={() =>
          trackActionAwaitingMessageSelected({
            isSms: false,
            isCmRecipient: extraAttention,
            userId
          })
        }
      />

      <div
        className={
          possibleTechnicalProblem ? styles.exclamation : styles.sadFace
        }
      >
        {possibleTechnicalProblem && <ExclamationIcon color={iconColor} />}
        {!possibleTechnicalProblem && hasNegativeSentiment && (
          <SadFaceIcon color={iconColor} />
        )}
      </div>
    </div>
  );
};

export default MessageActionBubble;
