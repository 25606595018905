import { t } from "i18next";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import styles from "./WeekCalendar.module.scss";

import LogCard from "../LogCard";

import { MedicationLogSanitised } from "~/typing/carePortalTypes";

const DAYS_OF_WEEK = 7;

interface WeekCalendarProps {
  startDate: DateTime;
  weekOffset: number;
  logs: MedicationLogSanitised[];
}

type SelectedWeek = {
  days: WeekDay[];
};

type WeekDay = {
  date: DateTime;
  empty: boolean;
  data?: MedicationLogSanitised[];
};

const WeekCalendar = ({ startDate, weekOffset, logs }: WeekCalendarProps) => {
  const [todayIndex, setTodayIndex] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState<SelectedWeek>();

  const daysOfWeek = [
    t("medication.schedule.daysShort.mon"),
    t("medication.schedule.daysShort.tue"),
    t("medication.schedule.daysShort.wed"),
    t("medication.schedule.daysShort.thu"),
    t("medication.schedule.daysShort.fri"),
    t("medication.schedule.daysShort.sat"),
    t("medication.schedule.daysShort.sun")
  ];

  const filterData = () => {
    // Filters the logs for the current week

    //Find the start of the current week
    const startOfWeek = DateTime.now()
      .startOf("week")
      .plus({ days: weekOffset });

    const week: SelectedWeek = { days: [] };
    for (let i = 0; i < DAYS_OF_WEEK; i++) {
      week.days.push({
        date: startOfWeek.plus({ days: i }),
        empty: true
      });
    }

    const currentWeekLogs = logs.filter((log) => {
      return (
        DateTime.fromISO(log.scheduledDate ?? log.completedDate)
          .startOf("day")
          .toMillis() >= week.days[0].date.startOf("day").toMillis() &&
        DateTime.fromISO(log.scheduledDate ?? log.completedDate)
          .startOf("day")
          .toMillis() <=
          week.days[DAYS_OF_WEEK - 1].date.startOf("day").toMillis()
      );
    });
    // Adds the logs to the correct day
    week?.days.forEach((day) => {
      day.data = currentWeekLogs.filter((log) => {
        return (
          DateTime.fromISO(log.scheduledDate ?? log.completedDate)
            .startOf("day")
            .toMillis() === day.date.startOf("day").toMillis()
        );
      });
      //Sort the data in the day by time of day utc
      day.data?.sort((a, b) => {
        return (
          DateTime.fromISO(a.time).toMillis() -
          DateTime.fromISO(b.time).toMillis()
        );
      });
    });

    return week;
  };

  useEffect(() => {
    setSelectedWeek(filterData());
  }, [weekOffset, logs, startDate]);

  useEffect(() => {
    // Calculates the correct index of the current day
    const today = DateTime.now();
    const diff = today.diff(startDate, "days")?.days;
    setTodayIndex(Math.round(diff));
  }, [startDate, logs]);

  return (
    <div className={styles.weekCalendar}>
      <div className={styles.weekCalendarHeader}>
        {[...Array(DAYS_OF_WEEK)].map((item, index) => {
          return (
            <div
              className={
                todayIndex === index
                  ? styles.weekCalendarHeaderItemActive
                  : styles.weekCalendarHeaderItem
              }
              key={`day-header-${item}-${index}`}
            >
              <div className={styles.weekCalendarHeaderWeekdayShort}>
                {daysOfWeek[startDate.plus({ days: index }).weekday - 1]}
              </div>

              <div className={styles.weekCalendarHeaderDay}>
                {startDate.plus({ days: index }).day}
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.weekCalendarBody}>
        {selectedWeek
          ? selectedWeek.days.map((item, index) => {
              return (
                <div
                  className={styles.weekCalendarBodyItem}
                  key={`day-item-${item.date}-${index}`}
                >
                  {item.data?.map((data, index) => {
                    return (
                      <LogCard
                        log={data}
                        key={`day-injection-card-${item.date}-${index}-${item.data?.length}`}
                      />
                    );
                  })}
                </div>
              );
            })
          : undefined}
      </div>
    </div>
  );
};

export default WeekCalendar;
