import { DateTime } from "luxon";

import api from "~/api/api";
import config from "~/config";
import { TextMessageType } from "~/constants/textMessaging";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import {
  ChatMessage,
  ExternalUser,
  MessageThread,
  MessageType,
  SMSMessage
} from "~/typing/sidekickTypes";

export const readOtherCoachMessage = async (
  programId: string,
  locale: string,
  userId: string,
  messageId: string
): Promise<any> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/messagethreads/${messageId}/readNonRecipientMessage`;
    await api.post(path, {});
  } catch {
    displayErrorToast({
      message: t("errors.read", { resource: t("general.message") })
    });
  }
};

export const readMessage = async (
  programId: string,
  locale: string,
  userId: string,
  messageId: string
): Promise<any> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/messagethreads/${messageId}/read`;
    await api.post(path, {});
  } catch {
    displayErrorToast({
      message: t("errors.read", { resource: t("general.message") })
    });
  }
};

export const readAllMessages = async ({
  programId,
  locale,
  userId
}: {
  programId: string;
  locale: string;
  userId: string;
}): Promise<any> => {
  try {
    const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/messagethreads/readall`;
    await api.post(path, {});
  } catch {
    displayErrorToast({
      message: t("errors.read", { resource: t("general.message") })
    });
  }
};

export const addMessageForUser = async ({
  programId,
  locale,
  userId,
  message,
  replyingToMessage,
  suggestionId
}: {
  programId: string;
  locale: string;
  userId: string;
  message: string;
  replyingToMessage?: MessageType;
  suggestionId?: string;
}): Promise<ChatMessage> => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/messagethreads`;
  return await api
    .post(path, {
      text: message,
      originalSenderId: replyingToMessage?.recipientUserId,
      repliedToMessageId: replyingToMessage?.id,
      suggestionId
    })
    .then((res) => res.data);
};

export const getMessageThreads = async (
  programId: string,
  locale: string,
  userId: string
): Promise<MessageThread[] | undefined> => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/messagethreads`;
  return await api.get(path).then((res) => res.data.items || []);
};

export const submitAdHocSMS = async (
  message: string,
  externalUser?: ExternalUser
) => {
  if (!externalUser) {
    throw new Error("External user is undefiend");
  }

  const path = `/admin/messaging/sms`;
  return await api
    .post<{ message: string }>(path, {
      message: message,
      phoneNumber: externalUser.phoneNumber,
      appId: config.appId,
      externalUserId: externalUser.id
    })
    .then((res) => res.data.message);
};

export const submitSMSInvite = async (
  message: string,
  externalUser: ExternalUser
): Promise<ExternalUser | undefined> => {
  const { externalUserTypeId, id } = externalUser;
  const path = `/admin/externalusertypes/${externalUserTypeId}/externalusers/${id}/sendCustomInviteSms`;
  return await api
    .post<ExternalUser>(path, { message: message })
    .then((res) => res.data);
};

export const readSmsMessages = (messages: SMSMessage[]) => {
  const isNewSms = (sms: SMSMessage) => {
    return sms.type === TextMessageType.UserResponse && !sms.seenDate;
  };

  messages.forEach((msg) => {
    if (isNewSms(msg)) {
      const path = `/coach/messaging/textmessages/${msg.id}/seen`;
      api
        .put(path, {
          ...msg,
          seenDate: DateTime.utc().toISO()
        })
        .then((response) => {
          //Update the message in-place for the notification in the message window to update without fetching new data.
          if (response?.data && response?.data?.seenDate) {
            msg.seen = true;
            msg.seenDate = response?.data?.seenDate;
          }
        });
    }
  });
};
