import { DateTime } from "luxon";
import { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FoodJournalList from "./FoodJournalList";
import { FoodJournalPageContext } from "./foodJournalPageContext";
import foodJournalReducer, { JournalActions } from "./foodJournalReducer";

import { UserPageContext } from "../../userPage/UserPage.context";
import DateNavigator from "../DateNavigator";

import {
  getFoodJournals,
  updateFoodJournal
} from "~/api/requests/journalRequests";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import SkeletonFoodJournalList from "~/components/skeletons/SkeletonFoodJournalList";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";
import { FoodJournalUpdatePayload, ScoreMeal } from "~/typing/sidekickTypes";

type FoodJournalPageProps = {
  timeZoneOffset?: number;
};

const FoodJournalPage = ({ timeZoneOffset }: FoodJournalPageProps) => {
  const endDate = DateTime.local(); // default to this week
  const startDate = endDate.minus({ days: 7 }); // default to this week
  const [query, setQuery] = useState({
    startDate: startDate,
    endDate: endDate
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [journals, dispatch] = useReducer(foodJournalReducer, []);
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { mutateUserDetail, program } = useContext(UserPageContext);
  const { trackFoodJornalViewOpened } = useAmplitudeTracking();

  const resetState = () => {
    setFirstLoad(true);
    setLoading(false);
    dispatch({ type: JournalActions.Set, payload: [] });
    getFirstLoadJournals();
  };

  const getJournals = async (
    getMostRecent = false,
    page = 1
  ): Promise<ScoreMeal[] | undefined> => {
    const journalsData = await getFoodJournals({
      programId: program_id,
      locale,
      userId: user_id,
      startDate: query.startDate,
      endDate: query.endDate,
      getMostRecent,
      page
    });

    dispatch({
      type: JournalActions.Add,
      payload: journalsData?.scoremeals
    });

    const returnData = journalsData?.scoremeals;

    // Recursively get journals if we haven't fetched all the journals for the desired date range
    if ((journalsData?.page ?? 0) < (journalsData?.pageCount ?? 0)) {
      const tempData = (await getJournals(getMostRecent, page + 1)) ?? [];
      (returnData ?? []).concat(tempData);
    }
    return returnData;
  };

  useEffect(() => {
    if (!loading && !firstLoad) {
      getJournals();
    }
  }, [query.endDate]);

  useEffect(() => {
    if (!program) return;
    trackFoodJornalViewOpened();
  }, [program?.programCatalogItemId]);

  const getFirstLoadJournals = async () => {
    if (!loading && firstLoad) {
      setFirstLoad(false);
      setLoading(true);
      const journalScoreMeals = await getJournals(true);

      let date: DateTime | undefined;

      journalScoreMeals?.forEach((meal) => {
        const serverDate = DateTime.fromISO(meal.serverDate, {
          zone: "local"
        });

        if (!date || date < serverDate) {
          date = serverDate;
        }
      });

      if (date) {
        setQuery({ startDate: date.minus({ days: 7 }), endDate: date });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getFirstLoadJournals();
  }, [timeZoneOffset]);

  const updateJournal = async (
    payload: FoodJournalUpdatePayload,
    shouldUpdateDetails: boolean
  ) => {
    toast.remove();
    const updateResponse = await updateFoodJournal({
      userId: user_id,
      locale,
      programId: program_id,
      payload
    });

    if (updateResponse) {
      dispatch({ type: JournalActions.Update, payload: updateResponse });
      if (shouldUpdateDetails) {
        mutateUserDetail();
      }
    }

    return Boolean(updateResponse);
  };

  const renderFoodJournalList = (day) => {
    if (loading) {
      return <SkeletonFoodJournalList />;
    }

    return (
      // DateNavigator calls this function for each day that it is displayed
      // FoodJournalList only renders the relevant journals for a particular day
      <FoodJournalList
        data={journals}
        day={day.date.toISODate()}
        timeZoneOffset={timeZoneOffset !== undefined ? timeZoneOffset : 0}
      />
    );
  };

  const onChangeDate = ({ startDate, endDate }) => {
    setQuery({ startDate: startDate, endDate: endDate });
  };

  return (
    <FoodJournalPageContext.Provider value={{ updateJournal }}>
      <Helmet
        title={t("userSidenav.foodJournal", "Food Journal")}
        defer={false}
      />
      <SentryErrorBoundary
        resetState={resetState}
        transactionName="FoodJournalPage"
      >
        <DateNavigator
          onDateChange={onChangeDate}
          startDateISO={query.startDate.toISODate()}
          endDateISO={query.endDate.toISODate()}
          renderDayItems={renderFoodJournalList}
        />
      </SentryErrorBoundary>
    </FoodJournalPageContext.Provider>
  );
};

export default FoodJournalPage;
