import colors from "~/styles/colors";

type DoubleCheckMarkProps = {
  color?: string;
};

const DoubleCheckMark = ({ color = colors.black }: DoubleCheckMarkProps) => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.13602 8.61989C5.0665 8.69098 4.98358 8.74757 4.89204 8.78638C4.80051 8.8252 4.70218 8.84548 4.60276 8.84604C4.50334 8.8466 4.40479 8.82743 4.31282 8.78964C4.22086 8.75186 4.1373 8.6962 4.06699 8.62591L1.42478 5.58889"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.416 0.846062L8.35015 5.25721"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7731 0.846069L9.40758 8.5107C9.33679 8.61242 9.24284 8.69587 9.13347 8.75416C9.0241 8.81245 8.90244 8.84391 8.77853 8.84596C8.65461 8.84801 8.53198 8.82058 8.42074 8.76594C8.30951 8.7113 8.21285 8.631 8.13873 8.53167C8.13873 8.53167 5.9578 6.22093 5.4248 5.51036"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleCheckMark;
