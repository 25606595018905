import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useEffect } from "react";

import { QueryKeyFactory } from "./queryKeysFactory";
import { useFeatureToggle } from "./useFeatureToggle";

import {
  getMessageSuggestions,
  postReportMessage
} from "~/api/requests/chatAssistantRequests";
import config from "~/config";
import { TimeInMs } from "~/constants/measurements";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import {
  MessageSuggestion,
  MessageSuggestionType
} from "~/typing/sidekickTypes";

type SuggestedMessagesProps = {
  userId: string;
  programId: string;
  locale: string;
  numMessages?: number;
  messageType?: MessageSuggestionType;
};

export const useCoachSuggestedMessages = ({
  userId,
  programId,
  locale,
  numMessages = 3,
  messageType = MessageSuggestionType.WeeklyFeedback
}: SuggestedMessagesProps) => {
  const { data, isLoading, isError } = useQuery<MessageSuggestion[]>({
    queryKey: QueryKeyFactory.users.suggestedMessages(
      programId,
      locale,
      userId,
      messageType
    ),
    queryFn: () =>
      getMessageSuggestions({
        programCatalogItemId: programId,
        locale,
        userId,
        numMessages,
        messageType
      }),
    retry: false,
    staleTime: TimeInMs.Minute * 30
  });

  return {
    messages: data ?? [],
    isLoading,
    isError
  };
};

interface SuggestedMessagesPrefetchProps extends SuggestedMessagesProps {
  authUserId: string;
  condition?: boolean;
}

export const usePrefetchCoachSuggestedMessages = ({
  userId,
  programId,
  locale,
  authUserId,
  messageType = MessageSuggestionType.WeeklyFeedback,
  condition = true
}: SuggestedMessagesPrefetchProps) => {
  const queryClient = useQueryClient();
  const { isEnabled } = useFeatureToggle({
    featureName: SegmentationKey.RecommendedMessages,
    userId: authUserId
  });

  useEffect(() => {
    const shouldPrefetchMessageSuggestions =
      condition &&
      isEnabled &&
      Boolean(userId) &&
      Boolean(programId) &&
      Boolean(locale) &&
      config.showMessageSuggestions;

    if (!shouldPrefetchMessageSuggestions) return;

    queryClient.prefetchQuery({
      queryKey: QueryKeyFactory.users.suggestedMessages(
        programId,
        locale,
        userId,
        messageType
      ),
      queryFn: () =>
        getMessageSuggestions({
          programCatalogItemId: programId,
          locale,
          userId,
          messageType
        }),
      retry: false
    });
  }, [condition, isEnabled, userId, programId, locale]);
};

export type ReportMessageProps = {
  suggestionId: string;
  message: string;
  user_id: number;
  issues: string[];
  note: string;
};

export const useReportMessage = () => {
  const { mutate: reportMessageMutation, isPending, isError } = useMutation({
    mutationKey: ["reportMessage"],
    mutationFn: (reportMessageArgs: ReportMessageProps) =>
      postReportMessage(reportMessageArgs),
    onSuccess: () => {
      displaySuccessToast({
        message: t("nextStep.reportMessage.messageReported")
      });
    }
  });
  return {
    reportMessageMutation,
    isPending,
    isError
  };
};
