import styles from "./ExternalUserChangeLog.module.scss";

import {
  getCallConsentStatusText,
  getCallRecordingDisclaimerStatusText,
  getEnrolmentStatusText,
  getHipaaCertifiedStatusText
} from "../helper";

import config from "~/config";
import { ExternalUserEventSource } from "~/constants/externalUserEventSources";
import { ExternalUserEventType } from "~/constants/externalUserEventTypes";
import { getFormattedDateWithTime } from "~/helpers/date/dateHelpers";
import { t } from "~/i18n";
import {
  ExternalUser,
  ExternalUserHistory,
  ExternalUserHistoryEntry
} from "~/typing/sidekickTypes";

type ExternalUserHistoryProps = {
  externalUser?: ExternalUser;
  externalUserHistory?: ExternalUserHistory;
};

const ExternalUserChangeLog = ({
  externalUser,
  externalUserHistory
}: ExternalUserHistoryProps) => {
  if (!externalUser?.submittedByDate) return null;

  const externalUserHistoriesFiltered = externalUserHistory?.externalUserHistories?.filter(
    // Filter out records that were backfilled as part of PLA-935
    (euh) => euh.eventSource !== ExternalUserEventSource.Backfill
  );

  const getModifiedByText = (entry: ExternalUserHistoryEntry) => {
    if (entry?.eventSource === ExternalUserEventSource.Import) {
      return t("general.import");
    } else if (entry?.eventType === ExternalUserEventType.Enrollment) {
      return t("general.member");
    } else {
      return entry?.modifiedByUser?.displayName;
    }
  };

  return (
    <div className={styles.changeLog}>
      <div>
        {t("registerPatient.submittedBy")}{" "}
        {externalUser?.submittedByUser?.displayName} on{" "}
        {getFormattedDateWithTime(externalUser?.submittedByDate)}
      </div>
      {externalUser?.smsDate && (
        <div>
          {t("registerPatient.lastSmsStatusOn")}{" "}
          {getFormattedDateWithTime(externalUser.smsDate)} :{" "}
          {externalUser?.smsStatusDetails}
        </div>
      )}

      {externalUserHistoriesFiltered && (
        <table>
          <thead>
            <tr>
              <th>{t("registerPatient.modifiedOn")}</th>
              <th>{t("registerPatient.modifiedBy")}</th>
              <th>{t("registerPatient.enrolment")}</th>
              {config.isAnthem && (
                <>
                  <th>{t("registerPatient.call")}</th>
                  <th>CRD</th>
                  <th>HIPAA</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {externalUserHistoriesFiltered.map((entry, key) => {
              return (
                <tr key={key}>
                  <td>{getFormattedDateWithTime(entry.modifiedDate)}</td>
                  <td>{getModifiedByText(entry)}</td>
                  <td>{t(getEnrolmentStatusText(entry.enrolmentStatus))}</td>
                  {config.isAnthem && (
                    <>
                      <td>
                        {t(
                          getCallConsentStatusText(entry.enrolmentConsentToCall)
                        )}
                      </td>
                      <td>
                        {t(
                          getCallRecordingDisclaimerStatusText(
                            entry.enrolmentCallRecordingDisclaimer
                          )
                        )}
                      </td>
                      <td>
                        {t(
                          getHipaaCertifiedStatusText(
                            entry.enrolmentHipaaVerified
                          )
                        )}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExternalUserChangeLog;
