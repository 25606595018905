import { ReactNode, useEffect, useState } from "react";

import { shouldResetPagination } from "./CustomList.helpers";
import {
  useEntitiesToDisplay,
  useFilteredEntities,
  useSortedAndQueriedEntities
} from "./CustomList.hooks";
import styles from "./CustomList.module.scss";
import CustomListColumns from "./CustomListColumns";
import CustomListEmptyState from "./CustomListEmptyState";
import CustomListFilters from "./CustomListFilters";
import CustomListRows from "./CustomListRows";
import CustomListSearchAndAction from "./CustomListSearchAndAction";

import CheckBoxOptions from "../options/CheckBoxOptions";
import Pagination from "../pagination/Pagination";

import useDebounce from "~/hooks/useDebounce";
import useListColumns from "~/hooks/useListColumns";
import useListPagination from "~/hooks/useListPagination";
import {
  setCurrentPaginationPage,
  setListColumns,
  toggleListColumn
} from "~/state/customList/customListStateHelpers";
import {
  ActionData,
  FilterCheckboxData,
  CustomListFilterData,
  SearchData,
  SortableData,
  TableHeading,
  PaginationData
} from "~/typing/carePortalTypes";

export interface CustomListColumn<T> {
  heading: TableHeading;
  render: (row: T) => ReactNode;
  conditional?: boolean;
  className?: string;
}

export interface CustomListProps<T> {
  searchData?: SearchData;
  filterSelectData?: CustomListFilterData[];
  filterCheckboxData?: FilterCheckboxData<T>[];
  isLoading: boolean;
  actionData?: ActionData[];
  noEntitiesText?: string;
  defaultOrder?: "asc" | "desc";
  defaultSortingData?: SortableData;
  putEntityAtBottom?: (value: T) => boolean;
  renderBetweenFiltersAndList?: (renderedUsers: T[]) => ReactNode;
  tableClassName?: string;
  renderEmptyState?: () => ReactNode;
  initialSearchString?: string;
  columns: CustomListColumn<T>[];
  entities: T[];
  processFilteredEntities?: (entities: T[]) => void; // Process all the filtered entities, even if they are not rendered
  processRenderedEntities?: (entities: T[]) => T[]; // Process only the entities that are rendered
  onRowClick?: (row: T) => void;
  customListUniqueId: string;
  hideFilters?: boolean;
  onSearchChange?: (value: string) => void;
  pagination?: PaginationData;
  dataTestId?: string;
}

const CustomList = <T extends object>({
  columns,
  searchData,
  filterSelectData,
  filterCheckboxData,
  actionData,
  entities,
  isLoading,
  noEntitiesText,
  defaultOrder = "asc",
  defaultSortingData,
  putEntityAtBottom,
  renderBetweenFiltersAndList,
  tableClassName,
  renderEmptyState,
  initialSearchString,
  onRowClick,
  customListUniqueId,
  hideFilters,
  onSearchChange,
  pagination,
  processFilteredEntities,
  processRenderedEntities = (entities) => entities,
  dataTestId
}: CustomListProps<T>): React.ReactElement => {
  const [query, setQuery] = useState<string | undefined>(initialSearchString);
  const debouncedQuery = useDebounce<string>(query ?? "", 1000);
  const [order, setOrder] = useState(defaultOrder);
  const [sortingData, setSortingData] = useState<SortableData | undefined>(
    defaultSortingData
  );
  const columnsToDisplay = useListColumns(customListUniqueId);
  const { currentPage } = useListPagination(customListUniqueId);

  const handleSetOrderBy = (value: SortableData) => {
    setOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    if (sortingData?.sortBy !== value.sortBy) setSortingData(value);
  };

  useEffect(() => onSearchChange?.(debouncedQuery), [debouncedQuery]);
  useEffect(
    () =>
      initialSearchString !== query ? setQuery(initialSearchString) : undefined,
    [initialSearchString]
  );
  useEffect(() => {
    if (columnsToDisplay?.length) return;

    setListColumns(
      customListUniqueId,
      columns.filter((c) => !c.heading.hideByDefault).map((c) => c.heading)
    );
  }, [columns]);

  const filteredColumns = columns
    .filter((c) => (c.conditional !== undefined ? c.conditional : true))
    .filter((c) => columnsToDisplay?.some((h) => h.text === c.heading.text));

  const filteredEntities = useFilteredEntities({
    entities,
    filterCheckboxData,
    customListUniqueId,
    filterSelectData
  });

  const sortedAndQueriedEntities = useSortedAndQueriedEntities({
    entities: filteredEntities,
    sortingData,
    onSearchChange,
    order,
    query,
    putEntityAtBottom,
    searchData
  });

  const entitiesToDisplay = useEntitiesToDisplay({
    entities: sortedAndQueriedEntities,
    processRenderedEntities,
    processFilteredEntities,
    pagination,
    currentPage
  });

  useEffect(() => {
    if (
      shouldResetPagination({
        entities: sortedAndQueriedEntities,
        currentPage,
        pagination
      })
    ) {
      setCurrentPaginationPage(customListUniqueId, 1);
    }
  }, [pagination, sortedAndQueriedEntities]);

  return (
    <>
      <div className={styles.header}>
        <CustomListSearchAndAction
          actionData={actionData}
          searchData={searchData}
          query={query}
          setQuery={setQuery}
          entities={entitiesToDisplay}
          loading={isLoading}
        />
        <CustomListFilters
          filterCheckboxData={filterCheckboxData ?? []}
          filterSelectData={filterSelectData ?? []}
          disabled={!entities?.length}
          hide={hideFilters}
          loading={isLoading}
          listId={customListUniqueId}
        />
      </div>
      {!isLoading && renderBetweenFiltersAndList?.(sortedAndQueriedEntities)}
      {!!columns?.length && (
        <CheckBoxOptions
          clearAll={() => setListColumns(customListUniqueId, [])}
          selectAll={() =>
            setListColumns(
              customListUniqueId,
              columns.map((c) => c.heading)
            )
          }
          options={
            columns
              ?.filter((c) =>
                c.conditional !== undefined ? c.conditional : true
              )
              ?.map((column) => ({
                ...column.heading,
                title: column.heading.text,
                func: () =>
                  toggleListColumn(customListUniqueId, column.heading),
                checked: columnsToDisplay?.some(
                  (columnHeading) => columnHeading.text === column.heading.text
                )
              })) ?? []
          }
          className={styles.filterHeader}
        />
      )}
      <div className={styles.container}>
        {!isLoading &&
          (!sortedAndQueriedEntities?.length || !filteredColumns?.length) ? (
          <CustomListEmptyState
            noEntitiesText={noEntitiesText}
            renderEmptyState={renderEmptyState}
            noColumnsSelected={!filteredColumns?.length}
            noEntities={!entities?.length}
            loading={isLoading}
          />
        ) : (
          <table
            data-testid={dataTestId ?? "custom-list-table"}
            className={`${styles.usersTable} ${tableClassName ?? ""}`}
          >
            <thead>
              <CustomListColumns
                columns={filteredColumns}
                order={order}
                handleSetOrderBy={handleSetOrderBy}
                sortingData={sortingData}
              />
            </thead>
            <tbody>
              <CustomListRows
                entities={entitiesToDisplay}
                columns={filteredColumns}
                loading={isLoading}
                onRowClick={onRowClick}
              />
            </tbody>
          </table>
        )}
      </div>
      {pagination && filteredColumns?.length > 0 && (
        <Pagination
          chosenPage={currentPage}
          className={styles.pagination}
          onPageClick={(page) =>
            setCurrentPaginationPage(customListUniqueId, page)
          }
          pagesAmount={Math.ceil(
            sortedAndQueriedEntities.length / pagination.entitiesPerPage
          )}
          hidePagination={isLoading || !columns?.length}
        />
      )}
    </>
  );
};

export default CustomList;
