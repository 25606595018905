import styles from "./SuccessScreen.module.scss";

import SuccessGlitterIcon from "~/assets/success-glitter.svg";

type SuccessScreenProps = {
  text: string;
};

const SuccessScreen = ({ text }: SuccessScreenProps) => {
  return (
    <div className={styles.wrapper}>
      <img src={SuccessGlitterIcon} alt="Success" />
      <h1>{text}</h1>
    </div>
  );
};

export default SuccessScreen;
