import { getBasicFormattedDate } from "~/helpers/date/dateHelpers";
import { SettingNames } from "~/typing/carePortalTypes";
import { UserSettings } from "~/typing/sidekickTypes";

type LayerInfo = {
  name: string;
  joinedDate: string;
};

const diabetesNames = {
  GDN: "Gestational Diabetes",
  T2D: "Type 2 Diabetes",
  T1D: "Type 1 Diabetes"
};

export const getAllowedLayersToDisplay = (
  settings: UserSettings | undefined
): LayerInfo[] => {
  const layers: LayerInfo[] = [];

  const hasDiabetes = settings?.find(
    (setting) => setting.name === SettingNames.HasDiabetes
  );
  const hasHypertension = settings?.find(
    (setting) => setting.name === SettingNames.HasHypertension
  );

  if (hasDiabetes?.value === "True") {
    const diabetesType =
      settings?.find((setting) => setting.name === SettingNames.DiebetesType)
        ?.value ?? "";

    if (diabetesType !== "") {
      layers.push({
        name: diabetesNames[diabetesType],
        joinedDate: getBasicFormattedDate(hasDiabetes.modifiedDate)
      });
    }
  }
  if (hasHypertension?.value === "True") {
    layers.push({
      name: "Hypertension",
      joinedDate: getBasicFormattedDate(hasHypertension.modifiedDate)
    });
  }

  return layers;
};
