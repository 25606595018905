import {
  CustomListCheckboxFilterData,
  CustomListFilterData
} from "~/typing/carePortalTypes";

export function createFilterData<T, K>(
  data: Omit<CustomListFilterData<T, K>, "filter"> & {
    filter: ({ entity, filterValue }: { entity: T; filterValue: K }) => boolean;
  }
): CustomListFilterData<T, K> {
  return {
    ...data,
    usesCheckBoxes: false
  };
}

export function createCheckboxFilterData<T, K>(
  data: Omit<CustomListCheckboxFilterData<T, K>, "filter"> & {
    filter: ({
      entity,
      filterValue
    }: {
      entity: T;
      filterValue: K[];
    }) => boolean;
  }
): CustomListCheckboxFilterData<T, K> {
  return {
    ...data,
    usesCheckBoxes: true
  };
}
