export enum ExportCategories {
  Information = "Information",
  CommunicationPreferences = "Communication Preferences",
  FoodJournal = "Food Journal",
  MessagesSMS = "Messages & SMS",
  Notes = "Notes",
  Performance = "Performance",
  PRO = "PRO"
}

export const getInformationIncludedArray = (
  selectedCategories: ExportCategories[]
) => {
  const informationIncluded: string[] = [];

  if (selectedCategories.includes(ExportCategories.CommunicationPreferences)) {
    informationIncluded.push("Communication Preferences");
  }
  if (selectedCategories.includes(ExportCategories.Information)) {
    informationIncluded.push("User Information");
  }
  if (selectedCategories.includes(ExportCategories.MessagesSMS)) {
    informationIncluded.push("Messages");
  }
  if (selectedCategories.includes(ExportCategories.FoodJournal)) {
    informationIncluded.push("Food Journal");
  }
  if (selectedCategories.includes(ExportCategories.Notes)) {
    informationIncluded.push("User Notes");
  }
  if (selectedCategories.includes(ExportCategories.Performance)) {
    informationIncluded.push("User Performance");
  }
  if (selectedCategories.includes(ExportCategories.PRO)) {
    informationIncluded.push("PRO");
  }
  return informationIncluded;
};
