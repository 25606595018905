import { useRef, useEffect } from "react";

export const useChildrenTabIndexToggle = ({
  childrenHidden
}: {
  childrenHidden?: boolean;
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parent = parentRef.current;
    if (!parent) return;

    const updateTabIndexes = () => {
      const focusableElements = parent.querySelectorAll<HTMLDivElement>(
        'a, button, input, [tabindex], [role="button"]'
      );
      focusableElements.forEach((el) => {
        el.tabIndex = childrenHidden ? -1 : 0;
      });
    };

    // Call initially to update on first load
    updateTabIndexes();

    // MutationObserver to watch for DOM changes
    const observer = new MutationObserver(() => {
      updateTabIndexes();
    });

    observer.observe(parent, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [childrenHidden, parentRef.current]);

  return { parentRef };
};
