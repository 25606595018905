import "./Skeletons.scss";

type SkeletonElementProps = {
  type: "text" | "title" | "avatar" | "image" | "note" | "filter" | "rectangle";
};

export default function SkeletonElement({ type }: SkeletonElementProps) {
  const classes = `skeleton ${type}`;

  return <div className={classes}></div>;
}
