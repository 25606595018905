import { t } from "~/i18n";

export default function getRoleTextFromPrivileges(
  privileges: string | undefined
) {
  if (!privileges) {
    return "";
  }

  const num = parseInt(privileges, 10);

  if (num >= 20) {
    return t("roles.admin");
  }

  if (num >= 15) {
    return t("roles.lead");
  }

  return t("roles.coach");
}
