export enum TimeInMs {
  Hour = 3_600_000,
  Day = 86_400_000,
  Week = 604_800_000,
  Minute = 60_000,
  Second = 1_000
}

export enum ByteSize {
  MegaByte = 1_000_000,
  KiloByte = 1_000,
  Byte = 1
}
