const initialState = {
  timeZoneOffset: undefined
};

export type TimeZoneOffsetState = {
  timeZoneOffset?: number;
};

export enum TimeZoneOffsetActions {
  Set = "Set"
}

type SetAction = {
  type: TimeZoneOffsetActions.Set;
  payload: any;
};

type Action = SetAction;

export default function reducer(
  state: TimeZoneOffsetState = initialState,
  action: Action
): TimeZoneOffsetState {
  switch (action.type) {
    case TimeZoneOffsetActions.Set: {
      if (action?.payload?.timezoneOffset !== undefined) {
        if (
          action.payload.timezoneOffset &&
          (parseInt(action.payload.timezoneOffset) > 0 ||
            parseInt(action.payload.timezoneOffset) < 0)
        ) {
          // the backend returns an offset in seconds so lets convert that to hours
          const offset = parseInt(action.payload.timezoneOffset) / 3600;
          state.timeZoneOffset = offset;
        } else {
          state.timeZoneOffset = 0;
        }
      }
      return state;
    }
    default:
      return state;
  }
}
