import config from "~/config";

const getPrivacyUrl = (locale = "en") => {
  return `${config.legalBaseUrl}/${config.privacyVersion}/${locale}/care/privacy.html`;
};

const getTermsUrl = (locale = "en") => {
  return `${config.legalBaseUrl}/${config.termsVersion}/${locale}/care/terms.html`;
};

export { getPrivacyUrl, getTermsUrl };
