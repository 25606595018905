import config from "~/config";

export const objectIsEmpty = (object: any) => {
  return Object.keys(object).length === 0;
};

export const copyToClipboard = (value?: string) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(value ?? "");
  }
};

export const logErrorToConsoleOnDev = (error: Error) => {
  if (!config.isProduction) {
    console.error(error);
  }
};
