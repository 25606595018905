import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";
import { ExternalUser } from "~/typing/sidekickTypes";

type Props = {
  externalUserTypeId?: string;
  externalUserId?: string;
};

export const useExternalUser = ({
  externalUserTypeId,
  externalUserId
}: Props) => {
  const path = `/admin/externalusertypes/${externalUserTypeId}/externalusers/${externalUserId}`;

  const {
    data,
    isLoading,
    isError,
    invalidate,
    setManuallyInCache
  } = useApi<ExternalUser>({
    path,
    queryKey: QueryKeyFactory.externalUsers.user(
      externalUserTypeId ?? "",
      externalUserId ?? ""
    ),
    cacheTime: TimeInMs.Minute * 10,
    disabled: !externalUserId || !externalUserTypeId
  });
  return {
    externalUser: data,
    isLoading,
    isError,
    invalidate,
    setManuallyInCache
  };
};
