export const USTimezoneOptions = [
  "EST",
  "CST",
  "PST",
  "AST",
  "MST",
  "AKST",
  "HAST",
  "WST",
  "CHST"
];
