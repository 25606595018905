import toast, {
  ToastOptions,
  Renderable,
  ToastPosition
} from "react-hot-toast";

import styles from "./Toast.module.scss";

import Button from "~/components/button/Button";
import colors from "~/styles/colors";

const defaultOptions: ToastOptions = {
  position: "bottom-right"
};

type ToastProps = {
  message: string;
  shouldDisappear?: boolean;
  icon?: Renderable;
  id?: string;
  position?: ToastPosition;
};

export const displaySuccessToast = ({
  message,
  shouldDisappear = true,
  icon,
  position = "bottom-right"
}: ToastProps) =>
  toast.success(message, {
    ...defaultOptions,
    duration: shouldDisappear ? 5000 : Infinity,
    icon,
    position
  });

export const displayWarningToast = ({
  message,
  shouldDisappear = true,
  position = "bottom-right"
}: ToastProps) =>
  toast(message, {
    duration: shouldDisappear ? 8000 : Infinity,
    icon: "⚠️",
    style: {
      background: colors.mustardYellow20,
      color: colors.black
    },
    position
  });

export const displayErrorToast = ({
  message,
  shouldDisappear,
  icon,
  id,
  position = "bottom-right"
}: ToastProps) =>
  toast.error(message, {
    ...defaultOptions,
    duration: shouldDisappear ? 5000 : Infinity,
    icon,
    id,
    position
  });

export const displayActionToast = ({
  onClick,
  message,
  buttonText,
  id,
  position = "bottom-right"
}: {
  onClick: () => void;
  message: string;
  buttonText: string;
  id: string;
  position?: "bottom-right" | "top-right";
}) =>
  toast(
    <div className={styles.actionToast}>
      <p>{message}</p>
      <Button size="sm" onClick={onClick}>
        {buttonText}
      </Button>
    </div>,
    {
      ...defaultOptions,
      duration: Infinity,
      id,
      position
    }
  );
