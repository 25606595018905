import { gql } from "graphql-request";
import { t } from "i18next";

import { gqlClient } from "../api";

import { displayErrorToast } from "~/helpers/toast/displayToast";
import { TranslationsDto, TranslationsDtoInput } from "~/typing/graphql/types";

export const getTranslations = async (
  targetLanguage: string,
  translations: TranslationsDtoInput,
  sourceLanguage: string
): Promise<TranslationsDto> => {
  const query = gql`
    query GetTranslations(
      $targetLanguage: String!
      $translations: TranslationsDtoInput!
      $sourceLanguage: String!
    ) {
      getTranslations(
        targetLanguage: $targetLanguage
        translationsDto: $translations
        sourceLanguage: $sourceLanguage
      ) {
        translations {
          text
          id
        }
      }
    }
  `;

  return await gqlClient
    .request(query, {
      targetLanguage,
      translations,
      sourceLanguage
    })
    .then((data: any) => data?.getTranslations || {})
    .catch(() => {
      displayErrorToast({ message: t("translation.error") });
    });
};
