import SkeletonElement from "./SkeletonElement";
import styles from "./SkeletonProgramHeader.module.scss";
import SkeletonShimmer from "./SkeletonShimmer";

const SkeletonProgramHeader = () => {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={`skeleton-wrapper ${styles.image}`}></div>
      <div className={`skeleton-wrapper ${styles.content}`}>
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonShimmer />
      </div>
      <div className={styles.ringWrapper}>
        <div className={`skeleton-wrapper ${styles.ring}`} />
        <div className={styles.ringInfo}>
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonProgramHeader;
