import styles from "./ChartRating.module.scss";

import Cell from "../cell/Cell";
import SkeletonChartRating from "../skeletons/SkeletonChartRating";

import colors from "~/styles/colors";

type ChartRatingProps = {
  className?: string;
  title: string;
  cellTitle?: string;
  cellColor?: string;
  value: string | number;
  unit: string;
  isLoading: boolean;
};

const ChartRating = ({
  className,
  title,
  cellTitle,
  cellColor = colors.lightBlue40,
  value,
  unit,
  isLoading
}: ChartRatingProps) => {
  return (
    <div className={`${styles.rating} ${className ?? ""}`}>
      {isLoading ? (
        <SkeletonChartRating />
      ) : (
        <>
          <span className={styles.title}>
            <p>{title}</p>
            {cellTitle && <Cell text={cellTitle} color={cellColor} />}
          </span>
          <p className={styles.value}>
            {value ? `${value}` : "--"}
            <span className={styles.unit}>{unit}</span>
          </p>
        </>
      )}
    </div>
  );
};

export default ChartRating;
