import { DateTime } from "luxon";

import styles from "./MonthRangeNavigator.module.scss";

import ChevronRight from "~/assets/chevron-right.svg";
import { t } from "~/i18n";

type MonthRangeNavigatorProps = {
  startDate: string;
  increaseMonth: () => void;
  decreaseMonth: () => void;
  onThisMonthClick?: () => void;
  nextButtonDisabled;
};

const MonthRangeNavigator = ({
  startDate,
  increaseMonth,
  decreaseMonth,
  onThisMonthClick,
  nextButtonDisabled
}: MonthRangeNavigatorProps) => {
  const getDateRangeString = () => {
    const startDateYear = DateTime.fromISO(startDate).get("year");

    const currentMonthString = DateTime.fromISO(startDate).toLocaleString({
      month: "long"
    });

    return `${currentMonthString} ${startDateYear}`;
  };

  return (
    <div className={styles.wrapper}>
      <button
        data-testid="month-range-navigator-back-button"
        onClick={decreaseMonth}
        className={`${styles.button} ${styles.backButton}`}
      >
        <img src={ChevronRight} />{" "}
      </button>
      <div className={styles.dates}>
        <p className={styles.date}>{getDateRangeString()}</p>
      </div>
      {nextButtonDisabled === undefined || !nextButtonDisabled ? (
        <button
          data-testid="month-range-navigator-next-button"
          onClick={increaseMonth}
          className={styles.button}
        >
          <img src={ChevronRight} />{" "}
        </button>
      ) : null}
      {onThisMonthClick !== undefined && (
        <>
          <span className={styles.separator} />
          <button onClick={onThisMonthClick} className={styles.today}>
            {t("time.thisMonth")}
          </button>
        </>
      )}
    </div>
  );
};

export default MonthRangeNavigator;
