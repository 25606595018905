import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import styles from "./RegistrationList.module.scss";
import { RegistrationListContext } from "./registrationListContext";

import CustomList from "~/components/customList/CustomList";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import useExternalusers from "~/hooks/useExternalUsers";
import { t } from "~/i18n";
import { fetchExternalUsers } from "~/state/externalUsers/externalUsersSlice";
import { AppDispatch } from "~/state/store";
import { ProgramURLParams } from "~/typing/carePortalTypes";

const RegistrationList = () => {
  const {
    externalUsers,
    filterCheckboxData,
    filterData,

    columns,
    searchData,
    externalUserTypeId,
    customListUniqueId
  } = useContext(RegistrationListContext);

  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();

  const { loading: usersLoading, searchString } = useExternalusers(program_id);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getUsers = async () => {
      if (!externalUserTypeId) return;

      dispatch(
        fetchExternalUsers({
          programId: program_id,
          locale,
          userTypeId: externalUserTypeId
        })
      );
    };
    getUsers();
  }, [externalUserTypeId]);

  return (
    <SentryErrorBoundary transactionName="ProgramRegistrationsPage">
      <div className={styles.registrationTable}>
        <CustomList
          entities={externalUsers}
          isLoading={usersLoading}
          searchData={searchData}
          filterSelectData={filterData}
          filterCheckboxData={filterCheckboxData}
          columns={columns}
          noEntitiesText={t("programUserList.noUsers")}
          defaultSortingData={{ sortBy: ["submittedByDate"], reverse: true }}
          initialSearchString={searchString}
          customListUniqueId={customListUniqueId}
          pagination={{ entitiesPerPage: 20 }}
          dataTestId="registration-list"
        />
      </div>
    </SentryErrorBoundary>
  );
};

export default RegistrationList;
