import { useEffect, useRef, useState } from "react";
import ReactCrop, { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { cropImage } from "./ProfilePic.helpers";
import styles from "./ProfilePic.module.scss";

import { attachmentApi } from "~/api/api";
import Avatar from "~/components/avatar/Avatar";
import Button from "~/components/button/Button";
import Modal from "~/components/modal/Modal";
import { AppDispatch } from "~/state/store";
import { uploadProfilePic } from "~/state/user/userSlice";
import { EnrichedAuthenticatedUser } from "~/typing/sidekickTypes";

type ProfilePicProps = {
  user: EnrichedAuthenticatedUser;
};

const ProfilePic = ({ user }: ProfilePicProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const inputRef = useRef<HTMLInputElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | undefined>();
  const [selectedPicture, setSelectedPicture] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const { t } = useTranslation();

  const handleUpload = async () => {
    setIsLoading(true);
    const croppedImage = await cropImage(
      selectedPicture || profilePicture,
      croppedAreaPixels
    );
    if (!croppedImage) {
      setIsLoading(false);
      return;
    }

    // Convert Data URL to croppedImage
    const binary = atob(croppedImage.split(",")[1]);
    const array: number[] = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const imageArrayBuffer = new Uint8Array(array);

    // Prepare the body and dispatch the action
    const body = {
      imageType: "image/jpeg",
      image: [].slice.call(imageArrayBuffer)
    };

    dispatch(uploadProfilePic({ userId: user.id ?? "", body }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setShowModal(false);
        reset();
      });
  };

  const handleSelectPicture = (e) => {
    setSelectedPicture(URL.createObjectURL(e.target.files[0]));
  };

  const reset = () => {
    setSelectedPicture(undefined);
    setCroppedAreaPixels(undefined);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  useEffect(() => {
    const getImageAsBlob = async () => {
      const attachmentImage = await attachmentApi.get(user?.imageHref ?? "");

      if (!attachmentImage?.data) {
        return;
      }

      setProfilePicture(attachmentImage.data);
    };

    if (user?.imageHref) {
      getImageAsBlob();
    }
  }, [user]);

  return (
    <>
      <div className={styles.profilePic}>
        <Avatar
          user={user}
          size="xl"
          className={styles.avatar}
          onClick={() => setShowModal(true)}
        >
          <span className={styles.editImg}>
            {t("profile.editImage", "Edit")}
          </span>
        </Avatar>
      </div>
      {showModal && (
        <Modal
          title="Upload profile picture"
          onClose={() => {
            setShowModal(false);
            reset();
          }}
          className={styles.modal}
          contentClass={styles.modalContent}
        >
          <div className={styles.cropperWrapper}>
            {selectedPicture || profilePicture ? (
              <>
                <div className={styles.cropperContainer}>
                  <ReactCrop
                    image={selectedPicture || profilePicture}
                    crop={crop}
                    aspect={1}
                    onCropChange={setCrop}
                    zoom={zoom}
                    onZoomChange={setZoom}
                    cropShape="round"
                    onCropComplete={(_, croppedAreaPixels) => {
                      setCroppedAreaPixels(croppedAreaPixels);
                    }}
                    zoomWithScroll={false}
                  />
                </div>
                <input
                  type="range"
                  value={zoom}
                  onChange={(e) => setZoom(Number(e.target.value))}
                  min={1}
                  max={3}
                  step={0.1}
                  className={styles.slider}
                />
              </>
            ) : null}

            <div className={styles.actions}>
              <input
                ref={inputRef}
                type="file"
                accept=".jpg,.png,.jpeg"
                onChange={handleSelectPicture}
                hidden
              />
              <Button
                disabled={isLoading}
                onClick={() => inputRef.current?.click()}
                inverted
              >
                Upload new picture
              </Button>
              {(selectedPicture || croppedAreaPixels) && (
                <Button
                  isLoading={isLoading}
                  className={styles.save}
                  onClick={handleUpload}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProfilePic;
