import { ExternalUserTypeBehaviour } from "~/constants/externalUserBehaviours";
import locales from "~/constants/locales";
import { ExternalUserType, Program, UserProgram } from "~/typing/sidekickTypes";

export const isCancerProgram = (program?: Program) => {
  return (
    program?.externalUserTypes?.some(
      (user) => user.behaviour & ExternalUserTypeBehaviour.CancerProgram
    ) || false
  );
};

export const isCancerProgramByUserType = (userType?: ExternalUserType) => {
  return (
    userType &&
    !!(userType?.behaviour & ExternalUserTypeBehaviour.CancerProgram)
  );
};

export const programHasLayersForDisplay = (program?: Program) => {
  return (
    (program?.layersForDisplay && program?.layersForDisplay.length > 0) || false
  );
};
export const isTestProgram = (program?: Program) => {
  return program?.programCatalogItemProjectType?.toLowerCase() === "test";
};

export const shouldShowProgramSlotName = (program?: UserProgram) => {
  if (program?.slotName === undefined) return false;
  return !program?.slotName.toLowerCase().includes("pregnancy week");
};

export const getProgramDisplayName = (program?: Program) => {
  /**
   * If the program has a care portal name, use that.
   * Otherwise, use the program name (internal name).
   * If the program has neither, return an empty string.
   */
  if (!program) {
    return "";
  }

  if (!!program.carePortalName) {
    return program.carePortalName;
  }

  return program.name ?? "";
};

export const programHasBehaviour = (
  program: Program | undefined,
  behaviour: number
) => Boolean(program && program.behaviour & behaviour);

export const getProgramLocaleName = (programLocale: string) => {
  return locales.find((locale) => locale.locale === programLocale)?.name ?? "";
};

export const getProgramBreadcrumbName = (
  program?: Program,
  programLocales?: string[]
) => {
  if (program && programLocales && programLocales.length > 1) {
    return `${getProgramDisplayName(program)} (${getProgramLocaleName(
      program.locale
    )})`;
  } else {
    return getProgramDisplayName(program);
  }
};

export const isMedicareProgram = (program?: Program) => {
  return program?.lineOfBusiness?.toLowerCase().includes("medicare") || false;
};
