import { DateTime } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ExportContext } from "./exportPreviewContext";
import styles from "./PrintEntityList.module.scss";

import { TextMessageType } from "~/constants/textMessaging";
import { isBetweenDates } from "~/helpers/date/isBetweenDates";
import { ChatMessage, SMSMessage } from "~/typing/sidekickTypes";

type PrintEntityListProps = {
  entities: ChatMessage[] | SMSMessage[];
  useSms?: boolean;
  getMessageSenderName: (entity: any) => string;
};

export default function PrintEntityList({
  entities,
  useSms,
  getMessageSenderName
}: PrintEntityListProps) {
  const { t } = useTranslation();

  const { startDate, endDate } = useContext(ExportContext);

  const getText = (entity) => entity[useSms ? "message" : "text"];
  const header = t(`exportPreview.${useSms ? "smsMessages" : "messages"}`);

  const getSmsDisplayName = (sms) => {
    return sms.type === TextMessageType.UserResponse
      ? sms.phoneNumber
      : "Coach";
  };

  const getDisplayName = (entity) => {
    return useSms ? getSmsDisplayName(entity) : getMessageSenderName(entity);
  };

  return (
    <>
      <div className={styles.flexContainer}>
        <span className={styles.bigHeader}>{header}</span>
      </div>
      <div className={styles.flexContainer}>
        <ul className={styles.entityList}>
          {entities.map((entity, key) => {
            if (isBetweenDates(entity.createdDate, startDate, endDate)) {
              return (
                <li key={key}>
                  <div>
                    <div>{getText(entity)}</div>
                    <div className={styles.name}>
                      <span>{getDisplayName(entity)}</span>
                      {` ${t("exportPreview.on")} `}
                      {DateTime.fromISO(entity.createdDate).toLocaleString({
                        weekday: "long",
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                      })}
                    </div>
                  </div>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    </>
  );
}
