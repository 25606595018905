import { SurveyRating } from "~/typing/sidekickTypes";

export default function (
  result: number,
  surveyRatings: SurveyRating[] | undefined
): SurveyRating | undefined {
  const ratingsOrderedByLowerValue =
    surveyRatings?.sort((a, b) => {
      if (a.lowerValue === undefined) {
        return 1;
      }
      if (b.lowerValue === undefined) {
        return -1;
      }
      return a.lowerValue - b.lowerValue;
    }) ?? [];

  const rating = ratingsOrderedByLowerValue.find((rating) => {
    if (rating.lowerValue === undefined && (rating.upperValue ?? 0) >= result) {
      return true;
    }

    if (rating.upperValue === undefined && (rating.lowerValue ?? 0) <= result) {
      return true;
    }

    if ((rating.lowerValue ?? 0) <= result) {
      if (
        (rating.upperValue !== undefined && rating.upperValue >= result) ||
        rating.upperValue === undefined
      ) {
        return true;
      }
    } else if (rating.upperValue !== undefined && rating.upperValue > result) {
      return true;
    }

    return false;
  });

  return rating;
}
