import { getCallConsentStatusText, getEnrolmentStatusText } from "./helper";

import { ExternalUser } from "~/typing/sidekickTypes";

export enum ExternalUserActions {
  Sort = "Sort",
  Update = "Update"
}

type SortAction = {
  type: ExternalUserActions.Sort;
  payload: ExternalUser[];
};

type UpdateAction = {
  type: ExternalUserActions.Update;
  payload: ExternalUser;
};

type Action = SortAction | UpdateAction;

export default function externalUsersReducer(
  state: ExternalUser[],
  action: Action
) {
  switch (action.type) {
    case ExternalUserActions.Sort: {
      const users = action.payload ? [...action.payload] : [];
      return users.map((user) => ({
        ...user,
        enrolmentStatusText: getEnrolmentStatusText(user.enrolmentStatus),
        enrolmentConsentToCallText: getCallConsentStatusText(
          user.enrolmentConsentToCall
        )
      }));
    }

    case ExternalUserActions.Update: {
      if (!action.payload) return state;

      const user = action.payload;
      user.enrolmentStatusText = getEnrolmentStatusText(user.enrolmentStatus);
      user.enrolmentConsentToCallText = getCallConsentStatusText(
        user.enrolmentConsentToCall
      );
      const index = state.findIndex((x) => x.id === user.id);

      if (index >= 0) {
        const cp = [...state];
        cp.splice(index, 1, user);
        return cp;
      } else {
        return [...state, user];
      }
    }

    default: {
      return state;
    }
  }
}
