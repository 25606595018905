import SkeletonElement from "./SkeletonElement";
import styles from "./SkeletonMissionsList.module.scss";
import SkeletonShimmer from "./SkeletonShimmer";

export default function SkeletonMissionsList() {
  return (
    <div>
      {Array.from(Array(3).keys()).map((index) => (
        <div className={`skeleton-wrapper ${styles.wrapper}`} key={index}>
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
          <SkeletonShimmer />
        </div>
      ))}
    </div>
  );
}
