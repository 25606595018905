import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import styles from "./printMealCard.module.scss";

import {
  getMealTypeText,
  getPortionSizeCharacter
} from "../performance/journals/journalhelper";

import Image from "~/components/image/Image";
import { getTimeZoneAdjustedDate } from "~/helpers/date/adjustToTimeZone";
import getHealthinessText from "~/helpers/getHealthinessText";
import { ScoreMeal } from "~/typing/sidekickTypes";

type PrintMealCardProps = {
  journal: ScoreMeal;
  timeZoneOffset: number;
};

const PrintMealCard = ({ journal, timeZoneOffset }: PrintMealCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.printMealCard} mb-2`}>
      <Image className={styles.image} imageUrl={journal.attachmentUrl} />
      <div className={styles.mealInfo}>
        <div className={styles.mealInfoHeader}>
          <div className={styles.mealHeaderLeft}>
            <div className={styles.mealType}>
              {t(getMealTypeText(journal.mealType))}
            </div>
            <div className={styles.mealTime}>
              {DateTime.fromISO(
                getTimeZoneAdjustedDate(journal.serverDate, timeZoneOffset)
              ).toLocaleString({
                weekday: "long",
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              })}{" "}
              {t("performance.journals.review.localTime", "Local time")}
            </div>
          </div>
          <div className={`${styles.mealHeaderRight}`}>
            <div>
              {t("performance.journals.review.portionSize")}:{" "}
              {getPortionSizeCharacter(journal.portionSize)}
            </div>
            <div>
              {t("performance.journals.review.coachPortionSize")}:{" "}
              {journal.coachPortionSize
                ? getPortionSizeCharacter(journal.coachPortionSize)
                : null}
            </div>
          </div>
        </div>

        <div className={styles.mealInfoBody}>
          <div className={styles.healthynessRatings}>
            <div className={styles.userRating}>
              {t("performance.journals.review.userRating")}:{" "}
              {getHealthinessText(journal.healthyness)}
            </div>
            {journal.coachHealthyness && (
              <div className={styles.coachRating}>
                {t("performance.journals.review.coachRating")}:{" "}
                {getHealthinessText(journal.coachHealthyness)}
              </div>
            )}
          </div>
          <div className={styles.comment}>{journal.coachReview}</div>
        </div>
      </div>
    </div>
  );
};

export default PrintMealCard;
