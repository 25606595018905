import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./FoodJournal.module.scss";
import FoodJournalReview from "./FoodJournalReview";
import { getPortionSizeCharacter } from "./journalhelper";

import Image from "~/components/image/Image";
import { ScoreMeal } from "~/typing/sidekickTypes";

type FoodJournalProps = {
  journal: ScoreMeal;
  timeZoneOffset: number;
};

const FoodJournal = ({ journal, timeZoneOffset }: FoodJournalProps) => {
  const { t } = useTranslation();
  const getHealthinessClass = (rating) => {
    if (rating === undefined) {
      return styles.unknown;
    }
    if (rating > 0.666) {
      return styles.healthy;
    } else if (rating > 0.333) {
      return styles.neutral;
    } else {
      return styles.unhealthy;
    }
  };
  const getHoursAndMinutes = (dateString) => {
    return DateTime.fromISO(dateString).toFormat("HH':'mm");
  };
  const getMealTypeText = (mealType: number) => {
    switch (mealType) {
      case 1:
        return t("performance.journals.breakfast", "Breakfast"); //'MEALTYPE_BREAKFAST'
      case 2:
        return t("performance.journals.lunch", "Lunch"); //'MEALTYPE_LUNCH'
      case 3:
        return t("performance.journals.dinner", "Dinner"); //'MEALTYPE_DINNER'
      case 4:
        return t("performance.journals.snack", "Snack"); //'MEALTYPE_SNACK'
    }
    return "";
  };
  const [openModal, setOpenModal] = useState(false);
  const handleModalClosed = () => {
    setOpenModal(false);
  };
  // Take the current date from server and add the hours to offset the timezone
  const adjustedDate = DateTime.fromISO(journal.serverDate)
    .plus({ hours: timeZoneOffset })
    .toISO();
  return (
    <>
      <div
        onClick={() => setOpenModal(true)}
        className={`${styles.foodJournalBox} ${getHealthinessClass(
          journal.coachHealthyness
        )}`}
        data-testid="food-journal-box"
      >
        <div
          data-testid="food-journal-inner-box"
          className={styles.foodJournalInnnerBox}
        >
          {journal.coachModifiedDate === undefined && (
            <div className={styles.statusNew}>New</div>
          )}
          <Image
            shouldFetchAsBlob
            className={styles.image}
            imageUrl={journal.attachmentUrl}
          />
          <div
            data-testid="food-journal-text-container"
            className={styles.textContainer}
          >
            <p className={styles.date}>
              {journal.serverDate
                ? getHoursAndMinutes(journal.serverDate)
                : "N/A"}
            </p>
            <div>
              <span className={styles.title}>
                {getMealTypeText(journal.mealType)}
              </span>
              <span className={styles.portionSize}>
                {getPortionSizeCharacter(journal.portionSize)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <FoodJournalReview
          journal={journal}
          createdDate={adjustedDate ?? ""}
          serverDate={timeZoneOffset !== 0 ? journal.serverDate : undefined}
          close={handleModalClosed}
        />
      )}
    </>
  );
};

export default FoodJournal;
