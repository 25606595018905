import config from "~/config";
import { ExternalUser } from "~/typing/sidekickTypes";

export default function handleClickCareCompassLink(externalUser: ExternalUser) {
  let url = config.anthemCareCompassDeepLinkBaseUrl;

  const careCompassId = externalUser?.careCompassExternalUserID;

  if (careCompassId) {
    url += `?ExtMemeVal=${careCompassId}`;
  }

  window.open(url, "_blank");
}
