export default function updateStateFromPayload(
  state: any,
  action: { type: string; payload: any },
  idField = "id"
) {
  const stateCopy = [...state];
  const index = stateCopy.findIndex(
    (element) => element[idField] === action?.payload[idField]
  );

  const newElement = action.payload;
  stateCopy.splice(index, 1, newElement);
  return stateCopy;
}
