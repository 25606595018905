import colors from "~/styles/colors";

type SadFaceIconProps = {
  color?: string;
};

const SadFaceIcon = ({ color = colors.red10 }: SadFaceIconProps) => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.56641 1H1.4668V1.08008H1.56641V1Z" fill={color} />
      <path d="M7.55535 1H7.4668V1.08008H7.55535V1Z" fill={color} />
      <path
        d="M1.4668 1V0.1C0.969741 0.1 0.566797 0.502944 0.566797 1L1.4668 1ZM1.56641 1H2.46641C2.46641 0.502944 2.06346 0.1 1.56641 0.1V1ZM7.4668 1V0.1C6.96974 0.1 6.5668 0.502944 6.5668 1L7.4668 1ZM7.55535 1H8.45535C8.45535 0.502944 8.0524 0.1 7.55535 0.1V1ZM1.56641 1.08008V1.98008C2.06346 1.98008 2.46641 1.57713 2.46641 1.08008H1.56641ZM1.4668 1.08008H0.566797C0.566797 1.57713 0.969741 1.98008 1.4668 1.98008V1.08008ZM7.55535 1.08008V1.98008C8.0524 1.98008 8.45535 1.57713 8.45535 1.08008H7.55535ZM7.4668 1.08008H6.5668C6.5668 1.57713 6.96974 1.98008 7.4668 1.98008V1.08008ZM7.16818 8.93683C7.42979 9.35947 7.98449 9.49001 8.40713 9.22839C8.82977 8.96678 8.9603 8.41208 8.69869 7.98944L7.16818 8.93683ZM0.234746 7.98944C-0.0268681 8.41208 0.103667 8.96678 0.526305 9.22839C0.948943 9.49001 1.50364 9.35947 1.76525 8.93683L0.234746 7.98944ZM1.4668 1.9H1.56641V0.1H1.4668V1.9ZM7.4668 1.9H7.55535V0.1H7.4668V1.9ZM0.666406 1V1.08008H2.46641V1H0.666406ZM1.56641 0.180078H1.4668V1.98008H1.56641V0.180078ZM2.3668 1.08008V1H0.566797V1.08008H2.3668ZM6.65535 1V1.08008H8.45535V1H6.65535ZM7.55535 0.180078H7.4668V1.98008H7.55535V0.180078ZM8.3668 1.08008V1H6.5668V1.08008H8.3668ZM4.46672 7.40398C5.59173 7.40398 6.59191 8.00587 7.16818 8.93683L8.69869 7.98944C7.81675 6.56468 6.25661 5.60398 4.46672 5.60398V7.40398ZM1.76525 8.93683C2.34153 8.00587 3.3417 7.40398 4.46672 7.40398V5.60398C2.67683 5.60398 1.11668 6.56468 0.234746 7.98944L1.76525 8.93683Z"
        fill={color}
      />
    </svg>
  );
};

export default SadFaceIcon;
