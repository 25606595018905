import { CustomListColumn } from "./CustomList";

import SkeletonUserListRow from "../skeletons/SkeletonUserListRow";

type CustomListRows<T> = {
  columns: CustomListColumn<T>[];
  entities: T[];
  loading: boolean;
  onRowClick?: (row: T) => void;
};

const CustomListRows = <T,>({
  columns,
  entities,
  loading,
  onRowClick,
}: CustomListRows<T>) => {
  if (loading) {
    return (
      <>
        {[1, 2, 3, 4, 5].map((index) => {
          return (
            <SkeletonUserListRow
              key={`skeleton-userlist-row-${index}`}
              columns={(columns?.length ?? 0) + 1}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      {entities?.map((entity, index) => (
        <tr onClick={() => onRowClick?.(entity)} key={`row-list-${index}`}>
          {columns.map((column, columnIndex) => (
            <td
              className={column.className}
              key={`row-list-column-${columnIndex}`}
            >
              {column.render(entity)}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default CustomListRows;
