import { UserStatus } from "../program/programUsers/ProgramUsersPage.helpers";

import { TimeInMs } from "~/constants/measurements";
import getRatingBasedOnResult from "~/helpers/getRatingBasedOnResult";
import { getSurveyById } from "~/helpers/surveys/surveyHelpers";
import {
  hasQuitProgram,
  hasBeenDischargedFromProgram,
  hasFinishedProgram,
  isPausedInProgram,
  isInMaintenance
} from "~/helpers/user/userHelpers";
import { Program, ProviderViewUser, Survey } from "~/typing/sidekickTypes";

export const filterUserByActivity = (
  user: ProviderViewUser,
  activityFilters?: string[],
  program?: Program
): boolean => {
  const now = Date.now();

  if (!activityFilters) return false;

  if (
    !activityFilters.includes(UserStatus.Quit) &&
    hasQuitProgram(user.userColumn.programStatus)
  ) {
    return false;
  }

  if (
    !activityFilters.includes(UserStatus.Discharged) &&
    hasBeenDischargedFromProgram(user.userColumn.programStatus)
  ) {
    return false;
  }

  const lastActiveDateOrJoined = user.userColumn.lastActiveDate
    ? new Date(user.userColumn.lastActiveDate)
    : user.programStatusColumn?.joinedDate
    ? new Date(user.programStatusColumn.joinedDate)
    : undefined;
  const lastActiveDate = user.userColumn.lastActiveDate
    ? new Date(user.userColumn.lastActiveDate)
    : undefined;
  const joinedDate = user.programStatusColumn?.joinedDate
    ? new Date(user.programStatusColumn.joinedDate)
    : undefined;

  for (const filter of activityFilters) {
    switch (filter) {
      case UserStatus.Active:
        if (
          !hasFinishedProgram(user.userColumn.programStatus) &&
          lastActiveDate &&
          now - lastActiveDate.getTime() < TimeInMs.Week
        ) {
          return true;
        }
        break;
      case UserStatus.New:
        if (
          !hasFinishedProgram(user.userColumn.programStatus) &&
          joinedDate &&
          now - joinedDate.getTime() < TimeInMs.Week
        ) {
          return true;
        }
        break;
      case UserStatus.Pause:
        if (isPausedInProgram(user.userColumn.programStatus)) {
          return true;
        }
        break;
      case UserStatus.Finished:
        if (hasFinishedProgram(user.userColumn.programStatus)) {
          return true;
        }
        break;
      case UserStatus.Discharged:
        if (hasBeenDischargedFromProgram(user.userColumn.programStatus)) {
          return true;
        }
        break;
      case UserStatus.Quit:
        if (hasQuitProgram(user.userColumn.programStatus)) {
          return true;
        }
        break;
      case UserStatus.Maintenance:
        if (
          user.programStatusColumn?.currentSlot &&
          isInMaintenance(
            { currentSlot: parseInt(user.programStatusColumn.currentSlot) },
            program
          )
        ) {
          return true;
        }
        break;

      case UserStatus.Inactive7Days:
        if (
          lastActiveDateOrJoined &&
          now - lastActiveDateOrJoined.getTime() > TimeInMs.Week &&
          now - lastActiveDateOrJoined.getTime() < 2 * TimeInMs.Week
        ) {
          return true;
        }
        break;

      case UserStatus.Inactive14Days:
        if (
          lastActiveDateOrJoined &&
          now - lastActiveDateOrJoined.getTime() > 2 * TimeInMs.Week &&
          now - lastActiveDateOrJoined.getTime() < 30 * TimeInMs.Day
        ) {
          return true;
        }
        break;
      case UserStatus.Inactive30Days:
        if (
          lastActiveDateOrJoined &&
          now - lastActiveDateOrJoined.getTime() > 30 * TimeInMs.Day
        ) {
          return true;
        }
        break;
      case UserStatus.Scale14Day: {
        const latestScaleDate = user.userColumn.latestScaleDate
          ? new Date(user.userColumn.latestScaleDate)
          : undefined;
        if (
          latestScaleDate &&
          now - latestScaleDate.getTime() > 2 * TimeInMs.Week &&
          now - latestScaleDate.getTime() < 3 * TimeInMs.Week
        ) {
          return true;
        }
        break;
      }
      case UserStatus.Scale21Day: {
        const latestScaleDate = user.userColumn.latestScaleDate
          ? new Date(user.userColumn.latestScaleDate)
          : undefined;
        if (
          latestScaleDate &&
          now - latestScaleDate.getTime() > 3 * TimeInMs.Week
        ) {
          return true;
        }
        break;
      }
      default:
        break;
    }
  }
  return false;
};

export const filterUserByPRO = ({
  user,
  proStatusFilter,
  surveyId,
  surveys,
  symptomColumnIndex
}: {
  user: ProviderViewUser;
  proStatusFilter: string;
  surveys: Survey[];
  surveyId: string;
  symptomColumnIndex: number;
}): boolean => {
  if (symptomColumnIndex === undefined) return false;

  if (!user.symptomColumns?.[symptomColumnIndex].result) return false;

  const rating = getSurveyRating({
    surveys,
    surveyResult: user.symptomColumns?.[symptomColumnIndex].result,
    surveyId
  });

  return Boolean(rating && rating.id === proStatusFilter);
};

export const getSurveyRating = ({
  surveyId,
  surveyResult,
  surveys
}: {
  surveys: Survey[];
  surveyResult: number;
  surveyId: string;
}) => {
  const survey = getSurveyById(surveys, surveyId);

  const rating = getRatingBasedOnResult(surveyResult, survey?.ratings);

  return rating;
};
