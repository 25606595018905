import { Coach, Specialty } from "~/typing/sidekickTypes";

export const getSpecialtyName = (
  specialtyId: string,
  specialties: Partial<Specialty>[] | undefined
) => {
  return specialties?.find((specialty) => specialty.id === specialtyId)?.name;
};

export const mapSpecialtyCoachOption = ({
  coaches,
  coachUserId,
  specialtyId
}: {
  coaches?: Partial<Coach>[];
  specialtyId: string;
  coachUserId: string;
}) => {
  const coach = coaches?.find((coach) => coach.userId === coachUserId);

  return {
    specialtyId,
    ...coach
  };
};
