import ReactMarkdown from "react-markdown";

import styles from "./ProviderHeader.module.scss";

import ProgramCardDetail from "../programOverview/components/programCardDetail/ProgramCardDetail";

import ActiveUsersIcon from "~/assets/users-active.svg";
import NewUsersIcon from "~/assets/users-new.svg";
import HighRiskUsersIcon from "~/assets/users-warning.svg";
import Image from "~/components/image/Image";
import config from "~/config";

type ProviderHeaderProps = {
  imageHref: string;
  name: string;
  description: string;
  providerViewDescription: string;
  activeUsers: number;
  userCount: number;
  newUsers: number;
  highRiskUsers: number;
};

const ProviderHeader = ({
  imageHref,
  name,
  activeUsers,
  description,
  providerViewDescription,
  userCount,
  newUsers,
  highRiskUsers
}: ProviderHeaderProps) => {
  const programCardDetailData = [
    {
      title: "activeUsers",
      content: (
        <>
          {activeUsers}
          <span>/{userCount}</span>
        </>
      ),
      imgSrc: ActiveUsersIcon
    },
    {
      title: "newUsers",
      content: newUsers,
      imgSrc: NewUsersIcon
    },
    {
      title: "highRiskUsers",
      content: highRiskUsers,
      imgSrc: HighRiskUsersIcon,
      className: styles.highRisk
    }
  ];

  const programCardDetailComponents = programCardDetailData.map(
    ({ title, content, imgSrc, className }) => {
      return (
        <ProgramCardDetail
          title={title}
          key={title}
          content={content}
          className={`${styles.detail} ${className || ""}`}
          imgSrc={imgSrc}
          isCountry={false}
        />
      );
    }
  );

  const imageClasses =
    styles.image + (config.isAnthem ? ` ${styles.anthem}` : "");

  return (
    <div className={styles.container}>
      <Image imageUrl={imageHref} className={imageClasses} />
      <div className={styles.details}>
        <div className={styles.top}>
          <h1 className={styles.name}>{name}</h1>
          <div className={styles.inner}>{programCardDetailComponents}</div>
        </div>
        <div className={styles.description}>
          <ReactMarkdown>
            {providerViewDescription || description}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default ProviderHeader;
