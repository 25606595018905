import { DateTime } from "luxon";

import { t } from "~/i18n";
import colors from "~/styles/colors";
import {
  MedicationLogSanitised,
  MedicationReminderType
} from "~/typing/carePortalTypes";
import { Reminder } from "~/typing/sidekickTypes";

/**
 * Imports and returns a colored SVG icon as a string.
 *
 * @param {number} iconNumber - The number of the icon to import.
 * @param {string} color - The color to apply to the icon (optional). Defaults to navyBlue20.
 * @returns {Promise<string>} - A promise that resolves to the colored SVG icon as a string or an empty string if iconNumber is not provided.
 */

const getCorrectIcon = (iconNumber: number) => {
  const globs = import.meta.glob(
    "/src/assets/medication/medication_reminder_icon_*.svg",
    { as: "raw", eager: true }
  );

  const text =
    globs[`/src/assets/medication/medication_reminder_icon_${iconNumber}.svg`];
  return text;
};

export async function importIcon(
  iconNumber?: number,
  color?: string
): Promise<string> {
  if (!iconNumber) {
    return "";
  }
  const text = getCorrectIcon(iconNumber);

  // This is the default color for the medication icons.
  const nonColor = colors.navyBlue20;

  // This regex will replace all instances of the nonColor variable with the medication icon color.
  // gi means global and case insensitive.
  const regExp = new RegExp(nonColor, "gi");
  const iconColored = text.replace(regExp, `#${color || colors.navyBlue20}`);

  return iconColored;
}

/**
 * Returns a formatted reminder string based on the provided Reminder object.
 *
 * @param {Reminder[]} reminders - The array of reminder objects containing the schedule information.
 * @returns {string} - The formatted reminder string.
 */
export const createReminderString = (
  reminders: Reminder[],
  type: MedicationReminderType
): string => {
  // For each reminder, calculate the reminder string and return it in a new array
  const reminderStrings = reminders.map((reminder) => {
    let returnString = "";

    if (reminder.periodically) {
      const { every, period } = reminder.periodically;
      if (period === 1) {
        returnString = getPeriodString(every, "daily", "D");
      } else if (period === 2) {
        returnString = getPeriodString(
          every,
          "weekly",
          t("medication.schedule.w")
        );
      } else if (period === 3) {
        returnString = getPeriodString(every, "monthly", "M");
      }
    } else if (reminder.weekdaySchedule) {
      if (MedicationReminderType.MedicationCard === type) {
        if (reminder.weekdaySchedule.weekDays.length === 7) {
          returnString = t("medication.schedule.daily");
        } else {
          returnString = reminder.weekdaySchedule.weekDays
            .map(getWeekdayString)
            .join(" ");
        }
      } else {
        // Count the number of weekdays selected and add that to the return string
        const weekdaysSelected = reminder.weekdaySchedule.weekDays.length;

        returnString =
          weekdaysSelected + " " + t("medication.schedule.timesAWeek");
      }
    }
    return returnString;
  });

  // Join the reminder strings with a separator (e.g., comma and space) and return the resulting single string
  return reminderStrings.join(", ");
};

/**
 * Helper function to create a period string based on the provided values.
 *
 * @param {number} every - The number of days, weeks, or months between reminders.
 * @param {string} period - The period keyword ('daily', 'weekly', or 'monthly').
 * @param {string} perperiodShortiod - The period keyword in short version('D', 'W/V based on locale', or 'M').
 * @returns {string} - The formatted period string.
 */
const getPeriodString = (
  every: number,
  period: string,
  periodShort: string
): string => {
  return every === 1
    ? t(`medication.schedule.${period}`) + " "
    : t("medication.schedule.every") + " " + every + periodShort;
};

/**
 * Helper function to create a weekday string based on the provided day number.
 *
 * @param {number} day - The day number (1-7).
 * @returns {string} - The formatted weekday string.
 */
const getWeekdayString = (day: number): string => {
  const daysShort = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  return t(`medication.schedule.daysShort.${daysShort[day - 1]}`) + " ";
};

/**
 * Sorts the upcoming logs by date and time.
 * @param {MedicationLogSanitised[]} upcomingLogs - The array of upcoming logs.
 * @returns {MedicationLogSanitised[]} - The sorted upcoming logs.
 */
export const sortUpcomingLogs = (upcomingLogs: MedicationLogSanitised[]) => {
  //Sort the upcoming logs by date and time
  return upcomingLogs.sort((a, b) => {
    // If the completed date is not null, use that, otherwise use the scheduled date

    const dateA = !!a.completedDate
      ? DateTime.fromISO(a.completedDate)
      : DateTime.fromISO(a.scheduledDate);

    const dateB = !!b.completedDate
      ? DateTime.fromISO(b.completedDate)
      : DateTime.fromISO(b.scheduledDate);
    if (dateA.toMillis() > dateB.toMillis()) {
      return 1;
    } else if (dateA.toMillis() < dateB.toMillis()) {
      return -1;
    } else {
      if (a.time > b.time) {
        return 1;
      } else if (a.time < b.time) {
        return -1;
      } else {
        return 0;
      }
    }
  });
};

export default { importIcon, createReminderString, sortUpcomingLogs };
