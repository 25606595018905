export default function (name: string | undefined) {
  const base = "/flags";

  let filename;
  if (name) {
    const countryName = name.split(" -")[0]; //Support US states. Example: name = "United States - Indiana"
    filename = countryName.toLowerCase().replace(/ /g, "-");
  } else {
    filename = "empty";
  }

  let filetype = "svg";
  if (filename === "global") {
    filetype = "png";
  }
  return `${base}/${filename}.${filetype}`;
}
