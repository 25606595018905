import { SurveyResultAnswerSeverity } from "~/typing/sidekickTypes";

const defaultColors = [
  { backgroundColor: "#f2f4f6" },
  { backgroundColor: "#f5d8f4" },
  { backgroundColor: "#f7bbf2" },
  { backgroundColor: "#fa9ff0" },
  { backgroundColor: "#fc82ee" },
  { backgroundColor: "#ff66ec" },
  { backgroundColor: "#fb42e6" },
  { backgroundColor: "#ff00df" },
  { backgroundColor: "#e000c8" },
  { backgroundColor: "#c200ac" },
  { backgroundColor: "#990086", color: "#fff" },
  { backgroundColor: "#0000aa", color: "#fff" },
  { backgroundColor: "#000078", color: "#fff" },
  { backgroundColor: "#000046", color: "#fff" }
];

export default function cellStyleForValue(
  value: number,
  uniqueValues: number[],
  answerSeverities?: SurveyResultAnswerSeverity[]
) {
  if (answerSeverities) {
    return cellStyleForAnswerSeverityValue(value, answerSeverities);
  }

  const index = getColorIndex(value, uniqueValues);
  if (!isNaN(index)) {
    return defaultColors[index] || {};
  }
}

export function cellStyleForAnswerSeverityValue(
  value: number,
  answerSeverities: SurveyResultAnswerSeverity[]
) {
  const severity = answerSeverities?.find(
    (severity) => severity.value === value
  );
  return { backgroundColor: severity?.colour };
}

const getColorIndex = (value: number, uniqueValues: number[]) => {
  const maxValue = uniqueValues[uniqueValues.length - 1] || 7;

  let index = 0;
  if (maxValue > 25) {
    index = uniqueValues.indexOf(value);
  } else {
    // normalize the value to an index within the length of defaultColors
    index =
      value === 0
        ? 0
        : Math.ceil((value / maxValue) * defaultColors.length) - 1;
  }
  return index;
};
