import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";
import styles from "./SkeletonUserListRow.module.scss";

type SkeletonUserListRowProps = {
  columns?: number;
  hasUser?: boolean;
};

export default function SkeletonUserListRow({
  columns = 1,
  hasUser = true
}: SkeletonUserListRowProps) {
  const nonUserCols = columns - (hasUser ? 1 : 0);

  return (
    <tr className={`${styles.SkeletonUserListRow} skeleton-wrapper`}>
      {hasUser && (
        <td>
          <div className={styles.userInfo}>
            <SkeletonElement type="avatar" />
            <SkeletonElement type="text" />
            <SkeletonShimmer />
          </div>
        </td>
      )}
      {nonUserCols >= 1
        ? Array.from(Array(nonUserCols).keys()).map((index) => (
            <td key={index}>
              <SkeletonElement type="text" />
              {index % 2 === 1 && <SkeletonShimmer />}
            </td>
          ))
        : null}
    </tr>
  );
}
