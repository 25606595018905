import { useContext, useMemo } from "react";

import styles from "./AddCareManagerForm.module.scss";
import { AddCareManagersFormContext } from "./AddCareManagerModal.context";

// import AutoresizeTextarea from "~/components/autoresizeTextarea/AutoresizeTextarea";
import Avatar from "~/components/avatar/Avatar";
import MultiInput from "~/components/input/MultiInput";
import CustomCheckBoxSelect from "~/components/select/CustomCheckBoxSelect";
import { isValidEmail } from "~/helpers/string/stringHelpers";
import { t } from "~/i18n";
import { Program } from "~/typing/sidekickTypes";

const AddCareManagerForm = () => {
  const {
    userToInvite,
    setEmails,
    chosenEmails,
    availableLocales,
    availableLocations,
    chosenLocales,
    chosenLocations,
    setLocales,
    setLocations,
    // setMessage,
    chosenPrograms,
    availablePrograms,
    setPrograms,
    // message,
    availableTherapeuticAreas,
    chosenTherapeuticAreas,
    setTherapeuticAreas,
    errors
  } = useContext(AddCareManagersFormContext);

  // filter available program based on chosen locales and locations
  const filteredPrograms = useMemo(() => {
    const uniquePrograms = availablePrograms.reduce(
      (acc: Program[], program) => {
        const localeMatch =
          chosenLocales.includes(program.locale) || chosenLocales.length === 0;
        const locationMatch =
          chosenLocations.includes(program.location?.id ?? "") ||
          chosenLocations.length === 0;

        const therapeuticAreaMatch =
          chosenTherapeuticAreas.includes(program.therapeuticArea?.id ?? "") ||
          chosenTherapeuticAreas.length === 0;

        //We only want to show each program once in the list, not one for each locale.
        if (localeMatch && locationMatch && therapeuticAreaMatch) {
          const existingProgram = acc.find((p) => p.name === program.name);

          if (!existingProgram) {
            acc.push(program);
          }
        }

        return acc;
      },
      []
    );

    return uniquePrograms;
  }, [
    availablePrograms,
    chosenLocales,
    chosenLocations,
    chosenTherapeuticAreas
  ]);

  return (
    <div className={styles.formContainer}>
      {!userToInvite && (
        <MultiInput
          initialValue={chosenEmails}
          dataTestId="add-user-email-input"
          multiple
          label={t("general.email")}
          onChange={setEmails}
          wrapperClassName={styles.fullWidth}
          error={errors.emails}
          inputValidator={isValidEmail}
          required
        />
      )}
      {userToInvite && (
        <div className={styles.fullWidth}>
          <p>{t("roles.careManager")}</p>
          <div className={styles.careManager}>
            <Avatar
              user={{
                imageHref: userToInvite.careManager?.imageHref,
                fullName: userToInvite.careManager?.name
              }}
              displayTitle
            />
          </div>
        </div>
      )}
      <CustomCheckBoxSelect
        dataTestId="add-user-locale-select"
        options={availableLocales.map(({ locale, name }) => ({
          value: locale,
          text: `${name} (${locale})`
        }))}
        selected={chosenLocales}
        onChange={setLocales}
        label={t("general.locale")}
        allowSearch
        error={errors.locales}
        required
        noValuesSelectedPlaceholder={t("select.allShown")}
      />
      <CustomCheckBoxSelect
        dataTestId="add-user-location-select"
        options={availableLocations.map((location) => ({
          value: location.id,
          text: `${location.name} (${location.countryCode})`
        }))}
        selected={chosenLocations}
        onChange={setLocations}
        label={t("general.location")}
        allowSearch
        error={errors.locations}
        noValuesSelectedPlaceholder={t("select.allShown")}
      />
      <CustomCheckBoxSelect
        dataTestId="add-therapeutic-area-select"
        options={availableTherapeuticAreas.map((therapeuticArea) => ({
          value: therapeuticArea.id,
          text: therapeuticArea.name
        }))}
        label={t("general.therapeuticArea")}
        onChange={setTherapeuticAreas}
        wrapperClassName={styles.fullWidth}
        error={errors.therapeuticAreas}
        selected={chosenTherapeuticAreas}
        allowSearch
        noValuesSelectedPlaceholder={t("select.allShown")}
      />
      <CustomCheckBoxSelect
        noValuesSelectedPlaceholder={t("select.allShown")}
        dataTestId="add-user-program-select"
        options={filteredPrograms.map((program) => ({
          value: program.programCatalogItemId,
          text: program.name
        }))}
        selected={chosenPrograms}
        label={t("general.program")}
        onChange={setPrograms}
        displayOptionsCount
        wrapperClassName={styles.fullWidth}
        error={errors.programs}
        required
        allowSearch
        dependencies={{
          dependencies: [
            {
              title: t("general.locale"),
              visible: chosenLocales.length > 0
            },
            {
              title: t("general.location"),
              visible: chosenLocations.length > 0
            },
            {
              title: t("general.therapeuticArea"),
              visible: chosenTherapeuticAreas.length > 0
            }
          ]
        }}
      />

      {/* <AutoresizeTextarea
        dataTestId="add-user-message-textarea"
        value={message}
        placeholder={t("messages.addMessage")}
        onChange={setMessage}
        wrapperClassName={styles.fullWidth}
        label={t("general.message")}
        large
      /> */}
    </div>
  );
};

export default AddCareManagerForm;
