import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";
import { t } from "~/i18n";
import { CoachUser } from "~/typing/sidekickTypes";

export default function useProgramUsers({
  programId,
  locale
}: {
  programId: string;
  locale: string;
}) {
  const path = `/coach/programs/${programId}/locales/${locale}/users`;
  const { data, isError, isLoading, invalidate } = useApi<{
    coachUsers: CoachUser[];
  }>({
    path,
    queryKey: QueryKeyFactory.programs.users(programId, locale),
    staleTime: TimeInMs.Minute * 5,
    errorToastMessage: t("errors.fetch", { resource: t("general.users") })
  });

  return {
    users: data?.coachUsers ?? [],
    isError,
    isLoading,
    invalidate
  };
}
