import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmModal from "./ConfirmModal";
import styles from "./TeamMemberOptions.module.scss";

import AssignCoachMenu from "../user/information/assignCoachMenu/AssignCoachMenu";

import MenuIcon from "~/assets/menu.svg";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import {
  Coach,
  UserGroup,
  UserGroupUpdatePayload
} from "~/typing/sidekickTypes";

type TeamMemberOptionsProps = {
  group: UserGroup;
  coachId: string;
  updateGroups: (
    groupId: string,
    payload: UserGroupUpdatePayload
  ) => Promise<boolean>;
  coaches: Coach[];
};

export default function TeamMemberOptions({
  group,
  coachId,
  updateGroups,
  coaches
}: TeamMemberOptionsProps) {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const [showAssignPatients, setShowAssignPatients] = useState(false);
  const [showRemoveGroup, setShowRemoveGroup] = useState(false);
  const currentCoach = coaches?.find((coach) => coach.userId === coachId);
  const listRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(listRef, () => {
    if (showOptions) {
      setShowOptions(false);
    }
  });

  // -- Commented out while we wait for backend functionality for upgrading to lead coach --
  // const handleUpgradeToLead = () => {
  //   setShowOptions(false);
  // };

  const onShowAssignPatients = () => {
    setShowAssignPatients(true);
    setShowOptions(false);
  };

  // -- Commented out while we wait for backend functionality for removing coach
  // const onShowRemoveGroup = () => {
  //   setShowRemoveGroup(true);
  //   setShowOptions(false);
  // };

  const handleAssignPatients = async (newCoachId: string) => {
    const payload = {
      coachUserId: newCoachId,
      name: group.name,
      behaviour: group.behaviour
    };
    if (await updateGroups(group.id, payload)) {
      setShowAssignPatients(false);
      return true;
    } else {
      return false;
    }
  };

  const handleRemove = () => {
    // TODO add functionality when removeCoach endpoint is ready
    setShowRemoveGroup(false);
  };

  const options = (
    <div className={styles.options} ref={listRef}>
      <ul>
        <li>
          <button type="button" onClick={onShowAssignPatients}>
            {t("team.assignPatients", "Assign patients")}
          </button>
        </li>
        {/* -- Commented out while we wait for backend functionality for upgrading to lead coach and removing coach -- */}
        {/* <li>
          <button type="button" onClick={handleUpgradeToLead}>
            {t("team.upgradeToLead", "Upgrade to lead")}
          </button>
        </li> */}
        {/* <li className={styles.remove}>
          <button type="button" onClick={onShowRemoveGroup}>
            {t("general.remove", "Remove")}
          </button>
        </li> */}
      </ul>
    </div>
  );

  return (
    <>
      {" "}
      <div
        className={`${styles.TeamMemberOptions} ${
          showOptions ? styles.selected : ""
        }`}
      >
        <button
          type="button"
          className={styles.menu}
          onClick={() => setShowOptions(true)}
        >
          <img src={MenuIcon} />
        </button>
        {showOptions && options}
      </div>
      {showAssignPatients && (
        <AssignCoachMenu
          onClose={() => setShowAssignPatients(false)}
          currentCoach={currentCoach}
          coaches={coaches}
          onAssignCoach={handleAssignPatients}
        />
      )}
      {showRemoveGroup && (
        <ConfirmModal
          onClose={() => setShowRemoveGroup(false)}
          onConfirm={handleRemove}
          modalTitle={t("team.removeCoach")}
          confirmTitle={t("team.removeCoachConfirmTitle")}
        />
      )}
    </>
  );
}
