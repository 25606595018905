import { TranslationsDto } from "~/typing/graphql/types";
import {
  SurveyQuestion,
  SurveyResultAnswer,
  SurveyResultQuestion
} from "~/typing/sidekickTypes";

const findQuestion = (
  questionName: string,
  answeredQuestions: SurveyResultQuestion[]
) => {
  return answeredQuestions?.find((answer) => answer.name === questionName);
};

const getAnswerTitleForEmbeddedQuestion = (
  answer: SurveyResultAnswer,
  embeddedQuestion?: SurveyResultQuestion
) => {
  const embeddedQuestionAnswer = embeddedQuestion?.answers?.[0]?.answer ?? "-";

  const title = `${answer.title}${
    answer.title?.endsWith(":")
      ? ` ${embeddedQuestionAnswer}`
      : `: ${embeddedQuestionAnswer}`
  }`;

  return title;
};

export const getQuestionWithEmbeddedAnswers = (
  question: SurveyQuestion,
  resultQuestions: SurveyResultQuestion[],
  index: number,
  translations?: TranslationsDto[],
): { question: SurveyResultQuestion | undefined; index: number | undefined } => {
  // Find the index of the question in resultQuestions
  const answeredQuestionIndex = resultQuestions.findIndex(
    (q) => q.name === question.name
  );

  if (answeredQuestionIndex === -1) {
    return { question: undefined, index: undefined };
  }

  const answeredQuestion = resultQuestions[answeredQuestionIndex];

  const modifiedQuestions = answeredQuestion.answers?.map((answer) => {
    const resultQuestion = question.answers?.find(
      (q) => q.name === answer.name
    );

    if (!resultQuestion || !resultQuestion.embeddedQuestion) return answer;

    const embeddedQuestion = findQuestion(
      resultQuestion.embeddedQuestion.name,
      resultQuestions
    );

    const embeddedQuestionIndex = resultQuestions.findIndex(
      (q) => q.name === embeddedQuestion?.name
    );

    const title = getAnswerTitleForEmbeddedQuestion(answer, embeddedQuestion);

    if (translations !== undefined && translations?.length > index) {
      translations[index]?.translations?.forEach(translation => {
        if (translation && translation.id === embeddedQuestionIndex) {
          translation.id = answeredQuestionIndex;
          if (answer.title?.endsWith(":")) {
            translation.text = `${answer.title} ${translation.text}`;
          } else {
            translation.text = `${answer.title}: ${translation.text}`;
          }
        }
      });
    }

    return {
      ...answer,
      title,
    };
  });

  return {
    question: {
      ...answeredQuestion,
      answers: modifiedQuestions,
    },
    index: answeredQuestionIndex,
  };
};
