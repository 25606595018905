import { NavLink } from "react-router-dom";

import styles from "./TopNav.module.scss";

import Notification from "../notification/Notification";

import { NavData } from "~/typing/carePortalTypes";

type TopNavProps = {
  baseUrl?: string;
  navData?: NavData[];
  size?: "" | "sm" | "md" | "lg";
  className?: string;
  onLocaleSelect?: (locale: string) => void;
};

const TopNav = ({
  baseUrl,
  navData,
  size = "",
  className,
  onLocaleSelect
}: TopNavProps) => {
  const navItems = navData?.map((nav) => {
    if (!nav) return null;

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      if (nav.preventRouting) {
        event.preventDefault();
      }
      if (onLocaleSelect && nav.url) {
        onLocaleSelect(nav.url);
      }
    };

    return (
      <NavLink
        to={`${baseUrl}/${nav.url}`}
        className={({ isActive }) =>
          isActive ? `${styles.navItem} ${styles.active}` : styles.navItem
        }
        key={nav.url}
        onClick={handleClick}
      >
        <div className={styles.content}>
          {nav.iconSrc && (
            <img src={nav.iconSrc} className={styles.icon} alt={nav.title} />
          )}
          <div className={styles.title}>
            {nav.title}
            {!!nav.notificationCount && (
              <Notification
                size="xs"
                count={nav.notificationCount}
                className={styles.notification}
              />
            )}
          </div>
        </div>
        <div className={styles.underline} />
      </NavLink>
    );
  });

  const classes =
    `${styles.TopNav} ${className}` + (styles[size] ? ` ${styles[size]}` : "");

  return <div className={classes}>{navItems}</div>;
};

export default TopNav;
