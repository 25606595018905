import { isMedicareProgram } from "../program/programHelpers";

import config from "~/config";
import { Program } from "~/typing/sidekickTypes";

export function isLeadCoach(user: { privileges: number }) {
  return user?.privileges >= 15;
}

export function isMedicareLeadCoach(
  user: { privileges: number },
  programs: Program[]
) {
  if (!config.isAnthem) {
    return false;
  }
  const isMedicareCoach = !!(programs || []).find(isMedicareProgram);
  return isMedicareCoach && isLeadCoach(user);
}
