import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InformationPageHeaderCareTeam from "./info/InformationPageHeaderCareTeam";
import InformationPageHeaderLayers from "./info/InformationPageHeaderLayers";
import InformationPageHeaderPrograms from "./info/InformationPageHeaderPrograms";
import styles from "./InformationPageHeader.module.scss";

import InformationPageDeviceOrder from "../devices/InformationPageDeviceOrder";
import { InformationPageContext } from "../informationPageContext";
import EditProviderModal from "../modals/editProvider/EditProvider";

import pencilIcon from "~/assets/pencil.svg";
import CustomSelect from "~/components/select/CustomSelect";
import config from "~/config";
import { ExternalUserTypeBehaviour } from "~/constants/externalUserBehaviours";
import getExternalLinkOptions, {
  ExternalLinkOption
} from "~/helpers/getExternalLinkOptions";

type InformationPageHeaderProps = {
  className?: string;
};

const InformationPageHeader = ({ className }: InformationPageHeaderProps) => {
  const [filteredExternalLinks, setFilteredExternalLinks] = useState<
    ExternalLinkOption[]
  >([]);

  const { userDetail, externalUser, externalUserType, program } = useContext(
    InformationPageContext
  );

  const [editProviderModalOpen, setEditProviderModalOpen] = useState(false);

  const { t } = useTranslation();

  const isPrintPage = className?.includes("print");
  const isCancerProgram =
    externalUserType &&
    !!(externalUserType?.behaviour & ExternalUserTypeBehaviour.CancerProgram);

  useEffect(() => {
    const filteredExternalLinkOptions = getExternalLinkOptions(externalUser);
    setFilteredExternalLinks(filteredExternalLinkOptions);
  }, [externalUser]);

  if (!program) return null;

  return (
    <>
      <div className={className}>
        {!isPrintPage && (
          <div className={styles.coachContainer}>
            {externalUser && config.isAnthem && (
              <div
                className={styles.providerContainer}
                onClick={() => setEditProviderModalOpen(true)}
              >
                <span>
                  <strong>{t("provider.provider")}:</strong>{" "}
                  {(externalUser.providerName &&
                    externalUser.providerName.length > 0) ||
                  (externalUser.providerEmail &&
                    externalUser.providerEmail.length > 0) ? (
                    `${externalUser.providerName} ${externalUser.providerEmail}`
                  ) : (
                    <span className={styles.noProvider}>
                      {t("provider.assignProvider")}
                    </span>
                  )}
                </span>
                <img src={pencilIcon} />
              </div>
            )}
          </div>
        )}
        <InformationPageHeaderCareTeam />
        <InformationPageHeaderPrograms isPrintPage={isPrintPage} />
        <InformationPageHeaderLayers />

        <InformationPageDeviceOrder
          externalUser={externalUser}
          userDetails={userDetail}
        />

        {isCancerProgram && externalUser?.diseaseName && (
          <div className={styles.diseaseName}>
            <span>{`${t("registerPatient.cancerType")}: `}</span>
            {externalUser.diseaseName}
          </div>
        )}
        {!isPrintPage && filteredExternalLinks.length > 0 && (
          <div className={styles.externalLinks}>
            <CustomSelect
              placeholder={t("registerPatient.externalLinks")}
              options={filteredExternalLinks}
              renderOption={(option) => option.value}
            />
          </div>
        )}
      </div>
      {editProviderModalOpen && externalUser && (
        <EditProviderModal
          onClose={() => setEditProviderModalOpen(false)}
          externalUser={externalUser}
        />
      )}
    </>
  );
};

export default InformationPageHeader;
