import styles from "./PulseLoader.module.scss";

type PulseLoaderProps = {
  className?: string;
  inverted?: boolean;
};

const PulseLoader = ({ className, inverted }: PulseLoaderProps) => {
  const pulseClass = styles.pulse + (inverted ? ` ${styles.inverted}` : "");

  return (
    <div
      data-testid="pulse-loader"
      className={`${styles.wrapper} ${className ?? ""}`}
    >
      <span className={pulseClass} />
      <span className={pulseClass} />
      <span className={pulseClass} />
    </div>
  );
};

export default PulseLoader;
