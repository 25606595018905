import colors from "~/styles/colors";

type PencilIconProps = {
  color?: string;
};

const PencilIcon = ({ color = colors.lightBlue100 }: PencilIconProps) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.2933 1.20666C15.0662 0.980527 14.7966 0.801635 14.5 0.680313C14.2033 0.55899 13.8856 0.497642 13.5651 0.499811C13.2447 0.501981 12.9278 0.567625 12.6328 0.692953C12.3379 0.81828 12.0707 1.00081 11.8467 1.22999L2.18 10.8967L1 15.5L5.60333 14.3193L15.27 4.65266C15.4992 4.42875 15.6818 4.16161 15.8072 3.86671C15.9326 3.5718 15.9982 3.25496 16.0004 2.93452C16.0026 2.61408 15.9412 2.29639 15.8198 1.99981C15.6985 1.70323 15.5195 1.43365 15.2933 1.20666V1.20666Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5708 1.50665L14.9935 4.92932"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.18066 10.896L5.60666 14.316"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilIcon;
