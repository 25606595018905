import { useState } from "react";

import styles from "./ReassignUsersModal.module.scss";

import ExpandedCheckboxSelect from "../expandedCheckBoxSelect/ExpandedCheckboxSelect";
import SuccessScreen from "../success/SuccessScreen";

import Avatar from "~/components/avatar/Avatar";
import ModalWithFooterActions from "~/components/modal/ModalWithFooterActions";
import { useCareManagersAndInvites } from "~/hooks/graphql/useCareManagersAndInvites";
import { t } from "~/i18n";
import { CareManagerWithPcIs } from "~/typing/graphql/types";

type ReassignUsersModalProps = {
  onClose: () => void;
  selectedCareManager: CareManagerWithPcIs;
};

enum ReassignUsersStep {
  SelectUsers,
  SelectCareManagers,
  Preview,
  Success
}

const ReassignUsersModal = ({
  onClose,
  selectedCareManager
}: ReassignUsersModalProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedCareManagers, setSelectedCareManagers] = useState<string[]>(
    []
  );
  const { coaches } = useCareManagersAndInvites();
  const [reassignUsersStep, setReassignUsersStep] = useState<ReassignUsersStep>(
    ReassignUsersStep.SelectUsers
  );

  const convertCoachesToOptions = (careManagers: CareManagerWithPcIs[]) => {
    return careManagers.map(({ careManager }) => ({
      text: careManager?.name ?? careManager?.email ?? "",
      value: careManager?.email ?? "",
      renderOption: () => (
        <div className={styles.coachOption}>
          <Avatar
            user={{
              fullName: careManager?.name,
              imageHref: careManager?.imageHref
            }}
          />
          <span>{careManager?.name}</span>
        </div>
      )
    }));
  };

  const getBackButtonLabel = () => {
    if (
      reassignUsersStep === ReassignUsersStep.SelectCareManagers ||
      reassignUsersStep === ReassignUsersStep.Preview
    ) {
      return t("general.back");
    }
    return t("general.cancel");
  };

  const getNextButtonLabel = () => {
    if (reassignUsersStep === ReassignUsersStep.SelectUsers || reassignUsersStep === ReassignUsersStep.SelectCareManagers) {
      return t("general.next");
    } else if (reassignUsersStep === ReassignUsersStep.Preview) {
      return t("general.save");
    }
    return t("general.done");
  };

  const traverseStep = (direction: "forward" | "backward") => {
    if (direction === "forward") {
      if (reassignUsersStep === ReassignUsersStep.SelectUsers) {
        setReassignUsersStep(ReassignUsersStep.SelectCareManagers);
      } else if (reassignUsersStep === ReassignUsersStep.SelectCareManagers) {
        setReassignUsersStep(ReassignUsersStep.Preview);
      } else if (reassignUsersStep === ReassignUsersStep.Preview) {
        setReassignUsersStep(ReassignUsersStep.Success);
      } else {
        onClose();
      }
    } else {
      if (reassignUsersStep === ReassignUsersStep.SelectUsers) {
        onClose();
      } else if (reassignUsersStep === ReassignUsersStep.SelectCareManagers) {
        setReassignUsersStep(ReassignUsersStep.SelectUsers);
      } else if (reassignUsersStep === ReassignUsersStep.Preview) {
        setReassignUsersStep(ReassignUsersStep.SelectCareManagers);
      }
    }
  };

  const isNextButtonDisabled = () => {
    if (reassignUsersStep === ReassignUsersStep.SelectUsers) {
      return selectedUsers.length === 0;
    } else if (reassignUsersStep === ReassignUsersStep.SelectCareManagers) {
      return selectedCareManagers.length === 0;
    }
    return false;
  };

  return (
    <ModalWithFooterActions
      title="Reassign users"
      onClose={onClose}
      className={styles.modal}
      actions={[
        {
          label: getBackButtonLabel(),
          onClick: () => traverseStep("backward"),
          inverted: true,
          hidden: reassignUsersStep === ReassignUsersStep.Success
        },
        {
          label: getNextButtonLabel(),
          onClick: () => traverseStep("forward"),
          disabled: isNextButtonDisabled()
        }
      ]}
    >
      {reassignUsersStep === ReassignUsersStep.SelectUsers && (
        <ExpandedCheckboxSelect
          description={t("accessManagement.reassignUsersModal.selectUsersForReassign", {
            careManager: selectedCareManager.careManager?.name
          })}
          options={convertCoachesToOptions(coaches)}
          onChange={setSelectedUsers}
          selected={selectedUsers}
          placeholder={t("select.selectValues")}
        />
      )}
      {reassignUsersStep === ReassignUsersStep.SelectCareManagers && (
        <ExpandedCheckboxSelect
          description={t("accessManagement.reassignUsersModal.selectCareManagers", {
            userCount: selectedUsers.length
          })}
          options={convertCoachesToOptions(coaches)}
          onChange={setSelectedCareManagers}
          selected={selectedCareManagers}
          placeholder={t("select.selectValues")}
        />
      )}
      {reassignUsersStep === ReassignUsersStep.Success && (
        <SuccessScreen text={t("accessManagement.reassignUsersModal.successText")} />
      )}
    </ModalWithFooterActions>
  );
};

export default ReassignUsersModal;
