export enum Page {
  MyPrograms = "My Programs",
  ProgramView = "Program View",
  UserInformation = "User Information",
  FoodJournal = "User Food Journal",
  Missions = "User Missions",
  PhysicalPerformance = "Physical Performance",
  MentalPerformance = "Mental Performance",
  PRO = "User PRO",
  Documents = "User Documents",
  MyPage = "My Page",
  Unknown = "Unknown"
}

export enum View {
  User = "User",
  Users = "Users",
  Registration = "Registration",
  Weight = "Weight",
  Activity = "Activity",
  StepCounter = "Step Counter",
  FitnessTest = "Fitness Test",
  SleepQuality = "Sleep Quality",
  StressLevel = "Stress Level",
  EnergyLevel = "Energy Level",
  Text = "Text",
  Numerical = "Numerical",
  Unknown = "Unknown"
}

export enum FilteringType {
  UsersActivity = "UsersActivity",
  PRO = "PRO",
  PROStatus = "PRO Status",
  DiseaseTypes = "Disease Types",
  AssignedTo = "Assigned to",
  SMS = "SMS",
  UserType = "User Type",
  UsersRisk = "Users Risk",
  SymptomsStatuses = "Symptoms Statuses",
  Week = "Week",
  MemberState = "US State",
  PlanState = "Plan State",
  LOB = "Line of Business"
}

export enum Role {
  HealthAdvocate = "Health Advocate",
  SocialWorker = "Social Worker",
  HealthProgramRepresentative = "Health Program Representative",
  HealthCoach = "Health Coach",
  MyPharmacist = "My Pharmacist",
  MyDietitian = "My Dietitian",
  MyDietitianDiabetesEducation = "My Dietitian/Diabetes Education",
  CareNavigator = "Care Navigator",
  MyNavigator = "My Navigator",
  RegisteredNurse = "Registered Nurse",
  Other = "Other",
  N_A = "N/A"
}
