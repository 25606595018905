import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeyFactory } from "../queryKeysFactory";

import { addMessageForUser } from "~/api/requests/messageRequests";
import getMessageMarkdown from "~/helpers/getMessageMarkdown";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { logErrorToConsoleOnDev } from "~/helpers/util/util";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { MessageType } from "~/typing/sidekickTypes";

export const useSendMessage = ({
  programId,
  locale,
  userId,
  trackOnSend = true,
  wrapWithMarkdown = true
}: {
  programId: string;
  locale: string;
  userId: string;
  trackOnSend?: boolean;
  wrapWithMarkdown?: boolean;
}) => {
  const { trackSendMessage } = useAmplitudeTracking();
  const queryClient = useQueryClient();

  const { mutate, isError, isSuccess } = useMutation({
    mutationKey: ["sendMessage", programId, locale, userId],
    mutationFn: ({
      message,
      replyingToMessage,
      suggestionId
    }: {
      message: string;
      replyingToMessage?: MessageType;
      suggestionId?: string;
    }) =>
      addMessageForUser({
        programId,
        locale,
        userId,
        message: wrapWithMarkdown
          ? getMessageMarkdown(message.trim())
          : message.trim(),
        replyingToMessage,
        suggestionId
      }),
    onError: (error) => {
      logErrorToConsoleOnDev(error);
      displayErrorToast({
        message: t("errors.read", { resource: t("general.message") })
      });
    },
    onSuccess: (message) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.users.messages(programId, locale, userId)
      });
      if (trackOnSend) {
        trackSendMessage({
          message: message?.message?.trim() ?? "",
          messageSuggestionId: ""
        });
      }
    }
  });

  return {
    sendMessage: mutate,
    isError,
    isSuccess
  };
};
