import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import styles from "./ComplianceDetailModal.module.scss";

import DateRangeNavigator from "~/components/dateRangeNavigator/DateRangeNavigator";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import Modal from "~/components/modal/Modal";
import useComplianceUserDetail from "~/hooks/useApi/useComplianceUserDetail";
import { ProgramURLParams } from "~/typing/carePortalTypes";
import { ComplianceUser } from "~/typing/sidekickTypes";

type ComplianceDetailModalProps = {
  user: ComplianceUser;
  onClose: () => void;
};

const DAYS_TO_DISPLAY = 7;

const ComplianceDetailModal = ({
  user,
  onClose
}: ComplianceDetailModalProps) => {
  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();
  const { complianceUserDetail, isLoading } = useComplianceUserDetail(
    program_id,
    locale,
    user?.userId
  );
  const [currentFirstDay, setCurrentFirstDay] = useState(0);

  const { t } = useTranslation();
  const modalTitle =
    `Compliance: ${user?.displayName}` +
    (user?.phoneNumber ? ` (${user.phoneNumber})` : "");

  const calculateFirstDay = () => {
    if (!complianceUserDetail?.[0]) return null;

    const startDate = DateTime.fromISO(
      complianceUserDetail[0].programStartedOn
    ).set({ hour: 23, minute: 59 });

    const lastDay = Math.ceil(Math.abs(startDate.diffNow("days").days));

    const startDay =
      lastDay > DAYS_TO_DISPLAY ? lastDay - (DAYS_TO_DISPLAY - 1) : 0;

    setCurrentFirstDay(startDay);
  };

  useEffect(() => {
    calculateFirstDay();
  }, [complianceUserDetail]);

  const renderDotsRow = (days: Record<number, boolean>) => {
    return Array.from(Array(DAYS_TO_DISPLAY).keys()).map((i) => {
      const dayNumber = i + currentFirstDay;

      const completed = days[dayNumber + 1];

      if (completed === undefined) {
        return (
          <div className={styles.dotWrapper} key={dayNumber}>
            <div className={`${styles.dot}`} key={dayNumber}></div>
          </div>
        );
      }

      return (
        <div className={styles.dotWrapper} key={dayNumber}>
          <div
            className={`${styles.dot} ${
              completed ? styles.complete : styles.incomplete
            }`}
          />
        </div>
      );
    });
  };

  const renderDaysHeader = () => {
    if (!complianceUserDetail?.[0]) return null;

    return Array.from(Array(DAYS_TO_DISPLAY).keys()).map((i) => {
      const dayNumber = i + currentFirstDay;

      const startDate = DateTime.fromISO(
        complianceUserDetail[0].programStartedOn
      );

      const headerDate = startDate.plus({ days: dayNumber });

      return (
        <div className={styles.day} key={dayNumber}>
          <span>{headerDate.toLocaleString({ weekday: "short" })}</span>
          <span>{headerDate.toLocaleString({ day: "2-digit" })}</span>
        </div>
      );
    });
  };

  return (
    <Modal title={modalTitle} onClose={onClose} className={styles.modal}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {!complianceUserDetail?.[0] && (
            <div>
              <h3>No data available for the selected user</h3>
            </div>
          )}
          {!!complianceUserDetail?.[0] && (
            <>
              <div className={styles.navigation}>
                <DateRangeNavigator
                  startDate={
                    !!complianceUserDetail?.[0]?.programStartedOn
                      ? DateTime.fromISO(
                          complianceUserDetail?.[0].programStartedOn
                        )
                          .plus({ days: currentFirstDay })
                          .toISO() ?? ""
                      : ""
                  }
                  endDate={
                    !!complianceUserDetail?.[0]?.programStartedOn
                      ? DateTime.fromISO(
                          complianceUserDetail[0].programStartedOn
                        )
                          .plus({ days: currentFirstDay + DAYS_TO_DISPLAY - 1 })
                          .toISO() ?? ""
                      : ""
                  }
                  increaseDate={() => setCurrentFirstDay((prev) => prev + 1)}
                  decreaseDate={() =>
                    setCurrentFirstDay((prev) => (prev === 0 ? 0 : prev - 1))
                  }
                  onTodayClick={calculateFirstDay}
                />
              </div>
              <div className={styles.table}>
                <div className={styles.row}>
                  <div className={styles.proHeader}>
                    {t("compliancePage.modalHeader")}
                  </div>
                  <div className={styles.daysHeader}>{renderDaysHeader()}</div>
                </div>
                <div className={styles.rowsContainer}>
                  {complianceUserDetail?.map(
                    ({ surveyId, surveyTitle, days }) => {
                      return (
                        <div className={styles.row} key={surveyId}>
                          <div className={styles.surveyName}>{surveyTitle}</div>
                          <div className={styles.dotsRow}>
                            {renderDotsRow(days)}
                          </div>
                        </div>
                      );
                    }
                  )}
                  {complianceUserDetail?.length === 0 &&
                    t("compliancePage.noSurveys")}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ComplianceDetailModal;
