import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./InformationPageHeaderCareTeam.module.scss";
import InformationPageHeaderInfo from "./InformationPageHeaderInfo";

import AssignCareTeam from "../../assignCareTeam/AssignCareTeam";
import AssignCoachMenu from "../../assignCoachMenu/AssignCoachMenu";
import { InformationPageContext } from "../../informationPageContext";

import { assignCMToUser } from "~/api/requests/coachRequests";
import Avatar from "~/components/avatar/Avatar";
import CareTeam from "~/components/careTeam/CareTeam";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import { filterOutUndefined } from "~/helpers/array/arrayHelpers";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { useCoachesForUser } from "~/hooks/useApi/coaches/useCoachesForUser";
import { useProgramSpecialties } from "~/hooks/useApi/useProgramSpecialties";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";

const InformationPageHeaderCareTeam = () => {
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();
  const { userDetail: details, program, hasQuit, isFinished } = useContext(
    InformationPageContext
  );

  const {
    programSpecialties,
    isLoading: programSpecialtiesLoading
  } = useProgramSpecialties({
    programId: program_id,
    locale
  });

  const {
    coaches: careTeamCoaches,
    isLoading: careTeamLoading
  } = useCoachesForUser({
    programId: program_id,
    locale,
    userId: user_id
  });

  const [coachMenuOpen, setCoachMenuOpen] = useState(false);

  const { trackCoachAssigned } = useAmplitudeTracking();

  const { t } = useTranslation();

  const renderCoach = () =>
    details?.assignedCoach ? (
      <>
        <Avatar user={details.assignedCoach} className={styles.avatar} />
        <p>{details.assignedCoach.displayName}</p>
      </>
    ) : (
      t("user.coach.none")
    );

  const renderCareTeam = () => {
    const careTeam =
      careTeamCoaches
        ?.map((coach) =>
          program?.coaches.find((c) => c.userId === coach.coachId)
        )
        .filter(filterOutUndefined) ?? [];

    return (
      <CareTeam
        careTeam={
          careTeamCoaches?.map((coach) => ({
            coachUserId: coach.coachId,
            specialtyId: coach.specialtyId
          })) ?? []
        }
        coaches={careTeam}
        spreadOut
      />
    );
  };

  const renderHeader = () => {
    const isLoading = programSpecialtiesLoading || careTeamLoading;

    if (isLoading) return <PulseLoader inverted />;

    return (
      <div className={styles.coach}>
        {programSpecialties?.length ? renderCareTeam() : renderCoach()}
        {!(hasQuit || isFinished) && (
          <button
            data-testid="assign-to-coach-btn"
            onClick={() => setCoachMenuOpen(true)}
            className={styles.assignToCoachBtn}
            title="Assign a coach to this member"
          >
            {careTeamCoaches?.length
              ? t("general.edit")
              : t("user.coach.assign")}
          </button>
        )}
      </div>
    );
  };

  const onAssignCoach = async (newCoachId: string) => {
    toast.remove();

    return await assignCMToUser({
      coachId: newCoachId,
      programId: program_id,
      locale: locale,
      userId: user_id
    })
      .then(() => {
        const newCoach = program?.coaches?.find(
          (coach) => coach.userId === newCoachId
        );

        if (details && newCoach && newCoach.userId) {
          details.assignedCoach = newCoach;
          trackCoachAssigned();
          setCoachMenuOpen(false);
          displaySuccessToast({ message: t("user.coach.success") });
        }
        return true;
      })
      .catch(() => {
        displayErrorToast({ message: t("user.coach.error") });
        return false;
      });
  };

  return (
    <>
      <InformationPageHeaderInfo
        title={
          programSpecialties?.length
            ? t("programs.careTeam")
            : t("programs.coach")
        }
        renderDetails={renderHeader}
      />
      {coachMenuOpen && (
        <>
          {programSpecialties?.length ? (
            <AssignCareTeam onClose={() => setCoachMenuOpen(false)} />
          ) : (
            <AssignCoachMenu
              onClose={() => setCoachMenuOpen(false)}
              coaches={program?.coaches ?? []}
              currentCoach={details?.assignedCoach}
              onAssignCoach={onAssignCoach}
            />
          )}
        </>
      )}
    </>
  );
};

export default InformationPageHeaderCareTeam;
