export const isBetweenNumbers = ({
  value,
  lowerValue = Number.MIN_VALUE,
  upperValue = Number.MAX_VALUE
}: {
  value: number;
  lowerValue?: number;
  upperValue?: number;
}) => {
  return lowerValue <= value && value <= upperValue;
};
