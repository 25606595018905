import { DateTime } from "luxon";

import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";
import { PhysicalDataServer } from "~/typing/sidekickTypes";

export default function usePhysicalData({
  programId,
  locale,
  userId,
  startDate,
  endDate
}: {
  programId: string;
  locale: string;
  userId: string;
  startDate: DateTime;
  endDate: DateTime;
}) {
  const params = new URLSearchParams({
    startDate: startDate.toISO() ?? "",
    endDate: endDate.toISO() ?? ""
  });

  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/dailysummaryperiod?${params.toString()}`;

  const { data, isError, isLoading, invalidate } = useApi<{
    items: PhysicalDataServer[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.physicalActivity(
      userId,
      programId,
      locale,
      startDate?.toISO() ?? "",
      endDate?.toISO() ?? ""
    ),
    staleTime: TimeInMs.Minute * 3
  });

  const physicalData =
    data?.items
      .map((item) => {
        return {
          ...item,
          date: DateTime.fromISO(item.date, { zone: "utc" })
        };
      })
      .reverse() ?? [];

  return {
    physicalData,
    isError,
    isLoading,
    invalidate
  };
}
