import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { Mission } from "~/typing/sidekickTypes";

export default function useMissions() {
  const path = "/admin/missions";
  const { data, isError, isLoading, invalidate } = useApi<{
    items: Mission[];
  }>({
    path,
    queryKey: QueryKeyFactory.missions,
    staleTime: Infinity
  });
  return {
    missions: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
