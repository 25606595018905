import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";
import { UserDetails, UserWeekPoints } from "~/typing/sidekickTypes";

export default function useUserLastWeekPoints(
  programCatalogItemId: string,
  locale: string,
  userId?: string,
  userDetails?: UserDetails
) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/users/${userId}/lastWeeksPoints`;
  const { data, isError, isLoading, invalidate } = useApi<UserWeekPoints>({
    path,
    queryKey: QueryKeyFactory.users.lastWeekPoints(
      programCatalogItemId,
      locale,
      userId ?? ""
    ),
    staleTime: TimeInMs.Minute * 15,
    disabled: !!userDetails || !userId
  });

  return {
    lastWeekPoints: data || {
      foodPoints: userDetails?.foodPointsLastSevenDays ?? 0,
      mindPoints: userDetails?.mindPointsLastSevenDays ?? 0,
      movePoints: userDetails?.movePointsLastSevenDays ?? 0
    },
    isError,
    isLoading,
    invalidate
  };
}
