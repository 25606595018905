import colors from "~/styles/colors";

type RefreshProps = {
  color?: string;
};

export default function Close({ color = colors.black }: RefreshProps) {
  return (
    <svg
      viewBox="0 0 140 140"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      stroke={color}
      height="140"
      width="140"
    >
      <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
        <path
          d="M5.25 14.248v4.5H.75m18-9v-4.5h4.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M19.032 5.245A9.752 9.752 0 0 1 8.246 21m-3.279-2.249A9.753 9.753 0 0 1 15.754 3"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
}
