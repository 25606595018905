import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Avatar.module.scss";

import Image from "~/components/image/Image";
import { AvatarUser } from "~/typing/carePortalTypes";

type AvatarProps = {
  user?: AvatarUser;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "mdplus";
  shape?: "rounded" | "circle" | "square";
  shadow?: boolean;
  transparent?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  showNameOnHover?: boolean;
  displayTitle?: boolean;
  title?: string;
  wrapperClassName?: string;
};

const Avatar = ({
  user,
  size = "md",
  shape = "circle",
  shadow,
  transparent,
  children,
  className,
  onClick,
  showNameOnHover,
  displayTitle,
  title,
  wrapperClassName
}: AvatarProps) => {
  const { t } = useTranslation();
  const [showName, setShowName] = useState(false);
  const [imageHasError, setImageHasError] = useState(false);

  const classes =
    styles.Avatar +
    ` ${className ?? ""}` +
    ` ${styles[size] ?? ""}` +
    ` ${styles[shape] ?? ""}` +
    ` ${transparent ? styles.transparent : ""}` +
    ` ${shadow ? styles.shadow : ""}`;

  const onMouseEnter = () => {
    if (showNameOnHover) {
      setShowName(true);
    }
  };

  const initials = user?.fullName
    ?.split(" ")
    .map((name: string) => (name.length > 0 ? name[0].toUpperCase() : ""))
    .splice(0, 2)
    .join("");

  //if user.role is wicked long, cut it off at 40 characters and add "..."
  const userRole =
    user?.role && user?.role.length > 43
      ? user?.role.slice(0, 40) + "..."
      : user?.role;

  return (
    <div
      className={`${styles.container} ${wrapperClassName ?? ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => setShowName(false)}
    >
      <div
        data-initials={imageHasError || !user?.imageHref ? initials : undefined}
        className={classes}
        onClick={onClick}
        title={title}
        data-testid="avatar-container"
      >
        {user?.imageHref && !imageHasError && (
          <Image
            imageUrl={user?.imageHref}
            className={styles.image}
            onImageError={() => setImageHasError(true)}
          />
        )}
        {children}
      </div>
      {displayTitle && (
        <p title={user?.fullName} data-testid="avatar-display-name">
          {user?.fullName || t("programUserList.coachFilter.unassigned")}
          <br />
          {userRole && <span className={styles.subtitle}>{userRole}</span>}
        </p>
      )}
      {showName && (
        <div data-testid="avatar-tooltip" className={styles.tooltip}>
          {user?.fullName || t("programUserList.coachFilter.unassigned")}
        </div>
      )}
    </div>
  );
};

export default Avatar;
