import round from "../number/round";

import {
  getHighestValuePropertyInArray,
  getLowestValuePropertyInArray
} from "~/helpers/array/arrayHelpers";
import bloodSugarToImperial from "~/helpers/units/bloodSugarToImperial";
import mmolToMg from "~/helpers/units/mmolToMg";
import { t } from "~/i18n";
import colors from "~/styles/colors";
import { BloodSugarLineData } from "~/typing/carePortalTypes";
import { BloodSugarData } from "~/typing/sidekickTypes";

export type BloodSugarInfoData = {
  title: string;
  cellColor: string;
  cellTitle: string;
  value: number;
};

export const getCellData = (data: BloodSugarLineData | undefined) => {
  if (!data) return { cellColor: "", cellTitle: "" };

  switch (data?.rating) {
    case 1:
      return {
        cellTitle: t("user.health.status.normal"),
        cellColor: colors.neonGreen40
      };
    case 2:
      return {
        cellTitle: t("user.health.status.prediabetes"),
        cellColor: colors.mustardYellow40
      };
    case 3:
      return {
        cellTitle: t("user.health.status.diabetes"),
        cellColor: colors.red40
      };
    default:
      return {
        cellTitle: "",
        cellColor: ""
      };
  }
};

export const getAnalyzedBloodSugarData = (
  bloodSugarData: BloodSugarLineData[]
): BloodSugarInfoData[] => {
  const highestBloodSugar = getHighestValuePropertyInArray(
    bloodSugarData,
    "value"
  );
  const lowestBloodSugar = getLowestValuePropertyInArray(
    bloodSugarData,
    "value"
  );

  return [
    {
      title: t("user.health.bloodSugar.highestBloodSugar"),
      value: round(highestBloodSugar?.value, 1) ?? 0,
      ...getCellData(highestBloodSugar)
    },
    {
      title: t("user.health.bloodSugar.lowestBloodSugar"),
      value: round(lowestBloodSugar?.value, 1) ?? 0,
      ...getCellData(lowestBloodSugar)
    }
  ];
};

export const mapBloodSugarResponse = (
  bloodSugarResponse: BloodSugarData[],
  useMetric: boolean,
  type: string
): BloodSugarData[] => {
  if (!useMetric && type === "hba1c") {
    bloodSugarResponse.map((item) => {
      const imperialValue = bloodSugarToImperial(item.value);
      if (imperialValue) {
        item.value = imperialValue;
      }
    });
  } else if (type === "fasting" || type === "meal") {
    bloodSugarResponse.map((item) => {
      const mgValue = mmolToMg(item.value);
      if (mgValue) {
        item.value = mgValue;
      }
    });
  }
  return bloodSugarResponse;
};
