import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./Breadcrumbs.module.scss";

import BackIcon from "~/assets/back.svg";

type BreadcrumbsProps = {
  linkData: {
    url?: string;
    text: string;
  }[];
};

const Breadcrumbs = ({ linkData }: BreadcrumbsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Link to="/">
        <img src={BackIcon} className={styles.backIcon} alt="back" />
        <p>{t("programs.title", "Programs")}</p>
      </Link>
      {linkData?.map(({ url, text }, index) => (
        <Fragment key={`fragment-${index}`}>
          <p>{">"}</p>
          {url ? (
            <Link to={url}>
              <p>{text}</p>
            </Link>
          ) : (
            <p>{text}</p>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
