import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { RootState } from "~/state/store";

const memoizedSelector = createSelector(
  [(state) => state.customList.pagination ?? {}],
  (customList) => customList
);

export default function useListPagination(key: string) {
  const pagination = useSelector<
    RootState,
    {
      [programId: string]: {
        currentPage: number;
      };
    }
  >((state) => memoizedSelector(state));

  return pagination[key] ?? { currentPage: 1 };
}
