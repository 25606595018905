import { getTranslations } from "~/api/graphql/translations";
import { TranslationsDto, TranslationsDtoInput } from "~/typing/graphql/types";

// Async function to fetch translations
export const fetchTranslations = async ({
  translationsInput,
  targetLanguage,
  sourceLanguage
}: {
  translationsInput: TranslationsDtoInput[];
  targetLanguage: string;
  sourceLanguage: string;
}): Promise<TranslationsDto[]> => {
  try {
    // Fetch translations for each input in parallel
    const results = await Promise.all(
      translationsInput.map((inputArray) =>
        getTranslations(targetLanguage, inputArray, sourceLanguage)
      )
    );

    return results;
  } catch (error) {
    console.error("Error fetching translations:", error);
    throw error;
  }
};