import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { z } from "zod";

import styles from "./Profile.module.scss";
import ProfilePic from "./ProfilePic";

import CustomPhoneInput from "~/components/customPhoneInput/CustomPhoneInput";
import Input from "~/components/input/Input";
import CustomSelect from "~/components/select/CustomSelect";
import config from "~/config";
import locales from "~/constants/locales";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { getPrivacyUrl, getTermsUrl } from "~/helpers/legalUrls";
import {
  useAuthUserSpecialty,
  useSpecialties
} from "~/hooks/useApi/specialties/useSpecialties";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import { EnrichedAuthenticatedUser } from "~/typing/sidekickTypes";

const PROFILE_NICK_MAX_LENGTH = 30;

type ProfileProps = {
  user: EnrichedAuthenticatedUser;
  settings: { [key: string]: string };
  onUpdate: (props: {
    nick: string;
    locale: string;
    useMetric: boolean;
    title?: string;
    specialtyId?: string;
    phoneNumber: string;
  }) => void;
  isLoading?: boolean;
  hasError?: boolean;
};

const defaultErrorState = {
  nick: "",
  locale: "",
  useMetric: "",
  title: "",
  specialty: "",
  phoneNumber: ""
};

const Profile = ({
  user,
  settings,
  onUpdate,
  isLoading,
  hasError
}: ProfileProps) => {
  const { t } = useTranslation();
  const supportedLocales = ["en", "is"];

  const originalNick = user.nick || user.displayName || user.name;
  const [nick, setNick] = useState(originalNick);
  const [specialtyId, setSpecialtyId] = useState<string>();
  const [useMetric, setUseMetric] = useState(
    Boolean(settings["useMetric"] === undefined || settings["useMetric"])
  );
  const [title, setTitle] = useState(user.title);
  const [locale, setLocale] = useState(user.locale);
  const [phoneNumber, setPhoneNumber] = useState(settings["phoneNumber"]);

  const [errors, setErrors] = useState(defaultErrorState);

  const selectableLocales = locales.filter((l) =>
    supportedLocales.includes(l.locale)
  );

  const { isEnabled: careTeamFeatureRoleEnabled } = useFeatureToggle({
    featureName: SegmentationKey.CareTeamRole
  });

  const {
    specialties: possibleSpecialties,
    isLoading: specialtiesLoading
  } = useSpecialties({ disabled: !careTeamFeatureRoleEnabled });
  const {
    data: authUserSpecialty,
    isLoading: authUserSpecialtyLoading
  } = useAuthUserSpecialty({
    authUserId: user.id || user.userId || "",
    enabled: careTeamFeatureRoleEnabled
  });

  useEffect(() => {
    setUseMetric(
      Boolean(settings["useMetric"] === undefined || settings["useMetric"])
    );
    setPhoneNumber(settings["phoneNumber"]);
  }, [settings]);

  useEffect(() => {
    if (!authUserSpecialty) return;

    setSpecialtyId(authUserSpecialty.specialtyId);
  }, [authUserSpecialty]);

  const profileSchema = z
    .object({
      nick: z
        .string({ required_error: t("errors.requiredField") })
        .min(1, { message: t("errors.requiredField") })
        .max(30),
      locale: z.string({ required_error: t("errors.requiredField") }).min(1),
      useMetric: z.boolean({ required_error: t("errors.requiredField") }),
      specialty: z
        .string()
        .min(1, { message: t("errors.requiredField") })
        .max(2),
      phoneNumber: z
        .string()
        .refine(isPossiblePhoneNumber, {
          message: t("profile.enterValidPhonenumber")
        })
        .optional()
    })
    .partial({
      specialty:
        !config.isAnthem || !careTeamFeatureRoleEnabled ? true : undefined
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const response = profileSchema.safeParse({
      nick,
      locale,
      useMetric,
      title,
      specialty: specialtyId,
      phoneNumber
    });

    if (response.success) {
      setErrors(defaultErrorState);
      onUpdate({
        nick,
        locale,
        useMetric,
        title,
        specialtyId,
        phoneNumber
      });
    } else {
      const tempErrors = { ...defaultErrorState };
      response.error.issues.forEach((issue) => {
        tempErrors[issue.path[0]] = issue.message;
      });

      setErrors(tempErrors);
    }
  };

  if (
    careTeamFeatureRoleEnabled === undefined ||
    specialtiesLoading ||
    authUserSpecialtyLoading
  )
    return null;

  return (
    <div>
      <ProfilePic user={user} />

      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          type="text"
          value={nick}
          placeHolder={t("profile.name_placeholder")}
          onChange={setNick}
          disabled={isLoading}
          maxLength={PROFILE_NICK_MAX_LENGTH}
          error={errors.nick}
          label={t("profile.name")}
          className={styles.input}
          id="nick"
        />
        {config.isAnthem && careTeamFeatureRoleEnabled ? (
          <CustomSelect
            label={t("profile.role")}
            options={
              possibleSpecialties
                ?.filter((specialty) => !specialty.deleted)
                ?.map((specialty) => ({
                  value: specialty.id,
                  text: specialty.localizedName
                })) ?? []
            }
            renderOption={(option) => option?.text}
            value={specialtyId}
            placeholder={t("profile.rolePlaceholderSelect")}
            onChange={(e) => setSpecialtyId(e.target.value)}
            disabled={isLoading}
            error={errors.specialty}
            dataTestId="profile-role"
            className={
              !authUserSpecialty?.specialtyId && !specialtyId
                ? styles.errorBorderDropdown
                : styles.dropdown
            }
          />
        ) : (
          <Input
            type="text"
            value={title}
            placeHolder={t("profile.rolePlaceholder")}
            onChange={setTitle}
            maxLength={30}
            error={errors.title}
            label={t("profile.role")}
            className={styles.input}
            id="role"
          />
        )}

        {config.allowLanguageSelection && (
          <CustomSelect
            label={t("general.language", "Language")}
            value={locale}
            valueKey="locale"
            onChange={(e) => setLocale(e.target.value)}
            disabled={isLoading}
            renderOption={(option) => option?.name}
            options={selectableLocales}
            placeholder={t("select.language")}
            noOptions={t("select.noLanguages")}
            error={errors.locale}
            dataTestId="profile-language"
          />
        )}

        {config.allowUserConfigurableUnits && (
          <div>
            <p className="label label--block">
              {t("profile.units_label", "Units")}
            </p>
            <fieldset
              id="units"
              className={`${styles.units} form__horizontal-section`}
            >
              <input
                id="units-radio-imperial"
                type="radio"
                onChange={() => setUseMetric(false)}
                checked={!useMetric}
                disabled={isLoading}
              />
              <label htmlFor="units-radio-imperial">
                {t("profile.units_imperial", "Imperial (lbs / mi)")}
              </label>
              <input
                id="units-radio-metric"
                type="radio"
                checked={useMetric}
                onChange={() => setUseMetric(true)}
                disabled={isLoading}
              />
              <label htmlFor="units-radio-metric">
                {t("profile.units_metric", "Metric (kg / km)")}
              </label>
            </fieldset>
          </div>
        )}

        <CustomPhoneInput
          label={t("general.phoneNumber", "Phone number")}
          useCountrySelect={!config.isAnthem}
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry={config.isAnthem ? "US" : settings["country"]}
        />

        {errors.phoneNumber && (
          <p className={`${styles.phoneError} input__error-block`}>
            {errors.phoneNumber}
          </p>
        )}
        {hasError && !isLoading && (
          <p className={"input__error-block"}>
            {t("profile.error", "Error saving profile")}
          </p>
        )}

        <div className="form__actions">
          <input
            type="submit"
            className={"btn-primary"}
            value={t("profile.save")}
            disabled={isLoading}
          />
        </div>
      </form>
      <div className={styles.legal}>
        <a href={getTermsUrl()} target="_blank" rel="noopener noreferrer">
          {t("signup.terms")}
        </a>
        <a href={getPrivacyUrl()} target="_blank" rel="noopener noreferrer">
          {t("signup.privacyPolicy")}
        </a>
      </div>
    </div>
  );
};

export default Profile;
