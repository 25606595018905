import { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useParams, Outlet } from "react-router-dom";

import groupsReducer, { GroupActions } from "./groupsReducer";
import InviteCoach from "./InviteCoach";
import TeamNav from "./TeamNav";
import styles from "./TeamPage.module.scss";
import { TeamPageRouteContext } from "./teamPageContext";

import api from "~/api/api";
import UserFlag from "~/assets/user-flag.svg";
import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { UserPrivileges } from "~/constants/privileges";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import useInvites from "~/hooks/useApi/useInvites";
import useProgram from "~/hooks/useApi/useProgram";
import useUser from "~/hooks/useUser";

export default function TeamPage() {
  const [showAddCoach, setShowAddCoach] = useState(false);
  const [groups, groupsDispatch] = useReducer(groupsReducer, []);
  const [coachesLoading, setCoachesLoading] = useState(false);
  const { program_id = "", locale = "" } = useParams();
  const { t } = useTranslation();
  const { user } = useUser();

  const {
    invites,
    isLoading: invitesLoading,
    invalidate: invalidateInvites
  } = useInvites(program_id, locale);

  const hasLeadCoachPrivileges =
    parseInt(user?.privileges ?? "0") >= UserPrivileges.LeadCoach;

  const { program } = useProgram({ programCatalogItemId: program_id, locale });

  const getGroups = async () => {
    setCoachesLoading(true);
    const groupsRes = await api.get(
      `/coach/programs/${program_id}/locales/${locale}/groups`
    );

    if (groupsRes?.status === 200 || groupsRes?.status === 204) {
      const groups = await Promise.all(
        groupsRes?.data?.items?.map(async (group) => {
          const coachRes = await api.get(`/coach/users/${group.coachUserId}`);
          return {
            ...group,
            coach: coachRes?.data
          };
        })
      );

      groupsDispatch({ type: GroupActions.Fetch, payload: groups || [] });
    }
    setCoachesLoading(false);
  };
  useEffect(() => {
    getGroups();
  }, []);

  const updateGroups = async (groupId, payload) => {
    const groupRes = await api.put(
      `/coach/programs/${program_id}/locales/${locale}/groups/${groupId}`,
      payload
    );
    const group = groupRes?.data;

    if (group?.coachUserId) {
      const coachRes = await api.get(`/coach/users/${group.coachUserId}`);
      group.coach = coachRes?.data;
    }

    if (group && group.coach) {
      groupsDispatch({ type: GroupActions.Update, payload: group });
      return true;
    } else {
      return false;
    }
  };

  const onDeleteInvite = async (id) => {
    await api
      .delete(
        `/coach/programcatalogitems/${program_id}/locales/${locale}/invites/${id}`
      )
      .then(() => {
        invalidateInvites();
        displaySuccessToast({ message: t("team.deleteInviteSuccess") });
      })
      .catch(() => displayErrorToast({ message: t("team.deleteInviteError") }));
  };

  return (
    <SentryErrorBoundary transactionName="TeamPage">
      <Layout>
        <Helmet title={t("team.myTeam", "My team")} defer={false} />
        <div className={styles.header}>
          <img src={UserFlag} />
          <h1 className={styles.title}>{t("team.myTeam", "My team")}</h1>
          <div className={styles.actions}>
            {hasLeadCoachPrivileges && (
              <button
                type="button"
                className="btn-secondary btn-sm"
                onClick={() => setShowAddCoach(true)}
              >
                {t("team.addCoach", "Add coach")}
              </button>
            )}
          </div>
        </div>
        <Link
          to={`/program/${program_id}/locale/${locale}`}
          className={styles.back}
        >
          {t("team.backToProgram")}
        </Link>
        <div className={styles.navContainer}>
          <TeamNav />
        </div>
        <Outlet
          context={
            {
              team: groups,
              authUser: user,
              updateGroups,
              hasLeadCoachPrivileges,
              coaches: program?.coaches ?? [],
              isLoading: coachesLoading,
              invites,
              onDeleteInvite,
              loading: invitesLoading
            } as TeamPageRouteContext
          }
        />

        {showAddCoach && (
          <InviteCoach
            onClose={() => setShowAddCoach(false)}
            role={"role_care_manager"}
            programId={program_id}
            locale={locale}
            invalidateInvites={invalidateInvites}
          />
        )}
      </Layout>
    </SentryErrorBoundary>
  );
}
