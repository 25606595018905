import { Trans } from "react-i18next";

import styles from "./ExpandedCheckboxSelect.module.scss";

import PulseLoader from "~/components/pulseLoader/PulseLoader";
import CustomCheckBoxSelect from "~/components/select/CustomCheckBoxSelect";
import { t } from "~/i18n";
import { SelectOption } from "~/typing/carePortalTypes";

type ExpandedCheckboxSelectProps = {
  description?: string;
  options: SelectOption[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  selected?: string[];
  loading?: boolean;
  className?: string;
};

const ExpandedCheckboxSelect = ({
  description,
  options,
  onChange,
  placeholder,
  selected = [],
  loading,
  className
}: ExpandedCheckboxSelectProps) => {
  return (
    <div className={styles.wrapper}>
      {loading && <PulseLoader className={styles.loader} inverted />}
      {!loading && (
        <>
          <Trans
          components={{strong: <strong />}}>
            <p>{description}</p>
          </Trans>
          <CustomCheckBoxSelect
            expanded
            options={options}
            placeholder={placeholder || t("select.placeholder")}
            onChange={onChange}
            allowSearch
            checkboxWrapperClassName={styles.checkboxWrapper}
            selected={selected}
            className={className}
            wrapperClassName={styles.selectWrapper}
          />
        </>
      )}
    </div>
  );
};

export default ExpandedCheckboxSelect;
