import { DateTime } from "luxon";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  LabelList,
  Legend,
  LegendType,
  ComposedChart,
  ReferenceLine
} from "recharts";

import styles from "./CustomBarChart.module.scss";
import CustomTooltip from "./CustomTooltip";
import CustomXAxisTick from "./CustomXAxisTick";

import filterDataByWeekOrMonth from "~/helpers/date/filterDataByWeekOrMonth";
import round from "~/helpers/number/round";
import colors from "~/styles/colors";

type CustomBarChartData = {
  thisWeek?: number;
  lastWeek?: number;
  weekBefore?: number;
  date: DateTime;
  weight?: number;
  isFromScale?: boolean;
};

type CustomBarChartProps = {
  data: CustomBarChartData[];
  today: DateTime;
  units: string;
  useWeek?: boolean;
  dataKeys: string[];
  allDataKeys: string[];
  useTimeFilter?: boolean;
  endDate?: DateTime;
  getColorForBar?: (data: any, key: string) => string;
  legendData?: {
    value: string;
    color: string;
    type: LegendType;
    id: string;
  }[];
  baseLine?: number;
};

const CustomBarChart = ({
  data,
  today,
  units,
  useWeek = true,
  dataKeys,
  allDataKeys,
  useTimeFilter = true,
  endDate = DateTime.utc(),
  getColorForBar,
  legendData,
  baseLine
}: CustomBarChartProps) => {
  const barColors = [
    colors.lightBlue40,
    colors.mustardYellow40,
    colors.neonGreen40
  ];
  const todayBarColors = [
    colors.lightBlue100,
    colors.mustardYellow100,
    colors.neonGreen100
  ];

  const dateLabel = (date: DateTime): string | number => {
    if (useTimeFilter && !useWeek) {
      return date.day;
    }

    if (useWeek) {
      return date.weekdayShort ?? "";
    }

    return date.toLocaleString({
      hour: "numeric",
      minute: "numeric",
      month: "short",
      day: "numeric"
    });
  };

  const sortData = (data: CustomBarChartData[]) => {
    return data?.sort((a, b) => {
      return a.date.toMillis() - b.date.toMillis();
    });
  };

  const prepareTimeData = () => {
    const filteredData = filterDataByWeekOrMonth(data, useWeek, endDate);
    const reducedData: { date: DateTime }[] = [];
    let dailyReadings: { date: DateTime }[] = [];

    const sortedData = sortData(filteredData);

    // reduce datapoints for same day to averages
    if (sortedData.length > 0) {
      let currentDate = sortedData[0].date;
      for (let i = 0; i <= sortedData.length; i++) {
        const reading = sortedData[i];
        if (
          reading === undefined ||
          reading.date.toISODate() !== currentDate.toISODate()
        ) {
          const newPoint = { date: currentDate };

          allDataKeys.forEach((key) => {
            const avg = round(
              dailyReadings.reduce((r, a) => r + a[key], 0) /
                dailyReadings.length,
              1
            );
            newPoint[key] = avg;
          });

          reducedData.push(newPoint);
          dailyReadings = [];
          if (reading !== undefined) {
            currentDate = reading.date;
          }
        }

        if (reading !== undefined) {
          dailyReadings.push(reading);
        }
      }
    }

    // Fill days with empty readings
    const days = useWeek ? 7 : endDate.daysInMonth;
    if (!days) return reducedData;
    for (let i = 0; i < days; i++) {
      const newDay = endDate.minus({ days: useWeek ? i : endDate.day - 1 - i });
      const hasReadingForDay = reducedData.find((reading) => {
        return reading.date.toISODate() === newDay.toISODate();
      });

      if (!hasReadingForDay) {
        const newReading = { date: newDay };
        dataKeys.forEach((key) => {
          newReading[key] = 0;
        });
        reducedData.push(newReading);
      }
    }

    return reducedData;
  };

  const getFilteredData = () => {
    const sortedData = sortData(useTimeFilter ? prepareTimeData() : data);

    return sortedData.map((reading) => {
      return {
        ...reading,
        label: dateLabel(reading.date),
        baseLine: baseLine
      };
    });
  };

  const filteredData = getFilteredData();

  const axisLine = {
    stroke: colors.navyBlue20,
    strokeWidth: 2
  };

  const defaultColorForBar = (entry, key) => {
    const isToday = entry.date.toISODate() === today.toISODate();
    return isToday
      ? todayBarColors[allDataKeys.indexOf(key)]
      : barColors[allDataKeys.indexOf(key)];
  };

  return (
    <div className={styles.CustomBarChart}>
      <div className={styles.units}>{units}</div>

      <ResponsiveContainer>
        <ComposedChart
          data={filteredData}
          barSize={filteredData.length <= 7 ? 32 : 8}
          margin={{ top: 20, bottom: 10 }}
        >
          {baseLine && (
            <ReferenceLine
              stroke={colors.darkBlue100}
              strokeWidth={2}
              y={baseLine}
              alwaysShow={true}
            />
          )}
          <CartesianGrid stroke={colors.navyBlue10} vertical={false} />
          <XAxis
            dataKey="label"
            axisLine={axisLine}
            tickLine={false}
            tick={
              <CustomXAxisTick
                todayLabel={dateLabel(today)}
                wrapLabels={!useTimeFilter}
              />
            }
          />
          <YAxis
            axisLine={axisLine}
            tickLine={false}
            tick={{ fill: colors.navyBlue50 }}
          />
          <Tooltip
            content={<CustomTooltip units={units} allDataKeys={allDataKeys} />}
            cursor={{ fill: "#ccecff", opacity: "0.2" }}
          />
          {legendData && (
            <Legend
              verticalAlign="top"
              height={48}
              iconType="circle"
              payload={legendData}
            />
          )}
          {dataKeys.map((key) => {
            return (
              <Bar dataKey={key} key={key} radius={[4, 4, 0, 0]}>
                {useWeek && (
                  <LabelList
                    valueAccessor={(data) => data[key]}
                    position="top"
                  />
                )}
                {filteredData.map((dataPoint, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        getColorForBar
                          ? getColorForBar(dataPoint, key)
                          : defaultColorForBar(dataPoint, key)
                      }
                    />
                  );
                })}
              </Bar>
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomBarChart;
