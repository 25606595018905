import { useState } from "react";

import styles from "./UserStatuses.module.scss";

import { getUserStatuses } from "../../ProgramUsersPage.helpers";

import colors from "~/styles/colors";
import { CoachUser, Program } from "~/typing/sidekickTypes";

type UserStatus = {
  value: string;
  text?: string;
  color: string;
  fontColor?: string;
};

type UserStatusesProps = {
  user: CoachUser;
  program?: Program;
  chosenStatuses?: string[];
};
const UserStatuses = ({ user, program, chosenStatuses }: UserStatusesProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const userStatuses = getUserStatuses(user, program);

  const indexOfFinishedStatus = userStatuses.findIndex(
    (status) => status.value === "finished" || status.value === "drop-out"
  );

  let statusesToFilter: UserStatus[];

  if (indexOfFinishedStatus > -1) {
    statusesToFilter = [userStatuses[indexOfFinishedStatus]];
  } else {
    statusesToFilter = userStatuses.filter((status) =>
      chosenStatuses?.includes(status.value)
    );
  }

  return (
    <div className={styles.userStatuses}>
      {statusesToFilter?.length > 2 ? (
        <>
          <div
            style={{
              backgroundColor: userStatuses[0].color,
              color: userStatuses[0].fontColor ?? colors.navyBlue100
            }}
            className={styles.userStatus}
            title={statusesToFilter[0].text}
          >
            {statusesToFilter[0].text}
          </div>
          <div
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            style={{
              backgroundColor: colors.neonGreen140,
              color: colors.white,
              borderRadius: "50%",
              padding: "0 0.3125rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            className={styles.userStatus}
          >
            +{statusesToFilter.length - 1}
          </div>
          {showTooltip && (
            <div className={styles.tooltip}>
              {statusesToFilter.slice(1).map((status, index) => (
                <p key={`extra-status-${index}-${user.id}`}>{status.text}</p>
              ))}
            </div>
          )}
        </>
      ) : (
        statusesToFilter.map((status, index) => (
          <div
            style={{
              backgroundColor: status.color,
              color: status.fontColor ?? colors.navyBlue100
            }}
            key={`user-status-${user.id}-${index}`}
            className={styles.userStatus}
            title={status.text}
          >
            {status.text}
          </div>
        ))
      )}
    </div>
  );
};

export default UserStatuses;
