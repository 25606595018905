import { createLinkMatcherWithRegExp } from "@lexical/react/LexicalAutoLinkPlugin";

import { emailRegex, phoneRegex, urlRegex } from "~/helpers/getMessageMarkdown";

export const URL_MATCHERS = [
  createLinkMatcherWithRegExp(urlRegex, (text) => {
    return text;
  }),
  createLinkMatcherWithRegExp(emailRegex, (text) => {
    return `mailto:${text}`;
  }),
  createLinkMatcherWithRegExp(phoneRegex, (text) => {
    return `tel:${text}`;
  })
];

const urlRegExp = new RegExp(urlRegex);

export const validateUrl = (url: string): boolean => {
  return url === "https://" || urlRegExp.test(url);
};
