import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { QueryKeyFactory } from "../queryKeysFactory";

import {
  submitAdHocSMS,
  submitSMSInvite
} from "~/api/requests/messageRequests";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { logErrorToConsoleOnDev } from "~/helpers/util/util";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { ExternalUser } from "~/typing/sidekickTypes";

export const useSendAdHocSMS = ({
  programId,
  locale,
  externalUser
}: {
  programId: string;
  locale: string;
  externalUser?: ExternalUser;
}) => {
  const { trackSendSMS } = useAmplitudeTracking();
  const queryClient = useQueryClient();

  const { mutate, isError, isSuccess } = useMutation({
    mutationKey: [
      "sendSmsMessage",
      programId,
      locale,
      externalUser?.externalUserTypeId,
      externalUser?.id
    ],
    mutationFn: ({ message }: { message: string }) =>
      submitAdHocSMS(message, externalUser),
    onError: (err) => {
      logErrorToConsoleOnDev(err);
      displayErrorToast({ message: t("errors.generic") });
    },
    onSuccess: (message) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.users.sms(
          programId,
          locale,
          externalUser?.externalUserTypeId ?? "",
          externalUser?.id ?? ""
        )
      });
      trackSendSMS(message ?? "");
    }
  });

  return {
    sendSmsMessage: mutate,
    isError,
    isSuccess
  };
};

export const useSendInviteSms = ({
  programId,
  locale,
  externalUser
}: {
  programId: string;
  locale: string;
  externalUser: ExternalUser;
}) => {
  const { trackNewInviteSMSSent } = useAmplitudeTracking();
  const queryClient = useQueryClient();

  const [currentMessage, setCurrentMessage] = useState<string>("");

  const { mutate, isError, isSuccess, isPending } = useMutation({
    mutationKey: [
      "sendInviteSmsMessage",
      programId,
      locale,
      externalUser?.externalUserTypeId,
      externalUser?.id
    ],
    mutationFn: ({ message }: { message: string }) => {
      setCurrentMessage(message);
      return submitSMSInvite(message, externalUser);
    },
    onError: (err) => {
      logErrorToConsoleOnDev(err);
      displayErrorToast({ message: t("registerPatient.errorSMS") });
    },
    onSuccess: (response) => {
      if (!response) return;
      displaySuccessToast({ message: t("registerPatient.successSMS") });
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.users.sms(
          programId,
          locale,
          externalUser?.externalUserTypeId ?? "",
          externalUser?.id ?? ""
        )
      });
      trackNewInviteSMSSent({
        message: currentMessage,
        externalUserId: externalUser?.id ?? ""
      });
    }
  });

  return {
    isPending,
    sendInviteSmsMessage: mutate,
    isError,
    isSuccess
  };
};
