import { useEffect, useReducer } from "react";

import useUser from "./useUser";

import unreadMessagesReducer, {
  UnreadMessagesActions
} from "~/pages/user/userPage/unreadMessagesReducer";
import { ChatMessage } from "~/typing/sidekickTypes";

type Props = {
  messages: ChatMessage[];
  userId: string;
};

const useUnreadMessages = ({ messages, userId }: Props) => {
  const { user: authUser } = useUser();

  const [unreadMessages, unreadMessagesDispatch] = useReducer(
    unreadMessagesReducer,
    {
      unreadMessages: [],
      totalUnreadToOtherCoaches: 0,
      totalToAuthUser: 0,
      initialized: false
    }
  );

  useEffect(() => {
    if (!messages?.length || !userId) return;

    if (unreadMessages.initialized) {
      unreadMessagesDispatch({
        type: UnreadMessagesActions.Update,
        payload: {
          messages: messages,
          authUser,
          patientUserId: userId
        }
      });
    } else {
      unreadMessagesDispatch({
        type: UnreadMessagesActions.Initialize,
        payload: {
          messages: messages,
          authUser,
          patientUserId: userId
        }
      });
    }
  }, [messages, userId]);

  return {
    unreadMessages,
    updateUnreadMessages: () =>
      unreadMessagesDispatch({
        type: UnreadMessagesActions.Update,
        payload: {
          messages: messages ?? [],
          authUser,
          patientUserId: userId
        }
      })
  };
};

export default useUnreadMessages;
