import styles from "./SkeletonUserListFilters.module.scss";

import SkeletonElement from "../SkeletonElement";
import SkeletonShimmer from "../SkeletonShimmer";

type Props = {
  numberOfFilters?: number;
};

const SkeletonUserListFilters = ({ numberOfFilters = 5 }: Props) => {
  return (
    <div className={`${styles.filtersWrapper}`}>
      {numberOfFilters >= 1
        ? Array.from(Array(numberOfFilters).keys()).map((index) => (
            <div className={styles.filter} key={index}>
              <SkeletonElement type="filter" />
              {index % 2 === 1 && <SkeletonShimmer />}
            </div>
          ))
        : null}
    </div>
  );
};

export default SkeletonUserListFilters;
