import { DateTime } from "luxon";

import api from "~/api/api";
import { UserReadmission, UserWeekPoints } from "~/typing/sidekickTypes";

export const stopUserProgram = async ({
  programId,
  locale,
  userId
}: {
  programId: string;
  locale: string;
  userId: string;
}) => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/discharge`;
  return await api.post(path, {});
};

export const checkForUserListChanges = async ({
  programId,
  locale,
  lastUpdate
}: {
  programId: string;
  locale: string;
  lastUpdate: DateTime;
}): Promise<boolean> => {
  const path =
    `/coach/programs/${programId}/locales/${locale}/users/changed` +
    `?oldestDate=${lastUpdate.toISO()}`;
  return await api
    .get(path)
    .then((res) => res?.data?.hasChanged ?? false)
    .catch(() => false);
};

export const getLastWeekUserPoints = async ({
  programId,
  locale,
  userId
}: {
  programId: string;
  locale: string;
  userId: string;
}): Promise<UserWeekPoints | undefined> => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/lastWeeksPoints`;

  return await api
    .get(path)
    .then((res) => res.data)
    .catch(() => undefined);
};

export const restartMember = async ({
  programId,
  locale,
  userId,
  externalUserTypeId,
  externalUserId
}: {
  programId: string;
  locale: string;
  userId: string;
  externalUserTypeId?: string;
  externalUserId?: string;
}) => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/restart`;
  return await api.post(path, {
    externalUserTypeId,
    externalUserId
  });
};

export const getUserReAdmissions = async ({
  programId,
  locale,
  userProgramCatalogItemIds
}: {
  programId: string;
  locale: string;
  userProgramCatalogItemIds: number[];
}): Promise<UserReadmission[] | undefined> => {
  const path = `/coach/programs/${programId}/locales/${locale}/userReadmissions`;
  return await api
    .post(path, {
      userProgramCatalogItemIds
    })
    .then((res) => res.data.items)
    .catch(() => undefined);
};
