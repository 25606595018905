import { Buffer } from "buffer";

import { useRef, useState } from "react";

import styles from "./UploadDocument.module.scss";

import ArrowIcon from "~/assets/arrow-right.svg";
import CloseIcon from "~/assets/close.svg";
import PaperClip from "~/assets/paper-clip.svg";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import { ByteSize } from "~/constants/measurements";
import { t } from "~/i18n";

type AcceptedFileType = "application/pdf" | "text/csv";

window.Buffer = Buffer;

export type UploadDocumentProps = {
  onUpload: (props: {
    selectedFile?: File;
    encodedFile: string;
  }) => Promise<void>;
  buttonText?: string;
  acceptedFiles?: AcceptedFileType;
  buttonDisabled?: boolean;
};

const UploadDocument = ({
  onUpload,
  buttonText,
  acceptedFiles = "application/pdf",
  buttonDisabled
}: UploadDocumentProps) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [error, setError] = useState("");
  const [encodedFile, setEncodedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const mapFileTypeToFileEnding = (fileType: AcceptedFileType) => {
    switch (fileType) {
      case "application/pdf":
        return ".pdf";
      case "text/csv":
        return ".csv";
      default:
        return "";
    }
  };

  const encodeFile = (file) => {
    switch (acceptedFiles) {
      case "application/pdf":
        return file.slice(28, file.length);
      case "text/csv":
        return Buffer.from(file).toString("base64");
      default:
        return "";
    }
  };

  const removeFile = () => {
    if (inputRef.current?.value) {
      inputRef.current.value = "";
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggedOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDraggedOver(false);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
  };

  const onFileLoad = (e) => {
    const file = e.target.result;
    setEncodedFile(encodeFile(file));
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
    const fileReader = new FileReader();
    fileReader.onload = onFileLoad;

    if (acceptedFiles === "application/pdf") {
      fileReader.readAsDataURL(file);
    } else if (acceptedFiles === "text/csv") {
      fileReader.readAsText(file);
    }
  };

  const handleSelectFile = () => {
    inputRef.current?.click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setError("");
    setIsDraggedOver(false);
    const file = e.dataTransfer.files[0];

    if (file && acceptedFiles.includes(file.type)) {
      removeFile();
      handleFileChange(file);
    } else {
      const supportedFileType = mapFileTypeToFileEnding(acceptedFiles);
      setError(
        t("documentsPage.unsupportedFileType", {
          supportedFileTypes: supportedFileType
        })
      );
    }
  };

  const getFileSizeString = (size: number) => {
    if (size > ByteSize.MegaByte) {
      return `${(size / ByteSize.MegaByte).toPrecision(3)}MB`;
    }
    if (size > ByteSize.KiloByte) {
      return `${(size / ByteSize.KiloByte).toPrecision(3)}KB`;
    }
    return `${size.toPrecision(3)}B`;
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      if (selectedFile) {
        await onUpload({ selectedFile, encodedFile });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className={`${styles.dropZone} ${isDraggedOver ? styles.active : ""}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragOver}
        onDragEnd={handleDragLeave}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleSelectFile}
        disabled={loading}
        data-testid="upload-dropzone"
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className={styles.uploadCircle}>
              <img src={ArrowIcon} />
            </div>
            <div className={styles.instructions}>
              {`${t("documentsPage.dropToUpload")} ${t("documentsPage.or")} ${t(
                "documentsPage.browse"
              )}`}
            </div>
            {error && <div className={styles.error}>{error}</div>}
            <input
              type="file"
              className={styles.selectFileInput}
              accept={`.${acceptedFiles.split("/")[1]}`}
              onInput={onFileChange}
              ref={inputRef}
              value={selectedFile ? "" : undefined}
            />
          </>
        )}
      </button>

      {selectedFile && (
        <div className={styles.file}>
          <img
            className={styles.paperclip}
            src={PaperClip}
            alt="paper clip icon"
          />
          <p className={styles.name}>{selectedFile.name}</p>
          <p className={styles.size}>{getFileSizeString(selectedFile.size)}</p>
          <button
            className={styles.close}
            onClick={() => setSelectedFile(undefined)}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
      )}
      <button
        type="button"
        className={`btn btn-primary btn-sm ${styles.submit}`}
        onClick={handleUpload}
        disabled={loading || !selectedFile || buttonDisabled}
      >
        {buttonText || t("general.upload")}
      </button>
    </>
  );
};

export default UploadDocument;
