import _ from "lodash";
import { DateTime } from "luxon";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { IdentifierColumn } from "./columnsRegistrationList";
import externalUsersReducer, {
  ExternalUserActions
} from "./externalUsersReducer";
import {
  getBrandOptionsForUsers,
  getCallConsentStatusText,
  getCallConsentTypes,
  getCallRecordingDisclaimerTypes,
  getEnrolmentFilterTypes,
  getEnrolmentStatusText,
  getHipaaCertifiedTypes,
  getLineOfBusinessOptionsForUsers,
  getOutreachTypeOptionsForUsers,
  getSmsFilterTypes,
  memberStateFilterOptions,
  planStateFilterOptions,
  isSmsStatusOk,
  isSmsStatusError,
  smsFilterMatchesStatus,
  getRelationshipText,
  getRetriggerOptions,
  filterUserByGroupNumber,
  filterUserByGroupName
} from "./helper";
import styles from "./ProgramRegistrationsPage.module.scss";
import RegisterPatient from "./registerPatient/RegisterPatient";
import RegistrationList from "./registrationList/RegistrationList";
import { RegistrationListContext } from "./registrationList/registrationListContext";
import RegistrationListWithSearch from "./registrationList/RegistrationListWithSearch";
import SMSModal from "./SMSModal";

import {
  groupFilterOptions,
  groupNameFilterOptions
} from "../programUsers/filters/filterOptions";

import { getExternalUserLinkedUser } from "~/api/requests/externalUserRequests";
import { readSmsMessages } from "~/api/requests/messageRequests";
import { CustomListColumn } from "~/components/customList/CustomList";
import {
  createCheckboxFilterData,
  createFilterData
} from "~/components/customList/CustomList.utils";
import MessageWindow from "~/components/messages/messageWindow/MessageWindow";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import getDiseaseNameOptions from "~/constants/getDiseaseNameOptions";
import { HipaaStatus } from "~/constants/hipaaStatus";
import { TimeInMs } from "~/constants/measurements";
import { DEFAULT_FILTER_VALUE, UNDEFINED_OPTION } from "~/constants/options";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import states from "~/constants/states";
import { getBasicFormattedDate } from "~/helpers/date/dateHelpers";
import {
  isCancerProgramByUserType,
  isMedicareProgram,
  programHasBehaviour
} from "~/helpers/program/programHelpers";
import { capitalizeWord } from "~/helpers/string/stringHelpers";
import {
  getRetriggeredText,
  getRetriggeredValue
} from "~/helpers/user/userHelpers";
import { useSendAdHocSMS } from "~/hooks/useApi/messages/useSendSmsMessage";
import { useSmsMessages } from "~/hooks/useApi/messages/useSmsMessages";
import useProgram from "~/hooks/useApi/useProgram";
import useExternalUserGroups from "~/hooks/useApi/useUserGroups";
import useExternalusers from "~/hooks/useExternalUsers";
import useListFilters from "~/hooks/useListFilters";
import useUrlQuery from "~/hooks/useUrlQuery";
import { setListFilter } from "~/state/customList/customListStateHelpers";
import { updateExternalusers } from "~/state/externalUsers/externalUsersSlice";
import { AppDispatch } from "~/state/store";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  CustomListFilterData,
  FilterCheckboxData,
  SearchData
} from "~/typing/carePortalTypes";
import { ExternalUser } from "~/typing/sidekickTypes";

export enum ProgramRegistrationFilterNames {
  MemberState = "memberState",
  PlanState = "planState",
  LineOfBusiness = "lineOfBusiness",
  OutreachType = "outreachType",
  MemberBrand = "memberBrand",
  Call = "call",
  CallRecording = "callRecording",
  Hipaa = "hipaa",
  Disease = "disease",
  Enrolment = "enrolment",
  Sms = "sms",
  UserTypeId = "userTypeId",
  UnreadSMS = "unreadSMS",
  Retrigger = "retrigger",
  GroupNumber = "groupNumber",
  GroupName = "groupName"
}

const ProgramRegistrationsPage = () => {
  const { program_id = "", locale = "" } = useParams<{
    program_id: string;
    locale: string;
  }>();
  const { program } = useProgram({ programCatalogItemId: program_id, locale });
  const {
    trackUserTypeFilterSelected,
    trackSmsFilterSelected,
    trackDiseaseTypesFilterSelected,
    trackRegistrationViewOpened
  } = useAmplitudeTracking();

  const customListUniqueId = `registration-list-${program_id}-${locale}`;
  const { filters: listFilters } = useListFilters(customListUniqueId);

  const { groups } = useExternalUserGroups();

  const { t } = useTranslation();
  const urlQuery = useUrlQuery();

  const [showSMSMessages, setShowSMSMessages] = useState(false);

  const [selectedExternalUser, setSelectedExternalUser] = useState<
    ExternalUser | undefined
  >();
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [usersForAvatars, setUsersForAvatars] = useState({});
  const [externalUsers, externalUsersDispatch] = useReducer(
    externalUsersReducer,
    []
  );

  const showSmsButton = Boolean(urlQuery.get("useSms")) || config.useSms;

  const { users, searchString, showUnseenSms } = useExternalusers(
    program_id,
    listFilters[ProgramRegistrationFilterNames.UserTypeId] as string
  );

  const dispatch = useDispatch<AppDispatch>();

  const { sendSmsMessage } = useSendAdHocSMS({
    locale,
    programId: program_id,
    externalUser: selectedExternalUser
  });

  const { smsMessages } = useSmsMessages({
    locale,
    programCatalogItemId: program_id,
    externalUser: selectedExternalUser
  });

  useEffect(() => {
    if (!program) return;
    trackRegistrationViewOpened();
  }, [program?.programCatalogItemId]);

  useEffect(() => {
    externalUsersDispatch({
      type: ExternalUserActions.Sort,
      payload: users || []
    });
  }, [users]);

  useEffect(() => {
    if (!program?.externalUserTypes || program?.externalUserTypes.length == 0) {
      return;
    }
    const selectedUserTypeId =
      listFilters[ProgramRegistrationFilterNames.UserTypeId] ||
      program?.externalUserTypes?.[0]?.id;

    if (
      !listFilters[ProgramRegistrationFilterNames.UserTypeId] ||
      selectedUserTypeId !==
        listFilters[ProgramRegistrationFilterNames.UserTypeId]
    )
      setListFilter(
        customListUniqueId,
        ProgramRegistrationFilterNames.UserTypeId,
        selectedUserTypeId
      );

    if (
      program?.useExternalUserKeywordSearch &&
      !showUnseenSms &&
      !searchString
    )
      return;

    const externalUsersInterval = setInterval(() => {
      dispatch(
        updateExternalusers({
          programId: program_id,
          locale,
          userTypeId: selectedUserTypeId as string,
          searchString:
            program?.useExternalUserKeywordSearch && !showUnseenSms
              ? searchString
              : undefined,
          unseenSmsOnly: program?.useExternalUserKeywordSearch && showUnseenSms
        })
      );
    }, TimeInMs.Minute);

    return () => clearInterval(externalUsersInterval);
  }, [program?.externalUserTypes, searchString, showUnseenSms]);

  useEffect(() => {
    const getImage = async () => {
      if (!selectedExternalUser) return;

      const linkedUser = await getExternalUserLinkedUser({
        programId: program_id,
        externalUserTypeId:
          (listFilters[ProgramRegistrationFilterNames.UserTypeId] as string) ??
          "",
        userId: selectedExternalUser.id,
        locale
      });
      usersForAvatars[0] = linkedUser;
      setUsersForAvatars({ ...usersForAvatars });
    };

    getImage();
  }, [selectedExternalUser]);

  const openRegistration = (user: ExternalUser) => {
    setSelectedExternalUser(user);
    setShowPatientModal(true);
  };

  useEffect(() => {
    if (showSMSMessages && selectedExternalUser?.unseenSms) {
      readSmsMessages(smsMessages);
      externalUsersDispatch({
        type: ExternalUserActions.Update,
        payload: {
          ...selectedExternalUser,
          unseenSms: false
        }
      });
    }
  }, [smsMessages]);

  const openSMSMessages = async (user: ExternalUser) => {
    setSelectedExternalUser(user);
    setShowSMSMessages(true);
  };

  const handleShowSMSModal = () => {
    setShowPatientModal(false);
    setShowSMSModal(true);
  };

  const handleExternalUserUpdate = (externalUser: ExternalUser) => {
    externalUsersDispatch({
      type: ExternalUserActions.Update,
      payload: externalUser
    });
  };

  const renderTranslatedOption = (option) => t(option?.text);

  const filterData: CustomListFilterData<ExternalUser>[] = _.compact([
    createFilterData({
      label: t("registerPatient.userType"),
      onChange: trackUserTypeFilterSelected,
      renderOption: (option) => option?.text,
      options:
        program?.externalUserTypes?.map((userType) => ({
          value: userType.id,
          text: userType.name
        })) ?? [],

      defaultValue: program?.externalUserTypes?.[0]?.id,
      key: ProgramRegistrationFilterNames.UserTypeId,
      filter: () => true
    }),
    createFilterData({
      label: t("registerPatient.enrolment"),
      renderOption: renderTranslatedOption,
      options: getEnrolmentFilterTypes({ includeAll: true }),
      conditional: config.isAnthem,
      defaultValue: parseInt(DEFAULT_FILTER_VALUE),
      key: ProgramRegistrationFilterNames.Enrolment,
      filter: ({ entity: user, filterValue }) =>
        getEnrolmentStatusText(user.enrolmentStatus) ===
        getEnrolmentStatusText(filterValue)
    }),
    createFilterData({
      label: t("registerPatient.consentToCall"),
      renderOption: renderTranslatedOption,
      options: getCallConsentTypes(true),
      conditional: config.isAnthem,
      defaultValue: parseInt(DEFAULT_FILTER_VALUE),
      key: ProgramRegistrationFilterNames.Call,
      filter: ({ entity: user, filterValue }) =>
        getCallConsentStatusText(user.enrolmentConsentToCall) ===
        getCallConsentStatusText(filterValue)
    }),
    createFilterData({
      label: t("registerPatient.callrecordingdisclaimer"),
      renderOption: renderTranslatedOption,
      options: getCallRecordingDisclaimerTypes(true),
      conditional: config.isAnthem,
      defaultValue: parseInt(DEFAULT_FILTER_VALUE),
      key: ProgramRegistrationFilterNames.CallRecording,
      filter: ({ entity: user, filterValue }) =>
        user.enrolmentCallRecordingDisclaimer === filterValue
    }),
    createFilterData({
      label: t("registerPatient.hipaaverified"),
      renderOption: renderTranslatedOption,
      options: getHipaaCertifiedTypes(true),
      conditional: config.isAnthem,
      defaultValue: parseInt(DEFAULT_FILTER_VALUE),
      key: ProgramRegistrationFilterNames.Hipaa,
      filter: ({ entity: user, filterValue }) => {
        if (filterValue === HipaaStatus.Unknown) {
          return !user.enrolmentHipaaVerified;
        }
        return user.enrolmentHipaaVerified === filterValue;
      }
    }),
    createFilterData({
      label: t("general.sms"),
      onChange: (value) => trackSmsFilterSelected(value.toString()),
      renderOption: (option) => option?.text,
      options: getSmsFilterTypes(true),
      defaultValue: parseInt(DEFAULT_FILTER_VALUE),
      key: ProgramRegistrationFilterNames.Sms,
      filter: ({ entity: user, filterValue }) =>
        smsFilterMatchesStatus(user.smsStatus, filterValue)
    }),
    createFilterData({
      label: t("registerPatient.cancerType"),
      onChange: trackDiseaseTypesFilterSelected,
      renderOption: (option) => option.text,
      options: getDiseaseNameOptions(),
      key: ProgramRegistrationFilterNames.Disease,
      defaultValue: DEFAULT_FILTER_VALUE,
      conditional:
        isCancerProgramByUserType(
          program?.externalUserTypes?.find(
            (eut) =>
              eut.id === listFilters[ProgramRegistrationFilterNames.UserTypeId]
          )
        ) ?? false,
      filter: ({ entity: user, filterValue }) =>
        user.diseaseName === filterValue
    }),
    createCheckboxFilterData({
      label: "Member state",
      options: memberStateFilterOptions(externalUsers),
      renderOption: (option) => option?.text,
      key: ProgramRegistrationFilterNames.MemberState,
      defaultValue: [],
      conditional: config.isAnthem,
      includeNoValueOption: true,
      filter: ({ entity: user, filterValue }) => {
        const filteredStates = filterValue as string[];
        //If no values are selected, don't filter. We want to show all entities.
        if (filteredStates.length === 0) {
          return true;
        }
        return states.some((state) => state.code === user.memberState)
          ? filteredStates?.includes(user.memberState ?? "")
          : filteredStates?.includes(UNDEFINED_OPTION);
      }
    }),
    createCheckboxFilterData({
      label: "Plan state",
      options: planStateFilterOptions(externalUsers),
      renderOption: (option) => option?.text?.toUpperCase(),
      key: ProgramRegistrationFilterNames.PlanState,
      defaultValue: [],
      conditional: config.isAnthem,
      includeNoValueOption: true,
      filter: ({ entity: user, filterValue }) => {
        const filteredStates = filterValue as string[];
        //If no values are selected, don't filter. We want to show all entities.
        if (filteredStates.length === 0) {
          return true;
        }
        return states.some(
          (state) => state.code.toLowerCase() === user.planState?.toLowerCase()
        )
          ? filteredStates?.includes(user.planState ?? "")
          : filteredStates?.includes(UNDEFINED_OPTION);
      }
    }),
    createCheckboxFilterData({
      label: "LOB",
      renderOption: (option) => capitalizeWord(option?.text),
      options: getLineOfBusinessOptionsForUsers(externalUsers),
      key: ProgramRegistrationFilterNames.LineOfBusiness,
      defaultValue: [],
      conditional: config.isAnthem,
      includeNoValueOption: true,
      filter: ({ entity: user, filterValue }) => {
        const filteredValues = filterValue as string[];
        //If no values are selected, don't filter. We want to show all entities.
        if (filteredValues.length === 0) {
          return true;
        }
        return Boolean(user.lineOfBusiness?.trim())
          ? filterValue?.includes(user.lineOfBusiness ?? "")
          : filterValue?.includes(UNDEFINED_OPTION);
      }
    }),
    createCheckboxFilterData({
      label: t("registerPatient.outReach"),
      renderOption: (option) => capitalizeWord(option?.text),
      options: getOutreachTypeOptionsForUsers(externalUsers),
      key: ProgramRegistrationFilterNames.OutreachType,
      defaultValue: [],
      conditional: config.isAnthem,
      includeNoValueOption: true,
      filter: ({ entity: user, filterValue }) => {
        const filteredValues = filterValue as string[];
        //If no values are selected, don't filter. We want to show all entities.
        if (filteredValues.length === 0) {
          return true;
        }

        return Boolean(user.outreachType?.trim())
          ? filterValue?.includes(user.outreachType ?? "")
          : filterValue?.includes(UNDEFINED_OPTION);
      }
    }),
    createCheckboxFilterData({
      label: "Brand",
      renderOption: (option) => capitalizeWord(option?.text),
      options: getBrandOptionsForUsers(externalUsers),
      key: ProgramRegistrationFilterNames.MemberBrand,
      defaultValue: [],
      conditional: config.isAnthem,
      includeNoValueOption: true,
      filter: ({ entity: user, filterValue }) => {
        const filteredValues = filterValue as string[];
        //If no values are selected, don't filter. We want to show all entities.
        if (filteredValues.length === 0) {
          return true;
        }

        return Boolean(user.brand?.trim())
          ? filterValue?.includes(user.brand ?? "")
          : filterValue?.includes(UNDEFINED_OPTION);
      }
    }),
    createFilterData({
      label: t("retrigger.retrigger"),
      options: getRetriggerOptions(true),
      renderOption: (option) => option?.text,
      conditional: programHasBehaviour(
        program,
        ProgramBehaviour.RetriggeringEnabled
      ),
      defaultValue: DEFAULT_FILTER_VALUE,
      key: ProgramRegistrationFilterNames.Retrigger,
      filter: ({ entity: user, filterValue }) =>
        getRetriggeredValue(
          user.eligibleForRestart,
          user.eligibleForRestartDate,
          program?.retriggeredStatusDurationSeconds
        ) === filterValue
    }),
    createCheckboxFilterData({
      //Do we want an amplitude event for this?
      key: ProgramRegistrationFilterNames.GroupNumber,
      options: groupFilterOptions(groups || []),
      renderOption: (option) => option.text,
      placeholder: "No group selected",
      label: t("programUserList.groupNumber"),
      defaultValue: [],
      filter: ({ entity: user, filterValue }) =>
        filterUserByGroupNumber(user?.groupNumber, filterValue),
      dataTestId: "group-number-filter",
      conditional: isMedicareProgram(program),
      allowSearch: true
    }),
    createCheckboxFilterData({
      //Do we want an amplitude event for this?
      key: ProgramRegistrationFilterNames.GroupName,
      options: groupNameFilterOptions(groups || []),
      renderOption: (option) => option.text,
      placeholder: "No group selected",
      label: t("programUserList.groupName"),
      defaultValue: [],
      filter: ({ entity: user, filterValue }) =>
        filterUserByGroupName(user?.groupName, filterValue),
      dataTestId: "group-name-filter",
      conditional: isMedicareProgram(program),
      allowSearch: true
    })
  ]);

  const filterCheckboxData: FilterCheckboxData<ExternalUser>[] = [
    {
      text: t("registerPatient.unreadSms"),
      key: ProgramRegistrationFilterNames.UnreadSMS,
      conditional: showSmsButton,
      defaultValue: false,
      filter: ({ entity: user, filterValue }) =>
        user.unseenSms === (filterValue ?? false)
    }
  ];

  const columns: CustomListColumn<ExternalUser>[] = [
    {
      heading: {
        text: config.isAnthem ? "HCID" : t("registerPatient.patientId"),
        sortable: {
          sortBy: ["identifier"]
        }
      },
      render: (user) => (
        <IdentifierColumn
          externalUser={user}
          showSMSButton={showSmsButton && Boolean(user.phoneNumber)}
          openSMSMessages={openSMSMessages}
          onOpenRegistration={openRegistration}
        />
      )
    },
    {
      heading: {
        text: config.isAnthem
          ? t("registerPatient.SKRegistrationId")
          : t("registerPatient.registrationId"),
        sortable: {
          sortBy: ["id"]
        }
      },
      render: (user) => user.id
    },
    {
      heading: {
        text: "MBR Key",
        hideByDefault: true
      },
      conditional: config.isAnthem,
      render: (user) => user.anthemMbrKey
    },
    {
      heading: {
        text: "MCID",
        hideByDefault: true
      },
      conditional: config.isAnthem,
      render: (user) => user.anthemMCID
    },
    {
      heading: {
        text: "HRS ID"
      },
      conditional: config.isAnthem,
      render: (user) => user.anthemHRSID
    },
    {
      heading: {
        text: "Proxy ID"
      },
      conditional: config.isAnthem,
      render: (user) => user.proxyId
    },
    {
      heading: {
        text: t("registerPatient.name"),
        sortable: {
          sortBy: ["name"]
        }
      },
      conditional: config.isAnthem,
      render: (user) => user.name
    },
    {
      heading: {
        text: "LOB",
        sortable: {
          sortBy: ["lineOfBusiness"]
        }
      },
      conditional: config.isAnthem,
      render: (user) => user.lineOfBusiness
    },
    {
      heading: {
        text: "Member State",
        sortable: {
          sortBy: ["memberState"]
        }
      },
      conditional: config.isAnthem,
      render: (user) => user.memberState
    },
    {
      heading: {
        text: "Plan State",
        sortable: {
          sortBy: ["planState"]
        }
      },
      conditional: config.isAnthem,
      render: (user) => user.planState
    },
    {
      heading: {
        text: "Outreach",
        sortable: {
          sortBy: ["outreachType"]
        }
      },
      conditional: config.isAnthem,
      render: (user) => user.outreachType
    },
    {
      heading: {
        text: "Brand",
        sortable: {
          sortBy: ["brand"]
        },
        hideByDefault: true
      },
      conditional: config.isAnthem,
      render: (user) => user.brand
    },
    {
      heading: {
        text: t("registerPatient.patientBond"),
        sortable: {
          reverse: false,
          sortBy: ["patientBond"]
        },
        hideByDefault: true
      },
      conditional: config.isAnthem,
      render: (user) => user.patientBond
    },
    {
      heading: {
        text: t("registerPatient.groupNumber"),
        sortable: {
          reverse: false,
          sortBy: ["groupNumber"]
        },
        hideByDefault: true
      },
      conditional: isMedicareProgram(program),
      render: (user) => user.groupNumber
    },
    {
      heading: {
        text: t("registerPatient.groupName"),
        sortable: {
          reverse: false,
          sortBy: ["groupName"]
        },
        hideByDefault: true
      },
      conditional: isMedicareProgram(program),
      render: (user) => user.groupName
    },
    {
      heading: {
        text: t("registerPatient.relationship"),
        sortable: {
          reverse: false,
          sortBy: ["relationship"]
        },
        hideByDefault: true
      },
      conditional: config.isAnthem,
      render: (user) => getRelationshipText(user.relationship ?? "")
    },
    {
      heading: {
        text: t("registerPatient.email"),
        sortable: {
          sortBy: ["email"]
        },
        hideByDefault: true
      },
      conditional: config.isAnthem,
      render: (user) => user.email
    },
    {
      heading: {
        text: t("registerPatient.phone"),
        hideByDefault: true
      },
      render: (user) => user.phoneNumber
    },
    {
      heading: {
        text: t("registerPatient.submittedOn"),
        sortable: {
          sortBy: ["submittedByDate"]
        }
      },
      render: (user) => (
        <div className={styles.date}>
          {config.isAnthem
            ? DateTime.fromISO(user.submittedByDate).toLocaleString({
                month: "numeric",
                day: "numeric",
                year: "numeric"
              })
            : DateTime.fromISO(user.submittedByDate).toLocaleString({
                month: "short",
                day: "numeric",
                year: "numeric"
              })}
        </div>
      )
    },
    {
      heading: { text: t("retrigger.retrigger"), hideByDefault: true },
      conditional: programHasBehaviour(
        program,
        ProgramBehaviour.RetriggeringEnabled
      ),
      render: (user) =>
        getRetriggeredText(
          user.eligibleForRestart,
          user.eligibleForRestartDate,
          program?.retriggeredStatusDurationSeconds
        )
    },
    {
      heading: {
        text: t("retrigger.lastRetriggerDate"),
        hideByDefault: true,
        sortable: {
          reverse: false,
          sortBy: ["eligibleForRestartDate"]
        }
      },
      conditional: programHasBehaviour(
        program,
        ProgramBehaviour.RetriggeringEnabled
      ),
      render: (user) => (
        <div className={styles.date}>
          {user.eligibleForRestartDate && (
            <>{getBasicFormattedDate(user.eligibleForRestartDate)}</>
          )}
        </div>
      )
    },
    {
      heading: {
        text: "SMS",
        sortable: {
          sortBy: ["smsStatus"]
        }
      },
      render: (user) => (
        <div
          className={
            isSmsStatusOk(user.smsStatus)
              ? styles.badgeOk
              : isSmsStatusError(user.smsStatus)
              ? styles.badgeError
              : styles.badgeNo
          }
        >
          {isSmsStatusOk(user.smsStatus)
            ? "OK"
            : isSmsStatusError(user.smsStatus)
            ? "Error"
            : "NO"}
        </div>
      )
    },
    {
      heading: {
        text: t("registerPatient.call"),
        sortable: {
          sortBy: ["enrolmentConsentToCallText"]
        }
      },
      conditional: config.isAnthem,
      render: ({ enrolmentConsentToCallText }) =>
        t(enrolmentConsentToCallText as any)
    },
    {
      heading: {
        text: t("registerPatient.enrolment"),
        sortable: {
          sortBy: ["enrolmentStatusText"]
        }
      },
      conditional: config.isAnthem,
      render: ({ enrolmentStatusText }) => t(enrolmentStatusText as any)
    }
  ];

  const searchData: SearchData = {
    data: [
      { exactMatch: true, key: ["id"] },
      { key: ["identifier"] },
      { key: ["email"] },
      { key: ["phoneNumber"] },
      { key: ["name"] },
      { key: ["providerName"] },
      { key: ["providerEmail"] },
      { key: ["caseIdentifier"] },
      { key: ["diseaseName"] },
      { key: ["pinCode"] }
    ],
    placeholder: t("general.search", "Search..."),
    className: `${styles.control} ${styles.search}`
  };

  if (!program) return null;

  return (
    <SentryErrorBoundary transactionName="ProgramRegistrationsPage">
      <RegistrationListContext.Provider
        value={{
          externalUsers,
          searchData,
          filterCheckboxData,
          filterData,
          columns,
          externalUserTypeId: listFilters[
            ProgramRegistrationFilterNames.UserTypeId
          ] as string,
          customListUniqueId,
          externalUserTypes: program.externalUserTypes ?? []
        }}
      >
        <div className={styles.registrationTable}>
          {program.useExternalUserKeywordSearch ? (
            <RegistrationListWithSearch />
          ) : (
            <RegistrationList />
          )}
        </div>
        {showPatientModal && (
          <RegisterPatient
            externaluser={selectedExternalUser}
            externalUserTypeId={
              listFilters[ProgramRegistrationFilterNames.UserTypeId] as string
            }
            onClose={() => setShowPatientModal(false)}
            externalUserTypes={program.externalUserTypes ?? []}
            location={program.location}
            smsSettings={{
              userOptedOut: selectedExternalUser?.smsOptOutType === "STOP",
              openSMSModal: handleShowSMSModal
            }}
            onExternalUserUpdate={handleExternalUserUpdate}
          />
        )}
        {showSMSModal && selectedExternalUser && (
          <SMSModal
            onClose={() => setShowSMSModal(false)}
            externalUser={selectedExternalUser}
            smsTemplate={
              program?.externalUserTypes?.find(
                (e) =>
                  e.id ===
                  listFilters[ProgramRegistrationFilterNames.UserTypeId]
              )?.smsText
            }
          />
        )}
        <MessageWindow
          onClose={() => setShowSMSMessages(false)}
          onSubmitMessage={({ message }) => sendSmsMessage({ message })}
          patient={{
            user: {
              fullName:
                selectedExternalUser?.name ||
                selectedExternalUser?.identifier ||
                "",
              imageHref: usersForAvatars?.[0]?.imageHref,
              authAccountId: "",
              userId: "",
              displayName:
                selectedExternalUser?.name ||
                selectedExternalUser?.identifier ||
                ""
            }
          }}
          showMessages={showSMSMessages}
          smsSettings={{
            useSMS: showSmsButton ?? false,
            userHasOptedOut: selectedExternalUser?.smsOptOutType === "STOP"
          }}
          messages={smsMessages}
          usersForAvatars={usersForAvatars}
          programThatUserIsIn={program}
        />
      </RegistrationListContext.Provider>
    </SentryErrorBoundary>
  );
};

export default ProgramRegistrationsPage;
