import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import MissionsList from "./MissionsList";
import { MissionPageContext } from "./missionsPageContext";
import QuizModalWrapper from "./quizzes/QuizModalWrapper";
import PreSelectionModal from "./value-preselection/PreselectionModal";

import DateNavigator from "../DateNavigator";

import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import SkeletonMissionsList from "~/components/skeletons/SkeletonMissionsList";
import useMissions from "~/hooks/useApi/useMissions";
import useMissionSummary from "~/hooks/useApi/useMissionSummary";
import useSurveys from "~/hooks/useApi/useSurveys";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";

const PRE_SELECTED_MISSION = "Values";

const MissionsPage = () => {
  const { t } = useTranslation();
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();
  const { trackMissionsViewOpened } = useAmplitudeTracking();
  const [selectedMissionType, setSelectedMissionType] = useState<string>();
  const [selectedDay, setSelectedDay] = useState<string>();
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>();
  const [selectedUserMissionId, setSelectedUserMissionId] = useState<string>();
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ days: 6 }).toISODate()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toISODate());

  const { slotDays, isLoading } = useMissionSummary({
    programId: program_id,
    locale,
    userId: user_id,
    startDate: startDate ?? "",
    endDate: endDate ?? ""
  });

  const { missions } = useMissions();

  const { surveys } = useSurveys(program_id, locale);

  useEffect(() => {
    trackMissionsViewOpened();
  }, []);

  const resetState = () => {
    handleCloseMissionModal();
  };

  const valuePreselectMissionId = missions?.find(
    (mission) => mission.name === PRE_SELECTED_MISSION
  );

  const quizzes = surveys?.filter((survey) => survey.options?.quiz);

  const handleMissionCardClick = (
    userMissionId: string,
    missionId: string,
    missionName: string,
    day: string
  ) => {
    const mission = missions?.find((mission) => mission.id === missionId);
    const quiz = quizzes?.find((quiz) => quiz.surveyName === missionName);
    if (quiz) {
      setSelectedSurveyId(quiz.id);
    }
    setSelectedUserMissionId(userMissionId);
    setSelectedMissionType(mission?.name);
    setSelectedDay(day);
  };

  const handleCloseMissionModal = () => {
    setSelectedMissionType(undefined);
    setSelectedUserMissionId(undefined);
    setSelectedSurveyId(undefined);
    setSelectedDay(undefined);
  };

  const renderMissionsList = (day: { date: DateTime; data: any }) => {
    if (isLoading) return <SkeletonMissionsList />;
    return (
      <MissionsList day={day.date.toISODate() ?? ""} slotDays={slotDays} />
    );
  };

  return (
    <SentryErrorBoundary resetState={resetState} transactionName="MissionsPage">
      <Helmet title={t("performance.missions", "Missions")} defer={false} />
      <MissionPageContext.Provider
        value={{
          handleMissionCardClick,
          quizzes,
          clickableMissionIds: [valuePreselectMissionId?.id ?? ""] // There will be more to come
        }}
      >
        <DateNavigator
          onDateChange={({ startDate, endDate }) => {
            if (!startDate.isValid || !endDate.isValid) return;
            setEndDate(endDate.toISODate() ?? "");
            setStartDate(startDate.toISODate() ?? "");
          }}
          renderDayItems={renderMissionsList}
        />
        {selectedMissionType === PRE_SELECTED_MISSION &&
          selectedUserMissionId && (
            <PreSelectionModal
              userMissionId={selectedUserMissionId}
              onClose={handleCloseMissionModal}
            />
          )}
        {selectedSurveyId && (
          <QuizModalWrapper
            surveyId={selectedSurveyId}
            onClose={handleCloseMissionModal}
            day={selectedDay}
            quizzes={quizzes}
          />
        )}
      </MissionPageContext.Provider>
    </SentryErrorBoundary>
  );
};

export default MissionsPage;
