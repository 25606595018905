import { DateTime } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import styles from "./PrintNotes.module.scss";

import { ExportContext } from "../exportPreviewContext";

import PinIcon from "~/assets/svgComponents/Pin";
import { journalItemTypes } from "~/constants/notes";
import { isBetweenDates } from "~/helpers/date/isBetweenDates";
import { useUserNotes } from "~/hooks/useUserNotes";
import colors from "~/styles/colors";
import { NoteInfo } from "~/typing/sidekickTypes";

const PrintNotes = () => {
  const { t } = useTranslation();

  const { startDate, endDate } = useContext(ExportContext);
  const { notes, pinnedNotes } = useUserNotes();

  const getCategoryName = (note: NoteInfo) => {
    const category = journalItemTypes.find(
      (item) => item.itemType === note.itemType
    );

    return category ? category.title : "";
  };

  const isPinned = (note: NoteInfo) => {
    return pinnedNotes.some((pinned) => pinned.journalItemId === note.id);
  };

  return (
    <>
      <div className={styles.flexContainer}>
        <span className={styles.bigHeader}>{t("exportPreview.notes")}</span>
      </div>
      <div className={styles.flexContainer}>
        <ul className={styles.entityList}>
          {notes
            .filter((note) => !note.archived && getCategoryName(note) !== "")
            .map((note, index) => {
              if (isBetweenDates(note.createdDate, startDate, endDate)) {
                return (
                  <li key={`note-print-${index}`}>
                    <div className={styles.noteInfo}>
                      <div className={styles.noteHeader}>
                        {isPinned(note) && (
                          <span className={styles.pin}>
                            <PinIcon color={colors.navyBlue80} />
                          </span>
                        )}
                        <p className={styles.category}>{`${getCategoryName(
                          note
                        )}`}</p>
                        <p className={styles.name}>
                          {`${note.coachUser.displayName} ${t(
                            "exportPreview.on"
                          )} `}
                          {DateTime.fromISO(note.createdDate).toLocaleString({
                            month: "long",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit"
                          })}
                        </p>
                      </div>
                      <p className={styles.text}>{note.text}</p>
                    </div>
                  </li>
                );
              } else {
                return null;
              }
            })}
        </ul>
      </div>
    </>
  );
};

export default PrintNotes;
