import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import styles from "./PhysicalChartTemplate.module.scss";

import ChartDetail from "~/components/charts/ChartDetail";
import CustomBarChart from "~/components/charts/CustomBarChart";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import getWeekDateRange from "~/helpers/date/getWeekDateRange";
import { PhysicalActivity } from "~/typing/carePortalTypes";

type PhysicalChartTemplateProps = {
  data: PhysicalActivity[];
  units: string;
  thisWeekFilter: boolean;
  setThisWeekFilter: (value: boolean) => void;
  lastWeekFilter: boolean;
  setLastWeekFilter: (value: boolean) => void;
  weekBeforeFilter: boolean;
  setWeekBeforeFilter: (value: boolean) => void;
  totals: { [key: string]: number };
  averages: { [key: string]: number };
  endDate?: DateTime;
};

type WeekString = "thisWeek" | "lastWeek" | "weekBefore";

const PhysicalChartTemplate = ({
  data,
  units,
  thisWeekFilter,
  setThisWeekFilter,
  lastWeekFilter,
  setLastWeekFilter,
  weekBeforeFilter,
  setWeekBeforeFilter,
  totals,
  averages,
  endDate = DateTime.utc()
}: PhysicalChartTemplateProps) => {
  const { t } = useTranslation();
  const today = DateTime.utc();

  const thisWeekDateRange = getWeekDateRange(today);

  const dataKeyTexts = {
    thisWeek: t("time.thisWeek", "This week"),
    lastWeek: t("time.lastWeek", "Last week"),
    weekBefore: t("time.beforeLast", "Before last")
  };

  const filterCheckboxData = [
    {
      value: weekBeforeFilter,
      setValue: setWeekBeforeFilter,
      text: t("time.weekBefore", "Week before last"),
      key: "weekBeforeFilter"
    },
    {
      value: lastWeekFilter,
      setValue: setLastWeekFilter,
      text: t("time.lastWeek", "Last week"),
      key: "lastWeekFilter"
    },
    {
      value: thisWeekFilter,
      setValue: setThisWeekFilter,
      text: `${t("time.thisWeek", "This week")} (${thisWeekDateRange})`,
      key: "thisWeekFilter"
    }
  ];

  const todayData = data?.find(
    (point) => point.date.toISODate() === today.toISODate()
  );
  const yesterdayData = data?.find(
    (point) => point.date.toISODate() === today.minus({ days: 1 }).toISODate()
  );

  const todayDiff =
    todayData && yesterdayData
      ? todayData.thisWeek - yesterdayData.thisWeek
      : 0;

  const totalDiff = totals.thisWeek - totals.lastWeek;
  const averagesDiff = averages.thisWeek - averages.lastWeek;

  const allDataKeys = ["thisWeek", "lastWeek", "weekBefore"];
  const dataKeys: WeekString[] = [];
  if (weekBeforeFilter) {
    dataKeys.push("weekBefore");
  }
  if (lastWeekFilter) {
    dataKeys.push("lastWeek");
  }
  if (thisWeekFilter) {
    dataKeys.push("thisWeek");
  }

  const todayTimeDiff = t("time.yesterday", "yesterday");
  const weekTimeDiff = t("time.lastWeek", "last week").toLowerCase();

  return (
    <div className={styles.PhysicalChartTemplate}>
      <div data-testid="physical-chart-details" className={styles.chartDetails}>
        <ChartDetail
          title={t("time.today")}
          values={todayData}
          dataKeys={[allDataKeys[0]]}
          units={units}
          valueDiff={todayDiff}
          positive={todayDiff > 0}
          timeDiff={todayTimeDiff}
        />
        <ChartDetail
          title={t("chartDetail.totalWeek")}
          values={totals}
          dataKeys={dataKeys.length > 0 ? dataKeys : [allDataKeys[0]]}
          dataKeyTexts={dataKeyTexts}
          units={units}
          valueDiff={totalDiff}
          positive={totalDiff > 0}
          timeDiff={weekTimeDiff}
          allDataKeys={allDataKeys}
        />
        <ChartDetail
          title={t("average.dailyWeek")}
          values={averages}
          dataKeys={dataKeys.length > 0 ? dataKeys : [allDataKeys[0]]}
          dataKeyTexts={dataKeyTexts}
          units={units}
          valueDiff={averagesDiff}
          positive={averagesDiff > 0}
          timeDiff={weekTimeDiff}
          allDataKeys={allDataKeys}
        />
      </div>
      <div className={styles.filters}>
        {filterCheckboxData.map(({ value, setValue, text, key }) => (
          <CheckboxInput
            checked={value}
            onChange={(checked) => setValue(checked)}
            size="lg"
            className={`${styles[key]} ${styles.checkbox}`}
            key={key}
            label={text}
          />
        ))}
      </div>
      <CustomBarChart
        data={data}
        today={today}
        endDate={endDate}
        dataKeys={dataKeys}
        units={units}
        allDataKeys={allDataKeys}
        useTimeFilter={false}
      />
    </div>
  );
};

export default PhysicalChartTemplate;
