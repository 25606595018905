import is from "date-fns/locale/is";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker/dist/es";

import styles from "./CustomDatePicker.module.scss";

import Close from "~/assets/svgComponents/Close";
import i18n from "~/i18n";

import "react-datepicker/dist/react-datepicker.css";

type CustomDatePickerProps = {
  onChange: (date: Date) => void;
  placeholderText: string;
  selected?: Date;
  onClear?: () => void;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  locale?: string;
  label?: string;
};

const CustomDatePicker = ({
  onChange,
  selected,
  placeholderText,
  dateFormat,
  onClear,
  minDate,
  maxDate,
  locale,
  label
}: CustomDatePickerProps) => {
  registerLocale("is", is);
  return (
    <div className={styles.datePicker} data-testid="custom-date-picker">
      {label && <label className="label label--block">{label} </label>}
      <DatePicker
        data-placeholder={placeholderText}
        placeholderText={selected ? "" : placeholderText}
        selected={selected}
        onChange={(date) => {
          if (date) onChange(date);
        }}
        popperClassName={styles.popper}
        calendarClassName={styles.calendar}
        formatWeekDay={(day: string) => day.slice(0, 1)}
        customInput={<CustomInput placeholderText={placeholderText} />}
        dateFormat={dateFormat ? dateFormat : "MMM dd, yyyy"}
        locale={locale || i18n.language || "en"}
        minDate={minDate}
        maxDate={maxDate}
      />
      {onClear && selected && (
        <button
          data-testid="date-picker-clear-btn"
          className={styles.clear}
          onClick={onClear}
        >
          <Close />
        </button>
      )}
    </div>
  );
};

interface CustomInputProps {
  placeholderText: string;
  onClick?: () => void;
  value?: any;
  onChange?: () => void;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ value, onClick, placeholderText, onChange }: CustomInputProps, ref) => (
    <div className={styles.customInput} data-placeholder={placeholderText}>
      <input
        data-testid="date-picker-input"
        type="text"
        value={value}
        onClick={onClick}
        ref={ref}
        onChange={onChange}
      />
    </div>
  )
);

CustomInput.displayName = "CustomInput";

export default CustomDatePicker;
