import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { UserPageContext } from "./UserPage.context";
import styles from "./UserPageHeader.module.scss";

import MessagesIcon from "~/assets/messages.svg";
import NotesIcon from "~/assets/notes.svg";
import SMSMessagesIcon from "~/assets/sms-notification.svg";
import Avatar from "~/components/avatar/Avatar";
import LastWeekPoints from "~/components/lastWeekPoints/LastWeekPoints";
import Notification from "~/components/notification/Notification";
import config from "~/config";
import getMostRecentProgram from "~/helpers/program/getMostRecentProgram";
import { shouldShowProgramSlotName } from "~/helpers/program/programHelpers";
import { getCurrentSlotDay } from "~/helpers/user/userDetailsHelpers";
import { shouldShowRestartedStatusByUserReadmission } from "~/helpers/user/userHelpers";
import { useExternalUser } from "~/hooks/useApi/useExternalUser";
import useUrlQuery from "~/hooks/useUrlQuery";
import RestartedStatus from "~/pages/program/programUsers/components/restart/RestartedStatus";
import { UserURLParams } from "~/typing/carePortalTypes";
import { Program, UserProgram } from "~/typing/sidekickTypes";

type UserPageHeaderProps = {
  onShowMessages: (value: boolean) => void;
  onShowNotes: () => void;
  hasUnreadSms: boolean;
  program: Program;
};

const UserPageHeader = ({
  onShowMessages,
  onShowNotes,
  hasUnreadSms,
  program
}: UserPageHeaderProps) => {
  const { t } = useTranslation();
  const { userDetail, unreadMessages } = useContext(UserPageContext);

  const { externalUser } = useExternalUser({
    externalUserId: userDetail?.externalUser?.id,
    externalUserTypeId: userDetail?.externalUser?.externalUserTypeId ?? ""
  });

  const { program_id = "", locale = "" } = useParams<UserURLParams>();

  const [selectedProgram, setSelectedProgram] = useState<
    UserProgram | undefined
  >(undefined);
  const user = userDetail?.user;

  useEffect(() => {
    setSelectedProgram(
      getMostRecentProgram(userDetail?.userPrograms ?? [], program_id, locale)
    );
  }, [userDetail]);

  const shouldShowDisplayName = () => {
    if (!user?.displayName || user?.displayName === user?.fullName) {
      return false;
    } else {
      return true;
    }
  };

  const slotDay = getCurrentSlotDay(selectedProgram);

  const urlQuery = useUrlQuery();
  const showSmsButton = urlQuery.get("useSms") || config.useSms;

  return (
    <div className={styles.MvpHeader}>
      <div className={styles.left}>
        <div className={styles.user}>
          <Avatar user={user} size="md" className={styles.avatar} />
          <div className={styles.name}>
            {user?.fullName ?? `[${t("warnings.noName")}]`}{" "}
            {shouldShowDisplayName() && `(${user?.displayName})`}
          </div>
          {user?.userId && <div className={styles.id}>ID: {user?.userId}</div>}
        </div>
        <div className={styles.progress}>
          {shouldShowProgramSlotName(selectedProgram) &&
            selectedProgram?.slotName && (
              <span className={styles.theme}>{selectedProgram.slotName}</span>
            )}
          {selectedProgram && (
            <span className={styles.week}>
              {selectedProgram?.currentSlot
                ? `${t("time.week")} ${selectedProgram.currentSlot} / ${
                    selectedProgram.noSlots
                  }, `
                : ""}
              {selectedProgram?.currentSlotStartDate
                ? `${t("time.day")} ${slotDay}`
                : ""}
            </span>
          )}
          {userDetail &&
            userDetail.userReadmissionOutput &&
            shouldShowRestartedStatusByUserReadmission(
              userDetail.userReadmissionOutput,
              program
            ) && (
              <RestartedStatus
                className={styles.restartedStatus}
                userId={userDetail.id}
                restartedDate={userDetail?.userReadmissionOutput?.createdDate}
              />
            )}
          {user?.userId && (
            <LastWeekPoints
              userId={user?.userId}
              className={styles.progressBar}
              displayName={user?.fullName}
              missions={userDetail?.missions}
            />
          )}
        </div>
      </div>
      <div className={styles.right}>
        {showSmsButton && externalUser?.phoneNumber && (
          <button
            type="button"
            onClick={() => onShowMessages(true)}
            className="btn-sm btn-tertiary-lightgrey"
          >
            <img className={styles.smsIcon} src={SMSMessagesIcon} alt="SMS" />
            <span>{"SMS"}</span>
            <Notification
              count={hasUnreadSms ? 1 : 0}
              size="xs"
              className={styles.smsNotification}
            />
          </button>
        )}
        <button
          type="button"
          onClick={() => onShowMessages(false)}
          className="btn-sm btn-tertiary-lightgrey"
          data-testid="messages"
        >
          <img src={MessagesIcon} alt="messages" />
          <span>{t("general.messages", "Messages")}</span>

          <Notification
            count={
              unreadMessages.totalToAuthUser ||
              unreadMessages.totalUnreadToOtherCoaches
            }
            size={"sm"}
            className={`${styles.notification} ${
              unreadMessages.totalToAuthUser ? styles.pinkNotification : ""
            }`}
          />
        </button>
        <button
          type="button"
          onClick={onShowNotes}
          className="btn-sm btn-tertiary-lightgrey"
          data-testid="userpage-show-notes"
        >
          <img src={NotesIcon} alt="notes" />
          <span>{t("general.notes", "Notes")}</span>
        </button>
      </div>
    </div>
  );
};

export default UserPageHeader;
