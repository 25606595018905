import styles from "./NarrowLayout.module.scss";

import NavHeader from "~/components/nav-header/NavHeader";

type NarrowLayoutProps = {
  children: any;
  title?: string;
  titleImg?: string;
};

const NarrowLayout = ({ children, title, titleImg }: NarrowLayoutProps) => {
  return (
    <>
      <NavHeader />
      <div className={styles.NarrowLayout}>
        {title && (
          <h1 className={styles.heading}>
            {titleImg && (
              <img
                src={titleImg}
                alt="Decorative logo"
                height="24"
                width="24"
              />
            )}
            {title}
          </h1>
        )}
        <div className={styles.wrapper}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default NarrowLayout;
