import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TableHeading } from "~/typing/carePortalTypes";

interface CustomListState {
  pagination?: {
    [key: string]: {
      currentPage: number;
    };
  };
  columns?: {
    [key: string]: TableHeading[];
  };
  filters?: {
    [key: string]: {
      // This variablee is here to force a rerender when filters change
      filtersChangedCount: number;
      filters: { [filterKey: string]: any };
    };
  };
}

const initialState: CustomListState = {
  columns: {},
};

interface ToggleColumn {
  key: string;
  column: TableHeading;
}

interface SetColumns {
  key: string;
  columns: TableHeading[];
}

interface SetFilter {
  key: string;
  filterKey: string;
  value: any;
}

interface SetCurrentPage {
  key: string;
  currentPage: number;
}

const customListSLice = createSlice({
  name: "customList",
  initialState,
  reducers: {
    setColumns(state, action: PayloadAction<SetColumns>) {
      if (!state.columns) state.columns = {};
      state.columns[action.payload.key] = action.payload.columns;
    },
    toggleColumn(state, action: PayloadAction<ToggleColumn>) {
      if (!state.columns) state.columns = {};
      const registrationColumns = state.columns[action.payload.key];

      if (!registrationColumns || registrationColumns?.length === 0) {
        state.columns[action.payload.key] = [action.payload.column];
      } else {
        const index = registrationColumns.findIndex(
          (column) => column.text === action.payload.column.text
        );

        if (index < 0) {
          registrationColumns.push(action.payload.column);
        } else {
          registrationColumns.splice(index, 1);
        }
        state.columns[action.payload.key] = registrationColumns;
      }
    },
    setFilter(state, action: PayloadAction<SetFilter>) {
      if (!state.filters) state.filters = {};
      if (!state.filters[action.payload.key]) {
        state.filters[action.payload.key] = {
          filters: {},
          filtersChangedCount: 0,
        };
      }

      const currentValue =
        state.filters[action.payload.key].filters[action.payload.filterKey];

      if (currentValue !== action.payload.value) {
        state.filters[action.payload.key].filters[action.payload.filterKey] =
          action.payload.value;
        state.filters[action.payload.key].filtersChangedCount++;
      }
    },
    setCurrentPage(state, action: PayloadAction<SetCurrentPage>) {
      if (!state.pagination) state.pagination = {};
      if (!state.pagination[action.payload.key]) {
        state.pagination[action.payload.key] = {
          currentPage: 1,
        };
      }

      state.pagination[action.payload.key].currentPage =
        action.payload.currentPage;
    },
  },
});

const customListReducer = customListSLice.reducer;
export { customListReducer };
export const {
  toggleColumn,
  setColumns,
  setFilter,
  setCurrentPage,
} = customListSLice.actions;
