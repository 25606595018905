import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";
import { Program } from "~/typing/sidekickTypes";

export default function useProgram({
  programCatalogItemId,
  locale,
  includeSlots = false,
  includeHighRiskUserCount = false
}: {
  programCatalogItemId: string;
  locale: string;
  includeSlots?: boolean;
  includeHighRiskUserCount?: boolean;
}) {
  const params = new URLSearchParams({
    includeSlots: includeSlots ? "true" : "false",
    includeHighRiskUserCount: includeHighRiskUserCount ? "true" : "false"
  });

  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}?${params.toString()}`;

  const { data, isError, isLoading, invalidate } = useApi<Program>({
    path,
    queryKey: QueryKeyFactory.programs.single(programCatalogItemId, locale),
    staleTime: TimeInMs.Minute * 20,
    disabled: !programCatalogItemId || !locale
  });

  return {
    program: data,
    isError,
    isLoading,
    invalidate
  };
}
