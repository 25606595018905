import classNames from "classnames/bind";
import { ReactNode } from "react";

import styles from "./CheckboxInput.module.scss";

import CheckIcon from "~/assets/check.svg";
import MinusIcon from "~/assets/minus.svg";

const cx = classNames.bind(styles);

type CheckboxInputProps = {
  checked: boolean;
  indetermined?: boolean;
  onChange?: (value: boolean) => void;
  label: ReactNode;
  className?: string;
  size?: "lg" | "sm";
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const CheckboxInput = ({
  checked,
  indetermined,
  onChange,
  className,
  label,
  disabled,
  size,
  onKeyDown
}: CheckboxInputProps) => {
  return (
    <label
      className={cx({
        CheckboxInput: true,
        checked: checked || indetermined,
        disabled: disabled,
        [className ?? ""]: className !== undefined,
        [size ?? ""]: size !== undefined
      })}
    >
      <img
        src={checked ? CheckIcon : MinusIcon}
        className={styles.checkIcon}
        alt="checkmark"
      />

      <input
        tabIndex={0}
        type="checkbox"
        checked={checked || indetermined || false}
        onChange={(e) => onChange && onChange(e.target.checked)}
        onKeyDown={onKeyDown}
        disabled={disabled}
        readOnly={onChange === undefined}
      />
      {label}
    </label>
  );
};

export default CheckboxInput;
