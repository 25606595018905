import { DateTime } from "luxon";

export default function changeMonth(endDate: DateTime, numMonths = 0) {
  const nextMonth = endDate.plus({ month: numMonths });
  return DateTime.utc(
    nextMonth.year,
    nextMonth.month,
    nextMonth.daysInMonth ?? 30
  );
}
