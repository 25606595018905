import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ProgramCardDetail.module.scss";

type ProgramCardDetailProps = {
  title: string;
  content: string | number | React.ReactNode;
  imgSrc: string;
  isCountry?: boolean;
  className?: string;
};

export default function ProgramCardDetail({
  title,
  content = "-",
  className = "",
  imgSrc,
  isCountry
}: ProgramCardDetailProps) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  const imageClassnames =
    styles.icon + (isCountry ? ` ${styles.isCountry}` : "");

  const supportedTitles = ["newUsers", "activeUsers", "highRiskUsers"];

  return (
    <div
      data-testid="program-card-detail"
      className={`${styles.ProgramCardDetail} ${className}`}
    >
      <img src={imgSrc} className={imageClassnames} />
      <div
        className={styles.DetailDivider}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <h6 className={styles.title}>{`${t(`programCardDetail.${title}`)}`}</h6>
        <p className={styles.content}>{content}</p>
        {showTooltip && supportedTitles.includes(title) && (
          <div className={styles.tooltip}>{`${t(
            `programCardDetail.tooltip.${title}`
          )}`}</div>
        )}
      </div>
    </div>
  );
}
