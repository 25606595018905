import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./EditProvider.module.scss";

import api from "~/api/api";
import Modal from "~/components/modal/Modal";
import { ExternalUser } from "~/typing/sidekickTypes";

type EditProviderModalProps = {
  onClose: () => void;
  externalUser: ExternalUser;
};

export default function EditProviderModal({
  onClose,
  externalUser
}: EditProviderModalProps) {
  const { t } = useTranslation();
  const { program_id = "", locale = "" } = useParams();
  //const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [providerName, setProviderName] = useState(externalUser.providerName);
  const [providerEmail, setProviderEmail] = useState(
    externalUser.providerEmail
  );
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const handleSave = async () => {
    setLoading(true);
    const payload = externalUser;
    payload.providerEmail = providerEmail;
    payload.providerName = providerName;
    const path = `/coach/programs/${program_id}/locales/${locale}/externalusertypes/${externalUser.externalUserTypeId}/externalusers/${externalUser.id}`;
    await api
      .put(path, payload)
      .then(() => {
        setLoading(false);
        setSuccess(t("provider.success"));
      })
      .catch(() => {
        setLoading(false);
        setError(t("provider.error"));
      });
    setLoading(false);
  };
  return (
    <Modal onClose={onClose} title={"Edit Provider"}>
      <div className={styles.editProvider}>
        <label className={"label label--block"}>
          {t("registerPatient.providerName")}
        </label>
        <input
          type="text"
          value={providerName}
          className="input"
          onChange={(e) => setProviderName(e.target.value)}
        />
        <br></br>
        <br></br>
        <label className={"label label--block"}>
          {t("registerPatient.providerEmail")}
        </label>
        <input
          type="email"
          placeholder="someone@example.com"
          className="input"
          value={providerEmail}
          onChange={(e) => setProviderEmail(e.target.value)}
        />
        <div className={styles.error}>{error}</div>
        <div className={styles.success}>{success}</div>
        <button
          className={`btn-primary`}
          disabled={loading}
          onClick={handleSave}
        >
          {t("general.save")}
        </button>
        <br></br>
      </div>
    </Modal>
  );
}
