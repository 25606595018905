import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./AssignCoachMenu.module.scss";

import Avatar from "~/components/avatar/Avatar";
import Modal from "~/components/modal/Modal";
import CustomSelect from "~/components/select/CustomSelect";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { UserURLParams } from "~/typing/carePortalTypes";
import { Coach } from "~/typing/sidekickTypes";

type AssignCoachMenuProps = {
  coaches: Coach[];
  currentCoach?: Coach;
  onClose: () => void;
  onAssignCoach: (coachId: string) => Promise<boolean>;
};

export default function AssignCoachMenu({
  onClose,
  currentCoach,
  coaches,
  onAssignCoach
}: AssignCoachMenuProps) {
  const { t } = useTranslation();
  const [selectedCoach, setSelectedCoach] = useState(currentCoach?.userId);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { program_id = "", locale = "" } = useParams<UserURLParams>();
  const queryClient = useQueryClient();

  const renderCoachOption = (coach) => {
    return (
      <div className={styles.coachOption} key={coach ? coach.userId : "-1"}>
        {coach ? (
          <>
            <Avatar user={coach} className={styles.avatar} />
            <span>{coach.fullName}</span>
          </>
        ) : (
          t("user.coach.assignACoach")
        )}
      </div>
    );
  };
  const renderSelectedOption = (coach) => {
    return (
      <div className={styles.selectedOption} key={coach ? coach.userId : "-1"}>
        {coach ? (
          <>
            <Avatar user={coach} className={styles.avatar} />
            <span>{coach.fullName}</span>
          </>
        ) : (
          t("user.coach.assignACoach")
        )}
      </div>
    );
  };
  const handleClickAssign = async () => {
    setLoading(true);
    setErrorMessage("");
    if (!selectedCoach) return;
    const success = await onAssignCoach(selectedCoach);
    if (!success) {
      setErrorMessage(t("errors.generic"));
    }

    // Invalidate the user programs so that the user's coach list is updated
    queryClient.invalidateQueries({
      queryKey: QueryKeyFactory.programs.users(program_id, locale)
    });
    setLoading(false);
  };
  return (
    <Modal
      onClose={onClose}
      title={t("user.coach.assignTo")}
      className={styles.modal}
      contentClass={styles.content}
    >
      <div className={styles.innerContent}>
        <label>{t("user.coach.coach")}</label>
        <CustomSelect
          value={selectedCoach ? selectedCoach : ""}
          valueKey="userId"
          className={styles.coachMenu}
          onChange={(e) => setSelectedCoach(e.target.value)}
          renderOption={renderCoachOption}
          renderSelectedOption={renderSelectedOption}
          options={coaches}
          dataTestId={"assign-coach-menu-select"}
        />
        <p className={styles.error}>{errorMessage}</p>
        <button
          className="btn-primary"
          disabled={
            loading ||
            !selectedCoach ||
            (currentCoach && selectedCoach === currentCoach.userId)
          }
          onClick={handleClickAssign}
        >
          {t("user.coach.assign")}
        </button>
      </div>
    </Modal>
  );
}
