import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { UserPageContext } from "./UserPage.context";
import styles from "./UserPageSidenav.module.scss";

import DischargePatient from "../modals/DischargePatient";
import RestartPatient from "../modals/RestartPatient";

import AnalyticsBarsIcon from "~/assets/analyticsBars.svg";
import AttachmentIcon from "~/assets/attach-document.svg";
import MissionsIcon from "~/assets/checklist.svg";
import DoubleArrow from "~/assets/chevron-double-left.svg";
import DischargeIcon from "~/assets/discharge.svg";
import FoodIcon from "~/assets/food.svg";
import PerformanceIcon from "~/assets/graph.svg";
import PillIcon from "~/assets/pill.svg";
import RestartIcon from "~/assets/user-restart.svg";
import UserIcon from "~/assets/user.svg";
import Notification from "~/components/notification/Notification";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import { programHasBehaviour } from "~/helpers/program/programHelpers";
import { hasQuitOrFinished } from "~/helpers/user/userHelpers";
import { UserURLParams } from "~/typing/carePortalTypes";

type UserPageSideNavProps = {
  hasMealLogActions: boolean;
  hasSurveyActions: boolean;
  showMedicationCenterInCarePortal: boolean;
};

const UserPageSideNav = ({
  hasMealLogActions,
  hasSurveyActions,
  showMedicationCenterInCarePortal
}: UserPageSideNavProps) => {
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();
  const { t } = useTranslation();
  const [dischargeModalOpen, setDischargeModalOpen] = useState(false);
  const { userDetail, program } = useContext(UserPageContext);

  const navigate = useNavigate();
  const [shouldShowReadmissionModal, setShouldShowReadmissionModal] = useState(
    false
  );

  const [useSmall, setUseSmall] = useState(true);

  const handleDischargeModalClosed = (userDeleted: boolean) => {
    setDischargeModalOpen(false);
    if (userDeleted) {
      navigate(`/program/${program_id}/locale/${locale}/users`);
    }
  };

  const handleCloseReadmissionModal = () => {
    setShouldShowReadmissionModal(false);
  };

  const hasActionsForLink = (link) => {
    return (
      (link === "pro" && hasSurveyActions) ||
      (link === "food-journal" && hasMealLogActions)
    );
  };

  const linkData = [
    {
      iconSrc: UserIcon,
      title: t("general.information", "Information"),
      link: "information"
    },
    {
      iconSrc: FoodIcon,
      title: t("userSidenav.foodJournal", "Food Journal"),
      link: "food-journal"
    },
    {
      iconSrc: MissionsIcon,
      title: t("performance.missions"),
      link: "missions"
    },
    {
      iconSrc: AnalyticsBarsIcon,
      title: t("userSidenav.performance", "Performance"),
      link: "performance"
    },
    {
      iconSrc: PerformanceIcon,
      title: t("general.pro", "PRO"),
      link: "pro"
    },
    {
      iconSrc: AttachmentIcon,
      title: t("userSidenav.documents"),
      link: "documents"
    }
  ];

  //If medication center is enabled, add it to the sidenav
  if (showMedicationCenterInCarePortal) {
    linkData.splice(1, 0, {
      iconSrc: PillIcon,
      title: t("userSidenav.medication", "Medication"),
      link: "medication"
    });
  }

  const programThatIsDischargable = userDetail?.userPrograms?.find(
    (program) =>
      program.programCatalogItemId === program_id &&
      program.locale === locale &&
      !hasQuitOrFinished(program.status)
  );

  const activeProgramInOtherProgramCatalog = userDetail?.userPrograms?.find(
    (program) =>
      program.programCatalogItemId !== program_id &&
      !hasQuitOrFinished(program.status)
  );

  return (
    <>
      <div
        data-testid="userpage-sidenav-wrapper"
        className={`${styles.MvpSidenav}`}
      >
        <div
          onMouseEnter={() => setUseSmall(false)}
          onMouseLeave={() => setUseSmall(true)}
          className={`${styles.expandableContent} ${
            useSmall ? styles.small : ""
          }`}
        >
          <div
            onMouseEnter={() => setUseSmall(false)}
            onMouseLeave={() => setUseSmall(true)}
            className={`${styles.expandableContent} ${
              useSmall ? styles.small : ""
            }`}
          >
            <div>
              <div
                className={`${styles.link} ${styles.content} ${styles.arrowContainer}`}
                onTouchEnd={() => setUseSmall(!useSmall)}
              >
                <img
                  className={`${styles.icon} ${styles.arrow}`}
                  src={DoubleArrow}
                />
              </div>
              {linkData.map(({ iconSrc, title, link }) => {
                return (
                  <NavLink
                    key={title}
                    to={`/program/${program_id}/locale/${locale}/users/${user_id}/${link}`}
                    className={({ isActive }) =>
                      `${styles.link} ${isActive ? styles.active : ""}`
                    }
                    onClick={() => setUseSmall(true)}
                  >
                    <div className={styles.top}>
                      <div></div>
                    </div>
                    <div className={styles.content}>
                      <img className={styles.icon} src={iconSrc} />
                      <div className={styles.title}>{title}</div>
                      {hasActionsForLink(link) && (
                        <Notification
                          size="xs"
                          count={1}
                          className={styles.notification}
                        />
                      )}
                    </div>
                    <div className={styles.bottom}>
                      <div></div>
                    </div>
                  </NavLink>
                );
              })}
            </div>
            <div>
              {programHasBehaviour(program, ProgramBehaviour.Restartable) && (
                <div className={`${styles.link} ${styles.readmitLink}`}>
                  <div
                    data-testid="restart-program-btn"
                    className={`${styles.content} ${styles.readmit}`}
                    onClick={() => setShouldShowReadmissionModal(true)}
                  >
                    <img
                      className={`${styles.icon} ${styles.readmitIcon}`}
                      src={RestartIcon}
                    />
                    <div className={styles.title}>
                      {t("userSidenav.readmit")}
                    </div>
                  </div>
                </div>
              )}

              {programThatIsDischargable && (
                <div className={`${styles.link} ${styles.dischargeLink}`}>
                  <div
                    className={`${styles.content} ${styles.discharge}`}
                    onClick={() => setDischargeModalOpen(true)}
                  >
                    <img
                      className={`${styles.icon} ${styles.dischargeIcon}`}
                      src={DischargeIcon}
                    />
                    <div className={styles.title}>
                      {t("userSidenav.discharge", "Discharge patient")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {shouldShowReadmissionModal && (
        <RestartPatient
          close={handleCloseReadmissionModal}
          canBeRestarted={!activeProgramInOtherProgramCatalog}
        />
      )}
      {dischargeModalOpen && programThatIsDischargable && (
        <DischargePatient close={handleDischargeModalClosed} />
      )}
    </>
  );
};

export default UserPageSideNav;
