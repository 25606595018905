import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { NoteInfo } from "~/typing/sidekickTypes";

export default function useNotes(
  programCatalogItemId: string,
  locale: string,
  userId: string
) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/users/${userId}/journalitems`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: NoteInfo[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.notes(userId)
  });

  return {
    notes: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
