import { useQuery } from "@tanstack/react-query";

import { getCoachesForProgram } from "~/api/requests/coachRequests";

type CoachesForProgramProps = {
  programId: string;
  locale: string;
};

export const useCoachesForProgram = ({
  programId,
  locale
}: CoachesForProgramProps) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["coachesForProgram", programId],
    queryFn: () => getCoachesForProgram({ programId, locale })
  });

  return {
    coaches: data?.items,
    isLoading,
    isError
  };
};
