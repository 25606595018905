import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import TopNav from "~/components/topNav/TopNav";
import { UserURLParams } from "~/typing/carePortalTypes";

export default function PhysicalNav() {
  const { t } = useTranslation();
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const navData = [
    {
      title: t("performance.physical.activity", "Activity"),
      url: "activity"
    },
    {
      title: t("performance.physical.stepCounter", "Step counter"),
      url: "step-counter"
    },
    {
      title: t("performance.physical.fitnessTest", "Fitness test"),
      url: "fitness-test"
    }
  ];

  const baseUrl = `/program/${program_id}/locale/${locale}/users/${user_id}/performance/physical`;

  return <TopNav baseUrl={baseUrl} navData={navData} size="sm" />;
}
