import classNames from "classnames";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import styles from "./CareManagerTaskList.module.scss";
import { CareManagerTaskListItem } from "./CareManagerTaskListItem";

import AlertCircle from "~/assets/alert-circle.svg";
import CheckCircleBig from "~/assets/check-circle-big.svg";
import ChevronLeft from "~/assets/chevron-left-next-step.svg";
import ChevronRight from "~/assets/chevron-right-next-step.svg";
// import Clock from "~/assets/clock.svg";
import SearchInput from "~/components/searchInput/SearchInput";
import CustomSelect from "~/components/select/CustomSelect";
import SkeletonCareManagerTasks from "~/components/skeletons/SkeletonCareManagerTasks";
import { DEFAULT_FILTER_VALUE } from "~/constants/options";
import { useGetCareManagerTask } from "~/hooks/graphql/useCareManagerTasks";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { SelectOption } from "~/typing/carePortalTypes";
import { CareManagerTaskDto } from "~/typing/graphql/types";

const cx = classNames.bind(styles);

const NUMBER_OF_ROWS_OPTIONS = [
  { value: 5, text: 5 },
  { value: 10, text: 10 }
];

export const CareManagerTaskList = () => {
  const {
    careManagerTasks,
    isError,
    isLoading,
    status
  } = useGetCareManagerTask();

  const [careManagerTasksToDisplay, setCareManagerTasksToDisplay] = useState<
    CareManagerTaskDto[]
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [chosenProgramCatalogName, setChosenProgramCatalogName] = useState(
    DEFAULT_FILTER_VALUE
  );
  const [programOptions, setProgramOptions] = useState<SelectOption[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalRecords / pageSize)
  );
  // const [estimatedTime, setEstimatedTime] = useState(0);

  const [showOptions, setShowOptions] = useState(false);

  const {
    trackTaskPaginatorClicked,
    trackTaskRowsChanged
  } = useAmplitudeTracking();

  const filterTasks = useCallback(
    (careManagerTasks: CareManagerTaskDto[]) => {
      let filteredCareManagerTasks = careManagerTasks;
      if (searchValue) {
        filteredCareManagerTasks = careManagerTasks?.filter((task) =>
          task?.userFullName?.toLowerCase().includes(searchValue.toLowerCase())
        );
      }

      if (
        chosenProgramCatalogName &&
        chosenProgramCatalogName !== DEFAULT_FILTER_VALUE
      ) {
        filteredCareManagerTasks = filteredCareManagerTasks?.filter((task) =>
          task?.programCatalogItemName !== "N/A"
            ? task?.programCatalogItemName === chosenProgramCatalogName
            : false
        );
      }
      return filteredCareManagerTasks;
    },
    [searchValue, chosenProgramCatalogName]
  );

  useEffect(() => {
    // get all unique programs from the tasks
    if (!careManagerTasks || status !== "success" || programOptions.length)
      return;

    const options: SelectOption<string>[] = careManagerTasks?.careManagerTaskDtos
      ?.map((task) => ({
        text: task?.programCatalogItemName ?? "",
        value: task?.programCatalogItemName ?? ""
      }))
      .filter(
        (value, index, self) =>
          self.findIndex((t) => t.value === value.value) === index
      );
    setProgramOptions(
      [{ text: "All programs", value: DEFAULT_FILTER_VALUE }].concat(options)
    );
  }, [careManagerTasks]);

  useEffect(() => {
    if (careManagerTasks && status === "success") {
      if (
        careManagerTasks.careManagerTaskDtos &&
        careManagerTasks.careManagerTaskDtos.length > 0
      ) {
        const filteredCareManagerTasks = filterTasks(
          (careManagerTasks?.careManagerTaskDtos ?? []) as CareManagerTaskDto[]
        );

        const careManagerTasksToDisplay = filteredCareManagerTasks.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        ) as CareManagerTaskDto[];

        setCareManagerTasksToDisplay(careManagerTasksToDisplay);
        setTotalRecords(filteredCareManagerTasks?.length as number);
        // setEstimatedTime(
        //   careManagerTasks.careManagerTaskDtos?.reduce(
        //     (x: number, currentTask) =>
        //       currentTask && currentTask.reachOutReasons
        //         ? x +
        //           currentTask.reachOutReasons.reduce(
        //             (y: number, currRor) =>
        //               y + getEstimatedTimeForReachOutReason(currRor),
        //             0
        //           )
        //         : 0,
        //     0
        //   ) as number
        // );
        setShowOptions(true);
      }
    }
  }, [
    careManagerTasks,
    currentPage,
    pageSize,
    searchValue,
    chosenProgramCatalogName
  ]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageSize));
  }, [totalRecords, pageSize]);

  const handleSetPageSize = (e: any) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
    trackTaskRowsChanged();
  };

  const handlePageChange = (dir: "prev" | "next") => {
    if (dir === "prev") {
      setCurrentPage(currentPage - 1);
      trackTaskPaginatorClicked({ direction: "previous" });
    } else {
      setCurrentPage(currentPage + 1);
      trackTaskPaginatorClicked({ direction: "next" });
    }
  };

  /**
   * Handle search input change. Update search value and reset current page to 1 to show the first page of results.
   * If we dont' do that we might end up with empty results on the current page.
   * @param value Current search value
   */
  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(1);
  };

  /**
   * Handle program change. Update chosen program catalog name and reset current page to 1 to show the first page of results.
   * @param e Current selected program
   */
  const handleProgramChange = (e: any) => {
    setChosenProgramCatalogName(e.target.value ?? "N/A");
    setCurrentPage(1);
  };

  return (
    <>
      <div className={styles.header}>
        <h2>{t("nextStep.careManagerTasks.title")} </h2>
        {/* {showOptions && (
          <span className={styles.estimate}>
            {t("nextStep.careManagerTasks.estimate")} <img src={Clock} alt="" />{" "}
            {estimatedTime} {t("nextStep.careManagerTasks.min")}
          </span>
        )} */}
        {!isLoading && (
          <div className={styles.filters}>
            <SearchInput
              value={searchValue}
              onChange={handleSearch}
              placeholder="Search..."
            />
            <CustomSelect
              options={programOptions}
              renderOption={(option) => option.text}
              value={chosenProgramCatalogName}
              onChange={handleProgramChange}
            />
          </div>
        )}
      </div>
      <div className={styles.rows}>
        {!isLoading &&
          careManagerTasksToDisplay?.map((task: CareManagerTaskDto, index) => (
            <CareManagerTaskListItem task={task} key={`key-cmtd-${index}`} />
          ))}
        {!isLoading && careManagerTasksToDisplay?.length === 0 && (
          <div className={styles.emptyContainer}>
            <div
              className={cx({
                [styles.empty]: !isError,
                [styles.error]: isError
              })}
            >
              <img src={isError ? AlertCircle : CheckCircleBig} alt="Check" />
              {!isError
                ? t("nextStep.careManagerTasks.noTasks")
                : t("nextStep.careManagerTasks.error")}
            </div>
          </div>
        )}
      </div>

      {showOptions && (
        <div className={styles.footer}>
          <div className={styles.settings}>
            {t("nextStep.careManagerTasks.rowsPerPage")}
            <CustomSelect
              options={NUMBER_OF_ROWS_OPTIONS}
              renderOption={(option) => option.text}
              value={pageSize}
              onChange={(e) => handleSetPageSize(e)}
              className={styles.select}
            />
            <div className={styles.pages}>
              {pageSize * (currentPage - 1) + 1} -{" "}
              {Math.min(pageSize * currentPage, totalRecords)}{" "}
              {t("nextStep.careManagerTasks.of")} {totalRecords}
            </div>
            <div className={styles.prevNext}>
              <button
                onClick={() => handlePageChange("prev")}
                disabled={currentPage === 1}
              >
                <img src={ChevronLeft} />
              </button>
              <button
                onClick={() => handlePageChange("next")}
                disabled={currentPage === totalPages}
              >
                <img src={ChevronRight} />
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && <SkeletonCareManagerTasks />}
    </>
  );
};
