import { useTranslation } from "react-i18next";

import styles from "./WeeklyTheme.module.scss";

type WeeklyTheme = {
  slots: string[];
};

type Slot = {
  slotName: string;
  slotNo: number;
};

export default function WeeklyTheme({ slots }: WeeklyTheme) {
  const { t } = useTranslation();
  const oddSlots: Slot[] = [];
  const evenSlots: Slot[] = [];

  slots?.forEach((slotName, i) => {
    const slot = {
      slotName,
      slotNo: i + 1
    };
    if (i % 2) {
      evenSlots.push(slot);
    } else {
      oddSlots.push(slot);
    }
  });

  const areTotalSlotsEven = !(slots?.length % 2);

  const calcSlotContainerWidth = (slots, isEven) => {
    if (!isEven && !areTotalSlotsEven) {
      return "100%";
    }

    const numSlots = slots?.length;
    const percent =
      (numSlots / (numSlots + (!isEven || areTotalSlotsEven ? 0.5 : 1))) * 100;
    return `${percent}%`;
  };

  const calcBarWidth = (slots) => {
    const numSlots = slots?.length;
    const percent =
      ((numSlots - (areTotalSlotsEven ? 0.5 : 0)) /
        (numSlots + (areTotalSlotsEven ? 0.5 : 1))) *
      100;
    return `calc(${percent}% + 1rem)`;
  };

  const renderSlot = ({ slotNo, slotName }) => (
    <div className={styles.slot} key={slotNo}>
      <div className={styles.slotNumber}>{`${t("time.week")} ${slotNo}`}</div>
      <div className={styles.slotName}>{slotName}</div>
    </div>
  );

  const evenContainerClasses = `${styles.slotsContainer} ${
    areTotalSlotsEven ? styles.right : styles.center
  }`;

  return (
    <div className={styles.container}>
      <div
        className={evenContainerClasses}
        style={{ width: calcSlotContainerWidth(evenSlots, true) }}
      >
        {evenSlots?.map(renderSlot)}
      </div>
      <div
        className={styles.barContainer}
        style={{ width: calcBarWidth(evenSlots) }}
      >
        <div className={styles.bar}></div>
        {slots?.map((_, index) => (
          <div className={styles.bubble} key={`slot-${index}`} />
        ))}
      </div>
      <div
        className={styles.slotsContainer}
        style={{ width: calcSlotContainerWidth(oddSlots, false) }}
      >
        {oddSlots?.map(renderSlot)}
      </div>
    </div>
  );
}
