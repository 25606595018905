import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import styles from "./PROPage.module.scss";
import { PROPageContext } from "./proPageContext";

import { UserPageRouteContext } from "../../userPage/UserPage.context";
import PRONav from "../PRONav/PRONav";
import PROTemplate from "../PROTemplate/PROTemplate";

import SidekickShrugging from "~/assets/sidekick-shrugging.png";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import ToggleSwitchBool from "~/components/toggleSwitchBool/ToggleSwitchBool";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { useAutoTranslate } from "~/contexts/translationContext/useAutoTranslate";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import useSurveys from "~/hooks/useApi/useSurveys";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { PROURLParams } from "~/typing/carePortalTypes";

const PROPage = () => {
  const {
    program_id = "",
    locale = "",
    survey_id = "",
    user_id = ""
  } = useParams<PROURLParams>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [initialRouted, setInitialRouted] = useState(false);
  const { autoTranslate, setAutoTranslate } = useAutoTranslate();

  const [useTextBased, setUseTextBased] = useState(false);

  const { userDetail, program } = useOutletContext<UserPageRouteContext>();
  const { trackPROViewOpened } = useAmplitudeTracking();

  const { surveys, invalidate: invalidateSurveys } = useSurveys(
    program_id,
    locale
  );

  const { isEnabled: messageTranslationSegment } = useFeatureToggle({
    featureName: SegmentationKey.MessageTranslationSegment,
    userId: user_id
  });

  const surveysToDisplay = surveys?.filter((survey) => !survey.options?.quiz);

  const noSurveys = surveysToDisplay && surveysToDisplay.length === 0;

  const switchData = [
    {
      label: t("pro.text"),
      value: true
    },
    {
      label: t("pro.numerical"),
      value: false
    }
  ];

  useEffect(() => {
    if (!program) return;
    trackPROViewOpened();
  }, [program?.programCatalogItemId]);

  useEffect(() => {
    if (!survey_id && !initialRouted) {
      const surveyToNavigateTo =
        program?.defaultSurveyId || surveysToDisplay?.[0]?.id;
      setInitialRouted(surveyToNavigateTo ? true : false);
      if (surveyToNavigateTo) navigate(surveyToNavigateTo);
    }
  }, [survey_id, program, surveysToDisplay, initialRouted]);

  return (
    <SentryErrorBoundary
      resetState={() => invalidateSurveys()}
      transactionName="PROPage"
    >
      <PROPageContext.Provider
        value={{
          isLoading,
          setIsLoading,
          surveys: surveysToDisplay,
          setUseTextBased,
          useTextBased
        }}
      >
        <div className={styles.top}>
          <PRONav
            surveys={surveysToDisplay}
            latestSurveyResults={userDetail?.latestSurveyResults ?? []}
          />
          {messageTranslationSegment && (
            <div className={styles.spacedContainer}>
              <div className={styles.title}>{t("translation.settings.header")}</div>
              <div className={styles.toggleContainer}>
                <p>{t("translation.settings.autoTranslation")}</p>
                  <ToggleSwitchBool
                    setValue={setAutoTranslate}
                    currentValue={autoTranslate}
                  />
              </div>
            </div>
          )}
          <ToggleSwitch
            switchData={switchData}
            setValue={setUseTextBased}
            currentValue={useTextBased}
            className={styles.switch}
          />
        </div>
        <PROTemplate />
        {noSurveys && !isLoading && (
          <div className={styles.noSurveys}>
            <img src={SidekickShrugging} alt="shrugging sidekick" />
            <p>{t("pro.noPros", "There are no PROs for this program")}</p>
          </div>
        )}
      </PROPageContext.Provider>
    </SentryErrorBoundary>
  );
};

export default PROPage;
