import { ReactNode } from "react";

import styles from "./Modal.module.scss";

import Overlay from "../overlay/Overlay";

import Close from "~/assets/svgComponents/Close";
import useEscape from "~/hooks/useEscape";
import colors from "~/styles/colors";

type ModalProps = {
  title: string;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  contentClass?: string;
};

const Modal = ({
  className,
  contentClass,
  title,
  onClose,
  children
}: ModalProps) => {
  useEscape(() => onClose());

  return (
    <div className={styles.modalWrapper}>
      <Overlay />
      <div className={`${styles.container} ${className}`}>
        <header data-testid="modal-header" className={styles.header}>
          <span>{title}</span>
          <button data-testid="modal-header-close" onClick={onClose}>
            <Close color={colors.white} />
          </button>
        </header>
        <div className={contentClass ? contentClass : styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
