export default Object.freeze([
  {
    name: "English",
    locale: "en"
  },
  {
    name: "English",
    locale: "en-GB"
  },
  {
    name: "English (Ireland)",
    locale: "en-IE"
  },
  {
    name: "English (Australia)",
    locale: "en-AU"
  },
  {
    name: "Íslenska",
    locale: "is"
  },
  {
    name: "Finnish",
    locale: "fi"
  },
  {
    name: "French",
    locale: "fr"
  },
  {
    name: "French (Belgium)",
    locale: "fr-BE"
  },
  {
    name: "French (Canada)",
    locale: "fr-CA"
  },
  {
    name: "French (Switzerland)",
    locale: "fr-CH"
  },
  {
    name: "Svenska",
    locale: "sv"
  },
  {
    name: "Dansk",
    locale: "da"
  },
  {
    name: "Portuguese",
    locale: "pt"
  },
  {
    name: "Spanish",
    locale: "es"
  },
  {
    name: "Chinese",
    locale: "zh"
  },

  {
    name: "German",
    locale: "de"
  },
  {
    name: "German (Austria)",
    locale: "de-AT"
  },
  {
    name: "German (Switzerland)",
    locale: "de-CH"
  },
  {
    name: "French (Switzerland)",
    locale: "fr-CH"
  },
  {
    name: "Dutch (Belgium)",
    locale: "nl-BE"
  },
  {
    name: "Dutch (Netherlands)",
    locale: "nl-NL"
  },
  {
    name: "Portugese (Brazil)",
    locale: "pt-BR"
  },
  {
    name: "Spanish (Spain)",
    locale: "es-ES"
  },
  {
    name: "Spanish (Argentina)",
    locale: "es-AR"
  },
  {
    name: "Spanish (Mexico)",
    locale: "es-MX"
  },
  {
    name: "Spanish (Puerto Rico)",
    locale: "es-PR"
  },
  {
    name: "Spanish (United States)",
    locale: "es-US"
  }
]);
