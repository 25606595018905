import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./DeviceOrderStatus.module.scss";

import Close from "../../../../../assets/svgComponents/Close";
import { getBasicFormattedDate } from "../../../../../helpers/date/dateHelpers";
import useEscape from "../../../../../hooks/useEscape";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

import Pill from "~/components/pill/Pill";
import TimelineStatus from "~/components/timelineStatus/TimelineStatus";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { ExternalDeviceOrder } from "~/typing/sidekickTypes";

type DeviceOrderStatusProps = {
  name: string;
  orderDate: string;
  decoratedStatus: {
    title: string;
    backgroundColor: string;
    fontColor: string;
  };
  status: number;
  carrier?: string;
  trackingNumber?: string;
  trackingUrl?: string;
  orderId?: string;
};

enum DeviceStatus {
  Pending = "Pending",
  Ordered = "Ordered",
  Shipped = "Shipped",
  Delivered = "Delivered"
}

const DeviceOrderStatus = ({
  name,
  orderDate,
  decoratedStatus,
  status,
  carrier,
  orderId,
  trackingNumber,
  trackingUrl
}: DeviceOrderStatusProps) => {
  const [showDeviceStatus, setShowDeviceStatus] = useState(false);

  const { t } = useTranslation();

  const { trackDeviceOrderStatusSelected } = useAmplitudeTracking();
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (showDeviceStatus) {
      setShowDeviceStatus(false);
    }
  });

  useEscape(() => setShowDeviceStatus(false));

  const handleShowDeviceStatus = (show: boolean) => {
    setShowDeviceStatus(show);

    if (show) {
      trackDeviceOrderStatusSelected({
        deviceOrderDate: orderDate,
        deviceOrderStatus:
          timelineStatuses.find((ts) => ts.id === status)?.title ?? "Pending"
      });
    }
  };

  const timelineStatuses = [
    {
      title: DeviceStatus.Pending,
      id: ExternalDeviceOrder.Pending,
      order: ExternalDeviceOrder.Pending,
      date: DateTime.now().toISO()
    },
    {
      title: DeviceStatus.Ordered,
      id: ExternalDeviceOrder.Ordered,
      order: ExternalDeviceOrder.Ordered,
      date: DateTime.now().toISO()
    },
    {
      title: DeviceStatus.Shipped,
      id: ExternalDeviceOrder.Shipped,
      order: ExternalDeviceOrder.Shipped,
      date: DateTime.now().toISO()
    },
    {
      title: DeviceStatus.Delivered,
      id: ExternalDeviceOrder.Delivered,
      order: ExternalDeviceOrder.Delivered,
      date: DateTime.now().toISO()
    }
  ];

  return status ? (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.device}>
        <p className={styles.name}>{name}</p>
        <Pill
          onClick={() => handleShowDeviceStatus(!showDeviceStatus)}
          className={`${styles.status} ${
            showDeviceStatus && styles.selectedPill
          }`}
          {...decoratedStatus}
        />
      </div>
      {showDeviceStatus && (
        <div className={styles.outerWrapper}>
          <div className={styles.metaInfoWrapper}>
            <span className={styles.arrow} />
            <button
              onClick={() => setShowDeviceStatus(false)}
              className={styles.close}
            >
              <Close />
            </button>
            <span className={styles.metaInfo}>
              <p className={styles.title}>
                {t("devices.orderDate", "Order date")}:
              </p>
              <p>{getBasicFormattedDate(orderDate)}</p>
            </span>
            {orderId && (
              <span className={styles.metaInfo}>
                <p className={styles.title}>
                  {t("devices.orderId", "Order ID")}:
                </p>
                <div className={styles.trackingInfo}>
                  <p>{orderId}</p>
                </div>
              </span>
            )}
            {trackingNumber && trackingUrl && (
              <span className={styles.metaInfo}>
                <p className={styles.title}>
                  {t("devices.delivery", "Delivery")}:
                </p>
                <div className={styles.trackingInfo}>
                  <p className={styles.carrier}>{carrier}</p>
                  <a target="_blank" href={trackingUrl} rel="noreferrer">
                    {trackingNumber}
                  </a>
                </div>
              </span>
            )}

            <TimelineStatus
              statuses={timelineStatuses}
              currentStatus={
                timelineStatuses.find((ts) => ts.id === status)?.order
              }
            />
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default DeviceOrderStatus;
