import { useEffect, useState } from "react";

import styles from "./DeleteCareManagerModal.module.scss";

import ExpandedCheckboxSelect from "../expandedCheckBoxSelect/ExpandedCheckboxSelect";
import PreviewForm from "../previewForm/PreviewForm";
import SuccessScreen from "../success/SuccessScreen";

import Avatar from "~/components/avatar/Avatar";
import ModalWithFooterActions from "~/components/modal/ModalWithFooterActions";
import { useCareManagersAndInvites } from "~/hooks/graphql/useCareManagersAndInvites";
import usePrograms from "~/hooks/useApi/usePrograms";
import { t } from "~/i18n";
import { PreviewFormData } from "~/typing/carePortalTypes";
import {
  CareManagerWithPcIs,
  ProgramCatalogItemLocaleDto
} from "~/typing/graphql/types";

type DeleteCareManagerModalProps = {
  onClose: () => void;
  selectedCareManager: CareManagerWithPcIs;
};

enum DeleteCareManagerStep {
  NoPrograms,
  SelectCareManagers,
  Preview,
  Success
}

const DeleteCareManagerModal = ({
  onClose,
  selectedCareManager
}: DeleteCareManagerModalProps) => {
  // Local state
  const [currentStep, setCurrentStep] = useState<DeleteCareManagerStep>(
    DeleteCareManagerStep.SelectCareManagers
  );
  const [currentProgram, setCurrentProgram] = useState<
    ProgramCatalogItemLocaleDto | undefined
  >(selectedCareManager?.pcis?.[0]);
  const [
    selectedCareManagerForProgram,
    setSelectedCareManagerForProgram
  ] = useState<Record<string, string[]>>({});

  // Data fetching hooks
  const { coaches } = useCareManagersAndInvites();
  const { programs } = usePrograms();

  useEffect(() => {
    if (!selectedCareManager?.pcis?.length) {
      setCurrentStep(DeleteCareManagerStep.NoPrograms);
    }
  }, [selectedCareManager]);

  const convertCoachesToOptions = (careManagers: CareManagerWithPcIs[]) => {
    return careManagers
      .filter((cm) =>
        cm.pcis?.some(
          (pci) =>
            pci?.programCatalogItemId ===
              currentProgram?.programCatalogItemId &&
            pci?.locale === currentProgram?.locale
        )
      )
      .map(({ careManager }) => ({
        text: careManager?.name ?? careManager?.email ?? "",
        value: careManager?.email ?? "",
        renderOption: () => (
          <div className={styles.coachOption}>
            <Avatar
              user={{
                fullName: careManager?.name ?? "",
                imageHref: careManager?.imageHref
              }}
            />
            <span>{careManager?.name}</span>
          </div>
        )
      }));
  };

  const setCoachesForProgram = (coaches: string[]) => {
    setSelectedCareManagerForProgram({
      ...selectedCareManagerForProgram,
      [currentProgram?.programCatalogItemId ?? ""]: coaches
    });
  };

  const handleNextButtonClick = () => {
    if (currentStep === DeleteCareManagerStep.SelectCareManagers) {
      const nextProgramIndex = selectedCareManager?.pcis?.findIndex(
        (pci) =>
          pci?.programCatalogItemId === currentProgram?.programCatalogItemId
      );
      if (
        nextProgramIndex &&
        nextProgramIndex < (selectedCareManager.pcis?.length ?? 0) - 1
      ) {
        setCurrentProgram(selectedCareManager.pcis?.[nextProgramIndex + 1]);
      } else {
        setCurrentStep(DeleteCareManagerStep.Preview);
      }
    } else if (currentStep === DeleteCareManagerStep.Preview) {
      setCurrentStep(DeleteCareManagerStep.Success);
    } else {
      onClose();
    }
  };

  const handleBackButtonClick = () => {
    if (currentStep === DeleteCareManagerStep.Preview) {
      setCurrentStep(DeleteCareManagerStep.SelectCareManagers);
    } else {
      onClose();
    }
  };

  const getNextButtonLabel = () => {
    if (currentStep === DeleteCareManagerStep.SelectCareManagers) {
      return t("general.next");
    } else if (currentStep === DeleteCareManagerStep.Preview) {
      return t("general.remove");
    } else if (currentStep === DeleteCareManagerStep.NoPrograms) {
      return t("general.yes");
    } else {
      return t("general.close");
    }
  };

  const getBackButtonLabel = () => {
    if (currentStep === DeleteCareManagerStep.Preview) {
      return t("general.back");
    } else if (currentStep === DeleteCareManagerStep.NoPrograms) {
      return t("general.no");
    } else {
      return t("general.cancel");
    }
  };

  const getPreviewFormData = (): PreviewFormData[] => {
    const previewData: PreviewFormData[] = [];

    for (const pci of selectedCareManager.pcis ?? []) {
      const program = programs.find(
        (program) =>
          program.programCatalogItemId === pci?.programCatalogItemId.toString()
      );
      const careManagersForProgram = coaches.filter((coach) =>
        selectedCareManagerForProgram[pci?.locale ?? ""]?.includes(
          coach.careManager?.email ?? ""
        )
      );

      previewData.push({
        values: [
          {
            renderOption: () => program?.name
          },
          {
            renderOption: () => "24 users"
          }
        ]
      });
      previewData.push({
        withBorder: true,
        heading: t("accessManagement.deleteCareManagerModal.reassignedToCareManagers"),
        values: careManagersForProgram.map(({ careManager }) => ({
          renderOption: () => (
            <Avatar
              displayTitle
              user={{
                fullName: careManager?.name ?? "",
                imageHref: careManager?.imageHref
              }}
            />
          )
        }))
      });
    }

    return previewData;
  };

  return (
    <ModalWithFooterActions
      title={t("accessManagement.deleteCareManagerModal.deleteCareManager")}
      contentClass={styles.modalContent}
      onClose={onClose}
      actions={[
        {
          label: getBackButtonLabel(),
          onClick: handleBackButtonClick,
          hidden: currentStep === DeleteCareManagerStep.Success,
          inverted: true
        },
        {
          label: getNextButtonLabel(),
          onClick: handleNextButtonClick
        }
      ]}
      className={styles.modal}
    >
      {[
        DeleteCareManagerStep.Preview,
        DeleteCareManagerStep.SelectCareManagers
      ].includes(currentStep) && (
        <div className={styles.avatarWrapper}>
          <p>{t("roles.careManager")}</p>
          <Avatar
            user={{
              fullName: selectedCareManager.careManager?.name ?? "",
              imageHref: selectedCareManager.careManager?.imageHref
            }}
            displayTitle
          />
        </div>
      )}
      {currentStep === DeleteCareManagerStep.NoPrograms && (
        <p>
          {t("accessManagement.deleteCareManagerModal.noUsersAssigned", {
            careManager: selectedCareManager.careManager?.name
          })}
        </p>
      )}
      {currentStep === DeleteCareManagerStep.SelectCareManagers && (
        <ExpandedCheckboxSelect
          description={t("accessManagement.deleteCareManagerModal.careManagerHasUsers", {
            "careManager": selectedCareManager?.careManager?.name,
            "userCount": selectedCareManager?.coachUserStatistics?.reduce(
              (acc, stats) => acc + (stats?.totalUserCount ?? 0),
              0
            )
          })}
          options={convertCoachesToOptions(coaches)}
          onChange={setCoachesForProgram}
          selected={
            selectedCareManagerForProgram[
              currentProgram?.programCatalogItemId || ""
            ]
          }
        />
      )}
      {currentStep === DeleteCareManagerStep.Preview && (
        <PreviewForm data={getPreviewFormData()} />
      )}
      {currentStep === DeleteCareManagerStep.Success && (
        <SuccessScreen text={t("accessManagement.deleteCareManagerModal.careManagerRemoved")} />
      )}
    </ModalWithFooterActions>
  );
};

export default DeleteCareManagerModal;