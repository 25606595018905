import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./MobileMenu.module.scss";

import MenuIcon from "~/assets/svgComponents/MenuIcon";
import config from "~/config";

type MenuItem = {
  link: string;
  text: string;
  hidden?: boolean;
};

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const { t } = useTranslation();

  const menuItems: MenuItem[] = [
    {
      link: "/programs",
      text: t("header.programs", "Programs")
    },
    {
      link: "/access-management",
      text: t("header.accessManagement", "Access Management"),
      hidden: !config.isAnthem
    },
    {
      link: "/userguide",
      text: t("header.userGuide", "User Guide"),
      hidden: !config.isAnthem
    },
    {
      link: "/iframe?src=anthemDashboard",
      text: t("header.anthemDashboard", "Anthem Dashboard"),
      hidden: !config.isAnthem
    },
    {
      link: "/me",
      text: t("header.myPage", "My page")
    },
    {
      link: "/logout",
      text: t("header.logout", "Log out")
    }
  ];

  return (
    <div className={styles.menu}>
      <button onClick={() => setShowMenu((prev) => !prev)}>
        <MenuIcon />
      </button>
      {showMenu && (
        <ul className={styles.menuItems}>
          {menuItems.map(
            (item) =>
              !item.hidden && (
                <li key={item.link}>
                  <Link to={item.link}>{item.text}</Link>
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
};

export default Menu;
