import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./SurveyResultExport.module.scss";

import api from "~/api/api";
import CustomDatePicker from "~/components/customDatePicker/CustomDatePicker";
import Modal from "~/components/modal/Modal";
import CustomSelect from "~/components/select/CustomSelect";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import { Survey } from "~/typing/sidekickTypes";

type SurveyResultExportProps = {
  onClose: () => void;
  surveys: Survey[];
};

export default function SurveyResultExport({
  onClose,
  surveys
}: SurveyResultExportProps) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ day: 7 }).toJSDate()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toJSDate());
  const { program_id = "", locale = "" } = useParams();
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setStartDate(DateTime.now().minus({ day: 7 }).toJSDate());
    setEndDate(DateTime.now().toJSDate());
    setSelectedSurvey(undefined);
    setErrorMessage("");
    setLoading(false);
  };

  const findSurveyById = (id) => {
    return surveys.find((survey) => survey.id === id)?.surveyName;
  };

  const getSurveyResultExport = async () => {
    setLoading(true);
    setErrorMessage("");
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    const exportResponse = await api.get(
      `/coach/programs/${program_id}/locales/${locale}/surveyresults/export?surveyId=${selectedSurvey}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );
    if (exportResponse?.data) {
      const blob = new Blob([exportResponse.data.file]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${findSurveyById(selectedSurvey)}_results.csv`;
      link.click();
      displaySuccessToast({ message: t("surveyResultExport.messageSuccess") });
    } else {
      setErrorMessage(t("surveyResultExport.error"));
    }
    setLoading(false);
  };

  const handleSurveyResultExport = () => {
    getSurveyResultExport();
  };

  const isValid = () => {
    return (
      !loading &&
      selectedSurvey &&
      endDate &&
      startDate &&
      startDate <= endDate &&
      endDate <= DateTime.now().toJSDate()
    );
  };

  return (
    <>
      <Modal
        title={t("surveyResultExport.exportProAsCsv")}
        className={styles.modal}
        onClose={onClose}
      >
        <SentryErrorBoundary
          resetState={resetState}
          transactionName="SurveyResultExport"
        >
          <div className={styles.surveySelectContainer}>
            <span className={styles.label}>{t("general.pro")}</span>
            <div className={styles.surveySelect}>
              <CustomSelect
                value={selectedSurvey}
                valueKey="id"
                onChange={(e) => setSelectedSurvey(e.target.value)}
                renderOption={(option) => option?.title}
                options={surveys}
              />
            </div>
          </div>
          <div className={styles.datePickerContainer}>
            <span className={styles.label}>
              {t("surveyResultExport.period")}
            </span>
            <div className={styles.datePicker}>
              <CustomDatePicker
                placeholderText={t("general.from")}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <CustomDatePicker
                placeholderText={t("general.to")}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
          <div>{t("surveyResultExport.todaysResultsNotIncluded")}</div>
          {errorMessage && (
            <div className={styles.errorBlock}>{errorMessage}</div>
          )}
          <div className={styles.action}>
            <button
              type="button"
              className="btn-primary btn-sm"
              onClick={handleSurveyResultExport}
              disabled={!isValid()}
            >
              {t("general.export")}
            </button>
          </div>
        </SentryErrorBoundary>
      </Modal>
    </>
  );
}
