import { useRef, useState } from "react";

import styles from "./AddNoteModal.module.scss";
import NoteForm from "./NoteForm";

import ConfirmModal from "../../pages/team/ConfirmModal";

import Modal from "~/components/modal/Modal";
import { useUserNotes } from "~/hooks/useUserNotes";
import { t } from "~/i18n";

interface UserNotesProps {
  onClose: () => void;
  showModal: boolean;
}

export const AddNoteModal = ({ onClose, showModal }: UserNotesProps) => {
  const { addNote } = useUserNotes();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleClose = () => {
    if (textAreaRef.current && textAreaRef.current.value.length > 0) {
      setShowConfirmModal(true);
    } else {
      onClose();
    }
  };

  const handleSaveNote = async (
    newNote: string,
    itemType: number,
    isPinned: boolean
  ) => {
    const success = await addNote(newNote, itemType, isPinned);
    if (success) {
      onClose();
    }
  };

  return showModal ? (
    <Modal
      onClose={handleClose}
      title={t("notes.addNote", "Add note")}
      className={styles.modal}
    >
      {showConfirmModal && (
        <ConfirmModal
          modalTitle={t("notes.unsaved")}
          confirmSubtitle={t("notes.closeWarning")}
          confirmText={t("general.close")}
          cancelText={t("general.back")}
          onClose={() => {
            setShowConfirmModal(false);
            if (textAreaRef?.current) textAreaRef.current.focus();
          }}
          onConfirm={() => {
            setShowConfirmModal(false);
            onClose();
          }}
          type="warning"
        />
      )}
      <NoteForm
        standalone
        onSave={handleSaveNote}
        onCancel={handleClose}
        textAreaRef={textAreaRef}
      />
    </Modal>
  ) : null;
};

export default AddNoteModal;
