import { useSelector } from "react-redux";

import { RootState } from "~/state/store";
import { ExternalUser } from "~/typing/sidekickTypes";

const useExternalusers = (
  programId: string,
  userTypeId?: string
): {
  loading: boolean;
  users: ExternalUser[];
  searchString: string;
  showUnseenSms: boolean;
} => {
  const state = useSelector((state: RootState) => state.externalUsers);

  if (!state.externalUsers) state.externalUsers = {};
  const users = state.externalUsers[`${programId}-${userTypeId}`];

  if (!state.externalUsersSearchString) state.externalUsersSearchString = {};
  const searchString = state.externalUsersSearchString[`${programId}`];

  if (!state.externalUsersView) state.externalUsersView = {};
  const showUnseenSms = state.externalUsersView[`${programId}`] === "unseenSms";

  return {
    loading: state.externalUsersStatus === "loading",
    users,
    searchString,
    showUnseenSms
  };
};

export default useExternalusers;
