import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { DateTime } from "luxon";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";

import Message from "./Message";
import { MessageListContext } from "./MessageList.context";
import { getHighestReadMessage } from "./MessageList.helpers";
import styles from "./MessageList.module.scss";
import SMSChatMessage from "./SMSMessage";

import { readAllMessages } from "~/api/requests/messageRequests";
import config from "~/config";
import { dateIsToday, isSameDate } from "~/helpers/date/dateHelpers";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { TaskPageContext } from "~/pages/nextStep/TaskPage.context";
import { UnreadMessagesState } from "~/pages/user/userPage/unreadMessagesReducer";
import {
  ChatMessage,
  Coach,
  EnrichedAuthenticatedUser,
  MessageType,
  Program,
  SMSMessage,
  UserDetailsUser
} from "~/typing/sidekickTypes";

const cx = classNames.bind(styles);

type MessageListProps = {
  messages: MessageType[];
  user: {
    user: UserDetailsUser;
    externalUser?: { externalUserTypeId: string; id: string };
  };
  optedOut: boolean;
  footerHidden?: boolean;
  className?: string;
  usersForAvatars?: { [key: number]: Coach };
  authUser?: EnrichedAuthenticatedUser;
  setMessageBeingRepliedTo: (message: ChatMessage) => void;
  unreadMessages?: UnreadMessagesState;
  minimized?: boolean;
  chosenManager?: Coach;
  programThatUserIsIn: Program;
  readMessageOnView?: boolean;
  searchQuery?: string;
};

const PAGE_SIZE = 50;

const MessageList = ({
  messages,
  user,
  optedOut,
  footerHidden,
  className,
  usersForAvatars,
  authUser,
  setMessageBeingRepliedTo,
  unreadMessages,
  minimized,
  chosenManager,
  programThatUserIsIn,
  readMessageOnView = true,
  searchQuery = ""
}: MessageListProps) => {
  const { t } = useTranslation();

  const { newNextStep } = useContext(TaskPageContext);
  const [messagesToDisplay, setMessagesToDisplay] = useState<MessageType[]>(
    messages.slice(-PAGE_SIZE)
  );
  const [scrollHeightToRestore, setScrollHeightToRestore] = useState<number>(0);
  const [isChangingChosenManager, setIsChangingChosenManager] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(
    currentPage * PAGE_SIZE < messages.length
  );

  const messagesRef = useRef<HTMLDivElement>(null);
  const messagesChunkDate = useRef<DateTime | null>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    // If there are unread messages, invalidate the users query since the CM is about to read the message
    const someMessageUnread = messages.some((msg) => !msg.seenDate);

    if (someMessageUnread) {
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.programs.users(
          programThatUserIsIn.programCatalogItemId,
          programThatUserIsIn.locale
        )
      });
    }
  }, [messages]);

  const scrollToBottomTimeout = (timeOut = 0) => {
    return setTimeout(() => {
      // scroll to span with id "last" to scroll to the bottom of the chat window
      const lastSpan = document.getElementById("last");
      if (lastSpan) {
        lastSpan.scrollIntoView(false);
      }
    }, timeOut);
  };

  const filterMsgsBySearchQuery = (messages) => {
    if (!searchQuery) return messages;
    return (
      messages?.filter(
        (msg) =>
          msg.text?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          msg.message?.toLowerCase().includes(searchQuery?.toLowerCase())
      ) || []
    );
  };

  // Hide messages while changing manager for a short time for a "smoother" transition between managers
  useEffect(() => {
    setCurrentPage(1);
    setIsChangingChosenManager(true);
    const hiddenTimeout = setTimeout(
      () => setIsChangingChosenManager(false),
      150
    );
    return () => clearTimeout(hiddenTimeout);
  }, [chosenManager?.userId]);

  useEffect(() => {
    const scrollDownTimeout = scrollToBottomTimeout(100);
    return () => clearTimeout(scrollDownTimeout);
  }, [chosenManager?.userId, messages.length]);

  useEffect(() => {
    const filteredMessages = filterMsgsBySearchQuery(messages);

    setMessagesToDisplay(filteredMessages.slice(-PAGE_SIZE * currentPage));
    setHasMore(currentPage * PAGE_SIZE < filteredMessages.length);
    messagesChunkDate.current = null;
  }, [messages, searchQuery]);

  const loadMoreMessages = () => {
    const newPage = currentPage + 1;
    // Save current scroll position before adding new messages
    const oldScrollHeight = messagesRef.current?.scrollHeight;
    const filteredMessages = filterMsgsBySearchQuery(messages);

    setScrollHeightToRestore(oldScrollHeight ?? 0);
    setMessagesToDisplay(filteredMessages.slice(-PAGE_SIZE * newPage));
    setCurrentPage(newPage);
    setHasMore(newPage * PAGE_SIZE < filteredMessages.length);
  };

  const renderMessageChunkDate = useCallback(
    (date: string, startOfMessages: boolean) => {
      if (!newNextStep) return;
      const messageDate = DateTime.fromISO(date).setLocale(
        config.isAnthem ? "en-us" : "en-GB"
      );

      const theSameDate =
        !!messagesChunkDate.current &&
        isSameDate(messageDate, messagesChunkDate.current);

      if (!theSameDate) {
        messagesChunkDate.current = messageDate;
      }

      const isToday =
        !!messagesChunkDate.current &&
        dateIsToday(messagesChunkDate.current.toString());

      return (
        (startOfMessages || !theSameDate) && (
          <div className={styles.messagesChunkDate}>
            {isToday
              ? t("time.today")
              : messageDate.toLocaleString({
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit"
                })}
          </div>
        )
      );
    },
    []
  );
  useEffect(() => {
    if (currentPage === 1) return;

    if (messagesRef.current) {
      messagesRef.current.scrollTop =
        messagesRef.current.scrollHeight - scrollHeightToRestore;
    }
  }, [currentPage]);

  useEffect(() => {
    if ((unreadMessages?.totalToAuthUser ?? 0) > 0) {
      readAllMessages({
        programId: programThatUserIsIn.programCatalogItemId,
        locale: programThatUserIsIn.locale,
        userId: user.user.userId
      });
    }
  }, [unreadMessages]);

  const highestReadMessage = getHighestReadMessage(
    messagesToDisplay,
    user.user
  );

  return !minimized ? (
    <MessageListContext.Provider
      value={{
        usersForAvatars: usersForAvatars ?? {},
        authUser,
        setMessageBeingRepliedTo,
        unreadMessages,
        program: programThatUserIsIn,
        readMessageOnView,
        searchQuery
      }}
    >
      <div
        className={cx({
          body: true,
          minimized: minimized,
          [className ?? ""]: className !== undefined,
          hiddenScroll: isChangingChosenManager
        })}
        ref={messagesRef}
      >
        <div
          className={cx({
            MessageList: true,
            footerHidden: footerHidden,
            hiddenChildren: isChangingChosenManager,
            newNextStep
          })}
          data-testid="message-list"
        >
          {hasMore && (
            <button
              data-testid="load-more-msg-button"
              className={styles.loadMoreBtn}
              onClick={loadMoreMessages}
            >
              Load more
            </button>
          )}
          {messagesToDisplay.map((message, index) => {
            const messageBeingRepliedTo =
              "repliedToMessageId" in message && message?.repliedToMessageId
                ? ([...messages].find(
                    (msg) => msg.id === message.repliedToMessageId
                  ) as MessageType)
                : undefined;

            return (
              <Fragment key={`message-${index}`}>
                {renderMessageChunkDate(message.createdDate, index === 0)}
                {messageBeingRepliedTo && (
                  <Message
                    repliedMessage={true}
                    user={user.user}
                    message={messageBeingRepliedTo as ChatMessage}
                    isHighestRead={highestReadMessage?.id === message.id}
                  />
                )}
                {message.phoneNumber ? (
                  <SMSChatMessage
                    message={message as SMSMessage}
                    isHighestRead={highestReadMessage?.id === message.id}
                    user={user}
                  />
                ) : (
                  <Message
                    message={message as ChatMessage}
                    isHighestRead={highestReadMessage?.id === message.id}
                    user={user.user}
                  />
                )}
              </Fragment>
            );
          })}
          {messagesToDisplay.length === 0 && (
            <p className={styles.centeredText}>{t("messages.noMessages")}</p>
          )}
          {optedOut && (
            <p className={styles.centeredText}>{t("messages.optedOut")}</p>
          )}
          <span id="last" />
        </div>
      </div>
    </MessageListContext.Provider>
  ) : null;
};

export default MessageList;
