import classNames from "classnames/bind";
import { Link, useLocation } from "react-router-dom";

import styles from "./NavHeaderLink.module.scss";

import { SegmentationKey } from "~/constants/segmentationKeys";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import useUser from "~/hooks/useUser";

const cx = classNames.bind(styles);

type NavHeaderLinkProps = {
  href: string;
  text: string;
  highlightOnPath?: string;
  hidden?: boolean;
};

const NavHeaderLink = ({
  href,
  text,
  highlightOnPath,
  hidden
}: NavHeaderLinkProps) => {
  const location = useLocation();

  const user = useUser();

  const { isEnabled: nextStepEnabled } = useFeatureToggle({
    featureName: SegmentationKey.CoachCentricView,
    userId: user?.userId
  });

  if (hidden) return null;

  return (
    <Link
      to={href}
      className={cx({
        nextStep: nextStepEnabled,
        link: true,
        active:
          location.pathname.startsWith(href) ||
          (highlightOnPath && location.pathname.startsWith(highlightOnPath))
      })}
    >
      {text}
    </Link>
  );
};

export default NavHeaderLink;
