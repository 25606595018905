import { ReactNode } from "react";

import styles from "./InformationPageHeaderInfo.module.scss";

type InformationPageHeaderInfoProps = {
  title: string;
  renderDetails: () => ReactNode;
};

const InformationPageHeaderInfo = ({
  title,
  renderDetails
}: InformationPageHeaderInfoProps) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{`${title}:`}</p>
      {renderDetails()}
    </div>
  );
};

export default InformationPageHeaderInfo;
