import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import useLoggedIn from "../useLoggedIn";

import sortByDatakey from "~/helpers/sortByDatakey";
import { Program } from "~/typing/sidekickTypes";

export default function usePrograms(includeHighRiskUserCount = false) {
  const path = `/coach/programs${
    includeHighRiskUserCount ? "" : "?includeHighRiskUserCount=false"
  }`;

  const isLoggedIn = useLoggedIn();

  const { data, isError, isLoading, invalidate } = useApi<{
    items: Program[];
  }>({
    path,
    queryKey: QueryKeyFactory.programs.all,
    refetchOnWindowFocus: false,
    shouldRetry: false,
    disabled: !isLoggedIn
  });

  const programs = data?.items ?? [];
  programs?.forEach((program) => {
    program.coaches = program.coaches?.sort((a, b) =>
      sortByDatakey(a, b, "displayName")
    );
  });

  return {
    programs,
    isError,
    isLoading,
    invalidate
  };
}
