import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { ExternalUserGroupOutput } from "~/typing/sidekickTypes";

interface Props {
    /**
     * The identifier of the file. If none is supplied, fetch latest file
     * @type {number}
     */
    fileUploadId?: number | 'latest';

    /**
     * Whether to filter out terminated groups. Should be set to true if choosing a new group to assign a user
     * @type {boolean}
     */
    skipTerminatedClients?: boolean;
}

export default function useExternalUserGroups({
    fileUploadId = 'latest',
    skipTerminatedClients = false,
}: Props = {}) {
    const path = `/coach/externalusergroups/${fileUploadId}`;
    const { data, isError, isLoading, invalidate } = useApi<{
        items: ExternalUserGroupOutput[];
    }>({
        path,
        queryKey: QueryKeyFactory.userGroups(skipTerminatedClients),
    });
    let groups = data?.items
    if (groups && skipTerminatedClients) {
        groups = groups.filter(g => {
            if (!g.clientTerminationDate) {
                return true;
            }
            const terminationDate = new Date(g.clientTerminationDate)
            const now = new Date().getTime();
            return isNaN(terminationDate.getTime()) || terminationDate.getTime() > now
        })
    }
    return {
        groups,
        isError,
        isLoading,
        invalidate
    };
}
