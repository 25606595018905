import React from 'react';
import './ToggleSwitchBool.scss';

type ToggleSwitchBoolProps= {
    setValue: (value: boolean) => void;
    currentValue: boolean;
};


const ToggleSwitchBool = ({
    setValue, currentValue
}: ToggleSwitchBoolProps) => {

  const handleToggle = () => {
    setValue(!currentValue);
  };

  return (
    <label className="toggle-switch-boolean">
      <input
        type="checkbox"
        checked={currentValue}
        onChange={handleToggle}
      />
      <span className="slider"></span>
    </label>
  );
};

export default ToggleSwitchBool;