import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import useApi from "~/hooks/useApi/useApi";
import { ImportEntry } from "~/typing/sidekickTypes";

export default function useImportEntries() {
  const path = `/admin/extras/importentries`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: ImportEntry[];
  }>({
    path,
    queryKey: QueryKeyFactory.importEntries
  });

  return {
    importEntries: data?.items?.sort((a, b) =>
      a.createdDate > b.createdDate ? -1 : 1
    ),
    isError,
    isLoading,
    invalidate
  };
}
