import { useTranslation } from "react-i18next";

import styles from "./MessagePreview.module.scss";

import CloseIcon from "~/assets/close.svg";
import getMessageHtml from "~/helpers/getMessageHtml";
import getMessageMarkdown from "~/helpers/getMessageMarkdown";

type MessagePreviewProps = {
  message: any;
  toggleMessagePreview: () => void;
};

const MessagePreview = ({
  message,
  toggleMessagePreview
}: MessagePreviewProps) => {
  const { t } = useTranslation();
  const getMessagePreview = () => {
    const markdown = getMessageMarkdown(message);
    return getMessageHtml(markdown);
  };

  return (
    <div className={styles.popup}>
      <div className={styles.popupHeader}>
        <div className={styles.title}>
          {t("messages.messagesWindow.messagePreview")}
        </div>
        <button className={styles.close} onClick={toggleMessagePreview}>
          <img src={CloseIcon} />
        </button>
      </div>
      <p>{getMessagePreview()}</p>
    </div>
  );
};

export default MessagePreview;
