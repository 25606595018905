import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./PreselectionModal.module.scss";
import ValuePreselectionSection from "./ValuePreselectionSection";

import api from "~/api/api";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import Modal from "~/components/modal/Modal";
import { UserURLParams } from "~/typing/carePortalTypes";

type ValuePreselectionModalProps = {
  onClose: () => void;
  userMissionId: string;
};

type ContentItem = {
  [key: string]: string;
};

const PreSelectionModal = ({
  onClose,
  userMissionId
}: ValuePreselectionModalProps) => {
  const [contentItem, setContentItem] = useState<ContentItem>({});
  const [answers, setAnswers] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  useEffect(() => {
    const fetchValuePreselectionData = async () => {
      setLoading(true);
      const res = await api.get(
        `/coach/programs/${program_id}/locales/${locale}/users/${user_id}/valuepreselectionresult/${userMissionId}`
      );
      const selectedAnswersData = res?.data?.items?.[0]?.items?.map(
        (answer) => {
          return answer.itemValue;
        }
      );

      setSelectedAnswers(selectedAnswersData);

      const contentBundleId = res?.data?.items?.[0]?.contentBundleId;
      const contentRes = await api.get(
        `/coach/contentbundles/${contentBundleId}/locales/${locale}/users/${user_id}`
      );

      const contentItemData = {};
      contentRes?.data?.contentItem?.contentItemValues?.forEach(
        (contentItemValue) => {
          const { name, value } = contentItemValue;
          contentItemData[name] = value;
        }
      );
      setContentItem(contentItemData);

      const answersData = contentRes?.data?.itemContentItems?.map(
        (contentItemValue) => {
          return contentItemValue?.contentItemValues?.[0]?.value;
        }
      );
      setAnswers(answersData);
      setLoading(false);
    };

    fetchValuePreselectionData();
  }, []);

  return (
    <Modal
      title={contentItem.title ?? ""}
      className={styles.modal}
      onClose={onClose}
    >
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <ValuePreselectionSection
            {...contentItem}
            answers={answers}
            selectedAnswers={selectedAnswers}
          />
          <div className={styles.separator}></div>
          <ValuePreselectionSection
            {...contentItem}
            ordered={true}
            selectedAnswers={selectedAnswers}
          />
        </>
      )}
    </Modal>
  );
};

export default PreSelectionModal;
