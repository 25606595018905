import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import styles from "./ProgramLocaleNav.module.scss";

import TopNav from "~/components/topNav/TopNav";
import locales from "~/constants/locales";
import { setProgramLocaleNav } from "~/state/programLocaleNav/programLocaleNavSlice";
import { NavData } from "~/typing/carePortalTypes";

type ProgramLocaleNavProps = {
  programLocales: string[];
};

const ProgramLocaleNav = ({ programLocales }: ProgramLocaleNavProps) => {
  const { program_id = "" } = useParams();
  const dispatch = useDispatch();

  const getNavData = (): NavData[] => {
    return programLocales.map((programLocale) => ({
      title:
        locales.find((locale) => locale.locale === programLocale)?.name || "",
      url: programLocale
    }));
  };

  const baseUrl = `/program/${program_id}/locale`;

  const handleLocaleSelect = (locale: string) => {
    dispatch(
      setProgramLocaleNav({ programId: program_id, selectedLocale: locale })
    );
  };

  return (
    <TopNav
      baseUrl={baseUrl}
      navData={getNavData()}
      size="sm"
      className={styles.localeNav}
      onLocaleSelect={handleLocaleSelect}
    />
  );
};

export default ProgramLocaleNav;
