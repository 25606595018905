import DeviceOrderStatus from "./DeviceOrderStatus";
import styles from "./DeviceStatuses.module.scss";

import { t } from "~/i18n";
import colors from "~/styles/colors";
import {
  DeviceOrderStatusData,
  ExternalDeviceOrder
} from "~/typing/sidekickTypes";

type DeviceStatusesProps = {
  deviceOrderStatuses: DeviceOrderStatusData[];
};

const DeviceStatuses = ({ deviceOrderStatuses }: DeviceStatusesProps) => {
  const getDecoratedStatus = (status: ExternalDeviceOrder) => {
    switch (status) {
      case ExternalDeviceOrder.Pending:
        return {
          title: t("devices.pending"),
          backgroundColor: colors.lightBlue120,
          fontColor: colors.lightBlue20
        };
      case ExternalDeviceOrder.Ordered:
        return {
          title: t("devices.ordered"),
          backgroundColor: colors.lightBlue20,
          fontColor: colors.lightBlue120
        };
      case ExternalDeviceOrder.Shipped:
        return {
          title: t("devices.shipped"),
          backgroundColor: colors.mustardYellow20,
          fontColor: colors.mustardYellow140
        };
      case ExternalDeviceOrder.Delivered:
        return {
          title: t("devices.delivered"),
          backgroundColor: colors.aqua20,
          fontColor: colors.aqua120
        };
      default:
        return { title: "Unknown", backgroundColor: "", fontColor: "" };
    }
  };

  return (
    <div className={styles.wrapper}>
      {deviceOrderStatuses.map((status, index) =>
        status.orderedExternalDevices?.map((device, deviceIndex) => {
          const decoratedStatus = getDecoratedStatus(status.status);

          return (
            <DeviceOrderStatus
              key={`device-order-status-${index}-${deviceIndex}`}
              name={device.name}
              orderDate={status.createdOn}
              status={status.status}
              decoratedStatus={decoratedStatus}
              carrier={status.carrier}
              trackingNumber={status.trackingNumber}
              trackingUrl={status.trackingUrl}
              orderId={status.externalOrderId}
            />
          );
        })
      )}
    </div>
  );
};

export default DeviceStatuses;
