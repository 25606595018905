import classNames from "classnames";
import { Trans } from "react-i18next";

import styles from "./CarePriority.module.scss";

import ActivityPanel from "../components/ActivityPanel";

import Chevron from "~/assets/svgComponents/Chevron";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import MessageSuggestion from "~/components/messageSuggestion/MessageSuggestion";
import config from "~/config";
import { useChildrenTabIndexToggle } from "~/hooks/useChildrenTabIndexToggle";
import { UserContextInfo } from "~/typing/graphql/types";
import { MessageSuggestionType } from "~/typing/sidekickTypes";

type CarePriorityProps = {
  title: string;
  userContext: UserContextInfo;
  userId: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
  completed?: boolean;
  messageSuggestionType?: MessageSuggestionType;
  carePriorityMessage?: string;
  expanded?: boolean;
  onClick?: () => void;
};

const cx = classNames.bind(styles);

const CarePriority = ({
  title,
  userContext,
  userId,
  checked,
  onChange,
  completed,
  messageSuggestionType,
  carePriorityMessage,
  expanded,
  onClick
}: CarePriorityProps) => {
  const handleCheckboxClick = () => {
    onChange(!checked);
  };

  const { parentRef } = useChildrenTabIndexToggle({
    childrenHidden: !expanded
  });

  return (
    <div className={styles.wrapper}>
      <div
        className={cx({
          [styles.header]: true,
          [styles.expanded]: expanded
        })}
      >
        <CheckboxInput
          checked={checked || completed || false}
          onChange={handleCheckboxClick}
          label={title}
          className={styles.checkbox}
          disabled={completed || false}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCheckboxClick();
            }
          }}
        />
        <button
          className={cx({
            [styles.toggle]: true,
            [styles.rotate]: expanded,
            [styles.completed]: completed
          })}
          tabIndex={0}
          onClick={onClick}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClick?.();
            }
          }}
        >
          <Chevron direction={"down"} />
        </button>
      </div>
      <div
        className={cx({
          [styles.content]: true,
          [styles.contentHidden]: !expanded
        })}
        ref={parentRef}
      >
        <Trans components={{ strong: <strong /> }}>
          <p>{carePriorityMessage}</p>
        </Trans>
        {messageSuggestionType && config.showMessageSuggestions && (
          <MessageSuggestion
            messageType={messageSuggestionType}
            locale={userContext.locale}
            numMessages={3}
            programId={userContext.programCatalogItemId}
            userId={userId}
          />
        )}
        <ActivityPanel userContextInfo={userContext} />
      </div>
    </div>
  );
};

export default CarePriority;
