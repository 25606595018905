import { DateTime } from "luxon";

import { convertWeight } from "./convertWeight";

enum WeightSource {
  Survey = 1,
  Scale = 2,
  Signup = 3
}

export enum WeightHistoryActions {
  Fetch = "Fetch",
  AddBaseWeight = "AddBaseWeight"
}

type State = {
  weightHistory: { weight: number; date: DateTime; isFromScale: boolean }[];
  baseWeight?: number;
};

type FetchAction = {
  payload: {
    scoreMissions: { value1: number; value2?: number; value3: number }[];
    date: string;
  }[];
  type: WeightHistoryActions.Fetch;
  useMetric: boolean;
};

type AddBaseWeightAction = {
  payload: { weight?: number };
  type: WeightHistoryActions.AddBaseWeight;
};

type Action = FetchAction | AddBaseWeightAction;

const weightHistoryReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case WeightHistoryActions.Fetch: {
      const useMetric = action.useMetric;

      const weightFormatted = action.payload.map((item) => {
        const weight =
          item.scoreMissions[0].value1 +
          (item.scoreMissions[0].value2 ?? 0) / 10;
        const weightSource = item.scoreMissions[0].value3;
        return {
          weight: convertWeight(weight, useMetric),
          date: DateTime.fromISO(item.date),
          isFromScale: weightSource === WeightSource.Scale
        };
      });
      return { ...state, weightHistory: weightFormatted };
    }
    case WeightHistoryActions.AddBaseWeight: {
      return { ...state, baseWeight: action.payload.weight };
    }
    default:
      return state;
  }
};

export default weightHistoryReducer;
