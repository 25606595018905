import styles from "./printUserScore.module.scss";

type PrintUserScoreProps = {
  date: string;
  scoreName: string;
  value: any;
  unit?: string;
};

export default function PrintUserScore({
  scoreName,
  value,
  unit,
  date
}: PrintUserScoreProps) {
  return (
    <div className={styles.userScore}>
      <div>
        <span className={styles.scoreName}>{scoreName}</span>{" "}
        <span className={styles.value}>
          {" "}
          {value === undefined || value === null ? "- " : value}
        </span>{" "}
        <span className={styles.unit}>{unit}</span>
      </div>
      <div className={styles.date}>
        <span>{date}</span>
      </div>
    </div>
  );
}
