import colors from "~/styles/colors";

type PlusProps = {
  color?: string;
};

export default function Plus({ color = colors.navyBlue50 }: PlusProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_443_2662)">
        <path
          d="M1.71387 12H22.2853"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M12 1.71387V22.2853"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_443_2662">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
