import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import styles from "./PeriodPhysicalChartTemplate.module.scss";

import ButtonLink from "~/components/buttonLink/ButtonLink";
import ChartDetail from "~/components/charts/ChartDetail";
import CustomTooltip from "~/components/charts/CustomTooltip";
import CustomXAxisTick from "~/components/charts/CustomXAxisTick";
import config from "~/config";
import round from "~/helpers/number/round";
import colors from "~/styles/colors";
import { PhysicalData } from "~/typing/sidekickTypes";

interface PeriodPhysicalChartTemplatepProps {
  data: PhysicalData[];
  dataKey: string;
  today: DateTime;
  units: string;
}

interface LineData {
  label: string;
  value: number;
}

const PeriodPhysicalChartTemplate = ({
  data,
  dataKey,
  today,
  units
}: PeriodPhysicalChartTemplatepProps) => {
  const { t } = useTranslation();
  const total = (data: LineData[], dataKey: string) => {
    return data.reduce((r, a) => {
      return r + a[dataKey];
    }, 0);
  };

  const [timePeriod, setTimePeriod] = useState<"last30" | "max">("last30");
  const [lineData, setLineData] = useState<LineData[]>([]);

  const average = (data: LineData[], dataKey: string, precision = 0) => {
    return round(total(data, dataKey) / data.length, precision);
  };

  const totalData = total(lineData, "value");
  const totalAverage = average(lineData, "value");

  useEffect(() => {
    const dataToDisplay =
      timePeriod === "last30"
        ? data.filter((element) => element[dataKey] !== 0).slice(-30)
        : data.filter((element) => element[dataKey] !== 0);

    setLineData(
      dataToDisplay.map((element) => ({
        date: element.date,
        label: element.date.toFormat(config.isAnthem ? "MM/dd" : "dd/MM"),
        value: element[dataKey],
        units: "smiles"
      }))
    );
  }, [timePeriod, data?.length]);

  return (
    <div className={styles.MonthlyPhysicalChartTemplate}>
      <div className={styles.chartDetails}>
        <ChartDetail
          title={t("chartDetail.totalPeriod")}
          values={{ total: totalData }}
          dataKeys={["total"]}
          units={units}
        />
        <ChartDetail
          title={t("average.period")}
          values={{ average: totalAverage }}
          dataKeys={["average"]}
          units={units}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonLink
          active={timePeriod === "last30"}
          title="Last 30 logs"
          onClick={() => setTimePeriod("last30")}
        />
        {data.filter((element) => element[dataKey] !== 0).length > 30 && (
          <ButtonLink
            active={timePeriod === "max"}
            title="Max"
            onClick={() => setTimePeriod("max")}
          />
        )}
      </div>
      <ResponsiveContainer height={400}>
        <LineChart data={lineData}>
          <CartesianGrid stroke={colors.navyBlue10} />
          <XAxis
            dataKey="label"
            axisLine={{
              stroke: colors.navyBlue20,
              strokeWidth: 2
            }}
            tickLine={false}
            tick={
              <CustomXAxisTick
                todayLabel={today.toLocaleString({
                  month: "short",
                  day: "numeric"
                })}
              />
            }
          />
          <YAxis
            dataKey="value"
            axisLine={{
              stroke: colors.navyBlue20,
              strokeWidth: 2
            }}
            tick={{
              fill: colors.navyBlue50
            }}
            tickLine={false}
            domain={[0, 10]}
            interval={0}
            allowDecimals={false}
          />
          <Tooltip
            content={<CustomTooltip units={units} allDataKeys={[dataKey]} />}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke={colors.lightBlue100}
            strokeWidth={3}
            connectNulls
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PeriodPhysicalChartTemplate;
