import { isBetweenNumbers } from "../number/numberHelpers";

import { Survey } from "~/typing/sidekickTypes";

export const getSurveyById = (surveys: Survey[], surveyId: string) => {
  const survey = surveys?.find((survey) => {
    return survey.id === surveyId;
  });

  return survey;
};

export const getSurveyByName = (surveys: Survey[], surveyName: string) => {
  const survey = surveys?.find((survey) => {
    return survey.surveyName === surveyName;
  });

  return survey;
};

export const getSurveyRating = (
  latestSurveyResult: number | undefined,
  survey: Survey | undefined
) => {
  if (latestSurveyResult === undefined || latestSurveyResult === null) {
    return;
  }

  const rating = survey?.ratings?.find((rating) =>
    isBetweenNumbers({
      value: latestSurveyResult,
      lowerValue: rating?.lowerValue,
      upperValue: rating?.upperValue
    })
  );

  return rating;
};
