import api from "~/api/api";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { DeviceOrderStatusData } from "~/typing/sidekickTypes";

export const getDeviceOrderStatuses = async ({
  programId,
  userId
}: {
  programId: string;
  userId: string;
}): Promise<DeviceOrderStatusData[]> => {
  const path = `/admin/programcatalogitems/${programId}/users/${userId}/externaldevices/orders`;
  return await api
    .get(path)
    .then((res) => res.data.items)
    .catch(() => {
      displayErrorToast({ message: "Error getting device statuses" });
      return [];
    });
};
