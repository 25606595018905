import { DateTime } from "luxon";

export enum MentalActions {
  Fetch = "Fetch"
}

type FetchAction = {
  type: MentalActions.Fetch;
  payload: {
    date: string;
    scoreMissions: { value1: number }[];
  }[];
};

type Action = FetchAction;

type State = {
  value: number;
  date: DateTime;
  label: string;
}[];

export default function mentalReducer(state: State, action: Action): State {
  switch (action.type) {
    case MentalActions.Fetch: {
      const missionData = action.payload.map((item) => {
        const date = DateTime.fromISO(item.date);
        return {
          value: item.scoreMissions[0].value1,
          date: date,
          label: date.toLocaleString({ month: "short", day: "numeric" })
        };
      });

      return missionData.reverse();
    }

    default:
      return state;
  }
}
