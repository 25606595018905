import { useEffect, useRef, useState } from "react";

import styles from "./CheckBoxOptions.module.scss";

import CheckboxInput from "../checkboxInput/CheckboxInput";
import checkBoxStyles from "../select/CustomCheckBoxSelect.module.scss";

import MenuIcon from "~/assets/menu.svg";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { t } from "~/i18n";

type CheckBoxOptionItem = {
  func: () => void;
  title: string;
  checked: boolean;
};

type OptionsProps = {
  options: CheckBoxOptionItem[];
  hideOptions?: boolean;
  className?: string;
  iconSrc?: string;
  selectAll?: () => void;
  clearAll?: () => void;
};

const CheckBoxOptions = ({
  options,
  className,
  hideOptions,
  iconSrc,
  clearAll,
  selectAll
}: OptionsProps) => {
  const [showList, setShowList] = useState(false);
  const listRef = useRef(null);

  useOnClickOutside(listRef, () => {
    if (showList) {
      setShowList(false);
    }
  });

  useEffect(() => {
    if (hideOptions) {
      setShowList(false);
    }
  }, [hideOptions]);

  return (
    <div
      className={`${styles.container} ${className ?? ""}  ${
        hideOptions ? styles.hide : ""
      }`}
      data-testid="checkbox-options-container"
      ref={listRef}
    >
      <button
        type="button"
        className={styles.menu}
        data-testid="checkbox-options-menu"
        onClick={() => setShowList(!showList)}
      >
        <img src={iconSrc || MenuIcon} />
      </button>
      {showList && (
        <div data-testid="checkbox-options-options" className={styles.options}>
          <ul>
            <li className={checkBoxStyles.toggle}>
              {selectAll && (
                <button
                  disabled={options.every((option) => option.checked)}
                  onClick={selectAll}
                >
                  {t("select.selectAll")}
                </button>
              )}
              {clearAll && (
                <button
                  disabled={options.every((option) => !option.checked)}
                  onClick={clearAll}
                >
                  {t("select.clearAll")}
                </button>
              )}
            </li>
            {options.map(({ checked, title, func }, index) => (
              <li
                key={`list-item-${index}-${title} `}
                className={`${checkBoxStyles.option}`}
              >
                <CheckboxInput
                  checked={checked}
                  label={title}
                  onChange={func}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CheckBoxOptions;
