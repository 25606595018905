import handleClickCareCompassLink from "./anthem/handleClickCareCompassLink";
import handleClickHipLink from "./anthem/handleClickHipLink";
import handleClickHRSLink from "./anthem/handleClickHRSLink";
import handleClickNurselineLink from "./anthem/handleClickNurselineLink";

import { ExternalUser } from "~/typing/sidekickTypes";

export type ExternalLinkOption = {
  value: string;
  onClick: () => void;
  condition: string;
};

export default function getExternalLinkOptions(
  externalUser?: ExternalUser
): ExternalLinkOption[] {
  if (!externalUser) return [];
  const showHRSLink = externalUser?.anthemHRSID || externalUser?.caseIdentifier;

  const showNurselineLink =
    externalUser?.identifier && externalUser?.anthemMCID;

  const showCareCompassLink = externalUser?.careCompassExternalUserID;

  const showHipLink = externalUser?.anthemMCID && externalUser.caseIdentifier;

  const externalLinkOptions = [
    {
      value: "Open in Nurseline",
      onClick: () => handleClickNurselineLink(externalUser),
      condition: showNurselineLink
    },
    {
      value: "Open HRS Case",
      onClick: () => handleClickHRSLink(externalUser),
      condition: showHRSLink
    },
    {
      value: "Open in Care Compass",
      onClick: () => handleClickCareCompassLink(externalUser),
      condition: showCareCompassLink
    },
    {
      value: "Open in HIP",
      onClick: () => handleClickHipLink(externalUser),
      condition: showHipLink
    }
  ];

  const filteredExternalLinkOptions = externalLinkOptions.filter(
    (option) => option.condition
  );

  return filteredExternalLinkOptions;
}
