import classNames from "classnames/bind";
import { CSSProperties, useState } from "react";

import styles from "./SurveyResultBubble.module.scss";

import ActionTooltip from "~/components/tooltip/Tooltip";

const cx = classNames.bind(styles);

type SurveyResultBubbleProps = {
  text?: string;
  timeSinceSurvey?: string;
  result: number;
  style: CSSProperties;
  useLightText: boolean;
  showNotifcation?: boolean;
};

const SurveyResultBubble = ({
  text,
  timeSinceSurvey,
  result,
  style,
  useLightText,
  showNotifcation = false
}: SurveyResultBubbleProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={cx({
        surveyResult: true,
        lightText: useLightText
      })}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className={styles.resultBubble} style={style}>
        {result}
        {showNotifcation && <div className={styles.notification} />}
      </div>
      {!!timeSinceSurvey && (
        <div className={styles.timeSinceSurvey}>
          <p>{timeSinceSurvey}</p>
        </div>
      )}
      {text && <ActionTooltip show={showTooltip} content={text} />}
    </div>
  );
};

export default SurveyResultBubble;
