import styles from "./ProgressDetail.module.scss";

import { MissionCategory } from "~/constants/missionCategories";
import { t } from "~/i18n";
import { Mission, UserWeekPoints } from "~/typing/sidekickTypes";

type ProgressDetailProps = {
  points: UserWeekPoints;
  missions?: Mission[];
  displayName?: string;
};

const ProgressDetail = ({
  points,
  missions,
  displayName
}: ProgressDetailProps) => {
  const { foodPoints, mindPoints, movePoints } = points;

  const moveMissions = missions?.filter(
    (mission) => mission.categoryId === MissionCategory.Move
  );
  const mindMissions = missions?.filter(
    (mission) => mission.categoryId === MissionCategory.Mind
  );
  const foodMissions = missions?.filter(
    (mission) => mission.categoryId === MissionCategory.Food
  );

  const portionMissions = ["Veggies", "Fruit", "Nuts and Seeds"];

  const renderMissions = (missions: Mission[] | undefined) => {
    if (!missions || missions.length === 0) {
      return null;
    }

    return (
      <ul className={styles.missions}>
        {missions.map((mission) => (
          <li key={mission.name}>
            <span>{`${mission.count} x ${mission.name}`}</span>
            {mission.categoryId === MissionCategory.Move ? (
              <span>
                {mission.name === "Step Counter" ? (
                  <>{`${mission.countValue} ${t("units.steps", "steps")}`}</>
                ) : (
                  <>{`${Math.floor(mission.timeValue / 60)} ${t(
                    "units.mins",
                    "mins"
                  )}`}</>
                )}
              </span>
            ) : mission.categoryId === MissionCategory.Food ? (
              <span>
                {`${mission.countValue} `}
                {mission.name === "Water" ? (
                  <>
                    {t(
                      `progressBar.glass${mission.countValue > 1 ? "es" : ""}`
                    )}
                  </>
                ) : portionMissions.includes(mission.name) ? (
                  <>
                    {t(
                      `progressBar.portion${mission.countValue > 1 ? "s" : ""}`
                    )}
                  </>
                ) : (
                  <>
                    {t(
                      `time.day${mission.countValue > 1 ? "s" : ""}`
                    ).toLowerCase()}
                  </>
                )}
              </span>
            ) : null}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.ProgressDetail}>
      <div className={styles.header}>
        {displayName} <span>{t("time.last7days", "last 7 days")}</span>
      </div>
      <div className={styles.move}>
        <span>{t("general.move", "Move")}</span>
        <span>{`${movePoints} ${t("general.drops", "drops")}`}</span>
      </div>
      {renderMissions(moveMissions)}

      <div className={styles.mind}>
        <span>{t("general.mind", "Mind")}</span>
        <span>{`${mindPoints} ${t("general.drops", "drops")}`}</span>
      </div>
      {renderMissions(mindMissions)}

      <div className={styles.food}>
        <span>{t("general.food", "Food")}</span>
        <span>{`${foodPoints} ${t("general.drops", "drops")}`}</span>
      </div>
      {renderMissions(foodMissions)}
    </div>
  );
};

export default ProgressDetail;
