import round from "../number/round";

export const arraysAreEqual = (arrayOne: any[], arrayTwo: any[]) => {
  const objectsAreSame = (x: any, y: any) => {
    let objectsAreSame = true;
    for (const propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  };

  if (!arrayOne || !arrayTwo) return false;

  if (arrayOne.length !== arrayTwo.length) return false;

  let areEqual = true;

  for (let index = 0; index < arrayOne.length; index++) {
    if (!objectsAreSame(arrayOne[index], arrayTwo[index])) {
      areEqual = false;
      break;
    }
  }

  return areEqual;
};

export const removeObjectFromArray = (array: any[], value: any) => {
  const index = array.findIndex((element) => element === value);
  array.splice(index, 1);
  return array;
};

export const getHighestValuePropertyInArray = <
  T extends Record<K, any>,
  K extends keyof any
>(
  array: T[],
  key: K
): T | undefined => {
  let highestValue = Number.MIN_VALUE;
  let highestValueIndex: number | undefined = undefined;

  array.forEach((element, index) => {
    const value = parseFloat(element[key]);

    if (isNaN(value) || !value) return;

    if (highestValue === undefined || value > highestValue) {
      highestValue = value;
      highestValueIndex = index;
    }
  });

  return highestValueIndex !== undefined ? array[highestValueIndex] : undefined;
};

export const getLowestValuePropertyInArray = <
  T extends Record<K, any>,
  K extends keyof any
>(
  array: T[],
  key: K
): T | undefined => {
  let lowestValue = Number.MAX_VALUE;
  let lowestValueIndex: number | undefined = undefined;

  array.forEach((element, index) => {
    const value = parseFloat(element[key]);

    if (isNaN(value) || !value) return;

    if (value < lowestValue) {
      lowestValue = value;
      lowestValueIndex = index;
    }
  });

  return lowestValueIndex !== undefined ? array[lowestValueIndex] : undefined;
};

export const avgByKey = <T extends Record<K, any>, K extends keyof any>(
  data: T[],
  dataKey: K,
  precision = 0
) => {
  if (!data.length) return undefined;

  return round(
    data.reduce((r, a) => {
      return r + a[dataKey];
    }, 0) / data.length,
    precision
  );
};

export const filterOutUndefined = <T>(item: T | undefined): item is T => {
  return !!item;
};
