import { useSelector } from "react-redux";

import { RootState } from "~/state/store";

const useNavHeaderNotification = () => {
  const notification = useSelector((state: RootState) => state.navHeader);

  return notification;
};

export default useNavHeaderNotification;
