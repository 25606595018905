import { createContext } from "react";

import { FoodJournalUpdatePayload } from "~/typing/sidekickTypes";

type FoodJournalPageContextProps = {
  updateJournal: (
    update: FoodJournalUpdatePayload,
    shouldUpdate: boolean
  ) => Promise<boolean>;
};

export const FoodJournalPageContext = createContext<FoodJournalPageContextProps>(
  {} as FoodJournalPageContextProps
);
