import { useEffect, useMemo } from "react";

import { queryFilteredEntities, sortEntities } from "./CustomList.helpers";

import { DEFAULT_FILTER_VALUE } from "~/constants/filters";
import useListFilters from "~/hooks/useListFilters";
import {
  CustomListFilterData,
  FilterCheckboxData,
  PaginationData,
  SearchData,
  SortableData
} from "~/typing/carePortalTypes";

export const useFilteredEntities = <T>({
  entities,
  filterCheckboxData,
  filterSelectData,
  customListUniqueId
}: {
  entities: T[];
  filterCheckboxData?: FilterCheckboxData<T>[];
  filterSelectData?: CustomListFilterData[];
  customListUniqueId: string;
}) => {
  const { filters, filtersChangedCount } = useListFilters(customListUniqueId);

  const entitiesStringified = JSON.stringify(entities);

  const filteredEntities = useMemo(() => {
    let entitiesToFilter = entities;
    filterCheckboxData?.forEach((filter) => {
      if (filter.filter) {
        entitiesToFilter = entitiesToFilter.filter((entity) =>
          !filters[filter.key]
            ? true
            : filter.filter?.({
                entity,
                filterValue: filters[filter.key] as boolean
              })
        );
      }
    });
    filterSelectData?.forEach((filter) => {
      if (filter.filter) {
        entitiesToFilter = entitiesToFilter.filter((entity) =>
          parseInt(filters[filter.key]?.toString()) ===
          parseInt(DEFAULT_FILTER_VALUE)
            ? true
            : filter.filter?.({
                entity,
                filterValue: filters[filter.key]
              })
        );
      }
    });
    return entitiesToFilter;
  }, [entitiesStringified, filtersChangedCount]);

  return filteredEntities;
};

export const useSortedAndQueriedEntities = <T>({
  entities,
  sortingData,
  onSearchChange,
  order,
  query,
  putEntityAtBottom,
  searchData
}: {
  query?: string;
  entities: T[];
  sortingData?: SortableData;
  onSearchChange?: (query: string) => void;
  order: "asc" | "desc";
  putEntityAtBottom?: (entity: T) => boolean;
  searchData?: SearchData;
}) => {
  const sortedAndQueriedEntities = useMemo(() => {
    let entitiesToSort = entities;
    if (query && !onSearchChange) {
      entitiesToSort = queryFilteredEntities(entities, query, searchData);
    }
    const sortedEntities = sortEntities(
      entitiesToSort,
      order,
      sortingData,
      putEntityAtBottom
    );

    return sortedEntities;
  }, [entities, order, sortingData, query]);

  return sortedAndQueriedEntities;
};

export const useEntitiesToDisplay = <T>({
  entities,
  processRenderedEntities,
  processFilteredEntities,
  pagination,
  currentPage
}: {
  entities: T[];
  processRenderedEntities: (entities: T[]) => T[];
  processFilteredEntities?: (entities: T[]) => void;
  pagination?: PaginationData;
  currentPage: number;
}) => {
  const entitiesToDisplay = pagination
    ? processRenderedEntities(
        entities.slice(
          (currentPage - 1) * pagination.entitiesPerPage,
          currentPage * pagination.entitiesPerPage
        )
      )
    : processRenderedEntities(entities);

  useEffect(() => {
    processFilteredEntities?.(entities);
  }, [entities]);

  return entitiesToDisplay;
};
