import { DateTime } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ExportContext } from "./exportPreviewContext";
import styles from "./table.module.scss";

import { isBetweenDates } from "~/helpers/date/isBetweenDates";
import getMissionValueText from "~/helpers/mission/getMissionValueText";

type PrintMissionsProps = {
  slotDays: any[];
  missionName?: string;
};

export default function PrintMissions({
  slotDays,
  missionName
}: PrintMissionsProps) {
  const { t } = useTranslation();
  const { startDate, endDate } = useContext(ExportContext);
  return (
    <div>
      {slotDays.map((day, key) => {
        return !missionName ? (
          <table key={key} className={`${styles.table} ${missionName}`}>
            <tbody>
              {day?.tasks?.map((task, taskKey) => {
                if (isBetweenDates(day.date, startDate, endDate)) {
                  return (
                    <tr key={taskKey}>
                      <td>
                        <span>
                          {taskKey < 1
                            ? DateTime.fromISO(day.date).toLocaleString({
                                month: "long",
                                day: "2-digit",
                                year: "numeric"
                              })
                            : ""}
                        </span>
                      </td>
                      <td>{task.name}</td>
                      <td className={styles.right}>
                        <span>
                          {getMissionValueText(
                            task.unit,
                            task.currentValue,
                            task.targetValue
                          )}
                        </span>
                      </td>
                      <td className={styles.right}>
                        {task.currentValue >= task.targetValue
                          ? t("exportPreview.complete")
                          : t("exportPreview.incomplete")}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        ) : (
          <table key={key} className={`${styles.table} ${styles.striped}`}>
            <tbody>
              {day?.tasks?.filter((task) => task.name === missionName).length >
                0 && (
                <tr>
                  <th>Date</th>
                </tr>
              )}
              {day?.tasks
                ?.filter((task) => task.name === missionName)
                .map((task, taskKey) => {
                  return (
                    <tr key={taskKey}>
                      <td>
                        {taskKey < 1
                          ? DateTime.fromISO(day.date).toLocaleString({
                              month: "long",
                              day: "2-digit",
                              year: "numeric"
                            })
                          : ""}
                      </td>
                      <td className={styles.right}>{task.currentValue}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        );
      })}
    </div>
  );
}
