import config from "~/config";
import { ExternalUser } from "~/typing/sidekickTypes";

export default function handleClickNurselineLink(externalUser: ExternalUser) {
  const hcid = externalUser.identifier;
  const mcid = externalUser.anthemMCID;

  if (!hcid || !mcid) {
    return;
  }

  const baseUrl = config.anthemNurselineDeepLinkBaseUrl;

  const url = `${baseUrl}?hcid=${hcid}&mcid=${mcid}`;
  window.open(url, "_blank");
}
