import styles from "./Notification.module.scss";

type NotificationProps = {
  count?: number;
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
  dataTestId?: string;
};

const Notification = ({
  count = 0,
  size = "md",
  className,
  dataTestId
}: NotificationProps) => {
  if (count === 0) {
    return null;
  }

  const displayText = count > 99 ? "99+" : count;

  return (
    <div
      className={`${styles.notification} ${styles[size]} ${className} ${
        count > 10 ? styles.wider : ""
      } 
      `}
      data-testid={dataTestId}
    >
      {count > 0 && size !== "xs" ? displayText : null}
    </div>
  );
};

export default Notification;
