import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { MessageThread } from "~/typing/sidekickTypes";

export const useMessageThreads = ({
  programId,
  locale,
  userId
}: {
  programId: string;
  locale: string;
  userId: string;
}) => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/messagethreads`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: MessageThread[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.notes(userId)
  });

  return {
    messageThreads: data?.items?.[0],
    isError,
    isLoading,
    invalidate
  };
};
