import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import styles from "./ValuePreselectionSection.module.scss";

type ValuePreselectionSectionProps = {
  index?: number;
  title?: string;
  description?: string;
  answers?: string[];
  selectedAnswers?: string[];
  ordered?: boolean;
};

const ValuePreselectionSection = ({
  index = 0,
  title = "",
  description = "",
  answers = [],
  selectedAnswers = [],
  ordered
}: ValuePreselectionSectionProps) => {
  const { t } = useTranslation();

  const unselectedAnswers = answers.filter(
    (answer) => !selectedAnswers.includes(answer)
  );

  const separator = (i: number, selected: boolean) => {
    if (selected) {
      if (unselectedAnswers.length === 0) {
        if (i === selectedAnswers.length - 1) {
          return "";
        }
      }
    } else {
      if (i === unselectedAnswers.length - 1) {
        return "";
      }
    }

    return ", ";
  };

  const renderAnswer = (answer: string, i: number, selected: boolean) => {
    return (
      <span className={selected ? styles.selected : ""} key={answer}>
        {`${ordered ? `${i + 1}. ` : ""}${answer}${separator(i, selected)}`}
      </span>
    );
  };

  const renderAnswers = (answers: string[], selected: boolean) => {
    return answers.map((answer, i) => renderAnswer(answer, i, selected));
  };

  return (
    <div className={styles.ValuePreselectionSection}>
      <div className={styles.title}>
        {index ? `${index}. ` : ""}
        {title}
      </div>
      {description && (
        <div className={styles.description}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      )}

      <div className={styles.answersHeading}>
        {t("valuePreselection.answers", "Answers")}
      </div>
      <div className={styles.answers}>
        {renderAnswers(selectedAnswers, true)}
        {renderAnswers(unselectedAnswers, false)}
      </div>
    </div>
  );
};

export default ValuePreselectionSection;
