const getPortionSizeCharacter = (size: number) => {
  if (size < 0.33) {
    return "S";
  } else if (size > 0.67) {
    return "L";
  }
  return "M";
};
const getMealTypeText = (mealType: number) => {
  switch (mealType) {
    case 1:
      return "performance.journals.breakfast"; //'MEALTYPE_BREAKFAST'
    case 2:
      return "performance.journals.lunch"; //'MEALTYPE_LUNCH'
    case 3:
      return "performance.journals.dinner"; //'MEALTYPE_DINNER'
    case 4:
      return "performance.journals.snack"; //'MEALTYPE_SNACK'
  }
  return "performance.journals.breakfast";
};
export { getPortionSizeCharacter, getMealTypeText };
