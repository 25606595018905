import { DateTime } from "luxon";

export default function getWeekDateRange(
  endDate: DateTime,
  useMonthText = false
) {
  return `${endDate.minus({ days: 6 }).toLocaleString({
    day: "2-digit",
    month: useMonthText ? "short" : "2-digit"
  })} - ${endDate.toLocaleString({
    day: "2-digit",
    month: useMonthText ? "short" : "2-digit"
  })}`;
}
