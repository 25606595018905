import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";

import { logout } from "./authRedcuer";

import useLoggedIn from "../hooks/useLoggedIn";
import { AppDispatch } from "../state/store";

export const PrivateRoute = () => {
  const isAuthenticated = useLoggedIn();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

// routable logout component
export function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const renderAfterCalled = useRef(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    // Added so the logout would not be called 2x
    if (!renderAfterCalled.current) {
      queryClient.clear();
      dispatch(logout(() => navigate("/login")));
    }
    renderAfterCalled.current = true;
  }, []);

  return null;
}
