import api from "../api";

import { Medication, MedicationLogs } from "~/typing/sidekickTypes";

export const getMedication = async ({
  userId
}: {
  userId: string;
}): Promise<Medication[]> => {
  const path = `/coach/users/${userId}/medications`;
  return await api.get(path).then((res) => res.data.items);
};

export const getMedicationLogs = async ({
  userId
}: {
  userId: string;
}): Promise<MedicationLogs[]> => {
  const path = `/coach/users/${userId}/medications/logs`;
  return await api.get(path).then((res) => res.data.items);
};
