import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import QuizModal from "./QuizModal";

import useSurveyResults from "~/hooks/useApi/useSurveyResults";
import { UserURLParams } from "~/typing/carePortalTypes";
import { Survey } from "~/typing/sidekickTypes";

type QuizModalWrapperProps = {
  surveyId: string;
  quizzes: Survey[];
  onClose: () => void;
  day?: string;
};

const QuizModalWrapper = ({
  surveyId,
  quizzes,
  onClose,
  day
}: QuizModalWrapperProps) => {
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const quiz = quizzes?.find((quiz) => quiz.id === surveyId);

  const { surveyResults } = useSurveyResults({
    programCatalogItemId: program_id,
    locale,
    userId: user_id,
    surveyId: surveyId
  });

  const quizResult = surveyResults?.find(
    (quizResult) => DateTime.fromISO(quizResult.date).toISODate() === day
  );

  return <QuizModal onClose={onClose} quiz={quiz} quizResult={quizResult} />;
};

export default QuizModalWrapper;
