import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import DeviceOrderModal from "./DeviceOrderModal";
import styles from "./InformationPageDeviceOrder.module.scss";
import DeviceStatuses from "./status/DeviceStatuses";

import InformationPageHeaderInfo from "../header/info/InformationPageHeaderInfo";

import {
  DeviceInformation,
  getAllowedDevices
} from "~/api/requests/deviceRequests";
import { getDeviceOrderStatuses } from "~/api/requests/iredeemRequests";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";
import {
  DeviceOrderStatusData,
  ExternalUser,
  UserDetails
} from "~/typing/sidekickTypes";

type InformationPageDeviceOrderProps = {
  userDetails?: UserDetails;
  externalUser?: ExternalUser;
};

const InformationPageDeviceOrder = ({
  userDetails,
  externalUser
}: InformationPageDeviceOrderProps) => {
  const { t } = useTranslation();
  const [allowedDevices, setAllowedDevices] = useState<DeviceInformation[]>([]);
  const [deviceOrderModalOpen, setDeviceOrderModalOpen] = useState(false);
  const [deviceOrderStatuses, setDeviceOrderStatuses] = useState<
    DeviceOrderStatusData[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user_id = "", program_id = "" } = useParams<UserURLParams>();
  const { trackDeviceOrderSelected } = useAmplitudeTracking();

  const getOrderStatuses = async () => {
    const statuses = await getDeviceOrderStatuses({
      programId: program_id,
      userId: user_id
    });
    setDeviceOrderStatuses(statuses ?? []);
  };

  const getAllowedDevicesForUser = async () => {
    const devices = await getAllowedDevices({
      programId: program_id,
      userId: user_id
    });
    setAllowedDevices(devices);
  };

  const getDevicesInfo = async () => {
    await getAllowedDevicesForUser();
    setIsLoading(true);
    await getOrderStatuses();
    setIsLoading(false);
  };

  useEffect(() => {
    getDevicesInfo();
  }, []);

  const showDeviceOrderModal = () => {
    setDeviceOrderModalOpen(true);
    trackDeviceOrderSelected({
      deviceNames: allowedDevices?.map((device) => device.name) ?? []
    });
  };

  const renderDevicesInfo = (canOrderDevice: boolean) => {
    return (
      <div className={styles.wrapper}>
        {!isLoading && (
          <>
            <DeviceStatuses deviceOrderStatuses={deviceOrderStatuses} />
            {canOrderDevice && (
              <button
                className={styles.modalButton}
                onClick={showDeviceOrderModal}
              >
                {t("devices.deviceOrder")}
              </button>
            )}
          </>
        )}
        {isLoading && <PulseLoader className={styles.loader} />}
      </div>
    );
  };

  const canOrderDevice = Boolean(
    userDetails?.canCareManagerOrderDevice && (allowedDevices?.length ?? 0) > 0
  );

  const shouldShowDevices = deviceOrderStatuses.length > 0 || canOrderDevice;

  return shouldShowDevices ? (
    <>
      <InformationPageHeaderInfo
        title={t("devices.devices")}
        renderDetails={() => renderDevicesInfo(canOrderDevice)}
      />
      {deviceOrderModalOpen && (
        <DeviceOrderModal
          externalUser={externalUser}
          onOrder={() => getDevicesInfo()}
          onClose={() => setDeviceOrderModalOpen(false)}
          allowedDevices={allowedDevices ?? []}
        />
      )}
    </>
  ) : null;
};

export default InformationPageDeviceOrder;
