import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";

import styles from "./InvitesList.module.scss";
import { TeamPageRouteContext } from "./teamPageContext";

import TrashIcon from "~/assets/trash.svg";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import { ProgramURLParams } from "~/typing/carePortalTypes";

type Invite = {
  id: string;
  email: string;
  createdDate: string;
  deleted: boolean;
};

const InvitesList = () => {
  const { t } = useTranslation();

  const {
    loading,
    onDeleteInvite,
    invites
  } = useOutletContext<TeamPageRouteContext>();

  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();

  const searchData = {
    data: [{ key: ["email"] }],
    placeholder: t("team.searchPlaceholder", "Search for a member...")
  };

  const handleDeleteInvite = async (id, email) => {
    if (window.confirm(t("team.confirmDeleteInvite", { email }))) {
      onDeleteInvite(id);
    }
  };

  const filteredInvites = invites?.filter((invite) => !invite.deleted);

  const columns: CustomListColumn<Invite>[] = [
    {
      heading: {
        text: t("general.email"),
        sortable: {
          sortBy: ["email"]
        }
      },
      render: ({ email }) => <div className={styles.email}>{email}</div>
    },
    {
      heading: {
        text: t("team.inviteDate"),
        sortable: {
          reverse: true,
          sortBy: ["createdDate"]
        }
      },
      render: ({ createdDate }) =>
        DateTime.fromISO(createdDate).toLocaleString()
    },
    {
      heading: {
        text: t("general.delete")
      },
      render: ({ id, email }) => (
        <img
          src={TrashIcon}
          className={styles.delete}
          onClick={() => handleDeleteInvite(id, email)}
        />
      )
    }
  ];

  return (
    <div className={styles.container}>
      <CustomList<Invite>
        columns={columns}
        entities={filteredInvites}
        searchData={searchData}
        isLoading={loading}
        noEntitiesText={t("team.noInvitedCoaches")}
        defaultSortingData={{ reverse: true, sortBy: ["createdDate"] }}
        defaultOrder="asc"
        customListUniqueId={`invites-list-${program_id}-${locale}`}
      />
    </div>
  );
};

export default InvitesList;
