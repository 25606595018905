import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import TopNav from "~/components/topNav/TopNav";

export default function PerformanceNav() {
  const { t } = useTranslation();
  const { program_id = "", locale = "", user_id = "" } = useParams();

  const navData = [
    {
      title: t("performance.physical.performance", "Physical Performance"),
      url: "physical"
    },
    {
      title: t("performance.mental.performance", "Mental Performance"),
      url: "mental"
    }
  ];

  const baseUrl = `/program/${program_id}/locale/${locale}/users/${user_id}/performance`;

  return <TopNav baseUrl={baseUrl} navData={navData} size="lg" />;
}
