import { DateTime } from "luxon";

import { MessageType, UserDetailsUser } from "~/typing/sidekickTypes";

export const getHighestReadMessage = (
  messages: MessageType[],
  user: UserDetailsUser
) => {
  return [...messages].reverse().find((msg) => {
    const createdDate = DateTime.fromISO(msg.createdDate);
    const seenDate = msg.seenDate ? DateTime.fromISO(msg.seenDate) : undefined;
    const isRead = seenDate && seenDate.diff(createdDate).toMillis() > 0;
    const isCoachReceiver =
      msg.senderUserId === user?.id || msg.senderUserId === user?.userId;

    return isRead && !isCoachReceiver;
  });
};
