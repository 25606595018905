import styles from "./MedicationEmptyState.module.scss";

import stylesMedPage from "../MedicationPage.module.scss";

import SidekickMedicationTime from "~/assets/Sidekick-medication-time.svg";
import { t } from "~/i18n";
import { MedicationReminderType } from "~/typing/carePortalTypes";

type MedicationEmptyStateProps = {
  onShowMessages: (value: boolean) => void;
  trackMedicationReminderSent: () => void;
  type: MedicationReminderType;
};

const MedicationEmptyState = ({
  onShowMessages,
  trackMedicationReminderSent,
  type
}: MedicationEmptyStateProps) => {
  return type === MedicationReminderType.MedicationLog ? (
    <div className={stylesMedPage.weekCalendar}>
      <div className={styles.emptyStateCalendar}>
        <p className={styles.emptyStateTitle}>
          {t("medication.emptyStateLogTitle")}
        </p>
        <p className={styles.emptyStateSubtitle}>
          {t("medication.emptyStateLogSubtitle")}
        </p>
        <img src={SidekickMedicationTime} />
        <button
          type="button"
          className={`btn-sm btn-primary ${styles.emptyStateButton}`}
          onClick={() => {
            onShowMessages(true);
            trackMedicationReminderSent();
          }}
        >
          {t("medication.sendMessage")}
        </button>
      </div>
    </div>
  ) : (
    <div className={styles.emptyState}>
      <p className={styles.emptyStateTitle}>
        {t("medication.emptyStateMedTitle")}
      </p>
      <p className={styles.emptyStateSubtitle}>
        {t("medication.emptyStateMedSubtitle")}
      </p>
      <img src={SidekickMedicationTime} />
      <button
        type="button"
        className={`btn-sm btn-primary ${styles.emptyStateButton}`}
        onClick={() => {
          onShowMessages(true);
          trackMedicationReminderSent();
        }}
      >
        {t("medication.sendMessage")}
      </button>
    </div>
  );
};

export default MedicationEmptyState;
