import MedicationCard from "./MedicationCard";
import styles from "./MedicationCardPage.module.scss";

import { Medication } from "~/typing/sidekickTypes";

interface MedicationCardPageProps {
  medication: Medication[];
  setToggleMedicine: (value: boolean) => void;
  setSelectedMedication: (value: string) => void;
}

const MedicationCardPage = ({
  medication,
  setToggleMedicine = () => undefined,
  setSelectedMedication = () => undefined
}: MedicationCardPageProps) => {
  return (
    <div className={styles.medicationCardContainer}>
      {medication.map((medication) => (
        <MedicationCard
          key={medication.id}
          medication={medication}
          hasLogs={medication.reminders?.length > 0}
          onClick={() => {
            setToggleMedicine(false);
            setSelectedMedication(medication.id);
          }}
        />
      ))}
    </div>
  );
};

export default MedicationCardPage;
