import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { Survey } from "~/typing/sidekickTypes";

interface Surveys {
  items: Survey[];
}

export default function useSurveys(
  programCatalogItemId: string,
  locale: string
) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/surveys`;
  const { data, isError, isLoading, invalidate } = useApi<Surveys>({
    path,
    queryKey: QueryKeyFactory.programs.surveys(programCatalogItemId, locale)
  });
  return {
    surveys: data?.items || [],
    isError,
    isLoading,
    invalidate
  };
}
