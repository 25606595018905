import { ReactNode, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import Signup from "./Signup";

import api from "~/api/api";
import { actOnInvite } from "~/api/graphql/invites";
import NarrowLayout from "~/components/layout/NarrowLayout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import { getPrivacyUrl, getTermsUrl } from "~/helpers/legalUrls";

const SignupPage = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<ReactNode>();
  const [isLoading, setLoading] = useState(false);
  const [showSuccessMessage, setSuccessMessage] = useState(false);

  const detectedLocale = "en"; //i18n.language.substring(0, 2);

  const privacyHref = getPrivacyUrl(detectedLocale);
  const termsHref = getTermsUrl(detectedLocale);

  // get query parameters from url
  const urlParams = new URLSearchParams(window.location.search);

  // get the value of the 'token' and 'email' query parameter
  const token = urlParams.get("token");

  const handleSignup = async (name, email, password) => {
    setLoading(true);

    // Create in auth service first...
    const authRes = await fetch(`${config.authBaseUrl}/auth/session/create`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "BasicCustom",
        "X-SK-PORTAL": `care-${config.platform}`
      }),
      credentials: "include",
      body: JSON.stringify({
        username: email,
        password: password,
        client_id: "sidekickhealth.com"
      })
    });

    let createBody;

    try {
      createBody = await authRes.json();
    } catch (err) {
      console.error(err);
    }

    if (!createBody) {
      setError(t("signup.errors.system"));
      setLoading(false);
      return;
    }

    if (createBody.error === "account_already_exists") {
      setError(t("signup.errors.userexists"));
      setLoading(false);
      return;
    }

    if (
      createBody.error?.indexOf(
        "Provided password does not meet our minimal password policy requirements"
      ) === 0
    ) {
      setError(
        <ul>
          <li>{t("signup.errors.passwordRequirements.title")}</li>
          <li>- {t("signup.errors.passwordRequirements.length")}</li>
          <li>- {t("signup.errors.passwordRequirements.lowercase")}</li>
          <li>- {t("signup.errors.passwordRequirements.uppercase")}</li>
          <li>- {t("signup.errors.passwordRequirements.number")}</li>
        </ul>
      );
      setLoading(false);
      return;
    }

    if (!authRes.ok) {
      setError(t("signup.errors.generic"));
      setLoading(false);
      return;
    }

    // ...then finalize user in backend
    const bakRes = api.put("/users/user/finalize", {
      name: name,
      locale: navigator.language,
      timezoneOffset: new Date().getTimezoneOffset() * 60,
      privacyPolicyVersion: config.privacyVersion,
      privacyPolicyLocale: detectedLocale
    });

    bakRes.then((res) => {
      if (token && res?.data.user.id) {
        setLoading(true);
        //Act on invite. on success, redirect to /programs
        actOnInvite(res?.data.user.id, token)
          .then(() => {
            window.location.href = "/programs";
          })
          .catch((error) => {
            console.error(error);
            setError(t("signup.errors.generic"));
            setSuccessMessage(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    if (!bakRes) {
      setError(t("signup.errors.generic"));
      setLoading(false);
      return;
    }

    setLoading(false);
    setSuccessMessage(true);
  };

  return (
    <SentryErrorBoundary transactionName="SignupPage">
      <NarrowLayout>
        <Helmet title={t("signup.signup")} defer={false} />
        <Signup
          onSignup={handleSignup}
          isLoading={isLoading}
          error={error}
          showSuccessMessage={showSuccessMessage}
          privacyHref={privacyHref}
          termsHref={termsHref}
        />
      </NarrowLayout>
    </SentryErrorBoundary>
  );
};

export default SignupPage;
