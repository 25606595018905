import { QueryKeyFactory } from "../queryKeysFactory";
import useApi from "../useApi";

import { TimeInMs } from "~/constants/measurements";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import useUnreadMessages from "~/hooks/useUnreadMessages";
import { t } from "~/i18n";
import { MessageThread } from "~/typing/sidekickTypes";

export const useMessageThreads = ({
  programCatalogItemId,
  locale,
  userId,
  disabled,
  messagesThreadOpen
}: {
  programCatalogItemId: string;
  locale: string;
  userId: string;
  disabled?: boolean;
  messagesThreadOpen?: boolean;
}) => {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/users/${userId}/messagethreads`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: MessageThread[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.messages(
      programCatalogItemId,
      locale,
      userId
    ),
    disabled,
    refetchInterval: () =>
      !messagesThreadOpen ? TimeInMs.Second * 30 : TimeInMs.Second * 5
  });

  const { unreadMessages, updateUnreadMessages } = useUnreadMessages({
    messages: data?.items?.[0]?.messages ?? [],
    userId
  });

  if (isError) {
    displayErrorToast({
      message: t("errors.fetch", { resource: t("general.message") })
    });
  }

  return {
    messageThreads: data?.items?.[0],
    unreadMessages,
    updateUnreadMessages,
    isError,
    isLoading,
    invalidate
  };
};
