import { useNavigate, useLocation } from "react-router-dom";

import ExportExternalUsersPage from "./ExportExternalUsersPage";

import api from "~/api/api";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import { UTMParameters } from "~/typing/carePortalTypes";

type UploadData = UTMParameters & {
  columns: string[];
  dateFrom: string;
  dateTo: string;
};

const ExportAnthemUsersPage = () => {
  // this page is explicitly for anthem
  const navigate = useNavigate();

  const location = useLocation();

  if (
    !config.isAnthem &&
    window.location.hostname !== "care-dev.sidekickhealth.com" &&
    window.location.hostname !== "localhost"
  ) {
    navigate(`/`);
  }

  const getExport = async ({
    uploadToken,
    externalUserTypeName,
    columnList,
    utmParams,
    dateFromString,
    dateToString,
    setResponseMessage,
    importEntryId
  }: {
    uploadToken: string;
    externalUserTypeName: string;
    columnList: string[];
    utmParams?: UTMParameters;
    dateFromString: string;
    dateToString: string;
    setResponseMessage: (message: string) => void;
    importEntryId?: string;
  }) => {
    const uploadData: UploadData = {
      ...utmParams,
      columns: columnList,
      dateFrom: dateFromString,
      dateTo: dateToString
    };

    await api
      .post(
        `/admin/extras/export/anthem?token=${uploadToken}${
          externalUserTypeName
            ? `&externalUserTypeName=${externalUserTypeName}`
            : ""
        }${importEntryId ? `&importEntryId=${importEntryId}` : ""}`,
        uploadData
      )
      .then((res) => {
        const csvContent = window.atob(res.data.file);
        // ugly way to download the csv data in a .csv file
        const link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
        );
        link.setAttribute("download", "exportedusers.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        if (err?.status === 403) {
          setResponseMessage(
            "Could not export due to a permission error. Is the upload token correct?"
          );
        } else {
          setResponseMessage(
            `Could not export users due to error, status:${err?.status}`
          );
        }
      });
  };

  return (
    <SentryErrorBoundary transactionName="ExportAnthemUsersPage">
      <ExportExternalUsersPage
        importEntryInfo={{
          id: (location.state as any)?.entryId,
          name: (location.state as any)?.entryName
        }}
        getExport={getExport}
      />
    </SentryErrorBoundary>
  );
};

export default ExportAnthemUsersPage;
