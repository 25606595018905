import { DateTime } from "luxon";
import { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { getLatestSurveyResult } from "./proHelpers";
import PROListAnswer from "./PROListAnswer";
import { getQuestionWithEmbeddedAnswers } from "./PROTemplate.helpers";
import styles from "./PROTemplate.module.scss";
import { PROTemplateContext } from "./proTemplateContext";
import PROTemplateImage from "./PROTemplateImage";

import { UserPageContext } from "../../userPage/UserPage.context";
import AnswerBubble from "../Answer/AnswerBubble";
import { PROPageContext } from "../PROPage/proPageContext";
import PROReducer, { PROActions } from "../PROReducer";
import ResultBubble from "../ResultBubble";

import { fetchTranslations } from "~/api/requests/getTranslations";
import {
  getSurveyResults,
  setCoachRatingForSurvey
} from "~/api/requests/surveyRequests";
import DisableIcon from "~/assets/calendar-disable.svg";
import ChevronRightThick from "~/assets/chevron-right-thick.svg";
import ChevronRight from "~/assets/chevron-right.svg";
import noteboard from "~/assets/noteboard.svg";
import Image from "~/components/image/Image";
import Modal from "~/components/modal/Modal";
import SkeletonPROPage from "~/components/skeletons/SkeletonPROPage";
import { SegmentationKey } from "~/constants/segmentationKeys";
import surveyQuestionDisplayTypes from "~/constants/surveyQuestionDisplayTypes";
import { useAutoTranslate } from "~/contexts/translationContext/useAutoTranslate";
import { dateIsInFuture, dateIsToday } from "~/helpers/date/dateHelpers";
import getRatingBasedOnResult from "~/helpers/getRatingBasedOnResult";
import kgToLbs from "~/helpers/units/kgToLbs";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import useUseMetric from "~/hooks/useUseMetric";
import useWindowSize from "~/hooks/useWindowSize";
import { PROURLParams } from "~/typing/carePortalTypes";
import {
  TranslationDtoInput,
  TranslationsDto,
  TranslationsDtoInput
} from "~/typing/graphql/types";
import {
  Survey,
  SurveyQuestion,
  SurveyResult,
  SurveyResultAnswer,
  SurveyResultQuestion
} from "~/typing/sidekickTypes";

type SurveyResultWithDateTime = SurveyResult & {
  dateTime: DateTime;
};

const PROTemplate = () => {
  const { userDetail, mutateUserDetail, userDetailLoading } = useContext(
    UserPageContext
  );

  const [translationsInput, setTranslationsInput] = useState<
    TranslationsDtoInput[]
  >([]);
  const [translations, setTranslations] = useState<TranslationsDto[]>([]);
  const [translationLoading, setTranslationLoading] = useState(true);

  const {
    isLoading,
    setIsLoading,
    useTextBased,
    surveys,
    setUseTextBased
  } = useContext(PROPageContext);

  const [PROResults, dispatch] = useReducer(PROReducer, []);

  const {
    program_id = "",
    locale = "",
    user_id = "",
    survey_id = ""
  } = useParams<PROURLParams>();

  const location = useLocation();
  const { autoTranslate } = useAutoTranslate();
  const { isEnabled: messageTranslationSegment } = useFeatureToggle({
    featureName: SegmentationKey.MessageTranslationSegment,
    userId: user_id
  });

  useEffect(() => {
    setSurveyResultsToDisplay([]);
  }, [location]);

  const [survey, setSurvey] = useState<Survey | undefined>(
    surveys?.find((survey) => survey.id == survey_id)
  );
  const [questions, setQuestions] = useState<SurveyQuestion[]>();

  //Allowed window sizes. Used to determine how many items are displayed.
  const windowSizes = [
    1440,
    1384,
    1328,
    1272,
    1216,
    1160,
    1104,
    1048,
    992,
    936,
    880
  ];

  const [proImages, setProImages] = useState<Map<string, any>>(new Map());

  //Offset from the start of the PROResults list.
  const [offsetFromToday, setOffsetFromToday] = useState(0);

  //List of SurveyResults that are supposed to be displayed.
  const [surveyResultsToDisplay, setSurveyResultsToDisplay] = useState<
    SurveyResultWithDateTime[] | undefined
  >();

  //Check for if an element in the surveyResultsToDisplay list has the value for the newest element.
  const [isToday, setIsToday] = useState(true);

  //Check for if an element in the surveyResultsToDisplay list has the value for the oldest element.
  const [isFirstDay, setIsFirstDay] = useState(false);

  //Map of months that are supposed to be displayed in the header. E.g. Aug 2022, Dec 2022, etc.
  //The key is the index in the surveyResultsToDisplay list above which the month should be displayed.
  //The value is the string to be displayed.
  const [headerMonths, setHeaderMonths] = useState<Map<number, string>>(
    new Map()
  );

  const [answerInModal, setAnswerInModal] = useState<
    SurveyResultAnswer | undefined
  >(undefined);

  const [dateInModal, setDateInModal] = useState<Date>();

  /* Settings */
  const { t } = useTranslation();
  const useMetric = useUseMetric();
  const { width, screenWidth } = useWindowSize();
  const coachLanguage = "en"; // Hard coded for Elevance
  const hasRatings = survey?.ratings && survey.ratings.length > 1;

  const getPROResults = async () => {
    if (survey) {
      setIsLoading(true);
      const surveyResults = await getSurveyResults({
        userId: user_id,
        locale,
        programId: program_id,
        surveyId: survey.id
      });

      dispatch({ type: PROActions.Fetch, payload: surveyResults });
      setIsLoading(false);
    }
  };

  /**
   * Sets the offset in the display list to a higher value.
   * This in turn makes the display list move left.
   */
  const goBack = () => {
    setOffsetFromToday(offsetFromToday + 1);
  };

  /**
   * Sets the offset in the display list to a lower value.
   * This in turn makes the display list move right.
   */
  const goForward = () => {
    if (!isToday) setOffsetFromToday(offsetFromToday - 1);
  };

  useEffect(() => {
    getSurveyResultsToDisplay();
  }, [PROResults, offsetFromToday, useTextBased, width, userDetail]);

  useEffect(() => {
    const loadTranslations = async () => {
      setTranslationLoading(true);

      try {
        const fetchedTranslations = await fetchTranslations({
          translationsInput,
          targetLanguage: coachLanguage,
          sourceLanguage: locale
        });

        setTranslations(fetchedTranslations);
      } catch (err) {
        console.error(err);
      } finally {
        setTranslationLoading(false);
      }
    };

    loadTranslations();
  }, [translationsInput]);

  useEffect(() => {
    if (messageTranslationSegment === undefined || !messageTranslationSegment) {
      return;
    }
    if (locale.split("-")[0] === coachLanguage.split("-")[0]) {
      return;
    }
    if (!surveyResultsToDisplay?.length || !autoTranslate) {
      return;
    }

    const translationsInput: TranslationsDtoInput[] = [];

    surveyResultsToDisplay?.forEach((pro) => {
      const translationArray: TranslationDtoInput[] = [];

      pro.questions?.forEach((question, index) => {
        if (question.displayType === surveyQuestionDisplayTypes.textInput) {
          translationArray.push({
            text: question.answers[0].answer,
            id: index
          });
        }
      });

      translationsInput.push({ translations: translationArray });
    });

    setTranslationsInput(translationsInput);
  }, [surveyResultsToDisplay, messageTranslationSegment, survey_id]);

  /**
   * Sets the check for if the last entry in the display list is the value for today.
   * Compares the value gotten from the backend with the current value in the display list.
   */
  useEffect(() => {
    if (
      surveyResultsToDisplay !== undefined &&
      PROResults !== undefined &&
      PROResults.length > 0 &&
      surveyResultsToDisplay.length > 0
    ) {
      setIsToday(
        surveyResultsToDisplay[surveyResultsToDisplay.length - 1].id ===
          PROResults[0].id
      );
      setIsFirstDay(
        surveyResultsToDisplay[0].id === PROResults[PROResults.length - 1].id
      );

      const monthHeaderMap = new Map<number, string>();

      //Check for other months in the display list.
      surveyResultsToDisplay?.forEach((pro, index) => {
        const entry = DateTime.fromISO(pro.date, {
          zone: "utc"
        }).toLocaleString({
          month: "short",
          year: "numeric"
        });

        if (!Array.from(monthHeaderMap.values()).includes(entry)) {
          monthHeaderMap.set(index, entry);
        }
      });

      setHeaderMonths(monthHeaderMap);
    }
  }, [surveyResultsToDisplay]);

  /**
   * Gets the answers that are supposed to be displayed on the screen.
   * Checks what resolution should be used to display the answers.
   * Returns the correct list wether the view is text based or not.
   */
  const getSurveyResultsToDisplay = () => {
    if (PROResults.length <= 0 || !userDetail) {
      setSurveyResultsToDisplay(undefined);
      return;
    }

    let numCols = useTextBased ? 2 : 10;

    if (!useTextBased) {
      windowSizes.forEach((size) => {
        if (
          ((width && width < size) || (screenWidth && screenWidth < size)) &&
          numCols > 1
        ) {
          numCols = numCols - 1;
        }
      });
    }

    //Reverse the list so that it is displayed correctly and add the date in the correct timezone.
    const finalList = PROResults.slice(
      offsetFromToday,
      offsetFromToday + numCols
    )
      .reverse()
      .map((item) => ({
        ...item,
        dateTime: DateTime.fromISO(item.localDate ?? item.date) // Use the users local timezone to display the date, if available
      }));

    setSurveyResultsToDisplay(finalList);
  };

  /**
   * Add the embedded questions to the corresponding answer it is enabled by
   */
  const formatEmbeddedQuestions = () => {
    const allQuestions = survey?.questions;

    allQuestions?.forEach((question, index) => {
      if (question.optionsOutput?.embedded) {
        for (let i = index; i >= 0; i--) {
          const prevQuestion = allQuestions?.[i];
          prevQuestion?.answers?.forEach((answer) => {
            if (question.enabledByAnswerIds?.includes(answer.id)) {
              answer.embeddedQuestion = question;
              i = -1;
            }
          });
        }
      }
    });

    setQuestions(allQuestions);
  };

  const updateCoachRating = async (
    coachRatingValue: number,
    surveyResultId: string,
    shouldUpdateDetails: boolean
  ) => {
    toast.remove();
    const ratingResponseData = await setCoachRatingForSurvey({
      programId: program_id,
      userId: user_id,
      locale,
      surveyResultId,
      coachRatingValue
    });

    if (ratingResponseData) {
      dispatch({ type: PROActions.Update, payload: ratingResponseData });
      if (shouldUpdateDetails) {
        mutateUserDetail();
      }
    }
  };

  const getUniqueValues = () => {
    const values: number[] = [];

    if (survey?.answerSeverities) {
      survey.answerSeverities.forEach((severity) => {
        values.push(severity.value);
      });
    } else {
      questions?.forEach((q) => {
        q.answers?.forEach((answer) => {
          if (!values.includes(answer.ratingValue)) {
            values.push(answer.ratingValue);
          }
        });
      });
    }
    return values.sort((a, b) => a - b);
  };

  const getUniqueValuesForQuestion = (question: SurveyQuestion) => {
    const values: number[] = [];

    if (survey?.answerSeverities) {
      survey.answerSeverities.forEach((severity) => {
        values.push(severity.value);
      });
    } else {
      question.answers?.forEach((answer) => {
        if (!values.includes(answer.ratingValue)) {
          values.push(answer.ratingValue);
        }
      });
    }
    return values.sort((a, b) => a - b);
  };

  const getCoachRating = (pro: SurveyResult) => {
    return survey?.ratings?.find((rating) => {
      return rating.coachRatingValue === pro.coachRatingValue;
    });
  };

  const getAnswerSeverity = (id: string) => {
    return survey?.answerSeverities?.find((severity) => severity.id === id);
  };

  /**
   * Creates the answers using the correct format based on whether to use metric or not.
   * @param question The question whose answer should be used.
   * @param useMetric Whether to use metric or not.
   * @returns The weight and the units based on the correct format. Kg or Lbs
   */
  const weightQuestionElement = (
    question: SurveyResultQuestion,
    useMetric: boolean
  ) => {
    const weight = question.answers?.[0]?.answer;
    const displayWeight = useMetric ? weight : kgToLbs(parseInt(weight));
    const units = useMetric ? "kg" : "lbs";
    return `${displayWeight} ${units}`;
  };

  const multiSelectionElement = (
    answerObj: SurveyResultAnswer,
    questionIndex?: number,
    proIndex?: number
  ) => {
    const { title, answer, ratingValue, surveyAnswerSeverityId } = answerObj;
    const value = surveyAnswerSeverityId
      ? getAnswerSeverity(surveyAnswerSeverityId)?.value
      : ratingValue;

    const answerText = title || answer;
    const defaultAnswer = <div className={styles.answer}>{answerText}</div>;

    return (
      <li key={answer}>
        <PROListAnswer
          proIndex={proIndex}
          isLoading={translationLoading}
          questionIndex={questionIndex}
          defaultAnswer={defaultAnswer}
          translations={translations}
        />
        {!surveyAnswerSeverityId && uniqueValues && uniqueValues.length > 1 && (
          <div className={styles.rating}>{value}</div>
        )}
      </li>
    );
  };

  const singleSelectionElement = (
    question: SurveyResultQuestion,
    questionIndex?: number,
    proIndex?: number
  ) => {
    if (!question?.answers) {
      return null;
    }

    const { title, answer } = question.answers?.[0];
    const unit = question.unit === undefined ? "" : question.unit;
    const defaultAnswer = <span>{title || answer + " " + unit}</span>;

    if (question.answers)
      return (
        <li>
          <PROListAnswer
            proIndex={proIndex}
            isLoading={translationLoading}
            questionIndex={questionIndex}
            defaultAnswer={defaultAnswer}
            translations={translations}
          />
        </li>
      );
  };

  /**
   * Returns a PROTemplateImage element for the question supplied.
   * @param question The question whos image will be returned.
   * @returns The element for the image.
   */
  const imageElement = (
    question: SurveyResultQuestion,
    date: Date | undefined
  ) => {
    return (
      <PROTemplateImage
        answer={question.answers?.[0]}
        onImageClick={() => {
          setAnswerInModal(question.answers?.[0]);
          setDateInModal(date);
        }}
      />
    );
  };

  /**
   * Determines what type of question should be displayed.
   * @param question The question to be displayed.
   * @param useSkipped Determines whether the question should be skipped but displayed.
   * @returns The element to displayed corresponding to the question type.
   */

  const answerDisplay = ({
    question,
    useSkipped,
    date,
    questionIndex,
    proIndex
  }: {
    question?: SurveyResultQuestion;
    useSkipped?: boolean;
    date?: Date;
    questionIndex?: number;
    proIndex?: number;
  }) => {
    if (!question) {
      if (useSkipped) {
        return t("pro.skipped", "Skipped");
      } else {
        return "";
      }
    }

    const displayType = question.displayType;
    const { weight, multiSelection, image } = surveyQuestionDisplayTypes;

    switch (displayType) {
      case weight:
        return weightQuestionElement(question, useMetric);
      case multiSelection:
        return question.answers?.map((answerObj) =>
          multiSelectionElement(answerObj, questionIndex, proIndex)
        );
      case image:
        return imageElement(question, date);
      default:
        return singleSelectionElement(question, questionIndex, proIndex);
    }
  };

  /**
   * Sets the display list to the newest survey result.
   */
  const handleSetToNewest = () => {
    setOffsetFromToday(0);
  };

  const dayClasses = (date: string) => {
    if (dateIsInFuture(date)) {
      return styles.dayColorFuture;
    }
    if (dateIsToday(date)) {
      return styles.dayColorToday;
    }
    return "";
  };

  useEffect(() => {
    if (survey_id && surveys?.length > 0 && survey?.id !== survey_id) {
      setSurveyResultsToDisplay(undefined);
      setSurvey(surveys?.find((survey) => survey.id == survey_id));
    }
  }, [survey_id, surveys]);

  useEffect(() => {
    if (survey) {
      setSurveyResultsToDisplay(undefined);
      setUseTextBased(survey?.options?.displayTextView ?? true);
      getPROResults();
      formatEmbeddedQuestions();
    }
  }, [survey]);

  const uniqueValues = getUniqueValues();

  const excludedFromResults = (
    <span
      className={styles.questionDescriptor}
      title={t("pro.excludedFromResults")}
    >
      <img src={DisableIcon} />
    </span>
  );

  if (isLoading || userDetailLoading) return null;

  const questionsToDisplay = questions?.filter(
    (question) => !question.title.includes("or calling 911")
  );

  if (isLoading) {
    return <SkeletonPROPage />;
  }
  const lastSurveyResult = getLatestSurveyResult(PROResults);

  return (
    <PROTemplateContext.Provider
      value={{
        proImages,
        setProImage: (key, image) => {
          proImages.set(key, image);
          setProImages(new Map(proImages));
        }
      }}
    >
      {surveyResultsToDisplay ? (
        <div
          className={`${styles.PROTemplate} ${
            survey && !hasRatings ? styles.hasNoRatings : ""
          }`}
        >
          <Helmet title={survey?.surveyName} defer={false} />
          <div
            className={
              styles.results + ` ${useTextBased ? styles.textResults : ""}`
            }
          >
            <table className={styles.table}>
              <thead>
                <tr>
                  {
                    <td colSpan={12} className={styles.todayCell}>
                      <button
                        type="button"
                        onClick={handleSetToNewest}
                        className={styles.today}
                      >
                        {t("time.latest")}
                        <img src={ChevronRightThick} />
                      </button>
                    </td>
                  }
                </tr>
                <tr>
                  <td />

                  {/* Header for month */}
                  {surveyResultsToDisplay?.map((pro, index) => {
                    return (
                      <td key={`month-${pro.id}-${index}-${pro.date}`}>
                        <div className={styles.header}>
                          {headerMonths.get(index)}
                        </div>
                      </td>
                    );
                  })}

                  {!useTextBased && (
                    <td
                      colSpan={
                        surveyResultsToDisplay
                          ? surveyResultsToDisplay?.length - 2
                          : 0
                      }
                    ></td>
                  )}
                </tr>
                <tr>
                  <td>
                    {!isFirstDay && (
                      <div className={styles.arrowLeftContainer}>
                        <img
                          className={`${styles.arrow} ${styles.arrowLeft}`}
                          src={ChevronRight}
                          onClick={() => goBack()}
                          data-testid="protemplate-arrow-left"
                        />
                      </div>
                    )}
                  </td>
                  {surveyResultsToDisplay?.map((pro, index) => (
                    <td key={`survey-display-${pro.id}-${index}-${pro.date}`}>
                      <div
                        className={`${styles.dayName} ${dayClasses(
                          pro.dateTime.toString()
                        )}`}
                      >
                        {pro.dateTime.weekdayShort}
                      </div>
                      <div
                        className={`${styles.dayNumber} ${dayClasses(
                          pro.dateTime.toString()
                        )}`}
                      >
                        {pro.dateTime.day}
                      </div>
                    </td>
                  ))}
                  <td>
                    {!isToday && (
                      <img
                        className={`${styles.arrow} ${styles.arrowRight}`}
                        src={ChevronRight}
                        onClick={() => goForward()}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td />
                  {
                    <>
                      {surveyResultsToDisplay?.map((pro, index) => {
                        return (
                          <td key={`time-simple-${pro.id}-${index}`}>
                            <div className={styles.time}>
                              {pro.dateTime.toLocaleString(
                                DateTime.TIME_SIMPLE
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </>
                  }
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {/*  */}
                <tr className={styles.resultRow}>
                  <th className={styles.resultTitle}>{t("general.result")}</th>

                  {
                    surveyResultsToDisplay?.map((pro, index) => {
                      const rating = getRatingBasedOnResult(
                        pro.result,
                        survey?.ratings
                      );
                      const coachRating = getCoachRating(pro);
                      const isLastPro = pro.id === lastSurveyResult?.id;
                      return (
                        <td key={`result-bubble-pro-${pro.id}-${index}`}>
                          <div
                            className={styles.resultContainer}
                            key={`result-${pro.id}`}
                          >
                            <ResultBubble
                              rating={rating}
                              coachRating={coachRating}
                              pro={pro}
                              onChangeRating={updateCoachRating}
                              survey={survey}
                              hasRatings={hasRatings ?? false}
                              isLastPro={isLastPro}
                              uniqueValues={uniqueValues}
                            />
                          </div>
                        </td>
                      );
                    })
                    //})
                  }
                </tr>
                {/* Questions and their answers */}
                {questionsToDisplay?.map((question, index) => {
                  if (question.optionsOutput?.embedded) {
                    return null;
                  }
                  return (
                    <tr key={`question-${question.id}-${index}`}>
                      <th>
                        <div className={styles.questionTitle}>
                          {question.title}
                          {question.optionsOutput?.excludeFromResults
                            ? excludedFromResults
                            : ""}
                        </div>
                      </th>
                      {surveyResultsToDisplay?.length === 0 && (
                        <td key={`qid-${question.id}-ab-${index}`}>
                          <div className={styles.answerContainer}>
                            {(hasRatings || !useTextBased) && (
                              <AnswerBubble
                                proExists={false}
                                uniqueValues={uniqueValues}
                              />
                            )}
                          </div>
                        </td>
                      )}
                      {surveyResultsToDisplay?.map((pro, index) => {
                        const {
                          question: answeredQuestion,
                          index: questionIndex
                        } = getQuestionWithEmbeddedAnswers(
                          question,
                          pro.questions ?? [],
                          index,
                          translations
                        );
                        if (!answeredQuestion)
                          return (
                            <td key={`non-answered-question-${index}`}></td>
                          );
                        //The answer cell. Can be an image.
                        return (
                          <td key={`answered-question-${pro.id}-${index}`}>
                            <div className={styles.answerContainer}>
                              {answeredQuestion?.displayType !==
                                surveyQuestionDisplayTypes.image &&
                                (hasRatings || !useTextBased) && (
                                  <AnswerBubble
                                    question={answeredQuestion}
                                    proExists={!!pro}
                                    uniqueValues={getUniqueValuesForQuestion(
                                      question
                                    )}
                                    answerSeverities={survey?.answerSeverities}
                                    answerDisplay={answerDisplay}
                                    getAnswerSeverity={getAnswerSeverity}
                                    questionIndex={questionIndex}
                                    proIndex={index}
                                  />
                                )}
                              {/* Text based answers that are not images*/}
                              {answeredQuestion?.displayType !==
                                surveyQuestionDisplayTypes.image &&
                                useTextBased && (
                                  <ul className={styles.answers}>
                                    {answerDisplay({
                                      question: answeredQuestion,
                                      useSkipped: false,
                                      questionIndex: questionIndex,
                                      proIndex: index
                                    })}
                                  </ul>
                                )}
                              {/* Image answers */}
                              {answeredQuestion?.displayType ===
                                surveyQuestionDisplayTypes.image &&
                                answerDisplay({
                                  question: answeredQuestion,
                                  useSkipped: false,
                                  date: DateTime.fromISO(pro.date).toJSDate()
                                })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className={styles.emptyPROContainer}>
          <div className={styles.emptyPROQuestionContainer}>
            <div className={styles.emptyPROQuestionHeader}>
              <p className={styles.emptyPROQuestionHeaderTitle}>
                {t("pro.questions")}
              </p>
            </div>

            <div className={styles.emptyPROQuestionContent}>
              {questionsToDisplay?.map((question, index) => {
                return (
                  <div
                    key={`question-display-${question.id}-${index}}`}
                    className={styles.emptyPROQuestionItem}
                  >
                    <p className={styles.emptyPROQuestionItemText}>
                      {question.title}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.emptyPROStateContainer}>
            <div className={styles.emptyPROStateTextFrame}>
              <p className={styles.emptyPROStateTextFrameUpperText}>
                {t("pro.noPROAnswerUpper")}
              </p>
              <p className={styles.emptyPROStateTextFrameLowerText}>
                {t("pro.noPROAnswerLower")}
              </p>
            </div>
            <div>
              <img src={noteboard} alt="" className="" />
            </div>
          </div>
        </div>
      )}
      {answerInModal && (
        <Modal
          title={`${
            dateInModal
              ? DateTime.fromISO(dateInModal?.toISOString()).toLocaleString(
                  DateTime.DATE_HUGE
                )
              : ""
          }`}
          onClose={() => setAnswerInModal(undefined)}
        >
          <Image
            className={styles.proModalImage}
            dataTestId="pro-template-modal-image"
            imageUrl={answerInModal.attachmentUrl ?? ""}
          />
        </Modal>
      )}
    </PROTemplateContext.Provider>
  );
};

export default PROTemplate;
