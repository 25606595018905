import { DateTime } from "luxon";

import api from "../api";

import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { BloodPressureData, BloodSugarData } from "~/typing/sidekickTypes";

export const getBloodPressureData = async ({
  programId,
  locale,
  userId,
  startDate,
  endDate,
  pageSize
}: {
  programId: string;
  locale: string;
  userId: string;
  startDate: DateTime;
  endDate: DateTime;
  pageSize?: number;
}): Promise<BloodPressureData[]> => {
  // Ensure startDate is always at the very start of the day
  startDate = startDate.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 1
  });
  // Ensure endDate is always at the very end of the day
  endDate = endDate.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999
  });

  return await api
    .get(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/bloodpressure?startDate=${startDate
        .toUTC()
        .toISO()}&endDate=${endDate.toUTC().toISO()}` +
        (pageSize ? `&pageSize=${pageSize}` : "")
    )
    .then((res) => res.data.bloodPressureScores ?? [])
    .catch(() => {
      displayErrorToast({
        message: t("errors.fetch", {
          resource: t("user.health.bloodPressure.bloodPressureData")
        })
      });
      return [];
    });
};

export const getBloodSugarData = async ({
  programId,
  locale,
  userId,
  startDate,
  endDate,
  pageSize,
  type
}: {
  programId: string;
  locale: string;
  userId: string;
  startDate: DateTime;
  endDate: DateTime;
  pageSize?: number;
  type: string;
}): Promise<BloodSugarData[]> => {
  // Ensure startDate is always at the very start of the day
  startDate = startDate.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 1
  });
  // Ensure endDate is always at the very end of the day
  endDate = endDate.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999
  });

  const getBloodSugarPath = () => {
    switch (type) {
      case "fasting":
        return "fastingbloodsugar";
      case "meal":
        return "mealbloodsugar";
      default:
        return "bloodsugar";
    }
  };

  return await api
    .get(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/${getBloodSugarPath()}?startDate=${startDate
        .toUTC()
        .toISO()}&endDate=${endDate.toUTC().toISO()}` +
        (pageSize ? `&pageSize=${pageSize}` : "")
    )
    .then((res) => res.data.bloodSugarScores ?? [])
    .catch(() => {
      displayErrorToast({
        message: t("errors.fetch", {
          resource: t("user.health.bloodSugar.bloodSugarData")
        })
      });
      return [];
    });
};
