import { useState, useEffect } from "react";

import styles from "./MedicationCard.module.scss";

import { createReminderString, importIcon } from "../utils/MedicationUtility";

import { t } from "~/i18n";
import { MedicationReminderType } from "~/typing/carePortalTypes";
import { Medication } from "~/typing/sidekickTypes";

interface MedicationCardProps {
  medication: Medication;
  hasLogs: boolean;
  onClick: (value: any) => void;
}

const MedicationCard = ({
  medication,
  hasLogs,
  onClick = () => undefined
}: MedicationCardProps) => {
  const [icon, setIcon] = useState<string>();
  const [reminderString, setReminderString] = useState<string>();

  useEffect(() => {
    //If iconId is not between 1 and 12, return because there is no icon for that index in the svg medication folder.
    if (!medication.iconId || medication.iconId > 12 || medication.iconId < 1)
      return;

    //Dynamically import the svg icon based on the medication iconId.
    const importIconAsync = async () => {
      setIcon(await importIcon(medication.iconId, medication.iconColor));
    };

    importIconAsync();
  }, [medication]);

  useEffect(() => {
    if (medication.reminders?.length > 0)
      setReminderString(
        createReminderString(
          medication.reminders,
          MedicationReminderType.MedicationCard
        )
      );
  }, [medication]);

  return (
    <div
      className={
        hasLogs ? styles.medicationCard : styles.medicationCardNonHover
      }
      onClick={hasLogs ? onClick : undefined}
    >
      <div className={styles.medicationCardHeader}>
        <p className={styles.medicationCardHeaderName} title={medication.name}>
          {medication.name}
        </p>
        {icon && (
          <img
            src={`data:image/svg+xml;base64,${window.btoa(icon)}`}
            className={styles.medicationCardHeaderIcon}
          />
        )}
        <p>{medication?.type?.title ?? "Other"}</p>
      </div>

      <div className={styles.medicationCardSchedule}>
        {/* List all medication reminders */}
        <div className={styles.medicationCardScheduleText}>
          {t("medication.schedule.schedule")}:{" "}
          {reminderString ? (
            <span className={styles.medicationCardScheduleTextBold}>
              {reminderString}
            </span>
          ) : (
            t("medication.schedule.none")
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicationCard;
