import { DateTime } from "luxon";
import { useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from "recharts";

import styles from "./MentalChartTemplate.module.scss";
import mentalReducer, { MentalActions } from "./mentalReducer";

import api from "~/api/api";
import ChartDetail from "~/components/charts/ChartDetail";
import CustomTooltip from "~/components/charts/CustomTooltip";
import CustomXAxisTick from "~/components/charts/CustomXAxisTick";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { avgByKey } from "~/helpers/array/arrayHelpers";
import filterDataByWeekOrMonth from "~/helpers/date/filterDataByWeekOrMonth";
import round from "~/helpers/number/round";
import getMostRecentProgram from "~/helpers/program/getMostRecentProgram";
import useUserDetail from "~/hooks/useApi/useUserDetail";
import colors from "~/styles/colors";
import { Mission } from "~/typing/sidekickTypes";

export default function MentalChartTemplate({
  mentalCategory,
  missions,
  useWeek
}: {
  mentalCategory: string;
  missions: Mission[];
  useWeek: boolean;
}) {
  const [missionData, dispatch] = useReducer(mentalReducer, []);
  const { program_id = "", locale = "", user_id = "" } = useParams();
  const { t } = useTranslation();
  const today = DateTime.utc();
  const { userDetail } = useUserDetail({
    programCatalogItemId: program_id,
    locale,
    userId: user_id
  });

  const userProgram = getMostRecentProgram(
    userDetail?.userPrograms ?? [],
    program_id,
    locale
  );

  const startDate = DateTime.fromISO(userProgram?.startDate ?? "");
  const endDate = DateTime.fromISO(userProgram?.endDate ?? "");

  const precision = 1;
  const weeklyData: {
    value: number | string;
    label: string;
    date: DateTime;
  }[] = [];

  let currentWeekLabel = "";
  for (
    let i = 1;
    (startDate.plus({ days: (i - 1) * 7 }).toISO() ?? "") <
    (endDate.toISO() ?? "");
    i++
  ) {
    const date = startDate.plus({ days: i * 7 });

    const weekData = filterDataByWeekOrMonth(missionData, true, date);

    const avg = avgByKey(weekData, "value", precision);
    const label = `${t("time.week")} ${i}`;

    if (date.diff(today, "days").days < 7) {
      currentWeekLabel = label;
    }

    weeklyData.push({
      value: !avg || isNaN(avg) ? t("performance.mental.noRatings") : avg,
      label: `${t("time.week")} ${i}`,
      date: date.minus({ days: 7 })
    });
  }

  const missionId = missions?.find(
    (mission) => mission.imageName === mentalCategory
  )?.id;

  useEffect(() => {
    getMissionData();
  }, [missionId]);

  const getMissionData = async () => {
    if (missionId) {
      const res = await api.get(
        `/coach/programs/${program_id}/locales/${locale}/users/${user_id}/scores?missionId=${missionId}`
      );
      dispatch({
        type: MentalActions.Fetch,
        payload: res?.data?.items || []
      });
    } else {
      dispatch({ type: MentalActions.Fetch, payload: [] });
    }
  };

  const axisLine = {
    stroke: colors.navyBlue20,
    strokeWidth: 2
  };

  const tick = {
    fill: colors.navyBlue50
  };

  const currentSlotStartDate = userProgram?.currentSlotStartDate
    ? DateTime.fromISO(userProgram.currentSlotStartDate)
    : today.minus({ days: 7 });

  const filterByWeek = true;
  const thisWeekData = filterDataByWeekOrMonth(
    missionData,
    filterByWeek,
    currentSlotStartDate.plus({ days: 7 })
  );
  const lastWeekData = filterDataByWeekOrMonth(
    missionData,
    filterByWeek,
    currentSlotStartDate
  );

  const thisWeekAvg = avgByKey(thisWeekData, "value", precision) ?? 0;
  const lastWeekAvg = avgByKey(lastWeekData, "value", precision) ?? 0;
  const weekDiff = round(thisWeekAvg - lastWeekAvg, 1) ?? 0;
  const weekTimeDiff = t("time.lastWeek", "last week").toLowerCase();
  const dataKey = ["value"];

  // considered positive if stress level goes down and if sleep quality or energy level go up
  const positive =
    mentalCategory === "mission_stress_levels" ? weekDiff < 0 : weekDiff > 0;

  return (
    <SentryErrorBoundary
      resetState={getMissionData}
      transactionName="MentalChartTemplate"
    >
      <div className={styles.chartDetailContainer}>
        <ChartDetail
          title={t("average.week", "Average of the week")}
          values={{ value: thisWeekAvg }}
          dataKeys={dataKey}
          valueDiff={weekDiff}
          positive={positive}
          timeDiff={weekTimeDiff}
        />
      </div>
      <ResponsiveContainer height={400}>
        <LineChart data={useWeek ? weeklyData : missionData}>
          <CartesianGrid stroke={colors.navyBlue10} />
          <XAxis
            dataKey="label"
            axisLine={axisLine}
            tickLine={false}
            tick={
              <CustomXAxisTick
                todayLabel={
                  useWeek
                    ? currentWeekLabel
                    : today.toLocaleString({
                        month: "short",
                        day: "numeric"
                      })
                }
              />
            }
          />
          <YAxis
            dataKey="value"
            axisLine={axisLine}
            tick={tick}
            tickLine={false}
            domain={[0, 10]}
            interval={0}
            allowDecimals={false}
          />
          <Tooltip content={<CustomTooltip allDataKeys={dataKey} />} />
          <Line
            type="monotone"
            dataKey="value"
            stroke={colors.lightBlue100}
            strokeWidth={3}
            connectNulls
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </SentryErrorBoundary>
  );
}
