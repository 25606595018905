import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  getSpecialtyName,
  mapSpecialtyCoachOption
} from "./AssignCareTeam.helpers";
import styles from "./AssignCareTeam.module.scss";

import { InformationPageContext } from "../informationPageContext";

import Close from "~/assets/svgComponents/Close";
import RefreshIcon from "~/assets/svgComponents/Refresh";
import Avatar from "~/components/avatar/Avatar";
import Modal from "~/components/modal/Modal";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import CustomSelect from "~/components/select/CustomSelect";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import { useCoachesForProgram } from "~/hooks/useApi/coaches/useCoachesForProgram";
import {
  useAssignCoachToUser,
  useAutoAssignCoachesToUser,
  useCoachesForUser,
  useRemoveCoachFromUser
} from "~/hooks/useApi/coaches/useCoachesForUser";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { useSpecialties } from "~/hooks/useApi/specialties/useSpecialties";
import { useProgramSpecialties } from "~/hooks/useApi/useProgramSpecialties";
import { UserURLParams } from "~/typing/carePortalTypes";
import { Coach, UserProgramCoach } from "~/typing/sidekickTypes";

type AssignCareTeamProps = {
  onClose: () => void;
};

type CoachWithSpecialty = Coach & {
  specialtyId?: string;
};

const AssignCareTeamContent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [assignmentError, setAssignmentError] = useState(false);

  const { program } = useContext(InformationPageContext);

  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const {
    programSpecialties,
    isLoading: programSpecialtiesLoading
  } = useProgramSpecialties({
    programId: program_id,
    locale
  });
  const { specialties, isLoading: specialtiesIsLoading } = useSpecialties({});
  const { coaches, isLoading: coachIsLoading } = useCoachesForProgram({
    programId: program_id,
    locale
  });

  const { coaches: assignedCoaches } = useCoachesForUser({
    programId: program_id,
    locale,
    userId: user_id
  });

  const { assignCoachToUser } = useAssignCoachToUser({
    userId: user_id,
    programId: program_id,
    locale
  });
  const { removeCoachFromUser } = useRemoveCoachFromUser({
    userId: user_id,
    programId: program_id,
    locale
  });

  const { autoAssignCoachesToUser } = useAutoAssignCoachesToUser({
    userId: user_id,
    programId: program_id,
    locale
  });

  const renderCoachOption = (coach) => {
    return (
      <div className={styles.coachOption} key={coach ? coach.coachId : "-1"}>
        {coach ? (
          <>
            {coach.userId ? (
              <Avatar user={coach} className={styles.avatar} />
            ) : null}
            <span className={!coach.userId ? styles.bold : ""}>
              {coach.fullName}
            </span>
          </>
        ) : (
          t("user.coach.assignACoach")
        )}
      </div>
    );
  };
  const renderSelectedOption = (coach) => {
    return (
      <div className={styles.selectedOption} key={coach ? coach.coachId : "-1"}>
        {coach ? (
          <>
            <Avatar user={coach} className={styles.avatar} />
            <span>{coach.fullName}</span>
          </>
        ) : (
          t("user.coach.assignACoach")
        )}
      </div>
    );
  };

  const renderOutdatedCareMemberOption = (outDatedCoach: UserProgramCoach) => {
    const coach = mapSpecialtyCoachOption({
      ...outDatedCoach,
      coachUserId: outDatedCoach.coachId,
      coaches: program?.coaches
    });
    return (
      <div className={styles.selectedOption} key={coach ? coach.userId : "-1"}>
        {coach ? (
          <>
            {coach.userId ? (
              <Avatar user={coach} className={styles.avatar} />
            ) : null}
            <span className={!coach.userId ? styles.bold : ""}>
              {coach.fullName}
            </span>
          </>
        ) : (
          t("user.coach.assignACoach")
        )}
      </div>
    );
  };

  const handleSelect = (coachId: string, specialtyId: string) =>
    assignCoachToUser(
      {
        coachId,
        specialtyId
      },
      {
        onSuccess: () => {
          // Invalidate the user programs so that the user's coach list is updated
          queryClient.invalidateQueries({
            queryKey: QueryKeyFactory.programs.users(program_id, locale)
          });
          displaySuccessToast({ message: t("user.coach.successfulAssign") });
        },
        onError: () => {
          setAssignmentError(true);
        }
      }
    );

  const handleDeselect = async (coachId: string, specialtyId: string) =>
    removeCoachFromUser(
      {
        coachId,
        specialtyId
      },
      {
        onError: () => {
          setAssignmentError(true);
        }
      }
    );

  const handleAutoAssign = async (specialtyId: string) =>
    autoAssignCoachesToUser(
      { specialtyId },
      {
        onSuccess: () => {
          displaySuccessToast({ message: t("user.coach.successfulAssign") });
        },
        onError: () => {
          setAssignmentError(true);
        }
      }
    );

  const getSpecialtyDropdownValue = (specialtyId: string) =>
    assignedCoaches?.find((coach) => coach.specialtyId === specialtyId)
      ?.coachId;

  const getDropdownOptions = (
    specialtyId: string
  ): Partial<CoachWithSpecialty>[] => {
    return (
      coaches
        ?.filter((coach) => coach.specialtyId === specialtyId)
        ?.map((coach) =>
          mapSpecialtyCoachOption({
            ...coach,
            coaches: program?.coaches
          })
        )
        ?.sort(
          (coachA, coachB) =>
            coachA.displayName?.localeCompare(coachB.fullName ?? "") ?? -1
        ) ?? []
    );
  };

  const outDatedCareTeamMembers = assignedCoaches?.filter(
    (coach) =>
      !programSpecialties?.some(
        (specialty) => specialty.specialtyId === coach.specialtyId
      )
  );

  const loading =
    coachIsLoading || programSpecialtiesLoading || specialtiesIsLoading;

  if (loading) return <PulseLoader inverted />;

  return (
    <>
      <div className={styles.dropdowns}>
        {programSpecialties?.map((specialty, index) => {
          const assignedCoachForSpecialty = assignedCoaches?.find(
            (coach) => coach.specialtyId === specialty.specialtyId
          );

          const dropdownOptions = getDropdownOptions(specialty.specialtyId);

          return (
            <div
              className={styles.dropdownItem}
              key={`specialty-dropdown-${index}`}
            >
              <CustomSelect
                key={`specialty-${specialty.id}-${index}`}
                label={getSpecialtyName(specialty.specialtyId, specialties)}
                value={getSpecialtyDropdownValue(specialty.specialtyId)}
                valueKey="userId"
                className={styles.coachMenu}
                onChange={(e) =>
                  handleSelect(e.target.value, specialty.specialtyId)
                }
                wrapperClassName={styles.coachMenuWrapper}
                renderOption={renderCoachOption}
                renderSelectedOption={renderSelectedOption}
                options={dropdownOptions}
                dataTestId={"assign-coach-menu-select"}
              />

              <div className={styles.actions}>
                <button
                  disabled={!dropdownOptions.length}
                  className={styles.autoAssignBtn}
                  onClick={() => handleAutoAssign(specialty.specialtyId)}
                >
                  <RefreshIcon />
                </button>
                <button
                  className={styles.deselectButton}
                  disabled={!assignedCoachForSpecialty}
                  onClick={() =>
                    handleDeselect(
                      assignedCoachForSpecialty?.coachId ?? "",
                      specialty.specialtyId
                    )
                  }
                >
                  <Close />
                </button>
              </div>
            </div>
          );
        })}
        {outDatedCareTeamMembers?.map((coach, index) => (
          <div
            className={styles.dropdownItem}
            key={`specialty-dropdown-${index}`}
          >
            <CustomSelect
              key={`specialty-${coach.specialtyId}-${index}`}
              label={getSpecialtyName(coach.specialtyId, specialties)}
              value={coach.coachId}
              valueKey="coachId"
              className={styles.coachMenu}
              wrapperClassName={styles.coachMenuWrapper}
              renderSelectedOption={renderOutdatedCareMemberOption}
              options={[coach]}
              dataTestId={"assign-coach-menu-select"}
              disabled
            />
            <div className={styles.actions}>
              <button
                className={styles.deselectButton}
                onClick={() =>
                  handleDeselect(coach?.coachId ?? "", coach.specialtyId)
                }
              >
                <Close />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.actions}>
        {assignmentError && (
          <p className={styles.error}>
            {"Something went wrong with the assignment"}
          </p>
        )}
      </div>
    </>
  );
};

const AssignCareTeamModal = ({ onClose }: AssignCareTeamProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      title={t("user.coach.careTeamAssignment")}
      className={styles.modal}
      contentClass={styles.content}
    >
      <div className={styles.innerContent}>
        <AssignCareTeamContent />
      </div>
    </Modal>
  );
};

export default AssignCareTeamModal;
