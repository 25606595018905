import { Link } from "react-router-dom";

import ActionBubble from "./components/actionBubble/ActionBubble";
import MessageActionBubble from "./components/actionBubble/MessageActionBubble";
import RestartedStatus from "./components/restart/RestartedStatus";
import { CoachUserRendered } from "./ProgramUsersPage";
import styles from "./ProgramUsersPage.module.scss";

import MealActionsIcon from "~/assets/food.svg";
import MessagesBubbleIcon from "~/assets/messages-bubble.svg";
import PROBubbleIcon from "~/assets/pro-bubble.svg";
import ScaleNotificationIcon from "~/assets/scale-notification.svg";
import SMSNotificationIcon from "~/assets/sms-notification.svg";
import Avatar from "~/components/avatar/Avatar";
import LastWeekPoints from "~/components/lastWeekPoints/LastWeekPoints";
import Notification from "~/components/notification/Notification";
import config from "~/config";
import { shouldUseLightText } from "~/helpers/colours/contrast";
import { humanizedTimeSince } from "~/helpers/date/dateHelpers";
import { isSurveyQuiz } from "~/helpers/getLatestSurvey";
import {
  getSurveyById,
  getSurveyRating
} from "~/helpers/surveys/surveyHelpers";
import {
  hasBeenDischargedFromProgram,
  hasFinishedProgram,
  hasQuitProgram,
  isScaleInactive,
  shouldShowRestartedStatusByRestartedDate
} from "~/helpers/user/userHelpers";
import { t } from "~/i18n";
import SurveyResultBubble from "~/pages/components/surveyResultBubble/SurveyResultBubble";
import colors from "~/styles/colors";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  CoachUser,
  LastMessageType,
  Program,
  Survey
} from "~/typing/sidekickTypes";

export const UserNameColumn = ({
  user,
  maxPoints,
  program
}: {
  user: CoachUser;
  maxPoints: number;
  program?: Program;
}) => {
  const userId = user.user?.userId || user?.id;

  const hasQuit = hasQuitProgram(
    user.programStatus,
    user.userProgramStatusReason
  );
  const isFinished = hasFinishedProgram(user.programStatus);

  const isDischarged = hasBeenDischargedFromProgram(
    user.programStatus,
    user.userProgramStatusReason
  );

  return (
    <Link to={`${userId}/information`}>
      <div className={styles.user}>
        <Avatar user={user.user} className={styles.avatar} />
        <div className={styles.userDetails}>
          <div
            className={`${styles.userName} ${
              hasQuit || isFinished || isDischarged ? styles.disabledUser : ""
            }`}
            data-testid="users-page-username"
          >
            {user.user.fullName ?? `[${t("warnings.noName")}]`}
            {!hasQuit && !isFinished && user.currentSlot && user.dayInSlot && (
              <span
                className={styles.currentSlot}
              >{` W${user.currentSlot}D${user.dayInSlot}`}</span>
            )}
            {shouldShowRestartedStatusByRestartedDate(user, program) && (
              <RestartedStatus
                className={styles.restartedStatus}
                userId={user.id}
                restartedDate={user.restartedDate}
              />
            )}
          </div>
          <LastWeekPoints
            userId={userId}
            maxPoints={maxPoints}
            displayName={user.user.fullName}
          />
        </div>
      </div>
    </Link>
  );
};

export const LastMessageDateColumn = ({
  user,
  isSmallerThanDesktop
}: {
  user: CoachUser;
  isSmallerThanDesktop: boolean;
}) => {
  if (!user.lastMessageDate) return null;

  return (
    <div className={styles.lastMessage}>
      <Notification
        className={`${styles.lastMessageNotification} ${
          user?.lastMessageType === LastMessageType.SMS ? styles.sms : ""
        }`}
        size="xs"
        count={1}
      />
      <p>
        {humanizedTimeSince(
          user.lastMessageDate ?? "",
          Boolean(isSmallerThanDesktop)
        )}
      </p>
    </div>
  );
};

export const PROResultColumn = ({
  user,
  surveys
}: {
  user: CoachUserRendered;
  surveys: Survey[];
}) => {
  if (user.user.selectedProResult === undefined) {
    return null;
  }

  const survey = getSurveyById(surveys, user.user.selectedSurveyId ?? "");

  const rating = getSurveyRating(user.user.selectedProResult, survey);

  const hasQuit = hasQuitProgram(
    user.programStatus,
    user.userProgramStatusReason
  );
  const isFinished = hasFinishedProgram(
    user.programStatus,
    user.userProgramStatusReason
  );

  const showPROResultNotification =
    !config.isAnthem &&
    user?.latestSurveyResults?.some(
      (result) => result.surveyId === survey?.id && !result.coachRatingDate
    ) &&
    !hasQuit &&
    !isFinished;

  return (
    <SurveyResultBubble
      // TODO TVT: Remove when surveys support Care Portal Content Items
      text={
        survey?.title != null &&
        survey.title.includes("Covid-19 Symptom Survey")
          ? survey.surveyName
          : survey?.title
      }
      result={user.user.selectedProResult}
      style={{
        background:
          user.user.selectedProColor || rating?.colour || colors.navyBlue20
      }}
      useLightText={shouldUseLightText(rating)}
      showNotifcation={showPROResultNotification}
    />
  );
};

export const ActionsAwaitingColumn = ({
  user,
  surveys,
  usingBodyTraceScale
}: {
  user: CoachUserRendered;
  surveys: Survey[];
  usingBodyTraceScale: boolean;
}) => {
  const userId = user.user?.userId || user?.id;
  const hasSurveyActions = user.latestSurveyResults?.some(
    (survey) =>
      !isSurveyQuiz(survey?.surveyName, surveys) && !survey?.coachRatingDate
  );
  const hasQuit = hasQuitProgram(
    user.programStatus,
    user.userProgramStatusReason
  );
  const isFinished = hasFinishedProgram(user.programStatus);
  const isDischarged = hasBeenDischargedFromProgram(
    user.programStatus,
    user.userProgramStatusReason
  );

  const {
    trackActionAwaitingMealSelected,
    trackActionAwaitingMessageSelected,
    trackActionAwaitingPROSelected
  } = useAmplitudeTracking();

  return (
    <div className={styles.actions}>
      {!(hasQuit || isFinished || isDischarged) && (
        <>
          {user.user.shouldShowFoodLogNotification && (
            <ActionBubble
              tooltipContent={t("programUserList.actionTooltip.meal")}
              icon={MealActionsIcon}
              url={`${userId}/food-journal`}
              isActive={user.hasMealLogActions}
              bottomText={humanizedTimeSince(user?.oldestPendingFoodLogDate)}
              onClick={() => trackActionAwaitingMealSelected({ userId })}
            />
          )}
          {user.user.shouldShowPRONotification && (
            <ActionBubble
              tooltipContent={t("programUserList.actionTooltip.survey")}
              icon={PROBubbleIcon}
              url={`${userId}/pro/${user.user.selectedSurveyId || ""}`}
              isActive={hasSurveyActions}
              bottomText={humanizedTimeSince(user?.oldestPendingSurveyResult)}
              onClick={() =>
                trackActionAwaitingPROSelected({
                  userId
                })
              }
            />
          )}
          {user.user.shouldShowMessageNotification &&
            (config.isAnthem ? (
              <ActionBubble
                tooltipContent={t("programUserList.actionTooltip.message")}
                icon={MessagesBubbleIcon}
                url={`${userId}/information?openMessages=true`}
                extraAttention={user.hasMessageActions}
                isActive={
                  user.hasNonRecipientsMessageActions || user.hasMessageActions
                }
                actionCount={
                  user.unseenMessageCount || user.unseenNonRecipientMessageCount
                }
                bottomText={humanizedTimeSince(
                  user.hasMessageActions
                    ? user?.oldestUnseenMessageFromUserDate
                    : user?.oldestUnseenNonRecipientMessageFromUserDate
                )}
                onClick={() =>
                  trackActionAwaitingMessageSelected({
                    userId,
                    isSms: false,
                    isCmRecipient: user.hasMessageActions
                  })
                }
              />
            ) : (
              <MessageActionBubble
                url={`${userId}/information?openMessages=true`}
                extraAttention={user.hasMessageActions}
                isActive={
                  user.hasNonRecipientsMessageActions || user.hasMessageActions
                }
                actionCount={
                  user.unseenMessageCount || user.unseenNonRecipientMessageCount
                }
                lastMessageDate={
                  user.oldestUnseenMessageFromUserDate ||
                  user.oldestUnseenNonRecipientMessageFromUserDate ||
                  ""
                }
                overallSentiment={user.overallSentiment}
                possibleTechnicalProblem={user.possibleUnseenTechnicalProblem}
                bottomText={humanizedTimeSince(
                  user.hasMessageActions
                    ? user?.oldestUnseenMessageFromUserDate
                    : user?.oldestUnseenNonRecipientMessageFromUserDate
                )}
                userId={userId}
              />
            ))}
          {user.user.shouldShowSMSNotification && (
            <ActionBubble
              tooltipContent={t("registerPatient.unreadSms")}
              icon={SMSNotificationIcon}
              isActive={user.unseenSms ?? false}
              url={`${userId}/information?openMessages=true&useSms=true`}
              className={styles.smsBubble}
              bottomText={humanizedTimeSince(user?.lastUnseenSmsDate)}
            />
          )}
          {!!usingBodyTraceScale &&
            user.user.shouldShowInactiveScaleNotification && (
              <ActionBubble
                tooltipContent={"Weight scale inactive"}
                icon={ScaleNotificationIcon}
                isActive={isScaleInactive(user)}
              />
            )}
        </>
      )}
    </div>
  );
};
