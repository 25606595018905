// custom hook to fetch graphql data through tanstack react-query

import { useMutation, useQuery } from "@tanstack/react-query";

import useUser from "../useUser";

import { getAllCoachesAndInvites, removeCareManager } from "~/api/graphql/invites";
import { RemoveCareManagerDtoInput } from "~/typing/graphql/types";

export const useCareManagersAndInvites = () => {
  const { user } = useUser();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["careManagersAndInvites"],
    queryFn: () => getAllCoachesAndInvites(parseInt(user?.id))
  });

  return { coaches: data || [], isLoading, isError, refetch };
};

// Mutation for removing the care manager from one or more programs, re-assigning any users to selected CMs
export const useRemoveCareManager = () => {
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: ['removeCareManager'],
    mutationFn: (removeCareManagerDto: RemoveCareManagerDtoInput) => removeCareManager(removeCareManagerDto)
  });

  return {
    removeCareManager: mutate,
    isPending,
    isSuccess,
    isError
  };
};
