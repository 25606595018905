import { DateTime } from "luxon";

export const getTimeZoneAdjustedDate = (
  isoDate: string,
  timeZoneOffset: number
) => {
  return (
    DateTime.fromISO(isoDate).plus({ hours: timeZoneOffset }).toISO() ?? ""
  );
};
