import { createContext } from "react";

import { UnreadMessagesState } from "~/pages/user/userPage/unreadMessagesReducer";
import {
  AuthenticatedUser,
  Coach,
  MessageType,
  UserDetails
} from "~/typing/sidekickTypes";

type MessageWindowContextType = {
  patient?: Partial<UserDetails>;
  usersForAvatars: { [key: number]: any };
  authUser?: AuthenticatedUser;
  minimized?: boolean;
  allCareManagersShown: boolean;
  chosenManager?: Coach;
  setMessageBeingRepliedTo: (message: MessageType | undefined) => void;
  messageInInput?: string;
  setMessageInInput: (message: string) => void;
  showRecommendedReplies?: boolean;
  setShowRecommendedReplies?: (show: boolean) => void;
  unreadMessages?: UnreadMessagesState;
  onSubmitMessage: (props: {
    message: string;
    replyingToMessage?: MessageType;
    messageSuggestionId?: string;
  }) => Promise<void>;
};

export const MessageWindowContext = createContext<MessageWindowContextType>(
  {} as MessageWindowContextType
);
