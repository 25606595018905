import { CareManagerTaskList } from "../programOverview/components/careManagerTasks/CareManagerTaskList";

import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";

const TaskListPage = () => {
  return (
    <SentryErrorBoundary transactionName="CareManagerTaskList">
      <Layout>
        <CareManagerTaskList />
      </Layout>
    </SentryErrorBoundary>
  );
};

export default TaskListPage;
