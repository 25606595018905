import { useTranslation } from "react-i18next";

import styles from "./ErrorFallback.module.scss";

type ErrorFallbackProps = {
  resetError: () => void;
  resetState?: () => void;
  eventId: string;
};

const ErrorFallback = ({
  resetError,
  resetState,
  eventId
}: ErrorFallbackProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{t("errors.oops")}</h1>
      <p className={styles.message}>{t("errors.somethingWentWrong")}</p>
      <p className={styles.eventId}>{`(eventId: ${eventId})`}</p>
      {resetState && (
        <button
          className="btn btn-sm btn-primary"
          type="button"
          onClick={() => {
            if (resetState) {
              resetState();
            }
            resetError();
          }}
        >
          {t("general.retry")}
        </button>
      )}
    </div>
  );
};

export default ErrorFallback;
