import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./NavHeaderBanner.module.scss";

import CloseIcon from "~/assets/close.svg";
import useNavHeaderNotification from "~/hooks/useNavHeaderNotification";
import { removeNavBanner } from "~/state/navHeaderNotification/navHeaderSlice";

export default function NavHeaderBanner() {
  const notification = useNavHeaderNotification();

  const [showBanner, setShowBanner] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setShowBanner(false);
    setTimeout(() => {
      dispatch(removeNavBanner());
    }, 500);
  };

  const getStyle = () => {
    switch (notification.type) {
      case "success":
        return styles.success;
      case "warning":
        return styles.warning;
      case "error":
        return styles.error;
      case "info":
      default:
        return styles.info;
    }
  };

  useEffect(() => {
    if (notification.message) {
      setShowBanner(true);
    }
  }, [notification.message]);

  if (!notification.message) return null;

  return (
    <div
      className={`${styles.banner} ${getStyle()} ${
        showBanner ? styles.show : styles.hide
      }`}
    >
      <div className={styles.content}>
        <p className={styles.text}>{notification.message}</p>
        {notification.action && (
          <button
            className={`${styles.action}`}
            onClick={() => {
              notification.action?.onClick();
              dispatch(removeNavBanner());
            }}
          >
            {notification.action.text}
          </button>
        )}
      </div>

      <button className={styles.close} onClick={handleClose}>
        <img src={CloseIcon} alt="Close" />
      </button>
    </div>
  );
}
