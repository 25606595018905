import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";

export default function SkeletonInformationPage() {
  return (
    <>
      <div className="skeleton-wrapper">
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonShimmer />
      </div>
      <div className="skeleton-wrapper">
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonShimmer />
      </div>
      <div className="skeleton-wrapper">
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonShimmer />
      </div>
    </>
  );
}
