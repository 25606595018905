import round from "../number/round";

import {
  getHighestValuePropertyInArray,
  getLowestValuePropertyInArray
} from "~/helpers/array/arrayHelpers";
import { t } from "~/i18n";
import colors from "~/styles/colors";
import { BloodPressureLineData } from "~/typing/carePortalTypes";

export type BloodPressureInfoData = {
  title: string;
  cellColor: string;
  cellTitle: string;
  systolic: number;
  diastolic: number;
};

export const getCellData = (data: BloodPressureLineData | undefined) => {
  if (!data) return { cellColor: "", cellTitle: "" };

  let cellColor = colors.neonGreen40;
  let cellTitle = t("user.health.bloodPressure.normalBp");

  if (data.systolic > 180 || data.diastolic > 120) {
    cellTitle = t("user.health.bloodPressure.crisisBp");
    cellColor = colors.red60;
  } else if (
    (data.systolic >= 140 && data.systolic <= 180) ||
    (data.diastolic >= 90 && data.diastolic <= 120)
  ) {
    cellTitle = t("user.health.bloodPressure.stage2Bp");
    cellColor = colors.red40;
  } else if (
    (data.systolic >= 130 && data.systolic < 140) ||
    (data.diastolic >= 80 && data.diastolic < 90)
  ) {
    cellTitle = t("user.health.bloodPressure.stage1Bp");
    cellColor = colors.red20;
  } else if (
    data.systolic >= 120 &&
    data.systolic < 130 &&
    data.diastolic < 80
  ) {
    cellTitle = t("user.health.bloodPressure.elevatedBp");
    cellColor = colors.mustardYellow40;
  }

  return {
    cellColor,
    cellTitle
  };
};

export const getAnalyzedBloodPressureData = (
  bloodPressureData: BloodPressureLineData[]
): BloodPressureInfoData[] => {
  const highestDiastolic = getHighestValuePropertyInArray(
    bloodPressureData,
    "diastolic"
  );
  const lowestSystolic = getLowestValuePropertyInArray(
    bloodPressureData,
    "systolic"
  );
  const highestSystolic = getHighestValuePropertyInArray(
    bloodPressureData,
    "systolic"
  );
  const lowestDiastolic = getLowestValuePropertyInArray(
    bloodPressureData,
    "diastolic"
  );

  return [
    {
      title: t("user.health.bloodPressure.highestSystolic"),
      systolic: round(highestSystolic?.systolic) ?? 0,
      diastolic: round(highestSystolic?.diastolic) ?? 0,
      ...getCellData(highestSystolic)
    },
    {
      title: t("user.health.bloodPressure.lowestSystolic"),
      systolic: round(lowestSystolic?.systolic) ?? 0,
      diastolic: round(lowestSystolic?.diastolic) ?? 0,
      ...getCellData(lowestSystolic)
    },
    {
      title: t("user.health.bloodPressure.highestDiastolic"),
      systolic: round(highestDiastolic?.systolic) ?? 0,
      diastolic: round(highestDiastolic?.diastolic) ?? 0,
      ...getCellData(highestDiastolic)
    },
    {
      title: t("user.health.bloodPressure.lowestDiastolic"),
      systolic: round(lowestDiastolic?.systolic) ?? 0,
      diastolic: round(lowestDiastolic?.diastolic) ?? 0,
      ...getCellData(lowestDiastolic)
    }
  ];
};
