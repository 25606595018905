import styles from "./DeviceCard.module.scss";

type DeviceCardProps = {
  name: string;
  subtitle: string;
  imageHref?: string;
};

const DeviceCard = ({
  name,
  subtitle,

  imageHref
}: DeviceCardProps) => {
  return (
    <div className={styles.card}>
      {imageHref && <img className={styles.image} src={imageHref} />}
      <div className={styles.content}>
        <p className={styles.title}>{name}</p>
        <p className={styles.description}>{subtitle}</p>
      </div>
    </div>
  );
};

export default DeviceCard;
