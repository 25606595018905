import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import Note from "./Note";
import {
  filterNoteByCareManager,
  filterNoteBySearchString,
  filterNoteByDate,
  filterByTopics
} from "./noteFilters";
import NoteForm from "./NoteForm";
import { getCoachesFromNotes, getTopicsFromNotes } from "./notesHelpers";
import styles from "./UserNotesModal.module.scss";

import ConfirmModal from "../../pages/team/ConfirmModal";

import PlusCircle from "~/assets/plus-circle.svg";
import Archive from "~/assets/svgComponents/Archive";
import Chevron from "~/assets/svgComponents/Chevron";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import CustomDatePicker from "~/components/customDatePicker/CustomDatePicker";
import Modal from "~/components/modal/Modal";
import SearchInput from "~/components/searchInput/SearchInput";
import CustomSelect from "~/components/select/CustomSelect";
import { journalItemTypes } from "~/constants/notes";
import { useUserNotes } from "~/hooks/useUserNotes";
import { t } from "~/i18n";
import colors from "~/styles/colors";
import { NoteInfo } from "~/typing/sidekickTypes";

interface UserNotesProps {
  onClose: () => void;
}

const UserNotes = ({ onClose }: UserNotesProps) => {
  const { notes, pinnedNotes, handleNotePinClick, addNote } = useUserNotes();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [searchString, setSearchString] = useState("");
  const [hideOptions, setHideOptions] = useState(true);
  const [showArchive, setShowArchive] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);

  const [selectedTopics, setSelectedTopics] = useState(
    journalItemTypes?.reduce((map, journal) => {
      map.set(journal.itemType, true);
      return map;
    }, new Map<number, boolean>())
  );
  const [selectedCareManager, setSelectedCareManager] = useState<string>(
    t("notes.allCareManagers")
  );
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filteredNotes, setFilteredNotes] = useState(notes);

  const filterNotes = (notes: NoteInfo[]) => {
    return notes.filter(
      (note) =>
        filterNoteBySearchString(note, searchString) &&
        filterNoteByCareManager(note, selectedCareManager) &&
        filterNoteByDate(note, startDate, endDate) &&
        filterByTopics(note, selectedTopics) &&
        (showArchive ? note.archived : !note.archived)
    );
  };

  useEffect(() => {
    setFilteredNotes(filterNotes(notes));
  }, [
    searchString,
    selectedCareManager,
    selectedTopics,
    startDate,
    endDate,
    notes,
    pinnedNotes,
    showArchive
  ]);

  const handleTopicToggle = (topicType, toggle: boolean) => {
    setSelectedTopics(new Map(selectedTopics.set(topicType, toggle)));
  };

  const toggleAllTopics = () => {
    let allTopicsSelected = true;

    selectedTopics.forEach((value) => {
      allTopicsSelected = allTopicsSelected && value;
    });

    selectedTopics.forEach((_, key, map) => {
      map.set(key, !allTopicsSelected);
    });
    setSelectedTopics(new Map(selectedTopics));
  };

  const handleClose = () => {
    if (textAreaRef.current && textAreaRef.current.value.length > 0) {
      setShowConfirmModal(true);
    } else {
      onClose();
    }
  };

  const handleNoteFormToggle = () => {
    setShowNoteForm((prev) => !prev);
  };
  return (
    <Modal
      onClose={handleClose}
      title={t("general.notes", "Notes")}
      className={styles.modal}
    >
      {showConfirmModal && (
        <ConfirmModal
          modalTitle={t("notes.unsaved")}
          confirmSubtitle={t("notes.closeWarning")}
          confirmText={t("general.close")}
          cancelText={t("general.back")}
          onClose={() => {
            setShowConfirmModal(false);
            if (textAreaRef?.current) textAreaRef.current.focus();
          }}
          onConfirm={() => {
            setShowConfirmModal(false);
            onClose();
          }}
          type="warning"
        />
      )}

      <div className={styles.container}>
        <div className={styles.sideNav}>
          <div>
            <SearchInput
              value={searchString}
              onChange={(value) => setSearchString(value)}
              placeholder={t("general.search")}
              className={styles.search}
            />
            <CustomDatePicker
              placeholderText={t("general.from")}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              onClear={() => setStartDate(undefined)}
              maxDate={endDate}
            />
            <CustomDatePicker
              placeholderText={t("general.to")}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              onClear={() => setEndDate(undefined)}
              minDate={startDate}
            />
            <CustomSelect
              placeholder={"Coaches"}
              valueKey="text"
              options={getCoachesFromNotes(notes)}
              onChange={(e) => {
                setSelectedCareManager(e.target.value);
              }}
              renderOption={(option) => option.text}
              value={selectedCareManager}
            />
          </div>
          <div className={styles.topicsWrapper}>
            <p>{t("notes.topics")}</p>
            <div data-testid="usernotes-topics" className={styles.topics}>
              <CheckboxInput
                label={t("general.all")}
                checked={Array.from(selectedTopics.values()).every(
                  (value) => value
                )}
                onChange={() => toggleAllTopics()}
              />
              {getTopicsFromNotes(notes).map(({ title, itemType }) => (
                <CheckboxInput
                  key={itemType}
                  label={title}
                  checked={selectedTopics.get(itemType) || false}
                  onChange={(checked) => handleTopicToggle(itemType, checked)}
                />
              ))}
            </div>
          </div>
          <div
            className={classNames(styles.archive, {
              [styles.show]: showArchive
            })}
          >
            <button
              className={styles.archiveButton}
              data-testid="usernotes-archive-btn"
              onClick={() => setShowArchive(!showArchive)}
            >
              <Archive
                color={showArchive ? colors.darkBlue100 : colors.navyBlue60}
              />
              Archive
            </button>
          </div>
        </div>
        <div className={styles.content}>
          {!showArchive ? (
            <>
              {showNoteForm && (
                <NoteForm
                  onCancel={handleNoteFormToggle}
                  onSave={addNote}
                  textAreaRef={textAreaRef}
                />
              )}
              <div className={styles.notesListHeader}>
                <h2>{t("general.notes")}</h2>
                {!showNoteForm && (
                  <button
                    className={classNames(
                      "btn-secondary btn-sm",
                      styles.addNoteButton
                    )}
                    onClick={handleNoteFormToggle}
                  >
                    <img src={PlusCircle} />
                    <p>{t("notes.addNote")}</p>
                  </button>
                )}
              </div>
            </>
          ) : (
            <button
              className={styles.backButton}
              onClick={() => setShowArchive(false)}
            >
              <Chevron direction="left" /> Back
            </button>
          )}
          <div
            className={styles.notes}
            onMouseLeave={() => setHideOptions(true)}
            onMouseEnter={() => setHideOptions(false)}
            data-testid="usernotes-notes"
          >
            {filteredNotes.map((note) => (
              <Note
                key={note.id}
                note={note}
                pinned={pinnedNotes?.some(
                  (pinned) => pinned.journalItemId === note.id
                )}
                inModal
                onPinClick={async () => {
                  await handleNotePinClick(note);
                }}
                hideOptions={hideOptions}
                highLightedWord={searchString}
              />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserNotes;
