import classNames from "classnames";
import { useEffect, useState } from "react";

import {
  getCarePriorityMessage,
  getCarePriorityMessageType
} from "./CarePriorities.helpers";
import styles from "./CarePriorities.module.scss";
import CarePriority from "./CarePriority";

import Button from "~/components/button/Button";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import { filterOutUndefined } from "~/helpers/array/arrayHelpers";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import { usePrefetchCoachSuggestedMessages } from "~/hooks/useApi/useCoachSuggestedMessages";
import {
  CareManagerActions,
  useNextRecommendedUserActions
} from "~/hooks/useApi/useNextRecommendedUserActions";
import useUser from "~/hooks/useUser";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  CarePriorities as Priorities,
  CarePriorityType,
  CarePriority as Priority,
  UserContextInfo
} from "~/typing/graphql/types";
import { MessageSuggestionType } from "~/typing/sidekickTypes";

type CarePrioritiesProps = {
  userId: string;
  userContextInfo: UserContextInfo;
  carePriorities?: Priorities;
  onAllCarePrioritiesCompleted?: () => void;
};

const cx = classNames.bind(styles);

const CarePriorities = ({
  userId,
  userContextInfo,
  carePriorities,
  onAllCarePrioritiesCompleted
}: CarePrioritiesProps) => {
  const [expandedCarePriority, setExpandedCarePriority] = useState<
    CarePriorityType | undefined
  >();
  const [markedAsDoneLoading, setMarkedAsDoneLoading] = useState(false);
  const [checkedCarePriorities, setCheckedCarePriorities] = useState<
    CarePriorityType[]
  >([]);
  const [completedCarePriorities, setCompletedCarePriorities] = useState<
    CarePriorityType[]
  >([]);
  const {
    registerActionForRecommendedUser,
    // isPending: registerActionPending, //TODO: Figure out best way to display loading state for this action
    isError: registerActionError
  } = useNextRecommendedUserActions({
    userId: userId
  });

  const {
    trackCarePriorityMarkedDone,
    trackCarePriorityTabChanged
  } = useAmplitudeTracking();

  const { user: authUser } = useUser();

  usePrefetchCoachSuggestedMessages({
    programId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? "",
    userId: userId,
    authUserId: authUser?.userId ?? "",
    messageType: MessageSuggestionType.FirstWeek,
    condition: carePriorities?.priorities.some(
      (p) => p?.type === CarePriorityType.FirstWeek
    )
  });

  usePrefetchCoachSuggestedMessages({
    programId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? "",
    userId: userId,
    authUserId: authUser?.userId ?? "",
    messageType: MessageSuggestionType.ChurnRisk,
    condition: carePriorities?.priorities.some(
      (p) => p?.type === CarePriorityType.HighChurnRisk
    )
  });

  usePrefetchCoachSuggestedMessages({
    programId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? "",
    userId: userId,
    authUserId: authUser?.userId ?? "",
    messageType: MessageSuggestionType.WeeklyFeedback,
    condition: carePriorities?.priorities.some(
      (p) => p?.type === CarePriorityType.WeeklyFeedback
    )
  });

  const handleCheckCarePriority = (type: CarePriorityType) => {
    if (completedCarePriorities.includes(type)) return;
    if (checkedCarePriorities.includes(type)) {
      setCheckedCarePriorities(
        checkedCarePriorities.filter((checkedType) => checkedType !== type)
      );
    } else {
      setCheckedCarePriorities([...checkedCarePriorities, type]);

      if (expandedCarePriority === type) {
        setExpandedCarePriority(undefined);
      }
    }
  };

  const markPriorityAsDone = async (carePriorityType: CarePriorityType) => {
    await registerActionForRecommendedUser({
      careManagerAction: CareManagerActions.CMA_MARK_ROR_AS_DONE,
      timeOfRanking: carePriorities?.timeOfRanking,
      userId: userId,
      carePriorityType
    });

    trackCarePriorityMarkedDone({
      carePriority: carePriorityType,
      userId: userId
    });
  };

  const handleMarkAsDone = async () => {
    setMarkedAsDoneLoading(true);
    for (const type of checkedCarePriorities) {
      await markPriorityAsDone(type);
    }
    //If we encounter an error, we should not mark the care priority as done
    if (registerActionError) return;

    setCompletedCarePriorities([
      ...completedCarePriorities,
      ...checkedCarePriorities
    ]);

    setCheckedCarePriorities([]);

    setMarkedAsDoneLoading(false);
  };

  useEffect(() => {
    setCompletedCarePriorities([]);
    setCheckedCarePriorities([]);
  }, [userId]);

  useEffect(() => {
    if (completedCarePriorities.length === carePriorities?.priorities.length) {
      onAllCarePrioritiesCompleted?.();
    }
  }, [completedCarePriorities]);

  const getCarePriorityProps = (
    priority: Priority
  ): {
    title: string;
    userContext: any;
    userId: string;
    checked?: boolean;
    onChange: (value: boolean) => void;
    completed?: boolean;
    messageSuggestionType?: MessageSuggestionType;
    carePriorityMessage?: string;
    onClick?: () => void;
    expanded?: boolean;
  } => {
    return {
      title: convertEnumToTitleCase(priority.type),
      messageSuggestionType: getCarePriorityMessageType(priority.type),
      userContext: userContextInfo,
      userId,
      onChange: () => handleCheckCarePriority(priority.type),
      completed: completedCarePriorities.includes(priority.type),
      checked: checkedCarePriorities.includes(priority.type),
      carePriorityMessage: getCarePriorityMessage({
        carePriority: priority,
        userName: userContextInfo.userName
      }),
      onClick: () => handleClickCarePriority(priority.type),
      expanded: expandedCarePriority === priority.type
    };
  };

  const handleClickCarePriority = (type: CarePriorityType) => {
    if (expandedCarePriority === type) {
      setExpandedCarePriority(undefined);
    } else {
      const previousExpandedCarePriority = expandedCarePriority;

      setExpandedCarePriority(type);

      trackCarePriorityTabChanged({
        from: previousExpandedCarePriority ?? "None",
        to: type
      });
    }
  };

  const uncompletedCarePrioritiesAmount = carePriorities?.priorities.length
    ? carePriorities.priorities.length - completedCarePriorities.length
    : 0;

  const allCarePrioritiesCompleted = uncompletedCarePrioritiesAmount === 0;

  if (!carePriorities?.priorities.length)
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p>
            <strong>{t("nextStep.careManagerTasks.noTasksDescription")}</strong>
          </p>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CheckboxInput
          checked={
            uncompletedCarePrioritiesAmount === checkedCarePriorities.length ||
            allCarePrioritiesCompleted ||
            false
          }
          indetermined={Boolean(checkedCarePriorities.length)}
          onChange={(checked) =>
            checked
              ? setCheckedCarePriorities(
                  carePriorities?.priorities
                    .filter(filterOutUndefined)
                    .filter((p) => !completedCarePriorities.includes(p.type))

                    .map((p) => p.type) ?? []
                )
              : setCheckedCarePriorities([])
          }
          disabled={allCarePrioritiesCompleted}
          label={"Care Priorities"}
          className={styles.checkbox}
        />
        <div className={styles.headerContent}>
          <div className={styles.headerInfo}>
            <p className={styles.amount}>{uncompletedCarePrioritiesAmount}</p>
          </div>

          <Button
            className={cx({
              [styles.button]: true,
              [styles.hidden]: !checkedCarePriorities.length
            })}
            onClick={handleMarkAsDone}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleMarkAsDone();
              }
            }}
            isLoading={markedAsDoneLoading}
            tabIndex={!checkedCarePriorities.length ? -1 : 0}
          >
            Mark as done
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {carePriorities?.priorities.map((priority, index) => {
          if (!priority) return null;
          return (
            <CarePriority key={index} {...getCarePriorityProps(priority)} />
          );
        })}
      </div>
    </div>
  );
};

export default CarePriorities;
