import styles from "./CoachColumn.module.scss";

import Avatar from "~/components/avatar/Avatar";
import CareTeam from "~/components/careTeam/CareTeam";
import { useProgramSpecialties } from "~/hooks/useApi/useProgramSpecialties";
import { CoachUser, Program } from "~/typing/sidekickTypes";

type CoachColumnProps = {
  program: Program;
  user: CoachUser;
};

const CoachColumn = ({ program, user }: CoachColumnProps) => {
  const { programSpecialties } = useProgramSpecialties({
    programId: program?.programCatalogItemId,
    locale: program?.locale
  });

  if (programSpecialties?.length) {
    return <CareTeam careTeam={user.coaches ?? []} coaches={program.coaches} />;
  }

  return (
    <Avatar
      user={
        program.coaches?.find(
          (coach) => coach.userId === user.assignedCoachUserId
        ) ?? { imageHref: "", fullName: "" }
      }
      className={styles.avatar}
      showNameOnHover={true}
    />
  );
};

export default CoachColumn;
