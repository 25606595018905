import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { AccessManagementProgramsColumn } from "./AccessManagementPage.columns";
import styles from "./AccessManagementPage.module.scss";
import AddCareManagerModal from "./components/addCareManagerModal/AddCareManagerModal";
import DeleteCareManagerModal from "./components/deleteCareManagerModal/DeleteCareManagerModal";
import ReassignUsersModal from "./components/reassignUsersModal/ReassignUsersModal";
import RemoveFromProgramModal from "./components/removeFromProgram/RemoveFromProgramModal";

import MinusCircleIcon from "~/assets/minus-circle.svg";
import PlusCircleIcon from "~/assets/plus-circle.svg";
import Avatar from "~/components/avatar/Avatar";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import { createFilterData } from "~/components/customList/CustomList.utils";
import Layout from "~/components/layout/Layout";
import Options from "~/components/options/Options";
import Pill from "~/components/pill/Pill";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import { DEFAULT_FILTER_VALUE } from "~/constants/filters";
import { getUserPrivilegeKey } from "~/constants/privileges";
import { filterOutUndefined } from "~/helpers/array/arrayHelpers";
import { useCareManagersAndInvites } from "~/hooks/graphql/useCareManagersAndInvites";
import usePrograms from "~/hooks/useApi/usePrograms";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { CustomListFilterData } from "~/typing/carePortalTypes";
import { CareManagerWithPcIs } from "~/typing/graphql/types";
import { Program } from "~/typing/sidekickTypes";

const cx = classNames.bind(styles);

enum ModalType {
  AddUser = "add-user",
  Reassign = "reassign",
  DeleteCareManager = "delete-care-manager",
  RemoveCareManagerFromProgram = "remove-care-manager-from-program",
  InviteToProgram = "invite-to-program"
}

const AccessManagementPage = () => {
  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
  const [userToInviteToProgram, setUserToInviteToProgram] = useState<
    CareManagerWithPcIs | undefined
  >();
  const [selectedOptionModal, setSelectedOptionModal] = useState<ModalType>();

  const setModalToOpen = (
    modalType: ModalType,
    selectedCoach: CareManagerWithPcIs
  ) => {
    setUserToInviteToProgram(selectedCoach);
    setSelectedOptionModal(modalType);
  };
  const { programs } = usePrograms();

  const { coaches, isLoading, isError } = useCareManagersAndInvites();

  const queryClient = useQueryClient();

  const invalidateList = () => {
    queryClient.invalidateQueries({ queryKey: ["careManagersAndInvites"] });
  };

  const { trackAccessManagementOpened } = useAmplitudeTracking();

  useEffect(() => {
    trackAccessManagementOpened();
  }, []);

  const columns: CustomListColumn<CareManagerWithPcIs>[] = [
    {
      className: styles.memberColumn,
      heading: {
        text: t("accessManagement.table.careManager"),
        sortable: {
          reverse: false,
          sortBy: ["careManager", "name"],
          //Sort alphabetically by name if the care manager exists, otherwise sort by email
          customSortFunction: (a, b) => {
            if (
              a?.careManager?.careManagerExists &&
              b?.careManager?.careManagerExists
            ) {
              return a?.careManager?.name.localeCompare(b?.careManager?.name);
            } else if (a?.careManager?.careManagerExists) {
              return -1;
            } else if (b?.careManager?.careManagerExists) {
              return 1;
            } else {
              return a?.careManager?.email.localeCompare(b?.careManager?.email);
            }
          }
        }
      },
      render: ({ careManager }) => (
        <div className={styles.member}>
          <Avatar
            user={{
              fullName: careManager?.name ?? careManager?.email ?? "",
              imageHref: careManager?.imageHref,
              role: careManager?.role
            }}
            displayTitle
            size="mdplus"
            wrapperClassName={styles.memberAvatar}
          />
          {!careManager?.careManagerExists && (
            <Pill
              title={t("accessManagement.table.pending")}
              className={styles.pill}
            />
          )}
        </div>
      )
    },
    {
      heading: {
        text: t("accessManagement.table.totalUsers"),
        sortable: {
          reverse: false,
          sortBy: ["coachUserStatistics", "totalUserCount"],
          customSortFunction: (a, b) => {
            const totalUsersA = a?.coachUserStatistics?.reduce(
              (acc, coachUser) => acc + (coachUser?.totalUserCount ?? 0),
              0
            );
            const totalUsersB = b?.coachUserStatistics?.reduce(
              (acc, coachUser) => acc + (coachUser?.totalUserCount ?? 0),
              0
            );

            return totalUsersA - totalUsersB;
          }
        }
      },
      render: ({ coachUserStatistics }) => (
        <p>
          {coachUserStatistics?.reduce(
            (acc, coachUser) => acc + (coachUser?.totalUserCount ?? 0),
            0
          )}
        </p>
      )
    },
    {
      heading: {
        text: t("accessManagement.table.activeUsers"),
        sortable: {
          reverse: false,
          sortBy: ["coachUserStatistics", "activeUserCount"],
          customSortFunction: (a, b) => {
            const totalActiveUsersA = a?.coachUserStatistics?.reduce(
              (acc, coachUser) => acc + (coachUser?.activeUserCount ?? 0),
              0
            );
            const totalActiveUsersB = b?.coachUserStatistics?.reduce(
              (acc, coachUser) => acc + (coachUser?.activeUserCount ?? 0),
              0
            );

            return totalActiveUsersA - totalActiveUsersB;
          }
        }
      },
      render: ({ coachUserStatistics }) => (
        <p>
          {coachUserStatistics?.reduce(
            (acc, coachUser) => acc + (coachUser?.activeUserCount ?? 0),
            0
          )}
        </p>
      )
    },
    {
      className: styles.programColumn,
      heading: {
        text: t("accessManagement.table.programs")
      },
      render: ({ pcis, coachUserStatistics }) => (
        <AccessManagementProgramsColumn
          pcis={pcis?.filter(filterOutUndefined) ?? []}
          programs={programs}
          coachUserStatistics={
            coachUserStatistics?.filter(filterOutUndefined) ?? []
          }
        />
      )
    },
    {
      heading: {
        text: t("accessManagement.table.privileges"),
        sortable: {
          reverse: true,
          sortBy: ["careManager", "privileges"],
          //custom sort function, that parses the privileges and sorts them by the order of the enum.
          //0 privileges are always last
          customSortFunction: (a, b) => {
            const privilegeA = parseInt(a?.careManager?.privileges);
            const privilegeB = parseInt(b?.careManager?.privileges);

            if (privilegeA === 0) {
              return 1;
            } else if (privilegeB === 0) {
              return -1;
            } else {
              return privilegeA - privilegeB;
            }
          }
        }
      },
      render: ({ careManager }) => (
        <p>
          {careManager?.privileges
            ? getUserPrivilegeKey(careManager?.privileges)
            : null}
        </p>
      )
    },
    {
      heading: {
        text: t("accessManagement.table.options")
      },
      render: (coach) => (
        <Options
          options={[
            {
              func: () => setModalToOpen(ModalType.InviteToProgram, coach),
              renderOption: () => (
                <span className={styles.option}>
                  <img
                    src={PlusCircleIcon}
                    alt={t("accessManagement.table.inviteToProgram")}
                  />
                  <p>{t("accessManagement.table.inviteToProgram")}</p>
                </span>
              )
            },
            {
              func: () =>
                setModalToOpen(ModalType.RemoveCareManagerFromProgram, coach),
              renderOption: () => (
                <span className={styles.option}>
                  <img
                    src={MinusCircleIcon}
                    alt={t("accessManagement.table.removeFromProgram")}
                  />
                  <p>{t("accessManagement.table.removeFromProgram")}</p>
                </span>
              )
            }
            // We won't be allowing deletion of care managers for now
            // {
            //   func: () => setModalToOpen(ModalType.DeleteCareManager, coach),
            //   className: styles.optionWithTopBorder,
            //   renderOption: () => (
            //     <span className={cx({ option: true, warning: true })}>
            //       <img src={RemoveUserIcon} alt="Delete Care Manager" />
            //       <p>Delete care manager</p>
            //     </span>
            //   )
            // }
          ]}
        />
      )
    }
  ];

  // Create filter data for roles, return array of {value: role, text: role}
  // Filter out undefined, null values and duplicates
  // Have an "All" option as default and None option for users with no role
  const getOptionsForRoles = (coaches: CareManagerWithPcIs[]) => {
    const roles = coaches
      .map((coach) => coach.careManager?.role)
      .filter(filterOutUndefined);
    const uniqueRoles = [...new Set(roles)];
    return [
      {
        value: DEFAULT_FILTER_VALUE,
        text: t("accessManagement.table.filters.all")
      },
      { value: "None", text: "No role set" },
      ...uniqueRoles.map((role) => ({ value: role, text: role }))
    ];
  };

  // Create filter data for programs, return array of {value: programCatalogItemId, text: programName}
  // Program name needs to be looked up in programs array
  const getOptionsForPrograms = (
    coaches: CareManagerWithPcIs[],
    programs: Program[]
  ) => {
    const programCatalogItemIds = coaches.flatMap(
      (coach) =>
        coach.pcis?.map((pci) => pci?.programCatalogItemId.toString()) ?? []
    );
    const uniqueProgramCatalogItemIds = [...new Set(programCatalogItemIds)];
    return [
      {
        value: DEFAULT_FILTER_VALUE,
        text: t("accessManagement.table.filters.all")
      },
      ...uniqueProgramCatalogItemIds.map((programCatalogItemId) => {
        const program = programs.find(
          (p) => p.programCatalogItemId === programCatalogItemId
        );
        return {
          value: programCatalogItemId,
          text: program?.name ?? t("accessManagement.table.filters.unknown")
        };
      })
    ];
  };

  const filterData: CustomListFilterData<CareManagerWithPcIs>[] = [
    createFilterData({
      key: "Program",
      label: t("accessManagement.table.filters.program"),
      options: getOptionsForPrograms(coaches ?? [], programs ?? []),
      renderOption: (option) => option.text,
      defaultValue: DEFAULT_FILTER_VALUE,
      filter: ({ entity: coach, filterValue }) =>
        filterValue === DEFAULT_FILTER_VALUE ||
        coach?.pcis?.some(
          (program) => program?.programCatalogItemId.toString() === filterValue
        ) ||
        false
    }),
    createFilterData({
      key: "Role",
      label: t("accessManagement.table.filters.role"),
      options: getOptionsForRoles(coaches ?? []),
      renderOption: (option) => option.text,
      defaultValue: DEFAULT_FILTER_VALUE,
      filter: ({ entity: coach, filterValue }) => {
        return (
          filterValue === DEFAULT_FILTER_VALUE ||
          (filterValue === "None" && !coach.careManager?.role) ||
          coach.careManager?.role === filterValue
        );
      }
    }),
    createFilterData({
      key: "Privileges",
      label: t("accessManagement.table.filters.privileges"),
      options: [
        {
          value: DEFAULT_FILTER_VALUE,
          text: t("accessManagement.table.filters.all")
        },
        // { value: "Admin", text: "Admin" }, // kjartan 2024-04-17: I don't think there are any users with this privilege.
        { value: "Coach", text: t("accessManagement.table.filters.coach") },
        {
          value: "Lead Coach",
          text: t("accessManagement.table.filters.leadCoach")
        },
        { value: "Other", text: t("accessManagement.table.filters.other") }
      ],
      renderOption: (option) => option.text,
      defaultValue: DEFAULT_FILTER_VALUE,
      filter: ({ entity: coach, filterValue }) => {
        if (filterValue === DEFAULT_FILTER_VALUE) {
          return true;
        }

        //If filterValue is "Other", check if there are no privileges set, or if the privilege is not "Coach" or "Lead Coach"
        if (filterValue === "Other") {
          return (
            !coach.careManager?.privileges ||
            !["Coach", "Lead Coach"].includes(
              getUserPrivilegeKey(coach.careManager?.privileges)
            )
          );
        }
        return (
          !!coach.careManager?.privileges &&
          getUserPrivilegeKey(coach.careManager?.privileges) === filterValue
        );
      }
    }),
    // filter to filter out pending users
    createFilterData({
      key: "Pending",
      label: t("accessManagement.table.filters.status"),
      options: [
        {
          value: DEFAULT_FILTER_VALUE,
          text: t("accessManagement.table.filters.all")
        },
        { value: "active", text: t("accessManagement.table.filters.active") },
        { value: "Pending", text: t("accessManagement.table.filters.pending") }
      ],
      renderOption: (option) => option.text,
      defaultValue: DEFAULT_FILTER_VALUE,
      filter: ({ entity: coach, filterValue }) => {
        if (filterValue === "active") {
          return !!coach.careManager?.careManagerId;
        } else {
          return (
            filterValue === DEFAULT_FILTER_VALUE ||
            !coach.careManager?.careManagerExists
          );
        }
      }
    })
  ];

  return (
    <Layout
      title={t("accessManagement.accessManagement")}
      actionItems={[
        {
          text: t("accessManagement.addUser"),
          onClick: () => setAddUsersModalOpen(true)
        }
      ]}
    >
      <Helmet title={t("accessManagement.accessManagement")} defer={false} />
      <div className={cx({ wrapper: true })}>
        {isError && (
          <div>You do not have the necessary privileges to view this page.</div>
        )}
        {isLoading && <PulseLoader inverted />}
        {!isError && !isLoading && (
          <>
            <CustomList
              customListUniqueId="access-management-coaches-list"
              pagination={{
                entitiesPerPage: 10
              }}
              columns={columns}
              entities={coaches ?? []}
              isLoading={false}
              searchData={{
                data: [
                  { key: ["careManager", "name"] },
                  { key: ["careManager", "email"] }
                ],
                placeholder: t("general.searchWithDots")
              }}
              filterSelectData={filterData}
              defaultOrder="desc"
              defaultSortingData={{
                reverse: false,
                sortBy: ["careManager", "name"]
              }}
            />
          </>
        )}
      </div>
      {addUsersModalOpen && (
        <AddCareManagerModal
          onClose={() => setAddUsersModalOpen(false)}
          programsAvailable={programs}
          onSuccess={invalidateList}
        />
      )}
      {selectedOptionModal === ModalType.InviteToProgram && (
        <AddCareManagerModal
          userToInvite={userToInviteToProgram}
          onClose={() => setSelectedOptionModal(undefined)}
          programsAvailable={programs}
          onSuccess={invalidateList}
        />
      )}
      {selectedOptionModal === ModalType.Reassign && userToInviteToProgram && (
        <ReassignUsersModal
          onClose={() => setSelectedOptionModal(undefined)}
          selectedCareManager={userToInviteToProgram}
        />
      )}
      {selectedOptionModal === ModalType.DeleteCareManager &&
        userToInviteToProgram && (
          <DeleteCareManagerModal
            onClose={() => setSelectedOptionModal(undefined)}
            selectedCareManager={userToInviteToProgram}
          />
        )}
      {selectedOptionModal === ModalType.RemoveCareManagerFromProgram &&
        userToInviteToProgram && (
          <RemoveFromProgramModal
            onClose={() => setSelectedOptionModal(undefined)}
            selectedCareManager={userToInviteToProgram}
            onSuccess={invalidateList}
          />
        )}
    </Layout>
  );
};

export default AccessManagementPage;
