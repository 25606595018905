import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import styles from "./IframePage.module.scss";

import HospitalShield from "~/assets/hospital-shield.svg";
import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import useUrlQuery from "~/hooks/useUrlQuery";

const iframeSources = {
  anthemDashboard: "https://d3k80772u5v0yr.cloudfront.net"
};

const IframePage = () => {
  const [source, setSource] = useState("");

  const queryParams = useUrlQuery();

  useEffect(() => {
    const page = queryParams.get("src");
    if (page) setSource(iframeSources[page] ?? "");
  }, [queryParams]);

  return (
    <SentryErrorBoundary transactionName="IframePage">
      <Layout title={""} titleImg={HospitalShield}>
        <Helmet title={"Sidekick "} defer={false} />

        <iframe
          className={styles.frame}
          src={source}
          width="560"
          height="315"
        ></iframe>
      </Layout>
    </SentryErrorBoundary>
  );
};

export default IframePage;
