import { DateTime } from "luxon";

import humanizeTimeDiff from "./humanizeTimeDiff";

import config from "~/config";
import { TimeInMs } from "~/constants/measurements";
import { t } from "~/i18n";

export const isSameDate = (date: DateTime, date2: DateTime) => {
  return (
    date.day === date2.day &&
    date.month === date2.month &&
    date.year === date2.year
  );
};

export const timeSince = (date: Date) => {
  return Date.now() - date.getTime();
};

export const humanizedTimeSince = (
  date?: string,
  isSmallerThanDesktop?: boolean
) => {
  if (!date) return undefined;
  const showAgoText =
    isSmallerThanDesktop !== undefined && !isSmallerThanDesktop;
  return humanizeTimeDiff(timeSince(new Date(date)), showAgoText);
};

export const clockTime = (time: string, twentyFourHourFormat: boolean) => {
  return DateTime.fromISO(time).toFormat(
    twentyFourHourFormat ? "HH:mm" : "hh:mm a"
  );
};

export const dateIsToday = (date: string) => {
  const today = DateTime.local();

  return (
    today.toISODate() === DateTime.fromISO(date, { zone: "utc" }).toISODate()
  );
};

export const dateIsInFuture = (date: string) => {
  const today = DateTime.local();
  return (today.toISODate() ?? "") < (DateTime.fromISO(date).toISODate() ?? "");
};

export const getTimeSinceString = (date: Date) => {
  const now = Date.now();

  if (now - date.getTime() < TimeInMs.Day) {
    const diff = now - date.getTime();

    return `${Math.floor(diff / TimeInMs.Hour)}${t("timeDiff.hours")}`;
  } else if (now - date.getTime() < 2 * TimeInMs.Day) {
    return t("time.yesterday");
  } else {
    return humanizeTimeDiff(date.getTime(), false);
  }
};

export const isBeforeDate = (date: string, compareDate: string): boolean => {
  return DateTime.fromISO(date) < DateTime.fromISO(compareDate);
};

export const getBasicFormattedDate = (date: string) => {
  return DateTime.fromISO(date)
    .setLocale(config.isAnthem ? "en-US" : "en-GB")
    .toLocaleString(DateTime.DATE_SHORT);
};

export const weeksSinceDate = (date: string) => {
  const dateConverted = DateTime.fromISO(date);

  const weeksSinceDate = dateConverted.diffNow("weeks");

  return Math.floor(Math.abs(weeksSinceDate.weeks));
};

export const getNewestDate = (dates: DateTime[]) => {
  let newestDate: DateTime | undefined = undefined;

  dates.forEach((date) => {
    if (!newestDate) newestDate = date;

    if (newestDate < date) newestDate = date;
  });

  return newestDate;
};

export const dateIsValid = (date: string) => {
  return DateTime.fromISO(date).isValid;
};

export const getLaterDate = (
  dateOne: string | undefined,
  dateTwo: string | undefined
) => {
  if (dateOne && dateTwo) {
    return isBeforeDate(dateOne, dateTwo) ? dateTwo : dateOne;
  } else if (!dateOne && dateTwo) {
    return dateTwo;
  } else {
    return dateOne;
  }
};
export const getDateAndTimeString = (date: string) => {
  const dateConverted = DateTime.fromISO(date);

  return dateConverted.toLocaleString(DateTime.DATETIME_SHORT).toUpperCase();
};

export const getPregnancyWeekFromDueDate = (
  dueDate: string | undefined,
  todayDate?: string
) => {
  if (!dueDate) return undefined;

  const dateConverted = DateTime.fromISO(dueDate);

  const today = todayDate ? DateTime.fromISO(todayDate) : DateTime.local();

  const gestationalAgeInDays = 280 - dateConverted.diff(today, "days").days;

  return Math.ceil(gestationalAgeInDays / 7);
};

export const getFormattedDateWithTime = (date: string) =>
  DateTime.fromISO(date).toFormat(
    `LLL dd, yyyy ${config.isAnthem ? "hh:mm a" : "HH:mm"}`
  );
