import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import HealthCard from "./HealthCard";
import { getHealthCardData, HealthCardType } from "./healthCardData";
import styles from "./HealthCards.module.scss";

import { InformationPageContext } from "../informationPageContext";
import BloodPressureModal from "../modals/bloodPressure/BloodPressureModal";
import BloodSugarModal from "../modals/bloodSugar/BloodSugarModal";
import HeartRateModal from "../modals/heartRate/HeartRateModal";
import WeightHistoryPage from "../weightHistory/WeightHistoryPage";

import { ProgramBehaviour } from "~/constants/programBehaviours";
import { programHasBehaviour } from "~/helpers/program/programHelpers";
import useUseMetric from "~/hooks/useUseMetric";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserDetails } from "~/typing/sidekickTypes";

type HealthCardsProps = {
  details?: UserDetails;
};

const HealthCards = ({ details }: HealthCardsProps) => {
  const { t } = useTranslation();
  const useMetric = useUseMetric();
  const { program } = useContext(InformationPageContext);
  const [
    currentModalShowing,
    setCurrentModalShowing
  ] = useState<HealthCardType>();
  const cardData = getHealthCardData(useMetric, details);
  const { trackHealthCardOpened } = useAmplitudeTracking();

  const handleHealthCardClick = (healthCardType?: HealthCardType) => {
    if (!healthCardType) return;
    setCurrentModalShowing(healthCardType);
    trackHealthCardOpened({ healthCardType });
  };

  return (
    <>
      <div className={styles.container}>
        <p> {t("informationPage.healthCards")}:</p>
        <div className={styles.cards}>
          {cardData.map((card) => (
            <HealthCard
              title={card.title}
              value={card.value}
              unit={card.unit}
              date={
                card.date ? DateTime.fromISO(card.date).toLocaleString() : ""
              }
              iconSrc={card.iconSrc}
              status={card.status}
              key={card.title}
              onClickCard={
                card.onClickParameter
                  ? () => handleHealthCardClick(card.onClickParameter)
                  : undefined
              }
            />
          ))}
        </div>
      </div>
      {currentModalShowing === HealthCardType.Weight && (
        <WeightHistoryPage
          onClose={() => setCurrentModalShowing(undefined)}
          doesProgramUseScale={programHasBehaviour(
            program,
            ProgramBehaviour.UsingBodyTraceScale
          )}
        />
      )}
      {currentModalShowing === HealthCardType.BloodPressue && (
        <BloodPressureModal
          onClose={() => setCurrentModalShowing(undefined)}
          latestLogDate={details?.bloodPressure?.date}
        />
      )}
      {currentModalShowing === HealthCardType.BloodSugar && (
        <BloodSugarModal
          onClose={() => setCurrentModalShowing(undefined)}
          useMetric={useMetric}
          latestHba1cLogDate={details?.bloodSugar?.date}
          latestFastingLogDate={details?.fastingBloodSugar?.date}
          latestAfterMealLogDate={details?.mealBloodSugar?.date}
        />
      )}
      {currentModalShowing === HealthCardType.HeartRate && (
        <HeartRateModal
          onClose={() => setCurrentModalShowing(undefined)}
          latestLogDate={details?.bloodPressure?.date}
        />
      )}
    </>
  );
};

export default HealthCards;
