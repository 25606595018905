import compareValues from "~/helpers/compareValues";
import {
  PaginationData,
  SearchData,
  SortableData
} from "~/typing/carePortalTypes";

export const queryFilteredEntities = <T>(
  entities: T[],
  query: string | undefined,
  searchData: SearchData | undefined
): T[] => {
  if (!query || !query.length || !searchData) return entities;

  const lowercaseQuery = query.toLowerCase();

  entities = entities?.filter((entity) =>
    searchData?.data?.some((searchData) => {
      const entityProperty = searchData.key.reduce(
        (prev, curr) => prev?.[curr],
        entity
      );

      return searchData.exactMatch
        ? String(entityProperty).toLowerCase() === lowercaseQuery
        : String(entityProperty)?.toLowerCase().includes(lowercaseQuery);
    })
  );
  return entities;
};

export const sortEntities = (
  entities: any,
  order: "asc" | "desc" = "desc",
  sortingData?: SortableData,
  shouldEntityBePutAtBottom?: (entity: any) => boolean
) => {
  if (!entities) {
    return [];
  }

  if (sortingData || shouldEntityBePutAtBottom) {
    return entities.sort((a, b) => {
      let aVal = a;
      let bVal = b;

      sortingData?.sortBy.forEach((key) => {
        aVal = aVal?.[key];
        bVal = bVal?.[key];
      });

      if (shouldEntityBePutAtBottom) {
        if (shouldEntityBePutAtBottom(a) && !shouldEntityBePutAtBottom(b)) {
          return 1;
        }
        if (shouldEntityBePutAtBottom(b) && !shouldEntityBePutAtBottom(a)) {
          return -1;
        }
      }

      let comparisonResult;

      if (sortingData?.customSortFunction) {
        comparisonResult = sortingData.customSortFunction(a, b);
      } else {
        // Always sort undefined to bottom
        if (aVal === undefined || aVal === "") {
          return 1;
        }
        if (bVal === undefined || bVal === "") {
          return -1;
        }
        comparisonResult = compareValues(aVal, bVal);
      }

      if (sortingData?.reverse) {
        comparisonResult = -comparisonResult;
      }

      return order === "asc" ? -comparisonResult : comparisonResult;
    });
  }

  return entities;
};

export const shouldResetPagination = <T>({
  pagination,
  entities,
  currentPage
}: {
  pagination: PaginationData | undefined;
  entities: T[];
  currentPage: number;
}) => {
  if (
    pagination &&
    entities.length &&
    entities.length < pagination.entitiesPerPage * (currentPage - 1)
  ) {
    return true;
  }
  return false;
};
