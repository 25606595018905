import { useId } from "react";
import { Tooltip } from "react-tooltip";

import { mapCareTeamToCoaches } from "./CareTeam.helpers";
import styles from "./CareTeam.module.scss";

import Avatar from "../avatar/Avatar";
import AvatarGroup from "../avatarGroup/AvatarGroup";

import { useSpecialties } from "~/hooks/useApi/specialties/useSpecialties";
import { CoachWithSpecialty } from "~/typing/carePortalTypes";
import { Coach, CareTeamMember } from "~/typing/sidekickTypes";

type CareTeamProps = {
  careTeam?: CareTeamMember[];
  coaches: Coach[];
  spreadOut?: boolean;
  inExportMode?: boolean;
};

const CareTeamCluster = ({ careTeam }: { careTeam: CoachWithSpecialty[] }) => {
  const id = useId();

  if (careTeam.length === 0) return null;

  return (
    <>
      <div
        className={styles.wrapper}
        data-tooltip-delay-show={250}
        data-tooltip-id={id}
        data-testid="care-team-cluster"
      >
        <AvatarGroup includeTitleOnHover={false} users={careTeam ?? []} />
      </div>
      <Tooltip
        data-testid="tooltip-care-team"
        className={styles.tooltip}
        place="top"
        id={id}
      >
        <div className={styles.usersTooltip}>
          {careTeam.map((user, index) => (
            <div className={styles.user} key={`user-${id}-${index}`}>
              <Avatar user={user} size="sm" />
              <span>{user.fullName}</span>
              <span>{"-"}</span>
              <span className={styles.specialty}>
                {user.specialty?.localizedName}
              </span>
            </div>
          ))}
        </div>
      </Tooltip>
    </>
  );
};

const CareTeamSpreadOut = ({
  careTeam,
  inExportMode
}: {
  inExportMode?: boolean;
  careTeam: CoachWithSpecialty[];
}) => {
  if (!careTeam.length) return null;

  return (
    <div
      data-testid="care-team-spreadout-wrapper"
      className={styles.spreadOutWrapper}
    >
      {careTeam.map((user, index) => (
        <div className={styles.coach} key={`user-${index}`}>
          <Avatar showNameOnHover title={user.fullName} user={user} size="md" />
          <span>
            {inExportMode ? user.displayName : user.specialty?.localizedName}
          </span>
        </div>
      ))}
    </div>
  );
};

const CareTeamExportView = ({
  careTeam
}: {
  careTeam: CoachWithSpecialty[];
}) => {
  if (!careTeam.length) return null;

  return (
    <div
      data-testid="care-team-export-wrapper"
      className={styles.exportWrapper}
    >
      {careTeam.map((user, index) => (
        <div className={styles.coach} key={`user-${index}`}>
          <Avatar title={user.fullName} user={user} size="md" />
          <span>
            {`${user.displayName} - ${user.specialty?.localizedName}`}
          </span>
        </div>
      ))}
    </div>
  );
};

const CareTeam = ({
  careTeam,
  coaches,
  spreadOut,
  inExportMode
}: CareTeamProps) => {
  const { specialties } = useSpecialties({});

  const mappedCareTeam = mapCareTeamToCoaches(
    careTeam ?? [],
    coaches,
    specialties
  );

  if (inExportMode) {
    return <CareTeamExportView careTeam={mappedCareTeam} />;
  }

  return spreadOut ? (
    <CareTeamSpreadOut inExportMode={inExportMode} careTeam={mappedCareTeam} />
  ) : (
    <CareTeamCluster careTeam={mappedCareTeam} />
  );
};

export default CareTeam;
