import colors from "~/styles/colors";

type PinProps = {
  color?: string;
  hollow?: boolean;
};

export default function Pin({
  color = colors.navyBlue50,
  hollow = false
}: PinProps) {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35473 14.2439C5.31112 14.4113 5.3065 14.5865 5.3412 14.756C5.37591 14.9255 5.44902 15.0847 5.55492 15.2215C5.66082 15.3584 5.79668 15.469 5.95207 15.5451C6.10745 15.6212 6.2782 15.6606 6.45121 15.6604L15.2113 15.6604C15.3843 15.6606 15.5551 15.6212 15.7104 15.5451C15.8658 15.469 16.0017 15.3584 16.1076 15.2215C16.2135 15.0847 16.2866 14.9255 16.3213 14.756C16.356 14.5865 16.3514 14.4113 16.3078 14.2439C15.934 12.8325 15.0305 11.6196 13.7852 10.8574L15.0513 5.15956C15.0881 4.9942 15.0873 4.82269 15.0489 4.65768C15.0106 4.49268 14.9356 4.3384 14.8297 4.20623C14.7237 4.07406 14.5894 3.96737 14.4367 3.89404C14.284 3.82071 14.1168 3.78262 13.9474 3.78256L7.71514 3.78256C7.54575 3.78255 7.37852 3.82058 7.22579 3.89385C7.07306 3.96711 6.93874 4.07374 6.83273 4.20586C6.72671 4.33797 6.65172 4.49221 6.61329 4.65718C6.57486 4.82216 6.57396 4.99366 6.61067 5.15902L7.87727 10.8574C6.63198 11.6196 5.72848 12.8325 5.35473 14.2439Z"
        fill={hollow ? "" : color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8319 15.662V23.2301"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
