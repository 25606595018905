import { PieChart, Pie, Cell } from "recharts";

import styles from "./ProgramUserActivityChart.module.scss";

import ProgramUserActivityLegend from "../programUserActivityLegend/ProgramUserActivityLegend";

import { t } from "~/i18n";
import colors from "~/styles/colors";

type ProgramUserActivityChartProps = {
  active: number;
  total: number;
};

const ProgramUserActivityChart = ({
  active,
  total
}: ProgramUserActivityChartProps) => {
  const data = [
    {
      name: "inactive",
      value: total - active,
      color: colors.navyBlue10 // navy-blue-10
    },
    {
      name: "active",
      value: active,
      color: colors.neonGreen100 // neon-green-100
    }
  ];

  return (
    <div className={styles.ProgramUserActivityChart}>
      <PieChart width={120} height={120} className={styles.UserActivityChart}>
        <Pie
          data={data}
          innerRadius={50}
          outerRadius={60}
          paddingAngle={0}
          blendStroke
          dataKey="value"
        >
          {data.map((entry) => (
            <Cell fill={entry.color} key={entry.name} />
          ))}
        </Pie>
      </PieChart>
      <div className={styles.TotalUsers}>
        <>
          <span className={styles.TotalUsers_Number}>{total}</span>
          {t("activityChart.users")}
        </>
      </div>
      <ul className={styles.LegendList}>
        {data
          .slice()
          .reverse()
          .map((entry) => {
            return (
              <ProgramUserActivityLegend
                entry={entry}
                total={total}
                key={entry.name}
              />
            );
          })}
      </ul>
    </div>
  );
};

export default ProgramUserActivityChart;
