import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { PinnedNoteInfo } from "~/typing/carePortalTypes";

export default function usePinnedNotes(programCatalogItemId, userId) {
  const path = `/coach/programs/${programCatalogItemId}/users/${userId}/journalPins`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: PinnedNoteInfo[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.pinnedNotes(userId)
  });

  return {
    pinnedNotes: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
