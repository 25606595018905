import { QueryKeyFactory } from "../queryKeysFactory";
import useApi from "../useApi";

import config from "~/config";
import { Specialty, UserSpecialty } from "~/typing/sidekickTypes";

export const useSpecialties = ({ disabled }: { disabled?: boolean }) => {
  const { data, isLoading, isError } = useApi<{ items: Specialty[] }>({
    queryKey: QueryKeyFactory.specialties.all,
    path: "/coach/specialties",
    disabled: !config.isAnthem || disabled,
    refetchOnWindowFocus: false,
    shouldRetry: false
  });

  return {
    specialties: data?.items ?? [],
    isLoading,
    isError
  };
};

export const useAuthUserSpecialty = ({
  authUserId,
  enabled = true
}: {
  authUserId: string;
  enabled?: boolean;
}) => {
  const { data, isLoading, isError } = useApi<UserSpecialty>({
    queryKey: QueryKeyFactory.specialties.authenticatedUser(authUserId),
    path: "/coach/me/specialty",
    disabled: !config.isAnthem || !enabled,
    shouldRetry: false
  });

  return {
    data,
    isLoading,
    hasError: isError
  };
};
