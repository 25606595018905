import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./InviteCoach.module.scss";

import api from "~/api/api";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import Modal from "~/components/modal/Modal";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";

type InviteCoachProps = {
  onClose: () => void;
  role: "role_health_care_provider" | "role_care_manager";
  isProviderInvite?: boolean;
  programId: string;
  locale: string;
  userId?: string;
  invalidateInvites?: () => void;
};

const InviteCoach = ({
  onClose,
  role,
  isProviderInvite,
  programId,
  locale,
  userId,
  invalidateInvites
}: InviteCoachProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { trackInviteCoach } = useAmplitudeTracking();

  const inviteCoach = async (email) => {
    const path = `/coach/programcatalogitems/${programId}/locales/${locale}/invites`;
    const payload = {
      email,
      role,
      userId
    };

    await api.post(path, payload).then((res) => {
      trackInviteCoach();

      if (invalidateInvites) invalidateInvites();
      return res;
    });
  };

  const handleInviteCoach = async (e) => {
    e.preventDefault();
    setLoading(true);
    await inviteCoach(email)
      .then(() => {
        displaySuccessToast({ message: t("team.addCoachSuccess", { email }) });
        onClose();
      })
      .catch(() => {
        displayErrorToast({ message: t("team.addCoachError") });
        setLoading(false);
      });
  };

  return (
    <Modal
      title={
        isProviderInvite ? t("team.inviteProvider") : t("team.inviteCoach")
      }
      className={styles.modal}
      onClose={onClose}
    >
      {loading && <LoadingSpinner />}
      <form
        onSubmit={handleInviteCoach}
        className={`${loading ? styles.hidden : ""} form--block`}
      >
        <label className="label label--block">
          {t("general.email", "Email")}
        </label>
        <div className={styles.inputContainer}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input input--block"
            autoFocus
          />
        </div>
        <button
          type="submit"
          className="btn-primary btn-sm"
          disabled={!email} // let browser validate email if non empty string
        >
          {t("general.invite")}
        </button>
      </form>
    </Modal>
  );
};

export default InviteCoach;
