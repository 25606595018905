import styles from "./CustomList.module.scss";

import SearchInput from "../searchInput/SearchInput";

import { ActionData, SearchData } from "~/typing/carePortalTypes";

type CustomListSearchAndActionProps<T> = {
  loading: boolean;
  query?: string;
  setQuery: (value: string) => void;
  searchData?: SearchData;
  entities: T[];
  actionData?: ActionData[];
};

const CustomListSearchAndAction = <T,>({
  loading,
  query,
  setQuery,
  searchData,
  entities,
  actionData
}: CustomListSearchAndActionProps<T>) => {
  if (loading) return null;

  return (
    <div className={styles.searchAndAction}>
      {searchData && (
        <SearchInput
          dataTestId="list-search-input"
          autoFocus
          value={query}
          onChange={(value) => setQuery(value)}
          placeholder={searchData?.placeholder}
          disabled={!entities}
          className={`${styles.search} ${searchData?.className || ""}`}
        />
      )}
      <div className={styles.actions}>
        {actionData
          ?.filter((value) =>
            value.conditional !== undefined ? value.conditional : true
          )
          ?.map(
            ({ key, text, className, onClick, iconSrc, dataTestId }, index) => (
              <button
                key={`action-data-${key || text}-${index}`}
                type="button"
                className={`btn btn-sm ${styles.actionData} ${className} `}
                onClick={onClick}
                data-testid={dataTestId}
              >
                {iconSrc && <img src={iconSrc} />}
                {text}
              </button>
            )
          )}
      </div>
    </div>
  );
};

export default CustomListSearchAndAction;
