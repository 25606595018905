import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { UserSettings } from "~/typing/sidekickTypes";

export default function useUserSettings(userId: string) {
  const path = `/admin/users/${userId}/settings`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: UserSettings;
  }>({
    path,
    queryKey: QueryKeyFactory.users.settings(userId)
  });
  return {
    settings: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
