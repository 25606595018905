import { createContext } from "react";

import { UserBaseWeight } from "./weightHistory/useBaseWeight";

import {
  ExternalUser,
  ExternalUserType,
  Program,
  UserDetails,
  UserSettings
} from "~/typing/sidekickTypes";

type InformationPageContextProps = {
  baseWeight?: UserBaseWeight;
  invalidateBaseWeight?: () => void;
  baseWeightEnabled?: boolean;
  program?: Program;
  userDetail?: UserDetails;
  settings?: UserSettings;
  externalUser?: ExternalUser;
  externalUserType?: ExternalUserType;
  hasQuit?: boolean;
  isFinished?: boolean;
};

export const InformationPageContext = createContext<InformationPageContextProps>(
  {} as InformationPageContextProps
);
