import { t } from "i18next";
import { DateTime } from "luxon";
import { useEffect } from "react";

import styles from "./MonthCalendar.module.scss";

import LogCard from "../LogCard";

import type { MedicationLogSanitised } from "~/typing/carePortalTypes";

/**
 * This is the number of items in the grid, 7 days a week, 6 weeks
 * This used to be 35 but was changed to 42 to allow for the 6th week
 * The 6th week is needed when the month starts on a Sunday, because if it is not included
 * the grid will not be filled correctly to the end of the month
 */
const NUMBER_OF_ITEMS_IN_GRID = 42;

interface MonthCalendarProps {
  month: DateTime;
  logs: MedicationLogSanitised[];
}

type SelectedMonth = {
  days: Day[];
};

type Day = {
  date: DateTime;
  empty: boolean;
  data?: MedicationLogSanitised[];
};

const MonthCalendar = ({ month, logs }: MonthCalendarProps) => {
  const daysOfWeek = [
    t("medication.schedule.daysShort.mon").toUpperCase(),
    t("medication.schedule.daysShort.tue").toUpperCase(),
    t("medication.schedule.daysShort.wed").toUpperCase(),
    t("medication.schedule.daysShort.thu").toUpperCase(),
    t("medication.schedule.daysShort.fri").toUpperCase(),
    t("medication.schedule.daysShort.sat").toUpperCase(),
    t("medication.schedule.daysShort.sun").toUpperCase()
  ];

  const filterData = () => {
    //Find the start of the current month
    const startOfMonth = month.startOf("month").startOf("week");

    const monthLogs: SelectedMonth = { days: [] };
    for (let i = 0; i < NUMBER_OF_ITEMS_IN_GRID; i++) {
      monthLogs.days.push({
        date: startOfMonth.plus({ days: i }),
        empty: true
      });
    }

    const currentMonthLogs = logs.filter((log) => {
      return (
        DateTime.fromISO(log.scheduledDate ?? log.completedDate)
          .startOf("day")
          .toMillis() >= monthLogs?.days[0]?.date?.startOf("day").toMillis() &&
        DateTime.fromISO(log.scheduledDate ?? log.completedDate)
          .startOf("day")
          .toMillis() <=
          monthLogs?.days[NUMBER_OF_ITEMS_IN_GRID - 1]?.date
            ?.startOf("day")
            .toMillis()
      );
    });

    // Adds the logs to the correct day
    monthLogs?.days.forEach((day) => {
      day.data = currentMonthLogs.filter((log) => {
        return (
          DateTime.fromISO(log.scheduledDate ?? log.completedDate)
            .startOf("day")
            .toMillis() === day.date.startOf("day").toMillis()
        );
      });
    });

    return monthLogs;
  };

  // Helper function to get the grid item class based on the conditions
  const getGridItemClass = (isDayLabel, indexCurrentDay, index, styles) => {
    if (isDayLabel) {
      return indexCurrentDay === index
        ? `${styles.monthCalendarItemTopRow} ${styles.active}`
        : styles.monthCalendarItemTopRow;
    } else {
      return indexCurrentDay === index
        ? `${styles.monthCalendarItemLowerRows} ${styles.active}`
        : styles.monthCalendarItemLowerRows;
    }
  };

  // Helper function to get the day class based on the conditions
  const getDayClass = (isDayLabel, indexCurrentDay, index, styles) => {
    if (isDayLabel) {
      return indexCurrentDay === index
        ? `${styles.monthCalendarItemTopRowDay} ${styles.active}`
        : styles.monthCalendarItemTopRowDay;
    } else {
      return indexCurrentDay === index
        ? `${styles.monthCalendarItemLowerRowsDay} ${styles.active}`
        : styles.monthCalendarItemLowerRowsDay;
    }
  };

  // Function to fill the grid with month days, including the day of the week
  // for the first 7 items, and the month abbreviation for the first day of each month
  const fillGridMonth = () => {
    // Filter data for month logs
    const monthLogs = filterData();

    // Get the nearest Monday to the start of the month
    const firstMonday = month.startOf("month").startOf("week");

    // Calculate the correct index of the current day
    const today = DateTime.now().startOf("day");
    const indexCurrentDay = Math.round(today.diff(firstMonday, "days")?.days);

    // Create grid items
    const gridItems = Array.from(
      { length: NUMBER_OF_ITEMS_IN_GRID },
      (_, index) => {
        // Calculate the date for each cell
        const currentDate = firstMonday.plus({ days: index });

        // Determine if the current cell is a day label (first 7 items)
        const isDayLabel = index < 7;

        // Determine if the current cell is the first day of the month
        const isFirstDayOfMonth = currentDate.day === 1;

        // Format the month and day string
        const monthAndDay = `${currentDate.monthShort?.toUpperCase()} ${
          currentDate.day
        }`;

        // Get the log item for the current day
        const item = monthLogs?.days[index];

        // Create a div with log cards for the current day
        const itemDiv = (
          <div key={`day-item-${item.date}-${index}`}>
            {item.data?.map((data, index) => {
              return (
                <LogCard
                  log={data}
                  small={true}
                  key={`day-injection-card-${item.date}-${index}-${item.data?.length}`}
                />
              );
            })}
          </div>
        );

        // Create a grid item with the appropriate content for each cell
        const gridItemContent = (
          <div
            className={getGridItemClass(
              isDayLabel,
              indexCurrentDay,
              index,
              styles
            )}
          >
            <div
              className={getDayClass(
                isDayLabel,
                indexCurrentDay,
                index,
                styles
              )}
            >
              {isDayLabel ? daysOfWeek[index] : ""}
            </div>
            <div
              className={getDayClass(
                isDayLabel,
                indexCurrentDay,
                index,
                styles
              )}
            >
              {isFirstDayOfMonth ? monthAndDay : currentDate.day}
            </div>
            {itemDiv}
          </div>
        );

        return (
          <div key={index} className={styles.monthCalendarItem}>
            {gridItemContent}
          </div>
        );
      }
    );

    return gridItems;
  };

  useEffect(() => {
    filterData();
  }, []);

  return (
    <div>
      <div className={styles.monthCalendar}>{fillGridMonth()}</div>
    </div>
  );
};

export default MonthCalendar;
