import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import styles from "./MonthlyPhysicalChartTemplate.module.scss";

import ChartDetail from "~/components/charts/ChartDetail";
import CustomBarChart from "~/components/charts/CustomBarChart";
import DateChanger from "~/components/dateChanger/DateChanger";
import round from "~/helpers/number/round";
import { PhysicalData } from "~/typing/sidekickTypes";

type MonthlyPhysicalChartTemplateProps = {
  data: PhysicalData[];
  dataKey: string;
  today: DateTime;
  units: string;
  endDate: DateTime;
  changeEndDate: (multiplier: 1 | -1) => void;
};

export default function MonthlyPhysicalChartTemplate({
  data,
  dataKey,
  today,
  units,
  endDate,
  changeEndDate
}: MonthlyPhysicalChartTemplateProps) {
  const { t } = useTranslation();
  const dataKeys = [dataKey];
  const total = (data, dataKey) => {
    return data.reduce((r, a) => {
      return r + a[dataKey];
    }, 0);
  };

  const average = (data: PhysicalData[], dataKey: string) => {
    const nonZeroData = data.filter((point) => point[dataKey] !== 0);
    return round(total(nonZeroData, dataKey) / nonZeroData.length, 1);
  };

  const thisMonthData = data.filter(
    (point) =>
      point.date.month === endDate.month && point.date.year === endDate.year
  );
  const lastMonthData = data.filter((point) => {
    const lastMonth = endDate.month === 12 ? 1 : endDate.month - 1;
    return (
      point.date.month === lastMonth &&
      (point.date.year === endDate.year - 1 || point.date.year === endDate.year)
    );
  });
  const thisMonthTotal = total(thisMonthData, dataKey);
  const lastMonthTotal = total(lastMonthData, dataKey);
  const monthTotalDiff = thisMonthTotal - lastMonthTotal;

  const thisMonthAverage = average(thisMonthData, dataKey);
  const lastMonthAverage = average(lastMonthData, dataKey);
  const monthAverageDiff = (thisMonthAverage || 0) - (lastMonthAverage || 0);

  const todayData = data.find((e) => e.date.toISO() === today.toISO());
  const yesterdayData = data[1];
  const todayDiff =
    todayData && yesterdayData
      ? todayData[dataKey] - yesterdayData[dataKey]
      : 0;

  const monthTimeDiff = t("time.lastMonth");

  return (
    <div className={styles.MonthlyPhysicalChartTemplate}>
      <div className={styles.chartDetails}>
        <ChartDetail
          title={t("time.today")}
          values={todayData}
          dataKeys={dataKeys}
          units={units}
          valueDiff={todayDiff}
          positive={todayDiff > 0}
          timeDiff={t("time.yesterday", "yesterday")}
        />
        <ChartDetail
          title={t("chartDetail.totalMonth")}
          values={{ total: thisMonthTotal }}
          dataKeys={["total"]}
          units={units}
          valueDiff={monthTotalDiff}
          positive={monthTotalDiff > 0}
          timeDiff={monthTimeDiff}
        />
        <ChartDetail
          title={t("average.dailyMonth")}
          values={{ average: thisMonthAverage }}
          dataKeys={["average"]}
          units={units}
          valueDiff={monthAverageDiff}
          positive={monthAverageDiff > 0}
          timeDiff={monthTimeDiff}
        />
      </div>
      <div className={styles.dateContainer}>
        <DateChanger
          endDate={endDate}
          changeEndDate={changeEndDate}
          useWeek={false}
        />
      </div>
      <CustomBarChart
        data={data}
        dataKeys={dataKeys}
        allDataKeys={dataKeys}
        today={today}
        useWeek={false}
        endDate={endDate}
        units={units}
      />
    </div>
  );
}
