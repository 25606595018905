import { UserProgramStatus } from "~/constants/programStatus";
import { t } from "~/i18n";

export default function getProgramStatusText(status: UserProgramStatus) {
  switch (status) {
    case UserProgramStatus.Active:
      return t("programs.status.active", "Active");
    case UserProgramStatus.Pending:
      return t("programs.status.pending", "Pending");
    case UserProgramStatus.Finished:
    case UserProgramStatus.AlmostFinished:
      return t("programs.status.finished", "Finished");
    case UserProgramStatus.Paused:
      return t("programs.status.paused", "Paused");
    case UserProgramStatus.Removed:
      return t("programs.status.removed", "Removed");
  }
}
