import { createContext } from "react";

type ExportContextProps = {
  startDate?: Date;
  endDate?: Date;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

export const ExportContext = createContext<ExportContextProps>({
  startDate: undefined,
  endDate: undefined,
  isLoading: false,
  setIsLoading: () => undefined
});
