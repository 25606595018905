import { t } from "i18next";
import { Tooltip } from "react-tooltip";

import styles from "./RestartedStatus.module.scss";

import Pill from "~/components/pill/Pill";
import { getFormattedDateWithTime } from "~/helpers/date/dateHelpers";
import colors from "~/styles/colors";

type RestartedStatusProps = {
  className?: string;
  userId: string;
  restartedDate?: string;
};

const RestartedStatus = ({
  className,
  userId,
  restartedDate
}: RestartedStatusProps) => {
  const dataTooltipId = `restartedBadge ${userId}`;

  return (
    <>
      <div
        data-tooltip-delay-show={400}
        data-tooltip-id={dataTooltipId}
        className={className}
      >
        <Pill
          backgroundColor={colors.mustardYellow100}
          title={t("restart.restarted")}
        />
      </div>
      <Tooltip className={styles.tooltip} place="top" id={dataTooltipId}>
        <span className={styles.tooltipText}>
          {restartedDate ? (
            <>
              {t("restart.restartedOn")}{" "}
              {getFormattedDateWithTime(restartedDate)}
            </>
          ) : (
            t("restart.restarted")
          )}
        </span>
      </Tooltip>
    </>
  );
};

export default RestartedStatus;
