import { createContext, Dispatch, SetStateAction } from "react";

import { NextRecommendedUserData } from "~/hooks/useApi/useNextRecommandedUser";
import { ActOnUserDtoInput } from "~/typing/graphql/types";
import { Program, UserDetails } from "~/typing/sidekickTypes";

type TaskPageContextProps = {
  nextUserInfo?: NextRecommendedUserData;
  userDetailsLoading?: boolean;
  userDetails?: UserDetails;
  getNextUser: () => void;
  registerActionForRecommendedUser: (action: ActOnUserDtoInput) => void;
  program?: Program;
  programLoading: boolean;
  copiedMessage?: string;
  copiedMessageId?: string;
  setCopiedMessage?: Dispatch<SetStateAction<string>>;
  setCopiedMessageId?: Dispatch<SetStateAction<string>>;
  newNextStep?: boolean;
};

export const TaskPageContext = createContext<TaskPageContextProps>(
  {} as TaskPageContextProps
);
