import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./HeartRateModal.module.scss";

import { getAnalyzedHeartRateData } from "../../../../../helpers/vitals/heartRatehelpers";

import { getBloodPressureData } from "~/api/requests/scoreRequests";
import ButtonLink from "~/components/buttonLink/ButtonLink";
import ChartRating from "~/components/charts/ChartRating";
import CustomLineChart from "~/components/charts/CustomLineChart";
import DateRangeNavigator from "~/components/dateRangeNavigator/DateRangeNavigator";
import Modal from "~/components/modal/Modal";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import { getNewestDate } from "~/helpers/date/dateHelpers";
import { getLineData } from "~/helpers/vitals/vitalsHelpers";
import colors from "~/styles/colors";
import { HeartRateLineData, UserURLParams } from "~/typing/carePortalTypes";
import { BloodPressureData } from "~/typing/sidekickTypes";

type HeartRateModalProps = {
  onClose: () => void;
  latestLogDate?: string;
};

const DEFAULT_DAYS_TO_DISPLAY = 7;
//Details view should display the last 30 logs for the past 2 years
const DETAILS_DAYS_TO_DISPLAY = 730;
const DETAILS_LOGS_TO_DISPLAY = 30;

const HeartRateModal = ({ onClose, latestLogDate }: HeartRateModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [heartRateData, setHeartRateData] = useState<BloodPressureData[]>([]);
  const latestDate = latestLogDate
    ? DateTime.fromISO(latestLogDate)
    : DateTime.now();
  const [endDate, setEndDate] = useState(latestDate);
  const [daysToDisplay, setDaysToDisplay] = useState(DEFAULT_DAYS_TO_DISPLAY);
  const { t } = useTranslation();

  const {
    user_id = "",
    program_id = "",
    locale = ""
  } = useParams<UserURLParams>();

  const isDetailsView = () => {
    return daysToDisplay === DETAILS_DAYS_TO_DISPLAY;
  };

  useEffect(() => {
    const getAndSetData = async () => {
      setIsLoading(true);
      //Heart rate data is fetched via blood pressure endpoint
      const heartRateResponse = await getBloodPressureData({
        userId: user_id,
        locale,
        programId: program_id,
        startDate: isDetailsView()
          ? DateTime.now().minus({ days: daysToDisplay - 1 })
          : endDate.minus({ days: daysToDisplay - 1 }),
        endDate: isDetailsView() ? DateTime.now() : endDate,
        pageSize: isDetailsView() ? DETAILS_LOGS_TO_DISPLAY : undefined
      });
      setHeartRateData(heartRateResponse);
      setIsLoading(false);
    };
    getAndSetData();
  }, [endDate, daysToDisplay]);

  const lineData = getLineData(heartRateData);

  const analyzedData = getAnalyzedHeartRateData(lineData);

  const highestValue = lineData.reduce(
    (prev, curr) => (curr.heartrate > prev ? curr.heartrate : prev),
    0
  );

  return (
    <Modal
      className={styles.modal}
      title={t("user.health.heartRate.heartRate")}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.infoWrapper}>
            {analyzedData.map((info, index) => (
              <ChartRating
                key={`${info.title}-${index}`}
                title={info.title}
                value={info.heartrate}
                unit={t("user.health.heartRate.bpm")}
                isLoading={isLoading}
              />
            ))}
          </div>
          <div className={styles.navWrapper}>
            <ToggleSwitch
              switchData={[
                {
                  label: "7d",
                  value: 7
                },
                {
                  label: "30d",
                  value: 30
                },
                {
                  label: t("time.detail"),
                  value: DETAILS_DAYS_TO_DISPLAY
                }
              ]}
              setValue={(value) => setDaysToDisplay(value)}
              currentValue={daysToDisplay}
              className={styles.switch}
              useLinks={true}
            />
            {isDetailsView() ? (
              <div className={styles.last30Link}>
                <ButtonLink
                  active={isDetailsView()}
                  title={t("time.last30Logs")}
                  onClick={() => undefined}
                />
              </div>
            ) : (
              <DateRangeNavigator
                className={styles.navigator}
                increaseDate={() =>
                  setEndDate((prev) => prev.plus({ days: daysToDisplay }))
                }
                decreaseDate={() =>
                  setEndDate((prev) => prev.minus({ days: daysToDisplay }))
                }
                startDate={
                  endDate.minus({ days: daysToDisplay - 1 }).toISO() ?? ""
                }
                endDate={endDate.toISO() ?? ""}
                onTodayClick={() =>
                  setEndDate(
                    getNewestDate(
                      heartRateData.map((item) => DateTime.fromISO(item.date))
                    ) ?? DateTime.now()
                  )
                }
                todayLabel={t("time.latest")}
              />
            )}
          </div>
        </div>

        <CustomLineChart<HeartRateLineData>
          lineData={lineData}
          height={400}
          YAxisDomain={[0, highestValue + 20]}
          units={t("user.health.heartRate.bpm")}
          lines={[
            {
              name: `${t("user.health.heartRate.heartRate")}`,
              dataKey: "heartrate",
              lineColor: colors.darkBlue140
            }
          ]}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default HeartRateModal;
