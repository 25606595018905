import colors from "~/styles/colors";

interface ChevronProps {
  color?: string;
  direction?: "right" | "left" | "down" | "up";
}

const Chevron = ({
  color = colors.black,
  direction = "right"
}: ChevronProps) => {
  switch (direction) {
    case "left":
      return (
        <svg
          width="8"
          height="17"
          viewBox="0 0 8 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.30273 1L0.939564 7.81768C0.580971 8.20189 0.580971 8.79811 0.939564 9.18232L7.30273 16"
            stroke={color}
            strokeLinecap="round"
          />
        </svg>
      );
    case "down":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
        </svg>
      );
    case "up":
      return (
        <svg
          viewBox="0 0 140 140"
          height="140"
          width="140"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(2,0,0,2,0,0)">
            <path d="M12.1,4.5c0.7,0,1.3,0.3,1.7,0.8l9.7,11c0.7,0.7,0.6,1.9-0.1,2.6s-1.9,0.6-2.6-0.1c0,0,0,0-0.1-0.1l-8.5-9.6 C12.1,9,12,9,11.8,9.1l0,0l-8.5,9.6c-0.7,0.8-1.8,0.9-2.6,0.2s-0.9-1.8-0.2-2.6c0,0,0,0,0.1-0.1l9.7-11C10.8,4.8,11.4,4.5,12.1,4.5z"></path>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width="8"
          height="17"
          viewBox="0 0 8 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.00681 1.00782C0.811543 1.20307 0.811529 1.51965 1.00678 1.71493L8.15258 8.86139L1.00678 16.0079C0.811528 16.2031 0.811543 16.5197 1.00681 16.715C1.20209 16.9102 1.51867 16.9102 1.71392 16.7149L8.85965 9.56853C8.85966 9.56852 8.85965 9.56853 8.85965 9.56853C8.95259 9.47572 9.02634 9.36548 9.07665 9.24415C9.12696 9.12281 9.15286 8.99274 9.15286 8.86139C9.15286 8.73004 9.12696 8.59997 9.07665 8.47863C9.02634 8.3573 8.95261 8.24708 8.85967 8.15427C8.85967 8.15426 8.85968 8.15427 8.85967 8.15427L1.71392 1.00785C1.51867 0.812581 1.20209 0.812566 1.00681 1.00782Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default Chevron;
