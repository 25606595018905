import { useParams } from "react-router-dom";

import styles from "./PRONav.module.scss";

import TopNav from "~/components/topNav/TopNav";
import { UserURLParams } from "~/typing/carePortalTypes";
import { Survey, SurveyResult } from "~/typing/sidekickTypes";

type PRONavProps = {
  surveys: Survey[];
  latestSurveyResults: SurveyResult[];
};

export default function PRONav({ surveys, latestSurveyResults }: PRONavProps) {
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const notificationForSurvey = (surveyId) => {
    const surveyResult = latestSurveyResults?.find(
      (result) => result.surveyId === surveyId
    );
    return surveyResult && !surveyResult.coachRatingDate ? 1 : 0;
  };
  const navData = surveys?.map((survey) => {
    // TODO TVT: Remove when surveys support Care Portal Content Items
    if (
      survey.title != null &&
      survey.title.includes("Covid-19 Symptom Survey")
    ) {
      return {
        title: survey.surveyName,
        url: survey.id,
        notificationCount: notificationForSurvey(survey.id),
        preventRouting:
          survey.id ===
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      };
    }
    return {
      title: survey.title,
      url: survey.id,
      notificationCount: notificationForSurvey(survey.id),
      preventRouting:
        survey.id ===
        location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    };
  });
  const baseUrl = `/program/${program_id}/locale/${locale}/users/${user_id}/pro`;
  return (
    <TopNav
      baseUrl={baseUrl}
      navData={navData}
      size="sm"
      className={styles.PRONav}
    />
  );
}
