import styles from "./Cell.module.scss";

type CellProps = {
  text: string;
  color: string;
};

const Cell = ({ text, color }: CellProps) => {
  return (
    <div className={styles.wrapper} style={{ backgroundColor: color }}>
      {text}
    </div>
  );
};

export default Cell;
