import { DateTime } from "luxon";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./FitnessTestPage.module.scss";
import fitnessTestReducer, { FitnessActions } from "./fitnessTestReducer";

import api from "~/api/api";
import ChartDetail from "~/components/charts/ChartDetail";
import CustomBarChart from "~/components/charts/CustomBarChart";
import round from "~/helpers/number/round";
import useMissions from "~/hooks/useApi/useMissions";
import useUseMetric from "~/hooks/useUseMetric";

type FitnessTestPageProps = {
  displayDistance: number;
};

export default function FitnessTestPage({
  displayDistance
}: FitnessTestPageProps) {
  const today = DateTime.local();
  const [fitnessTestData, dispatch] = useReducer(fitnessTestReducer, []);
  const { program_id = "", locale = "", user_id = "" } = useParams();
  const useMetric = useUseMetric();
  const { t } = useTranslation();

  const { missions } = useMissions();
  const fitnessTestMission = missions?.find(
    (mission) => mission.imageName === "mission_fitness_test"
  );

  const getFitnessTestData = async () => {
    if (fitnessTestMission) {
      const res = await api.get(
        `/coach/programs/${program_id}/locales/${locale}/users/${user_id}/scores?missionId=${fitnessTestMission.id}`
      );
      dispatch({
        type: FitnessActions.Fetch,
        payload: res?.data?.items || [],
        useMetric: useMetric
      });
    } else {
      dispatch({
        type: FitnessActions.Fetch,
        payload: [],
        useMetric: useMetric
      });
    }
  };

  useEffect(() => {
    getFitnessTestData();
  }, [fitnessTestMission]);

  const filteredData = fitnessTestData.filter(
    (test) => test.mins === displayDistance
  );

  const units = useMetric
    ? t("units.meters", "meters")
    : t("units.miles", "miles");
  const latestTimeDiff = t("chartDetail.lastLog", "last log");
  const latestDiff = round(
    filteredData[0]?.distance - filteredData[1]?.distance,
    2
  );

  return (
    <>
      <div className={styles.chartDetails}>
        <ChartDetail
          title={t("time.latest", "Latest")}
          values={filteredData[0]}
          dataKeys={["distance"]}
          units={units}
          valueDiff={latestDiff}
          positive={latestDiff ? latestDiff > 0 : false}
          timeDiff={latestTimeDiff}
        />
      </div>
      <CustomBarChart
        data={
          filteredData.length > 0
            ? filteredData
            : [{ distance: 0, date: today, mins: 0 }]
        }
        today={today}
        dataKeys={["distance"]}
        units={units}
        allDataKeys={["distance"]}
        useTimeFilter={false}
        useWeek={false}
      />
    </>
  );
}
