import { t } from "i18next";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

import styles from "./CareManagerTaskListItem.module.scss";

import Avatar from "~/components/avatar/Avatar";
import humanizeTimeDiff from "~/helpers/date/humanizeTimeDiff";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  CareManagerTaskDto,
  CarePriorityType,
  Maybe
} from "~/typing/graphql/types";

type Props = {
  task: CareManagerTaskDto;
};

export const CareManagerTaskListItem = ({ task }: Props) => {
  const { trackTaskOpened } = useAmplitudeTracking();

  return (
    <Link
      to={`/tasks/${task.userId}`}
      onClick={() =>
        trackTaskOpened({
          carePriorities: Array.from(
            task?.carePriorities?.filter(
              (reason): reason is CarePriorityType => reason !== undefined
            )
          ),
          userId: task.userId
        })
      }
      tabIndex={0}
    >
      <div
        key={`key-cmtdi-${task.userFullName}-${task.timeOfRanking} `}
        className={styles.row}
      >
        <div className={styles.content}>
          <div>
            <Avatar
              user={{
                imageHref: task.profilePictureUrl,
                fullName: task.userFullName
              }}
              size="mdplus"
            />
          </div>
          <div>
            <span>
              {task.userFullName} {t("nextStep.careManagerTasks.needs")}{" "}
            </span>
            {task.carePriorities?.map((reason: Maybe<CarePriorityType>, i) => {
              return i > 0 ? (
                <span key={`key-ror-${task.userFullName}-${reason}-${i}`}>
                  <span>, </span>
                  <span className={styles.bold}>
                    {" "}
                    {convertEnumToTitleCase(reason?.toString())}
                  </span>
                </span>
              ) : reason !== undefined ? (
                <span
                  key={`key-ror-${task.userFullName}-${reason}-${i}`}
                  className={styles.bold}
                >
                  {convertEnumToTitleCase(reason?.toString())}
                </span>
              ) : (
                ""
              );
            })}
            <div className={styles.time}>
              <span>
                {t("nextStep.careManagerTasks.since")}{" "}
                {DateTime.fromISO(task.timeOfRanking as string).toFormat(
                  "dd MMM"
                )}
              </span>{" "}
              /{" "}
              <span>
                {DateTime.fromISO(task.timeOfRanking as string).toLocaleString(
                  DateTime.TIME_SIMPLE
                )}
              </span>{" "}
              /{" "}
              <span>
                {humanizeTimeDiff(
                  Math.max(
                    Date.now() -
                      DateTime.fromISO(task.timeOfRanking as string).toMillis()
                  ),
                  true
                )}
              </span>
            </div>
          </div>
        </div>
        {/* TODO: Put this back in when we get estimates from the backend */}
        {/* <div className={styles.estimate}>
          <span className={styles.estimate}>
            <img src={Clock} alt="" /> {estimatedTime}{" "}
            {t("nextStep.careManagerTasks.min")}
          </span>
        </div> */}
      </div>
    </Link>
  );
};
