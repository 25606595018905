import { useState } from "react";

import styles from "./CoachList.module.scss";

import Avatar from "~/components/avatar/Avatar";
import { Coach } from "~/typing/sidekickTypes";

type CoachListProps = {
  coaches: Coach[];
  numToShow?: number;
  className?: string;
};

const CoachList = ({ coaches, numToShow = 5, className }: CoachListProps) => {
  const sortedCoaches =
    coaches?.sort((a, b) =>
      a.displayName?.toLowerCase() === b.displayName?.toLowerCase()
        ? a.userId > b.userId
          ? 1
          : -1
        : a.displayName?.toLowerCase() > b.displayName?.toLowerCase()
        ? 1
        : -1
    ) ?? [];

  const firstCoaches = sortedCoaches.slice(0, numToShow);
  const moreCoaches = sortedCoaches.slice(numToShow);
  const [showMoreCoaches, setShowMoreCoaches] = useState(false);

  return (
    <div className={`${styles.container} ${className}`}>
      {firstCoaches.map((coach, index) => (
        <Avatar
          size="sm"
          user={coach}
          key={`avatar-${coach.userId}-${index}`}
          className={styles.avatar}
          showNameOnHover={true}
        />
      ))}
      {moreCoaches.length > 0 && (
        <div
          className={styles.moreCoaches}
          onMouseEnter={() => setShowMoreCoaches(true)}
          onMouseLeave={() => setShowMoreCoaches(false)}
          onFocus={() => setShowMoreCoaches(true)}
          onBlur={() => setShowMoreCoaches(false)}
        >
          <button
            type="button"
            data-testid="coach-list-number"
            className={styles.number}
            tabIndex={0}
          >
            {`+${moreCoaches.length}`}
          </button>
          {showMoreCoaches && (
            <ul className={styles.list}>
              {moreCoaches.map((coach, index) => (
                <li key={`more-coach-${coach.userId}-${index}`}>
                  <div
                    data-testid="coach-list-coachrow"
                    className={styles.coachRow}
                  >
                    <Avatar
                      size="sm"
                      user={coach}
                      className={styles.avatar}
                      showNameOnHover={false}
                    />
                    {coach.displayName}
                  </div>
                  {index + 1 < moreCoaches.length && (
                    <div className={styles.separator} />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default CoachList;
