import * as Sentry from "@sentry/react";
import { ReactNode, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import ErrorFallback from "./errorFallback/ErrorFallback";

type SentryErrorBoundaryProps = {
  resetState?: () => void;
  transactionName: string;
  children: ReactNode;
};

const SentryErrorBoundary = ({
  resetState,
  children,
  transactionName
}: SentryErrorBoundaryProps) => {
  Sentry.getCurrentScope().setTransactionName(transactionName);

  const [eventId, setEventId] = useState<string>("");

  const createEventId = () => {
    const newEventId = uuidv4().split("-")[0];
    Sentry.setTag("eventId", newEventId);
    setEventId(newEventId);
  };

  return (
    <Sentry.ErrorBoundary
      beforeCapture={createEventId}
      fallback={({ resetError }) => (
        <ErrorFallback
          resetError={resetError}
          resetState={resetState}
          eventId={eventId}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
