const EmptyDataIcon = () => {
  return (
    <svg
      width="64"
      height="66"
      viewBox="0 0 64 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.0459"
        y="9.10559"
        width="44.0371"
        height="54.8387"
        rx="7.20543"
        fill="#CCD2DC"
        stroke="#002066"
        strokeWidth="2.225"
      />
      <rect
        x="8.83887"
        y="15.8984"
        width="34.8387"
        height="45.8064"
        rx="3.60272"
        fill="#7F8FA7"
      />
      <rect
        x="6.56226"
        y="13.6215"
        width="34.8387"
        height="45.8064"
        rx="3.60272"
        fill="#F2F4F6"
        stroke="#002066"
        strokeWidth="2.225"
      />
      <path
        d="M7.20728 44.2796H15.7534L18.6428 35.557L21.3716 52.3312L24.1005 44.2796H35.2718"
        stroke="#002066"
        strokeWidth="2.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5309 26.4598C19.8198 26.1927 18.7576 26.5012 18.4446 27.1401C17.8279 26.2964 16.6109 26.1551 15.8168 26.7519C15.0227 27.3487 14.699 28.4317 15.0242 29.4042C15.4143 30.571 16.8022 31.6553 17.6845 32.2487C18.1453 32.5586 18.7365 32.5586 19.1973 32.2488C20.0798 31.6554 21.4679 30.5711 21.8575 29.4042C22.0501 28.8273 22.0185 28.193 21.7697 27.6408C21.5209 27.0886 21.0753 26.6638 20.5309 26.4598Z"
        fill="#CCD2DC"
      />
      <path
        d="M25.9167 27.17H36.2393"
        stroke="#002066"
        strokeWidth="2.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9167 31.0409H36.2393"
        stroke="#002066"
        strokeWidth="2.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9167 34.912H36.2393"
        stroke="#002066"
        strokeWidth="2.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6967 6.9423C28.7402 6.68311 28.7628 6.41685 28.7628 6.14531C28.7628 3.5044 26.622 1.36353 23.981 1.36353C21.3401 1.36353 19.1993 3.5044 19.1993 6.14531C19.1993 6.41685 19.2219 6.68311 19.2654 6.9423H14.943C13.7725 6.9423 12.8237 7.89112 12.8237 9.06154L12.8237 12.7927C12.8237 13.9631 13.7725 14.9119 14.943 14.9119L33.0195 14.9119C34.1899 14.9119 35.1387 13.9631 35.1387 12.7927V9.06155C35.1387 7.89112 34.1899 6.9423 33.0195 6.9423H28.6967Z"
        fill="#E5E8ED"
      />
      <path
        d="M28.6967 6.9423L27.5995 6.75822C27.5454 7.08094 27.6361 7.41114 27.8475 7.66094C28.0588 7.91073 28.3695 8.0548 28.6967 8.0548V6.9423ZM19.2654 6.9423V8.0548C19.5926 8.0548 19.9032 7.91073 20.1146 7.66093C20.326 7.41114 20.4167 7.08095 20.3625 6.75822L19.2654 6.9423ZM12.8237 9.06154H13.9362H12.8237ZM12.8237 12.7927H11.7112H12.8237ZM14.943 14.9119V13.7994V14.9119ZM33.0195 14.9119V16.0244V14.9119ZM27.6503 6.14531C27.6503 6.35493 27.6329 6.55962 27.5995 6.75822L29.7939 7.12638C29.8475 6.80661 29.8753 6.47878 29.8753 6.14531H27.6503ZM23.981 2.47603C26.0075 2.47603 27.6503 4.11882 27.6503 6.14531H29.8753C29.8753 2.88999 27.2364 0.251025 23.981 0.251025V2.47603ZM20.3118 6.14531C20.3118 4.11882 21.9546 2.47603 23.981 2.47603V0.251025C20.7257 0.251025 18.0868 2.88999 18.0868 6.14531H20.3118ZM20.3625 6.75822C20.3292 6.55962 20.3118 6.35493 20.3118 6.14531H18.0868C18.0868 6.47878 18.1146 6.80661 18.1682 7.12638L20.3625 6.75822ZM14.943 8.0548H19.2654V5.8298H14.943V8.0548ZM13.9362 9.06154C13.9362 8.50554 14.387 8.0548 14.943 8.0548V5.8298C13.1581 5.8298 11.7112 7.2767 11.7112 9.06154H13.9362ZM13.9362 12.7927L13.9362 9.06154H11.7112L11.7112 12.7927H13.9362ZM14.943 13.7994C14.387 13.7994 13.9362 13.3487 13.9362 12.7927H11.7112C11.7112 14.5775 13.1581 16.0244 14.943 16.0244V13.7994ZM33.0195 13.7994L14.943 13.7994V16.0244L33.0195 16.0244V13.7994ZM34.0262 12.7927C34.0262 13.3487 33.5755 13.7994 33.0195 13.7994V16.0244C34.8043 16.0244 36.2512 14.5775 36.2512 12.7927H34.0262ZM34.0262 9.06155V12.7927H36.2512V9.06155H34.0262ZM33.0195 8.0548C33.5755 8.0548 34.0262 8.50553 34.0262 9.06155H36.2512C36.2512 7.2767 34.8043 5.8298 33.0195 5.8298V8.0548ZM28.6967 8.0548H33.0195V5.8298H28.6967V8.0548Z"
        fill="#002066"
      />
      <path
        d="M54.0222 37.4316C54.5034 38.0939 53.7381 39.9814 52.069 41.1941C50.3999 42.4068 48.3131 42.4755 47.8871 41.889C47.461 41.3026 47.9456 39.5031 49.8403 38.1265C51.4192 36.9794 53.541 36.7693 54.0222 37.4316Z"
        fill="#F2F4F6"
      />
      <path
        d="M54.0222 37.4316C54.5034 38.0939 53.7381 39.9814 52.069 41.1941C50.3999 42.4068 48.3131 42.4755 47.8871 41.889C47.461 41.3026 47.9456 39.5031 49.8403 38.1265C51.4192 36.9794 53.541 36.7693 54.0222 37.4316Z"
        stroke="#002066"
        strokeWidth="2.225"
      />
      <rect
        x="45.3057"
        y="35.516"
        width="3.03577"
        height="9.10731"
        rx="1.51789"
        fill="#99A6B9"
        stroke="#002066"
        strokeWidth="2.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2718 47.148C35.6428 40.7644 41.9816 41.3545 45.8093 42.7657C46.6349 43.07 47.5458 43.0701 48.3723 42.7684C52.2434 41.3553 58.7921 40.7602 61.1647 47.148C64.4914 56.1045 58.094 67.1082 47.0903 64.8051C36.0867 67.1082 29.9451 56.1045 33.2718 47.148Z"
        fill="#CCD2DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.3992 65.0354C59.685 64.088 64.0289 54.8593 61.1648 47.1481C59.8044 43.4856 57.0713 42.1187 54.257 41.8853C55.5155 42.8415 56.5823 44.2323 57.3097 46.1907C59.9288 53.2423 57.3363 61.3912 51.3992 65.0354Z"
        fill="#99A6B9"
      />
      <path
        d="M33.2718 47.148C35.6428 40.7644 41.9816 41.3545 45.8093 42.7657C46.6349 43.07 47.5458 43.0701 48.3723 42.7684C52.2434 41.3553 58.7921 40.7602 61.1647 47.148C64.4778 56.0677 58.1464 66.557 47.2256 64.3681C47.1378 64.3506 47.0428 64.3506 46.955 64.3682C36.0364 66.557 29.9587 56.0677 33.2718 47.148Z"
        stroke="#002066"
        strokeWidth="2.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3621 48.0881C35.0849 51.5268 35.5091 55.3334 37.2474 58.2164"
        stroke="#F2F4F6"
        strokeWidth="3.3375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyDataIcon;
