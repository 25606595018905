import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import styles from "./DateRangeNavigator.module.scss";

import ChevronRight from "~/assets/chevron-right.svg";

type DateRangeNavigatorProps = {
  startDate: string;
  endDate: string;
  increaseDate: () => void;
  decreaseDate: () => void;
  onTodayClick?: () => void;
  todayLabel?: string;
  className?: string;
  nextButtonDisabled?: boolean;
};

const DateRangeNavigator = ({
  startDate,
  endDate,
  increaseDate,
  decreaseDate,
  onTodayClick,
  todayLabel,
  className,
  nextButtonDisabled
}: DateRangeNavigatorProps) => {
  const { t } = useTranslation();

  const formatDate = (date: string) => {
    return DateTime.fromISO(date).toLocaleString({
      day: "2-digit",
      month: "short"
    });
  };

  const getDateRangeString = () => {
    const startDateYear = DateTime.fromISO(startDate).get("year");
    const endDateYear = DateTime.fromISO(endDate).get("year");

    const isSameYear = startDateYear === endDateYear;
    return `${formatDate(startDate)}${
      isSameYear ? "" : `, ${startDateYear}`
    } - ${formatDate(endDate)}, ${endDateYear}`;
  };

  return (
    <div className={`${styles.wrapper} ${className ?? ""}`}>
      <button
        onClick={decreaseDate}
        data-testid="date-range-back-btn"
        className={`${styles.button} ${styles.backButton}`}
      >
        <img src={ChevronRight} />{" "}
      </button>
      <div className={styles.dates}>
        <p className={styles.date}>{getDateRangeString()}</p>
      </div>
      {nextButtonDisabled === undefined || !nextButtonDisabled ? (
        <button
          data-testid="date-range-next-btn"
          onClick={increaseDate}
          className={styles.button}
        >
          <img src={ChevronRight} />{" "}
        </button>
      ) : null}
      {onTodayClick !== undefined && (
        <>
          <span className={styles.separator} />
          <button
            data-testid="date-range-today-btn"
            onClick={onTodayClick}
            className={styles.today}
          >
            {todayLabel ?? t("time.thisWeek")}
          </button>
        </>
      )}
    </div>
  );
};

export default DateRangeNavigator;
