import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./PrintSurvey.module.scss";

import { ExportContext } from "../exportPreviewContext";

import Image from "~/components/image/Image";
import { isBetweenDates } from "~/helpers/date/isBetweenDates";
import { SurveyResult } from "~/typing/sidekickTypes";

type PrintSurveyProps = {
  survey: (SurveyResult & { quiz: boolean })[];
};

const PrintSurvey = ({ survey }: PrintSurveyProps) => {
  const { t } = useTranslation();

  const [numberOfImagesLoaded, setNumberOfImagesLoaded] = useState(0);
  const [numberOfImages, setNumberOfImages] = useState(0);
  const { endDate, startDate, setIsLoading } = useContext(ExportContext);

  useEffect(() => {
    setIsLoading(true);
    setNumberOfImages(0);
    const number =
      survey
        ?.filter((result) => isBetweenDates(result.date, startDate, endDate))
        .filter((result) => {
          return result.questions?.some((question) => {
            return question?.answers?.some((answer) => {
              //Return only if there is an actual image answer
              return answer?.attachmentUrl !== undefined;
            });
          });
        }) ?? [];
    setIsLoading(number.length > 0);
    setNumberOfImages(number.length);
    setIsLoading(number.length > numberOfImagesLoaded);
  }, [survey]);

  useEffect(() => {
    if (numberOfImagesLoaded === numberOfImages && numberOfImagesLoaded !== 0) {
      setIsLoading(false);
    }
  }, [numberOfImagesLoaded]);

  return (
    <>
      {survey
        ?.filter((result) => isBetweenDates(result.date, startDate, endDate))
        ?.map((result, key) => {
          return (
            <table key={key} className={styles.table}>
              <tbody>
                <tr key={key}>
                  <th className={styles.dateHeader}>
                    <span>
                      {DateTime.fromISO(result.date).toFormat("MMMM dd, y")}
                    </span>
                  </th>
                  <th className={styles.left}>{t("exportPreview.result")}</th>
                  <th></th>
                  <th className={styles.score}>
                    {!result.quiz ? result.result : ""}
                  </th>
                </tr>
                {result.questions?.map((question, trKey) => {
                  return (
                    <tr key={trKey}>
                      <td></td>
                      <td>{question.title}</td>
                      <td className={styles.right}>
                        {question &&
                          question.answers &&
                          question.answers.map((answer, akey) => {
                            // if (answer.attachmentUrl) numberOfImages++;
                            return (
                              <div key={akey}>
                                {answer.attachmentUrl ? (
                                  <Image
                                    className={styles.image}
                                    imageUrl={answer.attachmentUrl}
                                    onImageLoad={() =>
                                      setNumberOfImagesLoaded(
                                        (prev) => prev + 1
                                      )
                                    }
                                  />
                                ) : answer.title ? (
                                  answer.title
                                ) : (
                                  answer.answer
                                )}
                              </div>
                            );
                          })}
                      </td>
                      <td className={styles.scoreValue}>
                        {question &&
                          question.answers &&
                          question.answers.map((answer, akey) => {
                            return <div key={akey}>{answer.ratingValue}</div>;
                          })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
    </>
  );
};

export default PrintSurvey;
