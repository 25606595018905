export default function compareValues(a: string | number, b: string | number) {
  // sort case insensitive
  if (typeof a === "string") {
    a = a.toLowerCase();
  }

  if (typeof b === "string") {
    b = b.toLowerCase();
  }

  if (a < b)
    //sort string ascending
    return -1;
  if (a > b) return 1;
  return 0; //default return value (no sorting)
}
