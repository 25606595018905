import { DateTime } from "luxon";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";

import styles from "./LogCard.module.scss";

import { UserPageContext } from "../../userPage/UserPage.context";
import { importIcon, createReminderString } from "../utils/MedicationUtility";

import { t } from "~/i18n";
import {
  MedicationLogSanitised,
  MedicationReminderType
} from "~/typing/carePortalTypes";

interface LogCardProps {
  log: MedicationLogSanitised;
  small?: boolean; // If true, the card will be smaller
}

enum UserMedicationLogStatus {
  Unspecified = "USERMEDICATION_LOG_STATUS_UNSPECIFIED",
  Pending = "USERMEDICATION_LOG_STATUS_PENDING",
  Success = "USERMEDICATION_LOG_STATUS_SUCCESS",
  Error = "USERMEDICATION_LOG_STATUS_ERROR",
  Skipped = "USERMEDICATION_LOG_STATUS_SKIPPED",
  Missed = "USERMEDICATION_LOG_STATUS_MISSED",
  ErrorUnsafeInjection = "USERMEDICATION_LOG_STATUS_ERROR_UNSAFE_INJECTION",
  ErrorHoldingTime = "USERMEDICATION_LOG_STATUS_ERROR_HOLDING_TIME",
  ErrorInjectionTime = "USERMEDICATION_LOG_STATUS_ERROR_INJECTION_TIME",
  ErrorUnexpectedUncoupling = "USERMEDICATION_LOG_STATUS_ERROR_UNEXPECTED_UNCOUPLING",
  Upcoming = "USERMEDICATION_UPCOMING"
}

// Define the object that maps UserMedicationLogStatus to the corresponding styles
const statusStyleMap = {
  [UserMedicationLogStatus.Unspecified]: [
    styles.medicationCardScheduleInterrupted,
    styles.medicationCardScheduleInterruptedSmall
  ],
  [UserMedicationLogStatus.Pending]: [
    styles.medicationCardScheduleNotLogged,
    styles.medicationCardScheduleNotLoggedSmall
  ],
  [UserMedicationLogStatus.Success]: [
    styles.medicationCardScheduleSuccess,
    styles.medicationCardScheduleSuccessSmall
  ],
  [UserMedicationLogStatus.Error]: [
    styles.medicationCardScheduleError,
    styles.medicationCardScheduleErrorSmall
  ],
  [UserMedicationLogStatus.Skipped]: [
    styles.medicationCardScheduleError,
    styles.medicationCardScheduleErrorSmall
  ],
  [UserMedicationLogStatus.Missed]: [
    styles.medicationCardScheduleNotLogged,
    styles.medicationCardScheduleNotLoggedSmall
  ],
  [UserMedicationLogStatus.ErrorUnsafeInjection]: [
    styles.medicationCardScheduleError,
    styles.medicationCardScheduleErrorSmall
  ],
  [UserMedicationLogStatus.ErrorHoldingTime]: [
    styles.medicationCardScheduleError,
    styles.medicationCardScheduleErrorSmall
  ],
  [UserMedicationLogStatus.ErrorInjectionTime]: [
    styles.medicationCardScheduleError,
    styles.medicationCardScheduleErrorSmall
  ],
  [UserMedicationLogStatus.ErrorUnexpectedUncoupling]: [
    styles.medicationCardScheduleError,
    styles.medicationCardScheduleErrorSmall
  ]
};

const LogCard = ({ log, small }: LogCardProps) => {
  const [icon, setIcon] = useState<string>("");
  const [reminderString, setReminderString] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  let isMounted = useRef(false);

  const { userDetail } = useContext(UserPageContext);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getStatus = () => {
    switch (log.status) {
      case UserMedicationLogStatus.Unspecified:
        return "Unspecified";
      case UserMedicationLogStatus.Pending:
        return "Not logged";
      case UserMedicationLogStatus.Success:
        if (
          log.medicationType === "USERMEDICATION_TYPE_AUTOINJECTION" ||
          log.medicationType === "USERMEDICATION_TYPE_INJECTION"
        )
          return "Success";
        else return "Taken";
      case UserMedicationLogStatus.Error:
        return "Error";
      case UserMedicationLogStatus.Skipped:
        return "Skipped";
      case UserMedicationLogStatus.Missed:
        return "Not logged";
      case UserMedicationLogStatus.ErrorUnsafeInjection:
        return "Error Unsafe Injection";
      case UserMedicationLogStatus.ErrorHoldingTime:
        return "Error Holding Time";
      case UserMedicationLogStatus.ErrorInjectionTime:
        return "Error Injection Time";
      case UserMedicationLogStatus.ErrorUnexpectedUncoupling:
        return "Error Unexpected Uncoupling";
      case UserMedicationLogStatus.Upcoming:
        return "Upcoming";
      default:
        return "Unrecognized";
    }
  };

  /**
   * Returns the appropriate status color based on the given injection status.
   *
   * @param small - Whether to return the small variant of the status color.
   * @returns The appropriate status color based on the given injection status.
   */
  const getStatusColor = (small: boolean) => {
    // Get the corresponding style for the given injection.status
    const stylePair = statusStyleMap[log.status] || [
      styles.medicationCardScheduleUpcomming,
      styles.medicationCardScheduleUpcommingSmall
    ];

    // Return the appropriate style based on the 'small' parameter
    return small ? stylePair[1] : stylePair[0];
  };

  useEffect(() => {
    isMounted = { current: true };

    //If iconId is not between 1 and 12, return because there is no icon for that index in the svg medication folder.
    if (!log.icon || log.icon > 12 || log.icon < 1) {
      setIcon("");
      return;
    }

    //Dynamically import the svg icon based on the medication iconId.
    const importIconAsync = async () => {
      const icon = await importIcon(log.icon, log.iconColor);
      if (isMounted.current) {
        setIcon(icon);
      }
    };

    importIconAsync();

    return () => {
      isMounted.current = false;
    };
  }, [log]);

  useEffect(() => {
    if (log.reminders && log.reminders?.length > 0) {
      const reminderStringFunc = () => {
        setReminderString(
          createReminderString(
            log.reminders ?? [],
            MedicationReminderType.MedicationLog
          )
        );
      };

      reminderStringFunc();
    }
  }, []);

  return !!small ? (
    <div>
      <div
        className={`${styles.medicationCardSmall} ${getStatusColor(true)}`}
        onClick={openModal}
      >
        {icon && (
          <img
            src={`data:image/svg+xml;base64,${window.btoa(icon)}`}
            className={styles.medicationCardSmallIcon}
          />
        )}
        {!icon && <img />}
        <p
          className={styles.medicationCardSmallName}
          title={log.medicationName}
        >
          {log.medicationName}
        </p>
      </div>
      <MedicationModal isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles.modalBody}>
          <div className={styles.modalBodyInfo}>
            <p className={styles.modalBodyInfoText}>
              {t("medication.medicine")}
            </p>

            <p className={styles.modalBodyInfoValue}>{log.medicationName}</p>
            <p className={styles.modalBodyInfoValue}>{log.medicationType}</p>
          </div>
          <div className={styles.modalBodyTime}>
            <p className={styles.modalBodyTimeText}>{t("medication.time")}</p>
            <div className={styles.modalBodyTimeValue}>
              {!log.upcoming ? (
                <div>
                  <div className={styles.fadedText}>
                    {t("medication.scheduled")}
                    {!!log.scheduledDate ? (
                      DateTime.fromISO(log.scheduledDate, {
                        zone: userDetail?.timezoneId
                      })
                        .toLocaleString(DateTime.TIME_SIMPLE)
                        .toUpperCase()
                    ) : (
                      <span> {t("medication.na")}</span>
                    )}
                  </div>
                  {!!log.completedDate && (
                    <div>
                      {t("medication.takenAt")}
                      {DateTime.fromISO(log.completedDate, {
                        zone: userDetail?.timezoneId
                      })
                        .toLocaleString(DateTime.TIME_SIMPLE)
                        .toUpperCase()}
                    </div>
                  )}
                </div>
              ) : (
                <span className={styles.fadedText}>
                  {t("medication.scheduled")}
                  {DateTime.fromISO(log.time ?? "", {
                    zone: userDetail?.timezoneId
                  })
                    .toLocaleString(DateTime.TIME_SIMPLE)
                    .toUpperCase()}
                </span>
              )}
            </div>
          </div>
          <div className={styles.modalBodyFrequency}>
            <p className={styles.modalBodyFrequencyText}>
              {t("medication.frequency")}
            </p>
            <div className={styles.modalBodyFrequencyValue}>
              <span>
                {reminderString !== "" ? reminderString : t("medication.na")}
              </span>
            </div>
          </div>
        </div>
        <div className={`${styles.modalFooter} ${getStatusColor(true)}`}>
          <div className={styles.modalFooterText}>{getStatus()}</div>
        </div>
      </MedicationModal>
    </div>
  ) : (
    <div className={styles.medicationCard}>
      <div className={styles.medicationCardHeader}>
        <p className={styles.medName} title={log.medicationName}>
          {log.medicationName}
        </p>
        {icon && (
          <img
            src={`data:image/svg+xml;base64,${window.btoa(icon)}`}
            className={styles.medicationCardIcon}
          />
        )}
      </div>
      <div className={styles.medicationCardTime}>
        {!log.upcoming ? (
          <div>
            <div className={styles.fadedText}>
              {t("medication.scheduled")}
              {!!log.scheduledDate ? (
                DateTime.fromISO(log.scheduledDate, {
                  zone: userDetail?.timezoneId
                })
                  .toLocaleString(DateTime.TIME_SIMPLE)
                  .toUpperCase()
              ) : (
                <span> {t("medication.na")}</span>
              )}
            </div>
            {!!log.completedDate && (
              <div>
                {t("medication.takenAt")}
                {DateTime.fromISO(log.completedDate, {
                  zone: userDetail?.timezoneId
                })
                  .toLocaleString(DateTime.TIME_SIMPLE)
                  .toUpperCase()}
              </div>
            )}
          </div>
        ) : (
          <span className={styles.fadedText}>
            {t("medication.scheduled")}
            {DateTime.fromISO(log.time ?? "", { zone: userDetail?.timezoneId })
              .toLocaleString(DateTime.TIME_SIMPLE)
              .toUpperCase()}
          </span>
        )}
      </div>
      <div
        className={`${styles.medicationCardSchedule} ${getStatusColor(false)}`}
      >
        {
          <span className={styles.medicationCardScheduleText}>
            {getStatus()}
          </span>
        }
      </div>
    </div>
  );
};

export default LogCard;

interface MedicationModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const MedicationModal = ({
  isOpen,
  onClose,
  children
}: MedicationModalProps) => {
  if (!isOpen) {
    return null;
  }

  const handleClickOutside = (event) => {
    if (
      Array.from<string>(event.target.classList).some((className: string) =>
        className.includes("modalOverlay")
      )
    ) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleClickOutside}>
      <div className={styles.modal}>{children}</div>
    </div>
  );
};
