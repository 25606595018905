import styles from "./Pill.module.scss";

import colors from "~/styles/colors";

type PillProps = {
  title?: string;
  className?: string;
  onClick?: () => void;
  backgroundColor?: string;
  fontColor?: string;
};

const Pill = ({
  title,
  className,
  onClick,
  backgroundColor = colors.mustardYellow100,
  fontColor
}: PillProps) => {
  if (!title) return null;

  return (
    <div
      className={`${styles.wrapper} ${
        onClick ? styles.clickable : ""
      } ${className}`}
      onClick={onClick}
      style={{
        backgroundColor,
        color: fontColor
      }}
    >
      {title}
    </div>
  );
};

export default Pill;
