import { t } from "~/i18n";

export default function genderText(gender: number | undefined) {
  switch (gender) {
    case 0:
      return t("gender.male", "Male");
    case 1:
      return t("gender.female", "Female");
    case 2:
      return t("gender.other", "Other");
    default:
      return t("gender.unspecified", "Unspecified");
  }
}
