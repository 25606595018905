import { DateTime } from "luxon";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import ComplianceDetailModal from "./ComplianceDetailModal";
import styles from "./CompliancePage.module.scss";

import Avatar from "~/components/avatar/Avatar";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import Notification from "~/components/notification/Notification";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { UserProgramStatus } from "~/constants/programStatus";
import { hasQuitProgram } from "~/helpers/user/userHelpers";
import useComplianceUsers from "~/hooks/useApi/useComplianceUsers";
import useProgram from "~/hooks/useApi/useProgram";
import { CustomListFilterData } from "~/typing/carePortalTypes";
import { SearchData } from "~/typing/carePortalTypes";
import { ComplianceUser } from "~/typing/sidekickTypes";

enum CompliancePageFilterNames {
  UserStatus = "userStatus"
}

const CompliancePage = () => {
  const { program_id = "", locale = "" } = useParams<{
    program_id: string;
    locale: string;
  }>();
  const { program } = useProgram({ programCatalogItemId: program_id, locale });
  const [selectedUser, setSelectedUser] = useState();
  const {
    complianceUsers,
    isLoading,
    invalidate: invalidateComplianceData
  } = useComplianceUsers(program_id, locale);

  const { t } = useTranslation();

  const searchData: SearchData = {
    placeholder: t("programUserList.search"),
    data: [{ key: ["user", "displayName"] }, { key: ["user", "phoneNumber"] }],
    className: styles.search
  };

  const columns: CustomListColumn<ComplianceUser>[] = [
    {
      heading: {
        text: t("programUserList.userName"),
        sortable: {
          sortBy: ["user", "displayName"]
        },
        tooltip: {
          text: t("programUserList.headingTooltip.userName")
        }
      },
      render: (user) => (
        <div className={styles.user}>
          <Avatar user={user.user} className={styles.avatar} />
          {user.displayName}
          {hasQuitProgram(user.programStatus) && (
            <p className={styles.dropOutUser}>
              {t("programUserList.programRemoved")}
            </p>
          )}
          {user.programStatus === UserProgramStatus.Finished && (
            <p className={styles.dropOutUser}>
              {t("programUserList.programFinished")}
            </p>
          )}
        </div>
      )
    },
    {
      heading: {
        text: t("registerPatient.phone")
      },
      render: (user) => user.phoneNumber
    },
    {
      heading: {
        text: t("registerPatient.email")
      },
      render: (user) => user.email
    },
    {
      heading: {
        text: t("compliancePage.lastProDate"),
        sortable: {
          reverse: true,
          sortBy: ["lastTakenOn"]
        },
        tooltip: {
          text: t("compliancePage.lastProDateTooltip")
        }
      },
      render: ({ lastTakenOn, compliant, user }) => (
        <button
          type="button"
          className={styles.date}
          onClick={() => setSelectedUser(user)}
        >
          {lastTakenOn
            ? DateTime.fromISO(lastTakenOn).toLocaleString({
                month: "short",
                day: "numeric",
                year: "numeric"
              })
            : t("compliancePage.notTaken")}
          {!compliant && (
            <Notification count={1} size="xs" className={styles.notification} />
          )}
        </button>
      )
    }
  ];

  const handleCloseDetailModal = () => {
    setSelectedUser(undefined);
  };

  const resetState = () => {
    setSelectedUser(undefined);
    invalidateComplianceData();
  };

  const filterData: CustomListFilterData<ComplianceUser> = {
    label: t("programUserList.userStatus"),
    options: [
      { value: "active", text: "Active users" },
      { value: "finished", text: "Finished Users" },
      { value: "quit", text: "Quit Users" },
      { value: "all", text: "All users" }
    ],
    key: CompliancePageFilterNames.UserStatus,
    renderOption: (option) => option.text,
    filter: ({ entity: user, filterValue }) => {
      switch (filterValue) {
        case "active":
          return user.active;
        case "finished":
          return user.programStatus === UserProgramStatus.Finished;
        case "quit":
          return hasQuitProgram(user.programStatus);
        case "all":
          return true;
        default:
          return true;
      }
    }
  };

  return (
    <div className={styles.container}>
      <Helmet
        title={`${program?.name || ""} ${t("compliancePage.compliance")}`}
      />
      <SentryErrorBoundary
        resetState={resetState}
        transactionName="CompliancePage"
      >
        <CustomList<ComplianceUser>
          entities={complianceUsers ?? []}
          searchData={searchData}
          isLoading={isLoading}
          noEntitiesText={t("programUserList.noUsers")}
          defaultSortingData={{ reverse: true, sortBy: ["lastTakenOn"] }}
          columns={columns}
          filterSelectData={[filterData]}
          customListUniqueId={`compliance-page-ist${program_id}-${locale}`}
        />
        {selectedUser && (
          <ComplianceDetailModal
            user={selectedUser}
            onClose={handleCloseDetailModal}
          />
        )}
      </SentryErrorBoundary>
    </div>
  );
};

export default CompliancePage;
