import { ForwardedRef, forwardRef, useEffect } from "react";

import styles from "./AutoresizeTextarea.module.scss";
interface AutoresizeTextareaProps {
  value: string;
  onChange: (value: string) => void;
  large?: boolean;
  className?: string;
  placeholder: string;
  dataTestId?: string;
  label?: string;
  wrapperClassName?: string;
}

interface IProps extends AutoresizeTextareaProps {
  textAreaRef: ForwardedRef<HTMLTextAreaElement>;
}

const AutoresizeTextarea = forwardRef<
  HTMLTextAreaElement,
  AutoresizeTextareaProps
>((props, textAreaRef) => {
  return <AutoresizeTextareaComponent {...props} textAreaRef={textAreaRef} />;
});

const AutoresizeTextareaComponent = ({
  value,
  onChange,
  large,
  className,
  placeholder,
  textAreaRef,
  dataTestId,
  label,
  wrapperClassName,
  ...rest
}: IProps) => {
  const padding = large ? 24 : 16;

  const handleResize = () => {
    if (typeof textAreaRef !== "function" && textAreaRef?.current) {
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight - padding + "px";
    }
  };

  const handleChange = (value: string) => {
    onChange(value);
  };

  useEffect(() => {
    handleResize();
  }, [value]);

  return (
    <div className={`${styles.wrapper} ${wrapperClassName ?? ""}`}>
      {label && <label className="label label--block">{label}</label>}
      <textarea
        data-testid={dataTestId}
        ref={textAreaRef}
        value={value}
        rows={1}
        onChange={(e) => handleChange(e.target.value)}
        className={
          "input textarea textarea-expandable " +
          (large ? "input-lg " : "") +
          className
        }
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

AutoresizeTextarea.displayName = "AutoresizeTextarea";

export default AutoresizeTextarea;
