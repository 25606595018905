import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./BloodPressureModal.module.scss";

import {
  BloodPressureInfoData,
  getAnalyzedBloodPressureData,
  getCellData
} from "../../../../../helpers/vitals/bloodPressureHelpers";

import { getBloodPressureData } from "~/api/requests/scoreRequests";
import ButtonLink from "~/components/buttonLink/ButtonLink";
import ChartRating from "~/components/charts/ChartRating";
import CustomLineChart from "~/components/charts/CustomLineChart";
import DateRangeNavigator from "~/components/dateRangeNavigator/DateRangeNavigator";
import Modal from "~/components/modal/Modal";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import { getNewestDate } from "~/helpers/date/dateHelpers";
import { getLineData } from "~/helpers/vitals/vitalsHelpers";
import colors from "~/styles/colors";
import { BloodPressureLineData, UserURLParams } from "~/typing/carePortalTypes";
import { BloodPressureData } from "~/typing/sidekickTypes";

type BloodPressureModalProps = {
  onClose: () => void;
  latestLogDate?: string;
};

const DEFAULT_DAYS_TO_DISPLAY = 7;
//Details view should display the last 30 logs for the past 2 years
const DETAILS_DAYS_TO_DISPLAY = 730;
const DETAILS_LOGS_TO_DISPLAY = 30;

const BloodPressureModal = ({
  onClose,
  latestLogDate
}: BloodPressureModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bloodPressureData, setBloodPressureData] = useState<
    BloodPressureData[]
  >([]);
  const latestDate = latestLogDate
    ? DateTime.fromISO(latestLogDate)
    : DateTime.now();
  const [endDate, setEndDate] = useState(latestDate);
  const [daysToDisplay, setDaysToDisplay] = useState(DEFAULT_DAYS_TO_DISPLAY);

  const {
    user_id = "",
    program_id = "",
    locale = ""
  } = useParams<UserURLParams>();
  const { t } = useTranslation();

  const isDetailsView = () => {
    return daysToDisplay === DETAILS_DAYS_TO_DISPLAY;
  };

  useEffect(() => {
    const getAndSetData = async () => {
      setIsLoading(true);
      const bloodPressureResponse = await getBloodPressureData({
        userId: user_id,
        locale,
        programId: program_id,
        startDate: isDetailsView()
          ? DateTime.now().minus({ days: daysToDisplay - 1 })
          : endDate.minus({ days: daysToDisplay - 1 }),
        endDate: isDetailsView() ? DateTime.now() : endDate,
        pageSize: isDetailsView() ? DETAILS_LOGS_TO_DISPLAY : undefined
      });
      setBloodPressureData(bloodPressureResponse);
      setIsLoading(false);
    };
    getAndSetData();
  }, [endDate, daysToDisplay]);

  const lineData = getLineData(bloodPressureData);

  const analyzedData = getAnalyzedBloodPressureData(lineData);

  const bloodPressureInfoToString = (info: BloodPressureInfoData) => {
    return `${info?.systolic > 0 ? info?.systolic : "--"}/${
      info?.diastolic > 0 ? info?.diastolic : "--"
    }`;
  };

  const highestValue = lineData.reduce(
    (prev, curr) => (curr.systolic > prev ? curr.systolic : prev),
    0
  );

  const lowestValue = lineData.reduce(
    (prev, curr) => (curr.diastolic < prev ? curr.diastolic : prev),
    1000
  );

  return (
    <Modal
      className={styles.modal}
      title={t("user.health.bloodPressure.bloodPressure")}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.infoWrapper}>
            {analyzedData.map((info, index) => (
              <ChartRating
                key={`${info.title}-${index}`}
                title={info.title}
                cellTitle={info.cellTitle}
                cellColor={info.cellColor}
                value={bloodPressureInfoToString(info)}
                unit={t("user.health.bloodPressure.sysDia")}
                isLoading={isLoading}
              />
            ))}
          </div>
          <div className={styles.navWrapper}>
            <ToggleSwitch
              switchData={[
                {
                  label: "7d",
                  value: 7
                },
                {
                  label: "30d",
                  value: 30
                },
                {
                  label: t("time.detail"),
                  value: DETAILS_DAYS_TO_DISPLAY
                }
              ]}
              setValue={(value) => setDaysToDisplay(value)}
              currentValue={daysToDisplay}
              className={styles.switch}
              useLinks={true}
            />
            {isDetailsView() ? (
              <div className={styles.last30Link}>
                <ButtonLink
                  active={isDetailsView()}
                  title={t("time.last30Logs")}
                  onClick={() => undefined}
                />
              </div>
            ) : (
              <DateRangeNavigator
                className={styles.navigator}
                increaseDate={() =>
                  setEndDate((prev) => prev.plus({ days: daysToDisplay }))
                }
                decreaseDate={() =>
                  setEndDate((prev) => prev.minus({ days: daysToDisplay }))
                }
                startDate={
                  endDate.minus({ days: daysToDisplay - 1 }).toISO() ?? ""
                }
                endDate={endDate.toISO() ?? ""}
                onTodayClick={() =>
                  setEndDate(
                    getNewestDate(
                      bloodPressureData.map((item) =>
                        DateTime.fromISO(item.date)
                      )
                    ) ?? DateTime.now()
                  )
                }
                todayLabel={t("time.latest")}
              />
            )}
          </div>
        </div>

        <CustomLineChart<BloodPressureLineData>
          lineData={lineData}
          height={400}
          YAxisDomain={[lowestValue - 20, highestValue + 20]}
          lines={[
            {
              name: `${t("user.health.bloodPressure.systolic")}`,
              dataKey: "systolic",
              lineColor: colors.darkBlue140
            },
            {
              name: `${t("user.health.bloodPressure.diastolic")}`,
              dataKey: "diastolic",
              lineColor: colors.darkBlue60
            }
          ]}
          units="mmHg"
          getCellData={getCellData}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default BloodPressureModal;
