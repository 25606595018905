import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { FoodJournalPageContext } from "./foodJournalPageContext";
import styles from "./FoodJournalReview.module.scss";
import { getMealTypeText } from "./journalhelper";

import Image from "~/components/image/Image";
import Modal from "~/components/modal/Modal";
import Slider from "~/components/slider/Slider";
import SliderHunger from "~/components/slider/SliderHunger";
import { wordCount } from "~/helpers/string/stringHelpers";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";
import { ScoreMeal } from "~/typing/sidekickTypes";

type FoodJournalReviewProps = {
  journal: ScoreMeal;
  createdDate: string;
  close: () => void;
  serverDate?: string;
};

const FoodJournalReview = ({
  serverDate,
  close,
  journal,
  createdDate
}: FoodJournalReviewProps) => {
  const {
    coachPortionSize,
    portionSize,
    coachHealthyness,
    healthyness,
    coachReview,
    id: scoreMealId,
    scoreId,
    attachmentUrl,
    coachModifiedDate,
    mealType,
    title,
    hungerAfter,
    hungerBefore
  } = journal;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { program_id = "", locale = "" } = useParams<UserURLParams>();

  const queryClient = useQueryClient();

  const { updateJournal } = useContext(FoodJournalPageContext);
  const { trackMealMessageSent } = useAmplitudeTracking();

  const initialCoachPortionSize = coachPortionSize
    ? coachPortionSize
    : portionSize;
  const initialCoachHealthyness = coachHealthyness
    ? coachHealthyness
    : healthyness;
  const [newCoachHealthyness, setNewCoachHealthyness] = useState(
    initialCoachHealthyness
  );
  const [newCoachPortionSize, setNewCoachPortionSize] = useState(
    initialCoachPortionSize
  );
  const initialComment = coachReview || "";
  const [comment, setComment] = useState(initialComment);
  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setError("");
    setLoading(true);
    const payload = {
      id: scoreMealId,
      scoreId: scoreId,
      coachReview: comment,
      coachHealthyness: newCoachHealthyness,
      coachPortionSize: newCoachPortionSize
    };

    const shouldUpdateDetails = coachModifiedDate === undefined;
    const journalUpdated = await updateJournal(payload, shouldUpdateDetails);

    if (journalUpdated) {
      trackMealMessageSent({
        isHealthyRatingChanged: newCoachHealthyness !== initialCoachHealthyness,
        isPortionSizeChanged: newCoachPortionSize !== initialCoachPortionSize,
        mealDate: journal.serverDate,
        mealId: parseInt(journal.id),
        mealName: journal.title ?? "",
        wordCount: wordCount(comment)
      });

      // Invalidate the user programs so that the user's coach list is updated
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.programs.users(program_id, locale)
      });
      close();
    } else {
      setError(t("performance.journals.review.error"));
    }
    setLoading(false);
  };
  const handleDismiss = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setError("");
    const payload = {
      id: scoreMealId,
      scoreId: scoreId
    };

    const shouldUpdateDetails = coachModifiedDate === undefined;
    const journalUpdated = await updateJournal(payload, shouldUpdateDetails);

    if (journalUpdated) {
      // Invalidate the program users so that the user's coach list is updated
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.programs.users(program_id, locale)
      });
      close();
    } else {
      setError(t("performance.journals.review.error"));
    }
    setLoading(false);
  };
  const hasChanges = () => {
    return (
      newCoachHealthyness !== initialCoachHealthyness ||
      newCoachPortionSize !== initialCoachPortionSize ||
      comment !== initialComment
    );
  };

  return (
    <>
      <Modal
        title={t("performance.journals.review.title", "Meal")}
        className={`${styles.container}`}
        onClose={() => close()}
        contentClass={styles.noMargins}
      >
        <div className={styles.content}>
          <Image
            shouldFetchAsBlob
            className={styles.image}
            imageUrl={attachmentUrl}
          />

          <div className={styles.description}>
            <span className={styles.subHeader}>
              {t(getMealTypeText(mealType))}
            </span>
            <div className={styles.timestamp}>
              <span>
                {DateTime.fromISO(createdDate).toLocaleString({
                  month: "short",
                  weekday: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: "utc"
                })}{" "}
                {t("performance.journals.review.userTime", "User time")}
              </span>
              {serverDate && (
                <span className={styles.secondTimeStamp}>
                  {DateTime.fromISO(serverDate).toLocaleString({
                    month: "short",
                    weekday: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })}{" "}
                  {t("performance.journals.review.localTime", "Local time")}
                </span>
              )}
            </div>
          </div>
          <div className={styles.text}>{title}</div>
          <Slider
            staticValue={healthyness}
            dynamicValue={newCoachHealthyness}
            onChange={(value) => setNewCoachHealthyness(value)}
            title={t("performance.journals.review.healthRate")}
            legendLeft={t("performance.journals.review.unhealthy")}
            legendRight={t("performance.journals.review.healthy")}
            className={styles.sliderHealthyness}
            dataTestId="food-journal-slider-healthyness"
          />
          <Slider
            staticValue={portionSize}
            dynamicValue={newCoachPortionSize}
            onChange={(value) => setNewCoachPortionSize(value)}
            title={t("performance.journals.review.portionSize")}
            legendLeft={t("performance.journals.review.small")}
            legendRight={t("performance.journals.review.large")}
            className={styles.sliderPortion}
          />
          {hungerBefore !== undefined && (
            <SliderHunger
              staticValue={hungerBefore}
              title={t(
                "performance.journals.review.hungerBefore",
                "Hunger before"
              )}
              className={styles.sliderHunger}
              legendLeft={t("performance.journals.review.starving")}
              legendRight={t("performance.journals.review.stuffed")}
            />
          )}
          {hungerAfter !== undefined && (
            <SliderHunger
              staticValue={hungerAfter}
              title={t(
                "performance.journals.review.hungerAfter",
                "Hunger after"
              )}
              className={styles.sliderHunger}
              legendLeft={t("performance.journals.review.starving")}
              legendRight={t("performance.journals.review.stuffed")}
            />
          )}
          <div data-testid="food-journal-comment" className={styles.comment}>
            <textarea
              rows={3}
              placeholder={t("performance.journals.review.commentPlaceholder")}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={error ? { height: "80px" } : { height: "120px" }}
              className={styles.textarea}
            />
          </div>

          {error && <div className={styles.error}>{error}</div>}
          <div
            data-testid="food-journal-review-actions"
            className={styles.actions}
          >
            {!coachModifiedDate && (
              <button
                className="btn-secondary btn-sm"
                onClick={handleDismiss}
                disabled={loading}
              >
                {t("performance.journals.review.dismiss")}
              </button>
            )}
            <button
              className="btn-primary btn-sm"
              onClick={handleSubmit}
              disabled={loading || !hasChanges()}
            >
              {t("performance.journals.review.send")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FoodJournalReview;
