import styles from "./SortIcon.module.scss";

import SortDownIcon from "~/assets/sort-down.svg";
import SortUpIcon from "~/assets/sort-up.svg";

type SortIconProps = {
  active?: boolean;
  sortOrder?: "asc" | "desc";
};

const SortIcon = ({ active, sortOrder }: SortIconProps) => {
  return (
    <img
      src={active && sortOrder === "desc" ? SortUpIcon : SortDownIcon}
      className={`${styles.sortingIcon} ${
        sortOrder === "desc" && active ? styles.desc : styles.asc
      } ${active ? styles.active : ""}`}
    />
  );
};

export default SortIcon;
