import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./ImportInfoModal.module.scss";
import { convertEntryStatusToText } from "./importUtils";

import api from "~/api/api";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import Modal from "~/components/modal/Modal";
import {
  getBasicFormattedDate,
  getDateAndTimeString
} from "~/helpers/date/dateHelpers";
import { capitalizeWord } from "~/helpers/string/stringHelpers";
import { ImportEntry } from "~/typing/sidekickTypes";

type ImportInfoModalProps = {
  close: () => void;
  importEntry?: ImportEntry;
};

enum ImportStatusStatus {
  Info = 0,
  Running = 1,
  Done = 2,
  Error = 10
}

enum ImportStatusStep {
  Validation = "VALIDATION",
  Importing = "IMPORTING",
  Parsing = "PARSING"
}

enum ImportStatusType {
  Info = "INFO",
  Step = "STEP",
  Check = "CHECK"
}

type ImportStatus = {
  id: number;
  importEntryId: number; // ID of the import entry
  message: string; // message from this status (“Finished parsing”, “Error parsing the header” …)
  messageType: ImportStatusType;
  time: string; // time when created
  status: ImportStatusStatus;
  step: ImportStatusStep;
};

type ErrorLine = {
  fileUploadId: string;
  id: string;
  lineNumber: number;
  status: number;
  statusMessage: string;
};

const convertImportStatusToText = (status?: ImportStatusStatus) => {
  if (status === undefined) return "";

  switch (status) {
    case ImportStatusStatus.Done:
      return "Done";
    case ImportStatusStatus.Error:
      return "Error";
    case ImportStatusStatus.Info:
      return "Info";
    case ImportStatusStatus.Running:
      return "Running";
    default:
      return "";
  }
};

const ImportInfoModal = ({ close, importEntry }: ImportInfoModalProps) => {
  const [importStatuses, setImportStatuses] = useState<ImportStatus[]>([]);
  const [errorLines, setErrorLines] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const getImportStatuses = async () => {
      if (!importEntry) return;
      await api
        .get(`/admin/extras/importentry/${importEntry.id}/status`)
        .then((res) => setImportStatuses(res?.data?.items ?? []));
    };
    getImportStatuses();
  }, []);

  useEffect(() => {
    const getErrorLines = async () => {
      if (!importEntry) return;

      await api
        .get(`/admin/extras/importentry/${importEntry.id}/errorlines`)
        .then((res) => setErrorLines(res?.data?.items ?? []));
    };
    getErrorLines();
  }, []);

  const errorTableColumns: CustomListColumn<ErrorLine>[] = [
    {
      heading: {
        text: "Line number",
        sortable: {
          sortBy: ["lineNumber"]
        },
        tooltip: {
          text: "Line number"
        }
      },
      render: ({ lineNumber }) => lineNumber
    },
    {
      heading: {
        text: "Comment",
        tooltip: {
          text: "Comment"
        }
      },
      render: ({ statusMessage }) => statusMessage
    }
  ];

  const infoStatusColumns: CustomListColumn<ImportStatus>[] = [
    {
      heading: {
        text: "Message",
        tooltip: {
          text: "Message"
        }
      },
      render: ({ message }) => message
    },
    {
      heading: {
        text: "Import Step",
        tooltip: {
          text: "Import Step"
        }
      },
      render: ({ step }) => capitalizeWord(step)
    },
    {
      heading: {
        text: "Date",
        tooltip: {
          text: "Date"
        }
      },
      render: ({ time }) => getDateAndTimeString(time)
    },
    {
      heading: {
        text: "Status",
        tooltip: {
          text: "Status"
        }
      },
      render: ({ status }) => convertImportStatusToText(status)
    }
  ];

  const renderInfoItem = (title: string, info?: string | number) => {
    return (
      <div className={styles.infoItem}>
        <p className={styles.title}>{title}</p>
        <p className={styles.info}>{info}</p>
      </div>
    );
  };

  return (
    <Modal
      title={t("importExport.importInfoPage.title", {
        resource: importEntry?.name ?? t("importExport.importInfoPage.entry")
      })}
      onClose={close}
      className={styles.modal}
    >
      <div className={styles.content}>
        <div className={styles.infoBox}>
          {renderInfoItem(
            "Import date",
            getBasicFormattedDate(importEntry?.createdDate ?? "")
          )}
          {renderInfoItem(
            "Status",
            convertEntryStatusToText(importEntry?.status)
          )}
          {renderInfoItem("User type", importEntry?.externalUserTypeName)}
          {importEntry?.duplicateUsers !== undefined &&
            renderInfoItem("Duplicate entries", importEntry?.duplicateUsers)}
          {importEntry?.retriggeredUsers !== undefined &&
            renderInfoItem(
              "Retriggered entries",
              importEntry?.retriggeredUsers
            )}
          {importEntry?.errorUsers !== undefined &&
            renderInfoItem("Error entries", importEntry?.errorUsers)}
          {importEntry?.usersToImport !== undefined &&
            renderInfoItem("Users to import", importEntry?.usersToImport)}
          {importEntry?.importedUsers !== undefined &&
            renderInfoItem("Imported users", importEntry?.importedUsers)}
        </div>
        {errorLines?.length > 0 && (
          <>
            <h3>Errors</h3>
            <CustomList<ErrorLine>
              columns={errorTableColumns}
              entities={errorLines}
              isLoading={false}
              customListUniqueId="importInfoErrorLines"
            />
          </>
        )}
        {importStatuses?.length > 0 && (
          <>
            <h3>Steps</h3>
            <CustomList<ImportStatus>
              entities={importStatuses.sort((a, b) =>
                a.time > b.time ? 1 : -1
              )}
              columns={infoStatusColumns}
              isLoading={false}
              customListUniqueId="importInfoStatuses"
            />
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Link
          to={{
            pathname: "/exporting"
          }}
          state={{
            entryId: importEntry?.id,
            entryName: importEntry?.name
          }}
          className={styles.exportLink}
        >
          <button
            type="button"
            className={`btn btn-primary btn-sm ${styles.exportBtn}`}
            onClick={() => undefined}
          >
            {t("general.export")}
          </button>
        </Link>
      </div>
    </Modal>
  );
};

export default ImportInfoModal;
