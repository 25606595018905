import { TimeInMs } from "~/constants/measurements";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import useApi from "~/hooks/useApi/useApi";
import { ProgramSpecialty } from "~/typing/sidekickTypes";

export const useProgramSpecialties = ({
  programId,
  locale
}: {
  programId: string;
  locale: string;
}) => {
  const path = `/coach/programs/${programId}/locales/${locale}/specialties`;

  const { data, isLoading, isError, invalidate } = useApi<{
    items: ProgramSpecialty[];
  }>({
    queryKey: QueryKeyFactory.programs.specialties(programId, locale),
    path,
    staleTime: TimeInMs.Minute * 20
  });

  return {
    programSpecialties: data?.items ?? [],
    isLoading,
    isError,
    invalidate
  };
};
