import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";

import { QueryKeyFactory } from "../queryKeysFactory";

import {
  assignSpecialtyCoachToUser,
  autoAssignCoachesToUser,
  getCoachesForUser,
  removeCoachFromUser
} from "~/api/requests/coachRequests";

type CoachesForUserProps = {
  programId: string;
  locale: string;
  userId: string;
};

export const useCoachesForUser = ({
  userId,
  programId,
  locale
}: CoachesForUserProps) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: QueryKeyFactory.users.coachesForUser(programId, locale, userId),
    queryFn: () => getCoachesForUser({ userId, programId, locale })
  });

  return {
    coaches: data?.items,
    isLoading,
    isError
  };
};

type AssignCoachToUserProps = {
  specialtyId: string;
  coachId: string;
};

const invalidateProgramUsersAndCoahces = (
  queryClient: QueryClient,
  programId: string,
  locale: string,
  userId: string
) => {
  // Invalidate queries to update user list and assigned coaches
  queryClient.invalidateQueries({
    queryKey: QueryKeyFactory.users.coachesForUser(programId, locale, userId)
  });

  queryClient.invalidateQueries({
    queryKey: QueryKeyFactory.programs.users(programId, locale)
  });
};

export const useAssignCoachToUser = ({
  userId,
  programId,
  locale
}: {
  userId: string;
  programId: string;
  locale: string;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isError, isSuccess, isPending } = useMutation({
    mutationKey: ["assignCoachToUser", userId, programId, locale],
    mutationFn: async (props: AssignCoachToUserProps) =>
      assignSpecialtyCoachToUser({ userId, programId, locale, ...props }),
    onSuccess: () => {
      invalidateProgramUsersAndCoahces(queryClient, programId, locale, userId);
    }
  });

  return {
    assignCoachToUser: mutate,
    isPending: isPending,
    isError,
    isSuccess
  };
};

export const useRemoveCoachFromUser = ({
  userId,
  programId,
  locale
}: {
  userId: string;
  programId: string;
  locale: string;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isError, isSuccess, isPending } = useMutation({
    mutationKey: ["removeCoachFromUser", userId, programId, locale],
    mutationFn: (props: AssignCoachToUserProps) =>
      removeCoachFromUser({ userId, programId, locale, ...props }),
    onSuccess: () => {
      invalidateProgramUsersAndCoahces(queryClient, programId, locale, userId);
    }
  });

  return {
    removeCoachFromUser: mutate,
    isPending: isPending,
    isError,
    isSuccess
  };
};

export const useAutoAssignCoachesToUser = ({
  userId,
  programId,
  locale
}: {
  userId: string;
  programId: string;
  locale: string;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isError, isSuccess, isPending } = useMutation({
    mutationKey: ["autoAssignCoachesToUser", userId, programId, locale],
    mutationFn: async ({ specialtyId }: { specialtyId: string }) =>
      autoAssignCoachesToUser({ userId, programId, locale, specialtyId }),
    onSuccess: () => {
      invalidateProgramUsersAndCoahces(queryClient, programId, locale, userId);
    }
  });

  return {
    autoAssignCoachesToUser: mutate,
    isPending: isPending,
    isError,
    isSuccess
  };
};
