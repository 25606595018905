import { SegmentationKey } from "~/constants/segmentationKeys";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";

type SegmentationProps = {
  featureName: SegmentationKey;
  userId?: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const Segmentation = ({
  featureName,
  userId,
  children,
  fallback
}: SegmentationProps) => {
  const { isEnabled } = useFeatureToggle({ featureName, userId });

  return <>{isEnabled ? children : fallback || null}</>;
};
