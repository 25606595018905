import { UserProgram } from "~/typing/sidekickTypes";

export default function (
  userPrograms: UserProgram[] | undefined,
  programId: string,
  locale: string
) {
  if (!userPrograms?.length) return undefined;

  const programsWithTemplate = userPrograms.filter(
    (program) =>
      program.programCatalogItemId === programId && program.locale === locale
  );

  const sorted = programsWithTemplate.sort((a, b) => {
    const aVal = a.joinedDate;
    const bVal = b.joinedDate;

    // Always sort undefined to bottom
    if (aVal === undefined || aVal === "") {
      return 1;
    }
    if (bVal === undefined || bVal === "") {
      return -1;
    }

    return aVal < bVal ? 1 : -1;
  });

  const mostRecentProgram = sorted?.[0];
  return mostRecentProgram;
}
