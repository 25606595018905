import { DateTime } from "luxon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./QuizModal.module.scss";

import CorrectIcon from "~/assets/correct-check.svg";
import WrongIcon from "~/assets/wrong.svg";
import Image from "~/components/image/Image";
import Modal from "~/components/modal/Modal";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { Survey, SurveyResult } from "~/typing/sidekickTypes";

type QuizModalProps = {
  onClose: () => void;
  quiz?: Survey;
  quizResult?: SurveyResult;
};

const QuizModal = ({ onClose, quiz, quizResult }: QuizModalProps) => {
  const { t } = useTranslation();
  const { trackQuizMissionOpened } = useAmplitudeTracking();

  useEffect(() => {
    if (!quizResult?.result) return;
    trackQuizMissionOpened({
      quizName: quiz?.title ?? "",
      quizResult: quizResult?.result
    });
  }, [quizResult]);

  const renderQuestion = (question, index) => {
    const displaySeparator = index + 1 < (quiz?.questions?.length ?? 0);
    const selectedAnswers = quizResult?.questions?.[index]?.answers?.map(
      (answer) => answer.name
    );

    return (
      <div className={styles.questionContainer} key={question.id}>
        <div className={styles.question}>
          {`${index + 1}. ${question.title}`}
        </div>
        <div className={styles.answersContainer}>
          {question.answers?.map((answer) =>
            renderAnswer(answer, selectedAnswers)
          )}
        </div>
        {displaySeparator && <div className={styles.separator} />}
      </div>
    );
  };

  const renderAnswer = (answer, selectedAnswers) => {
    const isSelected = selectedAnswers?.includes(answer.name);
    const radioClasses = `${styles.radio} ${isSelected ? styles.selected : ""}`;
    const wrongAnswer = isSelected && !answer.ratingValue;
    const correctAnswer = answer.ratingValue > 0;
    const image = answer.imageUrl;
    const answerContainerClasses = `${styles.answerContainer} ${
      image ? styles.hasImages : ""
    } ${isSelected ? styles.selected : ""}`;

    return (
      <div className={answerContainerClasses} key={answer.id}>
        {image && (
          <div className={styles.imageWrapper}>
            <Image className={styles.image} imageUrl={image} />
            {(wrongAnswer || correctAnswer) && (
              <div className={styles.overlay}>
                <div
                  className={`${styles.indicatorContainer} ${
                    wrongAnswer ? styles.wrong : styles.correct
                  }`}
                >
                  <img
                    src={wrongAnswer ? WrongIcon : CorrectIcon}
                    className={styles.indicator}
                  />
                </div>
              </div>
            )}
            {isSelected && <div className={styles.selectedBorder} />}
          </div>
        )}
        <div className={styles.content}>
          <div className={radioClasses}>
            <div className={styles.innerRadio} />
          </div>
          <div className={styles.answer}>{answer.title}</div>
          {!image && (wrongAnswer || correctAnswer) && (
            <img
              src={wrongAnswer ? WrongIcon : CorrectIcon}
              className={styles.indicator}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={t("quizzes.modalTitle")}
      onClose={onClose}
      className={styles.modal}
    >
      <div className={styles.top}>
        <div data-testid="quiz-modal-title" className={styles.title}>
          {quiz?.title}
        </div>
        <div className={styles.date}>
          {DateTime.fromISO(quizResult?.date ?? "").toLocaleString({
            day: "2-digit",
            month: "short",
            year: "numeric"
          })}
        </div>
      </div>
      <div className={styles.resultContainer}>
        {t("quizzes.result")}
        <span className={styles.result}>{quizResult?.result}</span>
      </div>
      <div className={styles.separator} />
      <div className={styles.questionsContainer}>
        {quiz?.questions?.map(renderQuestion)}
      </div>
    </Modal>
  );
};

export default QuizModal;
