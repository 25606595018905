import { createContext } from "react";

interface PROTemplateContextProps {
  proImages: Map<string, string>;
  setProImage: (key: string, image: string) => void;
}

export const PROTemplateContext = createContext<PROTemplateContextProps>({
  proImages: new Map(),
  setProImage: () => undefined
});
