import styles from "./ButtonLink.module.scss";

type ButtonLinkProps = {
  title: string;
  onClick: () => void;
  size?: "sm" | "md" | "lg";
  active?: boolean;
};

const ButtonLink = ({
  title,
  onClick,
  size = "md",
  active
}: ButtonLinkProps) => {
  return (
    <button
      className={`${styles.linkItem} ${styles[size]} ${
        active ? styles.active : ""
      }`}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.underline} />
    </button>
  );
};

export default ButtonLink;
