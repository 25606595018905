import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";
import { MissionsSlotDay } from "~/typing/sidekickTypes";

export default function useMissionSummary({
  programId,
  locale,
  userId,
  startDate,
  endDate
}: {
  programId: string;
  locale: string;
  userId: string;
  startDate: string;
  endDate?: string;
}) {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/summaryperiod?startDate=${startDate}${
    endDate ? `&endDate=${endDate}` : ""
  }`;

  const { data, isError, isLoading, invalidate } = useApi<{
    items: MissionsSlotDay[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.missionSummary(
      userId,
      programId,
      locale,
      startDate
    ),
    staleTime: TimeInMs.Minute * 3
  });

  return {
    slotDays: data?.items ?? [],
    isError,
    isLoading,
    invalidate
  };
}
