import classNames from "classnames";
import { DateTime } from "luxon";
import { useContext, useState } from "react";

import styles from "./Note.module.scss";

import PinIcon from "~/assets/svgComponents/Pin";
import Avatar from "~/components/avatar/Avatar";
import Options from "~/components/options/Options";
import Tooltip from "~/components/tooltip/Tooltip";
import config from "~/config";
import {
  historicalTypes,
  JournalItemType,
  journalItemTypes,
  welcomeJournalItemType
} from "~/constants/notes";
import { escapeSpecialCharacters } from "~/helpers/string/stringHelpers";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import { copyToClipboard } from "~/helpers/util/util";
import useUser from "~/hooks/useUser";
import { useUserNotes } from "~/hooks/useUserNotes";
import { t } from "~/i18n";
import { TaskPageContext } from "~/pages/nextStep/TaskPage.context";
import colors from "~/styles/colors";
import { NoteInfo } from "~/typing/sidekickTypes";

interface NoteProps {
  note: NoteInfo;
  onPinClick: () => Promise<void> | undefined;
  pinned?: boolean;
  inModal?: boolean;
  hideOptions?: boolean;
  highLightedWord?: string;
}

const Note = ({
  note,
  pinned = true,
  inModal = false,
  onPinClick,
  hideOptions,
  highLightedWord
}: NoteProps) => {
  const [isPinning, setIsPinning] = useState(false);
  const [pinHovering, setPinHovering] = useState(false);
  const [showCoachName, setShowCoachName] = useState(false);

  const { archiveNote } = useUserNotes();
  const { newNextStep } = useContext(TaskPageContext);
  const { user } = useUser();

  let journalItemType: JournalItemType | undefined = undefined;

  if (note.itemType === welcomeJournalItemType.itemType) {
    journalItemType = welcomeJournalItemType;
  } else {
    journalItemType = journalItemTypes.find(
      (journalType) => journalType.itemType === note.itemType
    );
  }

  if (!note || !journalItemType) return null;

  const pinOption = () => {
    return {
      func: onPinClick,
      title: pinned ? t("notes.unpin") : t("notes.pin")
    };
  };

  const copyOption = () => {
    return {
      func: () => {
        copyToClipboard(note.text);
        displaySuccessToast({ message: t("exportPreview.copySuccess") });
      },
      title: t("exportPreview.copyText")
    };
  };

  const handlePinClick = async () => {
    if (isPinning) return;
    setIsPinning(true);
    await onPinClick();
    setIsPinning(false);
  };

  const archiveOption = () => {
    return {
      func: () => archiveNote(note, pinned),
      title: note.archived ? t("notes.unarchive") : t("notes.moveArchive")
    };
  };

  const getText = () => {
    // Split on highlight term and include term into parts, ignore case
    if (!highLightedWord) return <p>{note.text}</p>;
    const escapedquery = escapeSpecialCharacters(highLightedWord);

    const parts = note.text.split(new RegExp(`(${escapedquery})`, "gi"));
    return (
      <p>
        <span>
          {" "}
          {parts.map((part, index) => (
            <span
              key={`note-${note.id}-${index}`}
              className={
                part.toLowerCase() === highLightedWord.toLowerCase()
                  ? styles.highlight
                  : ""
              }
            >
              {part}
            </span>
          ))}{" "}
        </span>
      </p>
    );
  };

  return (
    <>
      {inModal && (
        <div className={styles.aboveNote} data-testid="notesHeader">
          <div className={styles.aboveNoteInfo}>
            <p>
              {DateTime.fromISO(note.createdDate)
                .toLocaleString({
                  hour12: config.isAnthem,
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit"
                })
                .toUpperCase()}
            </p>
            <p>{note?.coachUser?.displayName}</p>
          </div>
          <Options
            hideOptions={hideOptions}
            options={[
              ...(note.archived || historicalTypes.includes(note.itemType)
                ? []
                : [pinOption()]),
              ...(note?.coachUser?.userId === user?.id &&
              !historicalTypes.includes(note.itemType)
                ? [archiveOption()]
                : []),
              copyOption()
            ]}
            className={styles.options}
            dataTestId="note-options"
          />
        </div>
      )}
      <div
        className={classNames(styles.container, {
          [styles.pinned]: pinned && !inModal,
          [styles.newNextStep]: newNextStep && !inModal
        })}
        data-testid="note-container"
      >
        <div className={styles.header}>
          <div data-testid="note-header-info" className={styles.headerNoteInfo}>
            {(pinned || inModal) && (
              <button
                data-testid="pinNotesAfter"
                onMouseEnter={() => setPinHovering(true)}
                onMouseLeave={() => setPinHovering(false)}
                onClick={handlePinClick}
                className={styles.pinButton}
                disabled={
                  note.archived || historicalTypes.includes(note.itemType)
                }
              >
                <PinIcon
                  color={
                    newNextStep && !inModal
                      ? colors.darkBlue80
                      : colors.navyBlue80
                  }
                  hollow={(pinned && pinHovering) || (!pinned && !pinHovering)}
                />
              </button>
            )}
            <p>{journalItemType.title}</p>
          </div>
          {!inModal && (
            <div
              onMouseEnter={() => setShowCoachName(true)}
              onMouseLeave={() => setShowCoachName(false)}
              className={styles.coach}
            >
              <p>
                {DateTime.fromISO(note.createdDate).toLocaleString({
                  hour12: false,
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit"
                })}
              </p>
              <Avatar size="sm" user={note?.coachUser} />
              <Tooltip
                show={showCoachName}
                content={note?.coachUser?.displayName}
              />
            </div>
          )}
        </div>
        {getText()}
      </div>
    </>
  );
};

export default Note;
