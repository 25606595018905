import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FitnessTestPage from "./FitnessTestPage";
import MonthlyPhysicalChartTemplate from "./MonthlyPhysicalChartTemplate";
import PeriodPhysicalChartTemplate from "./PeriodPhysicalChartTemplate";
import PhysicalChartTemplate from "./PhysicalChartTemplate";
import PhysicalNav from "./PhysicalNav";
import {
  DAYS_IN_WEEK,
  DisplayDistance,
  GraphView
} from "./PhysicalPage.constants";
import { getStartAndEndDatesForQuery } from "./PhysicalPage.helpers";
import styles from "./PhysicalPage.module.scss";

import ButtonLink from "~/components/buttonLink/ButtonLink";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { urlContainsString } from "~/helpers/browser/browserHelpers";
import filterDataByWeekOrMonth from "~/helpers/date/filterDataByWeekOrMonth";
import round from "~/helpers/number/round";
import usePhysicalData from "~/hooks/useApi/usePhysicalData";
import useUrlQuery from "~/hooks/useUrlQuery";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  PhysicalActivity,
  PhysicalPageURLParams
} from "~/typing/carePortalTypes";
import { PhysicalData } from "~/typing/sidekickTypes";

const PhysicalPage = () => {
  const {
    program_id = "",
    locale = "",
    user_id = "",
    physical_category = ""
  } = useParams<PhysicalPageURLParams>();
  const today = DateTime.utc();
  const [thisWeekFilter, setThisWeekFilter] = useState(true);
  const [lastWeekFilter, setLastWeekFilter] = useState(false);
  const [weekBeforeFilter, setWeekBeforeFilter] = useState(false);
  const [displayDistance, setDisplayDistance] = useState<DisplayDistance>(
    DisplayDistance.Six
  );
  const { t } = useTranslation();

  const activityData: PhysicalActivity[] = [];
  const stepCountData: PhysicalActivity[] = [];
  const query = useUrlQuery();
  const [graphView, setGraphView] = useState<GraphView>(
    (query.get("type") as GraphView) ?? GraphView.Weekly
  );

  const totalActivity: { [key: string]: number } = {};
  const totalStepCount = {};
  const averageActivity: { [key: string]: number } = {};
  const averageStepCount = {};

  const [endDate, setEndDate] = useState<DateTime>(today);
  const startDateDefault = today.minus({ days: 200 });
  const [startDate, setStartDate] = useState<DateTime>(startDateDefault);

  const { trackPerformancePageViewed } = useAmplitudeTracking();

  const { physicalData, invalidate: invalidatePhysicalData } = usePhysicalData({
    programId: program_id,
    locale,
    userId: user_id,
    startDate,
    endDate
  });

  const getPerformancePageTitle = () => {
    if (urlContainsString("activity")) {
      return "Activity";
    }
    if (urlContainsString("step-counter")) {
      return "Step counter";
    }
    if (urlContainsString("fitness-test")) {
      return "Fitness test";
    }
    return "Activity";
  };

  const changeEndDate = (multiplier: 1 | -1) => {
    const { newStartDate, newEndDate } = getStartAndEndDatesForQuery(
      graphView,
      endDate,
      multiplier
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const resetState = () => {
    setThisWeekFilter(true);
    setLastWeekFilter(false);
    setWeekBeforeFilter(false);
    setDisplayDistance(6);
    setGraphView(GraphView.Weekly);
    setEndDate(today);
    setStartDate(startDateDefault);
    invalidatePhysicalData();
  };

  useEffect(() => {
    trackPerformancePageViewed({
      performanceSection: getPerformancePageTitle(),
      performanceView: "Physical",
      performanceViewMode:
        graphView === GraphView.Weekly
          ? "Weekly"
          : graphView === GraphView.Monthly
          ? "Monthly"
          : "Detail"
    });

    const { newStartDate, newEndDate } = getStartAndEndDatesForQuery(
      graphView,
      endDate
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [graphView]);

  if (physicalData) {
    const thisWeekData: PhysicalData[] = filterDataByWeekOrMonth(
      physicalData,
      graphView === "weekly",
      endDate
    );

    const lastWeekData: PhysicalData[] = filterDataByWeekOrMonth(
      physicalData,
      graphView === "weekly",
      endDate.minus({ days: 7 })
    );

    const weekBeforeData: PhysicalData[] = filterDataByWeekOrMonth(
      physicalData,
      graphView === "weekly",
      endDate.minus({ days: DAYS_IN_WEEK * 2 })
    );

    let index = 0;
    while (index < DAYS_IN_WEEK) {
      const weekDay = today.minus({ days: index });
      if (!thisWeekData.some((data) => data.date.weekday === weekDay.weekday)) {
        const date = weekDay;
        thisWeekData.push({
          date: date,
          physicalActivityMinutes: 0,
          scoreCount: 0,
          stepCount: 0
        });
      }
      if (!lastWeekData.some((data) => data.date.weekday === weekDay.weekday)) {
        const date = weekDay.minus({ days: DAYS_IN_WEEK * 1 });
        lastWeekData.push({
          date: date,
          physicalActivityMinutes: 0,
          scoreCount: 0,
          stepCount: 0
        });
      }
      if (
        !weekBeforeData.some((data) => data.date.weekday === weekDay.weekday)
      ) {
        const date = weekDay.minus({ days: DAYS_IN_WEEK * 2 });
        weekBeforeData.push({
          date: date,
          physicalActivityMinutes: 0,
          scoreCount: 0,
          stepCount: 0
        });
      }
      index++;
    }

    thisWeekData.sort((a: PhysicalData, b: PhysicalData) =>
      a.date.toMillis() < b.date.toMillis() ? -1 : 1
    );
    weekBeforeData.sort((a: PhysicalData, b: PhysicalData) =>
      a.date.toMillis() < b.date.toMillis() ? -1 : 1
    );
    lastWeekData.sort((a: PhysicalData, b: PhysicalData) =>
      a.date.toMillis() < b.date.toMillis() ? -1 : 1
    );

    // separate into activity and step count data
    for (let i = 0; i < 7; i++) {
      activityData.push({
        thisWeek: thisWeekData[i] ? thisWeekData[i].physicalActivityMinutes : 0,
        lastWeek: lastWeekData[i] ? lastWeekData[i].physicalActivityMinutes : 0,
        weekBefore: weekBeforeData[i]
          ? weekBeforeData[i].physicalActivityMinutes
          : 0,
        date: thisWeekData[i]
          ? thisWeekData[i].date
          : endDate.minus({ days: i })
      });

      stepCountData.push({
        thisWeek: thisWeekData[i] ? thisWeekData[i].stepCount : 0,
        lastWeek: lastWeekData[i] ? lastWeekData[i].stepCount : 0,
        weekBefore: weekBeforeData[i] ? weekBeforeData[i].stepCount : 0,
        date: thisWeekData[i]
          ? thisWeekData[i].date
          : endDate.minus({ days: i })
      });
    }

    activityData.sort((a, b) =>
      a.date.toMillis() < b.date.toMillis() ? 1 : -1
    );

    const total = (data: PhysicalActivity[], dataKey: string) => {
      return data.reduce((r, a) => {
        return r + a[dataKey];
      }, 0);
    };

    const average = (
      data: PhysicalActivity[],
      dataKey: string,
      precision = 0
    ) => {
      const nonZeroData = data.filter((d) => d[dataKey] > 0);

      return round(total(nonZeroData, dataKey) / nonZeroData.length, precision);
    };

    const dataKeys = ["thisWeek", "lastWeek", "weekBefore"];

    dataKeys.forEach((key) => {
      totalActivity[key] = total(activityData, key);
      totalStepCount[key] = total(stepCountData, key);
      averageActivity[key] = average(activityData, key) ?? 0;
      averageStepCount[key] = average(stepCountData, key);
    });
  }

  const activityUnits = t("units.mins", "mins");
  const stepUnits = t("units.steps", "steps");

  const TopNavigation = ({ forFitness }: { forFitness?: boolean }) => (
    <div className={styles.top}>
      <PhysicalNav />

      <div style={{ display: "flex" }}>
        {forFitness ? (
          <>
            <ButtonLink
              title={`6 ${t("units.mins", "mins")}`}
              onClick={() => setDisplayDistance(DisplayDistance.Six)}
              active={displayDistance === DisplayDistance.Six}
            />
            <ButtonLink
              title={`12 ${t("units.mins", "mins")}`}
              onClick={() => setDisplayDistance(DisplayDistance.Twelve)}
              active={displayDistance === DisplayDistance.Twelve}
            />
          </>
        ) : (
          <>
            <ButtonLink
              active={graphView === GraphView.Weekly}
              title={t("time.weekly")}
              onClick={() => setGraphView(GraphView.Weekly)}
            />
            <ButtonLink
              active={graphView === GraphView.Monthly}
              title={t("time.monthly")}
              onClick={() => setGraphView(GraphView.Monthly)}
            />
            <ButtonLink
              active={graphView === GraphView.Period}
              title={t("performance.physical.detail")}
              onClick={() => setGraphView(GraphView.Period)}
            />
          </>
        )}
      </div>
    </div>
  );

  return (
    <SentryErrorBoundary transactionName="PhysicalPage" resetState={resetState}>
      <Helmet
        title={t("performance.physical.performance", "Physical Performance")}
        defer={false}
      />
      <TopNavigation forFitness={physical_category === "fitness-test"} />
      {physical_category === "fitness-test" ? (
        <FitnessTestPage displayDistance={displayDistance} />
      ) : (
        <>
          {graphView === "weekly" && (
            <PhysicalChartTemplate
              data={
                physical_category === "activity" ? activityData : stepCountData
              }
              units={
                physical_category === "activity" ? activityUnits : stepUnits
              }
              thisWeekFilter={thisWeekFilter}
              setThisWeekFilter={setThisWeekFilter}
              lastWeekFilter={lastWeekFilter}
              setLastWeekFilter={setLastWeekFilter}
              weekBeforeFilter={weekBeforeFilter}
              setWeekBeforeFilter={setWeekBeforeFilter}
              totals={
                physical_category === "activity"
                  ? totalActivity
                  : totalStepCount
              }
              averages={
                physical_category === "activity"
                  ? averageActivity
                  : averageStepCount
              }
              endDate={endDate}
            />
          )}
          {graphView === "monthly" && (
            <MonthlyPhysicalChartTemplate
              data={physicalData}
              dataKey={
                physical_category === "activity"
                  ? "physicalActivityMinutes"
                  : "stepCount"
              }
              today={today}
              units={
                physical_category === "activity" ? activityUnits : stepUnits
              }
              endDate={endDate}
              changeEndDate={changeEndDate}
            />
          )}
          {graphView === "period" && (
            <PeriodPhysicalChartTemplate
              data={physicalData}
              dataKey={
                physical_category === "activity"
                  ? "physicalActivityMinutes"
                  : "stepCount"
              }
              today={today}
              units={
                physical_category === "activity" ? activityUnits : stepUnits
              }
            />
          )}
        </>
      )}
    </SentryErrorBoundary>
  );
};

export default PhysicalPage;
