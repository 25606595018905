import { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link, useParams, Outlet } from "react-router-dom";

import ProgramHeader from "./components/programHeader/ProgramHeader";
import ProgramLocaleNav from "./components/programLocaleNav/ProgramLocaleNav";
import ProgramNav from "./components/programNav/ProgramNav";
import SurveyResultExport from "./components/surveyResultExport/SurveyResultExport";
import styles from "./ProgramPage.module.scss";
import RegisterPatient from "./registration/registerPatient/RegisterPatient";

import ExportIcon from "~/assets/export.svg";
import UsersAddIcon from "~/assets/users-add.svg";
import Breadcrumbs from "~/components/breadcrumbs/Breadcrumbs";
import Layout from "~/components/layout/Layout";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import { ExternalUserTypeBehaviour } from "~/constants/externalUserBehaviours";
import { UserPrivileges } from "~/constants/privileges";
import {
  getProgramBreadcrumbName,
  getProgramDisplayName
} from "~/helpers/program/programHelpers";
import useProgram from "~/hooks/useApi/useProgram";
import useProgramLocales from "~/hooks/useApi/useProgramLocales";
import { useProgramSpecialties } from "~/hooks/useApi/useProgramSpecialties";
import useSurveys from "~/hooks/useApi/useSurveys";
import useUser from "~/hooks/useUser";
import { ProgramURLParams } from "~/typing/carePortalTypes";

export default function ProgramPage() {
  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();
  const { t } = useTranslation();
  const [showRegisterPatient, setShowRegisterPatient] = useState(false);
  const [showSurveyResultExport, setShowSurveyResultExport] = useState(false);
  const { program, isLoading: programIsLoading } = useProgram({
    programCatalogItemId: program_id,
    locale
  });
  const {
    programLocales,
    invalidate: invalidateProgramLocales
  } = useProgramLocales({
    programCatalogItemId: program_id,
    disabled: !config.isAnthem
  });
  const externalUserTypes = program?.externalUserTypes;
  const enrolmentExternalUserTypes = externalUserTypes?.filter(
    (eut) => eut.behaviour & ExternalUserTypeBehaviour.EnrolmentEnabled
  );
  const { pathname } = useLocation();
  const { user } = useUser();

  const {
    programSpecialties,
    isLoading: programSpecialtiesLoading
  } = useProgramSpecialties({
    programId: program_id,
    locale
  });

  const isComplianceView = pathname.includes("compliance");

  const allowManageCoaches =
    parseInt(user?.privileges ?? "0") >= UserPrivileges.LeadCoach &&
    !programSpecialtiesLoading &&
    !programSpecialties?.length;

  const allowSurveyResultExport =
    program?.surveyResultExportEnabled && !isComplianceView;

  const allowInvitePatient =
    !isComplianceView &&
    enrolmentExternalUserTypes &&
    enrolmentExternalUserTypes?.length > 0;

  const { surveys } = useSurveys(program_id, locale);

  const handleOpenRegisterPatient = () => {
    setShowRegisterPatient(true);
  };

  const handleOpenSurveyResultExport = () => {
    setShowSurveyResultExport(true);
  };

  const breadcrumbLinkData = [
    {
      text: getProgramBreadcrumbName(program, programLocales)
    }
  ];

  return (
    <SentryErrorBoundary
      transactionName="ProgramPage"
      resetState={invalidateProgramLocales}
    >
      <Layout>
        <Helmet title={getProgramDisplayName(program)} defer={false} />
        {programLocales && programLocales.length > 1 && (
          <ProgramLocaleNav programLocales={programLocales} />
        )}
        <div className={styles.nav}>
          <Breadcrumbs linkData={breadcrumbLinkData} />
          <div className={styles.actions}>
            {allowManageCoaches && (
              <Link
                to={`/program/${program_id}/locale/${locale}/team`}
                className={`btn-secondary btn-sm ${styles.manageCoaches}`}
              >
                {t("manageCoaches.title")}
              </Link>
            )}
            {allowSurveyResultExport && (
              <button
                className={`btn-secondary btn-sm ${styles.topButton}`}
                onClick={handleOpenSurveyResultExport}
              >
                <img src={ExportIcon} />
                {t("surveyResultExport.exportPro")}
              </button>
            )}
            {allowInvitePatient && (
              <button
                className={`btn-secondary btn-sm ${styles.topButton}`}
                onClick={handleOpenRegisterPatient}
              >
                <img src={UsersAddIcon} />
                {t("registerPatient.registerPatient")}
              </button>
            )}
          </div>

          {showSurveyResultExport && program && (
            <SurveyResultExport
              onClose={() => setShowSurveyResultExport(false)}
              surveys={surveys}
            />
          )}

          {showRegisterPatient && program && externalUserTypes && (
            <RegisterPatient
              onClose={() => setShowRegisterPatient(false)}
              externalUserTypes={externalUserTypes}
              enrolmentExternalUserTypes={enrolmentExternalUserTypes ?? []}
              location={program.location}
            />
          )}
        </div>
        {<ProgramHeader program={program} loading={programIsLoading} />}
        <div className={styles.navContainer}>
          <ProgramNav
            showRegistration={
              (externalUserTypes && externalUserTypes.length > 0) ?? false
            }
            isComplianceView={isComplianceView}
          />
        </div>
        <div className={styles.innerApp}>
          <Suspense
            fallback={
              <div className={styles.loadingContainer}>
                <LoadingSpinner />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </div>
      </Layout>
    </SentryErrorBoundary>
  );
}
