import { ForwardedRef, forwardRef, useEffect, useState } from "react";

import styles from "./Input.module.scss";

type InputProps = {
  onChange?: (value: string) => void;
  placeHolder?: string;
  type?: "text" | "number" | "email" | "date" | "password";
  autoFocus?: boolean;
  required?: boolean;
  initialValue?: string;
  label?: string;
  dataTestId?: string;
  disabled?: boolean;
  value?: string;
  error?: string;
  onBlur?: () => void;
  maxLength?: number;
  className?: string;
  wrapperClassName?: string;
  id?: string;
  multiple?: boolean;
  autoComplete?: string;
};

interface IProps extends InputProps {
  inputRef?: ForwardedRef<HTMLInputElement>;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, inputRef) => {
  return <InputComponent {...props} inputRef={inputRef} />;
});

const InputComponent = ({
  onChange,
  placeHolder,
  type = "text",
  initialValue,
  autoFocus = false,
  label,
  required,
  dataTestId,
  disabled,
  inputRef,
  value,
  error,
  onBlur,
  maxLength,
  className,
  wrapperClassName,
  id,
  multiple,
  autoComplete
}: IProps) => {
  const [showError, setShowError] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialValue);

  const handleChange = (value: string) => {
    setShowError(false);
    if (type === "number") {
      setCurrentValue(value.replace(/[^\d.]/gi, ""));
    } else {
      setCurrentValue(value);
    }
  };

  useEffect(() => {
    if (currentValue !== undefined && onChange) onChange(currentValue);
  }, [currentValue]);

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  return (
    <div className={`inputContainer ${wrapperClassName}`}>
      {label && (
        <label className="label label--block">
          {label}
          {required && <span className={styles.star}>*</span>}
        </label>
      )}
      <input
        multiple={multiple}
        disabled={disabled}
        ref={inputRef}
        data-testid={dataTestId}
        className={`${styles.input} ${
          error && showError ? styles.errorBorder : ""
        } ${className}`}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeHolder}
        autoFocus={autoFocus}
        value={value ?? currentValue}
        type={type}
        onBlur={onBlur}
        maxLength={maxLength}
        id={id}
        autoComplete={autoComplete}
      />
      {error && showError && (
        <p data-testid="input-error" className={styles.error}>
          {error}
        </p>
      )}
    </div>
  );
};

Input.displayName = "Input";
export default Input;
